import React, { FC, memo } from 'react';
import { IconButton } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import {
  GetControllerProfilesQuery,
} from '$typings/graphql-codegen';
import {
  ListWrapper,
  Row,
  SecurityLevelCell,
  NameCell,
  ActionsCell,
  RowList,
} from '../../../../common/profile-and-parameter-list';
import { useIsMobile } from '$lib/hooks/isMobile';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { SecurityLevelEdit } from '$pages/common/controllers/controllerdetails-react/modules/controller-profiles/modules/security-level-edit';
import { IStatusMessage } from '../../../controller-parameters/modules/common/status-message';
import { ControllerProfileExportInfo } from '../../controller-profiles-types';

interface IControllerProfileListProps {
  data: GetControllerProfilesQuery;
  accessToSecurityLevel: boolean;
  editProfile: (profileId: number) => unknown;
  exportProfile: (profile: ControllerProfileExportInfo) => unknown;
  setStatusMessage: (statusMessage: IStatusMessage | undefined) => unknown;
  isExportingProfileId?: number;
  readonly?: boolean
}

const ControllerProfileListComponent: FC<IControllerProfileListProps> = ({
  data,
  accessToSecurityLevel,
  editProfile,
  exportProfile,
  isExportingProfileId,
  setStatusMessage,
  readonly
}: IControllerProfileListProps) => {
  const [t] = useCaseInsensitiveTranslation();
  const isMobile = useIsMobile();

  return (
    <>
      <ListWrapper>
        <Row>
          {accessToSecurityLevel && <SecurityLevelCell />}
          <NameCell fontWeight={'bold'}>
            {t(
              'UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_Table_Labels_Name'
            )}
          </NameCell>
          {!isMobile && !readonly && (
            <ActionsCell fontWeight={'bold'}>
              {t(
                'UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_Table_Labels_Edit'
              )}
            </ActionsCell>
          )}
          {!readonly && <ActionsCell fontWeight={'bold'}>
            {t(
              'UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_Table_Labels_Export'
            )}
          </ActionsCell>}
        </Row>
        <RowList>
          {data.controller?.profiles.map((p, i) => (
            <Row
              key={p.controllerProfileId}
              bgcolor={i % 2 === 0 ? '#FFF' : '#fafcfe'}
            >
              {accessToSecurityLevel && (
                <SecurityLevelCell>
                  <SecurityLevelEdit
                    controllerId={p.controllerId}
                    controllerProfileId={p.controllerProfileId}
                    setStatusMessage={setStatusMessage}
                    initialValue={p.securityLevel}
                  />
                </SecurityLevelCell>
              )}
              <NameCell>{p.name}</NameCell>
              {!isMobile && !readonly && (
                <ActionsCell>
                  <IconButton
                    size="small"
                    onClick={() => editProfile(p.controllerProfileId)}
                  >
                    <Icon name={'fa-pencil'} />
                  </IconButton>
                </ActionsCell>
              )}
              {!readonly && <ActionsCell>
                <IconButton
                  size="small"
                  onClick={() => exportProfile(p)}
                  disabled={!!isExportingProfileId}
                >
                  <Icon
                    name={
                      isExportingProfileId !== p.controllerProfileId
                        ? 'fa-file-excel-o'
                        : 'fa-spinner fa-pulse'
                    }
                  />
                </IconButton>
              </ActionsCell>}
            </Row>
          ))}
        </RowList>
      </ListWrapper>
    </>
  );
};

export const ControllerProfileList = memo(ControllerProfileListComponent);
