import { KeyPrefix, Namespace } from 'i18next';
import {
  useTranslation,
  UseTranslationOptions,
  UseTranslationResponse
} from 'react-i18next';
import { DefaultNamespace } from 'react-i18next/TransWithoutContext';

export function useCaseInsensitiveTranslation<
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined
>(
  ns?: N | Readonly<N>,
  options?: UseTranslationOptions<TKPrefix>
): UseTranslationResponse<N, TKPrefix> {
  const translation = useTranslation<N, TKPrefix>(ns, options);
  const t = (key: any, options: any) => {
    const lowercaseKey = key?.toString()?.toLowerCase();
    const translatedText = translation.t(lowercaseKey, options);
    return translatedText === lowercaseKey ? key : translatedText;
  };
  const i18n = translation[1];
  const ready = translation[2];
  return [t, i18n, ready] as UseTranslationResponse<N, TKPrefix>;
}
