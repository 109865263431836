import React, { FC, useState, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './contactssearch.css';
import { ContactType, ContactSortEnum, SortDirection } from '../../../../../custom_typings/graphql';
import CardContent from '$components/cards/card-content/card-content.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import Icon from '$components/icons/icon/icon.react';

import CardHeader from '$components/cards/card-header/card-header.react';
import TextField from '$components/textbox/text-field.react';
import { ListRange, Virtuoso } from 'react-virtuoso';
import Button from '$components/buttons/button.react';
import { Contact, ReactContactsSearchQueryDocument } from '$typings/graphql-codegen';
import ContactRow from '$pages/user-profile/react/modules/contact-info-card/modules/contact-row/contact-row';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import classNames from "classnames";

interface IContactsSearchProps {
  contactType?: ContactType;
  createNewContact?: () => void;
  className?: string;
  optionalButtons?: ReactNode;
  setAsContact?: (contact: Contact) => void;
  cancelSearch?: () => void;
}

const ContactsSearch: FC<IContactsSearchProps> = ({ contactType, className, setAsContact, createNewContact, optionalButtons, cancelSearch }) => {
  const [t] = useTranslation();
  const lastViewedItems = useRef<ListRange>();

  const [variables, setVariables] = useState({
    freeTextQuery: '',
    sortProperty: ContactSortEnum.Name,
    sortDirection: SortDirection.Asc,
    offset: 0,
    first: 100
  });

  const data = useGridData(
    ReactContactsSearchQueryDocument,
    variables,
    data => data?.contacts.totalCount,
    data => data.contacts.edges
  );

  function textQueryChanged(freeTextQuery: string) {
    if (!variables) return;
    setVariables({
      ...variables,
      freeTextQuery
    });
  }

  return (
    <div className={classNames(className)}>
      <CardHeader>{t('ui_contacts_contactsearch_header', { contactType: contactType })}</CardHeader>
      <CardContent>
        <TextField
          onChange={e => textQueryChanged(e.target.value)}
          icon={<Icon name="fa-search" />}
          autoFocus
          placeholder={t('ui_contacts_contactsearch_inputtext_placeholder')}
        ></TextField>
      </CardContent>
      <CardContent className="contactsearch-search-results">
        <Virtuoso
          style={{ height: '100%' }}
          overscan={50}
          data={data.data}
          components={{
            EmptyPlaceholder: () => (
              <div className="loading">{data.isRevalidating && !data.data ? <LoadingBars /> : <span>{t('ui_grid_nomatch')}</span>}</div>
            )
          }}
          rangeChanged={i => {
            if(i.startIndex > 0) {
              data.visibleItemsChanged(i)
            }
            lastViewedItems.current = i
          }}          itemContent={(_index, item: Contact) => (
            <div className={classNames('row-item', _index % 2 === 0 ? 'even' : 'odd')}>
              {item && <ContactRow contact={item} showDescription></ContactRow>}
              <Button variant="secondary" className="action-button" size="small" onClick={() => setAsContact && setAsContact(item)}>
                {t('ui_sitedetails_details_contacts_assigncontact_actions_setascontact')}
              </Button>
            </div>
          )}
        />
      </CardContent>
      <CardFooter className="flex jsb">
        <Button variant="secondary" onClick={cancelSearch}>
          {t('ui_common_cancel')}
        </Button>
        <div className="create-actions">
          {createNewContact && (
            <Button variant="secondary" icon="fa-user-plus" onClick={createNewContact}>
              {t('ui_contacts_contactsearch_createnewcontact')}
            </Button>
          )}
        </div>

        {optionalButtons}
      </CardFooter>
    </div>
  );
};

export default ContactsSearch;
