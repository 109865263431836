import React, { FC } from 'react';

interface ISlidersProps {
  className?: string;
}

const Sliders: FC<ISlidersProps> = ({ className }) => (
  <svg className={className} viewBox="0 0 24 24" height="1em" width="1em" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 17h-7M10 17H3M14 20v-6M21 8h-9M8 8H3M8 11V5" stroke="#2B6CB0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Sliders;
