import React, { FC } from "react";
import Dropdown from "$components/dropdowns/dropdown/dropdown.react";
import countries from "$lib/application/countries";

interface ICountryCodeDropdownProps {
  selected?: string;
  topElement?: string | boolean | 'IF_QUERY';
  itemClicked?: (params: { countryCode?: string }) => void;
  error?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}

const countryCodes: ICountry[] = countries.map(c => ({
  code: c.code,
  name: c.name,
  displayName: `${c.name}: ${c.code}`
}));

interface ICountry {
  code?: string;
  name?: string;
  displayName?: string
}

const CountryCodeDropdown: FC<ICountryCodeDropdownProps> = ({ selected, topElement, itemClicked, error, className, placeholder, disabled }) => {
  const selectedCountry = countryCodes.find(cc => cc.code?.toLowerCase() === selected?.toLowerCase());
  return (
    <Dropdown<ICountry>
      className={className}
      items={countryCodes}
      displayPath="displayName"
      valuePath="code"
      selected={selectedCountry}
      loading={false}
      itemClicked={params => itemClicked && itemClicked({countryCode: params?.value as string})}
      topElement={topElement}
      placeholder={placeholder}
      error={error}
      disabled={disabled}
    />
  );
};


export default CountryCodeDropdown;
