import Dropdown from '$components/dropdowns/dropdown/dropdown.react';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { ReportSchedulesDropdownQuery_reportSchedules } from '$typings/graphql';
import { ReactReportSchedulesDropdownQueryDocument } from '$typings/graphql-codegen';
import React, { FC, useEffect, useState } from 'react';

interface ISchedulesDropdownProps {
  selected?: string | ReportSchedulesDropdownQuery_reportSchedules;
  topElement?: string | boolean | 'IF_QUERY';
  placeholder?: string;
  itemClicked?: (params: { value: string; text: string }) => void;
  disabled?: boolean;
  required?: boolean;
}

type ReportScheduleDropDownOption = {
  reportScheduleId?: string | null;
  scheduleName?: string | null;
};

const SchedulesDropdown: FC<ISchedulesDropdownProps> = ({
  selected,
  topElement = undefined,
  placeholder,
  itemClicked,
  disabled = false,
}) => {
  const { data, loading } = useQuery(ReactReportSchedulesDropdownQueryDocument);
  const [noReportSchedules, setNoReportSchedules] = useState<boolean>(false);
  const [reportSchedules, setReportSchedules] = useState<
    ReportScheduleDropDownOption[] | null
  >(null);

  useEffect(() => {
    if (!data || !data.reportSchedules) return setReportSchedules(null);

    const enabledOptions = data.reportSchedules.map(x => ({
      reportScheduleId: x.reportScheduleId,
      scheduleName: x.enabled ? (x.scheduleType !== "System" ? x.scheduleName : `${x.scheduleName} - System`)
                              : `${x.scheduleName} - Disabled`
    }));

    setReportSchedules(enabledOptions);
  }, [data?.reportSchedules]);

  useEffect(() => {
    reportSchedules && reportSchedules?.length === 0
      ? setNoReportSchedules(true)
      : setNoReportSchedules(false);
  }, [reportSchedules]);

  return (
    <div>
      <Dropdown<ReportScheduleDropDownOption>
        items={reportSchedules || []}
        displayPath="scheduleName"
        valuePath="reportScheduleId"
        selected={selected}
        loading={loading}
        disabled={disabled}
        itemClicked={itemClicked}
        placeholder={`${
          noReportSchedules ? 'You do not have any schedules' : placeholder
        }`}
        topElement={topElement}
      />
    </div>
  );
};

export default SchedulesDropdown;
