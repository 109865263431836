import { getTimezoneForSiteSelector } from '../../../../../common';
import { getLogger } from 'aurelia-logging';
import {
  ensureNumber,
  distinct,
  isMobileWatcher
} from '../../../../../../utility';
import { ISiteGalleryItemDetails } from '../../../../../../interfaces';
import { GalleryService } from '../../../../../../services';
import { Router } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { autoinject, computedFrom } from 'aurelia-framework';
import './sitedetailsgallerydetails.css';
import { rootState } from '../../../../../../reducers';
import gql from 'graphql-tag';
import { GraphQLBaseViewModel } from '../../../../../common/GraphQLBaseViewModel';
import {
  SiteDetailsGalleryDetailsQueryVariables,
  SiteDetailsGalleryDetailsQuery,
  SiteDetailsGalleryDetailsQuery_site_galleryItems,
  SiteDetailsGalleryUpdateTagsMutation,
  SiteDetailsGalleryUpdateTagsMutationVariables
} from '../../../../../../../custom_typings/graphql';

interface ISiteDetailsGalleryDetailsState {
  timezone?: string;
}

export const SiteDetailsGalleryDetailsQueryFragmentDefinition = gql`
  fragment SiteDetailsGalleryDetailsQueryFragment on GalleryItem {
    siteGalleryItemId
    tags
    thumbnailUrl
    imageUrl
    sizeInBytes
    uploadedTimestamp
    fileName
    uploadedByUser {
      userId
      name
    }
  }
`;

const SiteDetailsGalleryUpdateTagsMutationDeclaration = gql`
  mutation SiteDetailsGalleryUpdateTagsMutation(
    $galleryItemId: ID!
    $tags: [String]!
  ) {
    updateGalleryItemTags(galleryItemId: $galleryItemId, tags: $tags) {
      siteGalleryItemId
      tags
    }
  }
`;

@autoinject()
export class SiteDetailsGalleryDetails extends GraphQLBaseViewModel<
  ISiteDetailsGalleryDetailsState,
  SiteDetailsGalleryDetailsQuery,
  SiteDetailsGalleryDetailsQueryVariables
> {
  constructor(private galleryService: GalleryService, private router: Router) {
    super(getLogger(SiteDetailsGalleryDetails.name));
    this.unsubscribeOnDetach(
      isMobileWatcher(isMobile => (this.isMobile = isMobile))
    );
  }

  query = gql`
    ${SiteDetailsGalleryDetailsQueryFragmentDefinition}
    query SiteDetailsGalleryDetailsQuery($siteId: Int!) {
      site(siteId: $siteId) {
        galleryItems {
          ...SiteDetailsGalleryDetailsQueryFragment
        }
      }
    }
  `;

  @computedFrom('data.site.galleryItems', 'galleryItemId')
  get selectedGalleryImage() {
    if (!this.data || !this.data.site || !this.data.site.galleryItems)
      return undefined;
    return this.data.site.galleryItems.find(
      galleryItem => galleryItem.siteGalleryItemId === this.galleryItemId
    );
  }

  @computedFrom('data.site.galleryItems', 'galleryItemId')
  get nextImage() {
    if (!this.data || !this.data.site || !this.data.site.galleryItems)
      return undefined;
    const { galleryItems } = this.data.site;

    const indexOfSelectedGalleryImage = galleryItems.findIndex(
      item => item.siteGalleryItemId === this.galleryItemId
    );
    return indexOfSelectedGalleryImage === galleryItems.length
      ? undefined
      : galleryItems[indexOfSelectedGalleryImage + 1];
  }

  @computedFrom('data.site.galleryItems', 'galleryItemId')
  get slideShowImages() {
    if (!this.data || !this.data.site || !this.data.site.galleryItems)
      return [];

    const { galleryItems } = this.data.site;

    const indexOfSelectedGalleryImage = galleryItems.findIndex(
      item => item.siteGalleryItemId === this.galleryItemId
    );

    const previousTwo = galleryItems.slice(
      Math.max(
        Math.min(
          indexOfSelectedGalleryImage - 2,
          indexOfSelectedGalleryImage -
            5 +
            (galleryItems.length - indexOfSelectedGalleryImage)
        ),
        0
      ),
      indexOfSelectedGalleryImage
    );

    const nextTwo = galleryItems.slice(
      indexOfSelectedGalleryImage + 1,
      Math.min(
        galleryItems.length,
        indexOfSelectedGalleryImage + 3 + (2 - previousTwo.length)
      )
    );

    return [
      ...previousTwo,
      galleryItems[indexOfSelectedGalleryImage],
      ...nextTwo
    ];
  }

  @computedFrom('data.site.galleryItems', 'galleryItemId')
  get previousImage() {
    if (!this.data || !this.data.site || !this.data.site.galleryItems)
      return undefined;
    const { galleryItems } = this.data.site;

    const indexOfSelectedGalleryImage = galleryItems.findIndex(
      item => item.siteGalleryItemId === this.galleryItemId
    );
    return indexOfSelectedGalleryImage === galleryItems.length
      ? undefined
      : galleryItems[indexOfSelectedGalleryImage - 1];
  }

  galleryItemId: string;
  siteId: number;
  isMobile: boolean;
  showInfo = false;

  activate(props: { galleryItemId: string; id: string }) {
    const siteId = ensureNumber(props.id);
    this.siteId = siteId;
    this.galleryItemId = props.galleryItemId;

    this.attachMapState(this.mapstate());

    this.variables = {
      siteId
    };

    this.pendingTag = '';
    this.tagUpdateFailedText = '';
  }

  mapstate = () => (state: rootState): ISiteDetailsGalleryDetailsState => {
    return {
      timezone: getTimezoneForSiteSelector(state)
    };
  };

  tagUpdateFailedText = '';
  pendingTag = '';

  public async addTagToImage(
    galleryItemId: number,
    oldTags: string[],
    newTag: string
  ) {
    if (newTag.length === 0) return;
    const newTags = distinct([...oldTags, newTag], a =>
      a.trim().toLocaleLowerCase()
    );
    this.pendingTag = '';
    this.tagUpdateFailedText = '';
    try {
      await this.runQuery<
        SiteDetailsGalleryUpdateTagsMutation,
        SiteDetailsGalleryUpdateTagsMutationVariables
      >(
        SiteDetailsGalleryUpdateTagsMutationDeclaration,
        {
          galleryItemId: galleryItemId.toString(),
          tags: newTags
        }
      );
      await this.revalidateAllActiveQueries();
    } catch (err) {
      this.tagUpdateFailedText = err;
    }
  }

  public async removeTagFromImage(
    galleryItemId: number,
    oldTags: string[],
    tagToRemove: string
  ) {
    const newTags = oldTags.filter(tag => tag !== tagToRemove);
    this.tagUpdateFailedText = '';
    try {
      await this.runQuery<
        SiteDetailsGalleryUpdateTagsMutation,
        SiteDetailsGalleryUpdateTagsMutationVariables
      >(
        SiteDetailsGalleryUpdateTagsMutationDeclaration,
        {
          galleryItemId: galleryItemId.toString(),
          tags: newTags
        }
      );
      this.revalidateAllActiveQueries();
    } catch (err) {
      this.tagUpdateFailedText = err;
    }

  }

  public viewImageDetails(
    galleryImage: SiteDetailsGalleryDetailsQuery_site_galleryItems
  ) {
    this.router.navigateToRoute(
      'sitedetails-gallerydetails',
      { galleryItemId: galleryImage.siteGalleryItemId },
      { replace: true }
    );
  }

  public closeImageDetails() {
    this.isMobile
      ? this.router.navigateBack()
      : this.router.navigateToRoute('sitedetails-gallerylist', undefined, {
          replace: true
        });
  }

  public toggleImageDetailsInfo() {
    this.showInfo = !this.showInfo;
  }

  public downloadSelectedFile(imageToDownload: ISiteGalleryItemDetails) {
    window.open(imageToDownload.imageUrl);
  }

  public async deleteImage(imageToDelete: ISiteGalleryItemDetails) {
    await this.galleryService.deleteGalleryItem(
      imageToDelete.siteGalleryItemId
    );
    this.closeImageDetails();
    await this.revalidateAllActiveQueries();
  }

  public addListener() {
    PLATFORM.global.addEventListener('keydown', this.keyDown);
  }

  public removeListener() {
    PLATFORM.global.removeEventListener('keydown', this.keyDown);
  }

  public attached() {
    this.addListener();
  }

  public detached() {
    super.detached();
    this.removeListener();
  }

  keyDown = (event: KeyboardEvent) => {
    const { keyCode } = event;
    if (keyCode !== 37 && keyCode !== 39) return;
    const newSelectedImage =
      keyCode === 37 ? this.previousImage : this.nextImage;

    if (newSelectedImage) this.viewImageDetails(newSelectedImage);
  };
}
