import React, { FC } from 'react';

interface ITickProps {
  className?: string;
}

const Tick: FC<ITickProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 316.336 316.336"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path
      d="M123.993 193.378c-6.437-4.31-45.858-44.53-47.637-44.53-5.582 0-32.103 27.601-32.103 33.138 0 2.992 36.736 36.222 41.68 41.167 4.602 4.6 40.566 39.61 43.238 39.61 5.048 0 64.432-79.34 72.305-89.247 7.093-8.926 70.607-88.341 70.607-90.947v-1.035c0-3.227-31.719-27.961-34.175-27.961h-1.036c-4.739 0-49.715 61.534-56.705 69.637-10.429 12.091-48.371 64.943-56.174 70.168z"
      fill="#78a22f"
    />
  </svg>
);

export default Tick;
