import React, {FC, memo, MouseEvent, useCallback, useRef, useState} from "react";
import './map-panel-resizable.css';
import Icon from "$components/icons/icon/icon.react";

interface IMapPanelResizableProps {
  leftSlot: React.ReactNode;
  rightSlot?: React.ReactNode;
  minimumHeightPercent?: string;
}

let lastMouseX = 0;
const minLeftSlotWidth = 200;

function calcPercentage(newWidth: number, boundingWidth: number) {
  return ((newWidth / boundingWidth) * 100);
}

const MapPanelResizableComponent: FC<
  IMapPanelResizableProps
> = ({
  leftSlot,
  minimumHeightPercent = '0%',
  rightSlot = undefined
 }: IMapPanelResizableProps) => {

  const [leftSlotWidthPercent, setLeftSlotWidthPercent] = useState(rightSlot !== undefined ? 50 : 100);
  const containerRef = useRef<HTMLDivElement>(null);
  const leftSlotWrapperRef = useRef<HTMLDivElement>(null);
  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (!rightSlot || !leftSlot || !containerRef || !containerRef.current || !leftSlotWrapperRef || !leftSlotWrapperRef.current) return;
      if (e.clientX === lastMouseX) return;
      lastMouseX = e.clientX; // throttle the event to 1px

      const leftSlotWrapperRect = leftSlotWrapperRef.current.getBoundingClientRect();
      if (!leftSlotWrapperRect) return;

      const containerRect = containerRef.current.getBoundingClientRect();

      const leftOffset = leftSlotWrapperRect.left + leftSlotWrapperRef.current.offsetLeft;
      // + 4px to center the cursor on the divider
      let newWidth = e.clientX - leftOffset + 4;

      if (newWidth < minLeftSlotWidth) {
        newWidth = minLeftSlotWidth;
      }
      
      const maxWidth = containerRect.width - 200;
      if (newWidth > minLeftSlotWidth && newWidth < maxWidth) {
        const widthPercent = calcPercentage(newWidth, containerRect.width);
        setLeftSlotWidthPercent(widthPercent);
      }
    },
     [containerRef]
  );  
  
  return (
    <div className='map-panel-resizable flex row' style={{minHeight: minimumHeightPercent}} ref={containerRef}>
      <div className="left-slot-wrapper" ref={leftSlotWrapperRef} style={{ flex: `0 0 ${leftSlotWidthPercent}%` }}>
        {leftSlot}  
      </div>
      {!!rightSlot ? 
        <div className="vertical-resize-divider" onMouseDown={handleMouseDown}>
          <div className="inner-vertical-resize-divider">
            <Icon name={'fa-ellipsis-v'} />
          </div>
        </div> 
        : undefined}
      {rightSlot}
    </div>
  );
}

export const MapPanelResizable = memo(MapPanelResizableComponent);

