import Slider from '$components/slider/slider.react';
import { isSomething } from '$lib/helpers';
import React, { FC, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { ConditionalOperator, IFilterGroup, INumberFilter } from '../../../../../../src/interfaces';
import { ensureNumber } from '$lib/numberHelpers';
import { useHistogram } from './rangefilterUtills';
import { IReactGridFilterComponentProps, IReactGridRangeComponentProps } from '$components/grid/react-grid/grid.react';

const RangeFilter: FC<IReactGridFilterComponentProps> = ({ activeFilters, filterChanged, property, componentProps}) => {
  if (!componentProps) {
    throw Error('Missing componentProps on rangefilterdefinition');
  }
  const rangeFilterProps = componentProps as IReactGridRangeComponentProps
  
  const histogram = useHistogram(property, activeFilters, rangeFilterProps);
  const [t] = useTranslation();
  const activeFilter = activeFilters?.find(f => {
    return f.field === property;
  })
  
  const defaultTo = rangeFilterProps.initialTo || rangeFilterProps.max || 0;
  const [toValue, setToValue] = useState<number>(defaultTo);

  const defaultFrom = rangeFilterProps.initialFrom || rangeFilterProps.min || 0;
  const [fromValue, setFromValue] = useState<number>(defaultFrom);

  useEffect(() => {
    const fromFilter = (activeFilter?.filters as INumberFilter[])?.find(
      f => f.operator === ConditionalOperator.GreaterThan || f.operator === ConditionalOperator.Between
    );
    if (fromFilter) return setFromValue(fromFilter.value);

    return setFromValue(defaultFrom);
  }, [activeFilter]);

  useEffect(() => {
    const toFilter = (activeFilter?.filters as INumberFilter[])?.find(
      f => f.operator === ConditionalOperator.LesserThan || f.operator === ConditionalOperator.Between
    );
    if (toFilter) return setToValue(toFilter.secondValue || toFilter.value);

    return setToValue(defaultTo);
  }, [activeFilter]);

  function rangeChanged(values: { from: number; to: number; value: number[] }) {
    setToValue(values.to);
    setFromValue(values.from);
    debouncedNotifyChangedFilter(values.from, values.to);
  }

  const debouncedNotifyChangedFilter = useMemo(() => debounce(notifyChangedFilter, 500), []);

  function notifyChangedFilter(from: number, to: number) {
    const filters: INumberFilter[] = [];

    const min = rangeFilterProps.minMaxValuesRemoveFilter && rangeFilterProps.min === from ? undefined : from;
    const max = rangeFilterProps.minMaxValuesRemoveFilter && rangeFilterProps.max === to ? undefined : to;

    const bothMinAndMaxIsSet = isSomething(min) && isSomething(max);
    if (isSomething(min) && isSomething(max))
      filters.push({
        operator: ConditionalOperator.Between,
        symbol: '&&',
        value: min,
        secondValue: max
      });

    if (!bothMinAndMaxIsSet && isSomething(min)) {
      filters.push({
        operator: ConditionalOperator.GreaterThan,
        symbol: '>',
        value: min
      });
    }

    if (!bothMinAndMaxIsSet && isSomething(max)) {
      filters.push({
        operator: ConditionalOperator.LesserThan,
        symbol: '>',
        value: max
      });
    }

    const newfilter: IFilterGroup = { ...(activeFilter || { exclude: false, field: property, type: 'number', filters: [] }), filters };
    filterChanged && filterChanged(newfilter);
  }

  function stepIsInRange(index: number, from: number, to: number): boolean {
    const max = rangeFilterProps.max || 100;
    const perStep = max / 20;
    const fromStep = ensureNumber(from) / perStep;
    const toStep = ensureNumber(to) / perStep;
    return index >= fromStep && index <= toStep;
  }

  return (
    <div className="block fill-width">
      <div className="histogram flex" style={{ height: '100px', alignItems: 'flex-end' }}>
        {histogram.map((item, i) => (
          <div
            key={"histogramItem" + i}
            className="flex_1"
            style={{
              borderRadius: '3px',
              height: item,
              background: isSomething(fromValue) && isSomething(toValue) && stepIsInRange(i, fromValue, toValue) ? '#3c8dbc' : '#ccc',
              margin: '2px',
              transition: 'all 0.5s ease-in-out'
            }}
          ></div>
        ))}
      </div>
      <Slider
        style={{ height: '1.5rem' }}
        min={rangeFilterProps.min}
        max={rangeFilterProps.max}
        step={rangeFilterProps.step || 1}
        from={fromValue}
        to={toValue}
        onChanged={rangeChanged}
        range
      />

      <div className="mar_tm">
        {t('ui_sitelist_filter_range_from')}: {fromValue}, {t('ui_sitelist_filter_range_to')}: {toValue}
      </div>
    </div>
  );
};

export default RangeFilter;
