import {
  Controller,
  DeepPartial,
  FieldValues,
  Path,
  PathValue,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import React, { useCallback, useMemo, memo, useState } from 'react';
import EmailField from '$components/inputs/email-input/email-field.react';
import Checkbox from '$components/checkbox/checkbox.react';
import DatePicker from '$components/date-picker/date-picker.react';
import FormElement from '$components/forms/form-element/form-element.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import { Button, CardContent } from '@mui/material';
import { ICardFormEditorItem } from '../../card-form-editor';
import Icon from '$components/icons/icon/icon.react';
import { getNewId } from '$lib/incrementer';
import '../../card-form-editor.css';
import { NumberInput } from '$components/inputs/number-input/number-input.react';
import {
  getMsisdnFormValidation,
  MsisdnInput,
} from '$components/inputs/msisdn-input/msisdn-input.react';
import {
  getIccFormValidation,
  IccInput,
} from '$components/inputs/icc-input/icc-input.react';
import MuiTextField from '$components/textbox/mui-textfield.react';
import {
  getIpAddressFormValidation,
  IpAddressInput,
} from '$components/inputs/ipaddress-input/ipaddress-input.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

export interface IEditingFormProps<T extends FieldValues> {
  items: ICardFormEditorItem<T>[];
  data: DeepPartial<T>;
  saveText?: string;
  onSubmit: (setErrorMessage: (message: string) => unknown) => SubmitHandler<T>;
  onCancel: () => unknown;
}

const EditingFormComponent = <T extends FieldValues,>({
  items,
  data,
  saveText,
  onSubmit,
  onCancel,
}: IEditingFormProps<T>) => {
  const [t] = useCaseInsensitiveTranslation();
  const [generalFormError, setGeneralFormError] = useState<string | undefined>(
    ''
  );

  const formId = useMemo(() => {
    return 'cardFormEditor_' + getNewId();
  }, []);

  const useFormProps = useForm<T>({ defaultValues: data });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useFormProps;

  const onError = () => {
    setGeneralFormError(t('ui_common_error_save_item_failed'));
  };
  const getEditor = useCallback(
    (item: ICardFormEditorItem<T>) => {
      const disabled = item.allowEdit !== undefined && !item.allowEdit;
      const itemKey = (item.dataPropertyName ?? '') as Path<T>;
      const itemValue: PathValue<T, Path<T>> | unknown =
        item.dataPropertyName ? data[item.dataPropertyName] : undefined;

      if (item.allowEdit !== undefined && !item.allowEdit) {
        const itemValueAsReactNode = itemValue as React.ReactNode;
        return (
          <div>
            {item.valueLink ? (
              <a href={item.valueLink}>{itemValueAsReactNode}</a>
            ) : (
              itemValueAsReactNode
            )}
          </div>
        );
      }

      switch (item.type) {
        case 'email':
          if (item.validationOptions)
            console.warn(
              '(form-editor) fields of type email does not parse custom validation options!'
            );
          return (
            <EmailField
              register={register}
              name={itemKey}
              error={errors[itemKey]?.message as string | undefined}
              disabled={disabled}
            />
          );
        case 'checkbox':
          return (
            <Checkbox
              checked={itemValue as boolean | undefined}
              {...register(itemKey, item.validationOptions)}
              disabled={disabled}
            />
          );
        case 'datetime':
          return (
            <Controller
              name={itemKey}
              control={control}
              render={({ field }) => (
                <DatePicker
                  showTimeSelector
                  onChanged={(e) => field.onChange(e)}
                  selectedDate={
                    field.value as string | Date | undefined
                  }
                />
              )}
            />
          );
        case 'custom':
          return item.customEditComponent
            ? item.customEditComponent(useFormProps)
            : undefined;
        case 'ipaddress':
          return (
            <Controller
              name={itemKey}
              control={control}
              rules={getIpAddressFormValidation(t)}
              render={({ field }) => (
                <IpAddressInput
                  setValue={(value) => field.onChange(value)}
                  value={field.value as string | undefined}
                  disabled={disabled}
                  errorText={
                    errors[itemKey]?.message as string | undefined
                  }
                />
              )}
            />
          );
        case 'icc':
          return (
            <Controller
              name={itemKey}
              control={control}
              rules={getIccFormValidation(t)}
              render={({ field }) => (
                <IccInput
                  setValue={(value) => field.onChange(value)}
                  value={field.value as string | undefined}
                  disabled={disabled}
                  errorText={
                    errors[itemKey]?.message as string | undefined
                  }
                />
              )}
            />
          );
        case 'msisdn':
          return (
            <Controller
              name={itemKey}
              control={control}
              rules={getMsisdnFormValidation(t)}
              render={({ field }) => (
                <MsisdnInput
                  setValue={(value) => field.onChange(value)}
                  value={field.value as string | undefined}
                  disabled={disabled}
                  errorText={
                    errors[itemKey]?.message as string | undefined
                  }
                />
              )}
            />
          );
        case 'number':
          return (
            <Controller
              name={itemKey}
              control={control}
              rules={item.validationOptions}
              render={({ field }) => (
                <NumberInput
                  numberType={item.allowDecimal ? 'decimal' : 'integer'}
                  setValue={(value) => field.onChange(value)}
                  value={field.value as string | undefined}
                  min={item.minValue}
                  max={item.maxValue}
                  disabled={disabled}
                  errorText={
                    errors[itemKey]?.message as string | undefined
                  }
                />
              )}
            />
          );
        case 'text':
        default:
          return (
            <MuiTextField
              {...register(itemKey, item.validationOptions)}
              type={item.type ?? 'text'}
              disabled={disabled}
              errorText={errors[itemKey]?.message as string | undefined}
            />
          );
      }
    },
    [register, errors]
  );

  return (
    <>
      <CardContent
        component={'form'}
        id={formId}
        onSubmit={handleSubmit(onSubmit(setGeneralFormError), onError)}
        className="editing-form-card-content"
      >
        {items.map((item, index) => {
          return (
            <FormElement label={item.displayText} key={'formItem' + index}>
              {getEditor(item)}
            </FormElement>
          );
        })}
      </CardContent>
      {generalFormError && (
        <CardFooter topBorder>
          <ErrorText className="text-align-center">
            {generalFormError}
          </ErrorText>
        </CardFooter>
      )}
      <CardFooter topBorder={!generalFormError} className="flex jsb">
        <Button onClick={onCancel} variant="outlined" type="button">
          {t('ui_common_cancel')}
        </Button>
        <Button
          type="submit"
          form={formId}
          variant="contained"
          onClick={() => {
            setGeneralFormError(undefined);
          }}
          disabled={isSubmitting}
          startIcon={
            isSubmitting && <Icon name="fa-spinner fa-pulse" className="text" />
          }
        >
          {generalFormError
            ? t('ui_common_try_again')
            : saveText ?? t('ui_common_savechanges')}
        </Button>
      </CardFooter>
    </>
  );
};

export const EditingForm = memo(
  EditingFormComponent
) as typeof EditingFormComponent;
