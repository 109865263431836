import { RequestDocument } from 'graphql-request/dist/types';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useEffect, useState, useRef, useCallback } from 'react';
import {
  getCachedFetchResult,
  IFetchResult,
  subscribeQuery,
  runSingleQuery,
  revalidateAllActiveQueries,
  revalidateFn,
  getCacheKey,
  IFetchOptions,
  deleteCachedFetchResult
} from '$pages/common/GraphQLFetcher';

interface UseQueryReturnType<T> extends IFetchResult<T> {
  revalidate?: () => void
  loading: boolean
  deleteCachedData: () => boolean
}

export function useQuery<T = any, TV = Record<string, any>>(query: TypedDocumentNode<T, TV>, variables?: TV, fetchOptions?: IFetchOptions, numberOfRetries?: number, onError?: (e?: string, code?: string) => void): UseQueryReturnType<T>;
export function useQuery<T, TV = void>(query: RequestDocument, variables: TV | undefined = undefined,  fetchOptions?: IFetchOptions, numberOfRetries?: number, onError?: (e?: string, code?: string) => void) {
  const cacheKey = getCacheKey(query, variables)
  const cacheValue = getCachedFetchResult<T, TV>(query, variables);
  const initialState = cacheValue ?? { data: undefined, error: undefined, isRevalidating: true };
  const [state, setState] = useState<IFetchResult<T>>(initialState);
  const revalidateFunction = useRef<revalidateFn | undefined>(undefined)

  const revalidate = useCallback(() => {
    revalidateFunction.current && revalidateFunction.current()
  }, [])

  useEffect(() => {
    const { unsubscribe, revalidate } = subscribeQuery(query, variables, fetchOptions, setState, numberOfRetries, onError);
    revalidateFunction.current = revalidate
    return unsubscribe;
  }, [cacheKey]);

  return {...state, loading: !state.data && state.isRevalidating, revalidate: revalidate, deleteCachedData: () => deleteCachedFetchResult(query, variables) };
}

export async function mutate<T = any, TV = Record<string, any>>(query: TypedDocumentNode<T, TV>, variables: TV, revalidate?: boolean,  onSuccess?: (res: T) => void, onError?: (err: any) => void, numberOfRetries?: number): Promise<T>;
export async function mutate<T, TV = void>(query: RequestDocument, variables: TV, revalidate: boolean = false, onSuccess?: (res: T) => void, onError?: (err: any) => void, numberOfRetries?: number): Promise<T> {
    const { promise } = runSingleQuery<T, TV>(query, variables, numberOfRetries);
    promise.then((res) => {
      revalidate && revalidateAllActiveQueries()
      onSuccess && onSuccess(res)
    });
    onError && promise.catch((e) => onError(e))

  return promise;
}
