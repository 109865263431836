import React, { useState, FC, memo } from 'react';
import { Button, CardHeader, IconButton, Typography } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import { getSession } from '../../../../../../config/sessionService';
import { hasAccessToSecurityLevel } from '$lib/authorizationHelper';
import { SecurityLevel } from '$interfaces/enums/securityLevel';
import {
  GetControllerProfileValuesDocument,
  GetControllerProfilesDocument,
} from '$typings/graphql-codegen';
import { ControllerProfileList } from './modules/controller-profile-list';
import { FileUpload } from './modules/file-upload';
import { pureFormatDate } from '$lib/dateHelpers';
import { downloadDataAsExcel } from '$lib/excelHelpers';
import { ScrollableMuiCardContent } from '$components/cards/mui-card';
import { CardFlexColumn } from '$components/cards/mui-card';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { IStatusMessage } from '../controller-parameters/modules/common/status-message';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import { ControllerProfileExportInfo }from './controller-profiles-types';

export interface IControllerProfilesProps {
  controllerId: number;
  editProfile: (profileId: number) => unknown;
  readonly?: boolean
}

const ControllerProfilesComponent: FC<IControllerProfilesProps> = ({
  controllerId,
  editProfile,
  readonly
}) => {
  const [updateFromController, setUpdateFromController] = useState(false);
  const [t] = useCaseInsensitiveTranslation();
  const [isExportingProfileId, setIsExportingProfileId] = useState<
    number | undefined
  >(undefined);
  const [statusMessage, setStatusMessage] = useState<
    IStatusMessage | undefined
  >(undefined);

  const session = getSession();
  const accessToSecurityLevel = hasAccessToSecurityLevel(
    session,
    SecurityLevel.High
  );

  const { data, loading, error, revalidate, isRevalidating } = useQuery(
    GetControllerProfilesDocument,
    {
      controllerId: controllerId,
      fromController: updateFromController,
    },
    undefined,
    1
  );

  const refreshControllerProfiles = () => {
    if (updateFromController) {
      revalidate?.();
    } else {
      setUpdateFromController(true);
    }
  };
  const exportProfile = async (profile: ControllerProfileExportInfo) => {
    if (isExportingProfileId) return;
    setIsExportingProfileId(profile.controllerProfileId);

    await mutate(
      GetControllerProfileValuesDocument,
      {
        controllerId: profile.controllerId,
        profileId: profile.controllerProfileId,
      },
      undefined,
      async (r) => {
        if (r.controller) {
          const result = r.controller?.profileValues;
          const exportedDateString = pureFormatDate(result.timestamp, true, '.');
          const fileName = `ProfileExport_${result.profileName}_${exportedDateString}.xlsx`;

          await downloadDataAsExcel(
            [
              { title: 'SiteId', value: result.siteId },
              { title: 'ControllerId', value: controllerId },
              { title: 'Profile', value: result.profileName },
              { title: 'Exported', value: exportedDateString },
            ],
            ['Point', 'Value'],
            result.profileValues.map((point, index) => [index + 1, point]),
            fileName,
            {
              controllerId,
              controllerProfileId: profile.controllerProfileId,
              type: 'profile',
              version: 1,
            }
          );
        }
        setIsExportingProfileId(undefined);
      }
    );
  };

  return (
    <CardFlexColumn>
      <CardHeader
        title={
          <Typography variant="h6">
            {t(
              'UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_Header'
            )}
          </Typography>
        }
        action={!readonly &&
          <IconButton
            size="small"
            onClick={refreshControllerProfiles}
            disabled={loading || isRevalidating}
          >
            <Icon
              name={
                loading || isRevalidating ? 'fa-spinner fa-pulse' : 'fa-refresh'
              }
            />
          </IconButton>
        }
      />
      <ScrollableMuiCardContent>
        {loading ? (
          <LoadingBars center />
        ) : error ? (
          <div className="mar_m">
            <Typography variant="h6">
              {t('UI_Common_Error_Loading_Data_Failed_Headline')}
            </Typography>
            <Typography>{t('UI_Common_Error_Loading_Data_Failed')}</Typography>
            <Typography>
              {t('UI_Common_Error_Controller_May_Be_Unavailable')}
            </Typography>
            <Button variant="outlined" onClick={revalidate}>
              {t('UI_Common_Try_Again')}
            </Button>
          </div>
        ) : !data || !data.controller?.profiles.length ? (
          <div className="mar_rm mar_lm">
            <p>
              {t(
                'UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_None'
              )}
            </p>
            <Button
              variant="outlined"
              sx={{ marginTop: '1rem' }}
              onClick={refreshControllerProfiles}
            >
              {t(
                'UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_Actions_FetchProfiles'
              )}
            </Button>
          </div>
        ) : (
          <>
            <ControllerProfileList
              data={data}
              accessToSecurityLevel={accessToSecurityLevel}
              editProfile={editProfile}
              exportProfile={exportProfile}
              isExportingProfileId={isExportingProfileId}
              setStatusMessage={setStatusMessage}
              readonly={readonly}
            />
            <div className="flex column">
              {!readonly && <FileUpload />}
              {statusMessage && (
                <CardFooter topBorder className="flex jsb aicenter">
                  {statusMessage.message}
                  {statusMessage.dismissible && (
                    <IconButton onClick={() => setStatusMessage(undefined)}>
                      <Icon name={'fa-times'} />
                    </IconButton>
                  )}
                </CardFooter>
              )}
            </div>
          </>
        )}
      </ScrollableMuiCardContent>
    </CardFlexColumn>
  );
};

export const ControllerProfiles = memo(ControllerProfilesComponent);
