import React, { FC } from 'react';
import './stock-vs-dose-bar.css';
import classNames from "classnames";

interface IStockVsDoseBarProps {
  accuracy: number;
  className?: string
}

const StockVsDoseBar: FC<IStockVsDoseBarProps> = ({ accuracy, className }) => {
  const abs = Math.abs(accuracy - 100);
  const error = abs >= 25;

  return (
    <div className={classNames('stock-vs-dose-bar-component', className)}>
      <div className="flex">
        <div className={classNames('flex_1', error && 'text-error')}>
          { accuracy.toFixed(0) }%
        </div>
        <div className="flex_3 flex jccenter aicenter mar_ls">
          <div className="bar-wrapper">
            <div
              className={classNames(
                'bar-fill',
                error && 'error',
                accuracy - 100 > 0 ? 'stockvsdose-right' : 'stockvsdose-left'
              )}
              style={{ width: `${abs / 2}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockVsDoseBar;
