// generated with https://bareynol.github.io/mui-theme-creator/

import { createTheme, ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    yaraBrightBlue: SimplePaletteColorOptions;
  }
  interface PaletteColor {
    lighter?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}

/**
 * Please note that changing this theme will alter all related components!
 * So do some testing elsewhere in the code as well :)
 *
 * The default props will be overridden if you assign a value to that
 * specific prop locally when using a component from Mui (that has been
 * altered by the theme here). To include the default props and have
 * custom props at the same time you need to merge them.
 * Import the yara-theme in the component and merge with your own props.
 * See MuiTextField for reference: there the 'sx' prop is merged with the
 * defaultProps from the yara-theme.
 *
 *
 * styleOverrides always apply (I think) so use with caution. Can be
 * overridden with '!important' or nested '& .className'.
 *
 **/

const themeCfg = {
  typography: {
    fontFamily: '"YaraMaxLF-Light", Arial, sans-serif',
  },
  palette: {
    primary: {
      main: '#1574b5',
    },
    secondary: {
      main: '#ffb03b',
    },
    error: {
      main: '#f05050',
    },
    background: {
      default: '#F7FAFC',
    },
    yaraBrightBlue: {
      main: "#6ab1e1",
      light: "#a6cbec",
      lighter: "#d0e2f4",
      contrastText: "#605c5a  "
    },
  },
  spacing: 8,
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
          backgroundColor: '#404040',
          color: '#f5f8fa'
        }
      }
    },
    MuiButtonGroup: {
      defaultProps: { 
        disableRipple: true 
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: 'transparent',
          },
          textTransform: 'none',
          lineHeight: 'unset',
          padding: '0.75rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          border: '1px solid #1574b5',
          backgroundColor: '#ffffff',
          ':hover': {
            border: '1px solid #1574b5',
            backgroundColor: '#ffffff',
            boxShadow: '0 1px 3px rgb(0 0 0 / 40%)',
          },
          boxShadow: '0 1px 1px rgb(0 0 0 / 20%)',
        },
        root: {
          textTransform: 'none',
          fontSize: '14px',
        },
        contained: {
          boxShadow: '0 1px 1px rgb(0 0 0 / 20%)',
          ':hover': {
            boxShadow: '0 1px 3px rgb(0 0 0 / 40%)',
            backgroundColor: '',
          },
          ':disabled': {
            backgroundColor: 'rgba(21,116,181,0.5)',
            color: '#FFF',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
        sx: {
          fontSize: '1rem',
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        }
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#ddd',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#a5a5a5',
            backgroundColor: '#e6e6e6',
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#F7FAFC',
          minWidth: '100%',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ddd',
            },
            '&:hover fieldset': {
              borderColor: '#ddd',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#ddd',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        /*
         * For some reason this is not accepted by TS, but it works
         * though? alternative is to add a css to every instance
         * of MuiSelect. -- see 'security-level-dropdown.css' for an
         * example
         */
        root: {
          '&:hover fieldset': {
            '&.MuiOutlinedInput-notchedOutline': {
              borderColor: '#ddd',
            },
          },
          '&:visited fieldset': {
            '&.MuiOutlinedInput-notchedOutline': {
              borderColor: '#ddd',
            },
          },
          '&.Mui-focused fieldset': {
            '&.MuiOutlinedInput-notchedOutline': {
              borderColor: '#ddd',
            },
          },
        },
        select: {
          backgroundColor: '#F7FAFC',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#1574b5',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 0 2px 1px rgba(0,0,0,0.1), 0 8px 16px 0 rgba(0,0,0,0.1)',
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderRadius: '20%',
        },
      },
    },
  },
  shape: {
    borderRadius: 3,
  },
};

const theme: ThemeOptions = createTheme(themeCfg as ThemeOptions);

export default theme;
