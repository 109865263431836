import { default as React, FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { ListRange, TableVirtuoso, VirtuosoHandle } from 'react-virtuoso';
import { columnKey } from '$components/grid/grid';
import Icon from '$components/icons/icon/icon.react';
import { usePersistedParsedState } from '$lib/hooks/usePersistedState';
import { distinct, removeNoneFromArray } from '$lib/arrayHelpers';
import { isNone, isSomething } from '$lib/helpers';
import './grid.react.css';
import { IFilterGroup } from 'src/interfaces';
import { ElasticSearchPage } from '$typings/graphql';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Checkbox from '$components/checkbox/checkbox.react';
import GridActionbar from '$components/flex-grid/flex-grid-actionbar/grid-actionbar.react';
import { UseGridDataReturnType } from './gridhelper';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import { SortDirection } from '$typings/graphql-codegen';
import { ColumnWidthChanged, GridTableHeaderCell } from './grid-table-header-cell/grid-table-header-cell.react';
import { FilterPopover } from '$pages/sitelistpage-react/modules/filters/column-filter-poppover.react';
import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';
import Tooltip from '$components/tooltip/tooltip.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { GridEditPanel } from './grid.column-edit.react';
import classNames from "classnames";

const rememberedScrollPosition: Map<string, number | undefined> = new Map<string, number>();

type GridProps<TItem> = {
  name: string;
  items: UseGridDataReturnType<TItem>;
  columns: IReactGridColumn<TItem>[];
  selectedColumns?: columnKey[];
  selectedColumnsChanged?: React.Dispatch<React.SetStateAction<any[]>>;
  columnEditMode?: boolean;
  sortedColumnKey: columnKey;
  sortedDirection?: SortDirection;
  activeFilters?: IFilterGroup[];
  filtersChanged?: (filters: IFilterGroup[] | undefined) => void;
  sortChanged?: (sortedColumnKey: columnKey, sortedDirection: SortDirection) => void;
  selectableRowsConfig?: IReactGridSelectableRowsConfig<TItem>;
  showGroupedHeader?: boolean;
  highlightText?: string | string[];
  freeTextQuery?: string[];
  loading?: boolean;
  rowClass?: string | ((item: unknown) => string | undefined);
  rowLink?: (row: TItem) => void | string | undefined;
  onRowClick?: (row: TItem) => (event?: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => unknown;
  className?: string;
  showBackToTopButton?: boolean
  rememberScroll?: boolean;
  highlightRowOnHover?: boolean;
  autoWidth?: boolean
  noMatchingRowsLanguageKey?: JSX.Element | string;
  hideActionBar?: boolean;
};

export type rowKey = unknown;
export interface IReactGridSelectableRowsConfig<TItem> {
  rowKey: (row: TItem) => rowKey[];
  selectedRowsChanged: (selectedRows: rowKey[], rowData?: TItem[]) => void;
  selectedRows: rowKey[];
  fetchAllSelectableRowIds: () => Promise<rowKey[]>;
  selectText?: string;
  selectSingleRow?: boolean;
  actionButtons: React.ReactNode;
}

export type IReactGridFilterComponentProps = {
  resetCalled: () => void;
  filterChanged: (group: IFilterGroup) => void;
  activeFilters: IFilterGroup[] | undefined;
  freeTextQuery: string[] | undefined;
  property: string;
  componentProps?: IReactGridComponentProps;
};

type IReactGridComponentProps = IReactGridRangeComponentProps | IReactGridRefComponentProps | IReactGridBooleanFilterProps;

export type IReactGridRangeComponentProps = {
  min: number;
  max: number;
  step: number;
  initialFrom: number;
  initialTo: number;
  minMaxValuesRemoveFilter: boolean;
  page: ElasticSearchPage;
};

export type IReactGridRefComponentProps = {
  page: ElasticSearchPage;
  dynamicHeight?: boolean;
};

type IReactGridBooleanFilterPropsValues = {
  value: boolean | undefined;
  header: string;
  default?: boolean;
  sub: string;
};

export type IReactGridBooleanFilterProps = {
  values: IReactGridBooleanFilterPropsValues[];
};

export type IReactGridFilter = {
  name: string;
  property: string;
  component?: FC<IReactGridFilterComponentProps>;
  componentProps?: IReactGridComponentProps;
};

export interface IReactGridColumn<T> {
  columnKey: columnKey;
  render: (item: T, width?: number | string, highlightText?: string | string[]) => React.ReactNode;
  hasOwnClickHandler?: boolean;
  columnTitle: string;
  initialWidth: number | string;
  managementGroup?: string;
  required?: boolean;
  filter?: IReactGridFilter;
  sortingDisabled?: boolean;
}

export type ColumnWidthRegister = Record<string, number | string | undefined>;

function getColumnWidthCacheKey(namespace: string) {
  return `${namespace}-columnWidths`;
}

export default function Grid<TItem>({
  name,
  items,
  columns,
  selectedColumns,
  selectedColumnsChanged,
  sortedColumnKey,
  sortedDirection = SortDirection.Asc,
  sortChanged,
  columnEditMode,
  activeFilters,
  filtersChanged,
  selectableRowsConfig,
  showGroupedHeader = false,
  highlightText, 
  freeTextQuery,
  loading,
  rowClass,
  className,
  rowLink,
  onRowClick,
  showBackToTopButton = true,
  rememberScroll,
  highlightRowOnHover = true,
  autoWidth = false,
  noMatchingRowsLanguageKey = 'UI_Grid_NoMatch',
  hideActionBar = false
}: React.PropsWithChildren<GridProps<TItem>>): React.ReactElement {  
  const ref = useRef<VirtuosoHandle>(null);
  const tableScrollerRef = useRef<HTMLElement | Window | null>(null);
  const [t] = useCaseInsensitiveTranslation();
  const lastViewedItems = useRef<ListRange>();
  const [columnWidth, setColumnWidth] = usePersistedParsedState<ColumnWidthRegister>(getColumnWidthCacheKey(name), {});
  const [filterIsShownForColumn, setFilterIsShownForColumn] = useState<columnKey | undefined>();
  const [lastSelectedRowIndex, setLastSelectedRowIndex] = useState<number | undefined>(undefined);
  const [lastSelectedColumnsLength, setLastSelectedColumnsLength] = useState<number | undefined>(undefined);
  const [selectableRows, setSelectableRows] = useState<rowKey[]>([]);
  const [backToTopButtonVisible, setBackToTopButtonVisible] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)
  const [flashingRowDidRun, setFlashingRowDidRun] = useState(false)
  const selectedColumnsWithDefinition = useMemo(() => removeNoneFromArray(selectedColumns ? selectedColumns?.map(s => columns.find(c => c.columnKey === s)) : columns), [
    columns,
    selectedColumns
  ]);

  const managmentGroups = useMemo(() => getColumnGroups(selectedColumnsWithDefinition), [selectedColumnsWithDefinition]);

  async function getSelectedRows(key: rowKey, event: React.MouseEvent, index: number): Promise<rowKey[]> {
    if (!event.shiftKey) return [key];
    if (isNone(lastSelectedRowIndex)) return [key];

    // Check if we have the data already for this selection
    const from = Math.min(index, lastSelectedRowIndex);
    const to = Math.max(index, lastSelectedRowIndex) + 1;

    const fromData = items.data?.slice(from, to);

    if (fromData?.every(isSomething) && selectableRowsConfig) return fromData.flatMap(selectableRowsConfig.rowKey);

    const fromSelectableRows = selectableRows.slice(from, to);

    if (isSomething(fromSelectableRows)) return fromSelectableRows;

    if (selectableRowsConfig?.fetchAllSelectableRowIds) {
      const selectableRows = await selectableRowsConfig?.fetchAllSelectableRowIds();
      setSelectableRows(selectableRows);
      return selectableRows.slice(from, to);
    }

    throw Error('Could not fetch correct rowkey');
  }

  //Selecting one row at a time
  async function toggleSelectedRow(key: rowKey, event: React.MouseEvent, index: number) {
    if (!selectableRowsConfig) return;

    const rowData = items.data?.find(r => selectableRowsConfig.rowKey(r)[0] === key);
    
    if (selectableRowsConfig.selectSingleRow) {
      return selectableRowsConfig.selectedRowsChanged(selectableRowsConfig.selectedRows.includes(key) ? [] : [key], rowData ? [rowData] : undefined);
    }
    setLastSelectedRowIndex(index);

    const currentlySelectedRows = await getSelectedRows(key, event, index);

    if (selectableRowsConfig.selectedRows.includes(key)) {
      //Remove previously checked      
      return selectableRowsConfig.selectedRowsChanged(selectableRowsConfig.selectedRows.filter(f => !currentlySelectedRows.includes(f)), rowData ? [rowData] : undefined);
    } else {
      //Add newly checked
      return selectableRowsConfig.selectedRowsChanged(distinct([...selectableRowsConfig.selectedRows, ...currentlySelectedRows]), rowData ? [rowData] : undefined);
    }
  }

  function getColumnGroups(columns: IReactGridColumn<TItem>[]) {
    const groups: Array<IReactGridColumn<TItem>[]> = [];

    for (const column of columns) {
      if (groups?.length == 0) {
        groups.push([column]);
        continue;
      }
      const lastGroup = groups[groups?.length - 1];
      const [first] = lastGroup;
      if (first.managementGroup === column.managementGroup) lastGroup.push(column);
      else groups.push([column]);
    }

    return groups;
  }

  function groupIndexForColumn(groups: Array<IReactGridColumn<TItem>[]>, column: IReactGridColumn<TItem>) {
    let index = 0;
    if (!groups) return 0;
    for (const group of groups) {
      if (group.includes(column)) return index;
      index++;
    }
    return 0;
  }

  const toggleAllRows = async (setToChecked: boolean) => {
    if (!selectableRowsConfig || !selectableRows || selectableRowsConfig.selectSingleRow) return;
    const selectableRowKeys = await selectableRowsConfig?.fetchAllSelectableRowIds();
    setSelectableRows(selectableRowKeys);

    if (setToChecked)  {
      const selectedRowsKeys = distinct([...selectableRowsConfig.selectedRows, ...selectableRowKeys]);
      const selectedRowsData = items.data?.filter(d => selectedRowsKeys.includes(selectableRowsConfig.rowKey(d)[0]));
            
      return selectableRowsConfig.selectedRowsChanged(selectedRowsKeys, selectedRowsData);
    }
    else {
      const selectedRowsKeys = selectableRowsConfig.selectedRows.filter(f => !selectableRowKeys?.includes(f));
      const selectedRowsData = items.data?.filter(d => selectedRowsKeys.includes(selectableRowsConfig.rowKey(d)[0]));
     
      return selectableRowsConfig.selectedRowsChanged(selectedRowsKeys, selectedRowsData);
    }
  };

  const isAllSelected = useCallback(() => {
    if (!selectableRows || selectableRows?.length < 1) return false;
    return selectableRows.every(r => selectableRowsConfig?.selectedRows.includes(r));
  }, [selectableRows, selectableRowsConfig?.selectedRows]);

  const isRowSelected = useCallback(
    (rowKey: rowKey) => {
      return selectableRowsConfig?.selectedRows.includes(rowKey);
    },
    [selectableRowsConfig?.selectedRows]
  );

  const sortByColumn = useCallback<(columnKey: columnKey) => void>(
    columnKey => {
      const newSortOrder =
        sortedColumnKey !== columnKey ? SortDirection.Asc : sortedDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
        sortChanged && sortChanged(columnKey, newSortOrder);
    },
    [sortedColumnKey, sortedDirection, sortChanged]
  );

  useEffect(() => {
    if (lastSelectedColumnsLength && selectedColumns && lastSelectedColumnsLength < selectedColumns?.length) {
      tableScrollerRef.current && ref.current?.scrollTo({ left: (tableScrollerRef.current as Element).scrollWidth });
    }

    setLastSelectedColumnsLength(selectedColumns?.length);
  }, [selectedColumns?.length]);

  const clearRememberScroll = () => {
    rememberedScrollPosition.set(name, undefined)
  }
 
  const columnWidthChanged = useCallback<ColumnWidthChanged>(
    (key, width) => {
      setColumnWidth(existing => ({ ...existing, [key]: width }));
    },
    [setColumnWidth]
  );

  useEffect(() => {
    if (initialLoad){
      clearRememberScroll()
    }
  }, [sortedColumnKey, sortedDirection, items?.cacheKey ?? '']);

  useEffect(() => {
    if (ref.current) ref.current.scrollToIndex(0);
  }, [sortedColumnKey, sortedDirection, items?.cacheKey ?? '']);

  useEffect(() => {
    if (initialLoad || !items?.data) return;
    
    const scrollIndex = rememberedScrollPosition.get(name);
    if (ref.current && rememberScroll && scrollIndex) {
      setTimeout(() => ref.current?.scrollToIndex({index: scrollIndex, align: "center"}), 1)
    }
    
    setInitialLoad(true)
    setTimeout(() => setFlashingRowDidRun(true), 2500);
  }, [ref.current, items])

  const closeFilter = useCallback(() => setFilterIsShownForColumn(undefined), []);
  const filterIconClicked = useCallback(column => setFilterIsShownForColumn((c: rowKey) => (c === column ? undefined : column)), []);

  const moveRow = (dragIndex: number, dropedOverIndex: number) => {
    selectedColumnsChanged && selectedColumnsChanged(existing => {
      const draggedColumnKey = existing[dragIndex];
      existing.splice(dragIndex, 1);
      existing.splice(dropedOverIndex, 0, draggedColumnKey);
      return [...existing];
    });
  };

  function getClassForRow(row: unknown) {
    if (!rowClass) return;
    if (typeof rowClass === 'string') return rowClass;
    return rowClass(row);
  }

  interface IInnerItem {
    index: number,
    data: TItem
  }

  const InnerItem: FC<IInnerItem> = React.memo(({index, data}) => {
    const link = rowLink && rowLink(data);
    const onClickAction = onRowClick && onRowClick(data);
    return (
      <>
        {selectableRowsConfig?.rowKey && (
          <td className="selectbox-cell flex column">
            {selectableRowsConfig.rowKey(data).map(rk => (
              <Checkbox
                key={`${rk}`}
                className="flex jccenter checkbox"
                onClick={e => toggleSelectedRow(rk, e, index)}
                checked={isRowSelected(rk)}
              />
            ))}
          </td>
        )}
        {selectedColumnsWithDefinition.map(column => {
          if (!data) {
            return <td key={column.columnKey + index} style={{ height: 44 }} />;
          }
          const cell = column.render(data, autoWidth ? "auto" : columnWidth[column.columnKey] ?? column.initialWidth, highlightText);
          return (
            <td 
              key={column.columnKey + index}
              style={{ verticalAlign: 'top' }}
              onMouseDown={() => rememberScroll && rememberedScrollPosition.set(name, index)}
              onClick={(e) => {
                if (!column.hasOwnClickHandler) onClickAction?.(e);
              }}
            >
              {(link && !column.hasOwnClickHandler) ? (
                <a
                  href={link}
                  style={{
                    color: 'inherit',
                    textDecoration: 'inherit',
                    cursor: 'pointer',
                  }}
                >
                  {cell}
                </a>
              ) : cell}
            </td>
          );
        })}
        <td style={{height: "44px"}} />{/* hacky solution for fixing rows with 0px height in controllerlist */}
      </>
    ); 
  })
  
  const NoMatchContent: string | JSX.Element = (() => {
    if (typeof noMatchingRowsLanguageKey === 'string') {
      return t(noMatchingRowsLanguageKey)
    }
    return noMatchingRowsLanguageKey
  })()

  const itemContent = (index: number, data: TItem) => {
    return <InnerItem index={index} data={data}/>
  }
  
  const columnHasActiveFilter = (fieldName: string, activeFilters?: IFilterGroup[]): boolean => {
    if (!activeFilters) return false;
    return activeFilters.find(filterGroup => filterGroup.field === fieldName) !== undefined;
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex_1 fill-height grid-react">
        <TableVirtuoso
          data={items?.data ?? []}
          ref={ref}
          scrollerRef={scrollref => (tableScrollerRef.current = scrollref)}
          rangeChanged={i => {
            if (i.startIndex > 0) {
              items.visibleItemsChanged(i)
            }
            setBackToTopButtonVisible(i.startIndex > 1);
            lastViewedItems.current = i
          }}
          style={{ flex: '1 0 0px' }}
          increaseViewportBy={{
            top: 50,
            bottom: 0
          }}
          className={className}
          components={{
            EmptyPlaceholder: () => {
              return <tbody><tr className="grid-loading">{loading ? <td><LoadingBars /></td> : <td>{NoMatchContent}</td>}</tr></tbody>;
            },
            TableRow: props => {
              const index = props['data-index'];
              const row = items.data && items.data[index];
              return <tr {...props} key={"trKey" + index} className={classNames(
                getClassForRow(row),
                index % 2 == 0 ? 'even' : 'odd',
                rememberScroll && index === rememberedScrollPosition.get(name) && !flashingRowDidRun ? 'flash' : '',
                highlightRowOnHover && 'highlight-on-hover'
              )} />;
            },
            FillerRow: ({ height }) => {
              // + 1 for hidden col at the end to enable column rearrange/resize
              let colspan = selectedColumnsWithDefinition?.length + 1; 
              if (selectableRowsConfig !== undefined) colspan++;
              
              return (
                <tr>
                  <td
                    colSpan={colspan}
                    style={{ height: height, padding: 0, border: 0 }}
                  />
                </tr>
              );
            }
          }}
          fixedHeaderContent={() => (
            <>
              {showGroupedHeader && (
                <tr>
                  {selectableRowsConfig && <th className={classNames(showGroupedHeader ? 'oddgroup' : 'evengroup')} />}
                  {managmentGroups.map((columnsInGroup, i) => (
                    <th key={"managmentGroups" + i} className={classNames(i % 2 === 0 ? 'evengroup' : 'oddgroup')} colSpan={columnsInGroup?.length}>
                      <div className="pad_m pad_bs text-align-left">
                        {columnsInGroup[0].managementGroup && t(columnsInGroup[0].managementGroup)}
                      </div>
                    </th>
                  ))}
                  <th></th>
                </tr>
              )}
              <tr>
                {selectableRowsConfig && (
                  <th className={classNames('relative', 'selectbox-cell', showGroupedHeader ? 'oddgroup' : 'evengroup')}>
                    {!selectableRowsConfig?.selectSingleRow &&
                      (selectableRows ? (
                        <Checkbox
                          onClick={() => toggleAllRows(!isAllSelected())}
                          checked={isAllSelected()}
                          className="selectbox-cell flex jccenter pad_m"
                        />
                      ) : (
                        <Icon name="fa-spinner fa-pulse" />
                      ))}
                  </th>
                )}
                {selectedColumnsWithDefinition.map((column, i, columns) => (
                  <GridTableHeaderCell
                    key={i}
                    moveRow={moveRow}
                    columnIndex={i}
                    className={groupIndexForColumn(managmentGroups, column) % 2 === 0 ? 'evengroup ' : 'oddgroup '}
                    filterIconClicked={filtersChanged && filterIconClicked}
                    sortedColumnKey={sortedColumnKey}
                    sortedDirection={sortedDirection}
                    widthChanged={columnWidthChanged}
                    width={autoWidth ? "100%" : columnWidth[column.columnKey] || column.initialWidth}
                    editMode={!!columnEditMode}
                    columnKey={column.columnKey}
                    popover={<FilterPopover filterIsShownForColumn={filterIsShownForColumn} closeCalled={closeFilter} column={column} columns={columns} i={i} activeFilters= {activeFilters || []} filtersChanged={filtersChanged} freeTextQuery={freeTextQuery} />}
                    sortClicked={sortChanged && sortByColumn}
                    hasFilters={!!column.filter}
                    hasActiveFilter={column.filter && columnHasActiveFilter(column.filter.property, activeFilters)}
                    sortingDisabled={column.sortingDisabled}
                  >
                    {t(column.columnTitle)}
                  </GridTableHeaderCell>
                ))}
                <th></th>
              </tr>
            </>
          )}
          itemContent={itemContent}
        />
        {columnEditMode && selectedColumnsChanged && selectedColumns &&  (
          <GridEditPanel selectedColumnsChanged={selectedColumnsChanged} columns={columns} selectedColumns={selectedColumns}></GridEditPanel>
        )}

        {showBackToTopButton && <div className={classNames('scroll-to-top-button', backToTopButtonVisible ? 'fade-in' : 'fade-out')}>
          <Tooltip text={t("UI_Grid_ScrollTop")} placement='left' delay={2000}>
            <ClickableIcon onClick={() => ref.current?.scrollToIndex({ index: 0, behavior: "smooth" })} name='fa-arrow-up' />
          </Tooltip>
        </div>}
      </div>
      {!hideActionBar && selectableRowsConfig && isSomething(selectableRowsConfig.selectedRows) && selectableRowsConfig.selectedRows?.length > 0 && (
        <GridActionbar
          selectedText={selectableRowsConfig?.selectText || 'ui_sitelist_selectionbar_description'}
          count={selectableRowsConfig?.selectedRows?.length}
          clearSelection={() => selectableRowsConfig.selectedRowsChanged([], [])}
          actionButtons={selectableRowsConfig.actionButtons}
        />
      )}
    </DndProvider>
  );
}
