import React, { memo, FC } from 'react';
import { GetControllerOutputCalibrationsQuery } from '$typings/graphql-codegen';
import {
  Row,
  NameCell,
  ValueCell,
  RowList,
  ListWrapper,
} from '../../../../common/profile-and-parameter-list';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { IStatusMessage } from '../common/status-message';
import { OutputCalibrationValueUpdater } from '../output-calibration-value';
import { getUserFeatures } from '../../../../../../../../config/sessionService';

interface IControllerOutputCalibrationListProps {
  data: GetControllerOutputCalibrationsQuery;
  showHistory: (outputCalibrationId?: number, name?: string) => unknown;
  setStatusMessage: (statusMessage?: IStatusMessage) => unknown;
}

const ControllerOutputCalibrationListComponent: FC<IControllerOutputCalibrationListProps> = ({
  data,
  showHistory,
  setStatusMessage,
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const features = getUserFeatures();
  const hasWriteAccess = features.controllerOutputCalibrations.write;

  return (
    <>
      <ListWrapper>
        <Row>
          <NameCell fontWeight={'bold'}>
            {t(
              'ui_sitedetails_controllers_controllerdetails_controlleroutputcalibrations_table_headers_name'
            )}
          </NameCell>
          <ValueCell fontWeight={'bold'} sx={{ textAlign: 'end' }}>
            {t(
              'ui_sitedetails_controllers_controllerdetails_controlleroutputcalibrations_table_headers_value'
            )}
          </ValueCell>
        </Row>

        <RowList>
          {data?.controller?.outputCalibrations.map((oc, i) => (
            <Row
              key={oc.controllerOutputCalibrationId}
              bgcolor={i % 2 === 0 ? '#FFF' : '#fafcfe'}
            >
              <NameCell>{oc.alias}</NameCell>

              <OutputCalibrationValueUpdater
                controllerOutputCalibrationId={oc.controllerOutputCalibrationId}
                allowEdit={hasWriteAccess}
                controllerId={oc.controllerId}
                setStatusMessage={setStatusMessage}
                initialValue={oc.value}
                showHistory={() => showHistory(oc.controllerOutputCalibrationId, oc.alias)}
              />
            </Row>
          ))}
        </RowList>
      </ListWrapper>
    </>
  );
};

export const ControllerOutputCalibrationList = memo(ControllerOutputCalibrationListComponent);
