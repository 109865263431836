import React, { FC } from 'react';

interface ISignalGoodProps {
  className?: string;
}

const SignalGood: FC<ISignalGoodProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 114.005 114.005"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <circle fill="#78a22f" cx="56.691" cy="48.682" r="10.384" />
    <rect x="50.021" y="62.497" width="13.341" height="37.818" rx="6.044" ry="6.044" fill="#7d6a55" />
    <path
      fill="#78a22f"
      d="M89.165 15.213c8.953 8.49 14.54 20.495 14.54 33.805 0 13.8-6.006 26.198-15.544 34.728a6.784 6.784 0 0 1-4.445.65 6.694 6.694 0 0 1-3.912-2.312c-2.358-2.853-1.971-7.134.882-9.492 6.035-6.033 9.77-14.368 9.77-23.574 0-9.203-3.732-17.535-9.763-23.567-2.82-2.33-3.208-6.545-.877-9.364 2.33-2.82 6.53-3.204 9.349-.874zM24.84 83.073C15.887 74.583 10.3 62.578 10.3 49.27c0-13.8 6.006-26.199 15.543-34.73a6.784 6.784 0 0 1 4.446-.649 6.694 6.694 0 0 1 3.911 2.312c2.358 2.853 1.972 7.134-.88 9.492-6.037 6.034-9.771 14.368-9.771 23.575 0 9.202 3.731 17.534 9.763 23.566 2.82 2.33 3.207 6.545.877 9.364-2.33 2.82-6.53 3.205-9.349.874z"
    />
    <path
      fill="#78a22f"
      d="M43.712 38.563c1.527-1.941 1.532-4.754-.116-6.661-1.872-2.166-5.25-2.399-7.45-.497a26.587 26.587 0 0 0-6.183 17.083c0 6.494 2.32 12.446 6.176 17.075 2.2 1.902 5.544 1.672 7.416-.494 1.632-1.888 1.663-4.688.18-6.627a16.241 16.241 0 0 1-3.389-9.954 16.24 16.24 0 0 1 3.366-9.924zm25.905.047c-1.527-1.942-1.532-4.755.116-6.661 1.872-2.167 5.25-2.4 7.45-.498a26.587 26.587 0 0 1 6.183 17.083c0 6.494-2.32 12.447-6.176 17.075-2.2 1.902-5.544 1.672-7.416-.494-1.632-1.888-1.663-4.687-.18-6.627a16.241 16.241 0 0 0 3.389-9.954 16.24 16.24 0 0 0-3.366-9.924z"
    />
  </svg>
);

export default SignalGood;
