import React, { FC, MouseEventHandler } from 'react';
import Icon, { IIconProps } from '../icon/icon.react';
import "./icon-clickable.css"
import classNames from "classnames";

interface IClickableIconProps extends IIconProps{
  onClick: MouseEventHandler<HTMLDivElement>;
}

const ClickableIcon: FC<IClickableIconProps> = (props) => (
  <div
    onClick={props.onClick}
    className={classNames('clickable', props.className)}>
    <Icon name={classNames(props.name)} />
  </div>
);

export default ClickableIcon;
