import React, { FC } from 'react';
import './simple-table-cell.css'
import classNames from "classnames";

interface ISimpleTableCellProps {
  className?: string
  children?: React.ReactNode;
}

const SimpleTableCell: FC<ISimpleTableCellProps> = ({children, className}) => (
    <div className={classNames('simple-table-cell-component', className)}>{children}</div>
  )
export default SimpleTableCell;
