import { useState, useLayoutEffect, MutableRefObject } from 'react';

export default function(ref: MutableRefObject<Element | null>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => {
    return setIntersecting(entry.isIntersecting);
  });

  useLayoutEffect(() => {
    if (!ref.current) return;
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  return isIntersecting;
}
