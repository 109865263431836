import React, { FC, memo } from 'react';
import './mobile-tabs.css';
import classNames from 'classnames';

interface IMobileTabsProps {
  children: React.ReactNode;
  topShadow?: boolean;
}

const MobileTabsComponent: FC<IMobileTabsProps> = ({
  children,
  topShadow = false,
}) => (
  <div
    className={classNames(
      'mobile-tabs-component',
      'radio-button-tabs',
      topShadow && 'top-shadow'
    )}>
    { children }
  </div>
);

export const MobileTabs = memo(MobileTabsComponent);
