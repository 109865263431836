import React, { FC, memo, useState } from 'react';
import { SecurityLevelMenu } from '$components/security-level/security-level-menu/security-level-menu';
import { IStatusMessage } from '$pages/common/controllers/controllerdetails-react/modules/controller-parameters/modules/common/status-message';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { mappedSecurityLevels } from '$components/security-level/common/security-level-definitions';
import { UpdateControllerProfileSecurityLevelDocument } from '$typings/graphql-codegen';
import { mutate } from '$lib/hooks/fetch-utillities';

interface ISecurityLevelEditProps {
  controllerId: number;
  controllerProfileId: number;
  setStatusMessage: (status: IStatusMessage | undefined) => unknown;
  initialValue: number;
}

const SecurityLevelEditComponent: FC<ISecurityLevelEditProps> = ({
  controllerId,
  controllerProfileId,
  setStatusMessage,
  initialValue,
}: ISecurityLevelEditProps) => {
  const [securityLevel, setSecurityLevel] = useState(initialValue);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [t] = useCaseInsensitiveTranslation();

  const setLevel = async (level: number) => {
    setIsSubmitting(true);

    const successMessage = t(
      'UI_SiteDetails_Controllers_ControllerDetails_ControllerParameters_SecurityLevelChanged',
      {
        before: mappedSecurityLevels[initialValue].displayText,
        after: mappedSecurityLevels[level].displayText,
      }
    );

    setTimeout(() => setIsSubmitting(false), 1000);
    // mutate && revalidate
    try {
      await mutate(
        UpdateControllerProfileSecurityLevelDocument,
        {
          controllerId,
          controllerProfileId,
          securityLevel: level,
        },
        true,
        ({ updateControllerProfileSecurityLevel: { message, success } }) => {
          if (success) {
            setSecurityLevel(level);
            setStatusMessage({
              error: false,
              dismissible: true,
              message: successMessage,
            });
          } else if (message) {
            setStatusMessage({
              error: true,
              dismissible: true,
              message: message,
            });
          } else {
            setStatusMessage({
              error: true,
              dismissible: true,
              message: t('UI_Common_Error_Save_Item_Failed'),
            });
          }
        }
      );
    } catch {
    } finally {
      setIsSubmitting(false);
    }
  };


  return (
    <>
      <SecurityLevelMenu
        allowEdit
        level={securityLevel}
        setLevel={setLevel}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export const SecurityLevelEdit = memo(SecurityLevelEditComponent);
