import ButtonGroup from '$components/button-group/button-group.react';
import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import CardHeader from '$components/cards/card-header/card-header.react';
import ModalCard from '$components/modals/modal-card/modal-card.react';
import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilterGroup } from 'src/interfaces';
import CreatePersistedFilterForm from './create-persisted-filter-form/create-persisted-filter-form.react';
import UpdatePersistedFilterForm from './update-persisted-filter-from/update-persisted-filter-form';
import { SiteGroupFilterDropdownQueryReact_siteFilters } from '$typings/graphql';

interface IPersistFilterModalProps {
  activeFilters?: IFilterGroup[];
  closeModal: () => void;
  onSaved: (filter: SiteGroupFilterDropdownQueryReact_siteFilters) => void;
}

const PersistFilterModal: FC<IPersistFilterModalProps> = ({ activeFilters, closeModal, onSaved }) => {
  const [t] = useTranslation();
  const [persistAsUpdate, setPersistAsUpdate] = useState(false);

  return (
    <div>
      <ModalCard overflow={false} placement-on-mobile="bottom">
        <CardHeader>{t('ui_sitelist_filter_persist_title')}</CardHeader>
        <CardContent className="pad_vnone">
          <div className="pad_b">
            <ButtonGroup className="flex">
              <Button className="flex_1" variant={persistAsUpdate ? 'secondary' : 'primary'} onClick={() => setPersistAsUpdate(false)}>
                {t('ui_sitelist_filter_persist_newgroup')}
              </Button>
              <Button className="flex_1" variant={persistAsUpdate ? 'primary' : 'secondary'} onClick={() => setPersistAsUpdate(true)}>
                {t('ui_sitelist_filter_persist_update')}
              </Button>
            </ButtonGroup>
          </div>
        </CardContent>
        {persistAsUpdate && <UpdatePersistedFilterForm closeForm={closeModal} mutationVariables={{ filtersToSave: activeFilters }} />}
        {!persistAsUpdate && <CreatePersistedFilterForm onSaved={onSaved} closeForm={closeModal} mutationVariables={{ filtersToSave: activeFilters }} />}
      </ModalCard>
    </div>
  );
};

export default PersistFilterModal;
