import { computedFrom } from 'aurelia-binding'
import { bindable, customElement } from 'aurelia-templating'
import gql from 'graphql-tag'
import { DataFreshnessQuery, DataFreshnessQueryVariables, ElasticSearchPage } from '../../../../custom_typings/graphql';
import { formatDate } from '../../../utility';
import { GraphQLBaseViewModel } from '../GraphQLBaseViewModel'
import { IFetchOptions } from '../GraphQLFetcher';

@customElement('data-freshness')
export class DataFreshness extends GraphQLBaseViewModel<void, DataFreshnessQuery, DataFreshnessQueryVariables>{

    @bindable() freshnessChanged: Function;

    @bindable() pageToCheck: ElasticSearchPage;

    query = gql`
        query DataFreshnessQuery($page: ElasticSearchPage!) {
            elasticSearchPages {
                lastUpdated(page: $page)
            }
            epochNow
        }
    `
    
    bind() {
        this.variables = {
            page: this.pageToCheck
        }
    }

    previousFreshness: number | undefined | null;

    dataChanged = () => {
        if(!this.freshnessChanged) return;
        const lastUpdated = this.data?.elasticSearchPages?.lastUpdated; 
        if(lastUpdated !== this.previousFreshness) this.freshnessChanged();
        this.previousFreshness = lastUpdated;
    }

    @computedFrom('data')
    get tooltipVariables() {
        const lastUpdated = this.data?.elasticSearchPages?.lastUpdated
        if(lastUpdated == null) return;

        const formattedDate = formatDate(new Date(lastUpdated * 1000), true, '.', undefined);
        return { date: formattedDate }
    }

    @computedFrom('data')
    get minutesSince() {
        const lastUpdated = this.data?.elasticSearchPages?.lastUpdated
        const now = this.data?.epochNow

        if(lastUpdated == null || now == null) return;

        const diff = now - lastUpdated
        return Math.floor(diff / 60);
    }

    fetchOptions: IFetchOptions = {
        refreshInterval: 5000
    }
}