import React, { useRef, useState, FC, memo } from 'react';
import { Button, CardHeader, IconButton, Typography } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { GetControllerOutputCalibrationsDocument } from '$typings/graphql-codegen';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import { ScrollableMuiCardContent } from '$components/cards/mui-card';
import { CardFlexColumn } from '$components/cards/mui-card';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { IStatusMessage } from '$pages/common/controllers/controllerdetails-react/modules/controller-output-calibrations/modules/common/status-message';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import { ControllerOutputCalibrationHistory } from './modules/controller-output-calibration-history'
import { ControllerOutputCalibrationList } from './modules/controller-output-calibration-list'

export interface IControllerOutputCalibrationsProps {
  controllerId: number;
}

export interface OutputCalibrationHistoryInfo {
  id: number;
  name: string;
}

const ControllerOutputCalibrationsComponent: FC<IControllerOutputCalibrationsProps> = ({
  controllerId,
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [updateFromController, setUpdateFromController] = useState(false);
  const [showHistoryForOutputCalibration, setShowHistoryForOutputCalibration] = useState<
  OutputCalibrationHistoryInfo | undefined
  >(undefined);
  const [statusMessage, setStatusMessage] = useState<
    IStatusMessage | undefined
  >(undefined);
  const cardContentRef = useRef<HTMLDivElement | null>(null);

  const { data, loading, error, revalidate, isRevalidating } = useQuery(
    GetControllerOutputCalibrationsDocument,
    {
      controllerId: controllerId,
      fromController: updateFromController,
    },
    undefined,
    1
  );

  const showHistory = (outputCalibrationId?: number, name?: string) => {
    if (outputCalibrationId && name) {
      setShowHistoryForOutputCalibration({ id: outputCalibrationId, name: name });
    } else {
      setShowHistoryForOutputCalibration(undefined);
    }
  };

  const closeAction = () => {
    if (showHistoryForOutputCalibration) setShowHistoryForOutputCalibration(undefined);
  };

  const refreshControllerOutputCalibrations = () => {
    if (updateFromController) {
      revalidate?.();
    } else {
      setUpdateFromController(true);
    }
  };

  const cardTitle = showHistoryForOutputCalibration
    ? `${showHistoryForOutputCalibration.name} ${t('ui_controlleroutputcalibration_history')}`
    : t(
        'UI_SiteDetails_Controllers_ControllerDetails_ControllerOutputCalibrations_Header'
      );

      return (
        <>
          <CardFlexColumn>
            <CardHeader
              title={<Typography variant="h6">{cardTitle}</Typography>}
              action={
                showHistoryForOutputCalibration ? (
                  <IconButton size="small" onClick={closeAction}>
                    <Icon name={'fa-times'} />
                  </IconButton>
                ) : (
                  <>
                    <IconButton
                      size="small"
                      onClick={refreshControllerOutputCalibrations}
                      disabled={loading || isRevalidating}
                    >
                      <Icon
                        name={
                          loading || isRevalidating
                            ? 'fa-spinner fa-pulse'
                            : 'fa-refresh'
                        }
                      />
                    </IconButton>
                  </>
                )
              }
            />
            <ScrollableMuiCardContent ref={cardContentRef}>
              {loading ? (
                <LoadingBars center />
              ) : error ? (
                <div className="mar_m">
                  <Typography variant="h6">
                    {t('UI_Common_Error_Loading_Data_Failed_Headline')}
                  </Typography>
                  <Typography>
                    {t('UI_Common_Error_Loading_Data_Failed')}
                  </Typography>
                  <Typography>
                    {t('UI_Common_Error_Controller_May_Be_Unavailable')}
                  </Typography>
                  <Button variant="outlined" onClick={revalidate}>
                    {t('UI_Common_Try_Again')}
                  </Button>
                </div>
              ) : !data || !data.controller?.outputCalibrations.length ? (
                <div className="mar_rm mar_lm">
                  <p>
                    {t(
                      'ui_sitedetails_controllers_controllerdetails_controlleroutputcalibrations_none'
                    )}
                  </p>
                  <Button
                    variant="outlined"
                    sx={{ marginTop: '1rem' }}
                    onClick={refreshControllerOutputCalibrations}
                  >
                    {t(
                      'ui_sitedetails_controllers_controllerdetails_controlleroutputcalibrations_actions_fetchoutputcalibrations'
                    )}
                  </Button>
                </div>
              ) : showHistoryForOutputCalibration ? (
                <ControllerOutputCalibrationHistory
                  controllerOutputCalibrationId={showHistoryForOutputCalibration.id}
                  closeView={closeAction}
                />
              ) : (
                <ControllerOutputCalibrationList
                  data={data}
                  showHistory={showHistory}
                  setStatusMessage={setStatusMessage}
                />
              )}
              {statusMessage && !showHistoryForOutputCalibration && (
                <CardFooter topBorder className="flex jsb aicenter">
                  {statusMessage.message}
                  {statusMessage.dismissible && (
                    <IconButton onClick={() => setStatusMessage(undefined)}>
                      <Icon name={'fa-times'} />
                    </IconButton>
                  )}
                </CardFooter>
              )}
            </ScrollableMuiCardContent>
          </CardFlexColumn>
        </>
      );
    };

export const ControllerOutputCalibrations = memo(ControllerOutputCalibrationsComponent);
