import Dropdown from '$components/dropdowns/dropdown/dropdown.react';
import TextField from '$components/textbox/text-field.react';
import { isSomething } from '$lib/helpers';
import { orderByPredicate } from '$lib/sorting';
import { timezones } from '$lib/timezone/timezones';
import React, { FC } from 'react';
import { ITimeZone } from 'src/interfaces';

interface ITimeTimezoneProps {
  time?: string;
  timezone?: string;
  timeValueChanged: (value: string | undefined) => void;
  timezoneValueChanged: (value?: string | number | ITimeZone) => void;
  disabled?: boolean;
}

const TimeTimezone: FC<ITimeTimezoneProps> = ({
  time,
  timezone,
  timeValueChanged,
  timezoneValueChanged,
  disabled = false
}) => {
  const timeregex = /[^0-9:]/;
  const modifyTime = (value: unknown) => {
    if (typeof value !== 'string') return;

    let strValue = value.replace(timeregex, "") as string;
    if(strValue.length === 3 && strValue.charAt(2) !== ":") {
      const ttt  = strValue.slice(0, 2) + ":" + strValue.slice(2);
      strValue = ttt
    }
    if (strValue.length >= 5) {
      strValue = strValue.substring(0, 5); // Value can go longer than the mask briefly, but just ignore the extra chars to avoid glitchy interaction. Must run the validation again though, to avoid yet another glitch.
    }

    updateTimeValidationState(strValue);
    if (false) return;

    time = strValue;
    if (isSomething(timeValueChanged)) timeValueChanged(time);
  };

  const updateTimeValidationState = (value: unknown) => {
    if (typeof value !== 'string' || value.length < 5) {
      return;
    }
    const [hours, mins] = parseHHMM(value);
    if (hours > 23 || mins > 59) {
      return;
    }
  };


  const parseHHMM = (value: string): [number, number] => {
    try {
      const hours = parseInt(value.substring(0, 2), 10);
      const mins = parseInt(value.substring(3, 5), 10);

      return [hours, mins];
    } catch {}
    return [99, 99];
  };

  return (
    <div>
      <div className="time-timezone-container">
        <TextField
          className="time"
          value={time}
          
          onChange={e => modifyTime(e.target.value)}
          //strightRight
          disabled={disabled}
        />
        <Dropdown
          className="timezone"
          straightLeft
          items={orderByPredicate(timezones, t => t.description, 'asc')}
          selected={timezone}
          displayPath="description"
          valuePath="timezoneId"
          itemClicked={value => timezoneValueChanged(value?.value)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default TimeTimezone;
