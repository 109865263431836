import { SortDirection } from '../../../../custom_typings/graphql';
import { IFilterGroup } from '../../../interfaces';
import { SiteSignal } from '../../../models';

export interface SiteListUrlParams {
  soldto?: string;
  shipto?: string;
  showMap?: boolean;
  onlineSites?: boolean;
  offlineSites?: boolean;
  parkedSites?: boolean;
  virtualSites?: boolean;
  activeAlarms?: boolean;
  tankIsParked?: boolean;
  sortProperty?: number;
  sortDirection?: SortDirection;
  customerRef?: string;
  freeText?: string;
  activateSearch?: boolean;
}

export const getSitelistVariablesFromParams = (params: SiteListUrlParams) => {
  const {
    sortProperty,
    sortDirection,
    soldto,
    shipto,
    onlineSites,
    offlineSites,
    parkedSites,
    virtualSites,
    activeAlarms,
    tankIsParked,
    customerRef,
    freeText,
    activateSearch
  } = params;

  const filters: IFilterGroup[] = [];

  if (shipto) {
    filters.push({
      exclude: false,
      field: 'tankshipto',
      type: 'string',
      filters: [{ value: shipto, selected: true }],
      partialMatch: true
    });
  }

  if (soldto) {
    filters.push({
      exclude: false,
      field: 'soldto',
      type: 'string',
      filters: [{ value: soldto, selected: true }],
      partialMatch: true
    });
  }

  if (onlineSites) {
    filters.push({
      exclude: false,
      field: 'sitesignal',
      type: 'ref',
      filters: [{ value: SiteSignal.Online.toString(), selected: true }]
    });
    addNotExistingDefaultFilters(filters);
  }

  if (offlineSites) {
    filters.push({
      exclude: false,
      field: 'sitesignal',
      type: 'ref',
      filters: [{ value: SiteSignal.Offline.toString(), selected: true }]
    });
    addNotExistingDefaultFilters(filters);
  }

  if (parkedSites) {
    filters.push({
      exclude: false,
      field: 'isParked',
      type: 'boolean',
      filters: [{ value: true }]
    });
    addNotExistingDefaultFilters(filters);
  }

  if (virtualSites) {
    filters.push({
      exclude: false,
      field: 'isVirtual',
      type: 'boolean',
      filters: [{ value: true }]
    });
    addNotExistingDefaultFilters(filters);
  }

  if (activeAlarms) {
    filters.push({
      exclude: false,
      field: 'hasAlarm',
      type: 'boolean',
      filters: [{ value: true }]
    });
    addNotExistingDefaultFilters(filters);
  }

  if (tankIsParked) {
    filters.push({
      field: 'tankisparked',
      type: 'boolean',
      exclude: true,
      filters: [{ value: true }]
    });
    addNotExistingDefaultFilters(filters);
  }

  if (customerRef) {
    filters.push({
      exclude: false,
      field: 'customerRef',
      type: 'string',
      filters: [{ value: customerRef, selected: true }],
      partialMatch: true
    });
  }

  return {
    filters: filters,
    freeText: freeText,
    sortDirection: sortDirection,
    sortProperty: sortProperty,
    activateSearch: activateSearch
  };
};

export const defaultSitelistFilters: IFilterGroup[] = [
  {
    type: 'boolean',
    field: 'isParked',
    exclude: true,
    filters: [
      {
        value: true
      }
    ]
  },
  {
    type: 'boolean',
    field: 'isVirtual',
    exclude: true,
    filters: [
      {
        value: true
      }
    ]
  }
];

export const addNotExistingDefaultFilters = (filters: IFilterGroup[]) => {
  defaultSitelistFilters.forEach(dsf => {
    if (!filters.find(f => f.field === dsf.field)) {
      filters.push(dsf);
    }
  });

  return filters;
};
