import React, {FC} from "react";

const Cloud: FC<React.HTMLAttributes<HTMLElement>> = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="10 10 55 55"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    className={className ?? ''}>
    <path
      d="M18.518 51.889h38.297c8.251 0 8.994-11.563 3.705-14.294.487-4.244-3.133-8.04-8.876-6.244-3.945-9.434-15.629-11.894-21.518-2.322-5.367-2.34-11.748.929-11.869 7.173-8.693.374-10.816 15.576.261 15.687zm2.21-4.113 33.17.026c6.711 0 7.32-5.204 2.539-7.814.868-4.487-1.435-6.936-7.67-3.661-.317-9.308-11.71-14.172-17.231-2.214-6.176-3.156-11.008-.638-9.937 6.373-7.13-1.485-9.291 7.41-.87 7.29z"
      fill="currentColor"/>
  </svg>
)

export default Cloud
