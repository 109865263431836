import React, { memo, FC } from 'react';
import { GetControllerParametersQuery } from '$typings/graphql-codegen';
import {
  Row,
  SecurityLevelCell,
  NameCell,
  ValueCell,
  ActionsCell,
  RowList,
  ListWrapper,
} from '../../../../common/profile-and-parameter-list';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { IStatusMessage } from '../common/status-message';
import { ParameterValueUpdater } from '../parameter-value';
import { SecurityLevelValue } from '../security-level-value/security-level-value';
import { getUserFeatures } from '../../../../../../../../config/sessionService';

interface IControllerParameterListProps {
  data: GetControllerParametersQuery;
  accessToSecurityLevel: boolean;
  showHistory: (parameterId?: number, name?: string) => unknown;
  setStatusMessage: (statusMessage?: IStatusMessage) => unknown;
  readonly?: boolean
}

const ControllerParameterListComponent: FC<IControllerParameterListProps> = ({
  data,
  accessToSecurityLevel,
  showHistory,
  setStatusMessage,
  readonly
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const features = getUserFeatures();
  const hasWriteAccess = features.controllerParameters.write;

  return (
    <>
      <ListWrapper>
        <Row>
          {accessToSecurityLevel && <SecurityLevelCell />}
          <NameCell fontWeight={'bold'}>
            {t(
              'UI_SiteDetails_Controllers_ControllerDetails_ControllerParameters_Table_Headers_Name'
            )}
          </NameCell>
          <ValueCell fontWeight={'bold'} sx={{ textAlign: 'end' }}>
            {t(
              'UI_SiteDetails_Controllers_ControllerDetails_ControllerParameters_Table_Headers_Value'
            )}
          </ValueCell>
          <ActionsCell />
        </Row>

        <RowList>
          {data.controller?.parameters.map((p, i) => (
            <Row
              key={p.controllerParameterId}
              bgcolor={i % 2 === 0 ? '#FFF' : '#fafcfe'}
            >
              {accessToSecurityLevel && (
                <SecurityLevelCell>
                  <SecurityLevelValue
                    allowEdit={hasWriteAccess && !readonly}
                    controllerId={p.controllerId}
                    controllerParameterId={p.controllerParameterId}
                    setStatusMessage={setStatusMessage}
                    initialValue={p.securityLevel}
                  />
                </SecurityLevelCell>
              )}
              <NameCell>{p.name}</NameCell>

              {/* The ParameterValue component contains the last two columns */}
              <ParameterValueUpdater
                controllerParameterId={p.controllerParameterId}
                allowEdit={hasWriteAccess && !readonly}
                controllerId={p.controllerId}
                setStatusMessage={setStatusMessage}
                initialValue={p.value}
                unitSymbol={p.unitSymbol}
                showHistory={() => showHistory(p.controllerParameterId, p.name)}
                min={p.minimum ?? undefined}
                max={p.maximum ?? undefined}
              />
            </Row>
          ))}
        </RowList>
      </ListWrapper>
    </>
  );
};

export const ControllerParameterList = memo(ControllerParameterListComponent);
