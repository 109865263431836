import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';
import Icon from '$components/icons/icon/icon.react';
import React, { FC, MouseEventHandler, PropsWithChildren } from 'react';
import './banner.css';
import classNames from "classnames";

interface IBannerProps {
  type: 'info' | 'error' | 'success' | 'warning' | 'parked';
  icon?: string;
  svg?: string;
  close?: Function;
  className?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

const Banner: FC<PropsWithChildren<IBannerProps>> = ({
  type = 'info',
  icon,
  svg,
  close,
  className,
  children,
  onClick
}) => {
  return (
    <div className={classNames('banner-component', type, className)} onClick={onClick}>
      {icon && <Icon className="banner-icon" name={icon} />}
      {svg === 'parked' && <Icon className="banner-icon parked" name="parked-p" />}
      <div className="message">{children}</div>
      {close && (
        <ClickableIcon
          className="banner-icon close-icon"
          name="fa-times"
          onClick={() => close()}
        />
      )}
    </div>
  );
};

export default Banner;
