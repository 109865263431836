import React, { memo, FC } from 'react';
import { IHistoryItem } from '../../../interfaces';
import {
  formatDate,
  formatDayAsNumber,
  formatTime,
  getShortMonthAsLanguageText,
  pureFormatDate,
  pureFormatDayAsNumber,
  pureFormatTime,
} from '$lib/dateHelpers';
import './history-list-item.css';
import Icon from '$components/icons/icon/icon.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import classNames from 'classnames';

interface IIndicatorProps {
  icon?: string;
}

interface IHistoryListItemProps extends IHistoryItem {
  convertTimezone?: boolean
}

const Indicator: FC<IIndicatorProps> = memo(({ icon }) => (
  <div className="indicator">
    <span className="indicator-connection-line"></span>
    <Icon name={icon ?? 'fa-circle-o'} className="indicator-icon" />
    <span className="indicator-connection-line"></span>
  </div>
));

interface ITimeStampProps {
  timeStamp: string;
  convertTimezone?: boolean
}

const TimeStamp: FC<ITimeStampProps> = memo(({ timeStamp, convertTimezone = false }) => {
  const [t] = useCaseInsensitiveTranslation();  
  const fullDate = convertTimezone ? formatDate(timeStamp, true, '.') : pureFormatDate(timeStamp, true, '.');
  const month = t(getShortMonthAsLanguageText(timeStamp) ?? '');
  const dateAsNumber = convertTimezone ? formatDayAsNumber(timeStamp) : pureFormatDayAsNumber(timeStamp);
  const time = convertTimezone ? formatTime(timeStamp) : pureFormatTime(timeStamp);

  return (
    <div className="datetime" title={fullDate}>
      <div className="datemonth">
        <span className="month">{month}</span>
        <span className="date">{dateAsNumber}</span>
      </div>
      <span className="time">{time}</span>
    </div>
  );
});

const HistoryListItemComponent: FC<IHistoryListItemProps> = ({
  class: _class,
  externalLinks,
  icon,
  metaInformation,
  text,
  timeStamp,
  convertTimezone,
  onClick,
  dataForOnClick
}) => {
  const outerOnClickHandler = onClick && ((ev: React.MouseEvent<HTMLDivElement>) => { ev.preventDefault(); return onClick(dataForOnClick) }); 
  return (
    <div
      className={classNames('history-list-item', _class)}
      onClick={outerOnClickHandler}>
      <TimeStamp timeStamp={timeStamp} convertTimezone={convertTimezone} />
      <Indicator icon={icon} />
      <div className="value">
        {metaInformation && (
          <div className="log-metainformation">{metaInformation}</div>
        )}
        {text.map((t, i) => (
          <div key={i}>{t}</div>
        ))}
        {externalLinks?.map((el, i) => (
          <div key={i} className="external-link">
            {el.url && <a href={el.url}>{el.text}</a>}
            {el.action && (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  el.action?.();
                }}
              >
                {el.text}
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export const HistoryListItem = memo(HistoryListItemComponent);
