import Button from '$components/buttons/button.react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './flex-grid-actionbar.css';

interface IGridActionbarProps {
  selectedText: string;
  clearSelection: () => void;
  count?: number;
  actionButtons?: React.ReactNode
}

const GridActionbar: FC<IGridActionbarProps> = ({
  selectedText,
  clearSelection,
  count,
  actionButtons
}) => {
  const [t] = useTranslation();
  return (
    <div className="grid-action-bar-component">
      <div className="selection-text">
        {t(selectedText, { count: count })}
        {clearSelection && (
          <Button variant="tertiary" onClick={clearSelection}>
            {t('ui_table_selectionbar_clearselection')}
          </Button>
        )}
      </div>
      <div className="flex row">{actionButtons}</div>
    </div>
  );
};

export default GridActionbar;
