import React, { FC, memo } from 'react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import { Button } from '@mui/material';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { ControllerOutputCalibrationHistoryQueryDocument as ControllerOutputCalibrationHistoryQueryDocument } from '$typings/graphql-codegen';
import ErrorText from '$components/texts/error-text/error-text.react';
import Skeleton from '$components/placeholders/skeleton/skeleton';
import { ControllerOutputCalibrationHistoryRow } from './controller-output-calibration-history-row';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

interface IOutputCalibrationHistoryProps {
  controllerOutputCalibrationId: number;
  closeView: () => unknown;
}

const ControllerOutputCalibrationHistoryComponent: FC<IOutputCalibrationHistoryProps> = ({
  controllerOutputCalibrationId,
  closeView,
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const { data, loading, revalidate } = useQuery(
    ControllerOutputCalibrationHistoryQueryDocument,
    { controllerOutputCalibrationId: controllerOutputCalibrationId }
  );

  return (
    <>
      {!loading && !data && (
        <div className="mar_lm mar_rm">
          <ErrorText bold>{t('ui_common_error_loading_data_failed')}</ErrorText>
        </div>
      )}

      {!loading && data && (
        <div className="mar_lm overflow-auto">
          {data.controllerOutputCalibrationHistory?.all.length === 0 ? (
            <>
              <div className="flex column flex_1 aicenter">
                <span>
                  {t(
                    'ui_sitedetails_controllers_controllerdetails_controlleroutputcalibrations_no_history_exists'
                  )}
                </span>
              </div>
            </>
          ) : (
            <>
              {data.controllerOutputCalibrationHistory?.all.sort((a, b) => Date.parse(b.timeOfChange) - Date.parse(a.timeOfChange)).map((row) => (
                <ControllerOutputCalibrationHistoryRow
                  key={row.controllerOutputCalibrationHistoryId}
                  item={row}
                />
              ))}
            </>
          )}
        </div>
      )}

      {loading && !data && <Skeleton rowCount={3}></Skeleton>}

      <CardFooter className="flex jsb">
        <Button onClick={closeView} variant="outlined">
          {t('ui_common_close')}
        </Button>
        <Button onClick={revalidate} variant="outlined">
          {t(!data && !loading ? 'ui_common_tryagain' : 'ui_common_refresh')}
        </Button>
      </CardFooter>
    </>
  );
};

export const ControllerOutputCalibrationHistory = memo(
  ControllerOutputCalibrationHistoryComponent
);
