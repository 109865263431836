import React, { FC } from 'react';
import "./form-element.css"
import classNames from "classnames";

interface IFormElementProps {
  label?: string;
  direction?: 'row' | 'column' | 'auto';
  className?: string
}

const FormElement: FC<IFormElementProps> = ({
  children,
  label,
  direction = 'auto',
  className
}) => (
  <div className={classNames('form-element-wrapper', direction, className)}>
    {label && <label className="form-element-label">{label}</label>}
    <div className="form-item-content">{children}</div>
  </div>
);

export default FormElement;
