import { default as React, FC } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  ControllerBackupViewerIda100Data,
} from '$typings/graphql-codegen';
import ChartistGraph from 'react-chartist';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Icon from '$components/icons/icon/icon.react';
import './controllerbackupviewer.css'

export interface IIDA100BackupViewerProps {
  ida100Data: ControllerBackupViewerIda100Data;
}

const IDA100BackupViewer: FC<IIDA100BackupViewerProps> = ({ ida100Data }) => {
  return (
    <>
      <div className="ida100backupviewerpage-react">
      <Accordion sx={{marginTop:"10px"}}>
        <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
          <Typography>Settings ({ida100Data.settings.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                <TableRow>
                  <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff', width:'200px'}}>Name</TableCell>
                  <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ida100Data.settings.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell sx={{width:'200px'}}>{row.name}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))} 
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Alarms ({ida100Data.alarmRows.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Source</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>SetCondition</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>SetValue</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>ResetCondition</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>ResetValue</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Message</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>SMSRecipients</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>EmailRecipients</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>State</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ida100Data.alarmRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                      <TableCell>{row.source}</TableCell>
                      <TableCell>{row.setCondition}</TableCell>
                      <TableCell>{row.setValue}</TableCell>
                      <TableCell>{row.resetCondition}</TableCell>
                      <TableCell>{row.resetValue}</TableCell>
                      <TableCell>{row.message}</TableCell>
                      <TableCell>{row.smsRecipients}</TableCell>
                      <TableCell>{row.emailRecipients}</TableCell>
                      <TableCell>{row.state}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Logins ({ida100Data.loginRows.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Password</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ida100Data.loginRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                      <TableCell>{row.password}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Parameters ({ida100Data.parameterRows.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Initial Value</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Units</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Minimum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Maximum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Security</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ida100Data.parameterRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                      <TableCell>{row.initialValue}</TableCell>
                      <TableCell>{row.units}</TableCell>
                      <TableCell>{row.minimum}</TableCell>
                      <TableCell>{row.maximum}</TableCell>
                      <TableCell>{row.security}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Profiles ({ida100Data.profileRows.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {ida100Data.profileRows.map((row) => {
              const chartData = {
                series: [
                  row.values
                ]
              };
              return (
              <div key={row.name}>
                <h4>{row.name}</h4>
                <span>Comment : {row.comment}</span>
                <ChartistGraph data={chartData} type='Line' />
              </div>
            )})}  
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Trends ({ida100Data.trendRows.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Units</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Minimum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Maximum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Security</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ida100Data.trendRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                      <TableCell>{row.units}</TableCell>
                      <TableCell>{row.minimum}</TableCell>
                      <TableCell>{row.maximum}</TableCell>
                      <TableCell>{row.security}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        {ida100Data.units.map((unit) => (
          <React.Fragment key={unit.id}>
            <h2>Unit {unit.name}</h2>

            <Accordion sx={{marginTop:"10px"}}>
              <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
                <Typography>Analog Inputs</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                      <TableRow>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Units</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Minimum</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Maximum</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>maMinimum</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>maMaximum</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Smoothing</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {unit.analogInputRows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.comment}</TableCell>
                          <TableCell>{row.units}</TableCell>
                          <TableCell>{row.minimum}</TableCell>
                          <TableCell>{row.maximum}</TableCell>
                          <TableCell>{row.maMinimum}</TableCell>
                          <TableCell>{row.maMaximum}</TableCell>
                          <TableCell>{row.smoothing}</TableCell>
                        </TableRow>
                      ))} 
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{marginTop:"10px"}}>
              <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
                <Typography>Analog Outputs</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                      <TableRow>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Units</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Minimum</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Maximum</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Calibration</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {unit.analogOutputRows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.comment}</TableCell>
                          <TableCell>{row.units}</TableCell>
                          <TableCell>{row.minimum}</TableCell>
                          <TableCell>{row.maximum}</TableCell>
                          <TableCell>{row.calibration}</TableCell>
                        </TableRow>
                      ))} 
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{marginTop:"10px"}}>
              <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
                <Typography>Digital Inputs</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                      <TableRow>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Units</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Minimum</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Maximum</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Smoothing</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {unit.digitalInputRows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.comment}</TableCell>
                          <TableCell>{row.units}</TableCell>
                          <TableCell>{row.minimum}</TableCell>
                          <TableCell>{row.maximum}</TableCell>
                          <TableCell>{row.smoothing}</TableCell>
                        </TableRow>
                      ))} 
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{marginTop:"10px"}}>
              <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
                <Typography>Digital Outputs</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                      <TableRow>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Units</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Minimum</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Maximum</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Calibration</TableCell>
                        <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>PulsesPM</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {unit.digitalOutputRows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.comment}</TableCell>
                          <TableCell>{row.units}</TableCell>
                          <TableCell>{row.minimum}</TableCell>
                          <TableCell>{row.maximum}</TableCell>
                          <TableCell>{row.calibration}</TableCell>
                          <TableCell>{row.pulsesPM}</TableCell>
                        </TableRow>
                      ))} 
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>            
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default IDA100BackupViewer;
