import React, { FC, Fragment, memo } from 'react';
import { Box, Button, ButtonGroup, Card, CardContent } from '@mui/material';
import { getUserFeatures } from '../../../../../../config/sessionService';
import { useIsMobile } from '$lib/hooks/isMobile';
import '../../controllerdetails.css';
import './controller-action-menu.css';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import classNames from 'classnames';

interface IControllerToolsMenuProps {
  deleteController: () => unknown;
  detachController: () => unknown;
  archiveController: () => unknown;
  detachControllerDisabled: boolean;
  archiveControllerDisabled: boolean;
  editSerial: () => unknown;
  editSerialDisabled: boolean;
  attachToSite: () => unknown;
  createAsSite: () => unknown;
  editChannels: () => unknown;
  cancelControllerDetails: () => unknown;
}

interface ControllerAction {
  userHasAccess: boolean;
  displayText: string;
  variant: 'outlined' | 'contained';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  onClick?: () => unknown;
  disabled?: boolean;
}

const ControllerActionsMenuComponent: FC<IControllerToolsMenuProps> = ({
  deleteController,
  detachController,
  archiveController,
  detachControllerDisabled,
  archiveControllerDisabled,
  editSerial,
  editSerialDisabled,
  attachToSite,
  createAsSite,
  editChannels,
  cancelControllerDetails,
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const features = getUserFeatures();
  const isMobile = useIsMobile();

  const buttons: ControllerAction[][] = [
    [
      {
        userHasAccess: features.controllerManagement.delete,
        variant: 'contained',
        color: 'error',
        onClick: deleteController,
        displayText: 'Delete controller',
      },
      {
        userHasAccess: features.controllerManagement.write,
        onClick: detachController,
        variant: 'outlined',
        disabled: detachControllerDisabled,
        displayText: 'Detach',
      },
      {
        userHasAccess: features.controllerManagement.write,
        onClick: archiveController,
        variant: 'outlined',
        disabled: archiveControllerDisabled,
        displayText: 'Archive',
      },
      {
        userHasAccess: features.replaceController.write,
        variant: 'outlined',
        onClick: editSerial,
        disabled: editSerialDisabled,
        displayText: 'Replace box',
      },
    ],
    [
      { // remove !isMobile && when implementing mobile view
        userHasAccess: features.controllerManagement.write,
        variant: 'outlined',
        onClick: attachToSite,
        displayText: t(
          'UI_ControllerManager_ControllerDetails_Actions_AttachToExisting'
        ),
      },
      {// remove !isMobile && when implementing mobile view
        userHasAccess: features.controllerManagement.write,
        variant: 'outlined',
        onClick: createAsSite,
        displayText: t(
          'UI_ControllerManager_ControllerDetails_Actions_CreateNewSites'
        ),
      },
    ],
    [
      {
        userHasAccess: !isMobile,
        variant: 'outlined',
        onClick: editChannels,
        displayText: t(
          'UI_ControllerManager_ControllerDetails_Actions_EditChannels'
        ),
      },
      {
        userHasAccess: true,
        variant: 'outlined',
        onClick: cancelControllerDetails,
        displayText: t('ui_cancel'),
      },
    ],
  ];

  // The return statement iterates over the 2d array an makes three groups
  // of buttons, the middle part is rendered inside the
  // <ButtonGroup > component to reflect the aurelia version
  return (
    <Card className="controller-action-card">
      <CardContent className="controller-action-card-content">
        <Box
          sx={{
            overflow: 'overlay',
            marginTop: isMobile ? '16px' : '',
            marginBottom: isMobile ? '16px' : '',
          }}
        >
          {buttons.map((group, i) => {
            const inButtonGroup = i === 1;
            const buttonsJsx = (
              <Fragment key={"group" + i}>
                {group.map(
                  ({ displayText, userHasAccess, ...buttonProps }) =>
                    userHasAccess && (
                      <Button
                        key={displayText + i}
                        size={isMobile ? 'large' : 'medium'}
                        {...buttonProps}
                        className={classNames(
                          isMobile
                            ? 'controller-action-button-mobile'
                            : 'controller-action-button' + (inButtonGroup ? '-grouped' : '')
                        )}
                        fullWidth={isMobile}
                      >
                        {displayText}
                      </Button>
                    )
                )}
              </Fragment>
            );
            if (inButtonGroup && !isMobile) {
              return (
                <ButtonGroup key={"bg" + i} className="button-group-margin-right">
                  {buttonsJsx}
                </ButtonGroup>
              );
            }
            if (i == 2 && !isMobile) {
              return <div key={"mobile" + i} className="float-right">{buttonsJsx}</div>;
            }
            return buttonsJsx;
          })}
        </Box>
      </CardContent>
    </Card>
  );
};

export const ControllerActionsMenu = memo(ControllerActionsMenuComponent);
