import Map from '$components/map/map.react';
import { emptyArray } from '$lib/helpers';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import {
  ReactSitelistmapQueryVariables,
  ReactSitelistmapQuery_elasticSearchPages_sitelist_mapGeoPositions
} from '$typings/graphql';
import { ReactSitelistmapQueryDocument } from '$typings/graphql-codegen';
import React, { FC, useEffect, useMemo, useState, useRef } from 'react';
import {
  ConditionalOperator,
  IControllerMapMarker,
  IFilterGroup,
  IMapMarker
} from '../../../../interfaces';
import './sitelistmap.css';
import { useResizeObserver } from '$lib/hooks/useResizeObserver';
import { useHackyNavigate } from '$lib/hooks/useHackyNavigate';
import { Button, Tooltip, styled } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';

const getControllerPositionFromChannel = (
  c: ReactSitelistmapQuery_elasticSearchPages_sitelist_mapGeoPositions
): IControllerMapMarker => ({
  accuracy: 0,
  lat: c.lat || 0,
  lng: c.lng || 0,
  controllerId: c.controllerId,
  siteId: c.siteId,
  markerIconEnum: c.markerIconEnum,
  markerType: 'controller',
  title: c.title || ''
});

interface ISitelistMapProps {
  freeTextQuery?: string[];
  filters?: IFilterGroup[];
  siteIds: string[] | undefined;
  siteChannelIds: Array<number | string> | undefined;
  setMapHeight?: React.Dispatch<React.SetStateAction<string>>;
  mapHeight?: string
}

export interface WindowProxyWithSetMarkers extends WindowProxy {
  setMarkers: (m: IMapMarker[]) => void
}

interface ExtendedWindowProxy extends WindowProxy {
  getMarkers?: () => IMapMarker[],
  navigate?: (url: string) => void,
}

let windowObjectReference: WindowProxyWithSetMarkers | null = null;

const OpenMapInNewWindowButton = styled(Button)`
  position: absolute;
  z-index: 2;
  right: 0; 
  padding: 0.5rem; 
  margin: 1rem;
  min-width: unset; 
  font-size: 1.5rem;
`

const SitelistMap: FC<ISitelistMapProps> = ({
  freeTextQuery,
  filters = [],
  siteIds,
  siteChannelIds,
  setMapHeight,
  mapHeight
}) => {

  const [variables, setVariables] = useState<ReactSitelistmapQueryVariables>();
  const { data } = useQuery(ReactSitelistmapQueryDocument, variables);
  const navigate = useHackyNavigate()

  const mapContainer = useRef<HTMLDivElement | null>(null);
  useResizeObserver(mapContainer, x =>
    mapHeightChanged(x.height)
  );

  function mapHeightChanged(newHeight: number) {
    setMapHeight &&
      newHeight > 50 &&
      setMapHeight(() => newHeight.toString() + 'px');
  }

  useEffect(() => {
    let sitelistMapFilters = filters

    if (siteIds) {
      sitelistMapFilters = [
        ...filters,
        {
          field: 'siteIdRef',
          type: 'ref',
          exclude: false,
          filters: siteIds.map(siteId => ({
            value: siteId,
            operator: ConditionalOperator.Equals
          }))
        }
      ];
    }

    setVariables({
      ...variables,
      filters: sitelistMapFilters.length ? JSON.stringify(sitelistMapFilters) : undefined,
      freeTextQuery: freeTextQuery,
      siteChannelIdsForTankChannels: siteChannelIds
        ? siteChannelIds.map(ensureNumber)
        : undefined
    });
  }, [freeTextQuery, filters, siteIds, siteChannelIds]);

  const flattenedGeolocation = useMemo((): IControllerMapMarker[] => {
    if (!data) return emptyArray;
    return data.elasticSearchPages.sitelist.mapGeoPositions.map(
      getControllerPositionFromChannel
    );
  }, [data]);

  function markerClicked(marker: IControllerMapMarker, e?: MouseEvent) {
    if (marker.siteId && e?.ctrlKey) {
      return window.open(`sitedetails/${marker.siteId}`)
    }

    if (marker.siteId) {
      return navigate(`sitedetails/${marker.siteId}`)
    }
  }

  useEffect(() => {
    if (!windowObjectReference && mapHeight === "0px" && setMapHeight)
      setMapHeight("50%")
  }, [])

  useEffect(() => {
    if (windowObjectReference?.closed) {
      setMapHeight && setMapHeight(() => "50%")
    }
  }, [windowObjectReference?.closed])

  useEffect(() => {
    if (!windowObjectReference?.setMarkers) return
    if (!data) return

    windowObjectReference?.setMarkers(flattenedGeolocation)
  }, [flattenedGeolocation, windowObjectReference?.setMarkers])

  function openMapInNewWindow() {
    (window as unknown as ExtendedWindowProxy).navigate = function (url: string) {
      return navigate(url)
    };
    (window as unknown as ExtendedWindowProxy).getMarkers = function () {
      return flattenedGeolocation
    };

    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open("/sitelistmap", "_blank", "popup") as WindowProxyWithSetMarkers;
      setMapHeight && setMapHeight(() => "0px")
    } else {
      windowObjectReference.focus();
    }
  }

  useEffect(() => {
    if (!mapHeight?.includes("%")) {
      mapContainer.current?.setAttribute("style", `height: ${mapHeight}`)
    } else {
      mapContainer.current?.setAttribute("style", `height: 100%`)
    }
  }, [mapHeight])

  return (
    <div className="react-site-map" ref={mapContainer}>
      {mapHeight !== "0px" && <Tooltip title="Open sitelist map in new window">
        <OpenMapInNewWindowButton variant='outlined' onClick={openMapInNewWindow}><Icon name="fa-external-link" /></OpenMapInNewWindowButton>
      </Tooltip>}
      {mapHeight !== "0px" && <Map
        name="sitelistMap"
        rememberZoomPosition
        className="fill-height"
        onClick={markerClicked}
        markers={flattenedGeolocation}
        clustered
        autoBounds
      />}
    </div>
  );
};

export default SitelistMap;
