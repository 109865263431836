import React, { FC } from 'react';
import classNames from "classnames";

interface IconProps {
  className?: string
}

const ArrowRight: FC<IconProps> = ({className}) => (
  <svg
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={classNames(
      'feather',
      'feather-arrow-right',
      className
    )}>
    <path d="M5 12h14M12 5l7 7-7 7" />
  </svg>
);

export default ArrowRight;
