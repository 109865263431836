import React, { useEffect, useState, FC, memo, Fragment } from 'react';
import { IHistoryItem } from '../../../interfaces';
import { orderByPredicate } from '$lib/sorting';
import { toLocalTimeFromUtc } from '$lib/dateHelpers';
import { HistoryListSeparator } from '../history-list-separator/history-list-separator.react';
import { HistoryListItem } from '$components/history-list/history-list-item/history-list-item.react';

export interface IHistoryListProps {
  items: IHistoryItem[];
  convertTimezone?: boolean
}

interface IHistoryItemGroupedByYear {
  year: number;
  items: IHistoryItem[];
}

const HistoryListComponent: FC<IHistoryListProps> = ({ items, convertTimezone = false }) => {
  const [itemsByYear, setItemsByYear] = useState<IHistoryItemGroupedByYear[]>(
    []
  );

  useEffect(() => {
    if (!items || items.length === 0) return;

    const groupItemsByYear = (_items: IHistoryItem[]) => {
      const sorted = orderByPredicate(_items, (item) => item.timeStamp, 'desc');
      const groupedItems: IHistoryItemGroupedByYear[] = [];

      for (const k of sorted) {
        const year = toLocalTimeFromUtc(new Date(k.timeStamp)).getFullYear();
        let found = groupedItems.find((g) => g.year === year);

        if (!found) {
          found = { year, items: [] };
          groupedItems.push(found);
        }
        found.items.push(k);
      }

      setItemsByYear(groupedItems);
    };

    groupItemsByYear(items);
  }, [items]);

  return (
    <>
      {itemsByYear &&
        itemsByYear.map(({ year, items }, i) => (
          <Fragment key={i}>
            <HistoryListSeparator year={year} />
            <br />
            {items.map((item, i) => (
              <HistoryListItem key={i} convertTimezone={convertTimezone} {...item} />
            ))}
          </Fragment>
        ))}
    </>
  );
};

export const HistoryList = memo(HistoryListComponent);
