import React, { FC, useRef, useEffect, useState, CSSProperties } from 'react';
import './tooltip.css';
import classNames from "classnames";

interface ITooltipProps {
  text?: string;
  placement?: 'left' | 'top';
  fill?: 'needed' | 'stretch';
  customClass?: string;
  delay?: number; // in milliseconds
}

const Tooltip: FC<ITooltipProps> = ({ text, placement = 'top', fill = 'needed', customClass, children, delay = 0}) => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const [placementStyle, setPlacementStyle] = useState<CSSProperties>();

  useEffect(() => {
    if (ref.current && placement === 'top') {
      setPlacementStyle({ top: -ref.current.clientHeight });
    } else if (ref.current && placement === 'left') {
      setPlacementStyle({ left: -ref.current.clientWidth });
    } 
  }, [ref, placement]);
  
  if (!text) return children as React.ReactElement;

  return (
    <div
      className={classNames(
        'tooltip-component',
        'tooltip-wrapper',
        'clickable',
        fill && ('fill-' + fill),
        customClass
      )}
      style={{transitionDelay: `${delay}s` }}>
      <span ref={ref} className="tooltiptext" style={{...placementStyle, transitionDelay: `${delay}ms` }}>
        {text}
      </span>
      {children}
    </div>
  );
};

export default Tooltip;
