import './controller-remote.css';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
  FC,
} from 'react';
import { useControllerCommandService } from '$lib/hooks/useControllerCommandService';
import { IControllerCommandStatus } from '$interfaces/entity';
import { formatTime } from '$lib/dateHelpers';
import Dropdown from '$components/dropdowns/dropdown/dropdown.react';
import {
  ControllerDetailsQueryQuery,
  GetControllerRemoteControlUrlDocument,
} from '$typings/graphql-codegen';
import {
  getUserFeatures,
} from '../../../../../../config/sessionService';
import Icon from '$components/icons/icon/icon.react';
import {
  controllerTypeIsFXA320,
  controllerTypeIsIda,
  isPingeableIPAddress,
} from '$lib/application/controllerHelpers';
import { useQuery } from '$lib/hooks/fetch-utillities';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Button,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { CardWithHeader } from '$components/cards/mui-card';
import { CommandHistoryClearMenu } from '$pages/common/controllers/controllerdetails-react/modules/controller-remote/comand-history-clear-menu';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { useIsMobile } from '$lib/hooks/isMobile';
import useIsVisible from '$lib/hooks/isVisible';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import classNames from 'classnames';

interface CommandListEntry {
  text: string;
  command: string;
}

const getCommandsForFXA320 = (canPing: boolean): CommandListEntry[] => {
  const commands = canPing
    ? [
        {
          text: 'UI_SiteDetails_Controllers_Administrate_Button_Ping',
          command: 'ping',
        },
      ]
    : [];
  return commands;
};

const getCommandsForIda = (canPing: boolean) => {
  let result: CommandListEntry[] = [
    {
      text: 'UI_SiteDetails_Controllers_Administrate_Button_Status',
      command: 'status',
    },
    {
      text: 'UI_SiteDetails_Controllers_Administrate_Button_SendData',
      command: 'sendData',
    },
    {
      text: 'UI_SiteDetails_Controllers_Administrate_Button_ListValues',
      command: 'lv',
    },
    {
      text: 'UI_SiteDetails_Controllers_Administrate_Button_Hello',
      command: 'sendhello',
    },
    {
      text: 'UI_SiteDetails_Controllers_Administrate_Button_ListParameters',
      command: 'lp',
    },
    {
      text: 'UI_SiteDetails_Controllers_Administrate_Button_TrendValues',
      command: 'ltv',
    },
  ];
  if (canPing) {
    result.unshift({
      text: 'UI_SiteDetails_Controllers_Administrate_Button_Ping',
      command: 'ping',
    });
  }
  return result;
};

const getStatusAsText = (status: IControllerCommandStatus) => {
  switch (status) {
    case IControllerCommandStatus.Running:
      return 'Running';
    case IControllerCommandStatus.Failed:
      return 'Failed';
    case IControllerCommandStatus.Success:
      return 'Success';
  }
};

type SlaveControllerDropdownItem = {
  text: string;
  value: number;
};

interface IRemoteControlProps {
  controllerId: number;
  controller: ControllerDetailsQueryQuery['controller'];
  toggleExpand: () => boolean;
}

interface IShortcutActionsMobileViewProps {
  shortcutButtons: JSX.Element[];
}

const ShortcutActionsMobileView: FC<IShortcutActionsMobileViewProps> = memo(
  ({ shortcutButtons }) => {
    const [lastItemHasBeenHidden, setLastItemHasBeenHidden] = useState(false);
    const [shortcutsExpanded, setShortcutsExpanded] = useState(false);
    const isLastVisibleRef = useRef<HTMLDivElement | null>(null);
    const isLastVisible = useIsVisible(isLastVisibleRef);

    useEffect(() => {
      if (!isLastVisible && !lastItemHasBeenHidden)
        setLastItemHasBeenHidden(true);
    }, [isLastVisible]);

    return (
      <div className="controller-command-card-command-row-react-mobile">
        <Collapse
          timeout={0}
          in={shortcutsExpanded}
          collapsedSize={33}
          className="flex"
          sx={{
            flex: '1 0 0',
          }}
        >
          <Grid container>
            <Grid container xs={11} spacing={1}>
              {shortcutButtons.map((b, i) => (
                <Grid key={i} xs sx={{ minWidth: '10rem' }} display={'flex'}>
                  {b}
                </Grid>
              ))}
              <span ref={isLastVisibleRef} />
            </Grid>

            {(!isLastVisible || shortcutsExpanded) && (
              <Grid
                xs={1}
                sx={{ display: 'flex', justifyContent: 'center' }}
                spacing={1}
              >
                <IconButton
                  sx={{
                    alignSelf: 'start',
                    padding: 1,
                  }}
                  onClick={() => setShortcutsExpanded((exp) => !exp)}
                >
                  <Icon
                    name={
                      shortcutsExpanded ? 'fa-chevron-down' : 'fa-chevron-up'
                    }
                    className="text"
                  />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Collapse>
      </div>
    );
  }
);

const ControllerRemoteComponent: FC<IRemoteControlProps> = ({
  controllerId,
  controller,
  toggleExpand,
}) => {
  const isMobile = useIsMobile();
  const [t] = useCaseInsensitiveTranslation();
  const [expanded, setExpanded] = useState(false);
  const [selectedSlaveController, setSelectedSlaveController] = useState<
    number | undefined
  >(undefined);
  const [customCommand, setCustomCommand] = useState<string>('');

  const { activeRequest, clearCommandHistory, commandHistory, sendCommand } =
    useControllerCommandService(controllerId);

  const remoteUrl = useQuery(GetControllerRemoteControlUrlDocument, {
    controllerId: controllerId,
    port: 80 + (selectedSlaveController ?? 0),
  });

  const toggleExpandAction = useCallback(() => {
    setExpanded(toggleExpand());
  }, [setExpanded, toggleExpand]);

  const ipaddr = useMemo(() => controller?.simDetail?.ipAddress, [controller]);
  const canPing = useMemo(
    () => ipaddr != undefined && isPingeableIPAddress(ipaddr),
    [ipaddr]
  );
  const controllerType = useMemo(
    () => controller?.controllerType ?? '',
    [controller]
  );

  const controllerCommands = useMemo(
    () =>
      controllerTypeIsIda(controllerType)
        ? getCommandsForIda(canPing)
        : controllerTypeIsFXA320(controllerType)
        ? getCommandsForFXA320(canPing)
        : [],
    [controllerType, canPing]
  );

  const controllerSupportsRemoteControl = useMemo(
    () =>
      controllerTypeIsIda(controllerType) ||
      controllerTypeIsFXA320(controllerType),
    [controllerType]
  );

  const features = getUserFeatures();

  const slaveControllerList: SlaveControllerDropdownItem[] = useMemo(() => {
    let list = [{ text: 'Master', value: 0 }];
    if(!controller) return list
    controller.numberOfSlaves = controller.numberOfSlaves ?? 0;

    for (let i = 0; i < controller.numberOfSlaves; i++) {
      list.push({
        text: 'Slave ' + (i + 1),
        value: i + 1,
      });
    }
    return list;
  }, [controller?.numberOfSlaves]);

  const goToRemoteControl = useCallback(() => {
    const url = remoteUrl.data?.controller?.remoteControlUrl.remoteUrl;
    window.open(url, '_blank');
  }, [remoteUrl]);

  const shortcutButtons = useMemo(() => {
    const arr = controllerCommands.map((c, i) => (
      <Button
        key={`${c.command}_${i}`}
        sx={{ flex: '1 0 auto' }}
        variant="outlined"
        disabled={!!activeRequest}
        startIcon={
          activeRequest === c.command ? (
            <Icon name={'fa-spinner fa-pulse'} className="text" />
          ) : undefined
        }
        onClick={() => sendCommand(c.command, selectedSlaveController)}
        title={t(c.text)}
      >
        <Typography noWrap>{t(c.text)}</Typography>
      </Button>
    ));

    if (controllerSupportsRemoteControl)
      arr.push(
        <Button
          key={`remote_control_shortcut`}
          variant="outlined"
          sx={{ flex: '1 0 auto' }}
          onClick={goToRemoteControl}
          title={t(
            'UI_SiteDetails_Controllers_Administrate_Button_RemoteAccess'
          )}
        >
          <Typography noWrap>
            {t('UI_SiteDetails_Controllers_Administrate_Button_RemoteAccess')}
          </Typography>
        </Button>
      );
    return arr;
  }, [controllerCommands, activeRequest, sendCommand, goToRemoteControl, t]);

  if (!controller) {
    return (
      <CardWithHeader
        headerText={t('UI_SiteDetails_Controllers_Headers_Administrate')}
      >
        <LoadingBars center />
      </CardWithHeader>
    );
  }

  return (
    <CardWithHeader
      headerText={t('UI_SiteDetails_Controllers_Headers_Administrate')}
      headerActions={
        <div className="controller-remote-action-header-wrapper">
          {slaveControllerList.length > 1 && (
            <div className="controller-slave-selector-dropdown-wrapper">
              <Dropdown<SlaveControllerDropdownItem>
                items={slaveControllerList}
                displayPath={'text'}
                valuePath={'value'}
                selected={slaveControllerList[selectedSlaveController ?? 0]}
                itemClicked={(item) =>
                  setSelectedSlaveController((item?.value as number) ?? 0)
                }
              />
            </div>
          )}
          <CommandHistoryClearMenu
            clearCommandHistory={clearCommandHistory}
            commandHistory={commandHistory}
          />
          {!isMobile && (
            <IconButton size="small" onClick={toggleExpandAction}>
              <Icon
                name={expanded ? 'fa-compress' : 'fa-expand'}
                title={
                  (expanded ? 'Shrink' : 'Expand') + ' remote control card'
                }
              />
            </IconButton>
          )}
        </div>
      }
    >
      <div className="controller-remote-command-history">
        {commandHistory.map((c, i) => {
          const commandText = `Command: '${c.command}'`;
          const toMasterOrSlaveText =
            controller.numberOfSlaves &&
            controller.numberOfSlaves > 0 &&
            c.slaveNumber !== undefined
              ? c.slaveNumber === 0
                ? 'to master'
                : `to slave ${c.slaveNumber}`
              : '';
          const resultLines = c.result?.split(/(\r\n)|\n/g) ?? [];

          return (
            <div
              key={'commandListKey' + i}
              className={classNames(
                'mar_tm',
                'mar_bm',
                'controller-remote-command-row',
                getStatusAsText(c.status).toLowerCase()
              )}
            >
              <div className="controller-remote-command-row-commandline">
                <span>
                  {commandText} {toMasterOrSlaveText}
                </span>
                <span className="mar_rs">
                  <Icon className="mar_rs" name={'fa-clock-o'} />
                  {formatTime(c.ended || c.started)}
                </span>
              </div>
              {c.status !== IControllerCommandStatus.Running && (
                <>
                  <div>Result:</div>
                  <br />
                  <div style={{ fontFamily: 'monospace' }}>
                    {resultLines.map((line, i) =>
                      line ? line.length ? <p key={i}>{line}</p> : <br key={i} /> : null
                    )}
                  </div>
                </>
              )}
              {c.status === IControllerCommandStatus.Running && (
                <div>Currently running</div>
              )}
            </div>
          );
        })}
      </div>
      <CardFooter topBorder>
        <div className="controller-remote-input">
          {features.controllerFreeTextCommand.write && 
          <div className="controller-command-custom-input mar_bm">
            <div className="mar_rs controller-command-custom-input-textfield">
              <TextField
                size="small"
                value={customCommand}
                onChange={(e) => setCustomCommand(e.target.value)}
                placeholder="Enter command"
              />
            </div>
            <Button
              variant="contained"
              disabled={
                !!activeRequest || !customCommand || customCommand === ''
              }
              onClick={() =>
                sendCommand(customCommand, selectedSlaveController)
              }
            >
              <Icon
                name={!activeRequest ? 'fa-terminal' : 'fa-spinner fa-pulse'}
                className="text"
              />
            </Button>
          </div>}
          {isMobile ? (
            <ShortcutActionsMobileView shortcutButtons={shortcutButtons} />
          ) : (
            <div className="controller-command-card-command-row-react">
              {shortcutButtons}
            </div>
          )}
        </div>
      </CardFooter>
    </CardWithHeader>
  );
};

export const ControllerRemote = memo(ControllerRemoteComponent);
