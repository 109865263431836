import { customElement, bindable, autoinject } from 'aurelia-framework';
import { BaseViewModel } from '../../../../common/BaseViewModel';
import { IAlarm } from '../../../../../interfaces/entity/iAlarm';
import { getLogger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { routeNames } from '../../../../../config/routes';
import { rootState } from '../../../../../reducers';

interface IChannelStateBannerComponentState {
  isMobile: boolean;
}

const mapState = (state: rootState): IChannelStateBannerComponentState => {
  return {
    isMobile: state.device.screenSize === 'mobile'
  };
};

@autoinject()
@customElement('channel-state-banner')
export class ChannelStateBannerComponent extends BaseViewModel<
  IChannelStateBannerComponentState
> {
  @bindable() isParked: boolean;
  @bindable() activeAlarms: IAlarm[];

  constructor(private router: Router) {
    super(getLogger('ChannelStateBannerComponent'), mapState);
  }

  goToAlarms = () => {
    if (!this.features.alarms.read) return;

    console.log('route', routeNames.sitedetailsAlarms);

    this.router.navigateToRoute(routeNames.sitedetailsAlarms, undefined, {
      replace: !this.state.isMobile
    });
  };
}
