import {Checkbox as MuiCheckbox, FormControlLabel, FormGroup} from "@mui/material";
import Icon from "$components/icons/icon/icon.react";
import {default as React} from "react";
import classNames from "classnames";

interface MuiLabeledCheckboxProps {
  checked: boolean;
  indeterminate?: boolean;
  onChange: () => void;
  size?: 'normal' | 'bigger';
  label: string;
  disabled?: boolean;
}

const MuiLabeledCheckbox = ({checked, indeterminate, onChange, size, ...formProps}: MuiLabeledCheckboxProps) => (
  <FormGroup sx={{padding: 0}}>
    <FormControlLabel
      sx={{padding: 0, alignItems:"start", whiteSpace: "nowrap"}}
      control={
        <MuiCheckbox
          className="checkbox-component"
          sx={{paddingY: 0, margin: 0}}
          checked={checked}
          indeterminate={!checked && indeterminate}
          onChange={onChange}
          checkedIcon={
            <span className={'checkmark medium'}>
              <Icon
                className={classNames('icon', checked ? 'checked' : null, size)}
                name={'fa-check'}
              />
            </span>
          }
          indeterminateIcon={
            <span className={'checkmark medium'}>
              <Icon
                className={classNames('icon', checked && 'checked', size)}
                name={'fa-minus'}
              />
            </span>
          }
          icon={
            <span className={classNames('checkmark', size)} />
          }
        />
      }
      {...formProps}
    />
  </FormGroup>
)

export default MuiLabeledCheckbox;
