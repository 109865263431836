import Dropdown from '$components/dropdowns/dropdown/dropdown.react';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { ReactReportProductDropdownQuery_reports_filters_products } from '$typings/graphql';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import {
  ReactReportProductDropdownQueryDocument,
  Reports
} from '$typings/graphql-codegen';
import React, { FC } from 'react';

interface IReportProductDropDownProps {
  selected?: ReactReportProductDropdownQuery_reports_filters_products | string;
  report: Reports;
  itemClicked?: (params: {
    value: ReactReportProductDropdownQuery_reports_filters_products;
    text: string;
  }) => void;
  topElement?: string | boolean | 'IF_QUERY';
  valuePath?: string;
}

const ReportProductDropDown: FC<IReportProductDropDownProps> = ({selected, report, itemClicked, topElement = undefined, valuePath}) => {
  const { data, loading } = useQuery(ReactReportProductDropdownQueryDocument, {report: report});
  const [t] = useCaseInsensitiveTranslation();

  return (
    <div>
      <Dropdown<ReactReportProductDropdownQuery_reports_filters_products>
        items={data?.reports.filters.products as ReactReportProductDropdownQuery_reports_filters_products[] || []}
        displayPath={({ item }: { item: ReactReportProductDropdownQuery_reports_filters_products }) => (item.languageKey ? t(item.languageKey) : item.name)}
        selected={selected}
        loading={loading}
        valuePath={valuePath}
        itemClicked={itemClicked}
        topElement={topElement}
      />
    </div>
  );
};

export default ReportProductDropDown;
