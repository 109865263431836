import TextField from '@mui/material/TextField';
import React, {
  FC,
  HTMLInputTypeAttribute,
  forwardRef,
  ChangeEvent
} from 'react';
import './textbox.css';
import { InputAdornment } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import YaraTheme from '$components/mui-theme-provider/yara-theme';
import ErrorText from '$components/texts/error-text/error-text.react';
import './mui-textfield.css';
import classNames from 'classnames';

export interface ITextFieldProps {
  type?: HTMLInputTypeAttribute;
  icon?: React.ReactNode;
  iconEnd?: React.ReactNode;
  size?: 'medium' | 'small';
  removeWhitespace?: boolean;
  errorText?: string;
}

export type ExtendedTextFieldProps = ITextFieldProps & TextFieldProps;

const MuiTextField: FC<ExtendedTextFieldProps> = forwardRef<
  HTMLInputElement,
  ExtendedTextFieldProps
>(
  (
    {
      icon,
      iconEnd,
      removeWhitespace,
      variant = 'outlined',
      sx,
      InputProps,
      type = 'text',
      size = 'small',
      errorText,
      ...rest
    },
    ref
  ) => {
    const _sx = {
      ...YaraTheme.components?.MuiTextField?.defaultProps?.sx,
      ...sx
    };

    const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
      //Remove whitespace from start of val
      if (evt?.target?.value && removeWhitespace)
        evt.target.value = evt.target.value.trim();

      if (rest.onChange) rest.onChange(evt);
    };

    return (
      <>
        <TextField
          {...rest}
          className={classNames(
            'mui-text-field',
            errorText && 'mui-text-field-error'
          )}
          sx={_sx}
          ref={ref}
          type={type}
          size={size}
          variant={variant}
          onChange={onChange}
          InputProps={
            icon || iconEnd
              ? {
                  ...{
                    startAdornment: icon && (
                      <InputAdornment position="start">{icon}</InputAdornment>
                    ),
                    endAdornment: iconEnd && (
                      <InputAdornment position="end">{iconEnd}</InputAdornment>
                    )
                  },
                  ...InputProps
                }
              : InputProps
          }
        />
        {errorText && (
          <div className="mui-text-field-error-message">
            <ErrorText>{errorText}</ErrorText>
          </div>
        )}
      </>
    );
  }
);

export default MuiTextField;
