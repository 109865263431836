import React, { FC, memo } from 'react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import { Button } from '@mui/material';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { ControllerParameterHistoryQueryDocument } from '$typings/graphql-codegen';
import ErrorText from '$components/texts/error-text/error-text.react';
import Skeleton from '$components/placeholders/skeleton/skeleton';
import { ControllerParameterHistoryRow } from './controller-parameter-history-row';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

interface IParameterHistoryProps {
  controllerParameterId: number;
  closeView: () => unknown;
}

const ControllerParameterHistoryComponent: FC<IParameterHistoryProps> = ({
  controllerParameterId,
  closeView,
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const { data, loading, revalidate } = useQuery(
    ControllerParameterHistoryQueryDocument,
    { controllerParameterId: controllerParameterId }
  );

  return (
    <>
      {!loading && !data && (
        <div className="mar_lm mar_rm">
          <ErrorText bold>{t('ui_common_error_loading_data_failed')}</ErrorText>
        </div>
      )}

      {!loading && data && (
        <div className="mar_lm overflow-auto">
          {data.controllerParametersHistory?.all.length === 0 ? (
            <>
              <div className="flex column flex_1 aicenter">
                <span>
                  {t(
                    'ui_sitedetails_controllers_controllerdetails_controllerparameters_no_history_exists'
                  )}
                </span>
              </div>
            </>
          ) : (
            <>
              {data.controllerParametersHistory?.all.map((row) => (
                <ControllerParameterHistoryRow
                  key={row.controllerParameterHistoryId}
                  item={row}
                />
              ))}
            </>
          )}
        </div>
      )}

      {loading && !data && <Skeleton rowCount={3}></Skeleton>}

      <CardFooter className="flex jsb">
        <Button onClick={closeView} variant="outlined">
          {t('ui_common_close')}
        </Button>
        <Button onClick={revalidate} variant="outlined">
          {t(!data && !loading ? 'ui_common_tryagain' : 'ui_common_refresh')}
        </Button>
      </CardFooter>
    </>
  );
};

export const ControllerParameterHistory = memo(
  ControllerParameterHistoryComponent
);
