import React, { FC } from 'react';

interface ParkedIconProps {
  className?: string;
}

const Parked: FC<ParkedIconProps> = ({ className }) => (
  <svg width="1em" height="1em" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M105.262 0H14.7385C6.59865 0 0 6.59865 0 14.7385v90.5235C0 113.401 6.59865 120 14.7385 120h90.5235c8.139 0 14.738-6.599 14.738-14.738V14.7385C120 6.59865 113.401 0 105.262 0Z"
      fill="#fff"
    />
    <path
      d="M105.556 3.92133H14.4443c-5.81168 0-10.52297 4.71129-10.52297 10.52297v91.1117c0 5.811 4.71129 10.523 10.52297 10.523h91.1117c5.811 0 10.523-4.712 10.523-10.523V14.4443c0-5.81168-4.712-10.52297-10.523-10.52297Z"
      fill="#5E8AB4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.394 16.6411v86.7179h20.6194V71.4588h22.1335c14.7603-.1513 25.3029-12.6161 24.408-30.8136-.7521-15.2948-14.5029-23.8829-26.7992-23.8698l-40.3617-.1343Zm20.7328 38.6774V32.6602h15.1778c6.8885-.0452 11.1799 3.8034 11.4374 11.0038-.262 7.3947-4.3094 11.8488-11.2206 11.817l-15.3946-.1626v.0001Z"
      fill="#fff"
    />
  </svg>
);

export default Parked;
