import Banner from '$components/banners/banner/banner.react';
import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import Card from '$components/cards/card/card.react';
import Checkbox from '$components/checkbox/checkbox.react';
import DatePicker from '$components/date-picker/date-picker.react';
import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import CustomerDropdown from '$components/dropdowns/customer-dropdown/customer-dropdown.react';
import FormElement from '$components/forms/form-element/form-element.react';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import Pill from '$components/pill/pill.react';
import TextField from '$components/textbox/text-field.react';
import Moment from 'moment';
import { userHasFeature } from '$lib/authorizationHelper';
import { getCurrentTimeAsString } from '$lib/dateHelpers';
import { isNone, isNotEmpty, isSomething, isEmpty } from '$lib/helpers';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import { getAllowedColumns } from '$pages/common';
import SitegroupFiltersDropdown from '$pages/common/sitegroup-filters-dropdown/sitegroup-filters-dropdown.react';
import { mapReportPeriodToTitle } from '$pages/scheduled-reports/schedule-reports-helpers';

import {
  AlarmTypeEnum,
  ReactReportFiltersCardQueryDocument,
  ReactUpsertScheduledReportDocument,
  ReactUpsertScheduledReportMutationVariables,
  ReportFormatEnum,
  ReportPeriodEnum,
  Reports,
  ReportScheduleRecurrence,
  ScheduledReportFilter,
  ScheduleTypeEnum
} from '$typings/graphql-codegen';
import React, { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { features } from '../../../../config';
import { getSession } from '../../../../config/sessionService';
import { AccessLevel } from '$interfaces/enums';
import { IReportFilter } from './modules/IReportFilter';
import ReportProductDropDown from '../report-product-dropdown/report-product-dropdown.react';
import ScheduleReportModal from './modules/schedule-report-modal';
import './schedule-report-filters.css';
import Skeleton from '$components/placeholders/skeleton/skeleton';
import ActionButton from '$components/actions/action-button/action-button.react';
import ActionContentItem from '$components/actions/action-content-item/action-content-item.react';
import {
  addTermToReportFilter,
  firstAccessTagFilterOrEmpty,
  firstBuidFilterOrEmpty,
  firstControllerTypeFilterOrEmpty,
  firstProductFilterOrEmpty,
  firstShipToFilterOrEmpty,
  firstSoldToFilterOrEmpty,
  IAccessTagBrief,
  IBuidBrief,
  IProductBrief,
  removeTermFromReportFilter,
  reportFilterHasValues,
  reportFilterIsFalsyOrEmpty,
  toAccessTagBrief,
  toBuidBrief,
  toProductBrief
} from './modules/reportFilterUtilities';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import ErrorText from '$components/texts/error-text/error-text.react';
import ControllerTypeDropdown from '$components/dropdowns/controllertype-dropdown/controllertype-dropdown';
import { WarningText } from '$components/texts/warning-text/warning-text.react';

export interface FormValues {
  reportPeriod?: ReportPeriodEnum | null;
  alarmType?: AlarmTypeEnum[] | null;
  report: Reports;
  filterId?: string | null;
  accessTagReportFilter?: IReportFilter;
  buidReportFilter?: IReportFilter;
  productReportFilter?: IReportFilter;
  shipToReportFilter?: IReportFilter;
  soldToReportFilter?: IReportFilter;
  controllerTypeReportFilter?: IReportFilter;
  selectedReportFormat?: ReportFormatEnum;
}

export interface IScheduledReportFilter {
  scheduledReportId?: string;
  report: Reports;
  filtersChanged?: (input: ScheduledReportFilter) => void;
  disableScheduledReports?: boolean;
  reportIsRunning?: boolean;
  supportedFormats?: Array<ReportFormatEnum>,
  allowRunningUnfiltered?: boolean;
}

type PeriodFilterType = boolean | 'expanded' | 'previousMonthOnly';

export interface IScheduledReportFiltersConfig {
  customerFilter?: boolean;
  alarmTypeFilter?: boolean;
  siteGroupFilter?: boolean;
  productFilter?: boolean;
  soldToFilter?: boolean;
  shipToFilter?: boolean;
  periodFilter?: PeriodFilterType;
  buidFilter?: boolean;
  controllerTypeFilter?: boolean
}

export type ScheduledReportFiltersConfigDateSelection = 'range' | 'endof' | 'yearAndMonth';

const userHasScheduledRerpotsFeature = () =>
  userHasFeature(getSession(), features.scheduledReports, AccessLevel.Read);

export const getScheduleReportDateSelectionTypeForReportType = (
  report: Reports
): ScheduledReportFiltersConfigDateSelection => {
  switch (report) {
    case Reports.ProductStock:
      return 'endof';
    case Reports.NitratesReport:
      return 'yearAndMonth';
  }
  return 'range';
};

const getScheduleReportsFiltersForReportType = (
  report: Reports
): IScheduledReportFiltersConfig | undefined => {
  switch (report) {
    case Reports.ProductStock:
      return {
        customerFilter: true,
        soldToFilter: true,
        siteGroupFilter: true,
        shipToFilter: true,
        productFilter: true,
        periodFilter: true
      };
    case Reports.StockVsDose:
      return {
        siteGroupFilter: true,
        customerFilter: true,
        periodFilter: true
      };
    case Reports.Alarm:
      return {
        siteGroupFilter: true,
        customerFilter: true,
        periodFilter: false,
        alarmTypeFilter: true
      };
    case Reports.UserStatistics:
      return {
        buidFilter: true,
        periodFilter: true,
        siteGroupFilter: false,
        customerFilter: false,
        alarmTypeFilter: false,
        shipToFilter: false
      };
    case Reports.NewSites:
      return {
        buidFilter: true,
        periodFilter: true,
        customerFilter: true,
        siteGroupFilter: false,
        alarmTypeFilter: false,
        shipToFilter: false
      };
    case Reports.IdaDeliveries:
      return {
        periodFilter: 'expanded',
      };
    case Reports.IdaLifecycle:
      return {
        buidFilter: true,
        controllerTypeFilter: true
      };
    case Reports.Dosing:
      return {
        buidFilter: true,
        periodFilter: true,
        customerFilter: true,
        productFilter: true
      };
    case Reports.Temperature:
      return {
        buidFilter: true,
        periodFilter: true,
        customerFilter: true
      };
    case Reports.DryRun:
      return {
        customerFilter: true,
        soldToFilter: true,
        siteGroupFilter: true,
        shipToFilter: true,
        productFilter: true,
        periodFilter: true
      };
    case Reports.DeviceType:
      return {
        buidFilter: true,
        controllerTypeFilter: true
      }
    case Reports.DailyDose:
      return {
        buidFilter: true,
        periodFilter: true,
        customerFilter: true,
        siteGroupFilter: false,
        alarmTypeFilter: false,
        shipToFilter: false
      };
    case Reports.SiteRefills:
      return {
        customerFilter: true,
        periodFilter: true
      };
    case Reports.NitratesReport:
      return {
        buidFilter: true,
        periodFilter: 'previousMonthOnly',
        customerFilter: true,
        siteGroupFilter: true,
      };
  }
  return;
};

const mapToYT3AlarmTypeEnum = (number: number) => {
  switch (number) {
    case 1:
      return AlarmTypeEnum.Custom;
    case 2:
      return AlarmTypeEnum.LowLevel;
    case 3:
      return AlarmTypeEnum.PreLowLevel;
    case 4:
      return AlarmTypeEnum.Configuration;
    case 5:
      return AlarmTypeEnum.Offline;
    case 6:
      return AlarmTypeEnum.PercentCapacity30;
    case 8:
      return AlarmTypeEnum.DoseCalibration;
    case 9:
      return AlarmTypeEnum.SeriousDoseCalibration;
    case 10:
      return AlarmTypeEnum.BrokenSensor;
    default:
      throw new Error("Cant map " + number + "to alarmTypeEnum");
  }
};

const defaultAlarmTypes: AlarmTypeEnum[] = [1, 2, 3, 4, 5, 6, 8, 9, 10].map(mapToYT3AlarmTypeEnum);

const enterKeyPressed = (code: string) => {
  return code === 'Enter' || code === 'NumpadEnter';
}

const ScheduledReportFilter: FC<IScheduledReportFilter> = ({
  scheduledReportId,
  report,
  filtersChanged,
  disableScheduledReports,
  supportedFormats,
  reportIsRunning,
  allowRunningUnfiltered
}) => {
  const alarmTypes = Object.values(AlarmTypeEnum);
  const {
    handleSubmit,
    control,
    setError,
    // Read the formState before render to subscribe the form state through the Proxy
    formState: { isValid, errors },
    watch,
    setValue,
    getValues
  } = useForm<FormValues>({
    defaultValues: {
      report: report,
      reportPeriod: report === Reports.NitratesReport 
        ? ReportPeriodEnum.PreviousMonth 
        : ReportPeriodEnum.PreviousDay,
      alarmType: alarmTypes
    }
  });
  const filters = getScheduleReportsFiltersForReportType(report);
  const dateSelectionType = getScheduleReportDateSelectionTypeForReportType(report);
  const accessToColumn = getAllowedColumns(getSession());
  const [modalOpen, setModalOpen] = useState(false);
  const formvalues = watch();
  const formBuidReportFilter = watch('buidReportFilter');
  const formControllerTypeReportFilter = watch('controllerTypeReportFilter');
  const formAccessTagReportFilter = watch('accessTagReportFilter');
  const formProductReportFilter = watch('productReportFilter');
  const formShipToReportFilter = watch('shipToReportFilter');
  const formSoldToReportFilter = watch('soldToReportFilter');
  const [selectedFromPeriod, setSelectedFromPeriod] = useState<Date | undefined>(new Date());
  const [selectedToPeriod, setSelectedToPeriod] = useState<Date | undefined>(new Date());
  const [selectedFormat, setSelectedFormat] = useState<ReportFormatEnum>(ReportFormatEnum.Excel);
  const [isSchedulablePeriod, setIsSchedulablePeriod] = useState<boolean | undefined>(true);
  const [showAllBuidPills, setShowAllBuidPills] = useState(false);
  const [showAllControllerTypePills, setShowAllControllerTypePills] = useState(false);
  const [showAllAccessTagPills, setShowAllAccessTagPills] = useState(false);
  const [showAllProductPills, setShowAllProductPills] = useState(false);
  const [showAllShipToPills, setShowAllShipToPills] = useState(false);
  const [showAllSoldToPills, setShowAllSoldToPills] = useState(false);
  const [allBuids, setAllBuids] = useState<IBuidBrief[]>([]);                       // used for frontend validations, not for picking buids
  const [allAccessTags, setAllAccessTags] = useState<IAccessTagBrief[]>([]);        // used for frontend validations, not for picking access tags
  const [allProducts, setAllProducts] = useState<IProductBrief[]>([]);                    // used for displaying product names in the users language
  const [t, i18n] = useCaseInsensitiveTranslation();
  const session = getSession();
  const [allowRunningReport, setAllowRunningReport] = useState<boolean>(false);
  
  const currUserHasAccessToBuidFilter: boolean = (() => {
    const isSuperUser: boolean = session.currentUser?.isSuperUser ?? false
    const hasAllBuids: boolean = session.currentUser?.allBuidAllowed ?? false
    const hasAnyAllowedBuids: boolean = session.allowedBuids.length > 0
    return isSuperUser || hasAllBuids || hasAnyAllowedBuids
  })()
  
  const currUserHasAccessToCustomerFilter: boolean = (() => {
    const isSuperUser: boolean = session.currentUser?.isSuperUser ?? false
    const hasAllCustomers = session.currentUser?.allCustomerAllowed ?? false
    const hasAnyAllowedCustomers = session.allowedCustomers.length > 0
    return isSuperUser || hasAllCustomers || hasAnyAllowedCustomers
  })()
  
  const renderBuidFilter: boolean = (() => {
    const hasAccessToBuidColumn = accessToColumn.Buid
    const hasAccessToBuidFilter = filters?.buidFilter ?? false
    const hasAccess: boolean = hasAccessToBuidColumn && hasAccessToBuidFilter
    return hasAccess && currUserHasAccessToBuidFilter
  })()
  
  const renderCustomerFilter: boolean = (() => {
    const hasAccessToCustomerColumn: boolean = accessToColumn.CustomerName
    const hasAccessToCustomerFilter: boolean = filters?.customerFilter ?? false
    const hasAccess: boolean = hasAccessToCustomerColumn && hasAccessToCustomerFilter
    return hasAccess && currUserHasAccessToCustomerFilter
  })()

  const renderControllerTypeFilter: boolean = (() => {
    const hasAccessToDeviceTypeColumn = accessToColumn.DeviceType
    const hasAccessToControllerTypeFilter = filters?.controllerTypeFilter ?? false
    const hasAccess: boolean = hasAccessToDeviceTypeColumn && hasAccessToControllerTypeFilter
    return hasAccess
  })()

  const { data, loading, error } = useQuery(ReactReportFiltersCardQueryDocument, {
    scheduledReportId: isSomething(scheduledReportId) ? scheduledReportId : '0',
    accessToAllBuids: session.currentUser?.isSuperUser === true || session.currentUser?.allBuidAllowed === true,
    accessToAllAccessTags: session.currentUser?.isSuperUser === true || session.currentUser?.allCustomerAllowed === true,
    languageCode: i18n.language
  },
    { revalidateOnEvents: false });

  const hasAccessToBuidFilter = (buidTerm: string): boolean => {
    const session = getSession();
    if (session.currentUser.isSuperUser || session.currentUser.allBuidAllowed) return true;
    const buidTermLowercase = buidTerm.toLowerCase();
    const buidMatches: IBuidBrief[] = allBuids.filter(buid => buid.name.toLowerCase().indexOf(buidTermLowercase) >= 0)

    return isSomething(buidMatches.length) && buidMatches.length > 0;
  };

  const hasAccessToAccessTagFilter = (accessTagTerm: string): boolean => {
    const session = getSession();
    if (session.currentUser.isSuperUser || session.currentUser.allBuidAllowed) return true;
    const accessTagTermLowercase = accessTagTerm.toLowerCase();
    const accessTagMatches: IAccessTagBrief[] = allAccessTags.filter(accessTag => accessTag.name.toLowerCase().indexOf(accessTagTermLowercase) >= 0)

    return isSomething(accessTagMatches.length) && accessTagMatches.length > 0;
  }

  useEffect(() => {
    if (!data) return;
    
    if (data.scheduledReport?.reportFormat)
      setSelectedFormat(data.scheduledReport?.reportFormat);
    
    if (data.scheduledReport?.filterId) {
      setValue('filterId', data.scheduledReport?.filterId?.toString());
    } else {
      setValue('buidReportFilter', firstBuidFilterOrEmpty(data));
      setValue('accessTagReportFilter', firstAccessTagFilterOrEmpty(data));
      setValue('productReportFilter', firstProductFilterOrEmpty(data));
      setValue('shipToReportFilter', firstShipToFilterOrEmpty(data));
      setValue('soldToReportFilter', firstSoldToFilterOrEmpty(data));
      setValue('controllerTypeReportFilter', firstControllerTypeFilterOrEmpty(data));
    }

    const reportPeriod = data.scheduledReport?.reportPeriod;
    if (isSomething(reportPeriod))
      setValue('reportPeriod', reportPeriod);

    const alarmTypes = (isNone(data.scheduledReport)
      ? defaultAlarmTypes
      : data.scheduledReport?.alarmType
        ?.split(',')
        .filter(isNotEmpty)
        .map(v => mapToYT3AlarmTypeEnum(ensureNumber(v))));

    setValue('alarmType', alarmTypes);

    // init data for frontend validations:
    setAllBuids(isSomething(data.buids)
      ? data.buids.map(buid => toBuidBrief(buid, true)).filter(isSomething) as IBuidBrief[]
      : []);
    setAllAccessTags(isSomething(data.customers)
      ? data.customers?.map(cust => toAccessTagBrief(cust, true)).filter(isSomething) as IAccessTagBrief[]
      : []);
    setAllProducts(isSomething(data.products)
      ? data.products?.map(prod => toProductBrief(prod)).filter(isSomething) as IProductBrief[]
      : []);
  }, [data]);

  useEffect(() =>   {

    // if user is a superuser, or has access to more than one buid, the user is not allowed to run or schedule a report without applying filters.
    if(!allowRunningUnfiltered && 
      (session.currentUser?.isSuperUser || session.currentUser.allBuidAllowed || session.allowedBuids.length > 1)) {
   
        if(isNone(formvalues.filterId)
          && isEmpty(formvalues.buidReportFilter?.values)
          && isEmpty(formvalues.controllerTypeReportFilter?.values)
          && isEmpty(formvalues.accessTagReportFilter?.values)
          && isEmpty(formvalues.productReportFilter?.values)
          && isEmpty(formvalues.shipToReportFilter?.values)
          && isEmpty(formvalues.soldToReportFilter?.values)) {

          setAllowRunningReport(false);
        }
        else{
          setAllowRunningReport(true);
        }
      }
      else{
        setAllowRunningReport(true);
      }
   }, [formvalues])

  const saveChangesToScheduledReport = async () => {
    const values = getValues();
    const variables: ReactUpsertScheduledReportMutationVariables = {
      input: {
        scheduledReportId: scheduledReportId,
        reportPeriod: values.reportPeriod,
        alarmType: filters?.alarmTypeFilter ? values.alarmType : undefined,
        report: values.report,
        filterId: (filters?.siteGroupFilter && isSomething(values.filterId)
          ? ensureNumber(values.filterId)
          : undefined),
        buidFilters: (filters?.buidFilter && isSomething(values.buidReportFilter)
          ? [values.buidReportFilter]
          : undefined),
        controllerTypeFilters: (filters?.controllerTypeFilter && isSomething(values.controllerTypeReportFilter)
          ? [values.controllerTypeReportFilter]
          : undefined
        ),
        accessTagFilters: (filters?.customerFilter && isSomething(values.accessTagReportFilter)
          ? [values.accessTagReportFilter]
          : undefined),
        productFilters: (filters?.productFilter && isSomething(values.productReportFilter)
          ? [values.productReportFilter]
          : undefined),
        shipToFilters: (filters?.shipToFilter && isSomething(values.shipToReportFilter)
          ? [values.shipToReportFilter]
          : undefined),
        soldToFilters: (filters?.soldToFilter && isSomething(values.soldToReportFilter)
          ? [values.soldToReportFilter]
          : undefined),
        reportFormat: selectedFormat ?? values?.selectedReportFormat ?? ReportFormatEnum.Excel,
        reportSchedule: {
          enabled: true,
          scheduleType: data?.scheduledReport?.reportSchedule?.scheduleType || ScheduleTypeEnum.User,
          systemReportRecipients: data?.scheduledReport?.reportSchedule?.systemReportRecipients,
          recurrence: ReportScheduleRecurrence.Daily,
          triggerTimeLocal: getCurrentTimeAsString(),
          timeZoneId: 114,
          scheduleName: null,
          reportScheduleId: null,
          recurrenceDetails: null
        }
      }
    };
    
    const response = await mutate(
      ReactUpsertScheduledReportDocument,
      variables
    );

    if (!response || !response.upsertScheduledReport)
      throw Error('Could not upsert schedule report');

    const scheduleId = response.upsertScheduledReport.reportScheduleId;

    document.location.href = `scheduled-reports/scheduled-reports/details/${scheduleId}`;
  };

  const runReport: SubmitHandler<FormValues> = async formValues =>  {
    const input = {
      accessTagFilters: filters?.customerFilter && formAccessTagReportFilter ? [formAccessTagReportFilter] : [],
      buidFilters: filters?.buidFilter && formBuidReportFilter ? [formBuidReportFilter] : [],
      controllerTypeFilters: filters?.controllerTypeFilter && formControllerTypeReportFilter ? [formControllerTypeReportFilter] : [],
      productFilters: filters?.productFilter && formProductReportFilter ? [formProductReportFilter] : [],
      filterId: filters?.siteGroupFilter && formValues.filterId || null,
      fromDate: selectedFromPeriod,
      fromDateOffset: getUtcOffset(selectedFromPeriod),
      toDate: selectedToPeriod,
      toDateOffset: getUtcOffset(selectedToPeriod),
      reportPeriod: formValues.reportPeriod,
      shipToFilters: filters?.shipToFilter && formShipToReportFilter ? [formShipToReportFilter] : [],
      soldToFilters: filters?.soldToFilter && formSoldToReportFilter ? [formSoldToReportFilter] : [],
      alarmType: filters?.alarmTypeFilter ? formValues.alarmType : undefined,
      reportFormat: selectedFormat,
    };

    if (!input.reportPeriod) {
      if (dateSelectionType === 'range' && !input.toDate && !input.fromDate) {
        setError('reportPeriod', { type: 'required', message: t("ui_reports_missing_from_and_to_date") });
        return;
      }
      else if (dateSelectionType === 'range' && !input.fromDate) {
        setError('reportPeriod', { type: 'required', message: t("ui_reports_missing_from_date") });
        return;
      }
      else if (!input.toDate) {
        setError('reportPeriod', {
          type: 'required',
          message: dateSelectionType === 'range'
            ? t("ui_reports_missing_to_date")
            : "ui_reports_missing_specific_date"
        });
        return;
      }
    }

    // Update from- and to-date according to report period
    if (input.reportPeriod) {
      let fromDate = new Date(input?.fromDate ?? '')
      let toDate = new Date(input?.toDate ?? '')
      
      switch (input.reportPeriod) {
        case ReportPeriodEnum.PreviousDay:
          fromDate.setDate(fromDate.getDate() - 1)
          break
        case ReportPeriodEnum.PreviousWeek:
          fromDate.setDate(fromDate.getDate() - 7)
          break
        case ReportPeriodEnum.PreviousMonth:
          fromDate.setMonth(fromDate.getMonth() - 1)
          break
        case ReportPeriodEnum.PreviousQuarter:
          fromDate.setMonth(fromDate.getMonth() - 3)
          break
        case ReportPeriodEnum.Previous6Months:
          fromDate.setMonth(fromDate.getMonth() - 6)
          break
        case ReportPeriodEnum.PreviousYear:
          fromDate.setFullYear(fromDate.getFullYear() - 1)
          break
      }
      
      // Update
      input.fromDate = fromDate
      input.toDate = toDate
    }
    
    filtersChanged && filtersChanged(input);
  };

  const getUtcOffset = (date: Date | undefined) => {
    if (date !== undefined)
      return Moment(date).utcOffset();

    return 0.0;
  }

  const getPeriodslist = (periodFilter?: PeriodFilterType) => {
    if (periodFilter === undefined) return [];
    
    const dateSelectionType = getScheduleReportDateSelectionTypeForReportType(
      report
    );
    
    if (periodFilter === 'previousMonthOnly') {
      return [
        {
          value: ReportPeriodEnum.PreviousMonth,
          label: mapReportPeriodToTitle(ReportPeriodEnum.PreviousMonth, report),
          schedulable: true
        },
        {
          value: ReportPeriodEnum.Custom,
          label: 'Custom month',
          schedulable: false
        }
      ].filter(a => !!a);
    }
    
    return [
      {
        value: ReportPeriodEnum.PreviousDay,
        label: mapReportPeriodToTitle(ReportPeriodEnum.PreviousDay, report),
        schedulable: true
      },
      {
        value: ReportPeriodEnum.PreviousWeek,
        label: mapReportPeriodToTitle(ReportPeriodEnum.PreviousWeek, report),
        schedulable: true
      },
      {
        value: ReportPeriodEnum.PreviousMonth,
        label: mapReportPeriodToTitle(ReportPeriodEnum.PreviousMonth, report),
        schedulable: true
      },
      periodFilter === 'expanded' ? ({
        value: ReportPeriodEnum.PreviousQuarter,
        label: mapReportPeriodToTitle(ReportPeriodEnum.PreviousQuarter, report),
        schedulable: true
      }) : undefined,

      dateSelectionType === 'range'
        ? {
          value: ReportPeriodEnum.Previous6Months,
          label: mapReportPeriodToTitle(
            ReportPeriodEnum.Previous6Months,
            report
          ),
          schedulable: true
        }
        : undefined,

      periodFilter === 'expanded' ? ({
        value: ReportPeriodEnum.PreviousYear,
        label: mapReportPeriodToTitle(ReportPeriodEnum.PreviousYear, report),
        schedulable: true
      }) : undefined,

      {
        value: ReportPeriodEnum.Custom,
        label:
          dateSelectionType === 'range'
            ? 'Specific dates'
            : 'End of specific date',
        schedulable: false
      }
    ].filter(a => !!a);
  };

  function cancelEdit() {
    window.history.back();
  }

  return (
    <div className="schedule-report-filters-component">
      {(loading || error) && (
        <Card className="filters">
          <CardContent className="relative">
            <Skeleton rowCount={6} />
          </CardContent>
        </Card>
      )}
      {!loading && !error && (
        <Card className="filters">
          <form onSubmit={handleSubmit(runReport)}>
            <CardContent className="no-top-padding">
              {filters?.siteGroupFilter && (
                <FormElement label="Sitegroup" direction="column">
                  <Controller
                    name="filterId"
                    control={control}
                    render={({ field }) => (
                      <SitegroupFiltersDropdown
                        filteringRule='private'
                        selected={field.value || undefined}
                        itemClicked={item => {
                          setValue('buidReportFilter', undefined);
                          setValue('accessTagReportFilter', undefined);
                          setValue('productReportFilter', undefined);
                          setValue('shipToReportFilter', undefined);
                          setValue('soldToReportFilter', undefined);
                          field.onChange(item.value);
                        }}
                        topElement={t('ui_common_none') as string}
                      />
                    )}
                  />
                </FormElement>
              )}
              {renderBuidFilter && (
                <FormElement
                  label="Buid"
                  className="no-bottom-border"
                  direction="column"
                >
                  <Controller
                    control={control}
                    name="buidReportFilter"
                    render={({ field }) => (
                      <BuidDropdown
                        placeholder={t('ui_common_add_buid_tags')}
                        value-path="name"
                        topElement="IF_QUERY"
                        replaceTopElement={s => {
                          return <div>{t('ui_common_add')}: {s}</div>;
                        }}
                        itemClicked={v => {
                          if (v.name && !formBuidReportFilter?.values.includes(v.name) && hasAccessToBuidFilter(v.name)) {
                            setValue('filterId', undefined);
                            setValue('accessTagReportFilter', undefined);
                            return field.onChange(addTermToReportFilter(v.name, formBuidReportFilter));
                          }
                          return;
                        }}
                      />
                    )}
                  />
                  {reportFilterIsFalsyOrEmpty(formBuidReportFilter) && <span className="no-pills">{t('ui_common_none')}</span>}
                  {reportFilterHasValues(formBuidReportFilter) && (
                    <div className="pills">
                      <Controller
                        control={control}
                        name="buidReportFilter"
                        render={({ field }) => (
                          <>
                            {(showAllBuidPills
                              ? formBuidReportFilter?.values ?? []
                              : formBuidReportFilter?.values.slice(0, 3) ?? []
                            ).map(tag => (
                              <Pill
                                key={`buid-${tag}`}
                                className="pill"
                                disabled={!hasAccessToBuidFilter(tag)}
                                iconClicked={() =>
                                  field.onChange(
                                    removeTermFromReportFilter(tag, formBuidReportFilter)
                                  )
                                }
                              >
                                {tag}
                              </Pill>
                            ))}
                          </>
                        )}
                      />
                      {!showAllBuidPills && formBuidReportFilter !== undefined && formBuidReportFilter.values.length > 3 && (
                        <Button
                          variant="tertiary"
                          className="pill"
                          onClick={() => setShowAllBuidPills(true)}
                        >
                          +{formBuidReportFilter.values.length - 3} BUID
                        </Button>
                      )}
                    </div>
                  )}
                </FormElement>
              )}

              {renderControllerTypeFilter && (
                <FormElement
                  label="ControllerType"
                  className="no-bottom-border"
                  direction="column"
                >
                  <Controller
                    control={control}
                    name="controllerTypeReportFilter"
                    render={({ field }) => (
                      <ControllerTypeDropdown
                        placeholder={t('ui_common_add_controller_types')}
                        value-path="name"
                        topElement="IF_QUERY"
                        replaceTopElement={s => {
                          return <div>{t('ui_common_add')}: {s}</div>;
                        }}
                        itemClicked={c => {
                          if (c && !formControllerTypeReportFilter?.values.includes(c)) {
                            setValue('filterId', undefined);
                            return field.onChange(addTermToReportFilter(c, formControllerTypeReportFilter));
                          }
                          return;
                        }}
                      />
                    )}
                  />
                  {reportFilterIsFalsyOrEmpty(formControllerTypeReportFilter) && <span className="no-pills">{t('ui_common_none')}</span>}
                  {reportFilterHasValues(formControllerTypeReportFilter) && (
                    <div className="pills">
                      <Controller
                        control={control}
                        name="controllerTypeReportFilter"
                        render={({ field }) => (
                          <>
                            {(showAllControllerTypePills
                                ? formControllerTypeReportFilter?.values ?? []
                                : formControllerTypeReportFilter?.values.slice(0, 3) ?? []
                            ).map(tag => (
                              <Pill
                                key={`controllerType-${tag}`}
                                className="pill"
                                iconClicked={() =>
                                  field.onChange(
                                    removeTermFromReportFilter(tag, formControllerTypeReportFilter)
                                  )
                                }
                              >
                                {tag}
                              </Pill>
                            ))}
                          </>
                        )}
                      />
                      {!showAllControllerTypePills && formControllerTypeReportFilter !== undefined && formControllerTypeReportFilter.values.length > 3 && (
                        <Button
                          variant="tertiary"
                          className="pill"
                          onClick={() => setShowAllControllerTypePills(true)}
                        >
                          +{formControllerTypeReportFilter.values.length - 3} BUID
                        </Button>
                      )}
                    </div>
                  )}
                </FormElement>
              )}
              
              {renderCustomerFilter && (
                <FormElement
                  label="AccessTag"
                  className="no-bottom-border"
                  direction="column"
                >
                  <Controller
                    control={control}
                    name="accessTagReportFilter"
                    render={({ field }) => (
                      <CustomerDropdown
                        placeholder={t('ui_common_add_accesstag_tags')}
                        value-path="name"
                        topElement="IF_QUERY"
                        replaceTopElement={s => {
                          return <div>{t('ui_common_add')}: {s}</div>;
                        }}
                        itemClicked={v => {
                          if (v.name && !formAccessTagReportFilter?.values.includes(v.name) && hasAccessToAccessTagFilter(v.name)) {
                            setValue('filterId', undefined);
                            setValue('buidReportFilter', undefined);
                            return field.onChange(addTermToReportFilter(v.name, formAccessTagReportFilter));
                          }
                          return;
                        }}
                      />
                    )}
                  />
                  {reportFilterIsFalsyOrEmpty(formAccessTagReportFilter) && <span className="no-pills">{t('ui_common_none')}</span>}
                  {reportFilterHasValues(formAccessTagReportFilter) && (
                    <div className="pills">
                      <Controller
                        control={control}
                        name="accessTagReportFilter"
                        render={({ field }) => (
                          <>
                            {(showAllAccessTagPills
                              ? formAccessTagReportFilter?.values ?? []
                              : formAccessTagReportFilter?.values.slice(0, 3) ?? []
                            ).map(tag => (
                              <Pill
                                key={`at-${tag}`}
                                className="pill"
                                disabled={!hasAccessToAccessTagFilter(tag)}
                                iconClicked={() =>
                                  field.onChange(
                                    removeTermFromReportFilter(tag, formAccessTagReportFilter)
                                  )
                                }
                              >
                                {tag}
                              </Pill>
                            ))}
                          </>
                        )}
                      />
                      {!showAllAccessTagPills && formAccessTagReportFilter !== undefined && formAccessTagReportFilter.values.length > 3 && (
                        <Button
                          variant="tertiary"
                          className="pill"
                          onClick={() => setShowAllAccessTagPills(true)}
                        >
                          +{formAccessTagReportFilter.values.length - 3}  Access Tag
                        </Button>
                      )}
                    </div>
                  )}
                </FormElement>
              )}
              {accessToColumn.ProductName && filters?.productFilter && (
                <FormElement
                  label="Product"
                  className="no-bottom-border"
                  direction="column"
                >
                  <Controller
                    control={control}
                    name="productReportFilter"
                    render={({ field }) => (
                      <ReportProductDropDown
                        report={report}
                        topElement={t('ui_common_none') as string}
                        value-path="name"
                        itemClicked={v => {
                          if (v.value && v.value.productId && !formProductReportFilter?.values.includes(v.value.productId)) {
                            setValue('filterId', undefined);
                            return field.onChange(addTermToReportFilter(v.value.productId, formProductReportFilter, false, false));
                          }
                          return;
                        }}
                      />
                    )}
                  />
                  {reportFilterIsFalsyOrEmpty(formProductReportFilter) && <span className="no-pills">{t('ui_common_none')}</span>}
                  {reportFilterHasValues(formProductReportFilter) && (
                    <div className="pills">
                      <Controller
                        control={control}
                        name="productReportFilter"
                        render={({ field }) => (
                          <>
                            {(showAllProductPills
                              ? formProductReportFilter?.values ?? []
                              : formProductReportFilter?.values.slice(0, 3) ?? []
                            ).map(tag => (
                              <Pill
                                key={`prod-${tag}`}
                                className="pill"
                                iconClicked={() =>
                                  field.onChange(
                                    removeTermFromReportFilter(tag, formProductReportFilter)
                                  )
                                }
                              >
                                {allProducts.find(prod => prod.productId === tag)?.bestMatchTranslation}
                              </Pill>
                            ))}
                          </>
                        )}
                      />
                      {!showAllProductPills && formProductReportFilter !== undefined && formProductReportFilter.values.length > 3 && (
                        <Button
                          variant="tertiary"
                          className="pill"
                          onClick={() => setShowAllProductPills(true)}
                        >
                          +{formProductReportFilter.values.length - 3}  {t('UI_Reports_Product')}
                        </Button>
                      )}
                    </div>
                  )}
                </FormElement>
              )}
              {accessToColumn.SoldTo && filters?.soldToFilter && (
                <FormElement
                  label="Sold To"
                  className="no-bottom-border"
                  direction="column"
                >
                  <Controller
                    control={control}
                    name="soldToReportFilter"
                    render={({ field }) => (
                      <TextField
                        onKeyPress={(evt) => {
                          if (!enterKeyPressed(evt.code)) {
                            return true;
                          }
                          const inputEl = (evt.target as HTMLInputElement);
                          field.onChange(addTermToReportFilter(inputEl.value, formSoldToReportFilter));
                          inputEl.value = '';
                          setValue('filterId', undefined);
                          evt.preventDefault();
                          return false;
                        }}
                      />)
                    }
                  />
                  {reportFilterIsFalsyOrEmpty(formSoldToReportFilter) && <span className="no-pills">{t('ui_common_none')}</span>}
                  {reportFilterHasValues(formSoldToReportFilter) && (
                    <div className="pills">
                      <Controller
                        control={control}
                        name="soldToReportFilter"
                        render={({ field }) => (
                          <>
                            {(showAllSoldToPills
                              ? formSoldToReportFilter?.values ?? []
                              : formSoldToReportFilter?.values.slice(0, 3) ?? []
                            ).map(tag => (
                              <Pill
                                key={`sold-${tag}`}
                                className="pill"
                                iconClicked={() =>
                                  field.onChange(
                                    removeTermFromReportFilter(tag, formSoldToReportFilter)
                                  )
                                }
                              >
                                {tag}
                              </Pill>
                            ))}
                          </>
                        )}
                      />
                      {!showAllShipToPills && formSoldToReportFilter !== undefined && formSoldToReportFilter.values.length > 3 && (
                        <Button
                          variant="tertiary"
                          className="pill"
                          onClick={() => setShowAllSoldToPills(true)}
                        >
                          +{formSoldToReportFilter.values.length - 3}  Sold To
                        </Button>
                      )}
                    </div>
                  )}
                </FormElement>
              )}
              {accessToColumn.ShipTo && filters?.shipToFilter && (
                <FormElement
                  label="Ship To"
                  className="no-bottom-border"
                  direction="column"
                >
                  <Controller
                    control={control}
                    name="shipToReportFilter"
                    render={({ field }) => (
                      <TextField
                        onKeyPress={(evt) => {
                          if (!enterKeyPressed(evt.code)) {
                            return true;
                          }
                          const inputEl = (evt.target as HTMLInputElement);
                          field.onChange(addTermToReportFilter(inputEl.value, formShipToReportFilter));
                          inputEl.value = '';
                          setValue('filterId', undefined);
                          evt.preventDefault();
                          return false;
                        }}
                      />)
                    }
                  />
                  {reportFilterIsFalsyOrEmpty(formShipToReportFilter) && <span className="no-pills">{t('ui_common_none')}</span>}
                  {reportFilterHasValues(formShipToReportFilter) && (
                    <div className="pills">
                      <Controller
                        control={control}
                        name="shipToReportFilter"
                        render={({ field }) => (
                          <>
                            {(showAllShipToPills
                              ? formShipToReportFilter?.values ?? []
                              : formShipToReportFilter?.values.slice(0, 3) ?? []
                            ).map(tag => (
                              <Pill
                                key={`ship-${tag}`}
                                className="pill"
                                iconClicked={() =>
                                  field.onChange(
                                    removeTermFromReportFilter(tag, formShipToReportFilter)
                                  )
                                }
                              >
                                {tag}
                              </Pill>
                            ))}
                          </>
                        )}
                      />
                      {!showAllShipToPills && formShipToReportFilter !== undefined && formShipToReportFilter.values.length > 3 && (
                        <Button
                          variant="tertiary"
                          className="pill"
                          onClick={() => setShowAllShipToPills(true)}
                        >
                          +{formShipToReportFilter.values.length - 3}  Ship To
                        </Button>
                      )}
                    </div>
                  )}
                </FormElement>
              )}
              {filters?.alarmTypeFilter && (
                <FormElement
                  label="Alarm type"
                  className="no-bottom-border"
                  direction="column"
                >
                  <Controller
                    name="alarmType"
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className="flex fill-width fill-height oh aicenter pad_bs">
                          <Checkbox
                            text="All"
                            className="pad_rs oh"
                            checked={alarmTypes.length === field.value?.length}
                            onChange={() => {
                              field.onChange(
                                alarmTypes.length === field.value?.length
                                  ? []
                                  : alarmTypes
                              );
                            }}
                          />
                        </div>
                        {alarmTypes.map(at => (
                          <div key={at}>
                            <div className="flex fill-width fill-height oh aicenter pad_s">
                              <Checkbox
                                text={at}
                                className="pad_rs oh"
                                checked={field.value?.includes(at)}
                                onChange={() => {
                                  field.onChange(
                                    field.value?.includes(at)
                                      ? field.value.filter(v => v !== at)
                                      : [...(field.value || []), at]
                                  );
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  ></Controller>
                </FormElement>
              )}
              {filters?.periodFilter && (
                <FormElement
                  label="Period"
                  className="top-border"
                  direction="column"
                >
                  <Controller
                    name="reportPeriod"
                    control={control}
                    render={({ field }) => (
                      <>
                        {getPeriodslist(filters.periodFilter).map(p => (
                          <div
                            key={p?.label}
                            className="periodcheckboxwrapper"
                            onClick={() => {
                              field.onChange(p?.value);
                              setIsSchedulablePeriod(p?.schedulable);
                            }}
                          >
                            <input
                              type="radio"
                              name="period"
                              checked={field.value === p?.value}
                              onChange={() => {
                                // this onChange is redundant, but necessary because we must use "checked" over for the
                                // field to display the correct value when loading a report. Otherwise "previous day" is always shown as selected.
                                field.onChange(p?.value);
                                setIsSchedulablePeriod(p?.schedulable);
                              }}
                              id={`${'period' + p?.value}`}
                              className="periodcheckbox" />
                            <label htmlFor="${'period' + p.value}">
                              {p?.label}
                            </label>
                          </div>
                        ))}
                        {errors.reportPeriod && <ErrorText>{errors.reportPeriod.message}</ErrorText>}
                      </>
                    )}
                  />
                </FormElement>
              )}

              {dateSelectionType === 'yearAndMonth' 
                && formvalues.reportPeriod === ReportPeriodEnum.Custom
                && (
                <FormElement label="Month" direction="column">
                  <DatePicker
                    placeholder="Select month"
                    maximumDate={new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)}
                    onChanged={e => { 
                        setSelectedFromPeriod(e); 
                        setSelectedToPeriod( new Date(e.getFullYear(), e.getMonth() + 1, 0));
                      }
                    }
                    selectedDate={selectedFromPeriod}
                    monthsAndYearOnly={true}
                  />
                </FormElement>
              )}

              {formvalues.reportPeriod === ReportPeriodEnum.Custom &&
                dateSelectionType === 'range' &&
                filters?.periodFilter && (
                  <FormElement label="From date" direction="column">
                    <DatePicker
                      placeholder="From date"
                      maximumDate={selectedToPeriod}
                      onChanged={e => setSelectedFromPeriod(e)}
                      selectedDate={selectedFromPeriod}
                    />
                  </FormElement>
                )}
              {formvalues.reportPeriod === ReportPeriodEnum.Custom &&
                dateSelectionType === 'range' &&
                filters?.periodFilter && (
                  <FormElement label="To date" direction="column">
                    <DatePicker
                      placeholder="To date"
                      minimumDate={selectedFromPeriod}
                      onChanged={e => setSelectedToPeriod(e)}
                      selectedDate={selectedToPeriod}
                    />
                  </FormElement>
                )}
              {formvalues.reportPeriod === ReportPeriodEnum.Custom &&
                dateSelectionType === 'endof' && (
                  <FormElement label="End of specific date" direction="column">
                    <DatePicker
                      placeholder="Date"
                      onChanged={e => {
                        setSelectedToPeriod(e);
                        setSelectedFromPeriod(e);
                      }}
                      selectedDate={selectedToPeriod}
                    />
                  </FormElement>
                )}
              {scheduledReportId && (
                <Button type="submit" 
                        variant="secondary" 
                        disabled={reportIsRunning || !allowRunningReport}>
                  Run report
                </Button>
              )}
            </CardContent>
            {scheduledReportId && (
              <Banner type="info" icon="fa-info-circle">
                You are editing an existing scheduled report connected to schedule {data?.scheduledReport?.reportSchedule?.scheduleName}
              </Banner>
            )}
            {scheduledReportId && (
              <CardFooter className="form-group-actions">
                <Button
                  variant="secondary"
                  disabled={reportIsRunning}
                  onClick={e => {
                    e.preventDefault();
                    return cancelEdit();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  disabled={!isSchedulablePeriod || reportIsRunning || !isValid}
                  onClick={saveChangesToScheduledReport}
                >
                  Save changes
                </Button>
              </CardFooter>
            )}
            {!allowRunningReport && (
              <WarningText className='require-filter-warning'>{t('ui_reports_warning_mustselectafiltertorun')}</WarningText>
            )}
            {!scheduledReportId && (
              <CardFooter className="flex jsb"> 
                <Button type="submit" variant="secondary" disabled={!allowRunningReport || reportIsRunning}>
                  Run report
                </Button>
                {userHasScheduledRerpotsFeature() && (
                  <ActionButton
                    buttonType={'secondary'}
                    text={'Schedule this report'}
                    disabled={disableScheduledReports || reportIsRunning || !formvalues.reportPeriod || !allowRunningReport}
                  >
                    {(supportedFormats ?? [ReportFormatEnum.Excel]).map(format => (
                      <ActionContentItem
                        key={format}
                        onClick={() => {
                          setSelectedFormat(format);
                          setModalOpen(true);
                        }}
                      >
                        {format}
                      </ActionContentItem>
                    ))}
                  </ActionButton>
                )}
              </CardFooter>
            )}
          </form>
          {loading && !error && (
            <Card>
              <CardContent className="center-content fill-height">
                <LoadingBars />
              </CardContent>
            </Card>
          )}
          {modalOpen && (
            <ScheduleReportModal
              mutationVariables={{...formvalues, reportFormat: selectedFormat}}
              closeModal={() => setModalOpen(false)}
            />
          )}
        </Card>
      )}
    </div>
  );
};

export default ScheduledReportFilter;
