import React, { useEffect, useMemo, useState, memo, Fragment } from 'react';
import { PageComponent } from '../../types/page-component';
import { CardPageMobileTabItem } from './card-page-mobile-tab-item';
import './mobile-view.css';
import { MobileTabs } from '$components/mobile-tabs/mobile-tabs';
import { ICardPageProps } from '../../card-page';
import { ActionsComponentType } from '../../types/actions-component-type';

type IMobileViewProps<T> = Omit<ICardPageProps<T>, 'showMenuBar'>;

const getTypeToIndexMap = <T,>(components: PageComponent<T>[]) => {
  const map = new Map<T, number>();
  components.forEach((c, i) => {
    map.set(c.type, i);
  });
  return map;
};

const getComponentsInRows = <T,>(
  components: PageComponent<T | ActionsComponentType>[]
): PageComponent<T | ActionsComponentType>[][] => {
  // move tools to last index
  const ordered =
    components[0].type === 'actions'
      ? [...components.slice(1), components[0]]
      : components;

  // create 2 menu rows if more than 4 components are available to the user
  if (ordered.length > 4) {
    return [ordered.slice(0, 3), ordered.slice(3, 7), ordered.slice(7)];
  }
  return [ordered];
};

const MobileViewComponent = <T,>({
  components,
  selectedMobileComponent,
  setSelectedMobileComponent,
}: IMobileViewProps<T>) => {
  const [menuExpanded, setMenuExpanded] = useState(false);

  const typeToIndexMap = useMemo(
    () => getTypeToIndexMap(components),
    [components]
  );

  const menuItems = useMemo(
    () => getComponentsInRows(components),
    [components]
  );

  useEffect(() => {
    // runs first time component is rendered,
    // will select the first card in the array
    // this will probably only be useful if the user
    // does not have access to the information card,
    // or the url points to a card that the user does not have access to
    if (components.find((c) => c.type === selectedMobileComponent)) return;
    const firstComponent = components.find((c) => c.type !== 'actions')?.type;
    if (firstComponent) setSelectedMobileComponent(firstComponent);
  }, []);

  const toggleExpand = () => setMenuExpanded((e) => !e);

  const setTab = (component: T | ActionsComponentType) => {
    setSelectedMobileComponent(component);
  };

  const activeComponent =
    components[typeToIndexMap.get(selectedMobileComponent) ?? 0];

  return (
    <div className="mobile-view-flex-container">
      <div className="mobile-card-wrapper">{activeComponent?.component}</div>
      <div className="bottom-menu-container">
        {components.length > 1 && menuItems.map((row, rowIndex) => (
          <Fragment key={rowIndex}>
            {(rowIndex === 0 || menuExpanded) && (
              <MobileTabs topShadow={rowIndex === 0}>
                {row.map((item, i) => (
                  <CardPageMobileTabItem
                    key={item.iconName + i}
                    iconName={item.iconName}
                    name={item.displayText}
                    onClick={() => setTab(item.type)}
                    active={selectedMobileComponent === item.type}
                  />
                ))}
                {row.length < 4 && menuItems.length > 1 && rowIndex === 0 && (
                  <CardPageMobileTabItem
                    iconName={
                      menuExpanded ? 'fa-chevron-down' : 'fa-chevron-up'
                    }
                    name={menuExpanded ? 'less' : 'more'}
                    onClick={toggleExpand}
                  />
                )}
              </MobileTabs>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export const MobileView = memo(MobileViewComponent);
