import { removeNoneAndEmptyFromArray } from '$lib/arrayHelpers';
import { getCacheKey, IFetchResult, subscribeQuery, unsubscribeFn } from '$pages/common/GraphQLFetcher';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useState, useEffect, useCallback, useRef } from 'react';

type SubscriptionsObject = Record<string, unsubscribeFn>;
export type VisibleRangeChangedFn = (visible: { startIndex: number; endIndex: number }) => void;

export type UseGridDataReturnType<TItem> = IFetchResult<TItem[]> & { visibleItemsChanged: VisibleRangeChangedFn; cacheKey: string };

export const EmptyGridReturnTypeObject = {error: false, isRevalidating: false, data: [], cacheKey: "", visibleItemsChanged: () => {}}

const pageSize = 50;
export function useGridData<TResult, TVariables, TItem>(
  query: TypedDocumentNode<TResult, TVariables>,
  variables: TVariables,
  extractCount: (t: TResult) => number,
  extractItems: (t: TResult) => TItem[]
): UseGridDataReturnType<TItem> { 
  const [cacheKey, setCacheKey] = useState(getCacheKey(query, variables));
  const [gridData, setGridData] = useState<IFetchResult<TItem[]>>({ error: false, isRevalidating: true, data: undefined });
  const subscriptions = useRef<SubscriptionsObject>({});
  const [fetchedRange, setFetchedRange] = useState({ start: 0, end: 0 });
  
  const unsubscribeQueries = () => {
    Object.values(subscriptions.current).forEach(u => u());
    subscriptions.current = {};
  };

  useEffect(() => { 
    const _cacheKey = getCacheKey(query, variables);
    if (cacheKey !== _cacheKey) {
      setGridData({ error: false, isRevalidating: true, data: undefined });
      setFetchedRange({ start: 0, end: 0 });
      setCacheKey(() => _cacheKey)

    }
  } , [query, variables]);

  const visibleItemsChanged = useCallback<VisibleRangeChangedFn>(({ startIndex, endIndex }) => {
      const _cache = getCacheKey(query, variables)
      if (gridData?.data?.length === removeNoneAndEmptyFromArray(gridData?.data|| []).length && cacheKey === _cache) return;
      
      if (startIndex > fetchedRange.start && endIndex < fetchedRange.end) return;
      unsubscribeQueries();

      if(!variables) return;
      
      const variablesForOffset = { ...variables, offset: startIndex };
      const { unsubscribe } = subscribeQuery(query, variablesForOffset, {revalidateOnEvents: false}, result => {
        if (!result.data) return;

        const items = extractItems(result.data);
        const count = extractCount(result.data);
        setFetchedRange({ start: variablesForOffset.offset, end: variablesForOffset.offset + items.length });
        setGridData(existing => {
          const dataToMerge = existing.data ? existing.data : new Array(count).fill(undefined);

          //Check if there are less items returned this time than we had before (happens if you delete from the list and there are less than page-size items remaining). 
          //We need to shrink the array to prevent duplicates being left in the array as data is shiftet upwards.
          if (dataToMerge.length > count) 
            dataToMerge.splice(count, dataToMerge.length-count); //Remove overshooting items from array

          //Add in new items
          dataToMerge.splice(startIndex, items.length, ...items);

          return {
            error: result.error,
            isRevalidating: result.isRevalidating,
            data: dataToMerge
          };
        });
      });
      const _cacheKey = getCacheKey(query, variablesForOffset);
      subscriptions.current[_cacheKey] = unsubscribe;
    },
    [variables, query, extractCount, extractItems, gridData]
  );

  useEffect(() => {
    setGridData({ error: false, isRevalidating: true, data: undefined });

    visibleItemsChanged({ startIndex: 0, endIndex: pageSize });

    return unsubscribeQueries;
  }, [cacheKey]);

  if(!variables) return EmptyGridReturnTypeObject
  
  return {
    ...gridData,
    visibleItemsChanged,
    cacheKey
  };
}

export const getFreeTextQueryRowCount = (newText?: string) => {
  if (newText === undefined) return 1;
  return newText.split(/\r\n|\r|\n/).length;
};
