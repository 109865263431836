import Button from '$components/buttons/button.react';
import { isNone } from '$lib/helpers';
import React, { FC, useState, MouseEventHandler } from 'react';
import ActionContent from '../action-content/action-content.react';
import './action-button.css';
import classNames from "classnames";

interface IActionButtonProps {
  text: string;
  processing?: boolean;
  disabled?: boolean;
  icon?: string;
  alignTop?: boolean;
  alignRight?: boolean;
  straightLeft?: boolean;
  straightRight?: boolean;
  showContent?: boolean;
  buttonType?: 'primary' | 'secondary';
  size?: 'medium' | 'small';
  showIcon?: boolean;
  buttonClicked?: Function;
  stopPropagation?: boolean;
  className?: string;
}

const ActionButton: FC<IActionButtonProps> = ({
  text,
  processing,
  disabled = false,
  icon = 'fa-caret-down',
  alignTop,
  alignRight,
  showContent,
  buttonType = 'primary',
  size = 'medium',
  showIcon = true,
  buttonClicked,
  stopPropagation = false,
  className,
  children
}) => {
  const [_isExpanded, setIsExpanded] = useState(false);

  const buttonIsClicked: MouseEventHandler<HTMLButtonElement> = event => {
    if (stopPropagation) event.stopPropagation();
    if (!isNone(buttonClicked)) {
      buttonClicked();
    }

    toggleList();
  };

  const toggleList = () => {
    if (!isNone(showContent) || disabled) return;
    if (_isExpanded) {
      closeList();
    } else {
      openList();
    }
  };

  const closeList = () => {
    if (!isNone(showContent) || disabled) return;
    setIsExpanded(false);
    removeEventListener('click', closeList, false);
  };

  const openList = () => {
    setIsExpanded(true);
    setTimeout(() => addEventListener('click', closeList, false));
  };

  return (
    <div className={classNames('actionbuttoncomponent', className)}>
      <div className="action-button-component">
        <Button
          variant={buttonType}
          onClick={buttonIsClicked}
          icon={showIcon ? icon : undefined}
          iconPlacement="right"
          size={size}
          className="inner-button"
          disabled={disabled}
          processing={processing}
          //straight-right="straightRight"
          //straight-left="straightLeft"
        >
          {text}
        </Button>
        {_isExpanded && (
          <ActionContent alignTop={alignTop} alignRight={alignRight}>
            {children}
          </ActionContent>
        )}
      </div>
    </div>
  );
};

export default ActionButton;
