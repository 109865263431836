import React, {FC, HTMLAttributes} from 'react';
import ArrowRight from '$components/icons/svg/react/arrow-right';
import Muted from '$components/icons/svg/react/muted';
import Parked from '../svg/react/parked';
import AlertTriangle from '../svg/react/alert-triangle';
import OfflineCross from '../svg/react/offline-cross';
import Tick from '../svg/react/tick';
import SignalGood from '../svg/react/signal-good';
import SignalBad from '../svg/react/signal-bad';
import SignalPoor from '../svg/react/signal-poor';
import Archive from '../svg/react/archive';
import Image from '../svg/react/image';
import File from '../svg/react/file';
import VolumeUp from '../svg/react/volume-up';
import DownloadIcon from '../svg/react/download';
import Columns from '../svg/react/columns';
import Virtual from '../svg/react/virtual';
import Compare from '../svg/react/compare';
import {SecurityLevelIcon} from "$pages/sitedetails/modules/common/securitylevelicon/SecurityLevelIcon.react";
import {SecurityLevel} from "$interfaces/enums/securityLevel";
import ChevronUp from '$components/icons/svg/react/chevron-up';
import ChevronDown from '$components/icons/svg/react/chevron-down';
import Settings from '$components/icons/svg/react/settings';
import ParkedP from '../svg/react/parked-p';
import {CustomIcon} from "$components/icons/icon/CustomIcon";
import FileText from "$components/icons/svg/react/file-text";
import Bell from "$components/icons/svg/react/bell";
import Bag from "$components/icons/svg/react/bag";
import Menu from "$components/icons/svg/react/menu";
import Map from "$components/icons/svg/react/map";
import Cloud from "$components/icons/svg/react/cloud";
import BarChart from "$components/icons/svg/react/bar-chart";
import BarChart2 from "$components/icons/svg/react/bar-chart-2";
import IosShare from "$components/icons/svg/react/ios-share";
import IosHomeScreen from "$components/icons/svg/react/ios-home-screen";
import classNames from "classnames";

export interface IIconProps extends HTMLAttributes<HTMLElement> {
  badge?: string;
  name: CustomIcon | string;
  title?: string;
}

function getCustomIcon(name: string | CustomIcon, className?: string): JSX.Element | null {
  switch (name) {
    case CustomIcon.ALERT_TRIANGLE: return <AlertTriangle className={className} />
    case CustomIcon.ARCHIVE: return <Archive className={className} />
    case CustomIcon.ARROW_RIGHT: return <ArrowRight className={className} />
    case CustomIcon.BAG: return <Bag className={className} />
    case CustomIcon.BAR_CHART: return <BarChart className={className} />
    case CustomIcon.BAR_CHART_2: return <BarChart2 className={className} />
    case CustomIcon.BELL: return <Bell className={className} />
    case CustomIcon.CHEVRON_DOWN: return <ChevronDown className={className} />
    case CustomIcon.CHEVRON_UP: return <ChevronUp className={className} />
    case CustomIcon.CLOUD: return <Cloud className={className} />
    case CustomIcon.COLUMNS: return <Columns className={className} />
    case CustomIcon.COMPARE: return <Compare className={className} />
    case CustomIcon.DOWNLOAD: return <DownloadIcon className={className} />
    case CustomIcon.FILE: return <File className={className} />
    case CustomIcon.FILE_TEXT: return <FileText className={className}/>
    case CustomIcon.IMAGE: return <Image className={className} />
    case CustomIcon.IOS_HOME_SCREEN: return <IosHomeScreen className={className} />
    case CustomIcon.IOS_SHARE: return <IosShare className={className} />
    case CustomIcon.MAP: return <Map className={className} />
    case CustomIcon.MENU: return <Menu className={className} />
    case CustomIcon.MUTED: return <Muted className={className} />
    case CustomIcon.OFFLINE_CROSS: return <OfflineCross className={className} />
    case CustomIcon.ONLINE_TICK: return <Tick className={className} />
    case CustomIcon.PARKED: return <Parked className={className} />
    case CustomIcon.PARKED_P: return <ParkedP className={className} />
    case CustomIcon.SECURITY_LEVEL_HIGH: return <SecurityLevelIcon className={className} level={SecurityLevel.High} />
    case CustomIcon.SECURITY_LEVEL_LOW: return <SecurityLevelIcon className={className} level={SecurityLevel.Low} />
    case CustomIcon.SECURITY_LEVEL_MEDIUM: return <SecurityLevelIcon className={className} level={SecurityLevel.Medium} />
    case CustomIcon.SECURITY_LEVEL_NONE: return <SecurityLevelIcon className={className} level={SecurityLevel.None} />
    case CustomIcon.SETTINGS: return <Settings className={className} />
    case CustomIcon.SIGNAL_BAD: return <SignalBad className={className} />
    case CustomIcon.SIGNAL_GOOD: return <SignalGood className={className} />
    case CustomIcon.SIGNAL_POOR: return <SignalPoor className={className} />
    case CustomIcon.VIRTUAL: return <Virtual className={className} />
    case CustomIcon.VOLUME_UP: return <VolumeUp className={className} />
    default: return null
  }
}

const Icon: FC<IIconProps> = (props) => {
  let innerElement: JSX.Element
  const className = classNames(
    'icon-component', // Distinguish between React and legacy icons
    props.onClick && 'cursor-pointer', // Indicate click-ability
    props.className,
  )

  const customIcon: JSX.Element | null = getCustomIcon(props.name, classNames(className))
  if (customIcon) {
    innerElement = customIcon
  } else {
    innerElement = <i
      className={classNames('fa', props.name)}
      title={props.title} />
  }

  return (
    <div
      {...props}
      className={className}
      onClick={props.onClick}>
      { innerElement }
    </div>
  )
};

export default Icon;
