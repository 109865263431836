import { IReactGridFilterComponentProps } from '$components/grid/react-grid/grid.react';
import Pill from '$components/pill/pill.react';
import TextboxMultiline from '$components/textbox-multiline/textbox-multiline.react';
import { removeNoneFromArray } from '$lib/arrayHelpers';
import { isEmpty, isSomething } from '$lib/helpers';
import { useIsTouchDevice } from '$lib/hooks/isTouchDevice';
import React, { FC, useState, useEffect, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionalOperator, IFilterGroup, INumberFilter, ITextFilter } from '../../../../../../src/interfaces';

const IdsFilter: FC<IReactGridFilterComponentProps> = ({ activeFilters, filterChanged, property }) => {
  const activeFilter = activeFilters?.find(f => {
    return f.field === property;
  })
  const filterGroup = activeFilter ?? {
    exclude: false,
    field: property,
    filters: [],
    type: 'string',
    partialMatch: true
  };
  const filters = filterGroup.filters as ITextFilter[];
  const [t] = useTranslation();
  const [rowsInTextArea, setRowsInTextArea] = useState(1);
  const [textQuery, setTextQuery] = useState('');

  const isTouchDevice = useIsTouchDevice();

  useEffect(() => {
    const numberOfNewlines = (textQuery && textQuery.match(/\n/g)?.length) || 0;
    setRowsInTextArea(Math.min(4, numberOfNewlines + 1));
  }, [textQuery]);

  function addFilter(query: string) {
    if (!query) return;
    const tryParse = (s: string) => {
      try {
        const parsed = parseInt(s, 10);
        if (isNaN(parsed)) {
          return;
        }
        return parsed;
      } catch (err) {
        return;
      }
    };
    const filters = removeNoneFromArray(
      query
        .split('\n')
        .filter(query => !isEmpty(query))
        .map<INumberFilter | undefined>(value => {
          const parsed = tryParse(value);
          return isSomething(parsed) ? { value: parsed, symbol: '=', operator: ConditionalOperator.Equals } : undefined;
        })
    );

    const newfilter: IFilterGroup = {
      ...(activeFilter || { exclude: false, field: property, type: 'number', filters: [], partialMatch: true }),
      filters: activeFilter ? [...(activeFilter.filters as INumberFilter[]), ...filters] : filters
    };

    filterChanged && filterChanged(newfilter);
    setTextQuery('');
  }

  const removeFilter = (filterToRemove: ITextFilter, event: MouseEvent) => {
    event.preventDefault();
    if (!activeFilter) return;
    const newfilter: IFilterGroup = { ...activeFilter, filters: (activeFilter.filters as ITextFilter[]).filter(f => f !== filterToRemove) };
    filterChanged && filterChanged(newfilter);
  };

  return (
    <div className="block">
      <TextboxMultiline
        className="pad_m"
        shiftEnterAsNewline
        restrictResizeTo="no-resize"
        rows={rowsInTextArea}
        autofocus={!isTouchDevice}
        value={textQuery}
        onEnter={addFilter}
        placeholder={t('ui_sitelist_filter_string_entertext')}
        onChange={setTextQuery}
      ></TextboxMultiline>
      <div className="pad_h" style={{ fontSize: '0.9rem', color: '#555', whiteSpace: 'pre-wrap' }}>
        <span className="strong">{t('ui_sitelist_filter_string_hint')}:</span>
        {t('ui_sitelist_filter_string_hinttextstart')}
        <span className="strong">{t('ui_sitelist_filter_string_hintshiftenter')}</span>.{t('ui_sitelist_filter_string_hintpress')}
        <span className="strong">{t('ui_sitelist_filter_string_hintenter')}</span>
        {t('ui_sitelist_filter_string_hinttextend')}
      </div>

      <div className="flex wrap pad_t">
        {filters.map((a, i) => (
          <Pill key={"idsFilterPill" + i} className="mar_rs mar_bs" iconClicked={e => removeFilter(a, e)}>
            {a.value}
          </Pill>
        ))}
      </div>
    </div>
  );
};

export default IdsFilter;
