import React, { memo } from 'react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import { ICardFormEditorItem } from '../../card-form-editor';
import '../../card-form-editor.css';
import { DeepPartial, FieldValues, Path, PathValue } from 'react-hook-form';

export interface IFormViewItemProps<T extends FieldValues> {
  item: ICardFormEditorItem<T>;
  data: DeepPartial<T>;
}

const FormViewItemComponent = <T extends FieldValues,>({ item, data }: IFormViewItemProps<T>) => {
  const itemValue: PathValue<T, Path<T>> | unknown =
  item.dataPropertyName ? data[item.dataPropertyName] : undefined;
  const valueAsReactNode = itemValue as React.ReactNode;
  
  return (
    <SimpleTableRow>
      <SimpleTableCell className="form-value-table-cell">
        {item.displayText}
      </SimpleTableCell>
      <SimpleTableCell className="form-value-table-cell">
        {item.type === 'custom' && item.customViewComponent ? (
          item.customViewComponent(itemValue, data)
        ) : item.valueLink ? (
          <a href={item.valueLink}>{valueAsReactNode}</a>
        ) : (
          valueAsReactNode
        )}
      </SimpleTableCell>
    </SimpleTableRow>
  );
};

export const FormViewItem = memo(
  FormViewItemComponent
) as typeof FormViewItemComponent;
