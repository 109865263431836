import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { getNewId } from '$lib/incrementer';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  ActionsCell,
  ValueCell,
  ValueCellEditMode,
} from '../../../../common/profile-and-parameter-list';
import { NumberInput } from '$components/inputs/number-input/number-input.react';
import { IconButton } from '@mui/material';
import ErrorText from '$components/texts/error-text/error-text.react';
import Icon from '$components/icons/icon/icon.react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { UpdateControllerOutputCalibrationValueDocument } from '$typings/graphql-codegen';
import { IControllerOutputCalibrationUpdater } from '../common/controller-output-calibration-updater';

const iconSize = '25px';

type OutputCalibrationValueFormType = { value: number | null | undefined };

interface IOutputCalibrationValueUpdaterProps extends IControllerOutputCalibrationUpdater {
  initialValue: number | null | undefined;
  showHistory: () => unknown;
  allowEdit: boolean;
}

const OutputCalibrationValueUpdaterComponent: FC<IOutputCalibrationValueUpdaterProps> = ({
  controllerOutputCalibrationId,
  controllerId,
  setStatusMessage,
  initialValue,
  showHistory,
  allowEdit,
}: IOutputCalibrationValueUpdaterProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [t] = useCaseInsensitiveTranslation();
  const [editing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(initialValue);
  const formId = useMemo(() => {
    return 'outputcalibration_value_edit_form_' + getNewId();
  }, []);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm<OutputCalibrationValueFormType>({
    defaultValues: {
      value: currentValue,
    },
  });

  useEffect(() => {
    if (initialValue !== currentValue) setCurrentValue(initialValue);
  }, [initialValue]);

  const setDefaultError = (message?: string) => {
    setStatusMessage({
      dismissible: true,
      error: true,
      message: t('ui_common_error_save_item_failed') + ': ' + message ?? '',
    });
  };

  const onSubmit = async (formData: OutputCalibrationValueFormType) => {
    if (formData.value === undefined || formData.value === null) return;
    setIsSubmitting(true);
    try {
      let valueAsNumber = 0;

      // since TS thinks this value only can be a number we have to cast
      // it to unknown
      const valueAsUnknown: unknown = formData.value;

      if (typeof valueAsUnknown === 'string') {
        const valueAsNumberOrNan = Number(valueAsUnknown);
        if (isNaN(valueAsNumberOrNan)) {
          setError('value', {
            message: t('ui_common_validationerrors_outputcalibration_value'),
          });
          return;
        } else {
          valueAsNumber = valueAsNumberOrNan;
        }
      } else if (
        formData.value !== undefined &&
        formData.value !== null &&
        typeof valueAsUnknown === 'number'
      ) {
        valueAsNumber = formData.value;
      }

      await mutate(
        UpdateControllerOutputCalibrationValueDocument,
        {
          controllerId,
          controllerOutputCalibrationId,
          value: valueAsNumber,
        },
        true,
        ({ updateControllerOutputCalibrationValue }) => {
          if (updateControllerOutputCalibrationValue.success) {
            setCurrentValue(valueAsNumber);
            setEditing(false);
          } else if (updateControllerOutputCalibrationValue.message) {
            setDefaultError(updateControllerOutputCalibrationValue.message);
          } else {
            setDefaultError();
          }
        }
      );
    } catch {
      // error-state is set before error is thrown above
    } finally {
      setIsSubmitting(false);
    }
  };
  const currentInputValue = useWatch({ control, name: 'value' });

  const errorText = errors.value?.message;

  return (
    <>
      {editing ? (
        <>
          <ValueCellEditMode>
            <form
              id={formId}
              onSubmit={handleSubmit(onSubmit)}
              className="flex column"
            >
              <Controller
                control={control}
                name={'value'}
                rules={{
                  required: {
                    value: true,
                    message: t('UI_Common_Fields_RequiredField'),
                  },
                  pattern: {
                    value: /^-?([0-9]+\.)?[0-9]+$/,
                    message: t('ui_common_validationerrors_outputcalibration_value'),
                  },
                }}
                render={({ field }) => (
                  <>
                    <NumberInput
                      sx={{
                        '& input': {
                          textAlign: 'end',
                        },
                      }}
                      inputRef={(input) => input?.focus?.()}
                      numberType={'decimal'}
                      disabled={isSubmitting}
                      setValue={(value) => {
                        // To allow x. and x.0 to be continued,
                        // otherwise x. will become x and x.0 will
                        // become x and you can never continue typing
                        if (
                          value?.endsWith?.('.') ||
                          (value?.endsWith?.('0') &&
                            value?.indexOf?.('.') >= 0) ||
                          value === '-0' ||
                          value === '-'
                        ) {
                          field.onChange(value);
                        } else {
                          const valueAsNumber = parseFloat(value);
                          if (isNaN(valueAsNumber)) {
                            field.onChange(null);
                          } else {
                            field.onChange(valueAsNumber);
                          }
                        }
                      }}
                      value={field.value ?? ''}
                      error={!!errorText}
                    />
                    <ErrorText>{errorText && errorText}</ErrorText>
                  </>
                )}
              />
            </form>
          </ValueCellEditMode>
          <ActionsCell>
            <div className="flex">
              <IconButton
                size="small"
                disabled={isSubmitting}
                onClick={() => {
                  reset();
                  setEditing(false);
                }}
                sx={{ width: iconSize, height: iconSize }}
              >
                <Icon name={'fa-times'} />
              </IconButton>
              <IconButton
                size="small"
                disabled={isSubmitting || currentInputValue === currentValue}
                sx={{ width: iconSize, height: iconSize }}
                type={'submit'}
                form={formId}
                onClick={() => {
                  setStatusMessage(undefined);
                  handleSubmit(onSubmit);
                }}
              >
                <Icon
                  name={isSubmitting ? 'fa-spinner fa-pulse' : 'fa-check'}
                />
              </IconButton>
            </div>
          </ActionsCell>
        </>
      ) : (
        <>
          <ValueCell>
            {currentValue}
          </ValueCell>
          <ActionsCell>
            <div className="flex">
              {allowEdit && (
                <IconButton
                  size="small"
                  onClick={() => setEditing(true)}
                  sx={{ width: iconSize, height: iconSize }}
                >
                  <Icon name={'fa-pencil'} title={'Edit'} />
                </IconButton>
              )}
              <IconButton
                size="small"
                onClick={showHistory}
                sx={{ width: iconSize, height: iconSize }}
              >
                <Icon name={'fa-history'} title={'History'} />
              </IconButton>
            </div>
          </ActionsCell>
        </>
      )}
    </>
  );
};

export const OutputCalibrationValueUpdater = memo(OutputCalibrationValueUpdaterComponent);
