import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';
import Icon from '$components/icons/icon/icon.react';
import { Contact } from 'custom_typings/graphql-codegen';
import React, { FC } from 'react';
import '$components/contacts/contact-row/contact-row.css';
import { useIsTouchDevice } from '$lib/hooks/isTouchDevice';
import classNames from 'classnames';

interface IContactRowProps {
  contact: Contact;
  contactType?: string;
  menuIsOpen?: boolean;
  canEdit?: boolean;
  canRemove?: boolean;
  changeContact?: () => void;
  removeContact?: () => void;
  selected?: boolean;
  showDescription?: boolean;
  canViewInContactManager?: boolean;
}

const ContactRow: FC<IContactRowProps> = ({
  contact,
  contactType,
  menuIsOpen,
  canEdit,
  canRemove,
  changeContact,
  removeContact,
  selected,
  showDescription,
  canViewInContactManager
}) => {
  const isTouchDevice = useIsTouchDevice();
  return (
    <div
      className={classNames(
        'contact-row-wrapper',
        'contact-row-component-wrapper',
        canEdit && 'canEdit',
        menuIsOpen && 'menuIsOpen',
        selected && 'selected',
      )}
    >
      {contact?.avatarUrl && (
        <avatar-image url={contact.avatarUrl} className="avatar"></avatar-image>
      )}
      <div className="description">
        <div className="description-item nameAndTitle">{contact.name}</div>
        {contact.description && showDescription && (
          <div className="description-item">
            <Icon name="fa-comment-o" />
            {contact.description}
          </div>
        )}
        {contact.telephoneNumber && (
          <div className="description-item">
            <Icon name="fa-phone"></Icon>
            <a href="tel:${contact.telephoneNumber}">
              {contact.telephoneNumber}
            </a>
          </div>
        )}
        {contact.email && (
          <div className="description-item">
            <Icon name="fa-envelope"></Icon>
            <a href="mailto:${contact.email}">{contact.email}</a>
          </div>
        )}
        {canViewInContactManager && (
          <div className="description-item">
            <Icon name="fa-pencil"></Icon>
            <a href="/contactmanager/details/${contact.contactId}">
              {'UI_Contact_EditInContactManager'}
            </a>
          </div>
        )}
      </div>

      {(contactType || canRemove || canEdit) && (
        <div className="contact-right-wrapper">
          <span className="contact-description">{contactType}</span>
          <div className="contact-menu-wrapper p-4">
            {canRemove && contact.userId != '0' && removeContact && (
              <ClickableIcon
                className={classNames(
                  'contact-menu',
                  'pad_m',
                  isTouchDevice && 'always-show-actions'
                )}
                name="fa-user-times"
                onClick={removeContact}
              />
            )}
            {canEdit && changeContact && (
              <ClickableIcon
                name="fa-pencil"
                className={classNames(
                  'contact-menu',
                  'pad_m',
                  isTouchDevice && 'always-show-actions'
                )}
                onClick={changeContact}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactRow;
