import { inlineView } from 'aurelia-framework';
import React, { ComponentType } from 'react';
import { createRoot, Root } from 'react-dom/client';
import MuiThemeProvider from '$components/mui-theme-provider/mui-theme-provider';
import { ReactComponentContextWrapper } from './react-component-context-wrapper';

/**
 * ## Use to mount your React Components in Aurelia!
 *
 * Just extend this class and add your React Component in the super()-constructor
 *
 * The constructor takes three arguments:
 *    1. The component you want to mount
 *    2. The components props
 *    3. An array of context components
 *
 *
 * To add url params to your component (from aurelia) add params as part of
 * your component props and specify the UrlParamsType. The Aurelia activate-
 * method will assign the params for you inside this component.
 * 
 * 
 * Remember to export the class extending this or else aurelia router does not 
 * recognize it! (and the error messages aurelia gives you are vague, so it's 
 * not super obvious if forget to export the class.
 */
@inlineView(`<template><div ref='react'></div></template>`)
export abstract class ReactWrapper<
  ComponentPropsType = {},
  UrlParamsType = void
> {
  react: HTMLElement | null;
  parent: HTMLElement | null;
  reactRoot: Root | null;
  ReactComponent: ComponentType<ComponentPropsType>;
  componentProps?:
    | ComponentPropsType
    | ((params: UrlParamsType) => ComponentPropsType);
  contextComponents: React.ComponentType[];
  params: UrlParamsType;

  /**
   * ## Constructor params:
   *  1. component: The component you want to mount inside aurelia.
   *
   *  2. componentProps: your component's props. to add url params to your
   *  component props, use the function notation and extract the properties you
   *  need from the url params object: example:
   *  (urlParams) => ({prop1: "some value", prop2: urlParams.someProperty})
   *  **There is no reason to use the function notation to define props if you
   *  are not assigning any url params**
   *
   *  3. contextComponents: an array of contexts you want to wrap your component in.
   *  For example MuiThemeProvider or ProvideControllerCommandService.
   *  The MuiThemeProvider-context is added by default.
   *  Accepts an empty array, which results in your component being mounted with no context around.
   *
   */
  protected constructor(
    component: ComponentType<ComponentPropsType>,
    componentProps?:
      | ComponentPropsType
      | ((params: UrlParamsType) => ComponentPropsType),
    contextComponents: ComponentType[] = [MuiThemeProvider]
  ) {
    this.ReactComponent = component;
    this.componentProps = componentProps;
    this.contextComponents = contextComponents;
  }

  getComponentProps() {
    if (typeof this.componentProps === 'function') {
      return (
        this.componentProps as (params: UrlParamsType) => ComponentPropsType
      )(this.params);
    }
    return this.componentProps as ComponentPropsType;
  }

  createElementInContext() {
    const element = React.createElement(ReactComponentContextWrapper, {
      Component: this.ReactComponent,
      componentProps: this.getComponentProps(),
      contexts: this.contextComponents,
    });
    return element;
  }

  activate(params: UrlParamsType) {
    this.params = params;
  }

  attached() {
    if (!this.react) return;
    if (!this.parent) { 
      this.parent = this.react.parentElement;
    }
    if (!this.parent) return;
    const element = this.createElementInContext();
    this.reactRoot = createRoot(this.parent);
    this.reactRoot.render(element);
  }

  detached() {
    if (this.parent) {
      this.reactRoot?.unmount();
    }
  }

    rerender() {  
      if (this.parent) {
        const element = this.createElementInContext();
        this.reactRoot?.render(element);
      }
    }
}
