import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import CardHeader from '$components/cards/card-header/card-header.react';
import CardTabBarItem from '$components/cards/card-tab-bar/card-tab-bar-item/card-tab-bar-item.react';
import CardTabBar from '$components/cards/card-tab-bar/card-tab-bar.react';
import FormElement from '$components/forms/form-element/form-element.react';
import ModalCard from '$components/modals/modal-card/modal-card.react';
import Pill from '$components/pill/pill.react';
import Banner from '$components/banners/banner/banner.react';
import TextField from '$components/textbox/text-field.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import TimeTimezone from '$components/time/time-timezone/time-timezone.react';
import { removeNoneAndEmptyFromArray } from '$lib/arrayHelpers';
import { getCurrentTimeAsString } from '$lib/dateHelpers';
import { isSomething } from '$lib/helpers';
import { mutate } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import { revalidateAllActiveQueries } from '$pages/common/GraphQLFetcher';
import {
  AlarmTypeEnum,
  ReactUpsertScheduledReportDocument,
  ReactUpsertScheduledReportMutationVariables, ReportFormatEnum,
  ReportPeriodEnum,
  Reports,
  ReportScheduleRecurrence,
  ScheduleTypeEnum,
  UpsertReportScheduleInput
} from '$typings/graphql-codegen';
import React, { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IReportFilter } from './IReportFilter';
import { getSession } from '../../../../../../src/config/sessionService';
import SchedulesDropdown from '../../schedules-dropdown/schedules-dropdown.react';
import './schedule-report-modal.css';
import { reportFilterIsFalsyOrEmpty } from './reportFilterUtilities';
import classNames from 'classnames';

interface MutationVariables {
  reportPeriod?: ReportPeriodEnum | null;
  alarmType?: AlarmTypeEnum[] | null;
  report: Reports;
  filterId?: string | null;
  customerId?: string | null;  // customerId is being phased out
  accessTagReportFilter?: IReportFilter;
  controllerTypeReportFilter?: IReportFilter;
  buidId?: string | null; // buidId is being phased out
  buidReportFilter?: IReportFilter;
  productId?: string | null;  // productId is being phased out
  productReportFilter?: IReportFilter;
  shipTo?: string | null;  // shipTo is being phased out
  shipToReportFilter?: IReportFilter;
  soldTo?: string | null;  // soldTo is being phased out
  soldToReportFilter?: IReportFilter;
  reportFormat?: ReportFormatEnum;
}

interface IScheduleReportModalProps {
  closeModal: () => void;
  mutationVariables: MutationVariables;
  reportScheduleId?: string;
}

interface FormValues {
  enabled: boolean;
  recurrence: ReportScheduleRecurrence;
  time: {
    triggerTimeLocal: string;
    timeZoneId: string;
  };
  scheduleName: string | null;
  reportScheduleId: string | null;
  reportType: ScheduleTypeEnum;
  recipients?: string[];
  recurrenceDetails: number | null;
}

const enterKeyPressed = (code: string) => {
  return code === 'Enter' || code === 'NumpadEnter';
};

export const defaultScheduleReport = {
  enabled: true,
  recurrence: ReportScheduleRecurrence.Daily,
  reportType: ScheduleTypeEnum.User,
  time: {
    triggerTimeLocal: getCurrentTimeAsString(),
    timeZoneId: '114'
  },
  scheduleName: null,
  reportScheduleId: null,
  recurrenceDetails: null
};

const ScheduleReportModal: FC<IScheduleReportModalProps> = ({
  closeModal,
  reportScheduleId,
  mutationVariables
}) => {
  const recurrence = ReportScheduleRecurrence;
  const [t] = useTranslation();

  const isSuperUser = getSession().currentUser.isSuperUser;

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState,
    trigger,
    formState: { isSubmitting }
  } = useForm<FormValues>({
    defaultValues: defaultScheduleReport,
    mode: 'onChange'
  });

  const [scheduleActionType, setScheduleActionType] = useState<
    'existing' | 'createNew'
  >('createNew');

  const persistChanges: SubmitHandler<FormValues> = async values => {
    const getReportScheduleForUpsert = (): UpsertReportScheduleInput => {
      if (scheduleActionType === 'createNew')
        return {         
          enabled: values.enabled,
          recurrence: values.recurrence,
          scheduleName: values.scheduleName,
          recurrenceDetails: isSomething(values.recurrenceDetails)
            ? values.recurrenceDetails
            : null,
          timeZoneId: ensureNumber(values.time.timeZoneId),
          triggerTimeLocal: values.time.triggerTimeLocal,
          scheduleType: values.reportType,
          systemReportRecipients: values.recipients?.join(', ')
        };
      if (scheduleActionType === 'existing')
        return {
          enabled: values.enabled,
          recurrence: values.recurrence,
          scheduleName: values.scheduleName,
          recurrenceDetails: isSomething(values.recurrenceDetails)
            ? values.recurrenceDetails
            : null,
          timeZoneId: ensureNumber(values.time.timeZoneId),
          triggerTimeLocal: values.time.triggerTimeLocal,
          reportScheduleId: values.reportScheduleId,
          scheduleType: values.reportType,
          systemReportRecipients: values.recipients?.join(', ')
        };

      throw Error('Unsupported action: ' + scheduleActionType);
    };

    const variables: ReactUpsertScheduledReportMutationVariables = {
      input: {
        scheduledReportId: reportScheduleId,
        reportPeriod: mutationVariables.reportPeriod,
        reportFormat: mutationVariables.reportFormat,
        alarmType: mutationVariables.alarmType,
        report: mutationVariables.report,
        filterId: isSomething(mutationVariables.filterId)
          ? ensureNumber(mutationVariables.filterId)
          : undefined,
        accessTagFilters: isSomething(mutationVariables.accessTagReportFilter) && !reportFilterIsFalsyOrEmpty(mutationVariables.accessTagReportFilter)
          ? [mutationVariables.accessTagReportFilter]
          : undefined,
        controllerTypeFilters: isSomething(mutationVariables.controllerTypeReportFilter) && !reportFilterIsFalsyOrEmpty(mutationVariables.controllerTypeReportFilter)
          ? [mutationVariables.controllerTypeReportFilter]
          : undefined,
        buidFilters: isSomething(mutationVariables.buidReportFilter) && !reportFilterIsFalsyOrEmpty(mutationVariables.buidReportFilter)
          ? [mutationVariables.buidReportFilter]
          : undefined,
        productFilters: isSomething(mutationVariables.productReportFilter) && !reportFilterIsFalsyOrEmpty(mutationVariables.productReportFilter)
        ? [mutationVariables.productReportFilter]
        : undefined,
        shipToFilters: isSomething(mutationVariables.shipToReportFilter) && !reportFilterIsFalsyOrEmpty(mutationVariables.shipToReportFilter)
        ? [mutationVariables.shipToReportFilter]
        : undefined,
        soldToFilters: isSomething(mutationVariables.soldToReportFilter) && !reportFilterIsFalsyOrEmpty(mutationVariables.soldToReportFilter)
        ? [mutationVariables.soldToReportFilter]
        : undefined,
        reportSchedule: getReportScheduleForUpsert()
      }
    };

    const response = await mutate(
      ReactUpsertScheduledReportDocument,
      variables
    );

    if (!response || !response.upsertScheduledReport)
      throw Error('Could not upsert schedule report');

    const scheduleId = response.upsertScheduledReport.reportScheduleId;

    document.location.href = `scheduled-reports/scheduled-reports/details/${scheduleId}`;

    revalidateAllActiveQueries();
  };

  const validateTime = (time: {
    triggerTimeLocal: string;
    timeZoneId: string;
  }) => {
    if (
      typeof time.triggerTimeLocal !== 'string' ||
      time.triggerTimeLocal.length < 5
    ) {
      return t('ui_common_validationerrors_time');
    }
    const hours = parseInt(time.triggerTimeLocal.substring(0, 2), 10);
    const mins = parseInt(time.triggerTimeLocal.substring(3, 5), 10);

    if (hours > 23 || mins > 59) {
      return t('ui_common_validationerrors_time');
    }

    return true;
  };

  const formRecurrence = watch('recurrence');
  const formReportType = watch('reportType');
  const formRecipients = watch('recipients');

  return (
    <ModalCard
      cardClass="schedule-report-modal"
      overflow={false}
    >
      <form onSubmit={handleSubmit(persistChanges)}>
        <CardHeader>Schedule report</CardHeader>
        <CardTabBar>
          <CardTabBarItem
            active={scheduleActionType == 'createNew'}
            onClick={() => {
              setScheduleActionType('createNew');
              trigger('scheduleName');
            }}
          >
            Create new schedule
          </CardTabBarItem>
          <CardTabBarItem
            active={scheduleActionType == 'existing'}
            onClick={() => {
              setScheduleActionType('existing');
              trigger('reportScheduleId');
            }}
          >
            Existing schedule
          </CardTabBarItem>
        </CardTabBar>
        <CardContent
          className={classNames(
            'schedule-action-content',
            scheduleActionType !== 'existing' && 'displayNone'
          )}>
          <FormElement label="Schedules" direction="column">
            <Controller
              name="reportScheduleId"
              control={control}
              rules={{ required: scheduleActionType === 'existing' }}
              render={({ field }) => (
                <SchedulesDropdown
                  placeholder="Select a schedule"
                  selected={field.value || undefined}
                  itemClicked={value => field.onChange(value.value)}
                  disabled={scheduleActionType === 'createNew'}
                />
              )}
            />
          </FormElement>
        </CardContent>
        <CardContent
          className={classNames(
            'schedule-action-content',
            scheduleActionType !== 'createNew' && 'displayNone',
          )}>
          <div>
            <FormElement label="Name" direction="column">
              <TextField
                {...register('scheduleName', {
                  required:
                    scheduleActionType === 'createNew' && 'Name required',
                  validate: s => {
                    return s?.trim() !== '' || !!watch('reportScheduleId')
                      ? true
                      : (t('ui_common_validationerrors_only_spaces') as string);
                  }
                })}
                error={formState.errors.scheduleName?.message}
                disabled={scheduleActionType === 'existing'}
              />
            </FormElement>

            {isSuperUser && (
              <FormElement label="Type" direction="column">
                <Controller
                  name="reportType"
                  control={control}
                  render={({ field }) => (
                    <>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="reportTypeUser"
                          onChange={() => {
                            return field.onChange(ScheduleTypeEnum.User);
                          }}
                          checked={field.value === ScheduleTypeEnum.User}
                          id="reportType0"
                        />
                        <label htmlFor="reportType0">User</label>
                      </div>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="reportTypeSystem"
                          onChange={() => {
                            return field.onChange(ScheduleTypeEnum.System);
                          }}
                          checked={field.value === ScheduleTypeEnum.System}
                          id="reportType1"
                        />
                        <label htmlFor="reportType1">System</label>
                      </div>
                    </>
                  )}
                />
              </FormElement>
            )}

            <FormElement label="Recurrence" direction="column">
              <Controller
                name="recurrence"
                control={control}

                render={({ field }) => (
                  <>
                    <div className="periodcheckboxwrapper">
                      <input
                        type="radio"
                        name="period_schedule_details"
                        onChange={() => field.onChange(recurrence.Never)}
                        checked={field.value === recurrence.Never}
                        id="period_schedule_details_period0"
                      />
                      <label htmlFor="period_schedule_details_period0">
                        Never
                      </label>
                    </div>
                    <div className="periodcheckboxwrapper">
                      <input
                        type="radio"
                        name="period_schedule_details"
                        onChange={() => field.onChange(recurrence.Daily)}
                        checked={field.value === recurrence.Daily}
                        id="period_schedule_details_period1"
                      />
                      <label htmlFor="period_schedule_details_period1">
                        Daily
                      </label>
                    </div>
                    <div className="periodcheckboxwrapper">
                      <input
                        type="radio"
                        name="period_schedule_details"
                        onChange={() =>
                          field.onChange(recurrence.WeeklyByWeekDay)
                        }
                        checked={field.value === recurrence.WeeklyByWeekDay}
                        id="period_schedule_details_period2"
                      />
                      <label htmlFor="period_schedule_details_period2">
                        Weekly
                      </label>
                    </div>
                    <div className="periodcheckboxwrapper">
                      <input
                        type="radio"
                        name="period_schedule_details"
                        onChange={() =>
                          field.onChange(recurrence.MonthlyByFirstWeekDay)
                        }
                        checked={
                          field.value === recurrence.MonthlyByFirstWeekDay
                        }
                        id="period_schedule_details_period3"
                      />
                      <label htmlFor="period_schedule_details_period3">
                        Monthly
                      </label>
                    </div>
                    <div className="periodcheckboxwrapper">
                      <input
                        type="radio"
                        name="period_schedule_details"
                        onChange={() => field.onChange(recurrence.FirstDayOfMonth)}
                        checked={field.value === recurrence.FirstDayOfMonth}
                        id="period_schedule_details_period4"
                      />
                      <label htmlFor="period_schedule_details_period4">
                        First day of month
                      </label>
                    </div>
                    <div className="periodcheckboxwrapper">
                      <input
                        type="radio"
                        name="period_schedule_details"
                        onChange={() => field.onChange(recurrence.FirstDayOfQuarter)}
                        checked={field.value === recurrence.FirstDayOfQuarter}
                        id="period_schedule_details_period5"
                      />
                      <label htmlFor="period_schedule_details_period5">
                        First day of quarter
                      </label>
                    </div>
                    <div className="periodcheckboxwrapper">
                      <input
                        type="radio"
                        name="period_schedule_details"
                        onChange={() => field.onChange(recurrence.FirstDayOfHalfYear)}
                        checked={field.value === recurrence.FirstDayOfHalfYear}
                        id="period_schedule_details_period6"
                      />
                      <label htmlFor="period_schedule_details_period6">
                        First day of half year
                      </label>
                    </div>
                    <div className="periodcheckboxwrapper">
                      <input
                        type="radio"
                        name="period_schedule_details"
                        onChange={() => field.onChange(recurrence.FirstDayOfYear)}
                        checked={field.value === recurrence.FirstDayOfYear}
                        id="period_schedule_details_period7"
                      />
                      <label htmlFor="period_schedule_details_period7">
                        First day of year
                      </label>
                    </div>
                  </>
                )}
              />
            </FormElement>

            {formRecurrence ===
              ReportScheduleRecurrence.WeeklyByWeekDay && (
              <FormElement label="Recurrence details" direction="column">
                <Controller
                  name="recurrenceDetails"
                  control={control}
                  shouldUnregister
                  rules={{
                    required: scheduleActionType === 'createNew' &&
                      formRecurrence ===
                        ReportScheduleRecurrence.WeeklyByWeekDay &&
                      'Details required'
                  }}
                  render={({ field }) => (
                    <>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="rec_weekly"
                          onChange={() => field.onChange(0)}
                          checked={field.value === 0}
                          id="recurrencew0"
                        />
                        <label htmlFor="recurrencew0">Monday</label>
                      </div>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="rec_weekly"
                          onChange={() => field.onChange(1)}
                          checked={field.value === 1}
                          id="recurrencew1"
                        />
                        <label htmlFor="recurrencew1">Tuesday</label>
                      </div>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="rec_weekly"
                          onChange={() => field.onChange(2)}
                          checked={field.value === 2}
                          id="recurrencew2"
                        />
                        <label htmlFor="recurrencew2">Wednesday</label>
                      </div>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="rec_weekly"
                          onChange={() => field.onChange(3)}
                          checked={field.value === 3}
                          id="recurrencew3"
                        />
                        <label htmlFor="recurrencew3">Thursday</label>
                      </div>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="rec_weekly"
                          onChange={() => field.onChange(4)}
                          checked={field.value === 4}
                          id="recurrencew4"
                        />
                        <label htmlFor="recurrencew4">Friday</label>
                      </div>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="rec_weekly"
                          onChange={() => field.onChange(5)}
                          checked={field.value === 5}
                          id="recurrencew5"
                        />
                        <label htmlFor="recurrencew5">Saturday</label>
                      </div>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="rec_weekly"
                          onChange={() => field.onChange(6)}
                          checked={field.value === 6}
                          id="recurrencew6"
                        />
                        <label htmlFor="recurrencew6">Sunday</label>
                      </div>
                    </>
                  )}
                />
                <ErrorText>
                  {formState.errors.recurrenceDetails?.message}
                </ErrorText>
              </FormElement>
            )}

            {formRecurrence ===
              ReportScheduleRecurrence.MonthlyByFirstWeekDay && (
              <FormElement label="Recurrence details" direction="column">
                <Controller
                  name="recurrenceDetails"
                  control={control}
                  shouldUnregister
                  rules={{
                    required: scheduleActionType === 'createNew' &&
                      formRecurrence ===
                        ReportScheduleRecurrence.MonthlyByFirstWeekDay &&
                      'Details required'
                  }}
                  render={({ field }) => (
                    <>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="rec_monthly"
                          onChange={() => field.onChange(0)}
                          checked={field.value === 0}
                          id="recurrencem2"
                        />
                        <label htmlFor="recurrencem2">
                          First Monday of month
                        </label>
                      </div>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="rec_monthly"
                          onChange={() => field.onChange(1)}
                          checked={field.value === 1}
                          id="recurrencem3"
                        />
                        <label htmlFor="recurrencem3">
                          First Tuesday of month
                        </label>
                      </div>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="rec_monthly"
                          onChange={() => field.onChange(2)}
                          checked={field.value === 2}
                          id="recurrencem4"
                        />
                        <label htmlFor="recurrencem4">
                          First Wednesday of month
                        </label>
                      </div>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="rec_monthly"
                          onChange={() => field.onChange(3)}
                          checked={field.value === 3}
                          id="recurrencem5"
                        />
                        <label htmlFor="recurrencem5">
                          First Thursday of month
                        </label>
                      </div>
                      <div className="periodcheckboxwrapper">
                        <input
                          type="radio"
                          name="rec_monthly"
                          onChange={() => field.onChange(4)}
                          checked={field.value === 4}
                          id="recurrencem6"
                        />
                        <label htmlFor="recurrencem6">
                          First Friday of month
                        </label>
                      </div>
                    </>
                  )}
                />
                <ErrorText>
                  {formState.errors.recurrenceDetails?.message}
                </ErrorText>
              </FormElement>
            )}

            <FormElement label="Time (local)" direction="column">
              <Controller
                name="time"
                control={control}
                rules={{ validate: validateTime }}
                render={({ field }) => (
                  <TimeTimezone
                    time={field.value.triggerTimeLocal}
                    timezone={field.value.timeZoneId}
                    timeValueChanged={value =>
                      field.onChange({
                        ...field.value,
                        triggerTimeLocal: value
                      })
                    }
                    timezoneValueChanged={value =>
                      field.onChange({ ...field.value, timeZoneId: value })
                    }
                  />
                )}
              />
              <ErrorText>{formState.errors.time?.message}</ErrorText>
            </FormElement>
            {formReportType === ScheduleTypeEnum.System && isSuperUser && (
              <>
                <Banner type="warning" icon="fa-info-circle">
                  These system reports runs as system user and has access to
                  anything, so ensure your filters are correctly set!
                </Banner>
                <FormElement
                  label="Recipients"
                  className="no-bottom-border"
                  direction="column"
                >
                  <Controller
                    control={control}
                    name="recipients"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Enter an email adress and press ENTER"
                        onKeyPress={evt => {
                          if (!enterKeyPressed(evt.code)) {
                            return true;
                          }
                          const inputEl = evt.target as HTMLInputElement;
                          field.onChange(
                            removeNoneAndEmptyFromArray([
                              ...(field.value ? field.value : []),
                              ...inputEl.value.split(',').map(v => v.trim())
                            ])
                          );
                          inputEl.value = '';
                          evt.preventDefault();
                          return false;
                        }}
                      />
                    )}
                  />
                  {formRecipients && (
                    <div className="pills">
                      <Controller
                        control={control}
                        name="recipients"
                        render={({ field }) => (
                          <>
                            {formRecipients.map((recipient, i) => (
                              <Pill
                                key={`recipient-${recipient + i}`}
                                className="pill"
                                iconClicked={() =>
                                  field.onChange(
                                    field.value?.filter(v => v !== recipient)
                                  )
                                }
                              >
                                {recipient}
                              </Pill>
                            ))}
                          </>
                        )}
                      />
                    </div>
                  )}
                </FormElement>
              </>
            )}
          </div>
        </CardContent>
        <CardFooter className="flex jsb">
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!formState.isValid}
            processing={isSubmitting}
          >
            {scheduleActionType == 'existing'
              ? 'Attach to schedule'
              : 'Create new'}
          </Button>
        </CardFooter>
      </form>
    </ModalCard>
  );
};

export default ScheduleReportModal;
