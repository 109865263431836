import React, { FC, ReactElement } from 'react';
import './skeleton.css';

interface ISkeletonProps {
  rowCount?: number;
  columnCount?: number;
  rowHeight?: number;
  gap?: number | string;
}

const Skeleton: FC<ISkeletonProps> = ({
  rowCount = 1,
  columnCount = 1,
  rowHeight = 30,
  gap = '1rem'
}) => {
  const elements: ReactElement[] = [];

  for (let i = 0; i < rowCount * columnCount; i++) {
    const skeletonSpan = (
      <span style={{ height: rowHeight }} className="skeleton" key={i} />
    );
    elements.push(skeletonSpan);
  }

  return (
    <div
      className="loading-skeleton-component"
      style={{ gap, gridTemplateColumns: 'repeat(' + columnCount + ', 1fr)' }}
    >
      {elements}
    </div>
  );
};

export default Skeleton;
