import { SecurityLevel } from '$interfaces/enums/securityLevel';
import React, { FC, memo } from 'react';
import { SecurityLevels } from '../../../../../config';
import styled from '@emotion/styled';
import classNames from "classnames";

interface ISecurityLevelIconProps {
  level: SecurityLevel;
  className?: string;
}

const IconWrapper = styled.div`
  display: flex;
`;

const SecurityLevelIconComponent: FC<ISecurityLevelIconProps> = ({
  level,
  className,
}) => (
  <IconWrapper
    title={'Security Level : ' + SecurityLevels[level]}
    className={classNames(className)}
  >
    <svg width="18" height="20" xmlns="http://www.w3.org/2000/svg">
      <g>
        {level >= 3 && <rect y="0" fill="black" width="4" height="3" />}
        {level >= 2 && <rect y="5" fill="black" width="8" height="3" />}
        {level >= 1 && <rect y="10" fill="black" width="12" height="3" />}
        {level >= 0 && <rect y="15" fill="black" width="16" height="3" />}
      </g>
    </svg>
  </IconWrapper>
);

export const SecurityLevelIcon = memo(SecurityLevelIconComponent);
