export const decimalRegex = /^-?([0-9]+\.)?[0-9]*$/;
export const integerRegex = /^-?[0-9]*$/;

export const getNewStringIfMatched = (
  currentValue: string,
  newValue: string,
  regex: RegExp,
  allowEmptyString: boolean = true
) => {
  if ((allowEmptyString && newValue === '') || regex.test(newValue)) {
    return newValue;
  }
  return currentValue;
};
