import React, { FC, memo } from 'react';
import Icon from '$components/icons/icon/icon.react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import './security-level-menu.css';
import {
  ISecurityLevelItem,
  mappedSecurityLevels,
  SecurityLevelItemWrapperDiv,
} from '$components/security-level/common/security-level-definitions';

interface ISecurityLevelMenuProps {
  level: number;
  setLevel: (level: number) => unknown;
  isSubmitting?: boolean;
  allowEdit: boolean;
}

const SecurityLevelMenuComponent: FC<ISecurityLevelMenuProps> = ({
  level,
  setLevel,
  isSubmitting,
  allowEdit
}: ISecurityLevelMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectLevel = (newLevel: ISecurityLevelItem) => {
    if (newLevel.level !== level) 
      setLevel(newLevel.level);
    handleClose();
  };

  return (
    <>
      <IconButton
        sx={{width: "26px", height: "26px"}}
        aria-controls={open ? 'clear-commands-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleIconClick}
        disabled={!allowEdit || isSubmitting}
      >
        <Icon
          className="security-level-icon-width"
          name={
            isSubmitting
              ? 'fa-spinner fa-pulse'
              : mappedSecurityLevels[level].iconName
          }
          title={mappedSecurityLevels[level].displayText}
        />
      </IconButton>
      <Menu
        id="clear-commands-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {mappedSecurityLevels.map((l) => (
          <MenuItem
            key={l.level}
            sx={{ fontSize: 'unset' }}
            onClick={() => selectLevel(l)}
          >
            <SecurityLevelItemWrapperDiv>
              <Icon name={l.iconName} />
              <div style={{ paddingLeft: '6px', width: '60px' }}>
                {l.displayText}
              </div>
            </SecurityLevelItemWrapperDiv>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const SecurityLevelMenu = memo(SecurityLevelMenuComponent);
