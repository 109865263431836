import { IReactGridBooleanFilterProps, IReactGridFilterComponentProps } from '$components/grid/react-grid/grid.react';
import { isNone } from '$lib/helpers';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilterGroup } from 'src/interfaces';
import './booleanfilter.css';

const BooleanFilter: FC<IReactGridFilterComponentProps> = ({ activeFilters, filterChanged, property, componentProps }) => {
  const [t] = useTranslation();
  const activeFilter = activeFilters?.find(f => {
    return f.field === property;
  })

  const selectedValue = useCallback((): boolean | undefined => {
    if (!activeFilter || !activeFilter.filters || !activeFilter.filters.length) return undefined;
    return !activeFilter.exclude;
  }, [activeFilter]);

  const valueSelected = (newValue?: boolean) => {
    const newfilter: IFilterGroup = {
      ...(activeFilter || { exclude: !newValue, field: property, type: 'boolean', filters: [] }),
      filters: isNone(newValue) ? [] : [{ value: true }],
      exclude: !newValue
    };
    filterChanged && filterChanged(newfilter);
  };

  return (
    <div className="flex column column-filter-bool">
      {(componentProps as IReactGridBooleanFilterProps).values.map((value, i) => {
        value.value;
        const name = `${property}_${value.value}`;
        return (
          <div key={name + i} className="flex item clickable" onClick={() => valueSelected(value.value)}>
            <div className="pad_m">
              <input type="radio" className="notClickable" id={name} name={property} checked={value.value === selectedValue()} onChange={() => valueSelected(value.value)} />
            </div>
            <label htmlFor={name} className="pad_m">
              <div className="strong pad_bs">
                {value.header} {value.default && <span className="default">({t('ui_sitelist_filter_predefined_default')})</span>}
              </div>
              <div>{value.sub}</div>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default BooleanFilter;
