import { Button } from '@mui/material';
import React, { memo, FC } from 'react';
import '../../controller-replace.css';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import classNames from "classnames";

interface IControllerSerialListRowProps {
  evenRow: boolean;
  onButtonClick: () => unknown;
  buttonText: string;
  children: React.ReactNode;
}

interface INewControllerSerialListProps
  extends Omit<IControllerSerialListRowProps, 'buttonText' | 'children'> {
  serialQuery: string;
}

interface IExistingControllerSerialListRowProps
  extends Omit<IControllerSerialListRowProps, 'buttonText' | 'children'> {
  serial?: string | null;
  alias?: string | null;
}

const ControllerSerialListRow: FC<IControllerSerialListRowProps> = memo(
  ({ evenRow, onButtonClick, buttonText, children }) => {
    return (
      <div className={classNames('row-item', evenRow ? 'even' : 'odd')}>
        <div className="row-text-wrapper">{children}</div>
        <Button
          variant="outlined"
          className="action-button"
          size="small"
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </div>
    );
  }
);

export const NewControllerSerialList: FC<INewControllerSerialListProps> = memo(
  ({ serialQuery, ...rowProps }) => {
    const [t] = useCaseInsensitiveTranslation();

    const getTranslatedText = () =>
      t(
        'ui_controllermanager_controllerdetails_editserial_results_actions_useasnewserial',
        { serial: serialQuery }
      );

    const buttonText = t(
      'UI_ControllerManager_ControllerDetails_EditSerial_Results_Actions_UseAsNewSerialButton'
    );

    return (
      <ControllerSerialListRow {...rowProps} buttonText={buttonText}>
        <div className="row-text">{getTranslatedText()}</div>
      </ControllerSerialListRow>
    );
  }
);

export const ExistingControllerSerialListRow: FC<IExistingControllerSerialListRowProps> =
  memo(({ serial, alias, ...rowProps }) => {
    const [t] = useCaseInsensitiveTranslation();

    const buttonText = t(
      'UI_ControllerManager_ControllerDetails_EditSerial_Results_Item_Action_TakeSerial'
    );

    return (
      <ControllerSerialListRow {...rowProps} buttonText={buttonText}>
        <div className="row-text">
          {t(
            'UI_ControllerManager_ControllerDetails_EditSerial_Results_Item_Alias'
          )}
          : {alias}
        </div>
        <div className="row-text">
          {t(
            'UI_ControllerManager_ControllerDetails_EditSerial_Results_Item_Serial'
          )}
          : {serial}
        </div>
      </ControllerSerialListRow>
    );
  });
