import './card.css';
import {default as React, FC, HTMLAttributes} from 'react';
import classNames from "classnames";

interface ICardProps extends HTMLAttributes<HTMLElement> {
  innerClass?: string
}

const Card: FC<ICardProps> = (props) => (
  <div className={classNames('card-component', props.className)}>
    <div className={classNames('component-card-content', props.innerClass)}>
      { props.children }
    </div>
  </div>
);

export default Card;
