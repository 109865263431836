import { IMapMarker } from "$interfaces/application";
import { isNone } from "$lib/helpers";
import { MarkerIconEnum } from "$typings/graphql-codegen";
import { Loader } from "@googlemaps/js-api-loader";
import { Cluster, ClusterStats, Marker } from "@googlemaps/markerclusterer";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { GOOGLE_MAPS_API_KEY } from "../../../src/config/endpoints";

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: 'weekly',
});

export const ONE_MARKER_ZOOM = 13;
export const NO_MARKER_ZOOM = 2;

const REGULAR_MARKER_SVG = '/svg/map_marker_regular.svg';
const TANK_LEVEL_ABOVE_50_PERCENT_SVG = '/svg/tank_level_above_50_percent.svg';
const TANK_LEVEL_ABOVE_MINIMUM_SVG = '/svg/tank_level_above_minimum.svg';
const ALARM_SVG = '/svg/alarm.svg';
const TANK_OFFLINE_SVG = '/svg/tank_offline.svg';
const VIRTUAL_SVG = '/svg/virtual.svg';
const H2S_HIGH_SVG = '/svg/h2s_high.svg';
const H2S_OFFLINE_SVG = '/svg/h2s_offline.svg';
const H2S_OK_SVG = '/svg/h2s_ok.svg';
const PARKED_OFFLINE_SVG = '/svg/parked_offline.svg';
const PARKED_ONLINE_SVG = '/svg/parked_online.svg';
const CONTROLLER_OFFLINE_SVG = '/svg/controller_offline.svg';
const CONTROLLER_ONLINE_SVG = '/svg/controller_online.svg';
const EASY_FEED_SVG = '/svg/yara.svg';

type UseMapReturn = [mapElement: MutableRefObject<HTMLDivElement | null>, map: MutableRefObject<google.maps.Map | null>, loaded: boolean]

export function useMap(opts?: google.maps.MapOptions, mapClick?: Function): UseMapReturn {
  const mapElement = useRef<HTMLDivElement | null>(null);
  const map = useRef<google.maps.Map | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  async function initMap(): Promise<void> {
    await  loader.importLibrary('marker')
    await loader.importLibrary('maps').then(({ Map }) => {
      map.current = new Map(mapElement.current as HTMLDivElement, {
        //This id is needed to set settings from the google cloud console
        mapId: 'c120d8c9f1a9f69a',
        mapTypeControl: true,
        center: {lat: 0, lng: 0},
        zoom: 2,
        //Needs to be disabled, the clustering library seems to not be able to handle this very well. If ever enabled, 
        //check high zoom levels and verify that clusters actually "unclusters"
        isFractionalZoomEnabled: false,
        gestureHandling: 'cooperative',
        zoomControl: true,
        fullscreenControl: false,
        minZoom: 0,
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT
        },
        ...opts
      });
      setLoaded(true)
    });
  }

  map.current?.addListener('click', ({ latLng }: {latLng: google.maps.LatLng}) => {    
    return !isNone(mapClick) && mapClick({ position: latLng.toJSON() });
  });
  
  useEffect(() => { initMap() }, []);

  return [mapElement, map, loaded]
}

export const renderCluster = (
  cluster: Cluster,
  _stats: ClusterStats,
  _map: google.maps.Map
): Marker => {

  const divElement = document.createElement("div");
  const markerImg = document.createElement("img");
  const labelElement = document.createElement("label");
  labelElement.className = "absolute strong fill-width text-align-center"
  labelElement.style.lineHeight = "48px"

  const clusterHasMarkerWithAlarm = cluster.markers?.some((m: google.maps.marker.AdvancedMarkerElement) => m.attributes.getNamedItem("hasAlarm")?.nodeValue === "1")

  markerImg.src = clusterHasMarkerWithAlarm ? "images/c1.png" : "images/c2.png"

  if (clusterHasMarkerWithAlarm) {
    labelElement.style.color = "white"
  }

  labelElement.innerHTML = cluster.count.toString()
  divElement.appendChild(labelElement)
  divElement.appendChild(markerImg)

  return new google.maps.marker.AdvancedMarkerElement({
    position: cluster.position,
    content: divElement
  });
};

export function getMarkerIcon(marker: IMapMarker): string {
  if (marker.markerType === 'controller') {
    switch (marker.markerIconEnum) {
      case MarkerIconEnum.TankLevelAbove50percent:
        return TANK_LEVEL_ABOVE_50_PERCENT_SVG;
      case MarkerIconEnum.TankLevelAboveMinimim:
        return TANK_LEVEL_ABOVE_MINIMUM_SVG;
      case MarkerIconEnum.Alarm:
        return ALARM_SVG;
      case MarkerIconEnum.TankOffline:
        return TANK_OFFLINE_SVG;
      case MarkerIconEnum.Virtual:
        return VIRTUAL_SVG;
      case MarkerIconEnum.H2SHigh:
        return H2S_HIGH_SVG;
      case MarkerIconEnum.H2SOffline:
        return H2S_OFFLINE_SVG;
      case MarkerIconEnum.H2SOk:
        return H2S_OK_SVG;
      case MarkerIconEnum.ParkedOffline:
        return PARKED_OFFLINE_SVG;
      case MarkerIconEnum.ParkedOnline:
        return PARKED_ONLINE_SVG;
      case MarkerIconEnum.ControllerOffline:
        return CONTROLLER_OFFLINE_SVG;
      case MarkerIconEnum.ControllerOnline:
        return CONTROLLER_ONLINE_SVG;
      case MarkerIconEnum.EasyFeed:
        return EASY_FEED_SVG;

      default:
        return REGULAR_MARKER_SVG;
    }
  }

  return REGULAR_MARKER_SVG;
}

export function getIconZIndex(icon: MarkerIconEnum) {
  switch (icon) {
    case MarkerIconEnum.Alarm:
      return 12;
    case MarkerIconEnum.H2SHigh:
      return 11;
    case MarkerIconEnum.TankLevelAboveMinimim:
      return 10;
    case MarkerIconEnum.H2SOk:
      return 9;
    case MarkerIconEnum.TankLevelAbove50percent:
      return 8;
    case MarkerIconEnum.Virtual:
      return 7;
    case MarkerIconEnum.ControllerOnline:
      return 6;
    case MarkerIconEnum.TankOffline:
      return 5;
    case MarkerIconEnum.H2SOffline:
      return 4;
    case MarkerIconEnum.ParkedOnline:
      return 3;
    case MarkerIconEnum.ControllerOffline:
      return 2;
    case MarkerIconEnum.ParkedOffline:
    case MarkerIconEnum.EasyFeed:
      return 1;
  }
}