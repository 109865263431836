import React, { FC, memo, useRef } from 'react';
import SitelistReact from '$pages/sitelistpage-react/modules/sitelist/sitelist.react';
import { CardWithHeader } from '$components/cards/mui-card';
import './planner.css';

interface IPlannerPageProps {
  header: string;
  children: React.ReactNode;
  selectedSitesChanged: (sites: string[]) => unknown;
  name: string;
  select: 'site' | 'tank' | 'siteButTanks';
}


const PlannerPageComponent: FC<IPlannerPageProps> = ({
  header,
  children,
  selectedSitesChanged,
  select = 'site'
}: IPlannerPageProps) => {
  const sitelistWrapper = useRef<HTMLDivElement>(null);
  const pageWrapper = useRef<HTMLDivElement>(null);

  return (
    
    <div
      className="planner-wrapper"
      ref={pageWrapper}>
      <div
        ref={sitelistWrapper}
        className="inner-wrapper mar_tm mar_lm mar_bm flex_1"
      >      
        <SitelistReact
          noPadding
          plannerMode
          select={select}
          selectedRowsChanged={selectedSitesChanged}          
          rememberScroll={false}
          showBackToTopButton={false}
          mapComponentSlot={
            <CardWithHeader headerText={header}>
              <div className="planner-card-content">{children}</div>
            </CardWithHeader>}
        />
      </div>
    </div>
  );
};

export const PlannerPage = memo(PlannerPageComponent);
