import React, {
  DragEventHandler,
  ChangeEventHandler,
  useState,
  FC,
  memo,
} from 'react';
import Icon from '$components/icons/icon/icon.react';
import './file-upload.css';
import { parseDataInExcel } from '$lib/excelHelpers';
import { mutate } from '$lib/hooks/fetch-utillities';
import { UpdateControllerProfileValuesDocument } from '$typings/graphql-codegen';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { useIsMobile } from '$lib/hooks/isMobile';
import classNames from "classnames";

const FileUploadComponent: FC = () => {
  const [t] = useCaseInsensitiveTranslation();

  const isMobile = useIsMobile();
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const [uploadError, setUploadError] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleFileList = async (files: FileList) => {
    if (uploadError) setUploadError(false);
    setUploading(true);
    for (let i = 0, f; (f = files[i]); i++) {
      const metadata = await parseDataInExcel(f).catch(() => {
        setUploading(false);
        setUploadError(true);
      });
      if (!metadata || !metadata.metadata || !metadata.data) return;
      const datarows = metadata.data.reduce(
        (prev, curr) => prev.concat(curr[1]),
        []
      ) as number[];

      await mutate(
        UpdateControllerProfileValuesDocument,
        {
          controllerId: metadata.metadata.controllerId,
          profileId: metadata.metadata.controllerProfileId,
          profileValues: datarows,
        },
        true,
        () => {
          setUploading(false);
        },
        () => {
          setUploading(false);
          setUploadError(true);
        }
      );
    }
  };

  const selectFile: ChangeEventHandler<HTMLInputElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files) {
      await handleFileList(e.target.files);
    }
  };

  const dragLeave: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDraggingOver) {
      setIsDraggingOver(false);
    }
  };

  const onDrop: DragEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);
    if (e.dataTransfer) {
      await handleFileList(e.dataTransfer.files);
    }
  };

  const dragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDraggingOver) setIsDraggingOver(true);
    if (uploadError) setUploadError(false);
  };

  return (
    <div
      className={classNames('droppable-area', isDraggingOver && 'dragover')}
      onDragOver={dragOver}>
      <div
        className="droppable-area-eventcatcher"
        onDragLeave={dragLeave}
        onDrop={onDrop}
      />
      <div className={classNames('droppable-area-cloud-icon', uploadError && 'error')}>
        {uploading ? (
          <Icon name={'fa-spinner fa-pulse'} />
        ) : uploadError ? (
          <Icon name={'fa-exclamation-triangle'} />
        ) : (
          <Icon name="fa-cloud-upload" />
        )}
      </div>

      {!isMobile && (
        <div className="droppable-area-main-text">
          {isDraggingOver && !uploading && (
            <span>
              {t(
                'UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_Excel_Dragover'
              )}
            </span>
          )}
          {!isDraggingOver && !uploading && !uploadError && (
            <span>
              {t(
                'UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_Excel_Intial'
              )}
            </span>
          )}
          {uploading && (
            <span>
              {t(
                'UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_Excel_Persisting'
              )}
            </span>
          )}
          {!isDraggingOver && uploadError && (
            <span>
              {t(
                'UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_Excel_Error'
              )}
            </span>
          )}
        </div>
      )}

      <span className={classNames('droppable-area-sub-text', uploading && 'transparent')}>
        {!isMobile && 'or'}

        <div className="droppable-area-sub-filelink-wrapper">
          <span className="droppable-area-sub-filelink">
            {t(
              isMobile
                ? 'UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_Excel_SelectFile_Mobile'
                : 'UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_Excel_SelectFile'
            )}
          </span>
          <input
            type="file"
            id="files"
            name="files[]"
            className="droppable-area-sub-filelink-picker"
            onChange={selectFile}
          />
        </div>
      </span>
    </div>
  );
};

export const FileUpload = memo(FileUploadComponent);
