import { Box, styled } from '@mui/material';

const rowHeight = '39px';

export const SecurityLevelCell = styled(Box)`
  display: flex;
  flex: 0 0 44px;
  padding: 6px 6px 6px 12px;
  align-items: center;
`;

export const NameCell = styled(Box)`
  // display:block and auto on margin to center, since we can't use
  // display flex when using text-overflow: ellipsis;
  margin-top: auto;
  margin-bottom: auto;

  padding: 6px 0 6px 6px;
  flex: 1 1 100px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;

export const ValueCell = styled(Box)`
  display: flex;
  padding: 6px;
  flex: 0 1 80px;
  align-items: center;
  white-space: nowrap;
  justify-content: end;
  font-size: 14px;
`;

export const ValueCellEditMode = styled(ValueCell)`
  flex: 0 0 130px;
  padding-top: 0;
  padding-bottom: 0;
`;

export const ActionsCell = styled(Box)`
  display: flex;
  padding: 6px;
  flex: 0 0 62px;
  align-items: center;
`;

export const Row = styled(Box)`
  display: flex;
  width: 100%;
  min-height: ${rowHeight};
  max-height: ${rowHeight};
  border-bottom: 1px solid #e2eaf2;
`;

export const RowList = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1 1 auto;
`;

export const ListWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
`;
