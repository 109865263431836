import React, {
  ComponentType,
  memo,
  ReactNode
} from 'react';

interface IReactComponentContextWrapperProps<T> {
  Component: ComponentType<T>;
  componentProps: any;
  contexts: ComponentType[];
}

const ReactComponentContextWrapperComponent = <T,>({
  Component,
  componentProps,
  contexts,
}: IReactComponentContextWrapperProps<T>) => {
  // will mount Component even if contexts array is 0 in length
  const componentInContext = contexts.reduceRight<ReactNode>(
    (acc, Current) => <Current>{acc}</Current>,
    <Component {...componentProps} />
  );

  return <>{componentInContext}</>;
};

export const ReactComponentContextWrapper = memo(
  ReactComponentContextWrapperComponent
);
