import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';
import RefillAttachmentModal from '$components/refill-attachment-modal/refill-attachment-modal.react';
import { formatDate } from '$lib/dateHelpers';
import { SiteListTankRefill, SiteListTankRefillAttachment } from '$typings/graphql-codegen';
import React, { FC, useState } from 'react';

interface ILastDeliveryTicketProps {
  lastDelivery: SiteListTankRefill;
  title?: "refillDate" | "filename"
}

const LastDeliveryTicket: FC<ILastDeliveryTicketProps> = ({ lastDelivery, title = "refillDate" }) => {
  const [showModalForRefill, setShowModalForRefill] = useState<SiteListTankRefillAttachment[] | undefined>(undefined);
  return (
    <div className="flex jsb">
      <span onClick={() => lastDelivery.attachments?.length && setShowModalForRefill(lastDelivery.attachments)}>
        {title === "refillDate" ? lastDelivery.refillStart && formatDate(lastDelivery.refillStart, true, '.') : lastDelivery.attachments?.[0].attachmentName}
      </span>
      {!!lastDelivery.attachments?.length && (
        <ClickableIcon name="fa-file-o" onClick={() => lastDelivery.attachments?.length && setShowModalForRefill(lastDelivery.attachments)} />
      )}
      {showModalForRefill && <RefillAttachmentModal attachments={showModalForRefill} closeModal={() => setShowModalForRefill(undefined)} />}
    </div>
  );
};

export default LastDeliveryTicket;
