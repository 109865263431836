import React, { useEffect, useState, memo, FC } from 'react';
import { Button, Checkbox, IconButton, Table, TableBody, TableCell, TableRow, Typography, styled } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import './confirm-controller-replace-modal.css';
import { SelectedSerialType } from '../selected-serial-type';
import {
  CanSuspendSimCardsDocument, ControllerLastBackupDocument,
} from '$typings/graphql-codegen';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import { ControllerReplaceMutationDocument } from '$typings/graphql-codegen';
import ErrorText from '$components/texts/error-text/error-text.react';
import { MuiModalCard } from '$components/modals/mui-modal-card/mui-modal-card';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { useIsMobile } from '$lib/hooks/isMobile';
import { WarningText } from '$components/texts/warning-text/warning-text.react';
import { controllerTypeIsIda2x } from '$lib/application/controllerHelpers';
import { formatDate } from '$lib/dateHelpers';

const StyledTableCell = styled(TableCell)`
  border: 0;
  padding: 0;
  color: gray;
`

interface IConfirmControllerReplaceModalProps {
  selectedSerial: SelectedSerialType;
  currentSerial: SelectedSerialType;
  controllerId: number;
  handleClose: () => unknown;
  onSuccess: (copyConfig: boolean) => unknown;
}

const ConfirmControllerReplaceModalComponent: FC<
  IConfirmControllerReplaceModalProps
> = ({ selectedSerial, currentSerial, controllerId, handleClose, onSuccess }) => {
  const [t] = useCaseInsensitiveTranslation();
  const [errors, setErrors] = useState<string[]>([]);
  const [suspendSim, setSuspendSim] = useState(false);
  const [canSuspendSim, setCanSuspendSim] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [canCopyOverConfig, setCanCopyOverConifg] = useState(false);
  const [copyConfig, setCopyConifg] = useState(true);
  const isMobile = useIsMobile();

  const { data: lastBackupData } = useQuery(ControllerLastBackupDocument, {
    controllerId,
  });

  const { data, loading } = useQuery(CanSuspendSimCardsDocument, {
    controllerIds: [controllerId],
  });

  
  useEffect(() => {
    if (!lastBackupData) return;
    setCanCopyOverConifg(!!lastBackupData.controllerLastBackup.attachmentId && !!lastBackupData?.controllerLastBackup.backupEquipmentId && controllerTypeIsIda2x(selectedSerial?.controllerType || "") && controllerTypeIsIda2x(currentSerial?.controllerType || ""));
  }, [lastBackupData, selectedSerial]);

  useEffect(() => {
    if (!data || loading) return;
    setCanSuspendSim(data.canSuspendSimCards);
  }, [data]);
  
  const clearErrorsAndCloseWithReason = (_event : any, reason : string) => {    
    //Don't close on click outside of modal
    if (reason && reason == 'backdropClick') 
      return;

    clearErrorsAndClose();
  }
  
  const clearErrorsAndClose = () => {    
    setIsProcessing(false);
    if (errors.length) setErrors([]);
    handleClose();    
  }
  

  const replace = async () => {
    if (!selectedSerial) return;
    if (errors.length) setErrors([]);
    try {
      setIsProcessing(true);
      await mutate(
        ControllerReplaceMutationDocument,
        {
          controllerId,
          newSerial: selectedSerial.serial,
          suspendSim,
          attachmentId: canCopyOverConfig && copyConfig && !!lastBackupData?.controllerLastBackup.attachmentId ? lastBackupData.controllerLastBackup.attachmentId : 0,
        },
        true,
        (result) => {
          if (!result.replaceController.success) {
            setErrors([result.replaceController.message]);
            setIsProcessing(false);
            return;
          }
          if (errors.length) setErrors([]);
          onSuccess(canCopyOverConfig && copyConfig);
        },
        (err) => {
          setErrors(err.message);
        }
      );
    } catch (e) {
      const errors = e.response?.errors?.map((e: any) => e.message);
      errors && setErrors(errors);
    }

    setIsProcessing(false);
  };

  return (
    <MuiModalCard
      fullScreen={isMobile}
      open={!!selectedSerial}         
      handleClose={(event : any, reason : string) => clearErrorsAndCloseWithReason(event, reason)}
      headerText={t(
        'UI_ControllerManager_ControllerDetails_ConfirmReplaceSerial_Header'
      )}
      headerActions={
        <IconButton size="small" onClick={clearErrorsAndClose}>
          <Icon name={'fa-times'} />
        </IconButton>
      }
      footerContent={
        <>
          <Button onClick={clearErrorsAndClose} variant="outlined" type="button">
            {t('ui_common_cancel')}
          </Button>
          <Button onClick={replace} 
            variant="contained" 
            disabled={isProcessing} 
            type="button"
            startIcon={
              isProcessing && (
                <Icon name={'fa-spinner fa-pulse'} className="text" />
              )}
            > 
            {t('UI_Common_Ok')}
          </Button>
        </>
      }
    >
      <div className="text-wrapper">
        
        {selectedSerial?.isNewSerial
          ? <>
            <p>{t('ui_controller_replace_box_swap_current_box')}</p>
            <Table sx={{margin: "12px"}}>
              <TableBody>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_Label_Alias')}</StyledTableCell>
                <StyledTableCell>{currentSerial?.controllerName}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_Label_ControllerType')}</StyledTableCell>
                <StyledTableCell>{currentSerial?.controllerType}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_Label_Serial')}</StyledTableCell>
                <StyledTableCell>{currentSerial?.serial}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_SimDetails_Label_ICC')}</StyledTableCell>
                <StyledTableCell>{currentSerial?.simIcc}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_SimDetails_Label_IPAddress')}</StyledTableCell>
                <StyledTableCell>{currentSerial?.simIp}</StyledTableCell>
              </TableRow>
              </TableBody>
            </Table>
            <p>{t('ui_controller_replace_box_new_serial')}</p>
            <Typography sx={{margin: "12px", color: "gray"}}>{selectedSerial.serial}</Typography>
          </>
          : <>
            <p>{t('ui_controller_replace_box_swap_current_box')}</p>
            <Table sx={{margin: "12px"}}>
              <TableBody>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_Label_Alias')}</StyledTableCell>
                <StyledTableCell>{currentSerial?.controllerName}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_Label_ControllerType')}</StyledTableCell>
                <StyledTableCell>{currentSerial?.controllerType}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_Label_Serial')}</StyledTableCell>
                <StyledTableCell>{currentSerial?.serial}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_SimDetails_Label_ICC')}</StyledTableCell>
                <StyledTableCell>{currentSerial?.simIcc}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_SimDetails_Label_IPAddress')}</StyledTableCell>
                <StyledTableCell>{currentSerial?.simIp}</StyledTableCell>
              </TableRow>
              </TableBody>
            </Table>
            <p>{t('ui_controller_replace_box_new_box')}</p>

            <Table sx={{margin: "12px"}}>
              <TableBody>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_Label_Alias')}</StyledTableCell>
                <StyledTableCell>{selectedSerial?.controllerName}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_Label_ControllerType')}</StyledTableCell>
                <StyledTableCell>{selectedSerial?.controllerType}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_Label_Serial')}</StyledTableCell>
                <StyledTableCell>{selectedSerial?.serial}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_SimDetails_Label_ICC')}</StyledTableCell>
                <StyledTableCell>{selectedSerial?.simIcc}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{t('UI_Common_Controller_SimDetails_Label_IPAddress')}</StyledTableCell>
                <StyledTableCell>{selectedSerial?.simIp}</StyledTableCell>
              </TableRow>
              </TableBody>
            </Table>

          </>
        }
        {canCopyOverConfig &&
          <div className="flex row aicenter">
            <Checkbox
              id="copyOverConfig"
              checked={copyConfig}
              className="mark-controllers"
              onChange={() => setCopyConifg((b) => !b)}
            />
            <label
              htmlFor="copyOverConfig"
              className="enabled-checkbox-label"
            >
              {t("ui_replacebox_copyconfigfromcurrentbox", {serial: currentSerial?.serial, date: formatDate(lastBackupData?.controllerLastBackup.lastBackupDate || undefined, false, ".")})}
            </label>
          </div>}
        <div className="flex row aicenter">
          <Checkbox
            id="suspendsimcheck"
            checked={suspendSim}
            className="mark-controllers"
            disabled={!canSuspendSim}
            onChange={() => setSuspendSim((b) => !b)}
          />
          <label
            htmlFor="suspendsimcheck"
            className={
              canSuspendSim
                ? 'enabled-checkbox-label'
                : 'disabled-checkbox-label'
            }
          >
            {t('UI_DeleteControllerDialogs_AlsoSuspendSIMCards', {serial: currentSerial?.serial})}
          </label>
        </div>
        <p>
          {t(
            'UI_ControllerManager_ControllerDetails_ConfirmReplaceSerial_ContinueYesNo'
          )}
        </p>

        {suspendSim && (
          <WarningText>
            <p className="suspend-warning mar_tm">
              {t(
                'UI_DeleteControllerDialogs_AlsoSuspendSIMCards_Warning_Line1'
              )}
            </p>
            <p className="suspend-warning mar_tm">
              {t(
                'UI_DeleteControllerDialogs_AlsoSuspendSIMCards_Warning_Line2'
              )}
            </p>
          </WarningText>
        )}
        {errors.length > 0 && (
          <div className="mar_tm mar_bs">
            {errors.map((e) => (
              <ErrorText key={e}>{t(e)}</ErrorText>
            ))}
          </div>
        )}
      </div>
    </MuiModalCard>
  );
};

export const ConfirmControllerReplaceModal = memo(
  ConfirmControllerReplaceModalComponent
);
