import React, { FC } from 'react';

const AlertTriangle: FC<React.HTMLAttributes<HTMLElement>> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 101.753 111.753"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <g fill="#fff">
      <path d="M50.49 15.236c2.831 0 5.229 1.167 7.074 4.719l24.703 48.24c1.478 2.884 4.034 8.424 2.103 12.19-1.93 3.767-7.406 6.132-11.493 6.128l-44.321.004c-4.275 0-9.011-1.125-10.981-4.968-1.97-3.843-.272-10.603 1.364-13.793L43.44 19.955c1.82-3.552 4.218-4.72 7.05-4.72zm.186 7.74c1.347.026 3.76 5.354 5.088 7.908l17.768 34.698c1.063 2.075 5.257 8.985 4.097 11.247-1.16 2.261-7.911 1.932-10.85 1.93l-31.88.002c-3.075 0-9.583.298-10.756-1.99s2.662-9.21 3.838-11.505l17.624-34.382c1.31-2.554 3.723-7.933 5.07-7.908z" />
      <path d="m46.712 44.543 2 20.32 4.214-.013 2.114-21.306v-5.996h-8.328zM47.045 75.19h7.662v-7.33h-7.662z" />
    </g>
  </svg>  
);

export default AlertTriangle;
