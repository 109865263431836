import React, { FC } from 'react';

interface ISignalBadProps {
  className?: string;
}

const SignalBad: FC<ISignalBadProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 118.743 118.743"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <circle cx="59.372" cy="50.705" r="10.815" fill="red" />
    <rect x="52.452" y="65.094" width="13.895" height="39.39" rx="6.295" ry="6.295" fill="#d9e1e2" />
  </svg>
);

export default SignalBad;
