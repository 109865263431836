import React, { memo, FC } from 'react';

interface IHistoryListSeparatorProps {
  year: number;
}

const HistoryListSeparatorComponent: FC<IHistoryListSeparatorProps> = ({
  year,
}) => (
  <div className="history-list-separator">
    <span>{year}</span>
    <hr />
  </div>
);

export const HistoryListSeparator = memo(HistoryListSeparatorComponent);
