import React, {FC, HTMLAttributes} from "react";

export type Truthy = object | string | number | boolean | any;

export interface RenderIfProps extends HTMLAttributes<HTMLElement> {
  if?: Truthy
  else?: React.ReactNode
}

/**
 * # RenderIf
 * A conditional render component that makes conditional rendering
 * readable and beautiful. If you are looking for conditional
 * visibility only, check out the ViewIf component.
 * @param props
 * @constructor
 * @see {ViewIf}
 */
const RenderIf: FC<RenderIfProps> = (props: RenderIfProps) => (
  <>{
    props.if ? (
      props.children
    ) : (
      props.else ? props.else : <></>
    )}</>
)

export default RenderIf
