import React, { useState, memo, FC, useEffect } from 'react';
import { TextField } from '@mui/material';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import Dropdown from '$components/dropdowns/dropdown/dropdown.react';

enum DecommissionReasonEnum 
{
    DefectiveDevice = 0,
    LostDevice = 1,
    LostCustomer = 2,
    FromTicket= 3,
    Other = 4
}

export interface IDecommissionReason {
  reason: DecommissionReasonEnum;
  reasonText: string;
}

interface IDecommissionReasonProps {
  onChange: (reason: IDecommissionReason | undefined) => void
}

const DecommissionReasonComponent: FC<IDecommissionReasonProps> = ({
  onChange
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [selectedReason, setSelectedReason] = useState<IReasonDropdownItem | undefined>(undefined);
  const [serviceNowTicketNumber, setServiceNowTicketNumber] = useState<number | undefined>(undefined);
  const [otherReasonComment, setOtherReasonComment] = useState("");
  
  interface IReasonDropdownItem {
    value: DecommissionReasonEnum;
    display: string;
  }

  const getReason = () =>  {
    if (selectedReason == undefined) return undefined;
    if (selectedReason.value == DecommissionReasonEnum.FromTicket) {
      if (serviceNowTicketNumber as number >= 1000000) return { reason: DecommissionReasonEnum.FromTicket, reasonText: 'RTSK' + serviceNowTicketNumber }
      else return undefined;
    }
    if (selectedReason.value == DecommissionReasonEnum.Other) {
      if (otherReasonComment != "") return { reason: DecommissionReasonEnum.Other, reasonText: otherReasonComment }
      else return undefined;
    }
    return { reason: selectedReason.value, reasonText: "" }
  }

  useEffect(() => { 
    const reason = getReason();
    onChange(reason);
  }, [selectedReason, serviceNowTicketNumber, otherReasonComment]);

  const reasonDropdownItems: IReasonDropdownItem[] = [
    {value:DecommissionReasonEnum.DefectiveDevice, display: t('ui_decommissionreason_dropdown_item_defectivedevice')},
    {value:DecommissionReasonEnum.LostDevice, display: t('ui_decommissionreason_dropdown_item_lostdevice')},
    {value:DecommissionReasonEnum.LostCustomer, display: t('ui_decommissionreason_dropdown_item_lostcustomer')},
    {value:DecommissionReasonEnum.FromTicket, display: t('ui_decommissionreason_dropdown_item_fromticket')},
    {value:DecommissionReasonEnum.Other, display: t('ui_decommissionreason_dropdown_item_other')},
  ];  

  const onReasonDropdownClicked = (params: any) => {
    const selectedReason = reasonDropdownItems.find(o => o.value == params.value);
    setSelectedReason(selectedReason);
  }

  const onTicketNumberChange = (value: string) => {
    const trimmedValue = value.trim();
    if (trimmedValue.substring(0, 4).toUpperCase() != "RTSK") {
      setServiceNowTicketNumber(undefined);
      return;
    }
    const numberPart = trimmedValue.substring(4);
    const parseIntResult = parseInt(numberPart);
    const ticketNumber = Number.isNaN(parseIntResult) ? undefined : parseIntResult;
    setServiceNowTicketNumber(ticketNumber);
  }

  return (  
    <>
      <div>
        <Dropdown<IReasonDropdownItem>    
          selected={selectedReason}
          items={reasonDropdownItems}
          itemClicked={onReasonDropdownClicked}
          displayPath="display"
          valuePath="value"
          canSelectBlankValue={false}
          placeholder={t('ui_decommissionreason_dropdown_placeholder')}
        />
      </div>
      { selectedReason?.value == DecommissionReasonEnum.FromTicket ?
        <div className="mar_tm">
            <TextField
              size="small"
              placeholder={t('ui_decommissionreason_ticket_textfield_placeholder')}
              onChange={(e) => onTicketNumberChange(e.target.value)}
            />
        </div>
        : null
      }
      { selectedReason?.value == DecommissionReasonEnum.Other ?
        <div className="mar_tm">        
            <TextField
              size="small"
              placeholder={t('ui_decommissionreason_other_textfield_placeholder')}
              onChange={(e) => setOtherReasonComment(e.target.value)}
            />            
        </div>
        : null
      }
    </>
  );
};

export const DecommissionReason = memo(DecommissionReasonComponent);
