import React, { FC, useEffect, useRef, useState } from 'react';
import { isEmpty } from '$lib/helpers';
import { IControllerMapMarker, IMapMarker } from '$interfaces/application';
import { MarkerIconEnum } from '$typings/graphql-codegen';
import {
  MarkerClusterer,
  SuperClusterAlgorithm
} from '@googlemaps/markerclusterer';
import { NO_MARKER_ZOOM, ONE_MARKER_ZOOM, getIconZIndex, getMarkerIcon, renderCluster, useMap } from './map-utills';
import SiteMarkerHoverComponent from './modules/site-marker-hover-component/site-marker-hover-component';

interface IPopupConfig {
  marker: IMapMarker
  googleMarker: google.maps.marker.AdvancedMarkerElement
  siteId: number,
  placeX: number,
  placeY: number,
}

interface IMapProps {
  name: string;
  rememberZoomPosition?: boolean;
  initialBounds?: string;
  markers: IMapMarker[];
  autoBounds?: boolean;
  onClick?: (marker: IMapMarker, e?: React.MouseEvent | MouseEvent) => void;
  clustered?: boolean
  className?: string;
  showAccuracy?: boolean,
  noGoogleui?: boolean;
  gestureHandling?: 'cooperative' | 'greedy' | 'none';
  mapClick?: Function
}

const rememberedZoomPosition: Map<string, { center?: { lat: number, lng: number }; zoom?: number } | undefined> =
  new Map<string, { center?: { lat: number, lng: number }; zoom?: number } | undefined>();

const MapComponent: FC<IMapProps> = ({
  rememberZoomPosition,
  initialBounds,
  autoBounds,
  markers,
  name,
  onClick,
  clustered,
  className,
  showAccuracy = true,
  gestureHandling = 'cooperative',
  noGoogleui,
  mapClick,
}) => {
  const markerCluster = useRef<MarkerClusterer | null>();
  const [mapElement, map, mapLoaded] = useMap({ center: getInitialCenter(), zoom: getInitialZoom(), gestureHandling, disableDefaultUI: noGoogleui }, mapClick)
  const [mapEventListeners, setMapEventListeners] = useState<google.maps.MapsEventListener[]>([]);
  const [openPopupConfig, setOpenPopupConfig] = useState<IPopupConfig | undefined>(undefined)
  const [mapCircles, setMapCircles] = useState<google.maps.Circle[]>([]);


  function createSaneMapBounds(
    markers: google.maps.marker.AdvancedMarkerElement[]
  ) {
    let bounds = new google.maps.LatLngBounds();
    markers.forEach(marker => {
      const position = marker.position;
      if (position) bounds.extend(position);
    });

    const minimalLat = 0.008;
    const minimalLng = 0.008;
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    if (sw.lat() - ne.lat() < minimalLat || ne.lng() - sw.lng() < minimalLng) {
      const centerPos = bounds.getCenter(); // These bounds adjustments solve bug #4393
      bounds.extend(
        new google.maps.LatLng(
          centerPos.lat() + minimalLat,
          centerPos.lng() - minimalLng,
          false
        )
      );
      bounds.extend(
        new google.maps.LatLng(
          centerPos.lat() - minimalLat,
          centerPos.lng() + minimalLng,
          false
        )
      );
    }
    return bounds;
  }

  function getInitialCenter() {
    const rememberedCenter = rememberedZoomPosition.get(name)?.center;
    if (rememberZoomPosition && rememberedCenter) {
      return rememberedCenter;
    }

    return isEmpty(markers) && !initialBounds
      ? { lat: 0, lng: 0 }
      : markers.length === 1 && !initialBounds
        ? { lat: markers[0].lat || 0, lng: markers[0].lng || 0 }
        : null;
  }

  function getInitialZoom() {
    const rememberedZoom = rememberedZoomPosition.get(name)?.zoom;
    if (rememberZoomPosition && rememberedZoom) {
      return rememberedZoom;
    }

    return isEmpty(markers) && !initialBounds
      ? NO_MARKER_ZOOM
      : markers.length === 1 && !initialBounds
        ? ONE_MARKER_ZOOM
        : undefined;
  }

  function clusterMarkers(mapMarkers: google.maps.marker.AdvancedMarkerElement[]
  ) {
    markerCluster.current?.clearMarkers();
    if (!map.current) return;

    markerCluster.current = new MarkerClusterer({
      map: map.current,
      markers: mapMarkers,
      algorithmOptions: { maxZoom: 8 },
      algorithm: new SuperClusterAlgorithm({
        minZoom: 0,
        maxZoom: 8,
        minPoints: 2,
        radius: 65,
        nodeSize: 1216
      }),
      renderer: { render: renderCluster }
    });
  }

  const addMarkersToMap = (
    mapMarkers: google.maps.marker.AdvancedMarkerElement[]
  ) => {
    if (clustered) {
      clusterMarkers(mapMarkers)
    } else {
      mapMarkers.forEach(mm => mm.map = map.current)
    }
  };

  function createAccuracyCircles(
    markers: IMapMarker[],
    map: google.maps.Map
  ): google.maps.Circle[] {
    let circles: google.maps.Circle[] = [];

    for (const marker of markers) {
      if (marker.accuracy !== 0) {
        const circle = new google.maps.Circle({
          strokeColor: '#000044',
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: '#000044',
          fillOpacity: 0.2,
          map: map,
          center: { lat: marker.lat, lng: marker.lng },
          radius: marker.accuracy
        });
        const circleBounds = circle.getBounds()
        //circle.bindTo('center', marker, 'position');
        if (circleBounds)
          map.fitBounds(circleBounds);

        circles.push(circle);
      }
    }

    return circles;
  }

  useEffect(() => {
    return () => {
      mapCircles.forEach(mc => mc.setMap(null))
      mapEventListeners.forEach(e => e.remove());
    }
  }, [])

  useEffect(() => {
    if (!mapLoaded) return;
    const mapMarkers = createMarkers(markers);
    addMarkersToMap(mapMarkers);

    if (!clustered && showAccuracy && !!map.current) {
      setMapCircles(createAccuracyCircles(markers, map.current));
    }


    if (
      !initialBounds &&
      autoBounds &&
      !rememberedZoomPosition.get(name)?.zoom
    ) {
      if (mapMarkers.length > 1) {
        const bounds = createSaneMapBounds(mapMarkers);
        map.current?.fitBounds(bounds);
      } else if (mapMarkers.length === 1) {
        const firstPosition = mapMarkers[0].position;
        if (firstPosition) {
          map.current?.setCenter(firstPosition);
          map.current?.setZoom(ONE_MARKER_ZOOM);
        }
      }
    }
  }, [markers, mapLoaded]);

  function mouseOverMarker(marker: IMapMarker, m: google.maps.marker.AdvancedMarkerElement) {
    if (m.content) { 
      (m.content as HTMLImageElement).style.width = "30px";
      (m.content as HTMLImageElement).style.height = "50px";
      m.zIndex = ((m.zIndex || 0) + 10)
    }

    const overlay = new google.maps.OverlayView();
    overlay.draw = function () { };
    overlay.setMap(map.current);
    const latLng = m.position
    if (!latLng) return
    const point = overlay.getProjection()?.fromLatLngToContainerPixel(latLng)

    const siteId = (marker as IControllerMapMarker).siteId

    siteId && setOpenPopupConfig({ siteId: siteId, placeX: point?.x || 0, placeY: point?.y || 0, googleMarker: m, marker: marker })
  }

  function mouseLeaveMarker(e: MouseEvent, m: google.maps.marker.AdvancedMarkerElement) {
    if ((e.relatedTarget as HTMLElement).id === "hoverElement") {
      return
    }
    closePopup(m)
  }

  function closePopup(m: google.maps.marker.AdvancedMarkerElement) {
    if (m.content) { 
      (m.content as HTMLImageElement).style.width = "25px";
      (m.content as HTMLImageElement).style.height = "45px";
      m.zIndex = ((m.zIndex || 0) - 10)
    }
    setOpenPopupConfig(undefined)
  }

  function createMarkers(markers: IMapMarker[]) {
    return markers.map(marker => {
      const markerImg = document.createElement("img");
      markerImg.src = getMarkerIcon(marker)
      markerImg.style.width = "25px"
      markerImg.style.height = "45px"


      const m = new google.maps.marker.AdvancedMarkerElement({
        position: new google.maps.LatLng({
          //For some reason the advanced marker element dont render markers with position (0,0), (0,0) is probably
          //wrong anyway but the 0.00000000000001 atleast puts the marker on the map
          lat: marker.lat === 0 ? 0.00000000000001 : marker.lat,
          lng: marker.lng === 0 ? 0.00000000000001 : marker.lng
        }),
        zIndex: marker.markerType === 'controller'
          ? getIconZIndex(marker.markerIconEnum)
          : undefined,
        content: markerImg,
      });


      //"Manually" adjust anchor points for custom markers
      if (m.content && m.content.parentElement && marker.markerType === "controller") {
        switch (marker.markerIconEnum) {
          case MarkerIconEnum.ControllerOffline:
          case MarkerIconEnum.ControllerOnline:
            m.content.parentElement.style.transform = "translate(42%, 30%)"
            break
          default:
            m.content.parentElement.style.transform = "translate(0, 10%)"
        }
      }

      if (marker.markerType === 'controller')
        m.setAttribute('hasAlarm', (marker.markerIconEnum === MarkerIconEnum.Alarm ? 1 : 0).toString());

      if (onClick) {
        setMapEventListeners([...mapEventListeners, m.addListener('click', ({ domEvent }: { domEvent: PointerEvent }) => {
          const center = map.current?.getCenter()
          rememberZoomPosition && center?.lat() && center?.lng() && rememberedZoomPosition.set(name, { center: { lat: center.lat(), lng: center.lng() }, zoom: map.current?.getZoom() })
          onClick(marker, domEvent)
        }
        )])
      }

      const mouseOverEventListner = m.content?.parentElement?.parentElement?.addEventListener("mouseover", () => mouseOverMarker(marker, m))
      if (mouseOverEventListner)
        setMapEventListeners([...mapEventListeners,])

      const mouseLeaveEventListner = m.content?.parentElement?.parentElement?.addEventListener("mouseleave", (e) => mouseLeaveMarker(e, m))
      if (mouseLeaveEventListner)
        setMapEventListeners([...mapEventListeners, mouseLeaveEventListner])

      return m;
    });
  }

  return (<>
    {openPopupConfig?.siteId && <SiteMarkerHoverComponent onClick={(e) => onClick && onClick(openPopupConfig.marker, e)} closePopup={() => closePopup(openPopupConfig.googleMarker)} siteId={openPopupConfig.siteId} position={{ x: openPopupConfig.placeX, y: openPopupConfig.placeY }} />}
    <div id="yt3_map_component_id" className={className} ref={mapElement} />
  </>)
};

export default MapComponent;
