import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import CardHeader from '$components/cards/card-header/card-header.react';
import ModalCard from '$components/modals/modal-card/modal-card.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import React, { FC, useState } from 'react';
import './require-confirmation.css';
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";

interface IRequireConfirmationProps {
  onCancel?: Function;
  onConfirm?: Function;
  confirmType?: 'delete' | 'primary';
  headerText?: string;
  submitText?: string;
  cancelText?: string;
  deleteDisabled?: boolean;
  deleteText?: string;
  entityName?: string;
  bodyText?: string;
  error?: string;
}

const RequireConfirmation: FC<IRequireConfirmationProps> = ({
  onCancel,
  onConfirm,
  confirmType = 'delete',
  submitText = 'ui_requireconfirmation_confirmokbutton',
  cancelText = 'ui_requireconfirmation_cancelbutton',
  deleteDisabled = false,
  deleteText = 'ui_requireconfirmation_confirmdeletebutton',
  entityName = '',
  bodyText = '',
  error,
  children
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [processing, setProcessing] = useState(false);

  function cancel() {
    if (!onCancel) return;
    onCancel();
  }

  async function confirm() {
    if (!onConfirm) return;
    setProcessing(true);
    await onConfirm();
    setProcessing(false);
  }

  return (
    <ModalCard cardClass={'require-confirmation-dialog'} overflow={false} placementOnMobile={'bottom'}>
      <CardHeader>
        <p>{t('ui_requireconfirmation_header')}</p>
      </CardHeader>

      <CardContent className="bodycontent">
        {children ? children : <p>{bodyText || t('ui_requireconfirmation_text', { entityName: entityName })} </p>}
      </CardContent>

      {error && (
        <CardContent className="error">
          <ErrorText>{error}</ErrorText>
        </CardContent>
      )}

      <CardFooter>
        <div className="action-buttons margin-top">
          <Button variant="secondary" className="margin-right" onClick={cancel}>
            {t(cancelText)}
          </Button>

          {confirmType === 'delete' && (
            <Button variant="danger" className="margin-left" disabled={processing || deleteDisabled} processing={processing} onClick={confirm}>
              {t(deleteText)}
            </Button>
          )}

          {confirmType === 'primary' && (
            <Button className="margin-left" onClick={confirm}>
              {t(submitText)}
            </Button>
          )}
        </div>
      </CardFooter>
    </ModalCard>
  );
};

export default RequireConfirmation;
