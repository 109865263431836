import React, { useState, memo, FC } from 'react';
import { MuiModalCard } from '$components/modals/mui-modal-card/mui-modal-card';
import { Button, IconButton } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import '../common-modal-styles.css';
import { useIsMobile } from '$lib/hooks/isMobile';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { UpdateControllerConflict} from '$typings/graphql-codegen';

interface IControllerUpdateModalProps {
    conflictingControllers: UpdateControllerConflict[]
    canShowControllerManager: boolean
    handleUpdateController: () => void
    handleClose: () => unknown;

  }

  const ControllerUpdateModalComponent: FC<IControllerUpdateModalProps> = ({
    conflictingControllers,
    canShowControllerManager,
    handleUpdateController,
    handleClose,
  }) => {
    const [t] = useCaseInsensitiveTranslation();
    const [loading, setLoading] = useState(false);
    const isMobile = useIsMobile();
  
    const doUpdateWithConflict = async () => {
      setLoading(true);
      await update();
      setLoading(false);
      handleClose();
    };

    const update = async () => {
      await handleUpdateController();
    }

    const buildControllerManagerLink = (controllerId: number) => {
      return canShowControllerManager ? `/controllermanager/${controllerId}` : '';
      }
    return (
        <MuiModalCard
          fullScreen={isMobile}
          open={true}
          handleClose={handleClose}
          headerText={t('UI_RequireConfirmation_Header')}
          headerActions={
            <IconButton size="small" onClick={handleClose}>
              <Icon name={'fa-times'} />
            </IconButton>
          }
          footerContent={
            <>
              <Button onClick={handleClose} variant="outlined" type="button">
                {t('ui_common_cancel')}
              </Button>
              <Button
                onClick={doUpdateWithConflict}
                disabled={loading}
                variant="contained"
                type="button"
                startIcon={
                  loading && <Icon name={'fa-spinner fa-pulse'} className="text" />
                }
              >
                {t('UI_RequireConfirmation_ConfirmOkButton')}
              </Button>
            </>
          }
        >
          <div style={{ width: isMobile ? undefined : '400px' }} className="text-wrapper">
            <p>
              {t(
                'UI_ControllerManager_ControllerDetails_UpdateControllersDialog_Body'
              )} 
            </p>
            {conflictingControllers.map((controller: UpdateControllerConflict, index) => (
                   <a href={buildControllerManagerLink(controller.controllerId)} target="_blank" rel="noreferrer" key={index}><br />Serial: {controller.serial} | Alias: {controller.alias}</a>
                ))}
          </div>
        </MuiModalCard>
      );
    };
    
    export const ControllerUpdateModal = memo(ControllerUpdateModalComponent);
   
    