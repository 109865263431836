import React, { memo, FC } from 'react';
import MuiTextField, {
  ExtendedTextFieldProps,
} from '$components/textbox/mui-textfield.react';
import { getNewStringIfMatched } from '$lib/inputHelpers';

interface IccInputSpecificProps {
  setValue?: (value: string) => unknown;
  value?: string;
}

export const getIccFormValidation = (t: (key: string) => string) => ({
  pattern: {
    value: /^[0-9]{19,20}$/,
    message: t('UI_Common_ValidationErrors_ICC'),
  },
});

type IIccInputProps = ExtendedTextFieldProps & IccInputSpecificProps;

const IccInputComponent: FC<IIccInputProps> = ({
  onChange,
  value,
  setValue,
  ...props
}) => {
  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    let newValue = getNewStringIfMatched(
      (value ?? '') as string,
      e.target.value,
      /^[0-9]*$/
    );

    if (newValue.length > 20) {
      newValue = value ?? '';
    }

    e.target.value = newValue;
    setValue?.(newValue);
    onChange?.(e);
  };

  return <MuiTextField {...props} value={value} onChange={changeHandler} />;
};

export const IccInput = memo(IccInputComponent);
