import {ReactWrapper} from "$pages/common/react-wrapper";
import {ComponentType, HTMLAttributes} from "react";

export interface IReactReportWrapper extends HTMLAttributes<HTMLElement> {
  scheduledReportId?: string;
}

/**
 * # Aurelia React Report Wrapper ⚛️
 * Wraps React components and makes them compatible with Aurelia.
 */
export class AureliaReactReportWrapper extends ReactWrapper<IReactReportWrapper, IReactReportWrapper> {
  constructor(reportComponent: ComponentType<IReactReportWrapper>) {
    super(reportComponent, AureliaReactReportWrapper._handleProp)
  }

  private static _handleProp(props: IReactReportWrapper) {
    return ({
      scheduledReportId: props.scheduledReportId
    })
  }
}
