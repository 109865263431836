import React, { FC, HTMLAttributes, useMemo, useState } from 'react';
import './generic-report.css';
import { Reports, ScheduledReportFilter, SortDirection, ReportFormatEnum } from '$typings/graphql-codegen';
import ScheduledReportFilterComponent
  from '$pages/reports/modules/schedule-report-filters/scheduled-report-filter.react';
import Card from '$components/cards/card/card.react';
import { ReportGridDataHook, useReportDownload, useReportGridData } from '$pages/reports-react/generic-report-hooks';
import Grid, { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import { ColumnDefinition } from '$pages/reports-react/column-definition';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { orderByPredicate } from '$lib/sorting';
import ActionButton from '$components/actions/action-button/action-button.react';
import ActionContentItem from '$components/actions/action-content-item/action-content-item.react';
import { isNumeric } from '$lib/numberHelpers';

enum DownloadIcon {
  Download = 'fa-download',
  Loading = 'fa-spinner fa-pulse'
}

export type SortObject = {
  sortColumnKey: string,
  sortDirection: SortDirection
}

export interface GenericReportProps<TResult, TVariables, TRowData, TDownloadResult> extends HTMLAttributes<HTMLElement> {
  id?: string,
  titleCallback: (data: ReportGridDataHook<TRowData>, variables?: TVariables) => JSX.Element,
  scheduledReportId?: string,
  reportEnum: Reports,
  supportedFormats?: Array<ReportFormatEnum>,
  mainQueryDocument: TypedDocumentNode<TResult, TVariables>,
  downloadQueryDocument: TypedDocumentNode<TDownloadResult, TVariables>,
  columnDefinitions: ColumnDefinition<TRowData>[],
  dataSelector: (result: TResult) => TRowData[],
  dataCounter: (result: TResult) => number,
  defaultSortSettings: SortObject,
  downloadUrlSelector: (result: TDownloadResult) => string,
  downloadFilename: string,
  allowRunningUnfiltered?: boolean
}

/**
 * # Use Generic Report 👽🛸
 * Will return a pre-typed, specialized — but generic — report component that will share the majority
 * of features of all reports. The purpose of this hook is to make reports as DRY as possible 🏜️.
 */
export function useGenericReport<TResult, TVariables, TRowData, TDownloadResult>(): {
  GenericReport: FC<GenericReportProps<TResult, TVariables, TRowData, TDownloadResult>>
} {
  return {
    GenericReport: (props) => {
      const [t] = useCaseInsensitiveTranslation()
      const [variables, setVariables] = useState<TVariables>()
      const [filtersChanged, setFiltersChanged] = useState<boolean>(false)
      const [downloadDisabled, setDownloadDisabled] = useState<boolean>(true)
      const [icon, setIcon] = useState<DownloadIcon>(DownloadIcon.Download)
      
      const [
        sortSettings,
        setSortSettings
      ] = useState<SortObject>(
        props.defaultSortSettings
      )

      const data: ReportGridDataHook<TRowData> = useReportGridData<TResult, TVariables, TRowData>(
        props.mainQueryDocument,
        variables as TVariables,
        props.dataSelector,
        props.dataCounter
      )

      /**
       * # Get Sorted Data
       * Mutate the data object by sorting and selecting columns.
       */
      function getSortedData() {
        if (!data) return {} as typeof data
        const dataArray: TRowData[] = data.data ?? []
        type Key = keyof TRowData
        const key: Key = sortSettings.sortColumnKey as Key
        const dir: string = sortSettings.sortDirection.toLowerCase()
        const predicateCallback = (v: TRowData) => v[key] ?? ''
        const sortedData: TRowData[] = orderByPredicate(dataArray, predicateCallback, dir)
        return {
          ...data,
          data: sortedData
        } as typeof data
      }

      /**
       * Returns the scheduledReportId if provided in the URL 
       */
      function getScheduledReportId() : string | undefined {
        const href = window.location.href;
        const parts = href.split('/');
        const lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash

        if (!lastSegment)
          return;

        if (isNumeric(lastSegment))
          return lastSegment;

        return;
      }

      const { downloadReport } = useReportDownload<TDownloadResult, TVariables>(
        props.downloadQueryDocument,
        variables as TVariables,
        props.downloadUrlSelector,
        props.downloadFilename,
        () => {
          setIcon(DownloadIcon.Loading)
          setDownloadDisabled(true)
        },
        () => {
          setIcon(DownloadIcon.Download)
          setDownloadDisabled(false)
        }
      )

      function onFilter(filter: ScheduledReportFilter): void {
        setVariables({ input: filter } as any as TVariables);
        setFiltersChanged(true);
        setDownloadDisabled(false);
      }

      const PlaceholderText: JSX.Element = useMemo(
        () => {
          const text = filtersChanged
            ? 'UI_Grid_NoMatch' :
            'UI_Reports_Dosing_Initial_Message'
          return (
            <div
              className="text-center text-balance"
              style={{maxWidth: 320}}>
              { t(text) }
            </div>
          )
        },
        [filtersChanged]
      )
      
      const toGridColumns = (def: ColumnDefinition<TRowData>) => def.createReactGridColumn()
      const GridColumns: IReactGridColumn<React.ReactNode>[] = useMemo(
        () => props.columnDefinitions.map(toGridColumns),
        [props]
      )

      const DownloadButton = (formats: Array<ReportFormatEnum>) => 
        ( 
          <ActionButton
            buttonType={'secondary'}
            text={'Download'}
            disabled={downloadDisabled}
            icon={icon}
          >
            {formats.map(format => (
              <ActionContentItem
                key={format}
                onClick={() => {
                  downloadReport(format);
                }}
              >
                {format}
              </ActionContentItem>
            ))}
          </ActionButton>
        )
      
      const MemoizedGrid: JSX.Element = useMemo(() => {
        const sortedData = getSortedData()

        return (
          <Grid
            name={props.id ?? ''}
            items={sortedData}
            loading={data.isLoading}
            columns={GridColumns}
            sortedColumnKey={sortSettings.sortColumnKey}
            sortedDirection={sortSettings.sortDirection}
            sortChanged={(sortColumnKey, sortDirection) => {
              setSortSettings({
                sortColumnKey,
                sortDirection
              });
            }}
            noMatchingRowsLanguageKey={PlaceholderText}/>
        )
      }, [data, sortSettings])
      
      const MemoizedTitle: JSX.Element = useMemo(() => props.titleCallback(data, variables), [data])
      
      const CardHeader = () => {
        const style: React.CSSProperties = {
          fontSize: '1.3rem' // What the other header component does.
        }
        return (
          <div className="flex items-center justify-between p-3">
            <h2 className="p-0 m-0 font-normal" style={style}>{MemoizedTitle}</h2>
            <div>{DownloadButton(props.supportedFormats ?? [ReportFormatEnum.Excel])}</div>
          </div>
        )
      }

      return (
        <div
          id={props.id}
          className="generic-report flex items-stretch h-full">
          <ScheduledReportFilterComponent
            scheduledReportId={getScheduledReportId()}
            report={props.reportEnum}
            supportedFormats={props.supportedFormats ?? [ReportFormatEnum.Excel]}
            reportIsRunning={data.isLoading}
            filtersChanged={onFilter} 
            allowRunningUnfiltered={props.allowRunningUnfiltered ?? true} />
          <div className="flex items-stretch w-full h-full p-4">
            <Card className="grid-card items-stretch h-full">
              <CardHeader />
              { MemoizedGrid }
            </Card>
          </div>
        </div>
      )
    }
  }
}
