import React, { FC } from 'react';
import './action-content-item.css';
import classNames from "classnames";

interface IActionContentItemProps {
  disabled?: boolean;
  onClick?: () => void;
}

const ActionContentItem: FC<IActionContentItemProps> = ({
  onClick,
  disabled,
  children
}) => (
  <div onClick={onClick} className="action-content-item-component">
    <div className={classNames('component-wrapper', 'clickable', disabled ? 'disabled' : '')}>
      {children}
    </div>
  </div>
);

export default ActionContentItem;
