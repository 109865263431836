import React, { memo } from 'react';
import {
  DeepPartial,
  FieldValues,
  Path,
  PathValue,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { CardHeader, IconButton, Typography } from '@mui/material';
import { ViewForm } from '$pages/common/form-editor/modules/view-form';
import { EditingForm } from '$pages/common/form-editor/modules/editing-form';
import Icon from '$components/icons/icon/icon.react';
import { CardFlexColumn } from '$components/cards/mui-card';
import { IEditingFormProps } from '$pages/common/form-editor/modules/editing-form/editing-form';

export type FormEditorType =
  | 'text'
  | 'email'
  | 'number'
  | 'checkbox'
  | 'datetime'
  | 'ipaddress'
  | 'icc'
  | 'msisdn'
  | 'custom';

export interface ICardFormEditorProps<T extends FieldValues> {
  allowEdit: boolean;
  editing: boolean;
  setEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel?: () => unknown; // overrides setEditing(false) if not undefined
  saveText?: string;
  headerText: string;
  formItems: ICardFormEditorItem<T>[];
  data: DeepPartial<T>;
  onSubmit: (setErrorMessage: (message: string) => unknown) => SubmitHandler<T>;
  children?: React.ReactNode;
}

export interface ICardFormEditorItem<T extends FieldValues> {
  displayText: string;
  dataPropertyName?: keyof DeepPartial<T>;
  valueLink?: string;
  placeholder?: string | number | boolean;
  type?: FormEditorType; //defaults to text
  allowEdit?: boolean; // defaults to true
  onlyShowIfPermission?: boolean;
  onlyDisplayInMode?: 'editing' | 'viewing'; //defaults to both
  validationOptions?: RegisterOptions<T>;
  customEditComponent?: (useFormProps: UseFormReturn<T>) => React.ReactNode;
  customViewComponent?: (value: PathValue<T, Path<T>> | unknown, allData?: DeepPartial<T>) => React.ReactNode;
  minValue?: number; // for number input
  maxValue?: number; // for number input
  allowDecimal?: boolean; // for number input
}

const CardFormEditorComponent = <T extends FieldValues,>({
  allowEdit,
  editing,
  formItems,
  headerText,
  setEditing,
  saveText,
  onCancel,
  data,
  children,
  onSubmit,
}: ICardFormEditorProps<T>) => {
  const allowedItems = formItems.filter(
    (i) => i.onlyShowIfPermission === undefined || i.onlyShowIfPermission
  );

  const requestEdit = () => {
    if (allowEdit) {
      setEditing?.(true);
    }
  };

  const discardEdit = () => {
    if (allowEdit) {
      setEditing?.(false);
    }
  };

  const editingProps: IEditingFormProps<T> = {
    data,
    onSubmit,
    saveText,
    items: allowedItems.filter(
      (i) =>
        i.onlyDisplayInMode === undefined || i.onlyDisplayInMode !== 'viewing'
    ),
    onCancel: onCancel ? onCancel : () => setEditing?.(false),
  };

  const viewProps = {
    data,
    items: allowedItems.filter(
      (i) =>
        i.onlyDisplayInMode === undefined || i.onlyDisplayInMode !== 'editing'
    ),
  };

  return (
    <CardFlexColumn>
      <CardHeader
        sx={{
          '& .MuiCardHeader-content': {
            overflow: 'hidden',
          },
        }}
        title={
          <Typography noWrap variant="h6" title={headerText}>
            {headerText}
          </Typography>
        }
        action={
          setEditing &&
          allowEdit &&
          (editing ? (
            <IconButton size="small" onClick={discardEdit}>
              <Icon name={'fa-times'} />
            </IconButton>
          ) : (
            <IconButton size="small" onClick={requestEdit}>
              <Icon name={'fa-pencil'} />
            </IconButton>
          ))
        }
      />

      {children && <div className="pad_h pad_bs">{children}</div>}

      {allowEdit && editing ? (
        <EditingForm {...editingProps} />
      ) : (
        <ViewForm {...viewProps} />
      )}
    </CardFlexColumn>
  );
};

export const CardFormEditor = memo(
  CardFormEditorComponent
) as typeof CardFormEditorComponent;
