import Icon from "$components/icons/icon/icon.react";
import TextField from "$components/textbox/text-field.react";
import { IReactGroupedFilterDefinition } from "$pages/sitelistpage-react/modules/sitelist/sitelist.react";
import { ElasticSearchPage } from "$typings/graphql";
import React, { FC, ReactNode, useEffect, useState, memo } from "react";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import { IFilterGroup } from "src/interfaces";
import "./filter-list.css";
import { IReactGridColumn, IReactGridFilter } from '$components/grid/react-grid/grid.react';
import { useIsMobile } from "$lib/hooks/isMobile";
import { Button } from "@mui/material";
import { groupByWithSelect } from '$lib/arrayHelpers';
import { orderByPredicate } from '$lib/sorting';
import classNames from "classnames";

export const groupFilterDefinitions = <T,>(
  columns: IReactGridColumn<T>[],
  t: (s: string) => string
): IReactGroupedFilterDefinition[] => {
  const dicts = columns
    .map(c => ({ filter: c.filter, managementGroup: c.managementGroup ?? 'Other' }))
    .filter(
    c => c.managementGroup !== undefined && c.filter !== undefined
  );
  const groupedFilters = groupByWithSelect(
    dicts,
    d => d.managementGroup,
    i => i.filter!
  );
  const groupedFilterDefinitions = Object.entries(groupedFilters).map<
    IReactGroupedFilterDefinition
  >(([key, value]) => ({
    managementGroup: key,
    filters: orderByPredicate(value, f => t(f.name) as string, 'asc', true)
  }));

  return orderByPredicate(
    groupedFilterDefinitions,
    g => t(g.managementGroup) as string,
    'asc',
    true
  );
};

export interface IFilterListProps {
  filtersChanged: (newFilters: IFilterGroup[]) => unknown;
  activeFilters?: IFilterGroup[];
  freeTextQuery: string[] | undefined;

  variablesHasChanged?: boolean;
  setDefaultFilters: () => unknown;

  filterQuery: string;
  setFilterQuery: (newFilter: string) => unknown;

  page: ElasticSearchPage;
  groupedFilterDefinitions: IReactGroupedFilterDefinition[];
  className?: string;

  slot1?: ReactNode; // child slot on top of the filter search field
  slot2?: ReactNode; // child slot beneath the filter search field, above the filter list
  slot3?: ReactNode; // child slot beneath the filter list, above the bottom buttons. inside the scrollable part
  rightButtonSlot?: ReactNode; // child slot at the right for the clear filter button
  underButtonSlot?: ReactNode; // child slot at the underneath the clear button
}

const FilterListComponent: FC<IFilterListProps> = ({
  groupedFilterDefinitions,
  activeFilters,
  variablesHasChanged,
  setDefaultFilters,
  filtersChanged,
  freeTextQuery,
  filterQuery,
  setFilterQuery,
  className,
  page,
  slot1,
  slot2,
  slot3,
  rightButtonSlot,
  underButtonSlot,
}) => {
  const [
    filteredGroupedFilterDefinitions,
    setFilteredGroupedFilterDefinitions,
  ] = useState<IReactGroupedFilterDefinition[]>(groupedFilterDefinitions);
  const [t] = useCaseInsensitiveTranslation();

  const [toggledGroups, setToggledGroups] = useState<string[]>([]);
  const [openColumn, setOpenColumn] = useState<IReactGridFilter | undefined>();

  const isMobile = useIsMobile();
  const smallScreen = window.innerHeight < 650 || isMobile;

  // open first group if there are no other groups
  useEffect(() => {
    if (filteredGroupedFilterDefinitions.length === 1) {
      setToggledGroups([filteredGroupedFilterDefinitions[0].managementGroup])
    }
  }, [filteredGroupedFilterDefinitions]);
  
  useEffect(() => {
    const lowerCaseQuery = filterQuery.toLowerCase();
    setToggledGroups(
      lowerCaseQuery
        ? groupedFilterDefinitions.map((group) => group.managementGroup)
        : []
    );

    setFilteredGroupedFilterDefinitions(
      groupedFilterDefinitions
        .map((g) => ({
          ...g,
          filters: g.filters.filter(
            (f) =>
              t(f.name).toLowerCase().includes(lowerCaseQuery) ||
              t(g.managementGroup).toLowerCase().includes(lowerCaseQuery)
          ),
        }))
        .filter((g) => g.filters.length)
    );
  }, [filterQuery]);

  function toggleColumn(columnToToggle: IReactGridFilter) {
    setOpenColumn(openColumn === columnToToggle ? undefined : columnToToggle);
  }

  function toggleGroup(groupName: string) {
    if (toggledGroups.some((group) => group === groupName))
      setToggledGroups(toggledGroups.filter((group) => group !== groupName));
    else setToggledGroups([groupName, ...toggledGroups]);
  }

  function getNumberOfActiveQueriesForFilter(
    definition: IReactGridFilter,
    activeFilters?: IFilterGroup[]
  ) {
    return activeFilters
      ?.filter(
        (c) => c.field.toLowerCase() == definition.property.toLowerCase()
      )
      .reduce((cur, next) => cur + next.filters.length, 0);
  }

  function getNumberOfActiveQueriesForGroup(
    group: IReactGroupedFilterDefinition,
    activeFilters?: IFilterGroup[]
  ) {
    let count = 0;
    group.filters.map(
      (filter) =>
        (count += getNumberOfActiveQueriesForFilter(filter, activeFilters) ?? 0)
    );

    return count;
  }

  function clearFilter(property: string) {
    filtersChanged(activeFilters?.filter((f) => f.field !== property) ?? []);
  }

  const filterChanged = (group: IFilterGroup) => {
    const filters = activeFilters?.filter((f) => f.field !== group.field) ?? [];

    if (group.filters.length) {
      filters.push(group);
    }

    filtersChanged(filters);
  };

  const renderOpenColumnFilter = (filter: IReactGridFilter) => {
    const Filter = filter.component;

    const resetFilter = () =>
      filtersChanged(
        activeFilters?.filter((f) => f.field !== filter.property) ?? []
      );

    const compProps = smallScreen
      ? {
          ...filter.componentProps,
          ...{
            dynamicHeight: true, // adds this for the ref filter on small screens
            page: page,
          },
        }
      : filter.componentProps;

    return (
      Filter && (
        <Filter
          componentProps={compProps}
          activeFilters={activeFilters}
          freeTextQuery={freeTextQuery}
          resetCalled={resetFilter}
          filterChanged={filterChanged}
          property={filter.property}
        />
      )
    );
  };
  return (
    <div className={classNames(
      'filterlist-wrapper',
      'flex',
      'column',
      'jsb',
      className
    )}>
      {smallScreen && openColumn ? (
        <div className="flex column flex_1">
          <div className="flex jsb mar_m aicenter">
            <a className="clickable" onClick={() => setOpenColumn(undefined)}>
              {t("ui_common_back")}
            </a>
            <Button
              variant="text"
              onClick={() => clearFilter(openColumn.property)}
            >
              {t("ui_sitelist_filter_reset")}
            </Button>
          </div>
          <div className="mar_rm mar_lm flex_1 flex">
            {renderOpenColumnFilter(openColumn)}
          </div>
        </div>
      ) : (
        <>
          {slot1}
          <div className="pad_m">
            <TextField
              value={filterQuery}
              onChange={(e) => setFilterQuery(e.target.value)}
              icon={<Icon name="fa-search" />}
              type="text"
              placeholder={t("ui_sitelist_filters_searchfilter")}
            />
          </div>
          {slot2}
          <div className="flex_1 overflow-auto overflow-x-hidden relative">
            {filteredGroupedFilterDefinitions.map((group, i) => {
              const groupFilterCount = getNumberOfActiveQueriesForGroup(
                group,
                activeFilters
              );
              return (
                <div key={group.managementGroup + i}>
                  <div
                    className="filterlist clickable"
                    onClick={() => toggleGroup(group.managementGroup)}
                  >
                    <div className="filterlist-content">
                      <span className="filterlist-name">
                        {t(group.managementGroup)}
                      </span>

                      {!!groupFilterCount && (
                        <span className="filterlist-activefiltercount">
                          {groupFilterCount}
                        </span>
                      )}
                      <Icon
                        name={
                          toggledGroups.indexOf(group.managementGroup) > -1
                            ? "fa-chevron-up"
                            : "fa-chevron-down"
                        }
                        className="mar_m filterlist-opengroup-icon"
                      />
                    </div>
                    <hr className="filterlist-bottomline" />
                  </div>
                  {toggledGroups.indexOf(group.managementGroup) > -1 &&
                    group.filters.map((definition, i) => {
                      const filtercount = getNumberOfActiveQueriesForFilter(
                        definition,
                        activeFilters
                      );
                      const FilterComponent = definition.component;
                      const resetFilter = () =>
                        filtersChanged(
                          activeFilters?.filter(
                            (f) => f.field !== definition.property
                          ) ?? []
                        );

                      return (
                        <div
                          key={definition.name + i}
                          className="filterlist clickable"
                        >
                          <div
                            className="filterlist-content column-row"
                            onClick={() => toggleColumn(definition)}
                          >
                            <span className="filterlist-name column-name">
                              {t(definition.name)}
                            </span>
                            {!!filtercount && (
                              <span className="filterlist-activefiltercount">
                                {filtercount}
                              </span>
                            )}
                            <Icon
                              name="fa-plus"
                              className={classNames(
                                'filterlist-editfilter-icon',
                                'mar_m',
                                (openColumn === definition) && "active"
                              )}
                            />
                          </div>
                          <hr className="filterlist-bottomline" />
                          {!smallScreen && (
                            <div className="overflow-auto">
                              {openColumn === definition && (
                                <div className="fill-height flex column">
                                  <div className="flex jcflexend">
                                    <Button
                                      variant="text"
                                      onClick={resetFilter}
                                    >
                                      {t("ui_sitelist_filter_reset")}
                                    </Button>
                                  </div>
                                  <div className="fill-height fill-width flex pad_m">
                                    {FilterComponent && (
                                      <FilterComponent
                                        componentProps={
                                          definition.componentProps
                                        }
                                        freeTextQuery={freeTextQuery}
                                        activeFilters={activeFilters}
                                        resetCalled={resetFilter}
                                        filterChanged={filterChanged}
                                        property={definition.property}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              );
            })}
            {slot3}
          </div>
          {(variablesHasChanged || rightButtonSlot || underButtonSlot) && (
            <div className="flex_0_0_auto flex column jcflexend mar_tm mar_lm mar_rm gap_s">
              <div className="flex jsb gap_s">
                {variablesHasChanged && (
                  <Button
                    variant={"contained"}
                    color={"error"}
                    className="flex_1"
                    onClick={setDefaultFilters}
                  >
                    {t("ui_sitelist_filter_clearall")}
                  </Button>
                )}
                {rightButtonSlot}
              </div>
              {underButtonSlot}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const FilterList = memo(FilterListComponent);
