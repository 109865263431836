import { useQuery } from '$lib/hooks/fetch-utillities';
import { BuidsQueryReactDocument, BuidsQueryReactQuery } from '$typings/graphql-codegen';
import React, { FC, ReactElement } from 'react';
import Dropdown from '../dropdown/dropdown.react';

type BuidDropdownItem = BuidsQueryReactQuery['buids'][0]

interface IBuidDropdownProps {
  selected?: BuidDropdownItem | string;
  topElement?: string | boolean | 'IF_QUERY';
  replaceTopElement?: (query: string) => ReactElement
  itemClicked?: (params: { buidId?: string | undefined; name?: string }) => void;
  error?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  includeResponsibleBuid?: boolean;
  displayPath?: string
  valuePath?: string
}

const BuidDropdown: FC<IBuidDropdownProps> = ({
  selected, 
  topElement, 
  replaceTopElement, 
  itemClicked, 
  error, 
  className, 
  placeholder,
  disabled,
  includeResponsibleBuid,
  displayPath, 
  valuePath }) => {

  const { data, loading } = useQuery(BuidsQueryReactDocument, { includeResponsibleBuid: includeResponsibleBuid ?? false });

  return (
    <Dropdown<BuidDropdownItem>
      className={className}
      items={data?.buids}
      displayPath={displayPath ?? 'name'}
      valuePath={valuePath ?? 'buidId'}
      selected={selected}
      loading={loading}
      itemClicked={params => itemClicked && itemClicked({ buidId: params?.value?.toString(), name: params?.text.toString() })}
      topElement={topElement}
      placeholder={placeholder}
      replaceTopElement={replaceTopElement}
      error={error}
      disabled={disabled}
    />
  );
};

export default BuidDropdown;
