import { IController } from '../../interfaces';

const ida2Api = [
  'ida211',
  'ida-211',
  'ida11',
  'ida-11',
  'idaxcloud',
  'idax-cloud',
  'ida-cloud',
  'idacloud'
];

const pingeableStartWiths = [ 
  '10.1.',
  '10.8.',
  '10.252.',
  '10.253.'
];

export const isIda2x = (controller: IController) => controllerTypeIsIda2x(controller.controllerType)

export const controllerTypeIsIda2x = (controllerType: string) =>
    ida2Api.includes(controllerType.toLowerCase());

export const controllerCanHaveSlaves = (controller: IController) =>
  controller.controllerType === 'IDA-10';

export const controllerHasSlaves = (controller: IController) =>
  controllerCanHaveSlaves(controller) && controller.numberOfSlaves > 0;

export const controllerTypeIsIda = (controllerType: string) =>
  controllerType.toLowerCase().startsWith('ida'); 

export const isIda = (controller: IController) =>
  controllerTypeIsIda(controller.controllerType)

export const isFXA320 = (controller: IController) =>
  controllerTypeIsFXA320(controller.controllerType);

export const controllerTypeIsFXA320 = (controllerType: string) =>
  controllerType === 'FXA320';

export const controllerSupportsRemoteCommand = (controller: IController) =>
  isIda(controller) || isFXA320(controller);

export const controllerSupportsRemoteControll = (controller: IController) =>
  isIda(controller) || isFXA320(controller);

export const controllerTypeSupportsPing = (controllerType: string) =>
    controllerTypeIsIda(controllerType) || controllerTypeIsFXA320(controllerType);

export const controllerSupportsPing = (controller: IController) =>
  isIda(controller) || isFXA320(controller);

export const controllerSupportsParameters = (controller: IController) =>
  isIda(controller);

export const controllerSupportsProfiles = (controller: IController) =>
  isIda(controller) || isIda2x(controller);

export const isPingeableIPAddress = (ipAddress: string) =>
  pingeableStartWiths.some(searchString => ipAddress.startsWith(searchString))
