import Icon from '$components/icons/icon/icon.react';
import React, { FC, MouseEventHandler } from 'react';
import './regular-button/regular-button.css';
import classNames from "classnames";

interface IButtonProps {
  variant?: 'primary' | 'secondary' | 'danger' | 'tertiary';
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  processing?: boolean;
  disabled?: boolean;
  icon?: string;
  iconPlacement?: 'right' | 'left';
  size?: 'medium' | 'small';
  type?: 'button' | 'submit';
}

const Button: FC<IButtonProps> = ({ children, onClick, variant = 'primary', className, processing, disabled, icon, iconPlacement = 'left', size, type = 'button' }) => {
  const wrapperClassName = classNames('button-component ', className)
  const buttonClassName = classNames('component-regular-button', `component-regular-button-${size}`, variant, !disabled && 'clickable')
  return (
    <div className={wrapperClassName}>
      <button className={buttonClassName} disabled={disabled || processing} onClick={onClick} type={type}>
        {processing && <Icon name="fa-spinner fa-pulse" className="text" />}
        {icon && iconPlacement === "left" && <Icon name={icon} className="button-icon" />}
        {children}
        {icon && iconPlacement === "right" && <Icon name={icon} className="button-icon-right" />}
      </button>
    </div>
  )
}

export default Button;
