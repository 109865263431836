import { useState, useEffect } from 'react'
import { LocalSettings } from '$services/localSettingsService'

export function usePersistedState(key: string, defaultState: string): [ string, React.Dispatch<React.SetStateAction<string>> ] {
    const [ state, setState ] = useState(() => LocalSettings.getSetting(key, defaultState))

    useEffect(() => {
        LocalSettings.setSetting(key, state)
    }, [state])

    useEffect(() => LocalSettings.subscribeSetting(key, value => {
        if(value) setState(value)
    }), [])

    return [state, setState]
}

export function usePersistedParsedState<T>(key: string, defaultState: T): [ T, React.Dispatch<React.SetStateAction<T>> ] {
    const [ state, setState ] = useState(() => LocalSettings.getSettingParsed(key, defaultState))

    useEffect(() => {
        const stateToPersist = JSON.stringify(state)
        const fromCache = LocalSettings.getSetting(key, '')
        if(stateToPersist !== fromCache)
            LocalSettings.setSetting(key, stateToPersist)
    }, [state])

    useEffect(() => LocalSettings.subscribeSetting(key, value => {
        if(value) setState(JSON.parse(value))
    }), [])

    return [state, setState]
}

export function usePersistedBooleanState(key: string, defaultState: boolean): [ boolean, React.Dispatch<React.SetStateAction<boolean>>  ] {
    const [ state, setState ] = usePersistedParsedState<boolean>(key, defaultState)
    return [ state, setState ]
}