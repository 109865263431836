import React, { memo, FC } from 'react';
import { CardFlexColumn } from '$components/cards/mui-card';
import { CardHeader } from '@mui/material';
import { ScrollableMuiCardContent } from '$components/cards/mui-card';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import './mui-card.css';
import { Variant } from '@mui/material/styles/createTypography';

export interface ICardWithHeaderProps {
  headerText?: string;
  headerActions?: React.ReactNode;
  children?: React.ReactNode;
  footerContent?: React.ReactNode;
  headerTextVariant?: Variant;
  allowHeaderTextWrap?: boolean;
  className?: string;
}

const CardWithHeaderComponent: FC<ICardWithHeaderProps> = ({
  headerText,
  headerActions,
  footerContent,
  className,
  headerTextVariant = 'h6',
  allowHeaderTextWrap,
  children,
}) => {
  return (
    <CardFlexColumn className={className}>
      <CardHeader
        sx={{
          '& .MuiCardHeader-content': {
            overflow: 'hidden',
          },
        }}
        title={headerText}
        titleTypographyProps={{
          noWrap: !allowHeaderTextWrap,
          variant: headerTextVariant,
          title: headerText,
        }}
        action={headerActions}
      />
      <ScrollableMuiCardContent>
        {children}
        {footerContent && (
          <CardFooter topBorder className="flex jsb">
            {footerContent}
          </CardFooter>
        )}
      </ScrollableMuiCardContent>
    </CardFlexColumn>
  );
};

export const CardWithHeader = memo(CardWithHeaderComponent);
