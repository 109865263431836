import React, { FC, memo } from 'react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { pureFormatDate } from '$lib/dateHelpers';
import { isSomething } from '$lib/helpers';
import { ControllerParameterHistory, Maybe } from '$typings/graphql-codegen';
import './controller-parameter-history-row.css';

const translateEventMessage = (
  message: Maybe<string> | undefined,
  t: (key: string, options: Object) => string
): string => {
  if (
    !!message &&
    (message.startsWith('Value') || message.startsWith('Security'))
  ) {
    // message created in YT3.API, so format "(Value|Security) from X to Y":
    const terms = message.split(' ');
    const before = terms.length > 1 ? terms[1] : 'unknown';
    const after = terms.length > 3 ? terms[3] : 'unknown';

    if (terms.length > 0 && terms[0] == 'Value') {
      return t(
        'ui_sitedetails_controllers_controllerdetails_controllerparameters_valuechanged',
        { before, after }
      );
    } else if (terms.length > 0 && terms[0] == 'Security') {
      return t(
        'ui_sitedetails_controllers_controllerdetails_controllerparameters_securitylevelchanged',
        {
          before,
          after,
        }
      );
    }
  } else if (!!message) {
    return message; // message from controller, so uncertain format
  }
  return 'Unknown'; // No content
};

interface IControllerParameterHistoryRowProps {
  item: ControllerParameterHistory;
}

const ControllerParameterHistoryRowComponent: FC<
  IControllerParameterHistoryRowProps
> = ({ item }) => {
  const [t] = useCaseInsensitiveTranslation();

  return (
    <div className="controller-parameter-history-row">
      <div className="header-line diffuse">
        {pureFormatDate(item.timeOfChange, true, '.')}
      </div>
      <div className="non-header-line">
        {translateEventMessage(item.event, t)}
      </div>
      <div className="non-header-line diffuse">
        {isSomething(item.userId)
          ? item.fullname
          : `${item.username} (${t('ui_common_external')})`}
      </div>
    </div>
  );
};

export const ControllerParameterHistoryRow = memo(
  ControllerParameterHistoryRowComponent
);
