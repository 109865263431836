import React, { memo, FC } from 'react';
import MuiTextField, {
  ExtendedTextFieldProps,
} from '$components/textbox/mui-textfield.react';
import { getNewStringIfMatched } from '$lib/inputHelpers';

interface MsisdnInputSpecificProps {
  setValue?: (value: string) => unknown;
  value?: string;
}

export const getMsisdnFormValidation = (t: (key: string) => string) => ({
  pattern: {
    value: /^\+?[0-9-]+$/,
    message: t('ui_common_validationerrors_msisdn'),
  },
});

type IMsisdnInputProps = ExtendedTextFieldProps & MsisdnInputSpecificProps;

const MsisdnInputComponent: FC<IMsisdnInputProps> = ({
  onChange,
  value,
  setValue,
  ...props
}) => {
  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    let newValue = getNewStringIfMatched(
      (value ?? '') as string,
      e.target.value,
      /^\+?[0-9-]*$/
    );

    e.target.value = newValue;
    setValue?.(newValue);
    onChange?.(e);
  };

  return <MuiTextField {...props} value={value} onChange={changeHandler} />;
};

export const MsisdnInput = memo(MsisdnInputComponent);
