import { channelPropertiesIsInErrorState, hasNoConsumption, lsdIsOffline } from '$lib/';
import { isNone } from '$lib/helpers';
import React, { FC, useCallback } from 'react';
import './tank-bar.css';
import classNames from "classnames";

interface ITankBarProps {
  lastSample?: number | null;
  maximum?: number | null;
  minimum?: number | null;
  percentage?: number | null;
  capacity?: number | null;
  lastSampleTime?: string | null;
  reachMinimum?: string | null;
}

const TankBar: FC<ITankBarProps> = ({ lastSample, maximum, minimum, percentage, capacity, lastSampleTime, reachMinimum }) => {
  const faulty = useCallback(() => {
    return (
      isNone(percentage) ||
      (percentage && percentage < 0) ||
      (percentage && percentage > 100) ||
      (minimum && maximum && lastSample && capacity && channelPropertiesIsInErrorState(minimum, maximum, lastSample, capacity))
    );
  }, [percentage, minimum, maximum, lastSample, capacity]);

  const barWrapperClass = useCallback(() => {
    if (faulty()) return 'faulty';
    const noConsumption = hasNoConsumption(reachMinimum);
    const isOffline = lsdIsOffline(lastSampleTime);
    if (noConsumption || isOffline) return 'noconsumption';

    return 'freshdata';
  }, [faulty, reachMinimum, lastSampleTime]);

  const barFillClass = useCallback(() => {
    if (faulty()) return 'faulty';
    const noConsumption = hasNoConsumption(reachMinimum);
    const isOffline = lsdIsOffline(lastSampleTime);
    if (noConsumption || isOffline) return 'noconsumption';

    return 'freshdata';
  }, [faulty, reachMinimum, lastSampleTime]);

  return (
    <div className='tank-bar-component'>
      <div className="flex">
        <div className="flex_1 pad_rs number-cell percentage">{percentage}%</div>
        <div className="flex_3 flex jccenter aicenter relative">
          <div className={classNames('bar-wrapper', barWrapperClass())}>
            <div
              className={classNames('bar-fill', barFillClass())}
              style={{ width: `${faulty() ? '100' : percentage}%` }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TankBar;
