import React, { useEffect, useState, memo, FC, useCallback } from 'react';
import { IconButton } from '@mui/material';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import {
  ControllerLastBackupDocument,
  RequestControllerBackupDocument,
} from '$typings/graphql-codegen';
import { pureFormatDate, toISOString } from '$lib/dateHelpers';
import Icon from '$components/icons/icon/icon.react';
import { NotificationService } from '$services/notificationService';
import { ControllerService } from '$services/controllerService';
import { saveBlob } from '$lib/fileHelpers';

interface IControllerLastBackupProps {
  controllerId: number;
  controllerSerial: string | undefined;
  notificationService: NotificationService;
  controllerService: ControllerService;
}

const ControllerLastBackupComponent: FC<IControllerLastBackupProps> = ({
  controllerId,
  controllerSerial,
  notificationService,
  controllerService
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const { data, loading } = useQuery(ControllerLastBackupDocument, {
    controllerId,
  });

  const [processing, setProcessing] = useState(false);
  const [isAvailableForBackup, setIsAvailableForBackup] = useState(false);
  const [successfullyRequestedBackup, setSuccessfullyRequestedBackup] = useState(false);

  const [backupEquipmentId, setBackupEquipmentId] = useState<number | undefined>(undefined);
  const [backupAttachmentId, setBackupAttachmentId] = useState<number | undefined>(undefined);
  const [siteId, setSiteId] = useState<number | undefined>(undefined);
  const [lastBackupDate, setLastBackupDate] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (!data) return;

    setIsAvailableForBackup(data.controllerLastBackup.isAvailableForBackup ?? false);
    setBackupEquipmentId(data.controllerLastBackup.backupEquipmentId ?? undefined);
    setBackupAttachmentId(data.controllerLastBackup.attachmentId ?? undefined);
    setSiteId(data.controllerLastBackup.siteId ?? undefined);
    
    setLastBackupDate(
      pureFormatDate(
        data.controllerLastBackup.lastBackupDate ?? undefined,
        true,
        '.'
      )
    );
  }, [data]);

  const showErrorNotification = useCallback(() => {
    notificationService.notify({
      type: 'CUSTOM',
      level: 'error',
      text: t('UI_SiteDetails_LastBackup_BackupError'),
      timestamp: toISOString(new Date(), true),
      acknowledged: false,
    });
  }, [notificationService, t]);

  const requestControllerBackup = async () => {
    if (controllerSerial === undefined) {
      showErrorNotification();
      return;
    }

    setProcessing(true);

    //Normal backup to site equipment DB
    if (siteId) {
      mutate(RequestControllerBackupDocument, { serial: controllerSerial })
        .then(() => {
          setSuccessfullyRequestedBackup(true);
          setTimeout(() => setSuccessfullyRequestedBackup(false), 30000); //Re-enable bakcup button after 30 seconds
          notificationService.notify({
            type: 'CUSTOM',
            level: 'info',
            text: t('UI_SiteDetails_LastBackup_BackupSuccess'),
            timestamp: toISOString(new Date(), true),
            acknowledged: false,
          });
        })
        .catch((errors) => {
          setSuccessfullyRequestedBackup(false);
          if (errors.graphQLErrors) {
            showErrorNotification();
          }
        })
        .finally(() => setProcessing(false));
    } else {
      //Stream backup straight back to browser
      try {
        const scriptConfig = await controllerService.downloadControllerBackup(controllerSerial);      
        if (scriptConfig) {
          saveBlob(scriptConfig, controllerSerial + ".tar.bz2"); 
        } else {
          showErrorNotification();          
        }
      } catch (error) {
        showErrorNotification();          
      }
      finally {
        setProcessing(false);
      }
    }
  };

  const backupUrl = `/sitedetails/${siteId}/assets/view/${backupEquipmentId}`;
  const backupViewerUrl = `/controllerbackupviewer/${backupAttachmentId}`; 
  
  return (
    <>
      <div className="flex jsb aicenter">
        {!backupEquipmentId ? (
          successfullyRequestedBackup ? (
            <p>
              {t('UI_SiteDetails_LastBackup_Backup_Requested_Successfully')}
            </p>
          ) : (
            <p>{
              siteId ? t('UI_SiteDetails_LastBackup_NoBackup') : t('UI_SiteDetails_LastBackup_Unattached')
              }</p>
          )
        ) : (
          <>
            <a href={backupUrl}>{lastBackupDate}</a>
            { data?.controllerLastBackup?.hasBackupViewer && (
              <a href={backupViewerUrl}>
                <IconButton
                size="small"
                title="View">
                  <Icon name="fa-search" className="text" />
                </IconButton>
              </a>
            )}
          </>
        )}
        <IconButton
          size="small"
          onClick={requestControllerBackup}
          title={t(
            isAvailableForBackup
              ? 'UI_SiteDetails_LastBackup_NewBackup'
              : 'UI_SiteDetails_LastBackup_Offline'
          )}
          disabled={
            successfullyRequestedBackup ||
            processing ||
            !isAvailableForBackup ||
            loading
          }
        >
          {successfullyRequestedBackup ? (
            <Icon name="fa-check" className="text" />
          ) : processing ? (
            <Icon name="fa-spinner fa-pulse" className="text" />
          ) : (
            <Icon name={'fa-refresh'} />
          )}
        </IconButton>
      </div>
      {!isAvailableForBackup && (
        <p>{t('UI_SiteDetails_LastBackup_Offline')}</p>
      )}
    </>
  );
};

export const ControllerLastBackup = memo(ControllerLastBackupComponent);
