import React, { memo } from 'react';
import { CardContent } from '@mui/material';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import { FormViewItem } from './form-view-item';
import { ICardFormEditorItem } from '../../card-form-editor';
import { DeepPartial, FieldValues } from 'react-hook-form';

export interface IViewFormProps<T extends FieldValues> {
  items: ICardFormEditorItem<T>[];
  data: DeepPartial<T>;
}

const ViewFormComponent = <T extends FieldValues,>({ items, data }: IViewFormProps<T>) => {
  return (
    <CardContent sx={{ overflow: 'auto' }}>
      <SimpleTable>
        {items.map((item, index) => (
          <FormViewItem
            item={item}
            data={data}
            key={`${index}_${item.displayText}`}
          />
        ))}
      </SimpleTable>
    </CardContent>
  );
};

export const ViewForm = memo(ViewFormComponent) as typeof ViewFormComponent;
