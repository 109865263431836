import React, { memo, FC, useMemo } from 'react';
import Map from '$components/map/map.react';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { ControllerDetailsMapQueryDocument, MarkerIconEnum } from '$typings/graphql-codegen';
import { CardFlexColumn } from '$components/cards/mui-card';
import { IMapMarker } from '$interfaces/application';

interface IControllerMapProps {
    controllerId: number;
}

const ControllerMapComponent: FC<IControllerMapProps> = ({
    controllerId,
}) => {
    const { data } = useQuery(ControllerDetailsMapQueryDocument, { controllerId }, undefined, 1);

    const markers = useMemo(() => {
        if (!data) return [];
        return [
            {
                lat: data.controller?.lat ?? 0,
                lng: data.controller?.lng ?? 0,
                accuracy: data.controller?.accuracy ?? 0,
                controllerId,
                markerIconEnum: MarkerIconEnum.ControllerOnline,
                title: data.controller?.alias ?? 'Controller',
                markerType: 'controller',
                markerSize: 40,
            } as IMapMarker
        ];
    }, [data])

    return (
        <CardFlexColumn>
            <Map
                name='controller-map'
                className="fill-height"
                autoBounds
                showAccuracy
                markers={markers}
            />
        </CardFlexColumn>
    )
}

export const ControllerMap = memo(ControllerMapComponent);
