import './controllerdetails.css';
import React, { useEffect, useState, FC, memo } from 'react';
import { Button } from '@mui/material';
import {
  controllerTypeIsFXA320,
  controllerTypeIsIda,
  controllerTypeIsIda2x,
} from '$lib/application/controllerHelpers';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { ControllerDetailsQueryDocument } from '$typings/graphql-codegen';
import { ControllerRemote } from './modules/controller-remote';
import ErrorText from '$components/texts/error-text/error-text.react';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import { ControllerAccess } from './modules/controller-access';
import { ControllerOutputCalibrations }from './modules/controller-output-calibrations';
import { ControllerParameters } from './modules/controller-parameters';
import { ControllerHistory } from './modules/controller-history';
import { ControllerProfiles } from './modules/controller-profiles';
import { ControllerInfo } from './modules/controller-info';
import { ControllerReplace } from './modules/controller-replace';
import { ControllerDeleteModal } from './modules/modals/controller-delete-modal';
import { ControllerDetachModal } from './modules/modals/controller-detach-modal';
import { getUserFeatures } from '../../../../config/sessionService';
import { ControllerActionsMenu } from './modules/controller-actions-menu';
import {
  ControllerTab,
  controllerTabUrlReplaceRegex,
  controllerTabUrlMatchRegex,
} from './types/controller-tab';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { NotificationService } from '$services/notificationService';
import { Router } from 'aurelia-router';
import { routeNames } from '../../../../config';
import { ControllerService } from '$services/controllerService';
import { useIsMobile } from '$lib/hooks/isMobile';
import { CardPage } from '$pages/common/card-page';
import { NavigateFunction } from 'react-router-dom';
import { ControllerArchiveModal } from './modules/modals/controller-archive-modal';
import { ControllerMap } from './modules/controller-map';

export interface IControllerDetailsProps {
  controllerId?: number;
  showMenubar: boolean;
  showAccessCard?: boolean;
  notificationService: NotificationService;
  aureliaRouter: Router;
  aureliaControllerService: ControllerService; // workaround until SiteDetails is written in React
  closeControllerdetails?: () => unknown;
  reactRouterNavigate?: NavigateFunction;
}

const ControllerDetails: FC<IControllerDetailsProps> = ({
  controllerId,
  showMenubar,
  showAccessCard = true,
  notificationService,
  aureliaRouter,
  aureliaControllerService,
  closeControllerdetails,
  reactRouterNavigate
}) => {
  const [deleteControllerOpen, setDeleteControllerOpen] = useState(false);
  const [archiveControllerOpen, setArchiveControllerOpen] = useState(false);
  const [renderArchiveModal, setRenderArchiveModal] = useState(false);
  const [renderDeleteModal, setRenderDeleteModal] = useState(false);
  const [detachControllerOpen, setDetachControllerOpen] = useState(false);
  const [renderDetachModal, setRenderDetachModal] = useState(false);
  const [controllerRemoteCardSize, setControllerRemoteCardSize] = useState<
    1 | 2
  >(1);
  const [selectedMobileComponent, _setSelectedMobileComponent] =
    useState<ControllerTab>('info');
  const [editSerial, setEditSerial] = useState(false);
  const [editingControllerDetails, setEditingControllerDetails] =
    useState(false);
  const [pendingBuidId, setPendingBuidId] = useState<number | undefined>();
  const [t] = useCaseInsensitiveTranslation();
  // const navigate = useNavigate();

  const isMobile = useIsMobile();

  const setSelectedMobileComponent = (card: ControllerTab) => {
    _setSelectedMobileComponent(card);
    const washed = window.location.href.replace(
      controllerTabUrlReplaceRegex,
      ''
    );
    window.history.replaceState({}, '', washed + '/' + card);
  };

  useEffect(() => {
    if (deleteControllerOpen && !renderDeleteModal) setRenderDeleteModal(true);
  }, [deleteControllerOpen, renderDeleteModal]);

  useEffect(() => {
    if (detachControllerOpen && !renderDetachModal) setRenderDetachModal(true);
  }, [detachControllerOpen, renderDetachModal]);

  useEffect(() => {
    if (archiveControllerOpen && !renderArchiveModal) setRenderArchiveModal(true);
  }, [archiveControllerOpen, renderArchiveModal]);

  useEffect(
    () => {
      const matches = window.location.href.match(controllerTabUrlMatchRegex);
      if (matches && matches.length >= 2 && matches[1]) {
        const card = matches[1];
        setSelectedMobileComponent(card as ControllerTab);
      }
    },
    [] /* runs first time component renders */
  );

  const onError = (_e?: string, code?: string) => {
    if (code === "404") { 
      aureliaRouter.navigate("/404?type=controller")
    }
  } 

  useEffect(() => {
    if (!editSerial) return;
    if (selectedMobileComponent !== 'info') setEditSerial(false);
  }, [selectedMobileComponent, editSerial]);

  const { data, loading, revalidate } = useQuery(
    ControllerDetailsQueryDocument,
    {
      controllerId: controllerId,
    }, undefined, undefined, onError
  );  

  const features = getUserFeatures();

  const toggleExpandControllerRemoteCard = () => {
    const isExpanded = controllerRemoteCardSize === 2;
    setControllerRemoteCardSize(isExpanded ? 1 : 2);
    return !isExpanded;
  };

  const createAsNewSite = () => {
    if (reactRouterNavigate) {
      reactRouterNavigate("/controllermanager/create/" + controllerId);
    } else {
      aureliaRouter.navigateToRoute(routeNames.controllermanagerCreateSite, {
        controllerId: controllerId,
      });
    }
  };
  const attachToExistingSite = () => {
    if (reactRouterNavigate) {
      reactRouterNavigate("/controllermanager/attach/" + controllerId);
    } else {
      aureliaRouter.navigateToRoute(routeNames.controllermanagerAttachSite, {
        controllerId: controllerId,
      });
    }
  };

  const editChannels = () => {
    aureliaRouter.navigateToRoute(routeNames.controllerManagerChannels, {
      controllerId: controllerId,
    });
  };

  const cancelControllerDetails = () => {
    closeControllerdetails?.();
  };

  const editProfile = (profileId: number) => {
    aureliaRouter.navigateToRoute(routeNames.sitedetailsProfileDetails, {
      profileId: profileId,
      controllerId,
    });
  };

  if (controllerId && data && data.controller) {
    const controller = data.controller;    
    const isIda2x = controllerTypeIsIda2x(controller.controllerType ?? '');
    const isFXA320 = controllerTypeIsFXA320(controller.controllerType ?? '');
    const isIda = controllerTypeIsIda(controller.controllerType ?? '');

    const showControllerCommands = !!(
      features.controllerCommands.read &&
      (isIda || isFXA320) &&
      controller.simDetail?.ipAddress
    );

    const showControllerOutputCalibrations = !!(
      isIda2x &&
      features.controllerOutputCalibrations.read &&
      controller.simDetail?.ipAddress
    );

    const showControllerParameters = !!(
      isIda &&
      features.controllerParameters.read);

    const showControllerProfiles = !!(
      (isIda || isIda2x) &&
      features.controllerProfiles.read);

    const hasIpAddress = controller.simDetail?.ipAddress

    const showControllerHistory = features.importManagement.read || features.controllerLog.read;
    const disabledDetach =
      editingControllerDetails ||
      controller.siteId === null ||
      controller.siteId === undefined ||
      controller.siteId <= 0;

    const startEditSerial = () => {
      setEditSerial(true);
      if (isMobile) setSelectedMobileComponent('info');
    };

    return (
      <>
        <CardPage<ControllerTab>
          showMenuBar={showMenubar}
          selectedMobileComponent={selectedMobileComponent}
          setSelectedMobileComponent={setSelectedMobileComponent}
          components={[
            {
              type: 'actions',
              displayText: t('ui_controllerdetails_mobile_tab_actions'),
              iconName: 'fa-microchip',
              component: showMenubar && (
                <ControllerActionsMenu
                  deleteController={() => setDeleteControllerOpen(true)}
                  detachController={() => setDetachControllerOpen(true)}
                  archiveController={() => setArchiveControllerOpen(true)}
                  archiveControllerDisabled={controller.isArchived}
                  detachControllerDisabled={disabledDetach}
                  editSerial={startEditSerial}
                  editSerialDisabled={editSerial || editingControllerDetails}
                  attachToSite={attachToExistingSite}
                  createAsSite={createAsNewSite}
                  editChannels={editChannels}
                  cancelControllerDetails={cancelControllerDetails}
                />
              ),
            },
            {
              type: 'info',
              displayText: t('ui_controllerdetails_mobile_tab_information'),
              iconName: 'fa-info',
              component: editSerial ? (
                <ControllerReplace
                notificationService={notificationService}

                  currentSerial={{controllerName: controller.alias || "",
                    isNewSerial: false,
                    controllerType: controller.controllerType,
                    serial: controller.serial,
                    simIcc: controller.simDetail?.icc || "",
                    simIp: controller.simDetail?.ipAddress || ""}}
                  controllerId={controllerId}
                  closeControllerReplace={() => setEditSerial(false)}
                />
              ) : (
                <ControllerInfo
                  notificationService={notificationService}
                  controllerService={aureliaControllerService}
                  controllerId={controllerId}
                  controller={controller}
                  editingControllerDetails={editingControllerDetails}
                  setEditingControllerDetails={setEditingControllerDetails}
                  setPendingBuidId={setPendingBuidId}
                  isIda2x={isIda2x}
                  isIda={isIda}
                  displayRemoteCardOnMobile={() =>
                    setSelectedMobileComponent('commands')
                  }
                />
              ),
            },
            {
              type: 'map',
              displayText: t('ui_controllerdetails_mobile_tab_map'),
              iconName: 'fa-map-marker',
              component: data?.controller?.lat && data?.controller?.lng &&  (<ControllerMap controllerId={controllerId} />)
            },
            {
              type: 'access',
              displayText: t('ui_controllerdetails_mobile_tab_access'),
              iconName: 'fa-users',
              component: showAccessCard && controller.siteId && (
                <ControllerAccess
                  controllerId={controllerId}
                  pendingBuidId={pendingBuidId}
                />
              ),
            },
            {
              type: 'commands',
              spanColumns: controllerRemoteCardSize,
              displayText: t('ui_controllerdetails_mobile_tab_remote'),
              iconName: 'fa-terminal',
              component: showControllerCommands && (
                <ControllerRemote
                  controllerId={controllerId}
                  controller={controller}
                  toggleExpand={toggleExpandControllerRemoteCard}
                />
              ),
            },
            {
              type: 'outputcalibrations',
              displayText: t('ui_controllerdetails_mobile_tab_output_calibrations'),
              iconName: 'fa-list',
              component: showControllerOutputCalibrations && (
                <ControllerOutputCalibrations controllerId={controllerId} />
              ),
            },
            {
              type: 'parameters',
              displayText: t('ui_controllerdetails_mobile_tab_parameters'),
              iconName: 'fa-list',
              component: showControllerParameters && (
                <ControllerParameters controllerId={controllerId} readonly={!hasIpAddress}/>
              ),
            },
            {
              type: 'profiles',
              displayText: t('ui_controllerdetails_mobile_tab_profiles'),
              iconName: 'fa-area-chart',
              component: showControllerProfiles && (
                <ControllerProfiles
                  controllerId={controllerId}
                  editProfile={editProfile}
                  readonly={!hasIpAddress}
                />
              ),
            },
            {
              type: 'history',
              displayText: t('ui_controllerdetails_mobile_tab_history'),
              iconName: 'fa-history',
              component: showControllerHistory && (
                <ControllerHistory controllerId={controllerId} />
              ),
            },
          ]}
        />
        
        {renderDeleteModal && features.controllerManagement.delete && (
          <ControllerDeleteModal
            open={deleteControllerOpen}
            controllerIds={[controllerId]}
            handleClose={() => setDeleteControllerOpen(false)}
            onDeleted={cancelControllerDetails}
          />
        )}
        {renderDetachModal && features.controllerManagement.write && (
          <ControllerDetachModal
            open={detachControllerOpen}
            controllerIds={[controllerId]}
            handleClose={() => setDetachControllerOpen(false)}
            aureliaControllerService={aureliaControllerService}
          />
        )}

        {renderArchiveModal && features.controllerManagement.write && (
          <ControllerArchiveModal
            open={archiveControllerOpen}
            controllerIds={[controllerId]}
            handleClose={() => setArchiveControllerOpen(false)}   
            onArchived={() => { 
                revalidate && revalidate();  
                setArchiveControllerOpen(false);
            }}
          />
        )}
      </>
    );
  }

  if (loading) {
    return <LoadingBars center />;
  }

  return (
    <div className="center-content absolute-fill flex column bg-white-80">
      <ErrorText bold>Could not fetch data</ErrorText>
      <Button variant="text" onClick={revalidate}>
        {t('ui_common_tryagain')}
      </Button>
    </div>
  );
};

export default memo(ControllerDetails);
