import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useGridData, VisibleRangeChangedFn } from '$components/grid/react-grid/gridhelper';
import { useMemo } from 'react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { downloadFileFromUrl } from '$lib/hooks/blob-utillities';
import { ReportFormatEnum } from '$typings/graphql-codegen';

export type ReportGridDataHook<TData> = {
  isLoading: boolean,
  hasData: boolean,
  isRevalidating: boolean,
  data: TData[] | undefined,
  count: number,
  error: unknown,
  visibleItemsChanged: VisibleRangeChangedFn,
  cacheKey: string
}

/**
 * # Use Report Grid Data 🧩
 * Hook wrapper that adds additional state granularity.
 * @param document GraphQL Query Document.
 * @param variables Variables for the GQL query.
 * @param dataSelector A callback that returns the internal data array.
 * @param countSelector A callback the returns the length of the data array.
 */
export function useReportGridData<TResult, TVariables, TData>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables: TVariables,
  dataSelector: (result: TResult) => TData[],
  countSelector: (result: TResult) => number
): ReportGridDataHook<TData> {
  const data = useGridData<TResult, TVariables, TData>(
    document,
    variables,
    countSelector,
    dataSelector
  )

  const isLoading: boolean = useMemo<boolean>(() => {
    const notRevalidating: boolean = !data.isRevalidating
    const hasLength: boolean = data.data?.length !== undefined
    const notLoading: boolean = (notRevalidating && hasLength)
    return !notLoading // isLoading
  }, [data, variables])

  const hasData: boolean = useMemo<boolean>(() => {
    const length: number = (!isLoading && data.data?.length) || 0;
    return length > 0;
  }, [data, variables])
  
  return useMemo(() => ({
    isLoading,
    hasData,
    isRevalidating: data.isRevalidating,
    data: data.data,
    count: data.data?.length ?? 0,
    error: data.error,
    visibleItemsChanged: data.visibleItemsChanged,
    cacheKey: data.cacheKey
  }), [data])
}

type ReportDownloadHook = {
  downloadReport: (format: ReportFormatEnum) => Promise<void>
}

/**
 * # Use Report Download 📥
 * A hook that handles
 * @param document GraphQL query document for the Excel download.
 * @param variables Variables for the GraphQL query.
 * @param urlSelector Callback that returns the corresponding download URL.
 * @param filename Filename of the downloaded report.
 * @param onDownloadStart Callback that is invoked when the download begins.
 * @param onDownloadComplete Callback that is invoked when the download completes.
 */
export function useReportDownload<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables: TVariables,
  urlSelector: (result: TResult) => string,
  filename: string,
  onDownloadStart?: () => any,
  onDownloadComplete?: () => any
): ReportDownloadHook {
  async function createReportURL(
    query: TypedDocumentNode<TResult, TVariables>,
    variables: TVariables
  ): Promise<string | null> {
    const data = await mutate(query, variables)
    return urlSelector(data) ?? null
  }
  
  function getFullFilename(baseName : string, reportFormat: ReportFormatEnum) {
    switch (reportFormat) {
      case ReportFormatEnum.Pdf:
        return baseName + ".pdf";
      case ReportFormatEnum.Csv:
        return baseName + ".csv";
      case ReportFormatEnum.Excel:
      default:
        return baseName + ".xlsx";
    }
  }
  
  async function downloadReport(reportFormat: ReportFormatEnum): Promise<void> {
    onDownloadStart && onDownloadStart()
    if (!variables) return

    const hasInput = typeof variables === 'object' && 'input' in variables;
    //const inputIsObject  = hasInput && typeof (variables.input) === 'object';
 
    if (hasInput) {
      const variablesWithFormat = {
        input: {
          ...(variables.input as object),
          reportFormat: reportFormat
        }
      }

      const url: string | null = await createReportURL(document, variablesWithFormat as TVariables)
      if (url) {
        await downloadFileFromUrl(url, getFullFilename(filename, reportFormat))
        onDownloadComplete && onDownloadComplete()
      }
    }
  }
  
  return { downloadReport }
}
