import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import FormElement from '$components/forms/form-element/form-element.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import { IGeneralFormError } from '$lib/hooks/reactHookFormHelper';
import SitegroupFiltersDropdown from '$pages/common/sitegroup-filters-dropdown/sitegroup-filters-dropdown.react';
import { SiteGroupFilterDropdownQueryReact_siteFilters } from '$typings/graphql';
import { UpdateSiteFilterDocument } from '$typings/graphql-codegen';
import React, { FC } from 'react';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFilterGroup } from 'src/interfaces';

interface IUpdatePresistedFilterFormValues extends IGeneralFormError {
  filterToUpdate: SiteGroupFilterDropdownQueryReact_siteFilters;
}

interface IUpdatePresistedFilterFormProps {
  closeForm: () => void;
  mutationVariables: {
    filtersToSave?: IFilterGroup[];
  };
}

const UpdatePersistedFilterForm: FC<IUpdatePresistedFilterFormProps> = ({ mutationVariables, closeForm }) => {
  const {
    handleSubmit,
    control,
    clearErrors,
    setError,
    formState: { isSubmitting, errors }
  } = useForm<IUpdatePresistedFilterFormValues>();
  const [t] = useTranslation();

  const onError = () => {
    setError('formError', {
      message: t('ui_common_error_save_item_failed')
    });
  };

  const onSubmit: SubmitHandler<IUpdatePresistedFilterFormValues> = async values => {
    await mutate(
      UpdateSiteFilterDocument,
      {
        input: {
          enabled: true,
          definition: JSON.stringify(mutationVariables.filtersToSave),
          filterId: ensureNumber(values.filterToUpdate.filterId),
          isPublic: values.filterToUpdate.isPublic,
          name: values.filterToUpdate.name
        }
      },
      true,
      closeForm,
      onError
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="fill-height flex column jsb">
      <CardContent className="pad_vnone pad_b">
        <FormElement label={t('ui_sitelist_filter_persist_label_update')}>
          <Controller
            name="filterToUpdate"
            control={control}
            rules={{ required: t('ui_common_validationerror_requiredfield') as string }}
            render={({ field }) => (
              <SitegroupFiltersDropdown
                topElement={t('ui_sitelist_sitegroup_nogroup') as string}
                selected={field.value}
                placeholder={t('ui_sitelist_sitegroup_select')}
                className="flex_1"
                itemClicked={item => field.onChange(item?.filter)}
                error={!!(errors.filterToUpdate as FieldError)?.message}
              />
            )}
          />
          <ErrorText>{(errors.filterToUpdate as FieldError)?.message}</ErrorText>
        </FormElement>
        {errors.formError && (
          <ErrorText className="text-align-center mar_tm">
            {errors.formError?.message}
          </ErrorText>
        )}
      </CardContent>

      <CardFooter className="flex jsb">
        <Button variant="secondary" onClick={closeForm}>
          {t('ui_common_cancel')}
        </Button>
        <Button onClick={() => clearErrors('formError')} type="submit" processing={isSubmitting}>
          {errors.formError ? t('ui_common_try_again') : t('ui_common_save')}
        </Button>
      </CardFooter>
    </form>
  );
};

export default UpdatePersistedFilterForm;
