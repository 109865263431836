import { useQuery } from '$lib/hooks/fetch-utillities';
import { useEffect, useState } from 'react';
import { ensureNumber } from '$lib/numberHelpers';
import { RefFilterValuesDocument } from '$typings/graphql-codegen';
import { IReactGridRangeComponentProps } from '$components/grid/react-grid/grid.react';
import { IFilterGroup } from 'src/interfaces';

export function useHistogram(property: string, activeFilters: IFilterGroup[] | undefined, componentProps: IReactGridRangeComponentProps) {
  const filters = activeFilters?.filter(f => f.field !== property);

  const variables = {
    property: property,
    filters: JSON.stringify(filters),
    page: componentProps.page
  };
  const { data } = useQuery(RefFilterValuesDocument, variables);

  const numBars = 20;
  const [histogram, setHistogram] = useState<number[]>(new Array(numBars).fill(0));

  useEffect(() => {
    const bars = new Array(numBars).fill(0);
    if (data) {
      const max = componentProps?.max || 100;
      const min = componentProps?.min || 0;
      const stepSize = max / numBars;
      const instances = data.refFilterValues
        .map(a => ({ percent: ensureNumber(a.key), instances: a.instances || 0, step: Math.floor(ensureNumber(a.key) / stepSize) }))
        .filter(a => a.percent >= min && a.percent <= max);

      const sum = (arr: number[]) => arr.reduce((cur, next) => cur + next, 0);
      // Return a histogram inside the steps. The last index is inclusive on the upper bounds.
      const isLastIndex = (index: number, arr: any[]) => arr.length - 1 === index;
      const instancesInSteps = bars.map((_, i, ar) =>
        sum(instances.filter(instance => instance.step === i || (isLastIndex(i, ar) && instance.step == i + 1)).map(a => a.instances))
      );
      const maximum = Math.max(...instancesInSteps);
      setHistogram(instancesInSteps.map(i => (i / maximum) * 100));
    }
  }, [data]);

  return histogram;
}
