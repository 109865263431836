import React, { useState, FC, memo, useEffect } from 'react';
import { CardContent, CardHeader, Typography } from '@mui/material';
import { CardFlexColumn } from '$components/cards/mui-card';
import CardTabBar from '$components/cards/card-tab-bar/card-tab-bar.react';
import CardTabBarItem from '$components/cards/card-tab-bar/card-tab-bar-item/card-tab-bar-item.react';
import { ImportView } from './modules/import-view';
import { HistoryView } from './modules/history-view';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { getUserFeatures } from '../../../../../../config/sessionService';

// tab 'none' should never render, because the parent component should check if the user has access
type historyTabName = 'history' | 'importRefs' | 'none';

export interface IControllerHistoryProps {
  controllerId: number;
}

const ControllerHistoryComponent: FC<IControllerHistoryProps> = ({
  controllerId,
}) => {
  const features = getUserFeatures();
  const accessToImport = features.importManagement.read;
  const accessToHistory = features.controllerLog.read;
  
  const [t] = useCaseInsensitiveTranslation();
  const [selectedTab, setSelectedTab] = useState<historyTabName>(accessToHistory ? 'history': accessToImport ? 'importRefs' : 'none');

  
  useEffect(() => {
    if (selectedTab === 'history' && !accessToHistory && accessToImport) setTab('importRefs');
    if (selectedTab === 'importRefs' && !accessToImport && accessToHistory) setTab('history');
  }, [selectedTab])

  const setTab = (tab: historyTabName) => () => {
    if (tab === 'history' && !accessToHistory) return;
    if (tab === 'importRefs' && !accessToImport) return;
    if (selectedTab !== tab) setSelectedTab(tab);
  };

  return (
    <>
      <CardFlexColumn>
        <CardHeader
          title={
            <Typography variant="h6">
              {t('ui_controllerdetails_controller_history')}
            </Typography>
          }
        />
        <CardTabBar>
          {accessToHistory && (
            <CardTabBarItem
              active={selectedTab === 'history'}
              onClick={setTab('history')}
            >
              {t('UI_SiteDetails_Controllers_Headers_History')}
            </CardTabBarItem>
          )}
          {accessToImport && (
            <CardTabBarItem
              active={selectedTab === 'importRefs'}
              onClick={setTab('importRefs')}
            >
              {t('UI_SiteDetails_Controllers_Headers_ImportRefs')}
            </CardTabBarItem>
          )}
        </CardTabBar>
        <CardContent sx={{ overflow: 'auto' }}>
          {selectedTab === 'importRefs' && accessToImport ? (
            <ImportView controllerId={controllerId} />
          ) : selectedTab === 'history' && accessToHistory ? (
            <HistoryView controllerId={controllerId} />
          ) : (
            <></>
          )}
        </CardContent>
      </CardFlexColumn>
    </>
  );
};

export const ControllerHistory = memo(ControllerHistoryComponent);
