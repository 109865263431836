import React, { FC } from 'react';
import classNames from "classnames";

interface IVolumeUpProps {
  className?: string;
}

const VolumeUp: FC<IVolumeUpProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={classNames('feather', 'feather-volume-2', className)}
  >
    <path d="M11 5 6 9H2v6h4l5 4V5zM19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07" />
  </svg>
);

export default VolumeUp;
