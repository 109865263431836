import React, { FC, memo, useState } from 'react';
import { SecurityLevelMenu } from '$components/security-level/security-level-menu/security-level-menu';
import { mutate } from '$lib/hooks/fetch-utillities';
import { UpdateControllerParameterSecurityLevelDocument } from '$typings/graphql-codegen';
import { IControllerParameterUpdater } from '../common/controller-parameter-updater';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { mappedSecurityLevels } from '$components/security-level/common/security-level-definitions';

interface ISecurityLevelValueProps extends IControllerParameterUpdater {
  initialValue: number;
  allowEdit: boolean;
}

const SecurityLevelValueComponent: FC<ISecurityLevelValueProps> = ({
  setStatusMessage,
  controllerId,
  controllerParameterId,
  initialValue, 
  allowEdit,
}: ISecurityLevelValueProps) => {
  const [securityLevel, setSecurityLevel] = useState(initialValue);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [t] = useCaseInsensitiveTranslation();

  const setLevel = async (level: number) => {
    setIsSubmitting(true);

    const successMessage = t(
      'UI_SiteDetails_Controllers_ControllerDetails_ControllerParameters_SecurityLevelChanged',
      {
        before: mappedSecurityLevels[initialValue].displayText,
        after: mappedSecurityLevels[level].displayText,
      }
    );
    
    //setTimeout(() => setIsSubmitting(false), 1000);
    // mutate && revalidate
    try {
      await mutate(
        UpdateControllerParameterSecurityLevelDocument,
        {
          controllerId,
          controllerParameterId,
          securityLevel: level,
        },
        true,
        ({ updateControllerParameterSecurityLevel: { message, success } }) => {
          if (success) {
            setSecurityLevel(level);
            setStatusMessage({
              error: false,
              dismissible: true,
              message: successMessage,
            });
          } else if (message) {
            setStatusMessage({
              error: true,
              dismissible: true,
              message: message,
            });
          } else {
            setStatusMessage({
              error: true,
              dismissible: true,
              message: t('UI_Common_Error_Save_Item_Failed'),
            });
          }
        }
      );
    } catch {
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <SecurityLevelMenu
        level={securityLevel}
        setLevel={setLevel}
        isSubmitting={isSubmitting}
        allowEdit={allowEdit}
      />
    </>
  );
};

export const SecurityLevelValue = memo(SecurityLevelValueComponent);
