export type ControllerTab =
    'info'
  | 'access'
  | 'commands'
  | 'outputcalibrations'
  | 'parameters'
  | 'profiles'
  | 'history'
  | 'lastBackup'
  | 'map';

export const controllerTabUrlReplaceRegex =
  /\/?(actions|info|access|commands|parameters|profiles|history|lastBackup|map)?\/?$/;
export const controllerTabUrlMatchRegex =
  /\/(actions|info|access|commands|parameters|profiles|history|lastBackup|map)\/?$/;
