import React, { FC, memo, useState } from "react";
import Icon from "$components/icons/icon/icon.react";
import { IconButton } from "@mui/material";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";

interface IClippyProps {
  text: string;
}

const defaultIcon = "fa-clipboard";

const ClippyComponent: FC<IClippyProps> = ({ text }: IClippyProps) => {
  const [t] = useCaseInsensitiveTranslation();
  const [icon, setIcon] = useState(defaultIcon);

  const showIconForAWhile = (iconToDisplay: string, durationMS: number = 3000) => {
    setIcon(iconToDisplay);
    return setTimeout(() => setIcon(defaultIcon), durationMS);
  };
  
  const copy = () => {
    navigator.clipboard.writeText(text)
      .then(() => showIconForAWhile("fa-check"))
      .catch(() => showIconForAWhile("fa-exclamation-triangle"));
  };
  
  return (
    <div className="flex jsb">
      {text}
      <IconButton
        size="small"
        sx={{ p: 0, color: "unset" }}
        title={t("ui_common_copy_to_clipboard")}
        onClick={copy}
      >
        <Icon
          name={icon}
          className="icon"
        />
      </IconButton>
    </div>
  );
};

export const Clippy = memo(ClippyComponent);
