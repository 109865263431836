import React, { FC, memo } from 'react';
import { useQuery } from '$lib/hooks/fetch-utillities';
import {
  GetUsersWithAccessToControllerDocument,
  GetUsersWithAccessToControllerQuery,
} from '$typings/graphql-codegen';
import { Button, Link, List, ListItem, Typography } from '@mui/material';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import { CardWithHeader } from '$components/cards/mui-card';
import ErrorText from '$components/texts/error-text/error-text.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

interface IControllerAccessProps {
  controllerId: number;
  pendingBuidId?: number;
}

type ControllerAccessType = Exclude<
  GetUsersWithAccessToControllerQuery['controller'],
  null | undefined
>['usersWithAccess'][0];

const sortByName = (u1: ControllerAccessType, u2: ControllerAccessType) => {
  const a = u1.user.name.toUpperCase();
  const b = u2.user.name.toUpperCase();
  return a < b ? -1 : a > b ? 1 : 0;
};

const ControllerAccessComponent: FC<IControllerAccessProps> = ({
  controllerId,
  pendingBuidId,
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const { data, loading, revalidate } = useQuery(
    GetUsersWithAccessToControllerDocument,
    {
      controllerId: controllerId,
      pendingBuidId,
    }
  );

  if (data) {
    const headerText = `${t(
      'ui_controllerdetails_controller_access_connected_users'
    )} (${data.controller?.usersWithAccess.length})`;
    return (
      <CardWithHeader headerText={headerText}>
        <div className="overflow-auto">
          <List>
            {data.controller?.usersWithAccess
              .sort(sortByName)
              .map((userAccess) => {
                const displayName = userAccess.user.name ?? 'No name';
                const removedOrAddedText = userAccess.removed
                  ? '(Removed)'
                  : userAccess.added
                  ? '(Added)'
                  : '';
                const textColor = userAccess.removed
                  ? 'red'
                  : userAccess.added
                  ? 'green'
                  : undefined;

                return (
                  <ListItem
                    sx={{ marginBottom: '0.7rem' }}
                    key={userAccess.user.userId}
                  >
                    <img
                      title="Internal user"
                      src="/favicon.ico"
                      width="14"
                      height="14"
                      style={{
                        visibility: userAccess.user.internalUser
                          ? 'visible'
                          : 'hidden',
                      }}
                    />
                    {userAccess.hasAccess ? (
                      <Link
                        marginLeft="0.4rem"
                        href={`/usermanager/details/${userAccess.user.userId}`}
                        sx={{ color: textColor }}
                      >
                        {displayName}
                      </Link>
                    ) : (
                      <Typography
                        marginLeft="0.4rem"
                        style={{ color: textColor }}
                      >
                        {displayName}
                      </Typography>
                    )}
                    {removedOrAddedText && (
                      <Typography>{removedOrAddedText}</Typography>
                    )}
                  </ListItem>
                );
              })}
            {data.controller?.usersWithAccess.length === 0 && (
              <div className="mar_m">
                <Typography>
                  {t('ui_controllerdetails_controller_access_no_users')}
                </Typography>
              </div>
            )}
          </List>
        </div>
      </CardWithHeader>
    );
  }

  if (loading) {
    return (
      <CardWithHeader
        headerText={t('ui_controllerdetails_controller_access_connected_users')}
      >
        <LoadingBars center />
      </CardWithHeader>
    );
  }

  return (
    <CardWithHeader
      headerText={t('ui_controllerdetails_controller_access_connected_users')}
      footerContent={
        <Button variant="outlined" onClick={revalidate}>
          {t('ui_common_tryagain')}
        </Button>
      }
    >
      <div className="mar_m">
        <ErrorText>{t('UI_Common_Error_Loading_Data_Failed')}</ErrorText>
      </div>
    </CardWithHeader>
  );
};

export const ControllerAccess = memo(ControllerAccessComponent);
