import React, { useEffect, useState, memo, FC } from 'react';
import { IHistoryItem } from '$interfaces/application';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { ControllerLogDocument } from '$typings/graphql-codegen';
import { isSomething } from '$lib/helpers';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import { HistoryList } from '$components/history-list/history-list/history-list.react';
import { Button } from '@mui/material';
import ErrorText from '$components/texts/error-text/error-text.react';
import { IControllerLogViewProps } from '../controller-log-view-props';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

interface QueryVariables {
  controllerId: number;
  amount?: number;
}

const HistoryViewComponent: FC<IControllerLogViewProps> = ({
  controllerId,
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [amount] = useState(10);
  const [historyItems, setHistoryItems] = useState<IHistoryItem[]>([]);
  const [variables, setVariables] = useState<QueryVariables>({
    controllerId: controllerId,
    amount: amount,
  });
  const [showAllClicked, setShowAllClicked] = useState(false);

  const { data, loading, error, revalidate } = useQuery(
    ControllerLogDocument,
    variables
  );

  const showAll = () => {
    if (showAllClicked) return;
    setVariables({ controllerId: controllerId });
    setShowAllClicked(true);
  };

  useEffect(() => {
    if (!data) return;

    const converted = data.controller?.logs.map(
      (item): IHistoryItem => ({
        timeStamp: isSomething(item.timeStamp) ? item.timeStamp.toString() : '',
        text: [item.data ?? ''],
        metaInformation: item.performedByUser?.name,
      })
    );
    if (converted) setHistoryItems(converted);
  }, [data]);

  if (error !== undefined)
    return (
      <>
        <ErrorText>Failed to fetch</ErrorText>
        <Button
          sx={{ marginTop: '1rem' }}
          fullWidth
          variant="text"
          onClick={revalidate}
        >
          {t('ui_common_try_again')}
        </Button>
      </>
    );

  return (
    <>
      {historyItems.length === 0 ? (
        loading ? (
          <LoadingBars center />
        ) : (
          <>
            {t(
              'UI_SiteDetails_Controllers_ControllerDetails_ControllerHistory_None'
            )}
          </>
        )
      ) : (
        <>
          <HistoryList items={historyItems} />
          {!showAllClicked && (
            <Button
              sx={{ marginTop: '1rem' }}
              fullWidth
              variant="contained"
              onClick={() => showAll()}
            >
              {t(
                'UI_SiteDetails_Controllers_ControllerDetails_ControllerHistory_Actions_LoadAll'
              )}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export const HistoryView = memo(HistoryViewComponent);
