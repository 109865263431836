import { default as React, FC } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  ControllerBackupViewerIdaxData,
} from '$typings/graphql-codegen';
import ChartistGraph from 'react-chartist';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Icon from '$components/icons/icon/icon.react';
import './controllerbackupviewer.css'

export interface IIDAXBackupViewerProps {
  idaxData: ControllerBackupViewerIdaxData;
}

const IDAXBackupViewer: FC<IIDAXBackupViewerProps> = ({ idaxData }) => {
  return (
    <>
      <div className="idaxbackupviewerpage-react">
      <Accordion sx={{marginTop:"10px"}}>
        <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
          <Typography>Controller Settings (7)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                <TableRow>
                  <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff', width:"200px"}}>Name</TableCell>
                  <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>{idaxData.unit.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Buid</TableCell>
                    <TableCell>{idaxData.unit.groupCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Data delivery period</TableCell>
                    <TableCell>{idaxData.unit.dataDeliveryPeriod}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Feedback data delivery period</TableCell>
                    <TableCell>{idaxData.unit.feedbackDataDeliveryPeriod}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Language</TableCell>
                    <TableCell>{idaxData.unit.language}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Modbus server</TableCell>
                    <TableCell>{idaxData.unit.modbusServer ? "Yes" : "No" }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Time zone</TableCell>
                    <TableCell>{idaxData.unit.timeZone}</TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{marginTop:"10px"}}>
        <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
          <Typography>Communication Manager Settings ({idaxData.communicationManagerSettings.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                <TableRow>
                  <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff', width:"200px"}}>Name</TableCell>
                  <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {idaxData.communicationManagerSettings.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))} 
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
		
		<Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Analog Inputs ({idaxData.analogInputRows.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Alias</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Unit</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Minimum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Maximum</TableCell>

                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>InputType</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Density</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Disabled</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Dish Radius</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Height</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Length</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>mA Minimum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>mA Maximum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Min TankLevel</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>No of Tanks</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Offset</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Pressure</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Product</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Smoothing</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Source</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Tanklevel Sensor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {idaxData.analogInputRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.alias}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                      <TableCell>{row.unit}</TableCell>
                      <TableCell>{row.minimum}</TableCell>
                      <TableCell>{row.maximum}</TableCell>

                      <TableCell>{row.analogInputType}</TableCell>
                      <TableCell>{row.density}</TableCell>
                      <TableCell>{row.disabled ? "Yes" : "No"}</TableCell>
                      <TableCell>{row.dishRadius}</TableCell>
                      <TableCell>{row.height}</TableCell>
                      <TableCell>{row.length}</TableCell>
                      <TableCell>{row.milliAmpereMinimum}</TableCell>
                      <TableCell>{row.milliAmpereMaximum}</TableCell>
                      <TableCell>{row.minTankLevel}</TableCell>
                      <TableCell>{row.numberOfTanks}</TableCell>
                      <TableCell>{row.offset}</TableCell>
                      <TableCell>{row.pressure}</TableCell>
                      <TableCell>{row.product}</TableCell>
                      <TableCell>{row.smoothing}</TableCell>
                      <TableCell>{row.source}</TableCell>
                      <TableCell>{row.tankLevelSensor}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

		<Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Analog Outputs ({idaxData.analogOutputRows.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Alias</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Unit</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Minimum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Maximum</TableCell>

                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Calibration</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Disabled</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>mA Minimum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>mA Maximum</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {idaxData.analogOutputRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.alias}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                      <TableCell>{row.unit}</TableCell>
                      <TableCell>{row.minimum}</TableCell>
                      <TableCell>{row.maximum}</TableCell>

                      <TableCell>{row.calibration}</TableCell>
                      <TableCell>{row.disabled ? "Yes" : "No"}</TableCell>
                      <TableCell>{row.milliAmpereMinimum}</TableCell>
                      <TableCell>{row.milliAmpereMaximum}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
		
		<Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Digital Inputs ({idaxData.digitalInputRows.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Unit</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Minimum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Maximum</TableCell>

                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Input Type</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Disabled</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Offset</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Pulses per Unit</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Smoothing</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Source</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Switch Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {idaxData.digitalInputRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                      <TableCell>{row.unit}</TableCell>
                      <TableCell>{row.minimum}</TableCell>
                      <TableCell>{row.maximum}</TableCell>

                      <TableCell>{row.digitalInputType}</TableCell>
                      <TableCell>{row.disabled ? "Yes" : "No"}</TableCell>
                      <TableCell>{row.offset}</TableCell>
                      <TableCell>{row.pulsesPerUnit}</TableCell>
                      <TableCell>{row.smoothing}</TableCell>
                      <TableCell>{row.source}</TableCell>
                      <TableCell>{row.switchType}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
		
		<Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Digital Outputs ({idaxData.digitalOutputRows.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Unit</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Minimum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Maximum</TableCell>

                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Calibration</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Disabled</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Mode</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Pulses per Minute</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Pulses per Minute Max</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Units per Pulse</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {idaxData.digitalOutputRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                      <TableCell>{row.unit}</TableCell>
                      <TableCell>{row.minimum}</TableCell>
                      <TableCell>{row.maximum}</TableCell>

                      <TableCell>{row.calibration}</TableCell>
                      <TableCell>{row.disabled ? "Yes" : "No"}</TableCell>
                      <TableCell>{row.mode}</TableCell>
                      <TableCell>{row.pulsesPerMinute}</TableCell>
                      <TableCell>{row.pulsesPerMinuteMax}</TableCell>
                      <TableCell>{row.unitsPerPulse}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Parameters ({idaxData.parameterRows.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Alias</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Value</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Unit</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Minimum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Maximum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Security</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {idaxData.parameterRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.alias}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                      <TableCell>{row.value}</TableCell>
                      <TableCell>{row.unit}</TableCell>
                      <TableCell>{row.minimum}</TableCell>
                      <TableCell>{row.maximum}</TableCell>
                      <TableCell>{row.security}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Trends ({idaxData.trendRows.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Alias</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Comment</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Unit</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Minimum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Maximum</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Security</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {idaxData.trendRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.alias}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                      <TableCell>{row.unit}</TableCell>
                      <TableCell>{row.minimum}</TableCell>
                      <TableCell>{row.maximum}</TableCell>
                      <TableCell>{row.security}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Profiles ({idaxData.profileRows.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {idaxData.profileRows.map((row) => {
              const chartData = {
                series: [
                  row.values
                ]
              };
              return (
              <div key={row.name}>
                <h4>{row.name}</h4>
                <span>Comment : {row.comment}</span>
                <ChartistGraph data={chartData} type='Line' />
              </div>
            )})}  
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default IDAXBackupViewer;
