import React, { FC } from 'react';

interface ISignalPoorProps {
  className?: string;
}

const SignalPoor: FC<ISignalPoorProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 122.035 122.035"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <circle fill="#5e8ab4" cx="61.046" cy="52.111" r="11.115" />
    <rect x="53.906" y="66.899" width="14.28" height="40.482" rx="6.47" ry="6.47" fill="#7d6a55" />
    <path
      fill="#5e8ab4"
      d="M47.153 41.28c1.635-2.079 1.64-5.09-.125-7.13-2.004-2.32-5.62-2.568-7.974-.533a28.46 28.46 0 0 0-6.619 18.287 28.459 28.459 0 0 0 6.611 18.277c2.355 2.036 5.935 1.79 7.938-.528 1.748-2.022 1.781-5.018.193-7.094a17.385 17.385 0 0 1-3.627-10.655c0-3.997 1.343-7.68 3.603-10.624zm27.73.05c-1.635-2.079-1.64-5.09.124-7.13 2.004-2.32 5.62-2.569 7.975-.533A28.46 28.46 0 0 1 89.6 51.953a28.459 28.459 0 0 1-6.611 18.278c-2.355 2.036-5.934 1.79-7.938-.529-1.747-2.02-1.78-5.018-.193-7.094a17.385 17.385 0 0 0 3.627-10.655c0-3.996-1.343-7.68-3.603-10.623z"
    />
  </svg>
);

export default SignalPoor;