import React, {
  FC,
  forwardRef,
  FocusEventHandler,
  ChangeEventHandler,
  MouseEventHandler,
  ChangeEvent,
  useState,
  useEffect
} from 'react';
import '$components/checkbox/checkbox.css';
import Icon from '$components/icons/icon/icon.react';
import classNames from 'classnames';

interface ICheckboxProps {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  name?: string;
  checked?: boolean;
  text?: string;
  loading?: boolean;
  disabled?: boolean;
  size?: 'normal' | 'bigger';
  className?: string;
  onClick?: MouseEventHandler
}

const Checkbox: FC<ICheckboxProps> = forwardRef<
  HTMLInputElement,
  ICheckboxProps
>(({ onChange, checked, text, loading, disabled, size, className, name, onClick }, ref) => {
    const [checkedState, setChecked] = useState(checked);

    function change(e: ChangeEvent<HTMLInputElement>) {
      onChange &&
        onChange({
          ...e,
          target: {
            ...e.target,
            value: e.target.checked as any,
            name: name as any
          }
        });
      setChecked(e.target.checked);
    }

    useEffect(() => {
      setChecked(checked);
    }, [checked]);
    
    const WrapperClassName = (() => {
      const base = 'checkbox-component'
      const externalClass = className ?? ''
      return [base, externalClass].join(' ')
    })()
  
    const IconClassName = (() => {
      const base = 'icon'
      const checkable = checkedState ? 'checked' : ''
      const sizable = size ?? ''
      return [base, checkable, sizable].join(' ')
    })()

    return (
      <div className={WrapperClassName}>
        <label className="container">
          <input
            name={name}
            onChange={change}
            type="checkbox"
            checked={checkedState}
            disabled={disabled}
            onClick={onClick}
            ref={ref}
          />
          <span className={classNames('checkmark', size)}>
            <Icon
              className={IconClassName}
              name={loading ? 'fa fa-spinner fa-pulse' : 'fa-check'}
            />
          </span>
          {text?.length && <span className="label-text">{text}</span>}
        </label>
      </div>
    );
  }
);

export default Checkbox;
