//import Tooltip from '$components/tooltip/tooltip.react';
import { Tooltip } from '@mui/material'
import React, {FC, HTMLAttributes} from 'react';

function escapeRegex(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const assureRegexSafeArray = (arrayOrString: string[] | string) => {
  if (Array.isArray(arrayOrString)) {
    return arrayOrString.map(escapeRegex);
  }
  return [escapeRegex(arrayOrString)];
};

interface IHighlightTextProps {
  inputValue: React.ReactNode;
  textToHighlight?: string | string[];
}

const HighlightText: FC<IHighlightTextProps> = ({
  inputValue,
  textToHighlight,
}) => {
  const value = inputValue?.toString().trim();
  if (!value) {
    return <></>;
  }
  if (
    !textToHighlight ||
    textToHighlight === '' ||
    (textToHighlight.length === 1 && textToHighlight[0] === '')
  ) {
    return <>{inputValue}</>;
  }
  const matchingWords = assureRegexSafeArray(textToHighlight).join('|');
  try {
    const matcher = new RegExp(`(${matchingWords})`, 'gi');
    const highlighted = value.split(matcher).map((part, i) =>
      matcher.test(part) ? (
        <span key={part + i} className="highlighted">
          {part}
        </span>
      ) : (
        part
      )
    );
    // Need to wrap these ReactNodes with a Fragment Tag '<></>' because they might be arrays or something..
    // TS DOES NOT like this for some reason.
    return <>{highlighted}</>;
  } catch (e) {
    console.error("something went wrong with regex matching in HighlightText", e);
    return <>{inputValue}</>
  }

};

interface IColumnProps extends HTMLAttributes<HTMLElement> {
  width?: number | string;
  highlightText?: string | string[];
  columnLink?: string;
  tooltipText?: string;
  className?: string;
  noPadding?: boolean;
  showPointerIfLink?: boolean;
  style?: React.CSSProperties;
  openLinkInNewTab?: boolean;
}

const GridCell: FC<IColumnProps> = ({
  children,
  width = 200,
  highlightText,
  columnLink,
  tooltipText,
  className,
  noPadding,
  showPointerIfLink,
  style,
  openLinkInNewTab,
}) => {
  const shouldHighlight =
    (typeof children === 'string' || typeof children === 'number') &&
    !!highlightText?.length &&
    highlightText[0] !== '';

  const component = (    
    <Tooltip title={tooltipText}>
      <div        
        className={className}
        style={{
          ...{
            padding: noPadding ? undefined : '1rem',
            whiteSpace: 'pre',
            width: width,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            height: 44,
          },
          ...style,
        }}
      >
        {!shouldHighlight ? (
          children
        ) : (
          <HighlightText
            textToHighlight={highlightText}
            inputValue={children}
          />
        )}
      </div>
    </Tooltip>
  );

  if (columnLink) {
    return (
      <a
        href={columnLink}
        target={openLinkInNewTab ? '_blank' : undefined}
        rel={openLinkInNewTab ? 'noreferrer' : undefined}
        style={{
          color: 'inherit',
          textDecoration: 'inherit',
          cursor: showPointerIfLink ? 'pointer' : 'inherit',
        }}
      >
        {component}
      </a>
    );
  }

  return component;
};

export default GridCell;
