import Button from '$components/buttons/button.react';
import { columnKey } from '$components/grid/grid';
import { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import { isSomething } from '$lib/helpers';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import React, { FC, ReactElement, useEffect } from 'react';
import { IFilterGroup } from 'src/interfaces';
import './column-filter-poppover.react.css';
import classNames from "classnames";

type ColumnFilterPopoverProps = {
  alignment: 'center' | 'left' | 'right';
  close: () => void;
};

const ColumnFilterPopover: FC<ColumnFilterPopoverProps> = ({
  alignment,
  close,
  children,
}) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (!target) return;
      if (target.closest('.flatpickr-calendar')) return; // Clicked inside datepicker
      if (target.closest('[data-type-popover]')) return; // We clicked inside
      close();
    };
    addEventListener('click', handleClick);
    return () => {
      removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div
      data-type-popover
      className={classNames('popover-wrapper', alignment)}>
      <div className="arrow"></div>
      <div className="popover">{children}</div>
    </div>
  );
};

interface IFilterPopoverProps<T> {
  filterIsShownForColumn: columnKey | undefined;
  closeCalled: () => void;
  column: IReactGridColumn<T>;
  columns: IReactGridColumn<T>[];
  i: number;
  activeFilters: IFilterGroup[];
  filtersChanged?: (groups: IFilterGroup[]) => void;
  freeTextQuery: string[] | undefined;
}

export const FilterPopover = <T,>({
  filterIsShownForColumn,
  closeCalled,
  column,
  i,
  columns,
  filtersChanged,
  activeFilters,
  freeTextQuery = undefined,
}: IFilterPopoverProps<T>): ReactElement | null => {
  const filterDefinition = column.filter;
  const [t] = useCaseInsensitiveTranslation();
  if (
    !isSomething(filterIsShownForColumn) ||
    filterIsShownForColumn !== column.columnKey ||
    !filterDefinition
  )
    return null;
  const alignment =
    i === 0 ? 'left' : i === columns.length - 1 ? 'right' : 'center';
  const FilterComponent = filterDefinition.component;

  const resetFilter = () => {
    filtersChanged &&
      filtersChanged(
        activeFilters?.filter((f) => f.field !== filterDefinition.property) ??
          []
      );
  };

  const filterChanged = (group: IFilterGroup) => {
    const filters = activeFilters?.filter((f) => f.field !== group.field) ?? [];

    if (group.filters.length) {
      filters.push(group);
    }

    filtersChanged && filtersChanged(filters);
  };

  return (
    <ColumnFilterPopover close={closeCalled} alignment={alignment}>
      <div className="flex jsb aicenter pad_b">
        <div className="strong">
          {t('ui_sitelist_columnfilter_popover_title')}
        </div>
        <Button variant="tertiary" className="clearall" onClick={resetFilter}>
          {t('ui_sitelist_filter_reset')}
        </Button>
      </div>
      {FilterComponent && (
        <FilterComponent
          componentProps={filterDefinition.componentProps}
          activeFilters={activeFilters}
          freeTextQuery={freeTextQuery}
          resetCalled={resetFilter}
          filterChanged={filterChanged}
          property={filterDefinition.property}
        />
      )}
      <div className="flex jcflexend mar_tm">
        <Button variant="secondary" onClick={closeCalled}>
          {t('ui_common_close')}
        </Button>
      </div>
    </ColumnFilterPopover>
  );
};
