import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import Checkbox from '$components/checkbox/checkbox.react';
import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import CustomerDropdown from '$components/dropdowns/customer-dropdown/customer-dropdown.react';
import FormElement from '$components/forms/form-element/form-element.react';
import TextField from '$components/textbox/text-field.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import { IGeneralFormError } from '$lib/hooks/reactHookFormHelper';
import { CreateSiteFilterDocument } from '$typings/graphql-codegen';
import React, { FC, useEffect } from 'react';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFilterGroup } from 'src/interfaces';
import { getUserFeatures } from '../../../../../config/sessionService';
import { SiteGroupFilterDropdownQueryReact_siteFilters } from '$typings/graphql';

interface ICreatePersistedFilterFormValues extends IGeneralFormError {
  filterName: string;
  isPublicFilter: boolean;
  selectedBuidId?: string;
  selectedCustomerId?: string;
}

interface ICreatePersistedFilterFormProps {
  closeForm: () => void;
  onSaved: (filter: SiteGroupFilterDropdownQueryReact_siteFilters) => void;
  mutationVariables: {
    filtersToSave?: IFilterGroup[];
  };
}

const CreatePersistedFilterForm: FC<ICreatePersistedFilterFormProps> = ({ closeForm, onSaved, mutationVariables }) => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    clearErrors,
    watch,
    control,
    setError,
    setFocus,
  } = useForm<ICreatePersistedFilterFormValues>();
  const [t] = useTranslation();
  const features = getUserFeatures();

  useEffect(() => {
    setFocus('filterName');
  }, [setFocus]);

  const onError = () => {
    setError('formError', {
      message: t('ui_common_error_save_item_failed')
    });
  };

  const onSubmit: SubmitHandler<ICreatePersistedFilterFormValues> = async values => {
    const variables = {
      input: {
        name: values.filterName,
        buidId: values.selectedBuidId ? ensureNumber(values.selectedBuidId) : undefined,
        customerId: values.selectedCustomerId ? ensureNumber(values.selectedCustomerId) : undefined,
        definition: JSON.stringify(mutationVariables.filtersToSave),
        isPublic: values.isPublicFilter ?? false,
        filterId: -1,
        enabled: true
      }
    };
    
    const res = await mutate(CreateSiteFilterDocument, variables, true, closeForm, onError);
    
    if (res.createSiteFilter && onSaved) {
      onSaved({
        filterId: res.createSiteFilter.filterId,
        name: variables.input.name,
        isPublic: variables.input.isPublic,
        definition: variables.input.definition,
      });
    }
  };

  const isPublicFilter = watch('isPublicFilter');

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="fill-height flex column jsb">
      <CardContent className="pad_vnone pad_b">
        <FormElement label={t('ui_sitelist_filter_persist_label_filtername')}>
          <TextField
            {...register('filterName', { required: t('ui_common_validationerror_requiredfield') as string })}
            error={errors.filterName?.message}
          />
        </FormElement>

        {features.publicFilters.write && (
          <FormElement label={t('ui_sitelist_filter_persist_label_publicfilter')}>
            <Checkbox {...register('isPublicFilter')} />
          </FormElement>
        )}

        {features.publicFilters.write && isPublicFilter && (
          <FormElement label={t('ui_sitelist_filter_persist_label_buidaccess')}>
            <Controller
              name="selectedBuidId"
              control={control}
              rules={{ required: { value: !watch('selectedCustomerId'), message: t('ui_common_validationerror_requiredfield') as string } }}
              render={({ field }) => (
                <BuidDropdown
                  selected={field.value}
                  itemClicked={item => field.onChange(item?.buidId)}
                  topElement={t('ui_common_none') as string}
                  error={!!(errors.selectedBuidId as FieldError)?.message}
                ></BuidDropdown>
              )}
            />
            <ErrorText>{(errors.selectedBuidId as FieldError)?.message}</ErrorText>
          </FormElement>
        )}

        {features.publicFilters.write && isPublicFilter && (
          <FormElement label={t('ui_sitelist_filter_persist_label_customeraccess')}>
            <Controller
              name="selectedCustomerId"
              control={control}
              rules={{ required: { value: !watch('selectedBuidId'), message: t('ui_common_validationerror_requiredfield') as string } }}
              render={({ field }) => (
                <CustomerDropdown
                  selected={field.value}
                  itemClicked={item => field.onChange(item?.customerId)}
                  topElement={t('ui_common_none')}
                  error={!!(errors.selectedCustomerId as FieldError)?.message}
                />
              )}
            />
            <ErrorText>{(errors.selectedCustomerId as FieldError)?.message}</ErrorText>
          </FormElement>
        )}
        {errors.formError && <ErrorText className="text-align-center mar_tm">{errors.formError?.message}</ErrorText>}
      </CardContent>

      <CardFooter className="flex jsb">
        <Button variant="secondary" onClick={closeForm}>
          {t('ui_common_cancel')}
        </Button>
        <Button onClick={() => clearErrors('formError')} type="submit" processing={isSubmitting}>
          {errors.formError ? t('ui_common_try_again') : t('ui_common_save')}
        </Button>
      </CardFooter>
    </form>
  );
};

export default CreatePersistedFilterForm;
