import { useQuery } from "$lib/hooks/fetch-utillities";
import { IMeasurementUnit, displayMeasurement } from "$lib/unitConverters";
import { MarkerIconEnum, MarkerPopupDocument } from "$typings/graphql-codegen";
import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, styled, tableCellClasses } from "@mui/material";
import React, { FC, Fragment, MouseEventHandler } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.yaraBrightBlue.main,
    border: 0,
    color: theme.palette.common.white,
    fontSize: 14
  },
  [`&.${tableCellClasses.body}`]: {
    border: 0,
    fontSize: 14,
    color: theme.palette.yaraBrightBlue.contrastText,
    padding: "0 0 0.2rem 0",
    "&:last-of-type": {
      padding: "0 0.5rem 0.2rem 0"
    },
  },
  "&.link:hover": {
    textDecoration: "underline"
  }
}));

const StyledTableTopCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.yaraBrightBlue.main,
    border: 0,
    color: theme.palette.common.white,
    fontSize: 14
  },
  [`&.${tableCellClasses.body}`]: {
    border: 0,
    fontSize: 14,
    color: theme.palette.yaraBrightBlue.contrastText,
    padding: "0.2rem 0 0 0"
  },
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.yaraBrightBlue.lighter,
  "&:nth-of-type(4n), :nth-of-type(4n-1)": {
    backgroundColor: theme.palette.yaraBrightBlue.light
  },
}));

const PopupArrow = styled("div")(({ theme }) => ({
  width: 20,
  height: 20,
  top: 10,
  left: 112.5,
  position: "relative",
  backgroundColor: theme.palette.yaraBrightBlue.main,
  transform: "rotate(45deg)"
}))


const tankLevelAbove50Percent = '../../svg/tank_level_above_50_percent_icon.svg'
const tankLevelAboveMinimum = '../../svg/tank_level_above_minimum_icon.svg'
const alarm = '../../svg/alarm_icon.svg'
const tankOffline = '../../svg/tank_offline_icon.svg'
const h2sHigh = '../../svg/h2s_high_icon.svg'
const h2sOffline = '../../svg/h2s_offline_icon.svg'
const h2sOk = '../../svg/h2s_ok_icon.svg'
const parkedOffline = '../../svg/parked_offline_icon.svg'
const parkedOnline = '../../svg/parked_online_icon.svg'
const yaraLogo = '../../svg/yara.svg'

function getMarkerIconByEnum(markerIconEnum: MarkerIconEnum) {
  switch (markerIconEnum) {
    case MarkerIconEnum.TankLevelAbove50percent:
      return tankLevelAbove50Percent
    case MarkerIconEnum.TankLevelAboveMinimim:
      return tankLevelAboveMinimum
    case MarkerIconEnum.Alarm:
      return alarm
    case MarkerIconEnum.TankOffline:
      return tankOffline
    case MarkerIconEnum.H2SHigh:
      return h2sHigh
    case MarkerIconEnum.H2SOffline:
      return h2sOffline
    case MarkerIconEnum.H2SOk:
      return h2sOk
    case MarkerIconEnum.ParkedOffline:
      return parkedOffline
    case MarkerIconEnum.ParkedOnline:
      return parkedOnline
    case MarkerIconEnum.EasyFeed:
      return yaraLogo;
  }

  return undefined
}

interface ISiteMarkerHoverComponentProps {
  siteId: number
  position: { x: number, y: number }
  closePopup: () => void
  onClick: MouseEventHandler<HTMLDivElement>
}

const LoadingComponent: FC = () => <>
  <PopupArrow />
  <Table sx={{ borderSpacing: 0, tableLayout: "fixed" }} stickyHeader>
    <TableHead>
      <TableRow>
        <StyledTableTopCell colSpan={3}>
          <Skeleton />
        </StyledTableTopCell>
      </TableRow>
      <TableRow>
        <StyledTableCell colSpan={2}>
          <Skeleton />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton />
        </StyledTableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {Array(2).fill(null).map((_e, i) => (
        <StyledTableRow key={i}>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </StyledTableRow>
      ))}
    </TableBody>
  </Table></>

const SiteMarkerHoverComponent: FC<ISiteMarkerHoverComponentProps> = ({ siteId, position, closePopup, onClick }) => {
  const { data, loading } = useQuery(MarkerPopupDocument, {
    sortProperty: 1, filters: JSON.stringify([{ exclude: false, field: "siteid", type: "number", filters: [{ value: siteId, symbol: "=", operator: 0 }], partialMatch: true }])
  })

  const site = data?.elasticSearchPages.sitelist.data.edges[0]

  return <div id="hoverElement" onClick={onClick} style={{ zIndex: 10, width: 250, maxHeight: 400, overflowY: "auto", overflowX: "hidden", position: "absolute", left: position.x - 122.5, top: position.y, cursor: "pointer"}} onMouseLeave={closePopup}>
    {loading && <LoadingComponent />}
    {data && site && <>
      <PopupArrow />
      <Table sx={{ borderSpacing: 0, tableLayout: "fixed" }} stickyHeader>
        <TableHead >
          <TableRow>
            <StyledTableTopCell colSpan={6}>
              <Typography typography="h5" noWrap textOverflow="ellipsis" overflow="hidden" maxWidth={200}>
                {site.alias}
              </Typography>
            </StyledTableTopCell>
          </TableRow>
          <TableRow>
            {site.customerRef && <StyledTableCell colSpan={site.soldTo ? 3 : 6} align="left" sx={{ padding: "0 0.5rem" }}>
              {site.customerRef && "CustomerRef"}
            </StyledTableCell>}
            {site.soldTo && <StyledTableCell colSpan={site.customerRef ? 3 : 6} align="right" sx={{ padding: "0 0.5rem" }}>
              {site.soldTo && "Sold-to"}
            </StyledTableCell>}
          </TableRow>
          <TableRow>
            {site.customerRef && <StyledTableCell colSpan={site.soldTo ? 3 : 6} align="left" sx={{ padding: "0 0.5rem", overflow: "hidden" }}>
              {site.customerRef}
            </StyledTableCell>}
            {site.soldTo && <StyledTableCell colSpan={site.customerRef ? 3 : 6} align="right" sx={{ padding: "0 0.5rem" }}>
              {site.soldTo}
            </StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {site.tankChannels?.map((tc, index) => (
            <Fragment key={index}>
              <StyledTableRow>
                <StyledTableTopCell rowSpan={2} colSpan={1}>
                  <img width={30} src={!!tc.markerIconEnum ? getMarkerIconByEnum(tc.markerIconEnum) : ""} />
                </StyledTableTopCell>
                <Tooltip title="Product" arrow placement="top-start">
                  <StyledTableTopCell colSpan={5}>
                    <>{tc.product?.name}</>
                  </StyledTableTopCell>
                </Tooltip>
              </StyledTableRow>
              <StyledTableRow>
                <Tooltip title="Tank level percentage" arrow>
                  <StyledTableCell colSpan={1}>
                    <>
                      {tc.percentage ? tc.percentage + "%" : undefined}
                    </>
                  </StyledTableCell>
                </Tooltip>
                <Tooltip title="Tank total capacity" arrow>
                  <StyledTableCell colSpan={2} align='center'>
                    <>
                      {displayMeasurement(tc.capacity, tc.unit as IMeasurementUnit, true)}
                    </>
                  </StyledTableCell>
                </Tooltip>
                <Tooltip title="Free capacity" arrow>
                  <StyledTableCell colSpan={2} align='right'>
                    <>
                      {displayMeasurement(tc.tankDetails?.freeCapacity, tc.unit as IMeasurementUnit, true)}
                    </>
                  </StyledTableCell>
                </Tooltip>
              </StyledTableRow>
            </Fragment>
          ))}
          {site.gasChannels?.map((gc, index) => (
            <Fragment key={index}>
              <StyledTableRow>
                <StyledTableTopCell rowSpan={2} colSpan={1}>
                  <img width={30} src={!!gc.markerIconEnum ? getMarkerIconByEnum(gc.markerIconEnum) : ""} />
                </StyledTableTopCell>
                <Tooltip title="Product/alias" arrow>
                  <StyledTableTopCell colSpan={5}>
                    {gc.productName ?? gc.alias}
                  </StyledTableTopCell>
                </Tooltip>
              </StyledTableRow>
              <StyledTableRow>
                <Tooltip title="Max ppm">
                  <StyledTableCell colSpan={1}>
                    {gc.gasDetails?.maxPpm ? gc.gasDetails?.maxPpm : "?"}
                  </StyledTableCell>
                </Tooltip>
                <Tooltip title="Mean ppm">
                  <StyledTableCell colSpan={2} align='center'>
                    {gc.gasDetails?.meanPpm ? gc.gasDetails.meanPpm : "?"}
                  </StyledTableCell>
                </Tooltip>
                <Tooltip title="% TWA">
                  <StyledTableCell colSpan={2} align='right'>
                    {gc.gasDetails?.percentTimeAboveLimit ? `${gc.gasDetails?.percentTimeAboveLimit}%` : "?"}
                  </StyledTableCell>
                </Tooltip>
              </StyledTableRow>
            </Fragment>
          ))}
          {!site?.gasChannels?.length && !site?.tankChannels?.length && !loading && <TableRow>
            <TableCell colSpan={6} style={{ backgroundColor: "#fff" }}>No tank- or h2s channels</TableCell></TableRow>}
        </TableBody>
      </Table>
    </>
    }
  </div>
}


export default SiteMarkerHoverComponent
