import React, { memo, useEffect, useState } from 'react';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import { PlannerGridColum } from './planner-grid';
import Icon from '$components/icons/icon/icon.react';
import './planner.css';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

interface IPlannerSummaryProps<T> {
  summaryFields: string[];
  summaryValues: Promise<Map<string, string>>;
  columnsOrdered: PlannerGridColum<T>[];
  columnWidth: Record<string, number | string | undefined>;
}

const PlannerGridSummaryComponent = <T,>({
  summaryFields,
  summaryValues,
  columnWidth,
  columnsOrdered,
}: IPlannerSummaryProps<T>) => {
  const [t] = useCaseInsensitiveTranslation();
  const [awaitedSummary, setAwaitedSummary] = useState<
    Map<string, string> | undefined
  >(undefined);

  useEffect(() => {
    summaryValues
      .then((summary) => {
        setAwaitedSummary(summary);
      })
      .catch(() => {});
  }, [summaryValues]);

  const firstColWithSummary = columnsOrdered.findIndex((c) =>
    summaryFields.includes(c.columnKey)
  );

  return (
    <tr className="summary-row">
      <td colSpan={2 + firstColWithSummary}>
        <GridCell className="strong" width={'unset'}>
          {t('ui_planner_summary')}
        </GridCell>
      </td>
      {columnsOrdered.slice(firstColWithSummary).map((c) => (
        <td key={c.columnKey}>
          <GridCell
            width={columnWidth[c.columnKey] ?? c.initialWidth}
            className="strong"
          >
            {awaitedSummary ? (
              <>
                {summaryFields?.includes(c.columnKey)
                  ? awaitedSummary.get(c.columnKey)
                  : ''}
              </>
            ) : (
              <Icon name={'fa-pulse fa-spinner'} />
            )}
          </GridCell>
        </td>
      ))}
      <td />
    </tr>
  );
};

export const PlannerGridSummary = memo(PlannerGridSummaryComponent);
