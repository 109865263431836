import {
  InputMaybe,
  SortDirection
} from '$typings/graphql-codegen';
import { isSomething } from '$lib/helpers';
import { IFilterGroup } from '$interfaces/iFilter';

const stringCompare = (a: string, b: string) => {
  return a === b ? 0 : a > b ? 1 : -1;
};

export interface GridVariables<T> {
  sortProperty?: InputMaybe<T>;
  sortDirection: SortDirection;
  freeTextQuery?: InputMaybe<Array<string> | string>;
  filters?: InputMaybe<string>;
}

/* 
 * Deep comparision of grid query variables which is more reliable 
 * than comparing by using JSON.stringify() on both objects
 */
export const variablesAreEqual = <T,>(
  a: GridVariables<T>,
  b: GridVariables<T>
) => {
  let aKeys = Object.keys(a).sort();
  let bKeys = Object.keys(b).sort();

  // freetext is sometimes undefined, but we want to match
  // undefined with [''] or ''. all these are acceptable "empty" values
  if (!aKeys.includes('freeTextQuery')) {
    aKeys.push('freeTextQuery');
    aKeys = aKeys.sort();
  }
  if (!bKeys.includes('freeTextQuery')) {
    bKeys.push('freeTextQuery');
    bKeys = bKeys.sort();
  }

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  for (let i = 0; i < aKeys.length; i++) {
    if (aKeys[i] !== bKeys[i]) {
      return false;
    }
  }
  for (let i = 0; i < aKeys.length; i++) {
    const key = aKeys[i] as keyof GridVariables<T>;

    if (key === 'filters') {
      if (isSomething(a.filters) && isSomething(b.filters)) {
        const aFilters = (JSON.parse(a.filters) as IFilterGroup[]).sort(
          (fa, fb) => stringCompare(fa.field, fb.field)
        );
        const bFilters = (JSON.parse(b.filters) as IFilterGroup[]).sort(
          (fa, fb) => stringCompare(fa.field, fb.field)
        );

        if (aFilters.length !== bFilters.length) return false;

        for (let j = 0; j < aFilters.length; j++) {
          if (
            aFilters[j].field !== bFilters[j].field ||
            aFilters[j].exclude !== bFilters[j].exclude ||
            aFilters[j].type !== bFilters[j].type ||
            aFilters[j].partialMatch !== bFilters[j].partialMatch ||
            aFilters[j].filters.length !== bFilters[j].filters.length
          )
            return false;
          for (let k = 0; k < aFilters[j].filters.length; k++) {
            if (aFilters[j].filters[k].value !== bFilters[j].filters[k].value)
              return false;
          }
        }
      }
    } else if (key === 'freeTextQuery') {
      // count undefined as empty
      if (
        (a.freeTextQuery === undefined &&
          (b.freeTextQuery === '' ||
            (typeof b.freeTextQuery === 'object' &&
              b.freeTextQuery?.length === 1 &&
              b.freeTextQuery[0] === ''))) ||
        (b.freeTextQuery === undefined &&
          (a.freeTextQuery === '' ||
            (typeof a.freeTextQuery === 'object' &&
              a.freeTextQuery?.length === 1 &&
              a.freeTextQuery[0] === '')))
      ) {
        continue;
      }

      if (
        typeof a.freeTextQuery !== typeof b.freeTextQuery ||
        (typeof a.freeTextQuery === 'string' &&
          a.freeTextQuery !== b.freeTextQuery) ||
        (typeof a.freeTextQuery === 'object' &&
          a.freeTextQuery?.join?.('') !==
          (b.freeTextQuery as string[])?.join?.(''))
      ) {
        return false;
      }
    } else if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
};
