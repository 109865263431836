import React, { FC, memo, useEffect, useState } from 'react';
import { IReactGridFilter } from '$components/grid/react-grid/grid.react';
import { FilterList } from '$components/grid/react-grid/filter-list';
import { IFilterGroup } from '$interfaces/iFilter';
import {
  ElasticSearchPage,
  SiteGroupFilterDropdownQueryReact_siteFilters
} from '$typings/graphql';
import { IReactGroupedFilterDefinition } from '$pages/sitelistpage-react/modules/sitelist/sitelist.react';
import SitegroupFiltersDropdown from '$pages/common/sitegroup-filters-dropdown/sitegroup-filters-dropdown.react';
import FormElement from '$components/forms/form-element/form-element.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import ButtonGroup from '$components/button-group/button-group.react';
import Button from '$components/buttons/button.react';
import { getUserFeatures } from '../../../../config/sessionService';
import RequireConfirmation from '$components/require-confirmation/require-confirmation.react';
import PersistFilterModal from '$pages/sitelistpage-react/modules/persist-filters-modal/persist-filter-modal.react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { DeleteSiteFilterDocument } from '$typings/graphql-codegen';
import { isNone } from '$lib/helpers';
import { defaultSitelistFilters } from '$pages/sitelistpage-react/modules/sitelist-utils';
import { removeNoneFromArray } from '$lib/arrayHelpers';
import stringify from 'json-stable-stringify';

export const hasModifiedFilters = (filters: IFilterGroup[]) => {
   return stringify(filters) !== stringify(defaultSitelistFilters)
}

const tristateFilters: IReactGridFilter[] = [
  {
    name: 'ui_sitelist_filter_predefined_offline',
    property: 'isOffline'
  },
  {
    name: 'ui_sitelist_filter_predefined_lowlevel',
    property: 'isBelowMinimum'
  },
  {
    name: 'ui_sitelist_filter_predefined_alarms',
    property: 'hasAlarm'
  },
  {
    name: 'ui_sitelist_filter_predefined_virtual',
    property: 'isVirtual'
  },
  {
    name: 'ui_sitelist_filter_predefined_isparked',
    property: 'isParked'
  }
];

interface ISitelistFilterListProps {
  filtersChanged: (newFilters: IFilterGroup[]) => void;
  activeFilters?: IFilterGroup[];
  freeTextQuery: string[] | undefined;
  selectedFilterGroup?: SiteGroupFilterDropdownQueryReact_siteFilters;
  groupedFilterDefinitions: IReactGroupedFilterDefinition[];
  className?: string;
}

const SitelistFilterListComponent: FC<ISitelistFilterListProps> = ({
  groupedFilterDefinitions,
  activeFilters,
  filtersChanged,
  freeTextQuery,
  selectedFilterGroup,
  className
}: ISitelistFilterListProps) => {
  const [t] = useCaseInsensitiveTranslation();
  const features = getUserFeatures();
  const [filteredTristateFilters, setFilteredTristateFilters] = useState<
    IReactGridFilter[]
  >(tristateFilters);
  const [_selectedFilterGroup, setSelectedFilterGroup] = useState<
    SiteGroupFilterDropdownQueryReact_siteFilters | undefined
  >(selectedFilterGroup);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [persistFilters, setPersistFilters] = useState(false);
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [deleteError, setDeleteError] = useState<string>('');

  const [variablesHasChanged, setVariablesHasChanged] = useState(false);

  useEffect(() => {
    setVariablesHasChanged(
      !!activeFilters && hasModifiedFilters(activeFilters)
    );
  }, [activeFilters]);

  useEffect(() => {
    const lowerCaseQuery = filterQuery.toLowerCase();
    setFilteredTristateFilters(
      tristateFilters.filter(f =>
        t(f.name)
          .toLowerCase()
          .includes(lowerCaseQuery)
      )
    );
  }, [groupedFilterDefinitions, filterQuery]);

  const deleteSavedFilter = async (id?: string) => {
    if (id) {
      const onSuccess = () => {
        setDeleteConfirmation(false);
        setSelectedFilterGroup(undefined);
      };

      const onError = () => { 
        setDeleteError(t("ui_sitelist_delete_sitegroupfilter"))
      }

      await mutate(DeleteSiteFilterDocument, { filterId: id }, true, onSuccess, onError);
    }
  };

  const clearFilters = () => {
    setSelectedFilterGroup(undefined);
    filtersChanged && filtersChanged(defaultSitelistFilters);
  };

  const predefinedFiltersSelected = async (
    filter?: SiteGroupFilterDropdownQueryReact_siteFilters
  ) => {
    setSelectedFilterGroup(filter);

    if (isNone(filter)) {
      clearFilters();
      return;
    }

    const parsed = JSON.parse(filter.definition);
    filtersChanged && filtersChanged(parsed);
  };

  const getTristateFilterValue = (
    filterDefinition: IReactGridFilter,
    filters?: IFilterGroup[]
  ): boolean | undefined => {
    const filter = filters?.find(f => f.field === filterDefinition.property);
    if (!filter) return undefined;
    return !filter.exclude;
  };

  const toggleTristateFilter = (
    filterDefinition: IReactGridFilter,
    state?: boolean
  ) => {
    const newFilter: IFilterGroup | undefined = isNone(state)
      ? undefined
      : {
          field: filterDefinition.property,
          type: 'boolean',
          exclude: !state,
          filters: [{ value: true }]
        };
    const newFilters = removeNoneFromArray([
      ...(activeFilters?.filter(f => f.field !== filterDefinition.property) ||
        []),
      newFilter
    ]);
    filtersChanged && filtersChanged(newFilters);
  };

  const selectFilterGroup = (filter: SiteGroupFilterDropdownQueryReact_siteFilters) => {
    setSelectedFilterGroup(filter);
  }
  
  const setDefaultFilters = () => {
    filtersChanged && filtersChanged(defaultSitelistFilters);
  };

  const requireConfirmationCancel = () => {
    setDeleteConfirmation(false)
    setDeleteError("")
  }

  return (
    <>
      <FilterList
        setDefaultFilters={setDefaultFilters}
        filtersChanged={filtersChanged}
        freeTextQuery={freeTextQuery}
        filterQuery={filterQuery}
        setFilterQuery={setFilterQuery}
        groupedFilterDefinitions={groupedFilterDefinitions}
        activeFilters={activeFilters}
        className={className}
        page={ElasticSearchPage.Sitelist}
        variablesHasChanged={variablesHasChanged}
        slot2={
          <FormElement
            className="pad_h flex_0_0_auto"
            label={t('ui_sitelist_sitegroup_label_apply')}
            direction="column"
          >
            <SitegroupFiltersDropdown
              topElement={t('ui_sitelist_sitegroup_nogroup') as string}
              selected={_selectedFilterGroup}
              placeholder={t('ui_sitelist_sitegroup_select')}
              className="flex_1"
              itemClicked={({ filter }) => predefinedFiltersSelected(filter)}
            />
          </FormElement>
        }
        slot3={
          <>
            {filteredTristateFilters.map((definition, i) => {
              const value = getTristateFilterValue(definition, activeFilters);
              return (
                <div
                  key={definition.name + i}
                  className="flex jsb pad_m aicenter"
                >
                  <span className="flex_1">{t(definition.name)}</span>
                  <ButtonGroup className="flex_1 flex nowrap">
                    <Button
                      variant={value === true ? 'primary' : 'secondary'}
                      className="flex_1"
                      onClick={() => toggleTristateFilter(definition, true)}
                    >
                      Only
                    </Button>
                    <Button
                      variant={value === undefined ? 'primary' : 'secondary'}
                      className="flex_1"
                      onClick={() =>
                        toggleTristateFilter(definition, undefined)
                      }
                    />
                    <Button
                      variant={value === false ? 'primary' : 'secondary'}
                      className="flex_1"
                      onClick={() => toggleTristateFilter(definition, false)}
                    >
                      Hide
                    </Button>
                  </ButtonGroup>
                </div>
              );
            })}
            {_selectedFilterGroup &&
              (!_selectedFilterGroup.isPublic ||
                (features.publicFilters.delete &&
                  _selectedFilterGroup.isPublic)) && (
                <div className="pad_m">
                  <Button
                    variant="danger"
                    onClick={() => setDeleteConfirmation(true)}
                  >
                    {t('ui_sitelist_sitegroup_delete')}
                  </Button>
                </div>
              )}
          </>
        }
        rightButtonSlot={
          variablesHasChanged && (
            <Button
              variant="secondary"
              className="flex_1"
              onClick={() => setPersistFilters(true)}
            >
              {t('ui_sitelist_sitegroup_saveasgroup')}
            </Button>
          )
        }
      />
      {deleteConfirmation && (
        <RequireConfirmation
          onCancel={requireConfirmationCancel}
          onConfirm={() => deleteSavedFilter(_selectedFilterGroup?.filterId)}
          entityName={_selectedFilterGroup?.name}
          confirmType="delete"
          error={deleteError}
        />
      )}

      {persistFilters && (
        <PersistFilterModal
          activeFilters={activeFilters}
          onSaved={(filter) => selectFilterGroup(filter)}
          closeModal={() => setPersistFilters(false)}
        />
      )}
    </>
  );
};

export const SitelistFilterList = memo(SitelistFilterListComponent);
