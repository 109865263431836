// import { mutate } from '$lib/hooks/fetch-utillities';
// import { ControllersDetachDocument } from '$typings/graphql-codegen';
import React, { useState, memo, FC } from 'react';
import { MuiModalCard } from '$components/modals/mui-modal-card/mui-modal-card';
import { Button, IconButton } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import '../common-modal-styles.css';
import ErrorText from '$components/texts/error-text/error-text.react';
import { useIsMobile } from '$lib/hooks/isMobile';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { ControllerService } from '$services/controllerService';
import { revalidateAllActiveQueries } from '$pages/common/GraphQLFetcher';

interface IControllerDetachModalProps {
  open: boolean;
  controllerIds: number[];
  handleClose: () => unknown;
  aureliaControllerService: ControllerService;
}

const ControllerDetachModalComponent: FC<IControllerDetachModalProps> = ({
  controllerIds,
  open,
  handleClose,
  aureliaControllerService,
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useIsMobile();

  const onClose = () => {
    errors.length && setErrors([]);
    handleClose();
  };

  const doDetach = async () => {
    setLoading(true);
    await aureliaControllerService.detachControllers(controllerIds);
    await revalidateAllActiveQueries();
    setLoading(false);
    onClose();
  };

  // DO NOT DELETE!
  // reenable when SiteDetails is in react
  // const doDetach = async () => {
  //   errors.length && setErrors([]);
  //   setLoading(true);
  //   try {
  //     await mutate(
  //       ControllersDetachDocument,
  //       { controllerIds: [controllerId] },
  //       true,
  //       (result) => {
  //         if (!result.detachControllers.success) {
  //           setErrors([result.detachControllers.message]);
  //           setLoading(false);
  //           return;
  //         }
  //         onClose();
  //         setLoading(false);
  //       },
  //       (err) => {
  //         setErrors(err.message);
  //         setLoading(false);
  //       }
  //     );
  //   } catch (e) {
  //     const errors = e.response?.errors?.map((e: any) => e.message);
  //     errors && setErrors(errors);
  //     setLoading(false);
  //   }
  // };

  return (
    <MuiModalCard
      fullScreen={isMobile}
      open={open}
      handleClose={onClose}
      headerText={t('UI_RequireConfirmation_Header')}
      headerActions={
        <IconButton size="small" onClick={onClose}>
          <Icon name={'fa-times'} />
        </IconButton>
      }
      footerContent={
        <>
          <Button onClick={onClose} variant="outlined" type="button">
            {t('ui_common_cancel')}
          </Button>
          <Button
            onClick={doDetach}
            disabled={loading ? true : undefined}
            variant="contained"
            type="button"
            startIcon={
              loading && <Icon name={'fa-spinner fa-pulse'} className="text" />
            }
          >
            {t('UI_RequireConfirmation_ConfirmOkButton')}
          </Button>
        </>
      }
    >
      <div style={{ width: isMobile ? undefined : '400px' }} className="text-wrapper">
        <p>
          {t(
            'UI_ControllerManager_ControllerDetails_DetachControllersDialog_Body'
          )}
        </p>
        {errors.map((e) => (
          <ErrorText key={e}>{e}</ErrorText>
        ))}
      </div>
    </MuiModalCard>
  );
};

export const ControllerDetachModal = memo(ControllerDetachModalComponent);
