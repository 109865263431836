import { CardContent, styled } from "@mui/material";


// needs a child with overflow auto to be scrollable
export const ScrollableMuiCardContent = styled(CardContent)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-height: 100%;
  padding: 0;
  justify-content: space-between;

  &:last-child {
    padding-bottom: 0;
  }
`;
