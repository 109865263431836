import { ReactSiteStatusCardFragmentFragment, SiteListPageRowChannel } from "$typings/graphql-codegen";
import React, { Dispatch, FC, SetStateAction } from 'react';
import { ChannelTypes } from '$interfaces/enums';
import TankBar from "$pages/sitelistpage-react/modules/channeldisplay/tank-bar/tank-bar.react";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import { Card, CardContent, CardHeader, styled } from "@mui/material";
import SiteSignalComponent from "$components/site-signal/site-signal.react";
import { IMeasurementUnit, displayMeasurement } from "$lib/unitConverters";
import BatteryBar from "$pages/sitelistpage-react/modules/channeldisplay/battery-bar/battery-bar.react";
import "./sitelist-status-card.css"
import StockVsDoseBar from "$pages/sitelistpage-react/modules/channeldisplay/stock-vs-dose-bar/stock-vs-dose-bar.react";
import Banner from "$components/banners/banner/banner.react";
import { getUserFeatures } from "../../../../../src/config/sessionService";
import { ensureNumber } from "$lib/numberHelpers";
import classNames from "classnames";

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

interface ICardProps {
  alarm?: boolean
  parked?: boolean
  selected?: boolean
}

const CardWithPossibleBorder = styled(Card, {
  shouldForwardProp: (prop) => prop !== "alarm" && prop !== "parked" && prop !== "selected"
})<ICardProps>(({ alarm, parked, selected }) => ({
  border: selected ? "solid #ffb03b 2px" : parked ? "solid #5e8ab4 1px" : alarm ? "solid #bd0000 1px" : ""
}));

interface ISiteStatusCardComponentProps {
  site: ReactSiteStatusCardFragmentFragment
  selectConfig?: { setSelectedSiteId: Dispatch<SetStateAction<number | undefined>>, selectedSiteId: number | undefined }
}

const SiteStatusCardComponent: FC<ISiteStatusCardComponentProps> = ({ site, selectConfig }) => {
  const { siteId, alias, isVirtual, signal } = site
  const [t] = useCaseInsensitiveTranslation()

  const orderedChannelsByType = [
    ...site?.tankChannels || [],
    ...site?.stockVsDoseChannels5 || [],
    ...site?.stockVsDoseChannels25 || [],
    ...site?.batteryChannels || [],
    ...site?.flowChannels || [],
    ...site?.gasChannels || [],
    ...site?.doseChannels || [],
    ...site?.temperatureChannels || [],
    ...site?.conductivityChannels || []
  ];

  const channelView = (channel: SiteListPageRowChannel) => {
    switch (channel.channelType) {
      case ChannelTypes.Tank:
        return <TankChannelView channel={channel} />;
      case ChannelTypes.Battery:
        return <BatteryChannelView channel={channel} />;
      case ChannelTypes.H2SGas:
        return <H2sChannelView channel={channel} />;
      case ChannelTypes.StockVsDoseAccuracy:
        return <StockVsDoseChannelView channel={channel} />;
      case ChannelTypes.Dose:
        return <DoseChannelView channel={channel} />;
      case ChannelTypes.Temperature:
        return <TemperatureChannelView channel={channel} />;
      case ChannelTypes.Flow:
        return <FlowChannelView channel={channel} />;
      case ChannelTypes.Conductivity:
        return <ConductivityChannelView channel={channel} />;
      default:
        return <GenericChannelView channel={channel} />       
    }
  }

  const siteIsParked = site.isParked
  const siteHasAlarm = site.hasActiveChannelAlarms && !site.isParked

  const component = <CardWithPossibleBorder elevation={1} sx={{ marginTop: "1rem" }} alarm={siteHasAlarm} parked={siteIsParked} selected={ensureNumber(siteId) === selectConfig?.selectedSiteId} onClick={() => selectConfig?.setSelectedSiteId(ensureNumber(siteId))} >
    <CardHeader titleTypographyProps={{ variant: 'h6' }}
      title={alias} action={<SiteSignalComponent isVirtual={isVirtual} siteSignal={signal} />} />
    {siteIsParked && <Banner type="parked" svg="parked">
      {t('UI_SiteDetails_Details_StatusCard_AllChannelsParked')}
    </Banner>}
    {siteHasAlarm && <Banner type="error" className={classNames(getUserFeatures().alarms.read && 'clickable')} onClick={() => { console.log("here") }}>
      {t("UI_SiteList_Mobile_StatusCard_HasActiveAlarms")}
    </Banner>}
    {orderedChannelsByType.map((channel: SiteListPageRowChannel, i) => (
      <CardContentNoPadding key={i} className="channel-row-wrapper">
        <div className="flex_1 flex row channel-row">
          {channelView(channel)}
        </div>
      </CardContentNoPadding>)
    )}
    {!orderedChannelsByType.length && <CardContent className="no-top-padding">
      <span>{t("UI_SiteList_Mobile_StatusCard_NoChannels")}</span>
    </CardContent>}
  </CardWithPossibleBorder>

  if (selectConfig)
    return (
      <div className="sitelist-site-status-card-component">
        {component}
      </div>
    )

  return (
    <a href={`/sitedetails/${siteId}`} className="sitelist-site-status-card-component" style={{ textDecoration: "inherit", color: "inherit" }}>
      {component}
    </a >)
}

export default SiteStatusCardComponent

interface IChannelView {
  channel: SiteListPageRowChannel
}

const removeBlank = (s?: string | null) => {
  if (!s || s === '<blank>')
    return '';
  
  return s;
}

const TankChannelView: FC<IChannelView> = ({ channel }) => {
  const [t] = useCaseInsensitiveTranslation()
  return (<div className="flex">
    <div className="channel-name"><p className="trunc">{channel.alias}</p></div>
    {(!channel.tankDetails || !channel.lastSample) && <div className="no-data">
      {t('UI_Sitelist_Channel_NoData')}
    </div>}
    {channel.tankDetails && !!channel.lastSample && <div className="flex_2 pad_m flex column oh">
      <TankBar
        lastSampleTime={channel.lastSampleTime}
        percentage={channel.percentage}
        minimum={channel.minimum}
        maximum={channel.maximum}
        capacity={channel.capacity}
        lastSample={channel.lastSample}
        reachMinimum={channel.tankDetails.reachMinimum}
      />
      <div className="detailed-sub-text">
        {displayMeasurement(channel.lastSample, channel.unit as IMeasurementUnit, false)} / {displayMeasurement(channel.capacity, channel.unit as IMeasurementUnit, true)} {t("UI_Common_with")} {channel.product?.name || 'unknown product'}
      </div>
    </div>}
  </div>)
}

const BatteryChannelView: FC<IChannelView> = ({ channel }) => {
  const [t] = useCaseInsensitiveTranslation()
  return (<div className="flex">
    <div className="channel-name"><p className="trunc">{channel.alias}</p></div>
    {!channel.lastSample && <div className="no-data">
      {t('UI_Sitelist_Channel_NoData')}
    </div>}
    {channel.lastSample && <div className="flex_2 pad_m flex oh jcflexend">
      <span className="pad_rs">{channel.lastSample} %</span>
      {typeof channel.percentage === "number" && <BatteryBar percentage={channel.percentage} />}
    </div>}
  </div>
  );
}

const H2sChannelView: FC<IChannelView> = ({ channel }) => {
  const [t] = useCaseInsensitiveTranslation()
  return (<div className="flex" style={{ height: "65px" }}>
    <div className="channel-name"><p className="trunc">{channel.alias}</p>
      <div className="sub-text">{removeBlank(channel.unit?.symbol)}</div>
    </div>
    {!channel.gasDetails && <div className="no-data">
      {t('UI_Sitelist_Channel_NoData')}
    </div>}
    {channel.gasDetails && <div className="flex_4 pad_m flex aicenter jsb">
      <div className="flex column jccenter aicenter">
        <div className="pad_bs">{channel.gasDetails.percentTimeAboveLimit} %</div>
        <div className="sub-text">&gt;{channel.maximum || 5} {removeBlank(channel.unit?.symbol)}</div>
      </div>
      <div className="flex column jscenter aicenter">
        <div className="pad_bs">{displayMeasurement(channel.gasDetails.meanPpm, channel.unit as IMeasurementUnit, false)}</div>
        <div className="sub-text">{t("UI_SiteList_Channel_Label_mean")}</div>
      </div>
      <div className="flex column jscenter aicenter">
        <div className="pad_bs">{displayMeasurement(channel.gasDetails.maxPpm, channel.unit as IMeasurementUnit, false)}</div>
        <div className="sub-text">{t("UI_SiteList_Channel_Label_max")}</div>
      </div>
    </div>}

  </div>);
}

const StockVsDoseChannelView: FC<IChannelView> = ({ channel }) => {
  const [t] = useCaseInsensitiveTranslation()
  const unit = channel.stockVsDoseAccuracyChannelDetails?.aggregatedTankUsageChannel?.unit
  return (<div className="flex">
    <div className="channel-name"><p className="trunc">{channel.alias}</p></div>
    {!channel.stockVsDoseAccuracyChannelDetails && <div className="no-data">
      {t('UI_Sitelist_Channel_NoData')}
    </div>}
    {channel.stockVsDoseAccuracyChannelDetails && <div className="flex_2 pad_m flex column oh">
      {typeof channel.lastSample === "number" && <StockVsDoseBar accuracy={channel.lastSample} className="pad_bs" />}
      <div className="detailed-sub-text">
        {t('UI_ChannelTypes_Tank')}: {displayMeasurement(channel.stockVsDoseAccuracyChannelDetails.aggregatedTankUsageChannel?.lastSample, unit as IMeasurementUnit, true)},
        {t('UI_ChannelTypes_Dose')}: {displayMeasurement(channel.stockVsDoseAccuracyChannelDetails.aggregatedDoseChannel?.lastSample, unit as IMeasurementUnit, true)}
      </div>
    </div>}
  </div>
  )
}

const DoseChannelView: FC<IChannelView> = ({ channel }) => {
  const [t] = useCaseInsensitiveTranslation()
  const unitWithoutInterval = channel.unit?.unitWithoutInterval
  const unit = channel.unit

  return (<div className="flex" style={{ height: "65px" }}>
    <div className="channel-name"><p className="trunc">{channel.alias}</p></div>
    {!channel.doseDetails && <div className="no-data">
      {t('UI_Sitelist_Channel_NoData')}
    </div>}
    {channel.doseDetails && <div className="flex_4 pad_m flex aicenter jsb">
      <div className="flex column jccenter aicenter">
        <div className="pad_bs">{displayMeasurement(channel.doseDetails.intervalSum, unitWithoutInterval as IMeasurementUnit, true)}</div>
        <div className="sub-text">{t("UI_SiteList_Channel_Label_sum")}</div>
      </div>
      <div className="flex column jscenter aicenter">
        <div className="pad_bs">
          {displayMeasurement(channel.doseDetails?.intervalMin, unit as IMeasurementUnit, false)}
          <span className="sub-text">/</span>
          {displayMeasurement(channel.doseDetails?.intervalMean, unit as IMeasurementUnit, false)}
          <span className="sub-text">/</span>
          {displayMeasurement(channel.doseDetails?.intervalMax, unit as IMeasurementUnit, true)}
        </div>
        <div className="sub-text">{t("UI_SiteList_Channel_Label_min")} / {t("UI_SiteList_Channel_Label_mean")} / {t("UI_SiteList_Channel_Label_max")}</div>
      </div>
    </div >}
  </div>)
}

const TemperatureChannelView: FC<IChannelView> = ({ channel }) => {
  const [t] = useCaseInsensitiveTranslation()
  return (<div className="flex">
    <div className="channel-name"><p className="trunc">{channel.alias}</p></div>
    {!channel.temperatureChannelDetails && <div className="no-data">
      {t('UI_Sitelist_Channel_NoData')}
    </div>}
    {channel.temperatureChannelDetails && <div className="flex_4 pad_m flex aicenter jsb">
      <div className="flex column jccenter aicenter">
        <div className="pad_bs pad_h" >{displayMeasurement(channel.lastSample, channel.unit as IMeasurementUnit, true)}</div>
        <div className="sub-text">{t("UI_SiteList_Channel_Label_last")}</div>
      </div>
      <div className="flex column jccenter aicenter">
        <div className="pad_bs pad_h" >{displayMeasurement(channel.temperatureChannelDetails.intervalMean, channel.unit as IMeasurementUnit, true)}</div>
        <div className="sub-text">{t("UI_SiteList_Channel_Label_mean")}</div>
      </div>
      <div className="flex column jccenter aicenter">
        <div className="pad_bs pad_h">{displayMeasurement(channel.temperatureChannelDetails.intervalMax, channel.unit as IMeasurementUnit, true)}</div>
        <div className="sub-text">{t("UI_SiteList_Channel_Label_max")}</div>
      </div>
    </div>}
  </div>)
}


const FlowChannelView: FC<IChannelView> = ({ channel }) => {
  const [t] = useCaseInsensitiveTranslation()
  return (<div className="flex" style={{ height: "65px" }}>
    <div className="channel-name"><p className="trunc">{channel.alias}</p>
      <div className="sub-text">{removeBlank(channel.unit?.symbol)}</div>
    </div>
    {!channel.flowChannelDetails && <div className="no-data">
      {t('UI_Sitelist_Channel_NoData')}
    </div>}
    {channel.flowChannelDetails && <div className="flex_4 pad_m flex aicenter jsb">
      <div className="flex column jccenter aicenter">
        <div className="pad_bs">{displayMeasurement(channel.flowChannelDetails.intervalSum, channel.unit as IMeasurementUnit, false)}</div>
        <div className="sub-text">{t("UI_SiteList_Channel_Label_sum")}</div>
      </div>
      <div className="flex column jscenter aicenter">
        <div className="pad_bs">{displayMeasurement(channel.flowChannelDetails.intervalMin, channel.unit as IMeasurementUnit, false)}</div>
        <div className="sub-text">{t("UI_SiteList_Channel_Label_min")}</div>
      </div>
      <div className="flex column jscenter aicenter">
        <div className="pad_bs">{displayMeasurement(channel.flowChannelDetails.intervalMean, channel.unit as IMeasurementUnit, false)}</div>
        <div className="sub-text">{t("UI_SiteList_Channel_Label_mean")}</div>
      </div>
      <div className="flex column jscenter aicenter">
        <div className="pad_bs">{displayMeasurement(channel.flowChannelDetails.intervalMax, channel.unit as IMeasurementUnit, false)}</div>
        <div className="sub-text">{t("UI_SiteList_Channel_Label_max")}</div>
      </div>
    </div>}
  </div>)
}

const ConductivityChannelView: FC<IChannelView> = ({ channel }) => {
  const [t] = useCaseInsensitiveTranslation()
  return (
    <div className="flex">
      <div className="channel-name"><p className="trunc one-line">{channel.alias}</p></div>
      {!channel.conductivityChannelDetails && <div className="no-data">
        {t('UI_Sitelist_Channel_NoData')}
      </div>}
      {channel.conductivityChannelDetails && <div className="flex_2 pad_m flex aicenter jcaround">
        <div className="flex column jccenter aicenter">
          <div className="pad_bs pad_h" >{displayMeasurement(channel.lastSample, channel.unit as IMeasurementUnit, true)}</div>
          <div className="sub-text">{t("UI_SiteList_Channel_Label_last")}</div>
        </div>
      </div>}
    </div>
  )
}

const GenericChannelView: FC<IChannelView> = ({ channel }) => {
  const [t] = useCaseInsensitiveTranslation()
  return (
    <div className="flex">
      <div className="channel-name"><p className="trunc one-line">{channel.alias}</p></div>

      {!channel.lastSample && <div className="no-data">
        {t('UI_Sitelist_Channel_NoData')}
      </div>}

      {channel.lastSample && <div className="flex_2 pad_m flex aicenter jcaround">
        <div className="flex column jccenter aicenter">
          <div className="pad_bs pad_h" >{displayMeasurement(channel.lastSample, channel.unit as IMeasurementUnit, true)}</div>
          <div className="sub-text">{t("UI_SiteList_Channel_Label_last")}</div>
        </div>
      </div>}
    </div>
  )
}
