import React, {FC} from "react";

const Map: FC<React.HTMLAttributes<HTMLElement>> = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="12 8 28 28"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={'feather feather-map ' + className ?? ''}>
    <g strokeWidth="1.2">
      <path
        d="M13.11401415 15.6199522v16l7-4 8.00000095 4 7-4v-16l-7 4-8.00000095-4-7 4zM20.11401 11.50119v16M28.11401 15.61995v16"/>
    </g>
    <g strokeWidth="1.2" stroke="red" fill="#fff">
      <path
        d="M39.28979 27.14692c0 3.48564-4.57601 6.47333-4.57601 6.47333s-4.57601-2.98769-4.57601-6.47333a4.57601 4.48154 0 0 1 9.15202 0z"/>
      <circle cx="34.85973" cy="27.23758" r="1.49385"/>
    </g>
  </svg>
)

export default Map
