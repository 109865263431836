import Icon from '$components/icons/icon/icon.react';
import flatpickr from 'flatpickr';
import React, {FC, useRef, useEffect, MouseEventHandler, HTMLAttributes} from 'react';
import './date-picker.css';
import 'flatpickr/dist/flatpickr.min.css';
import { isNone } from '$lib/helpers';
import { ensureDate } from '$lib/dateHelpers';
import classNames from "classnames";
import RenderIf from "$components/render-if/render-if";
import { Instance } from 'flatpickr/dist/types/instance';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import "flatpickr/dist/plugins/monthSelect/style.css"

interface IDatePickerProps extends HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  placeholder?: string;
  size?: string;
  inline?: boolean;
  showTimeSelector?: boolean;
  showRange?: boolean;
  selectedDate?: Date | string;
  selectedDateFrom?: Date | string;
  selectedDateTo?: Date | string;
  minimumDate?: Date;
  maximumDate?: Date;
  onChanged?: (selectedDate: Date, selectedDates: Date[]) => void;
  onClick?: MouseEventHandler<any> | undefined
  onClear?: () => void
  monthsAndYearOnly?: boolean
}

const DatePicker: FC<IDatePickerProps> = ({
  disabled,
  placeholder,
  size = 'medium',
  inline = false,
  showTimeSelector = false,
  showRange = false,
  selectedDate,
  selectedDateFrom,
  selectedDateTo,
  minimumDate,
  maximumDate,
  onChanged,
  onClick,
  onClear,
  monthsAndYearOnly,
  ...props
}) => {
  const datePicker = useRef<HTMLInputElement | null>(null);
  let flatpickrCtrl = useRef<Instance | null>(null);

  const getDefaultDate = (): Date | Date[] | undefined => {
    if (showRange && !isNone(selectedDateFrom) && !isNone(selectedDateTo)) return [ensureDate(selectedDateFrom), ensureDate(selectedDateTo)];
    if (!showRange && !isNone(selectedDate)) return ensureDate(selectedDate);
    return;
  };

  useEffect(() => {
    if (datePicker?.current) {
      flatpickrCtrl.current = flatpickr(datePicker?.current, {
        time_24hr: true,
        enableTime: showTimeSelector,
        mode: showRange ? 'range' : 'single',
        dateFormat: showTimeSelector ? 'd.m.Y H:i' : 'd.m.Y',
        defaultMinute: 0,
        inline: inline,
        minDate: minimumDate,
        maxDate: maximumDate,
        minuteIncrement: 5,
        defaultDate: getDefaultDate(),
        plugins: monthsAndYearOnly ? [
          monthSelectPlugin({
            shorthand: true,
            dateFormat: "M y",
          })
        ] : [],
        onChange: selectedDates => {
          if (!selectedDates.length) return;
          if (selectedDates && selectedDates[0] && onChanged) onChanged(selectedDates[0], selectedDates);
          if (!showRange) selectedDate = selectedDates[0];
        }
      });
    }

    return () => flatpickrCtrl.current?.destroy();
  }, []);

  useEffect(() => {
    if (isNone(flatpickrCtrl.current)) return;
    if (isNone(selectedDateTo) || isNone(selectedDateFrom)) flatpickrCtrl.current?.clear();
    else flatpickrCtrl.current?.setDate([ensureDate(selectedDateFrom), ensureDate(selectedDateTo)]);
  }, [selectedDateTo]);

  useEffect(() => {
    if (isNone(flatpickrCtrl.current)) return;
    if (isNone(selectedDateFrom)) flatpickrCtrl.current?.clear();
    else flatpickrCtrl.current?.setDate([ensureDate(selectedDateFrom)]);
  }, [selectedDateFrom]);

  useEffect(() => {
    if (isNone(flatpickrCtrl.current)) return;
    if (isNone(selectedDate)) flatpickrCtrl.current?.clear();
    else flatpickrCtrl.current?.setDate(ensureDate(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    if (flatpickrCtrl.current !== undefined) flatpickrCtrl.current?.set('maxDate', maximumDate);
  }, [maximumDate]);

  useEffect(() => {
    if (flatpickrCtrl.current !== undefined) flatpickrCtrl.current?.set('minDate', minimumDate);
  }, [minimumDate]);

  /**
   * Clear input
   */
  function clearInput(): void {
    if (datePicker.current?.value) {
      datePicker.current.value = ''
    }
    flatpickrCtrl.current?.clear()
    onClear && onClear()
  }

  /**
   * Listen to key press
   * @param e
   */
  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    switch (e.key) {
      case 'Backspace':
      case 'Delete':
        clearInput()
        break
    }
  }

  return (
    <div
      onClick={onClick}
      className={classNames(
        'cursor-pointer',
        'btn-default',
        disabled && 'disabled'
      )}>
      <label
        className={classNames(
          'component-datepicker',
          size && `component-datepicker-${size}`,
          inline && 'inline',
          disabled && 'disabled'
        )}>
        <RenderIf if={inline === undefined || !inline}>
          <input
            ref={datePicker}
            style={props.style}
            type="text"
            disabled={disabled}
            placeholder={placeholder}
            onClick={onClick}
            onKeyDown={onKeyDown}
          />

          <Icon
            name={'fa-calendar'}
            className="cursor-pointer"/>
        </RenderIf>
      </label>
    </div>
  );
};

export default DatePicker;
