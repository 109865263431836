import React, { FC, memo } from 'react';
import './mobile-tab-item.css';
import classNames from 'classnames';

interface IMobileTabItemProps {
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
}

const MobileTabItemComponent: FC<IMobileTabItemProps> = ({
  active = false,
  disabled = false,
  children,
  onClick,
  className,
}) => (
  <div
    className={classNames(
      'mobile-tab-item-component',
      'radio-button-tabs-item',
      active && 'radio-button-tabs-item--active',
      disabled ? 'disabled' : 'clickable',
      className
    )}
    onClick={onClick}
  >
    {children}
  </div>
);
export const MobileTabItem = memo(MobileTabItemComponent);
