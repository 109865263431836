import Dropdown from '$components/dropdowns/dropdown/dropdown.react';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { SiteGroupFilterDropdownQueryReact_siteFilters } from '$typings/graphql';
import { SiteGroupFilterDropdownQueryReactDocument } from '$typings/graphql-codegen';
import React, { FC, useState, useEffect } from 'react';
import classNames from "classnames";

interface ISitegroupFiltersDropdownProps {
  filteringRule?: 'public' | 'private';
  selected?: SiteGroupFilterDropdownQueryReact_siteFilters | string;
  itemClicked?: (params: { value: string | undefined; text: string; filter: SiteGroupFilterDropdownQueryReact_siteFilters | undefined }) => void;
  topElement?: string | boolean | 'IF_QUERY';
  placeholder?: string;
  required?: boolean;
  className?: string
  error?: boolean
}

const SitegroupFiltersDropdown: FC<ISitegroupFiltersDropdownProps> = ({
  filteringRule,
  selected,
  itemClicked,
  topElement = undefined,
  placeholder,
  className,
  error
}) => {
  const { data, loading } = useQuery(SiteGroupFilterDropdownQueryReactDocument);
  const [filters, setFilters] = useState<SiteGroupFilterDropdownQueryReact_siteFilters[] | undefined>();

  useEffect(() => {
    if (!data || !data.siteFilters) return setFilters(undefined);
    const { siteFilters } = data;
    if (!filteringRule) return setFilters(siteFilters);
    return setFilters(filteringRule === 'public' ? siteFilters.filter(sf => sf.isPublic) : siteFilters.filter(sf => !sf.isPublic));
  }, [data, filteringRule]);

  function clickedItem(value: string | undefined, text: string) {
    const filter = data?.siteFilters?.find(f => f.filterId === value);

    itemClicked && itemClicked({ text, value, filter });
  }  

  return (
    <div className={classNames(className)}>
      <Dropdown<SiteGroupFilterDropdownQueryReact_siteFilters>
        items={filters}
        displayPath="name"
        selected={selected}
        valuePath="filterId"
        loading={loading}
        itemClicked={params => clickedItem(params?.value?.toString(), params?.text.toString() || '')}
        placeholder={placeholder}
        topElement={topElement}
        error={error}
      ></Dropdown>
    </div>
  );
};

export default SitegroupFiltersDropdown;
