import Icon from '$components/icons/icon/icon.react';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import React, { FC } from 'react';
import './empty-component.css';
import classNames from "classnames";

interface IEmptyComponentProps {
  icon?: string;
  image?: string;
  loadingicon?: boolean;
  animated?: boolean;
  logoStretchesVertically?: boolean;
}

const EmptyComponent: FC<IEmptyComponentProps> = ({ icon, image, loadingicon = false, animated = true, logoStretchesVertically = true, children }) => (
  <div className='empty-component-wrapper'>
    <div className="page-empty-data flex">
      {(image || icon || loadingicon) && (
        <div className={classNames(
          'page-empty-data-logo',
          'flex',
          !logoStretchesVertically ? 'flex_0_0_auto' : 'flex_1',
          animated && 'animated')}>
          {icon && <Icon name={icon} />}
          {image && <img src={image} />}
          {loadingicon && <LoadingBars />}
        </div>
      )}
      {children}
    </div>
  </div>
);

export default EmptyComponent;
