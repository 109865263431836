import { SortDirection } from '$typings/graphql-codegen';
import React, { FC } from 'react';
import classNames from "classnames";

interface ISortIconProps {
  sortDirection: SortDirection;
  active: boolean;
  className?: string;
}

const SortIcon: FC<ISortIconProps> = ({ sortDirection, active, className }) => (
  <span className={classNames(
    'sort-icon',
    active && 'active',
    sortDirection !== SortDirection.Asc && 'reversed',
    className
  )}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '20px' }}>
      <path
        className="secondary"
        d="M18 13v7a1 1 0 0 1-2 0v-7h-3a1 1 0 0 1-.7-1.7l4-4a1 1 0 0 1 1.4 0l4 4A1 1 0 0 1 21 13h-3z"
        style={{ fill: '#fff' }}
      />
      <path
        className="primary"
        d="M3 3h13a1 1 0 0 1 0 2H3a1 1 0 1 1 0-2zm0 4h9a1 1 0 0 1 0 2H3a1 1 0 1 1 0-2zm0 4h5a1 1 0 0 1 0 2H3a1 1 0 0 1 0-2z"
        style={{ fill: '#ccc' }}
      />
    </svg>
  </span>
);

export default SortIcon;
