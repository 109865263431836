import React from "react";
import { ThemeProvider } from "@mui/material";
import theme from "./yara-theme";


const MuiThemeProvider = (props: any) => (
  <ThemeProvider theme={theme} {...props}/>
);

export default MuiThemeProvider;

