import ButtonGroup from '$components/button-group/button-group.react';
import Button from '$components/buttons/button.react';
import Checkbox from '$components/checkbox/checkbox.react';
import Pill from '$components/pill/pill.react';
import DatePicker from '$components/date-picker/date-picker.react';
import { isNone, lastTwo } from '$lib/helpers';
import React, { FC, useState, MouseEvent } from 'react';
import { ConditionalOperator, IDateFilter, IFilterGroup } from '../../../../../interfaces';
import { ensureDate, formatDate } from '$lib/dateHelpers';
import { IReactGridFilterComponentProps } from '$components/grid/react-grid/grid.react';
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";

type IOperations = 'equals' | 'lt' | 'gt' | 'between';

const DateFilter: FC<IReactGridFilterComponentProps> = ({ activeFilters, filterChanged, property }) => {
  const activeFilter = activeFilters?.find(f => {
    return f.field === property;
  })
  
  const filterGroup = activeFilter ?? {
    exclude: false,
    field: property,
    filters: [],
    type: 'string',
    partialMatch: true,
    showEmpty: false
  };

  const [t] = useCaseInsensitiveTranslation();
  const [operation, setOperation] = useState<IOperations>('equals');
  const [value1, setValue1] = useState<Date | undefined>();
  const [value2, setValue2] = useState<Date | undefined>();

  function validateValues(value1: Date | string | undefined, value2: Date | string | undefined, operation: IOperations): boolean {
    const value1IsNotDefined = isNone(value1) || value1.toString() === '';
    const value2IsNotDefined = isNone(value2) || value2.toString() === '';
    return operation === 'between' ? !value1IsNotDefined && !value2IsNotDefined : !value1IsNotDefined;
  }

  function getDateAsStringWithoutTimezone(date: string | Date) {
    if (date === undefined) return date;

    const converted = ensureDate(date);
    const isoString = `${converted.getFullYear()}-${lastTwo(converted.getMonth() + 1)}-${lastTwo(converted.getDate())}T${lastTwo(
      converted.getHours()
    )}:${lastTwo(converted.getMinutes())}:${lastTwo(converted.getSeconds())}`;
    return isoString;
  }

  function getNewFilter(): IDateFilter {
    if (!validateValues(value1, value2, operation) || isNone(value1)) throw Error('Value1 or value2 is not defined.');

    switch (operation) {
      case 'equals':
        return { operator: ConditionalOperator.Equals, value: getDateAsStringWithoutTimezone(value1), symbol: '=' };
      case 'gt':
        return { operator: ConditionalOperator.GreaterThan, value: getDateAsStringWithoutTimezone(value1), symbol: '>' };
      case 'lt':
        return { operator: ConditionalOperator.LesserThan, value: getDateAsStringWithoutTimezone(value1), symbol: '<' };
      case 'between':
        return {
          operator: ConditionalOperator.Between,
          value: getDateAsStringWithoutTimezone(value1),
          secondValue: value2 === undefined ? undefined : getDateAsStringWithoutTimezone(value2),
          symbol: '&&'
        };
    }
  }

  function addFilter() {
    const filter = getNewFilter();

    const newfilter: IFilterGroup = {
      ...(activeFilter || { exclude: false, field: property, type: 'date', filters: [], partialMatch: true }),
      filters: activeFilter ? [...(activeFilter.filters as IDateFilter[]), filter] : [filter]
    };

    filterChanged && filterChanged(newfilter);
    setValue1(undefined);
    setValue2(undefined);
  }

  const BLANK_DATE: Date = new Date(1970, 0, 1);

  const toogleBlank = (showEmpty : boolean) => {    
    filterGroup.showEmpty = showEmpty;

    const emptyFilter : IFilterGroup = {
      exclude: false, 
      field: property, 
      type: 'date', 
      partialMatch: true, 
      showEmpty: false,
      filters: []
    };

    let newFilter: IFilterGroup = emptyFilter;

    if (showEmpty) {
      newFilter = {
        ...emptyFilter,
        showEmpty: true,
        filters: [{value: BLANK_DATE, operator: ConditionalOperator.Equals, symbol: '='}],
      }
    } 
    
    filterChanged && filterChanged(newFilter);
    setValue1(undefined);
    setValue2(undefined);
  }

  function removeFilter(filterToRemove: IDateFilter, event: MouseEvent) {
    if (!activeFilter) return;
    event.preventDefault();
    const newfilter: IFilterGroup = { ...activeFilter, filters: (activeFilter.filters as IDateFilter[]).filter(f => f !== filterToRemove) };
    filterChanged && filterChanged(newfilter);
  }

  function getTextForFilter(filter: IDateFilter) {
    if (ensureDate(filter.value).getDate() == BLANK_DATE.getDate())
      return '<blank>';

    const format = (date: Date | undefined | string) => formatDate(date, false, '.');
    switch (filter.operator) {
      case ConditionalOperator.Between:
        return t('ui_sitelist_filter_date_between') + ` ${format(filter.value)} and ${format(filter.secondValue)}`;
      case ConditionalOperator.Equals:
        return `${format(filter.value)}`;
      case ConditionalOperator.GreaterThan:
        return t('ui_sitelist_filter_date_after') + ` ${format(filter.value)}`;
      case ConditionalOperator.LesserThan:
        return t('ui_sitelist_filter_date_before') + ` ${format(filter.value)}`;
    }
  }

  return (
    <div className="flex_1">
      <ButtonGroup className="mar_bm flex height-auto">
        <Button disabled={filterGroup.showEmpty} className="flex_1" variant={operation === 'equals' ? 'primary' : 'secondary'} onClick={() => setOperation('equals')}>
          {t('ui_sitelist_filter_date_on')}
        </Button>
        <Button disabled={filterGroup.showEmpty} className="flex_1" variant={operation === 'lt' ? 'primary' : 'secondary'} onClick={() => setOperation('lt')}>
          {t('ui_sitelist_filter_date_before')}
        </Button>
        <Button disabled={filterGroup.showEmpty} className="flex_1" variant={operation === 'gt' ? 'primary' : 'secondary'} onClick={() => setOperation('gt')}>
          {t('ui_sitelist_filter_date_after')}
        </Button>
        <Button disabled={filterGroup.showEmpty} className="flex_1" variant={operation === 'between' ? 'primary' : 'secondary'} onClick={() => setOperation('between')}>
          {t('ui_sitelist_filter_date_between')}
        </Button>
      </ButtonGroup>

      {operation !== 'between' && (
        <div className="pad_b">
          <DatePicker disabled={filterGroup.showEmpty} placeholder={t('ui_sitelist_filter_date_date')} onChanged={selectedDate => setValue1(selectedDate)} selectedDate={value1} />
        </div>
      )}
      {operation === 'between' && (
        <div className="flex oh pad_b">
          <DatePicker placeholder={t('ui_sitelist_filter_date_fromdate')} onChanged={selectedDate => setValue1(selectedDate)} selectedDate={value1} />
          <div className="center-content pad_m nowrap">{t('ui_common_and')}</div>
          <DatePicker placeholder={t('ui_sitelist_filter_date_todate')} onChanged={selectedDate => setValue2(selectedDate)} selectedDate={value2} />
        </div>
      )}

      <div className="pad_t pad_b">
        <Checkbox
          text="Show blank"
          checked={filterGroup.showEmpty}
          onChange={() => {                                          
              toogleBlank(!filterGroup.showEmpty ?? false);
            }
          }
        ></Checkbox>
      </div>

      <Button className="pad_b" disabled={!validateValues(value1, value2, operation)} onClick={() => addFilter()}>
        {t('ui_sitelist_filter_add')}
      </Button>

      <div className="flex wrap">
        {activeFilter?.filters.map((f,i) => (
          <Pill key={"dateFilterPill" + i} className="mar_rs mar_bs" iconClicked={e => removeFilter(f as IDateFilter, e)}>
            {getTextForFilter(f as IDateFilter)}
          </Pill>
        ))}
      </div>
    </div>
  );
};

export default DateFilter;
