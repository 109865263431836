import React, { FC, useRef, useEffect } from 'react';
import './textbox-multiline.css';
import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';
import classNames from 'classnames';

interface ITextboxMultilineProps {
  className?: string
  placeholder?: string;
  onEnter?: (value: string) => void;
  restrictResizeTo?: 'horizontal' | 'vertical' | 'no-resize';
  shiftEnterAsNewline?: boolean;
  minHeight?: string;
  autofocus?: boolean;
  onChange?: (value: string) => void;
  onBlur?: ({}) => void;
  value?: string;
  rows?: number;
  cols?: number;
  inline?: boolean;
  straightRight?: boolean;
  disabled?: boolean;
}

const TextboxMultiline: FC<ITextboxMultilineProps> = ({
  className,
  placeholder,
  onEnter,
  restrictResizeTo,
  shiftEnterAsNewline = false,
  minHeight,
  autofocus,
  onChange,
  onBlur,
  value = '',
  rows,
  cols,
  inline,
  straightRight,
  disabled
}) => {
  const inputelement = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (!disabled) {
      if (autofocus) {
        inputelement.current?.focus();
      }
    }
    inputelement.current?.addEventListener('keydown', keyPress);

    return () => {
      inputelement.current?.removeEventListener('keydown', keyPress);
    };
  }, []);

  function callOnBlur() {
    if (!onBlur) return;
    onBlur({ value: value });
  }

  const keyPress = (event: KeyboardEvent) => {
    if (shiftEnterAsNewline && !event.shiftKey && event.key === "Enter") {
      event.preventDefault();
      onEnter && onEnter((event.target as HTMLTextAreaElement).value);
    }
  };

  return (
    <div className={classNames('textbox-multiline-component', className)}>
      <div className="textbox-multiline-wrapper">
        <textarea
          placeholder={placeholder || ''}
          className={classNames(
            (restrictResizeTo === 'no-resize') && 'no-resize',
            (restrictResizeTo === 'horizontal') && 'resize-restrict-horizontal',
            (restrictResizeTo === 'vertical') && 'resize-restrict-vertical',
            inline && 'inline ',
            disabled && 'disabled',
            straightRight && 'straight-right'
          )}
          value={value}
          disabled={disabled}
          rows={rows}
          cols={cols}
          onBlur={callOnBlur}
          ref={inputelement}
          style={{ minHeight: minHeight ? minHeight : 'auto' }}
          onChange={(e) => onChange && onChange(e.target.value)}
        ></textarea>
        {value && value.length > 0 && (
          <ClickableIcon
            className="closeIcon reacticon"
            name="fa-times"
            //straight-left="true"
            onClick={() => onChange && onChange("")}
          ></ClickableIcon>
        )}
      </div>
    </div>
  );
};

export default TextboxMultiline;
