import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { requests } from '../config';
import { fetchingLoadingPoints } from '../actions';
import { emptyObject, getEntityOrUndefined, getAllEntities } from '../utility';
import { getLogger } from 'aurelia-logging';
import { BaseService } from './baseService';

@autoinject()
export class LoadingPointService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(LoadingPointService.name), taskQueue);
  }

  fetchLoadingPointsAsync = () =>
    this.httpRequestWithDispatch(
      requests.getAllLoadingPoints(),
      fetchingLoadingPoints,
      emptyObject,
      'Could not fetch loading points',
      state => getEntityOrUndefined(getAllEntities(state.loadingPoints))
    );

  fetchLoadingPoints = () =>
    this.httpRequestWithDispatchFromState(
      requests.getAllLoadingPoints(),
      fetchingLoadingPoints,
      emptyObject,
      'Could not fetch loading points',
      state => getAllEntities(state.loadingPoints)
    );
}
