import GridCell from '$components/grid/react-grid/cell/grid-cell';
import { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import {
  ReportScheduleRecurrence,
  ScheduledReportsListQuery
} from '$typings/graphql-codegen';
import { TFunction } from 'i18next';
import {
  getWeekDayFromNumber,
  mapReportEnumToTranslatedTitle
} from '../schedule-reports-helpers';
import React from 'react';
import Icon from '$components/icons/icon/icon.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { isSomething } from '$lib/helpers';
import { ensureDate, parseHumanDate, formatDate } from '$lib/dateHelpers';

const getInterpolatedRecurrenceDetailsForMonthlyByDate = (
  date: number | null
) => {
  switch (date) {
    default:
    case 0:
      return 'first';
  }
};

export const getScheduleDescription = (
  row: ScheduledReportsListQuery['reportSchedules'][0],
  t: TFunction<'translation', undefined>
) => {
  if (!isSomething(row.recurrence)) return;
  switch (row.recurrence) {
    case ReportScheduleRecurrence.Daily:
      return t('UI_ReportSchedules_ScheduleDescription_Daily', {
        time: row.triggerTimeLocal
      });
    case ReportScheduleRecurrence.MonthlyByDate:
      return t('UI_ReportSchedules_ScheduleDescription_MonthlyByDate', {
        date: getInterpolatedRecurrenceDetailsForMonthlyByDate(
          row.recurrenceDetails ?? null
        ),
        time: row.triggerTimeLocal
      });
    case ReportScheduleRecurrence.MonthlyByFirstWeekDay:
      return t('UI_ReportSchedules_ScheduleDescription_MonthlyByFirstWeekDay', {
        weekday: t(getWeekDayFromNumber(row.recurrenceDetails ?? 0)),
        time: row.triggerTimeLocal
      });
    case ReportScheduleRecurrence.WeeklyByWeekDay:
      return t('UI_ReportSchedules_ScheduleDescription_WeeklyByWeekDay', {
        weekday: t(getWeekDayFromNumber(row.recurrenceDetails ?? 0)),
        time: row.triggerTimeLocal
      });
    case ReportScheduleRecurrence.Never:
      return t('UI_ReportSchedules_ScheduleDescription_Never');
    case ReportScheduleRecurrence.FirstDayOfMonth:
        return t('UI_ReportSchedules_ScheduleDescription_FirstDayOfMonth');
    case ReportScheduleRecurrence.FirstDayOfQuarter:
          return t('UI_ReportSchedules_ScheduleDescription_FirstDayOfQuarter');
    case ReportScheduleRecurrence.FirstDayOfHalfYear:
          return t('UI_ReportSchedules_ScheduleDescription_FirstDayOfHalfYear');          
    case ReportScheduleRecurrence.FirstDayOfYear:
          return t('UI_ReportSchedules_ScheduleDescription_FirstDayOfYear');
  }
};

export const getReportScheduleDescription = (
  recurrence: ReportScheduleRecurrence,
  recurrenceDetails: number | undefined | null,
  triggerTimeLocal: string,

  t: TFunction<'translation', undefined>
) => {
  if (!isSomething(recurrence)) return;
  switch (recurrence) {
    case ReportScheduleRecurrence.Daily:
      return t('UI_ReportSchedules_ScheduleDescription_Daily', {
        time: triggerTimeLocal
      });
    case ReportScheduleRecurrence.MonthlyByDate:
      return t('UI_ReportSchedules_ScheduleDescription_MonthlyByDate', {
        date: getInterpolatedRecurrenceDetailsForMonthlyByDate(
          recurrenceDetails ?? null
        ),
        time: triggerTimeLocal
      });
    case ReportScheduleRecurrence.MonthlyByFirstWeekDay:
      return t('UI_ReportSchedules_ScheduleDescription_MonthlyByFirstWeekDay', {
        weekday: t(getWeekDayFromNumber(recurrenceDetails ?? 0)),
        time: triggerTimeLocal
      });
    case ReportScheduleRecurrence.WeeklyByWeekDay:
      return t('UI_ReportSchedules_ScheduleDescription_WeeklyByWeekDay', {
        weekday: t(getWeekDayFromNumber(recurrenceDetails ?? 0)),
        time: triggerTimeLocal
      });
    case ReportScheduleRecurrence.Never:
      return t('UI_ReportSchedules_ScheduleDescription_Never');
    case ReportScheduleRecurrence.FirstDayOfMonth:
        return t('UI_ReportSchedules_ScheduleDescription_FirstDayOfMonth');
    case ReportScheduleRecurrence.FirstDayOfQuarter:
          return t('UI_ReportSchedules_ScheduleDescription_FirstDayOfQuarter');
    case ReportScheduleRecurrence.FirstDayOfHalfYear:
          return t('UI_ReportSchedules_ScheduleDescription_FirstDayOfHalfYear');          
    case ReportScheduleRecurrence.FirstDayOfYear:
          return t('UI_ReportSchedules_ScheduleDescription_FirstDayOfYear');
  }
};

export const navigateToSchedule = (
  row: ScheduledReportsListQuery['reportSchedules'][0]
) => {
  if (!row) return;
  return `scheduled-reports/scheduled-reports/details/${row.reportScheduleId}`;
};

export const getLastScheduleRunDescription = (
  row: ScheduledReportsListQuery['reportSchedules'][0],
  t: TFunction<'translation', undefined>
) => {
  if (!row.lastDone || !row.lastTrigger)
    return t('UI_ReportSchedules_LastScheduleRunDescription_NotRunYet');
  const lastDoneDate = parseHumanDate(row.lastDone);
  const lastTriggerDate = parseHumanDate(row.lastTrigger);
  const startDate = ensureDate(lastTriggerDate);
  const endDate = ensureDate(lastDoneDate);
  const secondsDuration = (endDate.getTime() - startDate.getTime()) / 1000;
  const minutesDuration = secondsDuration / 60;
  const showMinutes = minutesDuration >= 1;
  const finish = formatDate(lastDoneDate, true, '.');

  const translationKey = showMinutes
    ? 'UI_ReportSchedules_LastScheduleRunDescription_Minutes'
    : 'UI_ReportSchedules_LastScheduleRunDescription_Seconds';
  return t(translationKey, {
    count: minutesDuration,
    finish
  });
};

const getReportsForSchedule = (
  t: TFunction<'translation', undefined>,
  row: ScheduledReportsListQuery['reportSchedules'][0]
) =>
  row.scheduledReports
    .map(s => s.report)
    .map(mapReportEnumToTranslatedTitle(t));

export const useReportScheduleColumns = (systemReport: boolean = false) => {
  const [t] = useCaseInsensitiveTranslation();

  const columns: IReactGridColumn<
    ScheduledReportsListQuery['reportSchedules'][0]
  >[] = [
    {
      columnTitle: 'UI_ReportSchedules_Name',
      columnKey: 'scheduleName',
      initialWidth: 200,
      render: (item, width) => (
        <GridCell width={width}>{item.scheduleName}</GridCell>
      )
    },
    {
      columnTitle: 'UI_ReportSchedules_Schedule',
      render: (item, width) => (
        <GridCell width={width}>{getScheduleDescription(item, t)}</GridCell>
      ),
      columnKey: 'Description',
      initialWidth: 200,
      sortingDisabled: true
    },
    {
      columnTitle: 'UI_ReportSchedules_Reports',
      render: (item, width) => (
        <GridCell width={width}>{getReportsForSchedule(t, item)}</GridCell>
      ),
      columnKey: 'Reports',
      initialWidth: 200,
      sortingDisabled: true
    },
    {
      columnTitle: 'UI_ReportSchedules_LastRun',
      render: (item, width) => (
        <GridCell width={width}>{item.lastDone}</GridCell>
      ),
      columnKey: 'lastDone',
      initialWidth: 200
    },
    {
      columnTitle: 'UI_ReportSchedules_NextRun',
      render: (item, width) => (
        <GridCell width={width}>
          {!item.enabled ? 'Disabled' : item.nextTrigger || ''}
        </GridCell>
      ),
      initialWidth: 200,
      columnKey: 'nextTrigger'
    }
  ];

  if (systemReport) {
    columns.push({
      columnTitle: 'UI_ReportSchedules_Recipients',
      render: (item, width) =>
        item.systemReportRecipients
          ?.split(',')
          .map((recipient, index) => <GridCell key={index} width={width}>{recipient}</GridCell>),
      initialWidth: 200,
      columnKey: 'recipients'
    });
  }

  columns.push({
    columnTitle: 'UI_Common_Edit',
    render: (_, width) => (
      <GridCell width={width}>
        <Icon name="fa-chevron-right" />
      </GridCell>
    ),
    columnKey: 'EditIcon',
    initialWidth: 200,
    sortingDisabled: true
  });

  return columns;
};
