import { BaseViewModel } from '..';
import {
  autoinject,
  customElement,
  bindable,
  PLATFORM
} from 'aurelia-framework';
import { defaultMemoize } from 'reselect';
import {
  IProduct,
  IUnit,
  IChannel,
  ITankDetails,
  IVehicle,
  ILoadingPoint,
  AccessLevel,
  IRetentionTimeDetails,
  IControllerParameter,
  IController,
  IRequestState,
  IAggregatedTankUsageDetails,
  IChannelsThatTrackTankChannel,
  IDoseChannelDetails,
  IAggregatedDoseChannelDetails,
  IStockVsDoseAccuracyChannelDetails,
  TankPredictionEnum
} from '../../../interfaces';
import { I18N } from 'aurelia-i18n';
import {
  orderByPredicate,
  isNone,
  emptyArray,
  asyncEntityIsFetched,
  mergeObjects,
  emptyObject,
  userHasFeature,
  channelIsDoseChannel,
  channelIsAggregatedDoseChannel,
  channelIsStockVsDoseChannel,
  ensureNumber,
  isSomething,
  getEntityOrUndefined,
} from '../../../utility';
import { getLogger } from 'aurelia-logging';
import {
  ChannelService,
  ProductService,
  UnitService,
  VehicleService,
  ExportJobService,
  ControllerService,
  SiteService
} from '../../../services';
import {
  IAsyncEntity,
  menuIsOpenForEntity,
  IAsyncEntityManager,
  createFetchedEntity
} from '../../../types';
import {
  TankTypes,
  SecurityLevels,
  OrderProcess,
  ChannelTypes,
  features,
  StockVsDoseTankUsageValues,
  TankPredictionTypes
} from '../../../config';
import { ChannelTypes as ChannelTypesEnum } from '../../../interfaces/enums';
import { rootState } from '../../../reducers';
import {
  editChannelType,
  editChannelProductProperty,
  editTankChannel,
  editChannel,
  addSiteChannelToExportJob,
  removeSiteChannelFromExportJob,
  editRetentionTimeDetails,
  editRetentionTimeParameterIdDetails,
  editAggregatedTankUsage,
  editDoseChannelProperty,
  editAggregatedDoseChannelProperty,
  editStockVsDoseAccuracyChannelProperty,
  validateChannel,
} from '../../../actions';
import { LoadingPointService } from '../../../services/loadingPointService';
import { getSession } from '../../../config/sessionService';
import { IExportJobNameAndId } from '../../../interfaces/entity/iexportjobNameAndId';
import {
  toggleConfirmDeleteChannel,
  getUnitDropdownFilter
} from '../../../reducers/ui/controllerManagerReducer';
import { ISiteExportReducer } from '../../../reducers/entity/siteExportReducer';

import './channeleditor.css';
import { compareReduxAndGraphQLIds } from '../../../utility/graphqlHelpers';
import { UnitDropdownFilter } from '../../../components/dropdowns/unit-dropdown/unit-dropdown';

interface IChannelEditorState {
  isMobile: boolean;
  controller: IAsyncEntity<IController> | undefined;
  channel: IAsyncEntity<IChannelDetails>;
  products: IProduct[];
  editorAction: editorAction;
  channelTypes: typeof ChannelTypes;
  tankTypes: typeof TankTypes;
  securityLevels: typeof SecurityLevels;
  orderProcesses: typeof OrderProcess;
  tankPredictionTypes: typeof TankPredictionTypes;
  menuOpenForEntity: menuIsOpenForEntity;
  channelType: channelType;
  isRemoteSiteChannel: boolean;
  channelHasInvalidProperties: boolean | string;
  deleteConfirmation: boolean;
  editChannelRequest: IRequestState;
  channelValidationErrors: Record<string, string[]> | undefined;
  exportJobConfig:
    | undefined
    | {
        allExportJobs: IExportJobNameAndId[];
        exportJobs: IExportJobNameAndId[];
      };
  access: {
    canDelete: boolean;
    canEdit: boolean;
    canEditExportJobRelation: boolean;
  };
  units: IUnit[];
  unitFilter: UnitDropdownFilter;
  soldTo: string | null | undefined;
}

interface IChannelDetails extends IChannel {
  product: IProduct | undefined;
  unit: IUnit | undefined;
  tankDetails?: IChannelDetailsTankDetails;
  retentionTimeDetails?: IChannelDetailsRetentionTimeDetails;
  aggregatedTankUsageDetails?: IChannelDetailsAggregatedTankUsageDetails;
  doseDetails?: IChannelDetailsDoseDetails;
  stockVsDoseAccuracyChannelDetails?: IChannelDetailsStockVsDoseAccuracyChannelDetails;
  pendingExportJobObjects?: IExportJobNameAndId[] | undefined;
  pendingExportJobs?: number[] | undefined;
}

interface IChannelDetailsTankDetails extends ITankDetails {
  vehicle?: IVehicle;
  loadingPoints?: ILoadingPoint[];
  loadingPoint?: ILoadingPoint;
  trackedChannels: IChannelsThatTrackTankChannel[] | undefined;
  addNewTrackedChannel?: boolean;
}

interface IChannelDetailsRetentionTimeDetails extends IRetentionTimeDetails {
  flowChannel?: IChannel;
  controllerChannels?: IAsyncEntity<IChannel[]>;
  volumeParameters?: IControllerParameter[];
}

interface IChannelDetailsDoseDetails extends IDoseChannelDetails {
  tankChannel?: IChannel;
  controllerChannels?: IAsyncEntity<IChannel[]>;
  trackedChannels: IChannelsThatTrackTankChannel[] | undefined;
}

interface IChannelDetailsAggregatedTankUsageDetails
  extends IAggregatedTankUsageDetails {
  controllerChannels?: IAsyncEntity<IChannel[]>;
  tankChannel?: IChannel;
}

interface IChannelDetailsAggregatedDoseChannelDetails
  extends IAggregatedDoseChannelDetails {
  controllerChannels?: IAsyncEntity<IChannel[]>;
  doseChannel?: IChannel;
}

interface IChannelDetailsStockVsDoseAccuracyChannelDetails
  extends IStockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannelsOnController?: IAsyncEntity<IChannel[]>;
  aggregatedTankChannelsOnController?: IAsyncEntity<IChannel[]>;
  aggregatedDoseChannel?: IChannel;
  aggregatedTankChannel?: IChannel;
}

export type channelType =
  | 'siteChannel'
  | 'remoteSiteChannel'
  | 'controllerchannel';
type editorAction = 'create' | 'edit' | 'view';

const sortProducts = defaultMemoize((products: IProduct[], i18n: I18N) =>
  orderByPredicate(
    products,
    product => i18n.tr(product.languageKey),
    'asc',
    true
  )
);

const channelIsTankChannel = (channel: IChannel) =>
  channel.channelType === ChannelTypesEnum.Tank;

const channelIsRetentionTime = (channel: IChannel) =>
  channel.channelType === ChannelTypesEnum.RetentionTime;

const channelIsAggregatedTankUsageChannel = (channel: IChannel) =>
  channel.channelType === ChannelTypesEnum.AggregatedTankUsage;

const fetchChannel = (
  channelId: number,
  type: channelType,
  channelService: ChannelService
) =>
  type === 'controllerchannel'
    ? channelService.getControllerChannelByChannelId(channelId)
    : channelService.getSiteChannelBySiteChannelId(channelId);

const mapTankDetailsToChannelDetailsTankDetails = (
  vehicleService: VehicleService,
  channelService: ChannelService,
  channelDetails: IChannel,
  editorAction: editorAction,
  loadingPointService: LoadingPointService,
  tankDetails: ITankDetails,
  siteChannelId: number | undefined
): IChannelDetailsTankDetails => {
  const vehicleFetcher = vehicleService.getAllVehicles();
  const loadingPointFetcher = loadingPointService.fetchLoadingPoints();
  const trackedChannels =
    editorAction === 'create'
      ? undefined
      : channelService.getChannelsThatTrackChannel(
          channelDetails.channelId,
          siteChannelId
        );
  return {
    ...tankDetails,
    vehicle: vehicleFetcher
      .map(vehicles =>
        vehicles.find(v =>
          compareReduxAndGraphQLIds(v.vehicleId, tankDetails.vehicleId)
        )
      )
      .getEntityOrUndefined(),
    loadingPoint: loadingPointFetcher
      .map(lps =>
        lps.find(lp => lp.loadingPointId === tankDetails.loadingPointId)
      )
      .getEntityOrUndefined(),
    loadingPoints: loadingPointFetcher.getEntityOrDefault(emptyArray),
    trackedChannels: trackedChannels && trackedChannels.getEntityOrUndefined()
  };
};

const mapRetentionDetailsToChannelDetailsRetentionDetails = (
  retentionTimeDetails: IRetentionTimeDetails,
  isRemote: boolean,
  controllerId: number,
  controllerService: ControllerService,
  channelService: ChannelService
): IChannelDetailsRetentionTimeDetails => {
  const controllerChannels = isRemote
    ? undefined
    : channelService
        .getControllerChannelsByControllerId(controllerId)
        .map(channels => orderByPredicate(channels, c => c.alias, 'asc'));
  const controllerParameters = isRemote
    ? undefined
    : controllerService
        .getControllerParameters(controllerId)
        .map(parameters => orderByPredicate(parameters, p => p.name, 'asc'));
  const { flowChannelId, flowChannelToken } = retentionTimeDetails;
  const flowChannelFetcher = !flowChannelId
    ? undefined
    : channelService.getChannelByChannelToken(
        flowChannelId,
        flowChannelToken || ''
      );
  return {
    ...retentionTimeDetails,
    controllerChannels:
      controllerChannels &&
      controllerChannels
        .map(channels =>
          channels.filter(
            channel => channel.channelType === ChannelTypesEnum.Flow
          )
        )
        .getAsyncEntity(),
    flowChannel:
      flowChannelFetcher && flowChannelFetcher.getEntityOrUndefined(),
    volumeParameters:
      controllerParameters &&
      controllerParameters.getEntityOrDefault(emptyArray)
  };
};

const mapDoseDetailsToChannelDetailsDoseDetails = (
  doseDetails: IDoseChannelDetails,
  channelDetails: IChannel,
  isRemote: boolean,
  channelService: ChannelService,
  editorAction: editorAction,
  siteChannelId: number | undefined
): IChannelDetailsDoseDetails => {
  const controllerChannels = isRemote
    ? undefined
    : channelService
        .getControllerChannelsByControllerId(channelDetails.controllerId)
        .map(channels => orderByPredicate(channels, c => c.alias, 'asc'));
  const { tankChannelId, tankChannelToken } = doseDetails;
  const tankChannelFetcher = !tankChannelId
    ? undefined
    : channelService.getChannelByChannelToken(
        tankChannelId,
        tankChannelToken || ''
      );
  const trackedChannels =
    editorAction === 'create'
      ? undefined
      : channelService.getChannelsThatTrackChannel(
          channelDetails.channelId,
          siteChannelId
        );
  return {
    ...doseDetails,
    controllerChannels:
      controllerChannels &&
      controllerChannels
        .map(channels => channels.filter(channelIsTankChannel))
        .getAsyncEntity(),
    tankChannel:
      tankChannelFetcher && tankChannelFetcher.getEntityOrUndefined(),
    trackedChannels: trackedChannels && trackedChannels.getEntityOrUndefined()
  };
};

const mapAggregatedDoseDetailsToChannelDetailsAggregatedDoseDetails = (
  aggregatedDetails: IAggregatedDoseChannelDetails,
  isRemote: boolean,
  controllerId: number,
  channelService: ChannelService
): IChannelDetailsAggregatedDoseChannelDetails => {
  const controllerChannels = isRemote
    ? undefined
    : channelService
        .getControllerChannelsByControllerId(controllerId)
        .map(channels => orderByPredicate(channels, c => c.alias, 'asc'));
  const { doseChannelId, doseChannelToken } = aggregatedDetails;
  const doseChannelFetcher = !doseChannelId
    ? undefined
    : channelService.getChannelByChannelToken(
        doseChannelId,
        doseChannelToken || ''
      );
  return {
    ...aggregatedDetails,
    controllerChannels:
      controllerChannels &&
      controllerChannels
        .map(channels => channels.filter(channelIsDoseChannel))
        .getAsyncEntity(),
    doseChannel: doseChannelFetcher && doseChannelFetcher.getEntityOrUndefined()
  };
};

const mapStockVsDoseAccuracyCHannelDetailsToChannelDetails = (
  stockVsDoseDetails: IStockVsDoseAccuracyChannelDetails,
  isRemote: boolean,
  controllerId: number,
  channelService: ChannelService
): IChannelDetailsStockVsDoseAccuracyChannelDetails => {
  const controllerChannels = isRemote
    ? undefined
    : channelService
        .getControllerChannelsByControllerId(controllerId)
        .map(channels => orderByPredicate(channels, c => c.alias, 'asc'));
  const {
    aggregatedDoseChannelId,
    aggregatedDoseChannelToken,
    aggregatedTankUsageChannelId,
    aggregatedTankUsageChannelToken
  } = stockVsDoseDetails;
  const aggregatedDoseChannelFetcher = !aggregatedDoseChannelId
    ? undefined
    : channelService.getChannelByChannelToken(
        aggregatedDoseChannelId,
        aggregatedDoseChannelToken || ''
      );
  const aggregatedTankUsageChannelFetcher = !aggregatedTankUsageChannelId
    ? undefined
    : channelService.getChannelByChannelToken(
        aggregatedTankUsageChannelId,
        aggregatedTankUsageChannelToken || ''
      );
  return {
    ...stockVsDoseDetails,
    aggregatedDoseChannelsOnController:
      controllerChannels &&
      controllerChannels
        .map(channels => channels.filter(channelIsAggregatedDoseChannel))
        .getAsyncEntity(),
    aggregatedTankChannelsOnController:
      controllerChannels &&
      controllerChannels
        .map(channels => channels.filter(channelIsAggregatedTankUsageChannel))
        .getAsyncEntity(),
    aggregatedDoseChannel:
      aggregatedDoseChannelFetcher &&
      aggregatedDoseChannelFetcher.getEntityOrUndefined(),
    aggregatedTankChannel:
      aggregatedTankUsageChannelFetcher &&
      aggregatedTankUsageChannelFetcher.getEntityOrUndefined()
  };
};

const mapAggregatedTankUsageToChannelDetailTankUsageDetails = (
  aggregatedTankUsage: IAggregatedTankUsageDetails,
  isRemote: boolean,
  controllerId: number,
  channelService: ChannelService
): IChannelDetailsAggregatedTankUsageDetails => {
  const controllerChannels = isRemote
    ? undefined
    : channelService
        .getControllerChannelsByControllerId(controllerId)
        .map(channels => orderByPredicate(channels, c => c.alias, 'asc'));
  const { tankChannelId, tankChannelToken } = aggregatedTankUsage;
  const tankChannel = !tankChannelId
    ? undefined
    : channelService.getChannelByChannelToken(
        tankChannelId,
        tankChannelToken || ''
      );
  return {
    ...aggregatedTankUsage,
    controllerChannels:
      controllerChannels &&
      controllerChannels
        .map(channels =>
          channels.filter(
            channel => channel.channelType === ChannelTypesEnum.Tank
          )
        )
        .getAsyncEntity(),
    tankChannel: tankChannel && tankChannel.getEntityOrUndefined()
  };
};

const canEditExportJobRelation = (type: channelType) =>
  type === 'controllerchannel'
    ? false
    : userHasFeature(
        getSession(),
        features.exportJobChannels,
        AccessLevel.Write
      );

const sortExportJobByName = (
  exports: IExportJobNameAndId[]
): IExportJobNameAndId[] => orderByPredicate(exports, e => e.name, 'asc');

const channelHasInvalidProperties = (
  channelDetails: IChannelDetails
): boolean | string => {
  if (
    channelIsRetentionTime(channelDetails) &&
    channelDetails.retentionTimeDetails &&
    channelDetails.retentionTimeDetails.calculatedChannel
  )
    // Retention time channels
    return (
      isNone(channelDetails.retentionTimeDetails.flowChannel) ||
      isNone(channelDetails.retentionTimeDetails.volume)
    );
  if (channelIsDoseChannel(channelDetails) && channelDetails.doseDetails) {
    // Dose channels
    const hasTrackedChannels =
      channelDetails.doseDetails.trackedChannels &&
      (channelDetails.doseDetails.trackedChannels.length ||
        channelDetails.doseDetails.addNewTrackedChannel);

    if (hasTrackedChannels && !channelDetails.unitId)
      return 'UI_ChannelEditor_ValidationErrors_DoseTracked_NeedUnit';
    if (hasTrackedChannels && !channelDetails.doseDetails.tankChannelId)
      return 'UI_ChannelEditor_ValidationErrors_DoseTracked_NeedTank';
  }
  if (channelIsTankChannel(channelDetails) && channelDetails.tankDetails) {
    // Tank channels
    const hasTrackedChannels =
      channelDetails.tankDetails.trackedChannels &&
      channelDetails.tankDetails.trackedChannels.length;
    const addingChannel =
      channelDetails.tankDetails.trackedChannels &&
      channelDetails.tankDetails.addNewTrackedChannel;

    if ((hasTrackedChannels || addingChannel) && !channelDetails.unitId)
      return 'UI_ChannelEditor_ValidationErrors_TankTracked_NeedUnit';
  }
  return false;
};

@autoinject()
@customElement('channel-editor')
export class ChannelEditor extends BaseViewModel<IChannelEditorState> {
  showChannelTypeDetails: boolean = false;

  @bindable({ changeHandler: 'reattachMapState' })
  channelOrControllerId: number | undefined;

  @bindable({ changeHandler: 'reattachMapState' })
  action: editorAction | undefined;

  @bindable({ changeHandler: 'reattachMapState' })
  type: channelType | undefined;

  @bindable({ changeHandler: 'reattachMapState' })
  timezone: string | undefined;
  @bindable siteId: number;

  @bindable navigateToChannel: Function = PLATFORM.noop;
  @bindable canNavigateToChannel: Function = PLATFORM.noop;

  tankVsDoseTankUsagePercentageValues = Object.entries(
    StockVsDoseTankUsageValues
  );
  tankVsDoseTankUsagePercentage = StockVsDoseTankUsageValues;

  constructor(
    private i18n: I18N,
    private channelService: ChannelService,
    private productService: ProductService,
    private unitService: UnitService,
    private vehicleService: VehicleService,
    private loadingPointService: LoadingPointService,
    private exportJobService: ExportJobService,
    private controllerService: ControllerService,
    private siteService: SiteService
  ) {
    super(getLogger('ChannelEditor'));
  }

  @bindable() canceledEdit = () => undefined;
  @bindable() deletedChannel = () => undefined;
  @bindable() persistedChannel = () => undefined;

  bind() {
    this.reattachMapState();
    this.channelCodeIsLocked = this.action !== 'create';
  }

  reattachMapState() {
    if (
      isNone(this.action) ||
      isNone(this.channelOrControllerId) ||
      isNone(this.type)
    )
      return;
    this.attachMapState(
      this.mapState(this.channelOrControllerId, this.action, this.type)
    );
  }

  getExportJobConfig = (
    channelId: number,
    type: channelType,
    siteExports: ISiteExportReducer,
    action: editorAction
  ) => {
    if (!canEditExportJobRelation(type))
      return undefined;

    //All exportJobs in YT3
    const allExportJobs = this.exportJobService
      .getExportJobNames()
      .map(sortExportJobByName)
      .getEntityOrDefault(emptyArray);

    //Existing export jobs for this channel
    let exportJobs = this.exportJobService
      .getExportedChannelsForSiteChannel(channelId)
      .map(n =>
        n.map<IExportJobNameAndId>(m => ({
          exportJobId: m.exportJobId,
          name: m.exportJobName,
          canBeRemoved: m.canBeRemoved
        }))
      )       
      .map(sortExportJobByName)
      .getEntityOrDefault(emptyArray);
            
    if (action === 'create' || siteExports.pendingExportJobsForCurrentChannel) {
        const pendingJobs = siteExports.pendingExportJobsForCurrentChannel || [];
        
        //Add pendingJobs not in existing jobs
        const newJobs = pendingJobs.filter(j => exportJobs.map(e => e.exportJobId).indexOf(j.exportJobId) < 0);        
        newJobs.forEach(j => {
          exportJobs.push({
            ...j
          }); 
        });
    } 
    
    //Remove exportJobs
    if (siteExports && siteExports.pendingExportJobsForCurrentChannel && siteExports.pendingExportJobsForCurrentChannel.length > 0)
    {
      const ids: number[] = siteExports.pendingExportJobsForCurrentChannel.map(j => j.exportJobId);
      exportJobs = exportJobs.filter(e => ids.indexOf(e.exportJobId) >= 0);
    }
    
    //All exportJobs have been deleted #7557
    //Without this code you are not able to remove last export job
    if (siteExports.pendingExportJobsForCurrentChannel && siteExports.pendingExportJobsForCurrentChannel.length == 0) {
      exportJobs = [];
    }

    return { allExportJobs, exportJobs };
  }    

  private getChannelDetails = (
    channelOrControllerId: number,
    action: editorAction,
    type: channelType
  ): IAsyncEntityManager<IChannel> => {
    switch (action) {
      case 'edit':
      case 'view':
        return fetchChannel(channelOrControllerId, type, this.channelService);
      case 'create':
        return new IAsyncEntityManager(
          createFetchedEntity<IChannel>({
            alias: '',
            channelId: 0,
            channelType: 0,
            code: '',
            isParked: false,
            securityLevel: 0,
            lastSample: 0,
            lastSampleTime: '',
            controllerId: channelOrControllerId
          })
        );
    }
  };
  channelCodeIsLocked: boolean;

  mapState = (
    channelOrControllerId: number,
    action: editorAction,
    type: channelType
  ) => ({
    application,
    controllerManager,
    siteExports,
    device
  }: rootState): IChannelEditorState => {
    const controller =
      action === 'create'
        ? this.controllerService.getController(channelOrControllerId)
        : undefined;

    const isRemote = type === 'remoteSiteChannel';
    const siteChannelId =
      type === 'controllerchannel' || action === 'create'
        ? undefined
        : channelOrControllerId;

    const channel = this.getChannelDetails(channelOrControllerId, action, type)
      .map(channel =>
        mergeObjects(channel, controllerManager.editChannel || emptyObject)
      )
      .map<IChannelDetails>(channel => ({
        ...channel,
        product: this.productService
          .getAll()
          .map(products =>
            products.find(p => p.productId === channel.productId)
          )
          .getEntityOrUndefined(),
        unit: this.unitService
          .fetchUnits()
          .map(units =>
            units.find(u => compareReduxAndGraphQLIds(u.unitId, channel.unitId))
          )
          .getEntityOrUndefined(),
        pendingExportJobObjects: siteExports.pendingExportJobsForCurrentChannel,
        pendingExportJobs: siteExports.pendingExportJobsForCurrentChannel?.map(j => j.exportJobId) || [],
        tankDetails: !channelIsTankChannel(channel)
          ? undefined
          : mapTankDetailsToChannelDetailsTankDetails(
              this.vehicleService,
              this.channelService,
              channel,
              action,
              this.loadingPointService,
              mergeObjects(
                channel.tankDetails || {
                  tankId: 0,
                  channelId: channel.channelId,
                  outsideThreshold: false,
                  percentage: 0,
                  freeCapacity: 0,
                  noConsumption: false,
                  error: false,
                  predictionType: TankPredictionEnum.TankCapacity
                },
                controllerManager.editTankDetails
              ),
              siteChannelId
            ),
        retentionTimeDetails: !channelIsRetentionTime(channel)
          ? undefined
          : mapRetentionDetailsToChannelDetailsRetentionDetails(
              {
                ...channel.retentionTimeDetails,
                ...controllerManager.editRetentionTimeDetails
              },
              isRemote,
              channel.controllerId,
              this.controllerService,
              this.channelService
            ),
        aggregatedTankUsageDetails: !channelIsAggregatedTankUsageChannel(
          channel
        )
          ? undefined
          : mapAggregatedTankUsageToChannelDetailTankUsageDetails(
              {
                ...channel.aggregatedTankUsageDetails,
                ...controllerManager.editAggregatedTankUsageDetails
              },
              isRemote,
              channel.controllerId,
              this.channelService
            ),
        doseDetails: !channelIsDoseChannel(channel)
          ? undefined
          : mapDoseDetailsToChannelDetailsDoseDetails(
              { ...channel.doseDetails, ...controllerManager.editDoseDetails },
              channel,
              isRemote,
              this.channelService,
              action,
              siteChannelId
            ),
        aggregatedDoseChannelDetails: !channelIsAggregatedDoseChannel(channel)
          ? undefined
          : mapAggregatedDoseDetailsToChannelDetailsAggregatedDoseDetails(
              {
                ...channel.aggregatedDoseChannelDetails,
                ...controllerManager.editAggregatedDoseChannelDetails
              },
              isRemote,
              channel.controllerId,
              this.channelService
            ),
        stockVsDoseAccuracyChannelDetails: !channelIsStockVsDoseChannel(channel)
          ? undefined
          : mapStockVsDoseAccuracyCHannelDetailsToChannelDetails(
              {
                ...channel.stockVsDoseAccuracyChannelDetails,
                ...controllerManager.editStockVsDoseAccuracyChannelDetails
              },
              isRemote,
              channel.controllerId,
              this.channelService
            )
      }));
    
    const hasInvalidProperties = channel
      .map(channelHasInvalidProperties)
      .getEntityOrDefault(false);
    const hasValidationErrors =
      (controllerManager.channelValidationErrors &&
        Object.values(controllerManager.channelValidationErrors).findIndex(
          x => x && x.length > 0
        ) >= 0) ||
      false;

    let soldTo: string | null | undefined = undefined;

    if (isSomething(this.siteId)) {
      soldTo = this.siteService
        .getSiteById(this.siteId)
        .map(site => site.soldTo)
        .getEntityOrUndefined();
    } else {
      soldTo = channel
        .bind(ch => this.controllerService.getController(ch.controllerId))
        .bind(controller =>
          isSomething(controller.siteId)
            ? this.siteService.getSiteById(controller.siteId)
            : undefined
        )
        .map(site => (isSomething(site) ? site.soldTo : undefined))
        .getEntityOrUndefined();
    }

    const myState =
     {
      isMobile: device.screenSize === 'mobile',
      menuOpenForEntity: application.menuIsOpenForEntity,
      controller: controller && controller.getAsyncEntity(),
      channelType: type,
      editorAction: action,
      isRemoteSiteChannel: isRemote,
      products: sortProducts(
        this.productService.getAll().getEntityOrDefault(emptyArray),
        this.i18n
      ),
      channelTypes: ChannelTypes,
      securityLevels: SecurityLevels,
      tankTypes: TankTypes,
      orderProcesses: OrderProcess,
      tankPredictionTypes: TankPredictionTypes,
      deleteConfirmation: controllerManager.deleteChannelConfirmation,
      exportJobConfig: this.getExportJobConfig(
        channelOrControllerId,
        type,
        siteExports,
        action
      ),
      channel: channel.getAsyncEntity(),
      channelHasInvalidProperties: hasInvalidProperties || hasValidationErrors,
      access: {
        canDelete: userHasFeature(
          getSession(),
          type === 'controllerchannel'
            ? features.controllerManagement
            : features.siteDetailsChannels,
          AccessLevel.Delete
        ),
        canEdit: userHasFeature(
          getSession(),
          type === 'controllerchannel'
            ? features.controllerManagement
            : features.siteDetailsChannels,
          AccessLevel.Write
        ),
        canEditExportJobRelation: canEditExportJobRelation(type)
      },
      editChannelRequest: controllerManager.editChannelRequest,
      channelValidationErrors: controllerManager.channelValidationErrors,
      units: this.unitService.fetchUnits().getEntityOrDefault(emptyArray),
      unitFilter: channel
        .map<UnitDropdownFilter>(c => getUnitDropdownFilter(c.channelType))
        .getEntityOrUndefined(),
      soldTo
    };
    
    return myState;
  };

  showChannelTypeValueInDropdown(value: string) {
    const notShow = [
      ChannelTypesEnum.AggregatedDose,
      ChannelTypesEnum.AggregatedTankUsage,
      ChannelTypesEnum.StockVsDoseAccuracy
    ];
    return !notShow.includes(ensureNumber(value));
  }

  editChannelProperty(key: keyof IChannel, value: string | number) {
    if (!asyncEntityIsFetched(this.state.channel)) return;
    if (this.state.channel && this.state.channel.entity[key] !== value)
      this.dispatch(editChannel({ key, value }));
  }

  editAggregatedTankUsageProperty(
    key: keyof IAggregatedTankUsageDetails,
    value: IAggregatedTankUsageDetails[keyof IAggregatedTankUsageDetails]
  ) {
    this.dispatch(editAggregatedTankUsage({ key, value }));
  }

  editDoseProperty(
    key: keyof IDoseChannelDetails,
    value: IDoseChannelDetails[keyof IDoseChannelDetails]
  ) {
    this.dispatch(editDoseChannelProperty({ key, value }));
  }

  setChannelMinMaxPreset(key: keyof IChannel, percent: number) {
    if (!asyncEntityIsFetched(this.state.channel)) return;
    const capacity = this.state.channel.entity.capacity;
    if (isNone(capacity)) return;
    const value = Math.floor(((capacity * 1.0) / 100) * percent);
    this.dispatch(editChannel({ key, value }));
  }

  editChannelTypeProperty = (channel: IChannel, type: string) => {
    const units = this.state.units;
    this.dispatch(
      editChannelType({ channel, channelType: parseInt(type, 10), units })
    );
  };

  editChannelProductProperty = (product: IProduct) =>
    this.dispatch(editChannelProductProperty(product));

  selectRetentionTimeParameterIdDetails = (parameter: IControllerParameter) =>
    this.dispatch(editRetentionTimeParameterIdDetails(parameter));

  editRetentionTimeDetailsProperty = (
    retentionTimeDetails: IRetentionTimeDetails,
    key: keyof IRetentionTimeDetails,
    value: number
  ) =>
    retentionTimeDetails[key] !== value
      ? this.dispatch(editRetentionTimeDetails({ key, value }))
      : undefined;

  editTankDetailsProperty(
    tankDetails: IChannelDetailsTankDetails,
    key: keyof ITankDetails,
    value: string | number
  ) {
    if (tankDetails[key] !== value)
      this.dispatch(editTankChannel({ key, value }));
  }

  editStockVsDoseChannelProperty(
    key: keyof IStockVsDoseAccuracyChannelDetails,
    value: IStockVsDoseAccuracyChannelDetails[keyof IStockVsDoseAccuracyChannelDetails]
  ) {
    this.dispatch(editStockVsDoseAccuracyChannelProperty({ key, value }));
  }

  editAggregatedDoseChannelProperty(
    key: keyof IAggregatedDoseChannelDetails,
    value: IAggregatedDoseChannelDetails[keyof IAggregatedDoseChannelDetails]
  ) {
    this.dispatch(editAggregatedDoseChannelProperty({ key, value }));
  }

  cancelEdit = () => this.canceledEdit();

  deleteChannel = async () => {
    if (!asyncEntityIsFetched(this.state.channel)) return;
    if (isNone(this.type) || isNone(this.channelOrControllerId)) return;

    const { entity } = this.state.channel;
    if (this.type === 'controllerchannel')
      await this.channelService.deleteChannel(
        this.channelOrControllerId,
        entity.controllerId,
        undefined,
        this.deletedChannel
      );
    else
      await this.channelService.deleteSiteChannel(
        this.channelOrControllerId,
        this.siteId,
        this.deletedChannel
      );
  };

  persistChannel = async () => {
    if (!asyncEntityIsFetched(this.state.channel)) return;
    if (
      isNone(this.type) ||
      isNone(this.channelOrControllerId) ||
      isNone(this.action)
    )
      return;
    
    //Start with pending (unsaved) export jobs
    let exportJobIds = this.state.channel.entity.pendingExportJobs || [];

    //Add existing export jobs
    this.state.exportJobConfig?.exportJobs.forEach(job => {
        if (exportJobIds.indexOf(job.exportJobId) < 0)
          exportJobIds.push(job.exportJobId);
      });

    const trimmedChannelEntity = {...this.state.channel.entity, 
      alias: this.state.channel.entity.alias.trim(), 
      code: this.state.channel.entity.code.trim(), 
      pendingExportJobs: exportJobIds //Required to fix #7557
    }

    switch (this.action) {
      case 'create':
        await this.persistNewChannel(this.type, trimmedChannelEntity);
        break;
      case 'edit':
        await this.persistExistingChannel(
          this.type,
          this.channelOrControllerId,
          trimmedChannelEntity
        );
        break;
    }    
    this.persistedChannel();
  };

  private async persistNewChannel(
    type: channelType,
    newChannel: IChannelDetails
  ) {
    switch (type) {
      case 'controllerchannel':
        await this.channelService.createChannel(newChannel);
        break;
      case 'siteChannel':
      case 'remoteSiteChannel':
        await this.channelService.createSiteChannelAndChannel(newChannel);
        break;
    }
  }

  private async persistExistingChannel(
    type: channelType,
    channelId: number,
    updatedChannel: IChannelDetails
  ) {
    switch (type) {
      case 'controllerchannel':
        await this.channelService.updateChannel(updatedChannel);
        break;
      case 'siteChannel':
      case 'remoteSiteChannel':
        await this.channelService.updateSiteChannel(channelId, updatedChannel);
        break;
    }
  }

  mainChannelViewTemplate(
    channelDetails: IChannelDetails,
    isRemote: boolean,
    canEdit: boolean
  ) {
    const readOnly = isRemote || !canEdit || this.action === 'view';
    const readOnlyTemplate = PLATFORM.moduleName(
      './templates/channeldetails-remote.html'
    );
    const aggregateChannelTemplate = PLATFORM.moduleName(
      './templates/channeldetails-aggregate-channel.html'
    );
    const editableTemplate = PLATFORM.moduleName(
      './templates/channeldetails-native.html'
    );

    switch (channelDetails.channelType) {
      case ChannelTypesEnum.AggregatedDose:
      case ChannelTypesEnum.AggregatedTankUsage:
        return readOnly ? readOnlyTemplate : aggregateChannelTemplate;
      default:
        return readOnly ? readOnlyTemplate : editableTemplate;
    }
  }

  channelDetailsTemplate(
    channelDetails: IChannelDetails,
    isRemote: boolean,
    canEdit: boolean
  ) {
    const readOnly = isRemote || !canEdit || this.action === 'view';
    switch (channelDetails.channelType) {
      case ChannelTypesEnum.Tank:
        return readOnly
          ? PLATFORM.moduleName('./templates/channeltypedetails-tank-read.html')
          : PLATFORM.moduleName(
              './templates/channeltypedetails-tank-edit.html'
            );
      case ChannelTypesEnum.RetentionTime:
        return readOnly
          ? PLATFORM.moduleName(
              './templates/channeltypedetails-retentiontime-read.html'
            )
          : PLATFORM.moduleName(
              './templates/channeltypedetails-retentiontime-edit.html'
            );
      case ChannelTypesEnum.AggregatedTankUsage:
        return readOnly
          ? PLATFORM.moduleName(
              './templates/channeltypedetails-aggregatedtankusage-read.html'
            )
          : PLATFORM.moduleName(
              './templates/channeltypedetails-aggregatedtankusage-edit.html'
            );
      case ChannelTypesEnum.Dose:
        return readOnly
          ? PLATFORM.moduleName('./templates/channeltypedetails-dose-read.html')
          : PLATFORM.moduleName(
              './templates/channeltypedetails-dose-edit.html'
            );
      case ChannelTypesEnum.AggregatedDose:
        return readOnly
          ? PLATFORM.moduleName(
              './templates/channeltypedetails-aggregateddosechannel-read.html'
            )
          : PLATFORM.moduleName(
              './templates/channeltypedetails-aggregateddosechannel-edit.html'
            );
      case ChannelTypesEnum.StockVsDoseAccuracy:
        return readOnly
          ? PLATFORM.moduleName(
              './templates/channeltypedetails-tankvsdose-read.html'
            )
          : PLATFORM.moduleName(
              './templates/channeltypedetails-tankvsdose-edit.html'
            );
      default:
        return undefined;
    }
  }

  showUnit = (unit: IUnit | undefined) =>
    isNone(unit)
      ? undefined
      : `${this.i18n.tr(unit.translationKey)} (${unit.symbol})`;

  channelIsTank = (channel: IChannelDetails) => channelIsTankChannel(channel);
  channelIsAggregatedTankUsage = (channel: IChannelDetails) =>
    channelIsAggregatedTankUsageChannel(channel);
  channelIsRetentionTime = (channel: IChannelDetails) =>
    channelIsRetentionTime(channel);
  channelIsDose = (channel: IChannelDetails) => channelIsDoseChannel(channel);
  channelIsAggregatedDoseChannel = (channel: IChannelDetails) =>
    channelIsAggregatedDoseChannel(channel);
  channelIsStockVsDoseChannel = (channel: IChannelDetails) =>
    channelIsStockVsDoseChannel(channel);

  addSiteChannelToExportJob = (
    existing: IExportJobNameAndId[],
    jobId: number
  ) => {
    const jobToAdd = this.state.exportJobConfig?.allExportJobs.filter(e => e.exportJobId == jobId);
    
    //Don't add if already present
    if (jobToAdd && existing.findIndex(j => j.exportJobId == jobToAdd[0].exportJobId) < 0)
      this.dispatch(addSiteChannelToExportJob({ existing, job: jobToAdd[0] }));
  }

  removeSiteChannelFromExportJob = (
    existing: IExportJobNameAndId[],
    jobId: number
  ) => {
    this.dispatch(removeSiteChannelFromExportJob({ existing, jobId }));
  }

  toggleConfirmDeleteChannel = () =>
    this.dispatch(toggleConfirmDeleteChannel());

  validateChannelProperties = () => {
    if (isSomething(this.state.channel))
      this.dispatch(
        validateChannel(getEntityOrUndefined(this.state.channel) as IChannel)
      );
  };

  editAndValidate(key: keyof IChannel, value: string | number) {    
    this.editChannelProperty(key, value);
    this.validateChannelProperties(); 
  }
}
