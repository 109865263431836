import React, { useRef, useState, FC, memo } from 'react';
import { Button, CardHeader, IconButton, Typography } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { GetControllerParametersDocument } from '$typings/graphql-codegen';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import { ControllerParameterList } from './modules/controller-parameter-list';
import { hasAccessToSecurityLevel } from '$lib/authorizationHelper';
import { SecurityLevel } from '$interfaces/enums/securityLevel';
import { getSession } from '../../../../../../config/sessionService';
import { ControllerParameterHistory } from './modules/controller-parameter-history';
import { ScrollableMuiCardContent } from '$components/cards/mui-card';
import { CardFlexColumn } from '$components/cards/mui-card';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { IStatusMessage } from '$pages/common/controllers/controllerdetails-react/modules/controller-parameters/modules/common/status-message';
import CardFooter from '$components/cards/card-footer/card-footer.react';

export interface IControllerParametersProps {
  controllerId: number;
  readonly?: boolean
}

export interface ParameterHistoryInfo {
  id: number;
  name: string;
}

const ControllerParametersComponent: FC<IControllerParametersProps> = ({
  controllerId,
  readonly
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [updateFromController, setUpdateFromController] = useState(false);
  const [showHistoryForParameter, setShowHistoryForParameter] = useState<
    ParameterHistoryInfo | undefined
  >(undefined);
  const [statusMessage, setStatusMessage] = useState<
    IStatusMessage | undefined
  >(undefined);
  const cardContentRef = useRef<HTMLDivElement | null>(null);

  const { data, loading, error, revalidate, isRevalidating } = useQuery(
    GetControllerParametersDocument,
    {
      controllerId: controllerId,
      fromController: updateFromController,
    },
    undefined,
    1
  );

  const showHistory = (parameterId?: number, name?: string) => {
    if (parameterId && name) {
      setShowHistoryForParameter({ id: parameterId, name: name });
    } else {
      setShowHistoryForParameter(undefined);
    }
  };

  const closeAction = () => {
    if (showHistoryForParameter) setShowHistoryForParameter(undefined);
  };

  const session = getSession();
  const accessToSecurityLevel = hasAccessToSecurityLevel(
    session,
    SecurityLevel.High
  );

  const refreshControllerParameters = () => {
    if (updateFromController) {
      revalidate?.();
    } else {
      setUpdateFromController(true);
    }
  };

  const cardTitle = showHistoryForParameter
    ? `${showHistoryForParameter.name} ${t('ui_controllerparameter_history')}`
    : t(
        'UI_SiteDetails_Controllers_ControllerDetails_ControllerParameters_Header'
      );

  return (
    <>
      <CardFlexColumn>
        <CardHeader
          title={<Typography variant="h6">{cardTitle}</Typography>}
          action={
            showHistoryForParameter ? (
              <IconButton size="small" onClick={closeAction}>
                <Icon name={'fa-times'} />
              </IconButton>
            ) : (
              <>
                {!readonly && <IconButton
                  size="small"
                  onClick={refreshControllerParameters}
                  disabled={loading || isRevalidating}
                >
                  <Icon
                    name={
                      loading || isRevalidating
                        ? 'fa-spinner fa-pulse'
                        : 'fa-refresh'
                    }
                  />
                </IconButton>}
              </>
            )
          }
        />
        <ScrollableMuiCardContent ref={cardContentRef}>
          {loading ? (
            <LoadingBars center />
          ) : error ? (
            <div className="mar_m">
              <Typography variant="h6">
                {t('UI_Common_Error_Loading_Data_Failed_Headline')}
              </Typography>
              <Typography>
                {t('UI_Common_Error_Loading_Data_Failed')}
              </Typography>
              <Typography>
                {t('UI_Common_Error_Controller_May_Be_Unavailable')}
              </Typography>
              <Button variant="outlined" onClick={revalidate}>
                {t('UI_Common_Try_Again')}
              </Button>
            </div>
          ) : !data || !data.controller?.parameters.length ? (
            <div className="mar_rm mar_lm">
              <p>
                {t(
                  'UI_SiteDetails_Controllers_ControllerDetails_ControllerParameters_None'
                )}
              </p>
              <Button
                variant="outlined"
                sx={{ marginTop: '1rem' }}
                onClick={refreshControllerParameters}
              >
                {t(
                  'UI_SiteDetails_Controllers_ControllerDetails_ControllerParameters_Actions_FetchParameters'
                )}
              </Button>
            </div>
          ) : showHistoryForParameter ? (
            <ControllerParameterHistory
              controllerParameterId={showHistoryForParameter.id}
              closeView={closeAction}
            />
          ) : (
            <ControllerParameterList
              data={data}
              accessToSecurityLevel={accessToSecurityLevel}
              showHistory={showHistory}
              setStatusMessage={setStatusMessage}
              readonly={readonly}
            />
          )}
          {statusMessage && !showHistoryForParameter && (
            <CardFooter topBorder className="flex jsb aicenter">
              {statusMessage.message}
              {statusMessage.dismissible && (
                <IconButton onClick={() => setStatusMessage(undefined)}>
                  <Icon name={'fa-times'} />
                </IconButton>
              )}
            </CardFooter>
          )}
        </ScrollableMuiCardContent>
      </CardFlexColumn>
    </>
  );
};

export const ControllerParameters = memo(ControllerParametersComponent);
