import { columnKey } from '$components/grid/grid';
import { SortDirection } from '$typings/graphql-codegen';
import { useDrag, useDrop } from 'react-dnd';
import React, { MouseEvent as ReactMouseEvent, useCallback, useRef } from 'react';
import { isNone } from '$lib/helpers';
import { ensureNumber } from '$lib/numberHelpers';
import Icon from '$components/icons/icon/icon.react';
import SortIcon from '$components/icons/svg/react/sort-icon';
import useIsVisible from '$lib/hooks/isVisible';
import Tooltip from '$components/tooltip/tooltip.react';
import classNames from "classnames";

export type ColumnWidthChanged = (columnKey: columnKey, width: string | number) => void;

interface GridTableHeaderCellProps {
  editMode: boolean;
  columnKey: columnKey;
  sortedColumnKey: columnKey;
  sortedDirection: SortDirection;
  sortClicked?: (column: columnKey) => void;
  filterIconClicked?: (column: columnKey) => void;
  width: number | string;
  widthChanged: ColumnWidthChanged;
  popover?: React.ReactElement;
  className?: string;
  columnIndex?: number;
  moveRow?: (dragIndex: number, hoverIndex: number) => void;
  hasActiveFilter?: boolean;
  hasFilters?: boolean;
  sortingDisabled?: boolean;
  disallowReordering?: boolean
}

interface IDraggableItem {
  index?: number;
}

interface IDropItemProps {
  isOver: boolean;
}

export function GridTableHeaderCell({
  popover,
  children,
  sortClicked,
  columnKey,
  width,
  editMode,
  filterIconClicked,
  sortedDirection,
  sortedColumnKey,
  widthChanged,
  className,
  columnIndex,
  moveRow,
  hasActiveFilter,
  hasFilters = false,
  sortingDisabled = false,
  disallowReordering,
}: React.PropsWithChildren<GridTableHeaderCellProps>): React.ReactElement {
  const [, drag] = useDrag<IDraggableItem>(
    () => ({
      type: 'TableHeaderCell',
      item: { index: columnIndex },
      collect: monitor => ({ isDragging: monitor.isDragging() }),
      canDrag: () => editMode && !disallowReordering
    }),
    [editMode, columnIndex]
  );

  const [{ isOver }, drop] = useDrop<IDraggableItem, {}, IDropItemProps>(() => ({
    accept: 'TableHeaderCell',
    collect: monitor => ({ isOver: monitor.isOver() }),
    drop: item => {
      if (!moveRow || isNone(item.index) || isNone(columnIndex)) return;
      if (item.index === columnIndex) return;

      moveRow(item.index, columnIndex);
      return undefined;
    }
  }), [editMode, columnIndex]);
  const ref = useRef<HTMLDivElement | null>(null);
  const isVisible = useIsVisible(ref);

  function filterClicked(e: ReactMouseEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    filterIconClicked && filterIconClicked(columnKey);
  }

  const resizeHandler = useCallback(
    mouseDownEvent => {
      mouseDownEvent.preventDefault();
      const dragStartPoint = mouseDownEvent.clientX;
      function onMouseMove(e: MouseEvent) {
        const diffX = e.clientX - dragStartPoint;
        const pendingWidth = Math.max(ensureNumber(width) + diffX, 90);
        widthChanged(columnKey, pendingWidth);
      }
      function onMouseUp() {
        removeEventListener('mousemove', onMouseMove);
        removeEventListener('mouseup', onMouseUp);
      }
      addEventListener('mousemove', onMouseMove);
      addEventListener('mouseup', onMouseUp);
    },
    [width, columnKey]
  );

  const getTooltipText = useCallback(() => {
    return !isVisible && !editMode ? children?.toString() : undefined
  }, [editMode, width, children])

  return (
    <th ref={drag} style={{ position: 'relative', width }} className={className}>
      <Tooltip text={getTooltipText()} customClass="flex">
        <div
          ref={drop}
          className={classNames(
            'th-aligner',
            editMode && 'resizable'
          )}
          onClick={() => !editMode && !sortingDisabled && sortClicked && sortClicked(columnKey)}
          style={
            isOver
              ? { width, cursor: !disallowReordering ? 'pointer' : '', position: 'relative', backgroundColor: '#eee', color: 'transparent', border: '2px dashed #ccc' }
              : { width, cursor: !disallowReordering && (hasFilters || !sortingDisabled) ? 'pointer' : 'auto', position: 'relative' }
          }
        >
          <span className="text-slot">
            <span className="text-slot-inner">
              {children}
              <span ref={ref} />
            </span>
          </span>
          <div className="actions">
            {!editMode && hasFilters && filterIconClicked && (
              <div onClick={!editMode && filterClicked} className={classNames('search-icon', hasActiveFilter && 'active')}>
                <Icon name="fa-search" />
              </div>
            )}
            {!sortingDisabled && !editMode  && sortClicked && <SortIcon active={columnKey === sortedColumnKey} sortDirection={sortedDirection} className="pad_s" />}
            {editMode && (
              <span className="width-adjuster" onMouseDown={editMode && resizeHandler}>
                <div className="draggable-width">
                  <Icon name="fa-caret-left" className="cursor-col-resize" />
                </div>
              </span>
            )}
          </div>
        </div>
      </Tooltip>
      {popover}
    </th>
  );
}
