import { isNone } from "$lib/helpers";
import { ReactReportFiltersCardQueryQuery, ReportFilter } from "$typings/graphql-codegen";
import { IReportFilter } from "./IReportFilter";


export interface IBuidBrief {
  buidId: number;
  name: string;
}

export interface IAccessTagBrief {
  customerId: number;
  name: string;
}

export interface IProductBrief {
  code: string;
  productId: string;
  name: string;
  bestMatchTranslation: string;
}

export const firstControllerTypeFilterOrEmpty = (data: ReactReportFiltersCardQueryQuery, partialMatch: boolean = true, exclude: boolean = false): IReportFilter =>
  firstMatchingFilterOrEmpty(data?.scheduledReport?.controllerTypeFilters, partialMatch, exclude);

export const firstBuidFilterOrEmpty = (data: ReactReportFiltersCardQueryQuery, partialMatch: boolean = true, exclude: boolean = false): IReportFilter =>
  firstMatchingFilterOrEmpty(data?.scheduledReport?.buidFilters, partialMatch, exclude);

export const firstAccessTagFilterOrEmpty = (data: ReactReportFiltersCardQueryQuery, partialMatch: boolean = true, exclude: boolean = false): ReportFilter =>
  firstMatchingFilterOrEmpty(data?.scheduledReport?.accessTagFilters, partialMatch, exclude);

export const firstProductFilterOrEmpty = (data: ReactReportFiltersCardQueryQuery, partialMatch: boolean = false, exclude: boolean = false): ReportFilter =>
   firstMatchingFilterOrEmpty(data?.scheduledReport?.productFilters, partialMatch, exclude);

// soldTos are exact matched
export const firstSoldToFilterOrEmpty = (data: ReactReportFiltersCardQueryQuery, partialMatch: boolean = false, exclude: boolean = false): ReportFilter =>
   firstMatchingFilterOrEmpty(data?.scheduledReport?.soldToFilters, partialMatch, exclude);

// shipTos are exact matched
export const firstShipToFilterOrEmpty = (data: ReactReportFiltersCardQueryQuery, partialMatch: boolean = false, exclude: boolean = false): ReportFilter =>
   firstMatchingFilterOrEmpty(data?.scheduledReport?.shipToFilters, partialMatch, exclude);

export const addTermToReportFilter = (term: string, filter: IReportFilter | undefined | null, partialMatch: boolean = true, exclude: boolean = false): IReportFilter =>
  ((filter === undefined || filter === null) 
    ? { values: [term], partialMatch, exclude }
    : { ...filter, values: [...filter.values, term ]});

export const removeTermFromReportFilter = (term: string, filter: IReportFilter | undefined | null): IReportFilter =>
  ((filter === undefined || filter === null) 
    ? { values: [], partialMatch: true, exclude: false }
    : { ...filter, values: filter.values.filter(val => val !== term)});

// Converter function for sanitizing data coming from GraphQL into a much less complicated interface type:
export const toBuidBrief = (buid: { buidId?: string | null | undefined, name?: string | null | undefined } | null | undefined,
                      toLowercase: boolean = true): IBuidBrief | undefined => {
  if (isNone(buid) || isNone(buid?.name) || isNone(buid?.buidId)) {
    return undefined;
  }
  const nameValue = buid?.name ?? '';
  return {
    buidId: parseInt(buid?.buidId ?? '0', 10),
    name: toLowercase ? nameValue.toLocaleLowerCase() : nameValue
  }
};

// Converter function for sanitizing data coming from GraphQL into a much less complicated interface type:
export const toAccessTagBrief = (accessTag: { customerId: string, name?: string | null | undefined } | null | undefined, 
                          toLowercase: boolean = true): IAccessTagBrief | undefined => {
  if (isNone(accessTag) || isNone(accessTag?.name) || isNone(accessTag?.customerId)) {
    return undefined;
  }
  const nameValue = accessTag?.name ?? '';
  return {
    customerId: parseInt(accessTag?.customerId ?? '0', 10),
    name: toLowercase ? nameValue.toLocaleLowerCase() : nameValue
  }
}

// Converter function for sanitizing data coming from GraphQL into a much less complicated interface type:
export const toProductBrief = (product: { code?: string | null, productId?: string | null, name?: string | null, translation: { key?: string | null, languageCode?: string | null, translation?: string | null }}) : IProductBrief | undefined => {
  if (isNone(product)) return undefined;

  let bestMatchTranslation = !isNone(product.translation) ? product.translation.translation : product.name;
  if (bestMatchTranslation === null || bestMatchTranslation === undefined || bestMatchTranslation?.trim() === '') {
    return undefined;
  }

  return {
    code: product.code || '',
    productId: product.productId || '',
    name: product.name || '',
    bestMatchTranslation
  };
}



export const reportFilterIsFalsyOrEmpty = (reportFilter?: IReportFilter | null): boolean => {
  if (isNone(reportFilter)) return true;
  const rf = reportFilter as IReportFilter;
  
  if (rf.values.every(term => isNone(term) || term.toString().trim().length === 0)) {
      return true; // none of the terms are anything
  }
  return false;
}

export const reportFilterHasValues = (reportFilter?: IReportFilter | null): boolean => !reportFilterIsFalsyOrEmpty(reportFilter);

const firstMatchingFilterOrEmpty = (reportFilters: IReportFilter[] | null | undefined, partialMatch: boolean = true, exclude: boolean = false): IReportFilter => {
  const matching = reportFilters?.filter(filter => filter.partialMatch === partialMatch && filter.exclude === exclude);
  const values: string[] = ((matching?.[0]?.values) ? matching[0].values : []);

  return ({
    values,
    partialMatch,
    exclude
  });
};

export const stringifyReportFilter = (reportFilter: IReportFilter): string => {
  return reportFilter.values.join(', ');
}
