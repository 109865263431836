import React, {ReactNode} from 'react'
import classNames from "classnames";
import GridCell from "$components/grid/react-grid/cell/grid-cell";
import {IReactGridColumn} from "$components/grid/react-grid/grid.react";

type Callback<TArg, TReturn> = (arg: TArg) => TReturn
export type ResolvableData = string | number | JSX.Element
export type RenderFunction<TRow> = Callback<TRow, ResolvableData>

/**
 * # Column Definition 🏛️
 * A generic class that can define columns to be used in reports.
 */
export class ColumnDefinition<TRow> {
  public static readonly DEFAULT_COLUMN_WIDTH = 200
  
  public title: string
  public render: RenderFunction<TRow | ReactNode>
  public columnKey: string
  public columnWidth: number | string
  public columnUrlCallback: Callback<TRow, string> | null

  /**
   * @constructor
   * @param title Column header title.
   * @param render Callback used to render the data to be displayed inside the cell.
   * @param columnKey Object key used to sort the array and must point to sortable data.
   * @param columnWidth The width of the column (optional, default is 'auto').
   * @param columnUrlCallback Callback used to resolve a URL string for the given column (optional).
   */
  public constructor(
    title: string,
    render: RenderFunction<TRow>,
    columnKey: string,
    columnWidth?: number | string,
    columnUrlCallback?: Callback<TRow, string> | null
  ) {
    this.title = title
    this.render = render
    this.columnKey = columnKey
    this.columnWidth = columnWidth ?? ColumnDefinition.DEFAULT_COLUMN_WIDTH
    this.columnUrlCallback = columnUrlCallback ?? null    
  }

  /**
   * # Create `GridCell` component
   * @param child Text inside the cell.
   * @param width Width of the cell.
   * @param url Corresponding URL string, if applicable.
   * @private
   */
  private static createGridCell(
    child: ResolvableData,
    width?: number | string,
    url?: string
  ): JSX.Element {
    return (
      <GridCell
        width={width}
        className={classNames(url && 'link clickable')}
        columnLink={url ?? ''}>
        { child }
      </GridCell>
    )
  }

  /**
   * # Create React Grid Column Object
   * Convert a `ColumnDefinition` instance into a react grid column object.
   */
  public createReactGridColumn(): IReactGridColumn<ReactNode> {
    return {
      columnTitle: this.title,
      columnKey: this.columnKey,
      initialWidth: this.columnWidth,
      render: (row: ReactNode, width?: string | number | undefined) => (
        ColumnDefinition.createGridCell(
          this.render(row),
          width,
          this.columnUrlCallback && this.columnUrlCallback(row as TRow) || undefined
        )
      )
    }
  }
}
