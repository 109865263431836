import React, { FC } from 'react';
import './simple-table.css';
import classNames from "classnames";

interface ISimpleTableProps {
  layout?: 'auto' | 'fixed';
  className?: string;
}

const SimpleTable: FC<ISimpleTableProps> = ({ children, layout = 'auto', className }) => (
  <div className={classNames('simple-table-component', layout && `layout-${layout}`, className)}>{children}</div>
);

export default SimpleTable;
