import React, { FC } from "react";
import Dropdown from "$components/dropdowns/dropdown/dropdown.react";
import { getTimezonesSorted } from "$lib/timezone/timezoneHelpers";
import { ITimeZone } from "../../../interfaces";


interface ITimezoneDropdownProps {
  selected?: ITimeZone | string;
  topElement?: string | boolean | 'IF_QUERY';
  itemClicked?: (params: { timezoneId?: number }) => void;
  error?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}

const TimezoneDropdown: FC<ITimezoneDropdownProps> = ({ selected, topElement, itemClicked, error, className, placeholder, disabled }) => {
  const zones = getTimezonesSorted()

  return (
    <Dropdown<ITimeZone>
      className={className}
      items={zones}
      displayPath="description"
      valuePath="timezoneId"
      selected={selected}
      loading={false}
      itemClicked={params => itemClicked && itemClicked({timezoneId: params?.value as number})}
      topElement={topElement}
      placeholder={placeholder}
      error={error}
      disabled={disabled}
    />
  );
};



export default TimezoneDropdown;
