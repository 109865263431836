// This is a bit of a nasty hack. We have a problem where the routing is set up in Aurelia.
// We have tried different solutions:
//
//   - Sending in aureliaRouter as a prop to React. It works but is not ideal; sometimes it's
//     necessary to pass it down multiple layers, and it will require a lot of cleanup when we are fully in React.
//
//   - Setting up reactRouter for bigger rewrites. ControllerManager and all sub-pages are fully in React. Here,
//     we have reactRouter working. The problem here is that it doesn't fit for smaller rewrites, like a single card in siteDetails, etc.
//     This is due to the fact that it takes quite some code/time and it doesn't work to navigate from this reactRouter setup
//     and out to anything else (Aurelia or React that is not set up within the same reactRouter). Meaning you probably have to use one of the other methods too.
//     We could probably reuse some things when doing it this way, but setting up reactRouter is going to be a big task when we are done rewriting anyway.
//
//   - Using `location.href = 'new-url'`. This is the easiest to use. The problem is that we get a "full refresh".
//     The entire page loads again, and this is slow and a poor user experience.


// I noticed that when we click on an HTML <a> element, we did not get the drawback of a full refresh, and we did not need any of the routers.
// But sometimes we need to redirect/link without having the option to use an <a> tag. This is where this hook can be used. It returns a function 
// that creates an <a> element and puts it in another element in app.html. Then it clicks it, all using JS. We don't get the "full refresh," and when we are done
// rewriting things to React, we can just replace the usage of this with "useNavigate" from reactRouter.
export function useHackyNavigate() {
  const navigate = (url: string) => { 
    const a = document.createElement("a")
      a.setAttribute("href", url)
      document.getElementById("hidden-navigate-element")?.appendChild(a)      
      a.click();
  }
  return navigate
}