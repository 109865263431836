import { autoinject } from 'aurelia-framework';
import {
  BaseViewModel,
  getSiteIdParameter,
  getTimezoneForSiteSelector
} from '../../../common';
import { rootState } from '../../../../reducers';
import {
  INote,
  ICurrentlyEditedNote,
  AccessLevel
} from '../../../../interfaces';
import { menuIsOpenForEntity } from '../../../../types';
import { NoteService } from '../../../../services';
import {
  markNoteForEditAction,
  toggleExpandMenuOnEntity,
  noteValuesEdited,
  cancelNoteEditAction
} from '../../../../actions';
import { notesForSiteSelector } from '../../selectors';
import { getLogger } from 'aurelia-logging';
import { userHasFeature, isNone } from '../../../../utility';
import { features } from '../../../../config';
import {
  getSession,
  getSessionUserId
} from '../../../../config/sessionService';
import './sitedetailsnotes.css';

interface ISiteNotesState {
  notes: INoteDetails[];
  currentlyEditedNote: ICurrentlyEditedNote;
  menuIsOpenForEntity?: menuIsOpenForEntity;
  siteId: number;
  timezone?: string;
  access: {
    delete: boolean;
    write: boolean;
    update: boolean;
  };
}

interface INoteDetails extends INote {
  canEdit: boolean;
  canDelete: boolean;
}

const mapState = (state: rootState): ISiteNotesState => {
  const access = {
    write:
      userHasFeature(getSession(), features.ownSiteNotes, AccessLevel.Write) ||
      userHasFeature(getSession(), features.allSiteNotes, AccessLevel.Write),
    update: userHasFeature(
      getSession(),
      features.allSiteNotes,
      AccessLevel.Write
    ),
    delete: userHasFeature(
      getSession(),
      features.allSiteNotes,
      AccessLevel.Delete
    )
  };

  return {
    notes: notesForSiteSelector(state).map(note => ({
      ...note,
      canEdit: access.update || note.modifiedBy.userId === getSessionUserId(),
      canDelete: access.delete || note.modifiedBy.userId === getSessionUserId()
    })),
    timezone: getTimezoneForSiteSelector(state),
    siteId: getSiteIdParameter(state).valueOr(0),
    menuIsOpenForEntity: state.application.menuIsOpenForEntity,
    currentlyEditedNote: state.sitedetails.currentlyEditedNote,
    access
  };
};

@autoinject()
export class SiteNotes extends BaseViewModel<ISiteNotesState> {
  noteTypes = [
    { name: 'UI_SiteDetails_Notes_NoteTypes_SiteNote', value: 0 },
    // { name: 'UI_SiteDetails_Notes_NoteTypes_ShippingInstructions', value: 1 },
    { name: 'UI_SiteDetails_Notes_NoteTypes_DisplayNote', value: 2 }
  ];

  constructor(private noteService: NoteService) {
    super(getLogger('SiteNotes'), mapState);
  }

  activate() {
    this.noteService.getAllNotesForSite(this.state.siteId);
  }

  markNoteForEdit(note: INote) {
    this.dispatch(markNoteForEditAction(note));
  }

  toggleExpandMenuOnEntity(note: INote) {
    this.dispatch(toggleExpandMenuOnEntity(note));
  }

  noteValueChanged(text: string) {
    const { currentlyEditedNote } = this.state;
    if (!currentlyEditedNote) return;
    const { noteType, text: previousText } = currentlyEditedNote;
    if (previousText === text) return;

    this.dispatch(noteValuesEdited({ noteType, text }));
  }

  noteTypeChanged(noteType: number) {
    const { currentlyEditedNote } = this.state;
    if (!currentlyEditedNote) return;
    const { noteType: previousNoteType, text } = currentlyEditedNote;
    if (noteType === previousNoteType) return;

    this.dispatch(noteValuesEdited({ noteType, text }));
  }

  getNoteTypeByValue(value: number) {
    return this.noteTypes.find(n => n.value === value);
  }

  postNote() {
    const { siteId, currentlyEditedNote } = this.state;
    if (!currentlyEditedNote) return;

    const { text, noteId, noteType } = currentlyEditedNote;

    if (text.trim().length === 0) return;

    if (!noteId) this.noteService.postNewNoteForSite(siteId, text, noteType);
    else
      this.noteService.updateExistingNoteOnSite(siteId, noteId, text, noteType);
  }

  deleteNote(note: INote) {
    if (!isNone(note.noteId))
      this.noteService.deleteNote(note.siteId, note.noteId);
  }

  cancelEdit() {
    this.dispatch(cancelNoteEditAction());
  }
}
