import SiteChannelBar from '$components/bars/site-channel-bar/site-channel-bar.react';
import { pureFormatDate, timeBetweenDateTranslation } from '$lib/dateHelpers';
import BatteryBar from '$pages/sitelistpage-react/modules/channeldisplay/battery-bar/battery-bar.react';
import { ElasticSearchPage, ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_activeAlarms } from '$typings/graphql';
import { HealthEnum, SignalEnum, SitelistItemFragment, SiteListPageRowChannel, SiteListPageRowProduct } from '$typings/graphql-codegen';
import React from 'react';
import { getUserFeatures } from '../../../src/config/sessionService';
import { ColumnAccess } from '../../../src/config';
import DateFilter from './modules/filters/datefilter/datefilter.react';
import NumberFilter from './modules/filters/numberfilter/numberfilter.react';
import RangeFilter from './modules/filters/rangefilter/rangefilter.react';
import RefFilter from './modules/filters/reffilter/reffilter';
import StringFilter from './modules/filters/stringfilter/stringfilter.react';
import { useTranslation } from 'react-i18next';
import { alarmTypesLowerCase } from '../../../src/models';
import { displayMeasurement, IMeasurementUnit } from '$lib/unitConverters';
import BooleanFilter from './modules/filters/booleanfilter/booleanfilter.react';
import Icon from '$components/icons/icon/icon.react';
import { Clippy } from '$components/clippy/clippy';
import LastDeliveryTicket from '$pages/sitelistpage-react/modules/columns/lastdeliveryticket/lastdeliveryticket.react';
import { isSomething } from '$lib/helpers';
import MutedAlarms from '$pages/sitelistpage-react/modules/columns/mutedalarms/mutedalarms.react';
import StockVsDoseBar from '$pages/sitelistpage-react/modules/channeldisplay/stock-vs-dose-bar/stock-vs-dose-bar.react';
import { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import IdsFilter from './modules/filters/idsfilter/ids-filter.react';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

function generateTooltipTextForReceivingDeliveryTickets(channels: SiteListPageRowChannel[] | null | undefined, i: number | null | undefined) {
  const lastDeliveryChannel = channels?.find(c => c.siteChannelId === i)
  const lastDelivery = lastDeliveryChannel?.tankDetails?.lastFilled;
  return lastDelivery ? pureFormatDate(lastDelivery, false, '.') : undefined;
}

function generateTooltipTextForIcon(channel: SiteListPageRowChannel | null): string {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [t] = useCaseInsensitiveTranslation();

  if (!channel) return '';

  switch (channel.health) {
    case HealthEnum.Offline:
      return t('ui_sitedetails_alarms_offline');
    case HealthEnum.Stale:
      return t('ui_sitedetails_alarms_noconsumption');
    case HealthEnum.HasAlarm:
      return generateActiveAlarmTooltipText(channel)
    default:
      return '';
  }
}

function generateTooltipTextForSignalStrength(lastSampleTime?: string | null) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [t] = useCaseInsensitiveTranslation();
  if (!lastSampleTime) return;
  const duration = timeBetweenDateTranslation(true, lastSampleTime);
  return duration?.count + ' ' + t(duration?.key.toLocaleLowerCase() as string) + (duration?.count || 0 > 1 ? 's' : '') + ' since last sample';
}

const getNameForAlarm = (activeAlarm: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_activeAlarms): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [t] = useCaseInsensitiveTranslation();
  if (activeAlarm.name) return activeAlarm.name;
  return t(alarmTypesLowerCase[activeAlarm.alarmType]);
};

function generateActiveAlarmTooltipText(tankChannel: SiteListPageRowChannel | null): string {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [t] = useCaseInsensitiveTranslation();
  if (!tankChannel) return '';
  const activeAlarms = tankChannel.activeAlarms;
  return t('ui_sitelist_activealarms', { activeAlarms: activeAlarms?.map(getNameForAlarm), count: activeAlarms?.length });
}

function generateTooltipTextForTank(tankChannel: SiteListPageRowChannel | null): string {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [t] = useCaseInsensitiveTranslation();
  if (!tankChannel) return '';

  const channel = tankChannel;

  switch (channel.health) {
    case HealthEnum.Offline:
      return t('ui_sitedetails_alarms_offline');
    case HealthEnum.Stale:
      return t('ui_sitedetails_alarms_noconsumption');
    case HealthEnum.HasAlarm:
      return generateActiveAlarmTooltipText(channel)
  }

  return displayMeasurement(channel.lastSample, channel.unit as IMeasurementUnit, true);
}

const features = getUserFeatures();

const getTranslatedProductName = (product?: SiteListPageRowProduct ) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [, n] = useTranslation();
  if (!product) return;
  switch (n.language) {
    case "de-DE":
      return product.alias_deDE;
    case "en-GB":
      return product.alias_enGB;
    case "es-ES":
      return product.alias_esES;
    case "fr-FR":
      return product.alias_frFR;
    case "hu-HU":
      return product.alias_huHU;
    case "it-IT":
      return product.alias_itIT;
    case "nb-NO":
      return product.alias_nbNO;
    case "nl-NL":
      return product.alias_nlNL;
    case "pl-PL":
      return product.alias_plPL;
    case "sv-SE":
      return product.alias_svSE;
    default:
      return product.name;
  }
};

const removeBlank = (text: string | null | undefined) => {
  if (text === '<blank>')
    return '';

  return text;
}

export const getColumns: (openSiteInNewTab?: boolean) => IReactGridColumn<SitelistItemFragment>[] = (openSiteInNewTab) => ([
  {
    columnKey: ColumnAccess.SiteName,
    columnTitle: 'ui_sitelist_column_sitename',
    render: (item, width, highlightText) => openSiteInNewTab ? (
      <GridCell
        highlightText={highlightText}
        width={width}
        openLinkInNewTab
        columnLink={`sitedetails/${item.siteId}`}
        className="sitename-link"
      >
        {item.alias.trim()}
      </GridCell>
      ) : (
      <GridCell highlightText={highlightText} width={width}>
        {item.alias.trim()}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    required: true,
    filter: {
      name: 'ui_sitelist_column_sitename',
      property: 'alias',
      component: StringFilter
    }
  }, 
  {
    columnKey: ColumnAccess.SiteAddress1,
    columnTitle: 'ui_sitelist_column_siteaddress1',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.addressLine1}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_siteaddress1',
      property: 'addressLine1',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.SiteAddress2,
    columnTitle: 'ui_sitelist_column_siteaddress2',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.addressLine2}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_siteaddress2',
      property: 'addressLine2',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.SiteCity,
    columnTitle: 'ui_sitelist_column_sitecity',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.city}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_sitecity',
      property: 'city',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.SoldTo,
    columnTitle: 'ui_sitelist_column_soldto',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.soldTo}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_soldto',
      property: 'soldto',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.Buid,
    columnTitle: 'ui_sitelist_column_buid',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.buid?.name}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_buid',
      property: 'buid',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.SiteId,
    columnTitle: 'ui_sitelist_column_siteid',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.siteId}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_siteid',
      property: 'siteid',
      component: IdsFilter,
    }
  },
  {
    columnKey: ColumnAccess.CustomerName,
    columnTitle: 'ui_sitelist_column_customername',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.customer?.name}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_customername',
      property: 'customerName',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.AccessTagAlias,
    columnTitle: 'ui_sitelist_column_accesstagalias',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.customer?.alias}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_accesstagalias',
      property: 'accessTagAlias',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.CountryCode,
    columnTitle: 'ui_sitelist_column_countrycode',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.countryCode}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_countrycode',
      property: 'countryCode',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.Postcode,
    columnTitle: 'ui_sitelist_column_postcode',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.postcode}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_postcode',
      property: 'postCode',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.ContractNumber,
    columnTitle: 'ui_sitelist_column_contractreference',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.contractReference}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_contractreference',
      property: 'contractReference',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.CustomerRef,
    columnTitle: 'ui_sitelist_column_customerref',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.customerRef}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_customerref',
      property: 'customerRef',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.CreationServiceNowTicket,
    columnTitle: 'UI_SiteDetails_DetailsList_Labels_ServiceNow',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.creationServiceNowTicket}
      </GridCell>
    ),
    initialWidth: 150,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'UI_SiteDetails_DetailsList_Labels_ServiceNow',
      property: 'creationServiceNowTicket',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.ProjectCode,
    columnTitle: 'ui_sitelist_column_projectcode',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.projectCode}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_projectcode',
      property: 'projectcode',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.SalesPerson,
    columnTitle: 'ui_sitelist_column_salesperson',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {removeBlank(item.salesContact?.initials)}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_salesperson',
      property: 'salesPerson',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.OrderPerson,
    columnTitle: 'ui_sitelist_column_orderperson',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {removeBlank(item.orderContact?.name)}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_orderperson',
      property: 'orderpersonref',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.TechPerson,
    columnTitle: 'ui_sitelist_column_techperson',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {removeBlank(item.techContact?.name)}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_techperson',
      property: 'techpersonref',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.CustomerPerson,
    columnTitle: 'ui_sitelist_column_customerperson',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {removeBlank(item.customerContact?.name)}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_customerperson',
      property: 'customerpersonref',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.TransportPerson,
    columnTitle: 'ui_sitelist_column_transportperson',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {removeBlank(item.transportContact?.name)}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_transportperson',
      property: 'transportpersonref',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.DealerPerson,
    columnTitle: 'ui_sitelist_column_dealerperson',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {removeBlank(item.dealerContact?.name)}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_dealerperson',
      property: 'dealerpersonref',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.Description,
    columnTitle: 'ui_sitelist_column_description',
    render: (item, width, highlightText) => (
      <GridCell highlightText={highlightText} width={width}>
        {item.description}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_site',
    filter: {
      name: 'ui_sitelist_column_description',
      property: 'sitedescription',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.TankPercentFull,
    columnTitle: 'ui_sitelist_column_percentfull',
    hasOwnClickHandler: features.siteTanks.read,
    render: (item, width) => {
      return item.tankChannels?.map((tc, i) => {
        const link = tc.siteChannelId > 0 ? `/sitedetails/${item.siteId}/tanks/tankDetails/${tc.siteChannelId}` : `/sitedetails/${item.siteId}/tanks`;
        return (
          <GridCell
            key={"TankPercentFull" + tc.siteChannelId + i}
            width={width}
            columnLink={features.siteTanks.read ? link : undefined}
            tooltipText={generateTooltipTextForTank(tc as SiteListPageRowChannel)}

          >
            {isSomething(tc.percentage) && (
              <SiteChannelBar
                lastSample={tc.lastSample}
                maximum={tc.maximum}
                minimum={tc.minimum}
                percentage={tc.percentage}
                capacity={tc.capacity}
                lastSampleTime={tc.lastSampleTime}
                reachMinimum={tc.tankDetails?.reachMinimum}
              />
            )}
          </GridCell>
        );
      });
    },
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_percentfull',
      property: 'tankLevel',
      component: RangeFilter,
      componentProps: {
        min: 0,
        max: 100,
        step: 1,
        initialFrom: 0,
        initialTo: 100,
        minMaxValuesRemoveFilter: true,
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.ProductName,
    columnTitle: 'ui_sitelist_column_product',
    render: (item, width, highlightText) => 
      item.tankChannels?.map((c, i) => {
        const displayText = getTranslatedProductName(c.product ?? undefined);
        return (
          <GridCell key={"ProductName" + i} highlightText={highlightText} width={width}>
            {removeBlank(displayText)}
          </GridCell>
        );
      }),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_product',
      property: 'tankchannelproductref',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.TankIsParked,
    columnTitle: 'ui_sitelist_column_parked',
    render: (item, width) =>
      item.tankChannels?.map((tc, i) => <GridCell key={"TankIsParked" + tc.siteChannelId + i}  width={width}>{tc.isParked ? <Icon name="parked" className="parked-icon" /> : undefined}</GridCell>),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_parked',
      property: 'tankisparked',
      component: BooleanFilter,
      componentProps: {
        values: [
          {
            value: true,
            header: 'With',
            sub: 'Show sites with parked tank channels'
          },
          {
            value: false,
            header: 'Without',
            sub: 'Show sites without parked tank channels'
          },
          {
            value: undefined,
            default: true,
            header: 'Both',
            sub: 'Do not filter by this property'
          }
        ]
      }
    }
  },
  {
    columnKey: ColumnAccess.TankLastDeliveryTicket,
    columnTitle: 'ui_sitelist_column_tanklastdeliveryticket',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={"TankLastDeliveryTicket" + c.siteChannelId + i} width={width} highlightText={highlightText}>
          {c.tankDetails?.lastTankRefill && <LastDeliveryTicket lastDelivery={c.tankDetails.lastTankRefill} />}
        </GridCell>
      )),
    hasOwnClickHandler: true,
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_tanklastdeliveryticket',
      property: 'tanklastdeliveryticket',
      component: DateFilter
    }
  },
  {
    columnKey: ColumnAccess.TankLastDeliveryAmount,
    columnTitle: 'ui_sitelist_column_tanklastdeliveryamount',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={"TankLastDeliveryAmount" + c.siteChannelId + i} width={width} highlightText={highlightText}>
          {c.tankDetails?.lastTankRefill?.amountFilled}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_tanklastdeliveryamount',
      property: 'tanklastdeliveryticketamount',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.HaulierTag,
    columnTitle: 'ui_sitelist_column_hauliertag',
    render: (item, width, highlightText) => 
      item.tankChannels?.map((c,i) => (
        <GridCell key={"HaulierTag" + c.siteChannelId + i} width={width} highlightText={highlightText}>
          {c.tankDetails?.haulierTag}
        </GridCell>)),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
        name: 'ui_sitelist_column_hauliertag',
        property: 'tankhauliertag',
        component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.H2SRuntimeCalibration,
    columnTitle: 'ui_sitelist_column_h2sruntimecalibration',
    render: (item, width, highlightText) =>
      item.gasChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(c.gasDetails?.h2SRuntimeCalibration, { 'symbol': 'days', 'decimals': 0 } as IMeasurementUnit, true)}  
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    filter: {
      name: 'ui_sitelist_column_h2sruntimecalibration',
      property: 'h2Sruntimecalibration',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.H2SMax,
    columnTitle: 'ui_sitelist_column_h2s_maxppm',
    render: (item, width, highlightText) =>
      item.gasChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {isSomething(c.gasDetails?.maxPpm) ? displayMeasurement(c.gasDetails?.maxPpm, c.unit as IMeasurementUnit, true) : undefined}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    filter: {
      name: 'ui_sitelist_column_h2s_maxppm',
      property: 'h2smax',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.H2SMean,
    columnTitle: 'ui_sitelist_column_h2s_meanppm',
    render: (item, width, highlightText) =>
      item.gasChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {isSomething(c.gasDetails?.meanPpm) ? displayMeasurement(c.gasDetails?.meanPpm, c.unit as IMeasurementUnit, true) : undefined}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    filter: {
      name: 'ui_sitelist_column_h2s_meanppm',
      property: 'h2smean',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.H2SPercentTWA,
    columnTitle: 'ui_sitelist_column_h2s_percenttwa',
    render: (item, width, highlightText) =>
      item.gasChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {isSomething(c.gasDetails?.percentTimeAboveLimit) ? c.gasDetails?.percentTimeAboveLimit?.toFixed(0) + '%' : undefined}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    filter: {
      name: 'ui_sitelist_column_h2s_percenttwa',
      property: 'h2stwa',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.H2SAlias,
    columnTitle: 'ui_sitelist_column_alias',
    render: (item, width, highlightText) =>
      item.gasChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {c.alias}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    filter: {
      name: 'ui_sitelist_column_alias',
      property: 'h2schannelalias',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.H2SLastSampleTime,
    columnTitle: 'ui_sitelist_column_lastsampletime',
    render: (item, width, highlightText) =>
      item.gasChannels?.map((b, i) => (
      <GridCell key={i} width={width} highlightText={highlightText}>
        {b.lastSampleTime && pureFormatDate(b.lastSampleTime, true, '.')}
      </GridCell>
    )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    filter: {
      name: 'ui_sitelist_column_lastsampletime',
      property: 'h2schannellastsampletime',
      component: DateFilter
    }
  },
  {
    columnKey: ColumnAccess.H2SParked,
    columnTitle: 'ui_sitelist_column_h2sparked',
    render: (item, width) =>
      item.gasChannels?.map((c, i) => <GridCell key={i} width={width}>{c.isParked ? <Icon name="parked" className="parked-icon" /> : undefined}</GridCell>),
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_h2s',
    filter: {
      name: 'ui_sitelist_column_h2sparked',
      property: 'h2schannelparked',
      component: BooleanFilter,
      componentProps: {
        values: [
          {
            value: true,
            header: 'With',
            sub: 'Show sites with parked H2S channels'
          },
          {
            value: false,
            header: 'Without',
            sub: 'Show sites without parked H2S channels'
          },
          {
            value: undefined,
            default: true,
            header: 'Both',
            sub: 'Do not filter by this property'
          }
        ]
      }
    }
  },
  {
    columnTitle: 'ui_sitelist_column_flowparked',
    columnKey: ColumnAccess.FlowParked,
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_flow',
    render: (item, width) =>
      item.flowChannels?.map((c, i) => <GridCell key={i} width={width}>{c.isParked ? <Icon name="parked" className="parked-icon" /> : undefined}</GridCell>),
    filter: {
      name: 'ui_sitelist_column_flowparked',
      property: 'flowchannelparked',
      component: BooleanFilter,
      componentProps: {
        values: [
          {
            value: true,
            header: 'With',
            sub: 'Show sites with parked flow channels'
          },
          {
            value: false,
            header: 'Without',
            sub: 'Show sites without parked flow channels'
          },
          {
            value: undefined,
            default: true,
            header: 'Both',
            sub: 'Do not filter by this property'
          }
        ]
      }
    }
  },
  {
    columnKey: ColumnAccess.BatteryLevel,
    columnTitle: 'ui_sitelist_column_batterylevel',
    render: (item, width) =>
      item.batteryChannels?.map((bc, i) => <GridCell key={i} width={width}>{!!bc.percentage && <BatteryBar percentage={bc.percentage}></BatteryBar>}</GridCell>),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_battery',
    filter: {
      name: 'ui_sitelist_column_batterylevel',
      property: 'batteryLevel',
      component: RangeFilter,
      componentProps: {
        min: 0,
        max: 100,
        step: 10,
        initialFrom: 0,
        initialTo: 100,
        minMaxValuesRemoveFilter: true,
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.BatteryAlias,
    columnTitle: 'ui_sitelist_column_alias',
    render: (item, width, highlightText) =>
      item.batteryChannels?.map((b, i) => (
        <GridCell key={i} highlightText={highlightText} width={width}>
          {b.alias}
        </GridCell>
      )),
    managementGroup: 'ui_sitelist_group_battery',
    initialWidth: 200,
    filter: {
      name: 'ui_sitelist_column_alias',
      property: 'batterychannelalias',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.BatteryLastSampleTime,
    columnTitle: 'ui_sitelist_column_lastsampletime',
    render: (item, width, highlightText) =>
      item.batteryChannels?.map((b, i) => (
        <GridCell key={i} highlightText={highlightText} width={width}>
          {b.lastSampleTime && pureFormatDate(b.lastSampleTime, true, '.')}
        </GridCell>
      )),
    managementGroup: 'ui_sitelist_group_battery',
    initialWidth: 200,
    filter: {
      name: 'ui_sitelist_column_lastsampletime',
      property: 'batterychannellastsampletime',
      component: DateFilter
    }
  },
  {
    columnKey: ColumnAccess.ChannelId,
    render: (item, width, highlightText) =>
      item.tankChannels?.map((tankChannel, i) => (
        <GridCell key={i} highlightText={highlightText} width={width}>
          {tankChannel.channelId}
        </GridCell>
      )),
    columnTitle: 'ui_sitelist_column_channelid',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_channelid',
      property: 'tankchannelchannelid',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.LastSampleDate,
    columnTitle: 'ui_sitelist_column_lastsampletime',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} highlightText={highlightText} width={width}>
          {c.lastSampleTime && pureFormatDate(c.lastSampleTime, true, '.')}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_lastsampletime',
      property: 'tankchannellastsampletime',
      component: DateFilter
    }
  },
  {
    columnKey: ColumnAccess.TankLevel,
    columnTitle: 'ui_sitelist_column_level',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} highlightText={highlightText} width={width}>
          {isSomething(c.lastSample) ? displayMeasurement(c.lastSample, c.unit as IMeasurementUnit) : undefined}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_level',
      property: 'tankchannellastsample',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.SapNumber,
    columnTitle: 'ui_sitelist_column_tankshipto',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} highlightText={highlightText} width={width}>
          {c.tankDetails?.shipTo}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_tankshipto',
      property: 'tankshipto',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.CustomerProductRef,
    columnTitle: 'ui_sitelist_column_tankcustomerproductref',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} highlightText={highlightText} width={width}>
          {c.tankDetails?.customerProductRef}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'UI_Sitelist_Column_tankcustomerproductref',
      property: 'tankcustomerproductref',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.TankDescription,
    columnTitle: 'ui_sitelist_column_description',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} highlightText={highlightText} width={width}>
          {c.tankDetails?.description}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_description',
      property: 'tankdescription',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.TankLastFilled,
    columnTitle: 'ui_sitelist_column_lastfilled',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {c.tankDetails?.lastFilled && pureFormatDate(c.tankDetails?.lastFilled, true, '.')}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_lastfilled',
      property: 'tanklastfilledspan',
      component: DateFilter
    }
  },
  {
    columnKey: ColumnAccess.ParkedDescription,
    columnTitle: 'ui_sitelist_column_parkeddescription',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {c.parkedDescription}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_parkeddescription',
      property: 'parkeddescription',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.ParkedSince,
    columnTitle: 'ui_sitelist_column_parkedsince',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} highlightText={highlightText} width={width}>
          {c.parkedDate && pureFormatDate(c.parkedDate, true, '.')}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_parkedsince',
      property: 'parkedsince',
      component: DateFilter
    }
  },
  {
    columnKey: ColumnAccess.TankNextFilling,
    columnTitle: 'ui_sitelist_column_tanknextfilling',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {c.tankDetails?.nextPlannedFilling && pureFormatDate(c.tankDetails.nextPlannedFilling, true, '.')}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_tanknextfilling',
      property: 'tanknextfilling',
      component: DateFilter
    }
  },
  {
    columnKey: ColumnAccess.TankUnit,
    columnTitle: 'ui_sitelist_column_unit',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {removeBlank(c.unit?.symbol)}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_unit',
      property: 'tankChannelUnitName',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.ReachMinimum,
    columnTitle: 'ui_sitelist_column_tankreachminimum',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {c.tankDetails?.reachMinimum && pureFormatDate(c.tankDetails?.reachMinimum, true, '.')}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_tankreachminimum',
      property: 'tankreachminimum',
      component: DateFilter
    }
  },
  {
    columnKey: ColumnAccess.ReachMinimumInDays,
    columnTitle: 'ui_sitelist_column_tankreachminimumspan',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell  key={i} width={width} highlightText={highlightText}>
          {c.tankDetails?.reachMinimumSpan}
        </GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_tankreachminimumspan',
      property: 'tankreachminimumspan',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.TankVehicle,
    columnTitle: 'ui_sitelist_column_tankvehiclename',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((tc, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {tc.tankDetails?.vehicle?.codeAndName}
        </GridCell>
      )),
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_tankvehiclename',
      property: 'tankVehicleName',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.AvailableSpace,
    columnTitle: 'ui_sitelist_column_availablespace',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c , i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {isSomething(c.tankDetails) && isSomething(c.tankDetails.freeCapacity)
            ? displayMeasurement(c.tankDetails.freeCapacity, c.unit as IMeasurementUnit)
            : undefined}
        </GridCell>
      )),
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_availablespace',
      property: 'availablespace',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.TankCapacity,
    columnTitle: 'ui_sitelist_column_tankcapacity',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(c.capacity, c.unit as IMeasurementUnit)}
        </GridCell>
      )),
    initialWidth: 150,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_tankcapacity',
      property: 'tankcapacity',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.TankMaximum,
    columnTitle: 'ui_sitelist_column_tankmaximum',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c , i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(c.maximum, c.unit as IMeasurementUnit)}
        </GridCell>
      )),
    initialWidth: 150,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_tankmaximum',
      property: 'tankmaximum',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.TankMinimum,
    columnTitle: 'ui_sitelist_column_tankminimum',
    render: (item, width, highlightText) =>
      item.tankChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(c.minimum, c.unit as IMeasurementUnit)}
        </GridCell>
      )),
    initialWidth: 150,
    managementGroup: 'ui_sitelist_group_tank',
    filter: {
      name: 'ui_sitelist_column_tankminimum',
      property: 'tankminimum',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.BatteryHealth,
    columnTitle: 'ui_sitelist_column_sitehealth',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_battery',
    render: (item, width) =>
      item.batteryChannels?.map((c, i) => {
        const getIcon = () => {
          switch (c.health) {
            case HealthEnum.HasAlarm:
              return <div className="icon-wrapper"><Icon name="alert-triangle" className="alarm-icon" /><span className="highlight-count">{item.numberOfActiveAlarms}</span></div>;
            case HealthEnum.Offline:
              return <Icon name="offline-cross" className="health-icon" />;
            case HealthEnum.Online:
              return <Icon name="online-tick" className="health-icon" />;
            case HealthEnum.Parked:
              return <Icon name="parked" className="parked-icon" />;
            case HealthEnum.Stale:
              return <Icon name="fa-question" className="parked-icon" />;
            default:
              return undefined;
          }
        };
        return (
          <GridCell key={i} width={width} style={{textAlign: 'center'}} tooltipText={generateTooltipTextForIcon(c as SiteListPageRowChannel)}>
            {getIcon()}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_sitehealth',
      property: 'batterychannelhealth',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.H2SHealth,
    columnTitle: 'ui_sitelist_column_sitehealth',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    render: (item, width) =>
      item.gasChannels?.map((c ,i) => {
        const getIcon = () => {
          switch (c.health) {
            case HealthEnum.HasAlarm:              
              return <div className="icon-wrapper"><Icon name="alert-triangle" className="alarm-icon" /><span className="highlight-count">{item.numberOfActiveAlarms}</span></div>;
            case HealthEnum.Offline:
              return <Icon name="offline-cross" className="health-icon" />;
            case HealthEnum.Online:
              return <Icon name="online-tick" className="health-icon" />;
            case HealthEnum.Parked:
              return <Icon name="parked" className="parked-icon" />;
            case HealthEnum.Stale:
              return <Icon name="fa-question" className="parked-icon" />;
            default:
              return undefined;
          }
        };
        return (
          <GridCell key={i}width={width} style={{textAlign: 'center'}} tooltipText={generateTooltipTextForIcon(c as SiteListPageRowChannel)}>
            {getIcon()}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_sitehealth',
      property: 'h2schannelhealth',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.TemperatureHealth,
    columnTitle: 'ui_sitelist_column_sitehealth',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_temperature',
    render: (item, width) =>
      item.temperatureChannels?.map((c, i) => {
        const getIcon = () => {
          switch (c.health) {
            case HealthEnum.HasAlarm:
              return <div className="icon-wrapper"><Icon name="alert-triangle" className="alarm-icon" /><span className="highlight-count">{item.numberOfActiveAlarms}</span></div>;
            case HealthEnum.Offline:
              return <Icon name="offline-cross" className="health-icon" />;
            case HealthEnum.Online:
              return <Icon name="online-tick" className="health-icon" />;
            case HealthEnum.Parked:
              return <Icon name="parked" className="parked-icon" />;
            case HealthEnum.Stale:
              return <Icon name="fa-question" className="parked-icon" />;
            default:
              return undefined;
          }
        };
        return (
          <GridCell key={i} width={width} style={{textAlign: 'center'}} tooltipText={generateTooltipTextForIcon(c as SiteListPageRowChannel)}>
            {getIcon()}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_sitehealth',
      property: 'temperaturechannelhealth',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.SiteHealth,
    columnTitle: 'ui_sitelist_column_sitehealth',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    render: (item, width) =>
      item.tankChannels?.map((c, i) => {
        const getIcon = () => {
          switch (c.health) {
            case HealthEnum.HasAlarm:
              return <div className="icon-wrapper"><Icon name="alert-triangle" className="alarm-icon" /><span className="highlight-count">{item.numberOfActiveAlarms}</span></div>;
            case HealthEnum.Offline:
              return <Icon name="offline-cross" className="health-icon" />;
            case HealthEnum.Online:
              return <Icon name="online-tick" className="health-icon" />;
            case HealthEnum.Parked:
              return <Icon name="parked" className="parked-icon" />;
            case HealthEnum.Stale:
              return <Icon name="fa-question" className="parked-icon" />;
            default:
              return undefined;
          }
        };
        return (
          <GridCell key={i} width={width} style={{textAlign: 'center'}} tooltipText={generateTooltipTextForIcon(c as SiteListPageRowChannel)}>
            {getIcon()}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_sitehealth',
      property: 'tankchannelhealth',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnTitle: 'ui_sitelist_column_signal',
    columnKey: ColumnAccess.Signal,
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_sitestatus',
    render: (item, width) => {
      const getIcon = () => {
        if (item.isVirtual === true) return <Icon name="virtual" className="signal-icon" />;
        switch (item.signal) {
          case SignalEnum.Online:
            return <Icon name="signal-good" className="signal-icon" />;
          case SignalEnum.Offline:
            return <Icon name="signal-bad" className="signal-icon" />;
          case SignalEnum.Stale:
            return <Icon name="signal-poor" className="signal-icon" />;
        }
      };
      return (
        <GridCell width={width} tooltipText={generateTooltipTextForSignalStrength(item.lastSampleTime)}>
          {getIcon()}
        </GridCell>
      );
    },
    filter: {
      name: 'ui_sitelist_column_signal',
      property: 'sitesignal',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.SiteHasEquipment,
    columnTitle: 'ui_sitelist_column_sitehasequipment',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_sitestatus',
    hasOwnClickHandler: true,
    render: (item, width) => (
      <GridCell width={width} columnLink={`/sitedetails/${item.siteId}/assets`}>
        {item.hasEquipment ? <Icon name="archive" className="archive-icon" /> : undefined}
      </GridCell>
    ),
    filter: {
      name: 'ui_sitelist_column_sitehasequipment',
      property: 'hasequipment',
      component: BooleanFilter,
      componentProps: {
        values: [
          {
            value: true,
            header: 'With',
            sub: 'Show sites with equipment'
          },
          {
            value: false,
            header: 'Without',
            sub: 'Show sites without equipment'
          },
          {
            value: undefined,
            default: true,
            header: 'Both',
            sub: 'Do not filter by site equipment'
          }
        ]
      }
    }
  },
  {
    columnKey: ColumnAccess.SiteHasGalleryImages,
    columnTitle: 'ui_sitelist_column_sitehasimagesingallery',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_sitestatus',
    hasOwnClickHandler: true,
    render: (item, width) => (
      <GridCell width={width} columnLink={`/sitedetails/${item.siteId}/gallery`}>
        {item.hasGalleryItems ? <Icon name="image" className="image-icon" /> : undefined}
      </GridCell>
    ),
    filter: {
      name: 'ui_sitelist_column_sitehasimagesingallery',
      property: 'hasgalleryimages',
      component: BooleanFilter,
      componentProps: {
        values: [
          {
            value: true,
            header: 'With',
            sub: 'Show sites that has images'
          },
          {
            value: false,
            header: 'Without',
            sub: 'Show sites without images'
          },
          {
            value: undefined,
            default: true,
            header: 'Both',
            sub: 'Do not filter by this property'
          }
        ]
      }
    }
  },
  {
    columnKey: ColumnAccess.SiteIsReceivingDeliveryTickets,
    columnTitle: 'ui_sitelist_column_siteisreceivingdeliverytickets',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_sitestatus',
    hasOwnClickHandler: true,
    render: (item, width) => {
      return (
        <GridCell
          width={width}
          columnLink={`/sitedetails/${item.siteId}/tanks/tankDetails/${item.tankWithLatestDeliveryTicket}`}
          tooltipText={generateTooltipTextForReceivingDeliveryTickets(
            item.tankChannels as SiteListPageRowChannel[],
            item.tankWithLatestDeliveryTicket
          )}
        >
          {item.hasDeliveryTickets ? <Icon name="file" className="file-icon" /> : undefined}
        </GridCell>
      );
    },
    filter: {
      name: 'ui_sitelist_column_siteisreceivingdeliverytickets',
      property: 'isreceivingdeliverytickets',
      component: BooleanFilter,
      componentProps: {
        values: [
          {
            value: true,
            header: 'With',
            sub: 'Show sites that are receiving tickets'
          },
          {
            value: false,
            header: 'Without',
            sub: 'Show sites that are not receiving tickets'
          },
          {
            value: undefined,
            default: true,
            header: 'Both',
            sub: 'Do not filter by this property'
          }
        ]
      }
    }
  },
  {
    columnKey: ColumnAccess.SiteAlarmsAreMuted,
    columnTitle: 'ui_sitelist_column_sitealarmsaremuted',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_sitestatus',
    render: (item, width) => (
      <GridCell width={width}>
        <MutedAlarms className="muted-icon" siteId={item.siteId} muted={item.alarmsAreMuted} />
      </GridCell>
    ),
    hasOwnClickHandler: true,
    filter: {
      name: 'ui_sitelist_column_sitealarmsaremuted',
      property: 'alarmsaremuted',
      component: BooleanFilter,
      componentProps: {
        values: [
          {
            value: true,
            header: 'With',
            sub: 'Show sites with muted alarms'
          },
          {
            value: false,
            header: 'Without',
            sub: 'Show sites with unmuted alarms'
          },
          {
            value: undefined,
            default: true,
            header: 'Both',
            sub: 'Do not filter by this property'
          }
        ]
      }
    }
  },
  {
    columnKey: ColumnAccess.StockVsDoseHoulyTankUsage,
    columnTitle: 'ui_sitelist_column_stockvsdose_hourlytankusage',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_stockvsdose',
    render: (item, width, highlightText) =>
      item.stockVsDoseChannels25?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(
            sd.stockVsDoseAccuracyChannelDetails?.aggregatedTankUsageChannel?.lastSample,
            sd.stockVsDoseAccuracyChannelDetails?.aggregatedTankUsageChannel?.unit as IMeasurementUnit,
            true
          )}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_stockvsdose_hourlytankusage',
      property: 'hourlytankusage',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.StockVsDoseHoulyAvgDose,
    columnTitle: 'ui_sitelist_column_stockvsdose_hourlyavgdose',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_stockvsdose',
    render: (item, width, highlightText) =>
      item.stockVsDoseChannels25?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(
            sd.stockVsDoseAccuracyChannelDetails?.aggregatedDoseChannel?.lastSample,
            sd.stockVsDoseAccuracyChannelDetails?.aggregatedDoseChannel?.unit as IMeasurementUnit,
            true
          )}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_stockvsdose_hourlyavgdose',
      property: 'hourlyaveragedose',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.StockVsDoseLastSampleTime,
    columnTitle: 'ui_sitelist_column_lastsampletime',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_stockvsdose',
    render: (item, width, highlightText) =>
      item.stockVsDoseChannels25?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.lastSampleTime && pureFormatDate(sd.lastSampleTime, true, '.')}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_lastsampletime',
      property: 'stockvsdosechannellastsampletime',
      component: DateFilter
    }
  },
  {
    columnKey: ColumnAccess.StockVsDoseHealth,
    columnTitle: 'ui_sitelist_column_sitehealth',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_stockvsdose',
    render: (item, width) =>
      item.stockVsDoseChannels25?.map((tc, i) => {
        const getIcon = () => {
          switch (tc.health) {
            case HealthEnum.HasAlarm:              
              return <div className="icon-wrapper"><Icon name="alert-triangle" className="alarm-icon" /><span className="highlight-count">{item.numberOfActiveAlarms}</span></div>;
            case HealthEnum.Offline:
              return <Icon name="offline-cross" className="health-icon" />;
            case HealthEnum.Online:
              return <Icon name="online-tick" className="health-icon" />;
            case HealthEnum.Parked:
              return <Icon name="parked" className="parked-icon" />;
            case HealthEnum.Stale:
              return <Icon name="fa-question" />;
          }
          return undefined;
        };

        return (
          <GridCell key={i} width={width} style={{textAlign: 'center'}} tooltipText={generateTooltipTextForIcon(tc as SiteListPageRowChannel)}>
            {getIcon()}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_sitehealth',
      property: 'stockvsdosechannelhealth',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnTitle: 'ui_sitelist_column_stockvsdose_calibration25',
    columnKey: ColumnAccess.StockVsDoseCalibration25,
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_stockvsdose',
    render: (item, width) =>
      item.stockVsDoseChannels25
        ?.map((sd, i) => <GridCell key={i} width={width}>{!!sd.lastSample && <StockVsDoseBar accuracy={sd.lastSample} />} </GridCell>),
    filter: {
      name: 'ui_sitelist_column_stockvsdose_calibration25',
      property: 'svdCalibration25',
      component: NumberFilter
    }
  },
  {
    columnTitle: 'ui_sitelist_column_stockvsdose_calibration5',
    columnKey: ColumnAccess.StockVsDoseCalibration5,
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_stockvsdose',
    render: (item, width) =>
      item.stockVsDoseChannels5
        ?.map((sd, i) => <GridCell key={i} width={width}>{!!sd.lastSample && <StockVsDoseBar accuracy={sd.lastSample} />} </GridCell>),
    filter: {
      name: 'ui_sitelist_column_stockvsdose_calibration5',
      property: 'svdCalibration5',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.TemperatureMax,
    columnTitle: 'ui_sitelist_column_temperaturemax',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_temperature',
    render: (item, width) =>
      item.temperatureChannels?.map((sd, i) => (
        <GridCell key={i} width={width}>{displayMeasurement(sd.temperatureChannelDetails?.intervalMax, sd.unit as IMeasurementUnit, true)}</GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_temperaturemax',
      property: 'tempmax',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.TemperatureMin,
    columnTitle: 'ui_sitelist_column_temperaturemin',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_temperature',
    render: (item, width) =>
      item.temperatureChannels?.map((sd, i) => (
        <GridCell key={i} width={width}>{displayMeasurement(sd.temperatureChannelDetails?.intervalMin, sd.unit as IMeasurementUnit, true)}</GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_temperaturemin',
      property: 'tempmin',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.TemperatureMean,
    columnTitle: 'ui_sitelist_column_temperaturemean',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_temperature',
    render: (item, width) =>
      item.temperatureChannels?.map((sd, i) => (
        <GridCell key={i} width={width}>{displayMeasurement(sd.temperatureChannelDetails?.intervalMean, sd.unit as IMeasurementUnit, true)}</GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_temperaturemean',
      property: 'tempmean',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.TemperatureAlias,
    columnTitle: 'ui_sitelist_column_alias',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_temperature',
    render: (item, width, highlightText) =>
      item.temperatureChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.alias}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_alias',
      property: 'temperaturechannelalias',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.TemperatureLastSampleTime,
    columnTitle: 'ui_sitelist_column_lastsampletime',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_temperature',
    render: (item, width, highlightText) =>
      item.temperatureChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.lastSampleTime && pureFormatDate(sd.lastSampleTime, true, '.')}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_lastsampletime',
      property: 'temperaturechannellastsampletime',
      component: DateFilter
    }
  },
  //Flow channels

  {
    columnKey: ColumnAccess.FlowMin,
    columnTitle: 'ui_sitelist_column_flowmin',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_flow',
    render: (item, width, highlightText) =>
      item.flowChannels?.map((sd, i ) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(sd.flowChannelDetails?.intervalMin, sd.unit as IMeasurementUnit, true, false)}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_flowmin',
      property: 'flowMin',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.FlowMax,
    columnTitle: 'ui_sitelist_column_flowmax',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_flow',
    render: (item, width, highlightText) =>
      item.flowChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(sd.flowChannelDetails?.intervalMax, sd.unit as IMeasurementUnit, true, false)}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_flowmax',
      property: 'flowMax',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.FlowMean,
    columnTitle: 'ui_sitelist_column_flowmean',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_flow',
    render: (item, width, highlightText) =>
      item.flowChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(sd.flowChannelDetails?.intervalMean, sd.unit as IMeasurementUnit, true, false)}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_flowmean',
      property: 'flowMean',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.FlowSum,
    columnTitle: 'ui_sitelist_column_flowsum',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_flow',
    render: (item, width, highlightText) =>
      item.flowChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(sd.flowChannelDetails?.intervalSum, sd.unit?.unitWithoutInterval as IMeasurementUnit, true, false)}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_flowsum',
      property: 'flowSum',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.FlowAlias,
    columnTitle: 'ui_sitelist_column_alias',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_flow',
    render: (item, width, highlightText) =>
      item.flowChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.alias}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_alias',
      property: 'flowchannelalias',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.FlowUnit,
    columnTitle: 'ui_sitelist_column_unit',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_flow',
    render: (item, width, highlightText) =>
      item.flowChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.unit?.symbol}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_unit',
      property: 'flowunitref',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.FlowLastSampleTime,
    columnTitle: 'ui_sitelist_column_lastsampletime',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_flow',
    render: (item, width, highlightText) =>
      item.flowChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.lastSampleTime && pureFormatDate(sd.lastSampleTime, true, '.')}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_lastsampletime',
      property: 'flowchannellastsampletime',
      component: DateFilter
    }
  },
  {
    columnKey: ColumnAccess.FlowHealth,
    columnTitle: 'ui_sitelist_column_sitehealth',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_flow',
    render: (item, width, highlightText) =>
      item.flowChannels?.map((tc, i) => {
        const getIcon = () => {
          switch (tc.health) {
            case HealthEnum.HasAlarm:
              return <div className="icon-wrapper"><Icon name="alert-triangle" className="alarm-icon" /><span className="highlight-count">{item.numberOfActiveAlarms}</span></div>;
            case HealthEnum.Offline:
              return <Icon name="offline-cross" className="health-icon" />;
            case HealthEnum.Online:
              return <Icon name="online-tick" className="health-icon" />;
            case HealthEnum.Parked:
              return <Icon name="parked" className="parked-icon" />;
            case HealthEnum.Stale:
              return <Icon name="fa-question" className="parked-icon" />;
            default:
              return undefined;
          }
        };

        return (
          <GridCell key={i} width={width} style={{textAlign: 'center'}} highlightText={highlightText} tooltipText={generateTooltipTextForIcon(tc as SiteListPageRowChannel)}>
            {getIcon()}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_sitehealth',
      property: 'flowchannelhealth',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  //Dose channels

  {
    columnKey: ColumnAccess.DoseMin,
    columnTitle: 'ui_sitelist_column_dosemin',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_dose',
    render: (item, width, highlightText) =>
      item.doseChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(sd.doseDetails?.intervalMin, sd.unit as IMeasurementUnit, true, false)}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_dosemin',
      property: 'doseMin',
      component: NumberFilter
    }
  },
  {
    columnTitle: 'ui_sitelist_column_dosemax',
    columnKey: ColumnAccess.DoseMax,
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_dose',
    render: (item, width, highlightText) =>
      item.doseChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(sd.doseDetails?.intervalMax, sd.unit as IMeasurementUnit, true, false)}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_dosemax',
      property: 'doseMax',
      component: NumberFilter
    }
  },
  {
    columnTitle: 'ui_sitelist_column_dosemean',
    columnKey: ColumnAccess.DoseMean,
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_dose',
    render: (item, width, highlightText) =>
      item.doseChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(sd.doseDetails?.intervalMean, sd.unit as IMeasurementUnit, true, false)}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_dosemean',
      property: 'doseMean',
      component: NumberFilter
    }
  },
  {
    columnTitle: 'ui_sitelist_column_dosesum',
    columnKey: ColumnAccess.DoseSum,
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_dose',
    render: (item, width, highlightText) =>
      item.doseChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(sd.doseDetails?.intervalSum, sd.unit?.unitWithoutInterval as IMeasurementUnit, true, false)}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_dosesum',
      property: 'doseSum',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.DoseAlias,
    columnTitle: 'ui_sitelist_column_alias',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_dose',
    render: (item, width, highlightText) =>
      item.doseChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.alias}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_alias',
      property: 'dosechannelalias',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.DoseUnit,
    columnTitle: 'ui_sitelist_column_unit',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_dose',
    render: (item, width, highlightText) =>
      item.doseChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.unit?.symbol}{' '}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_unit',
      property: 'doseunitref',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.DoseLastSampleTime,
    columnTitle: 'ui_sitelist_column_lastsampletime',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_dose',
    render: (item, width, highlightText) =>
      item.doseChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.lastSampleTime && pureFormatDate(sd.lastSampleTime, true, '.')}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_lastsampletime',
      property: 'dosechannellastsampletime', // missing in backend
      component: DateFilter
    }
  },

  {
    columnKey: ColumnAccess.DoseCustomerFactor,
    columnTitle: 'ui_sitelist_column_customerfactor',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_dose',
    render: (item, width, highlightText) =>
      item.doseChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {(sd.doseDetails?.customerFactor === undefined || sd.doseDetails?.customerFactor === null) ? '' : (sd.doseDetails?.customerFactor + (sd.doseDetails?.customerFactorUnit ? (' ' + sd.doseDetails?.customerFactorUnit) : '')) }
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_customerFactor',
      property: 'customerFactor', // missing in backend
      component: NumberFilter
    }
  },

  {
    columnKey: ColumnAccess.DoseHealth,
    columnTitle: 'ui_sitelist_column_sitehealth',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_dose',
    render: (item, width) =>
      item.doseChannels?.map((tc, i) => {
        const getIcon = () => {
          switch (tc.health) {
            case HealthEnum.HasAlarm:              
              return <div className="icon-wrapper"><Icon name="alert-triangle" className="alarm-icon" /><span className="highlight-count">{item.numberOfActiveAlarms}</span></div>;
            case HealthEnum.Offline:
              return <Icon name="offline-cross" className="health-icon" />;
            case HealthEnum.Online:
              return <Icon name="online-tick" className="health-icon" />;
            case HealthEnum.Parked:
              return <Icon name="parked" className="parked-icon" />;
            case HealthEnum.Stale:
              return <Icon name="fa-question" />;
          }
          return undefined;
        };

        return (
          <GridCell key={i} width={width} style={{textAlign: 'center'}} tooltipText={generateTooltipTextForIcon(tc as SiteListPageRowChannel)}>
            {getIcon()}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_sitehealth',
      property: 'dosechannelhealth',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.DoseParked,
    columnTitle: 'ui_sitelist_column_parked',
    render: (item, width) =>
      item.doseChannels?.map((dc, i) => <GridCell key={i} width={width}>{dc.isParked ? <Icon name="parked" className="parked-icon" /> : undefined}</GridCell>),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_dose',
    filter: {
      name: 'ui_sitelist_column_parked',
      property: 'doseisparked',
      component: BooleanFilter,
      componentProps: {
        values: [
          {
            value: true,
            header: 'With',
            sub: 'Show sites with parked dose channels'
          },
          {
            value: false,
            header: 'Without',
            sub: 'Show sites without parked dose channels'
          },
          {
            value: undefined,
            default: true,
            header: 'Both',
            sub: 'Do not filter by this property'
          }
        ]
      }
    }
  },
  //Retention time columns

  {
    columnKey: ColumnAccess.RetentiontimeMin,
    columnTitle: 'ui_sitelist_column_retentiontimemin',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_retentiontime',
    render: (item, width, highlightText) =>
      item.retentionTimeChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(sd.retentionTimeDetails?.intervalMin, sd.unit as IMeasurementUnit, true, false)}{' '}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_retentiontimemin',
      property: 'rtChannelMin',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.RetentiontimeMax,
    columnTitle: 'ui_sitelist_column_retentiontimemax',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_retentiontime',
    render: (item, width, highlightText) =>
      item.retentionTimeChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(sd.retentionTimeDetails?.intervalMax, sd.unit as IMeasurementUnit, true, false)}{' '}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_retentiontimemax',
      property: 'rtChannelMax',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.RetentiontimeMean,
    columnTitle: 'ui_sitelist_column_retentiontimemean',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_retentiontime',
    render: (item, width, highlightText) =>
      item.retentionTimeChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {displayMeasurement(sd.retentionTimeDetails?.intervalMean, sd.unit as IMeasurementUnit, true, false)}{' '}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_retentiontimemean',
      property: 'rtChannelMean',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.RetentiontimeAlias,
    columnTitle: 'ui_sitelist_column_alias',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_retentiontime',
    render: (item, width, highlightText) =>
      item.retentionTimeChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.alias}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_alias',
      property: 'rtChannelAlias',
      component: StringFilter
    }
  },

  {
    columnKey: ColumnAccess.RetentiontimeUnit,
    columnTitle: 'ui_sitelist_column_unit',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_retentiontime',
    render: (item, width, highlightText) =>
      item.retentionTimeChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.unit?.symbol}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_unit',
      property: 'rtChannelUnitRef',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.RetentiontimeLastSampleTime,
    columnTitle: 'ui_sitelist_column_lastsampletime',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_retentiontime',
    render: (item, width, highlightText) =>
      item.retentionTimeChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.lastSampleTime && pureFormatDate(sd.lastSampleTime, true, '.')}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_lastsampletime',
      property: 'rtChannelLastSampletime',
      component: DateFilter
    }
  },
  {
    columnKey: ColumnAccess.RetentiontimeHealth,
    columnTitle: 'ui_sitelist_column_sitehealth',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_retentiontime',
    render: (item, width) =>
      item.retentionTimeChannels?.map((c, i) => {
        const getIcon = () => {
          switch (c.health) {
            case HealthEnum.HasAlarm:
              return <div className="icon-wrapper"><Icon name="alert-triangle" className="alarm-icon" /><span className="highlight-count">{item.numberOfActiveAlarms}</span></div>;
            case HealthEnum.Offline:
              return <Icon name="offline-cross" className="health-icon" />;
            case HealthEnum.Online:
              return <Icon name="online-tick" className="health-icon" />;
            case HealthEnum.Parked:
              return <Icon name="parked" className="parked-icon" />;
            case HealthEnum.Stale:
              return <Icon name="fa-question" className="parked-icon" />;
            default:
              return undefined;
          }
        };
        return (
          <GridCell key={i} width={width} style={{textAlign: 'center'}} tooltipText={generateTooltipTextForIcon(c as SiteListPageRowChannel)}>
            {getIcon()}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_sitehealth',
      property: 'rtChannelHealth',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.RetentiontimeParked,
    columnTitle: 'ui_sitelist_column_parked',
    render: (item, width) =>
      item.retentionTimeChannels?.map((tc, i) => (
        <GridCell key={i} width={width}>{tc.isParked ? <Icon name="parked" className="parked-icon" /> : undefined}</GridCell>
      )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_retentiontime',
    filter: {
      name: 'ui_sitelist_column_parked',
      property: 'rtChannelIsParked',
      component: BooleanFilter,
      componentProps: {
        values: [
          {
            value: true,
            header: 'With',
            sub: 'Show sites with parked dose channels'
          },
          {
            value: false,
            header: 'Without',
            sub: 'Show sites without parked dose channels'
          },
          {
            value: undefined,
            default: true,
            header: 'Both',
            sub: 'Do not filter by this property'
          }
        ]
      }
    }
  },

  //Tank controller columns
  {
    columnKey: ColumnAccess.TankControllerPosition,
    columnTitle: 'ui_sitelist_column_controllerposition',
    hasOwnClickHandler: true,    
    render: (item, width, highlightText) =>
      item.tankChannels?.map((t, i) => {
        return (
          <GridCell key={i} highlightText={highlightText} width={width} columnLink={undefined}>
            <Clippy text={t.controllerLatitude?.toFixed(5) + ', ' + t.controllerLongitude?.toFixed(5)} />
          </GridCell>
        );
      }),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    sortingDisabled: true
  },
  {
    columnKey: ColumnAccess.TankAlias,
    columnTitle: 'ui_sitelist_column_alias',
    hasOwnClickHandler: true,
    render: (item, width, highlightText) =>
      item.tankChannels?.map((t, i) => {
        return (
          <GridCell key={i} highlightText={highlightText} width={width} columnLink={undefined}>
            {t.alias}
          </GridCell>
        );
      }),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    sortingDisabled: false,
    filter: {
      name: 'ui_sitelist_column_alias',
      property: 'tankalias',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.TankControllerType,
    columnTitle: 'ui_sitelist_column_controllertype',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.tankChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;
        return (
          <GridCell key={i} highlightText={highlightText} width={width} columnLink={features.siteDetailsController.read ? link : undefined}>
            {t.controllerType}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllertype',
      property: 'tankControllerType',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.TankControllerIpAddress,
    columnTitle: 'ui_sitelist_column_controlleripaddress',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.tankChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerIpAddress}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controlleripaddress',
      property: 'tankControllerIpAddress',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.TankControllerIcc,
    columnTitle: 'ui_sitelist_column_controllericc',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.tankChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerIcc}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllericc',
      property: 'tankControllerIcc',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.TankControllerSerial,
    columnTitle: 'ui_sitelist_column_controllerserial',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.tankChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerSerial}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllerserial',
      property: 'tankControllerSerial',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.TankControllerAlternateSerial,
    columnTitle: 'ui_sitelist_column_controlleralternateserial',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_tank',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.tankChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerAlternateSerial}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controlleralternateserial',
      property: 'tankControllerAlternateSerial',
      component: StringFilter
    }
  },

  //H2S controller columns
  {
    columnKey: ColumnAccess.H2SControllerPosition,
    columnTitle: 'ui_sitelist_column_controllerposition',
    hasOwnClickHandler: true,    
    render: (item, width, highlightText) =>
      item.gasChannels?.map((t, i) => {
        return (
          <GridCell key={i} highlightText={highlightText} width={width} columnLink={undefined}>
            <Clippy text={t.controllerLatitude?.toFixed(5) + ', ' + t.controllerLongitude?.toFixed(5)} />
          </GridCell>
        );
      }),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    sortingDisabled: true
  },
  {
    columnKey: ColumnAccess.H2SControllerType,
    columnTitle: 'ui_sitelist_column_controllertype',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.gasChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerType}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllertype',
      property: 'h2sControllerType',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.H2SControllerIpAddress,
    columnTitle: 'ui_sitelist_column_controlleripaddress',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.gasChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerIpAddress}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controlleripaddress',
      property: 'h2sControllerIpAddress',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.H2SControllerIcc,
    columnTitle: 'ui_sitelist_column_controllericc',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.gasChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerIcc}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllericc',
      property: 'h2sControllerIcc',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.H2SControllerSerial,
    columnTitle: 'ui_sitelist_column_controllerserial',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.gasChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerSerial}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllerserial',
      property: 'h2sControllerSerial',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.H2SControllerAlternateSerial,
    columnTitle: 'ui_sitelist_column_controlleralternateserial',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_h2s',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.gasChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerAlternateSerial}
          </GridCell>
        );
      }),
    filter: {
      name: 'H2SControllerAlternateSerial',
      property: 'h2sControllerAlternateSerial',
      component: StringFilter
    }
  },

  //Flow controller columns
  {
    columnKey: ColumnAccess.FlowControllerPosition,
    columnTitle: 'ui_sitelist_column_controllerposition',
    hasOwnClickHandler: true,    
    render: (item, width, highlightText) =>
      item.flowChannels?.map((t, i) => {
        return (
          <GridCell key={i} highlightText={highlightText} width={width} columnLink={undefined}>
            <Clippy text={t.controllerLatitude?.toFixed(5) + ', ' + t.controllerLongitude?.toFixed(5)} />
          </GridCell>
        );
      }),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_flow',
    sortingDisabled: true
  },
  {
    columnKey: ColumnAccess.FlowControllerType,
    columnTitle: 'ui_sitelist_column_controllertype',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_flow',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.flowChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerType}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllertype',
      property: 'flowControllerType',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.FlowControllerIpAddress,
    columnTitle: 'ui_sitelist_column_controlleripaddress',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_flow',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.flowChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerIpAddress}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controlleripaddress',
      property: 'flowControllerIpAddress',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.FlowControllerIcc,
    columnTitle: 'ui_sitelist_column_controllericc',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_flow',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.flowChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerIcc}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllericc',
      property: 'flowControllerIcc',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.FlowControllerSerial,
    columnTitle: 'ui_sitelist_column_controllerserial',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_flow',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.flowChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerSerial}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllerserial',
      property: 'flowControllerSerial',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.FlowControllerAlternateSerial,
    columnTitle: 'ui_sitelist_column_controlleralternateserial',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_flow',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.flowChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerAlternateSerial}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controlleralternateserial',
      property: 'flowControllerAlternateSerial',
      component: StringFilter
    }
  },
  //Dose controller columns
  {
    columnKey: ColumnAccess.DoseControllerPosition,
    columnTitle: 'ui_sitelist_column_controllerposition',
    hasOwnClickHandler: true,    
    render: (item, width, highlightText) =>
      item.doseChannels?.map((t, i) => {
        return (
          <GridCell key={i} highlightText={highlightText} width={width} columnLink={undefined}>
            <Clippy text={t.controllerLatitude?.toFixed(5) + ', ' + t.controllerLongitude?.toFixed(5)} />
          </GridCell>
        );
      }),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_dose',
    sortingDisabled: true
  },
  {
    columnKey: ColumnAccess.DoseControllerType,
    columnTitle: 'ui_sitelist_column_controllertype',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_dose',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.doseChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerType}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllertype',
      property: 'doseControllerType',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.DoseControllerIpAddress,
    columnTitle: 'ui_sitelist_column_controlleripaddress',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_dose',
    render: (item, width, highlightText) =>
      item.doseChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerIpAddress}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controlleripaddress',
      property: 'doseControllerIpAddress',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.DoseControllerIcc,
    columnTitle: 'ui_sitelist_column_controllericc',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_dose',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.doseChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerIcc}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllericc',
      property: 'doseControllerIcc',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.DoseControllerSerial,
    columnTitle: 'ui_sitelist_column_controllerserial',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_dose',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.doseChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerSerial}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllerserial',
      property: 'doseControllerSerial',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.DoseControllerAlternateSerial,
    columnTitle: 'ui_sitelist_column_controlleralternateserial',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_dose',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.doseChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerAlternateSerial}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controlleralternateserial',
      property: 'doseControllerAlternateSerial',
      component: StringFilter
    }
  },
  
  //Temperature controller columns
  {
    columnKey: ColumnAccess.TemperatureControllerPosition,
    columnTitle: 'ui_sitelist_column_controllerposition',
    hasOwnClickHandler: true,    
    render: (item, width, highlightText) =>
      item.temperatureChannels?.map((t, i) => {
        return (
          <GridCell key={i} highlightText={highlightText} width={width} columnLink={undefined}>
            <Clippy text={t.controllerLatitude?.toFixed(5) + ', ' + t.controllerLongitude?.toFixed(5)} />
          </GridCell>
        );
      }),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_temperature',
    sortingDisabled: true
  },
  {
    columnKey: ColumnAccess.TemperatureControllerType,
    columnTitle: 'ui_sitelist_column_controllertype',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_temperature',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.temperatureChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerType}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllertype',
      property: 'temperatureControllerType',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.TemperatureControllerIpAddress,
    columnTitle: 'ui_sitelist_column_controlleripaddress',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_temperature',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.temperatureChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerIpAddress}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controlleripaddress',
      property: 'temperatureControllerIpAddress',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.TemperatureControllerIcc,
    columnTitle: 'ui_sitelist_column_controllericc',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_temperature',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.temperatureChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerIcc}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllericc',
      property: 'temperatureControllerIcc',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.TemperatureControllerSerial,
    columnTitle: 'ui_sitelist_column_controllerserial',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_temperature',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.temperatureChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerSerial}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllerserial',
      property: 'temperatureControllerSerial',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.TemperatureControllerAlternateSerial,
    columnTitle: 'ui_sitelist_column_controlleralternateserial',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_temperature',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.temperatureChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerAlternateSerial}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controlleralternateserial',
      property: 'temperatureControllerAlternateSerial',
      component: StringFilter
    }
  },

  //Battery controller columns
  {
    columnKey: ColumnAccess.BatteryControllerPosition,
    columnTitle: 'ui_sitelist_column_controllerposition',
    hasOwnClickHandler: true,    
    render: (item, width, highlightText) =>
      item.batteryChannels?.map((t, i) => {
        return (
          <GridCell key={i} highlightText={highlightText} width={width} columnLink={undefined}>
            <Clippy text={t.controllerLatitude?.toFixed(5) + ', ' + t.controllerLongitude?.toFixed(5)} />
          </GridCell>
        );
      }),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_battery',
    sortingDisabled: true
  },
  {
    columnKey: ColumnAccess.BatteryControllerType,
    columnTitle: 'ui_sitelist_column_controllertype',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_battery',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.batteryChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerType}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllertype',
      property: 'batteryControllerType',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.BatteryControllerIpAddress,
    columnTitle: 'ui_sitelist_column_controlleripaddress',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_battery',
    render: (item, width, highlightText) =>
      item.batteryChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerIpAddress}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controlleripaddress',
      property: 'batteryControllerIpAddress',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.BatteryControllerIcc,
    columnTitle: 'ui_sitelist_column_controllericc',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_battery',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.batteryChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerIcc}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllericc',
      property: 'batteryControllerIcc',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.BatteryControllerSerial,
    columnTitle: 'ui_sitelist_column_controllerserial',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_battery',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.batteryChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerSerial}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controllerserial',
      property: 'batteryControllerSerial',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.BatteryControllerAlternateSerial,
    columnTitle: 'ui_sitelist_column_controlleralternateserial',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_battery',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.batteryChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {t.controllerAlternateSerial}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_controlleralternateserial',
      property: 'batteryControllerAlternateSerial',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.BatteryRuntimeService,
    columnTitle: 'ui_sitelist_column_batteryruntimeservice',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_battery',
    hasOwnClickHandler: features.siteDetailsController.read,
    render: (item, width, highlightText) =>
      item.batteryChannels?.map((t, i) => {
        const link = !!t.controllerId && t.controllerId > 0 ? `/sitedetails/${item.siteId}/controllers/${t.controllerId}` : undefined;

        return (
          <GridCell key={i} columnLink={features.siteDetailsController.read ? link : undefined} highlightText={highlightText} width={width}>
            {displayMeasurement(t.batteryRuntimeService, { 'symbol': 'days', 'decimals': 0 } as IMeasurementUnit, true)}  
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_batteryruntimeservice',
      property: 'batteryRuntimeService',
      component: NumberFilter
    }
  },

  // Conductivity:
  {
    columnKey: ColumnAccess.ConductivityMin,
    columnTitle: 'ui_common_min',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_conductivity',
    render: (item, width) =>
      item.conductivityChannels?.map((cc, i) => (
        <GridCell key={i} width={width}>{displayMeasurement(cc.conductivityChannelDetails?.intervalMin, cc.unit as IMeasurementUnit, true)}</GridCell>
      )),
    filter: {
      name: 'ui_common_min',
      property: 'conductivityMin',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.ConductivityMax,
    columnTitle: 'ui_common_max',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_conductivity',
    render: (item, width) =>
      item.conductivityChannels?.map((cc, i) => (
        <GridCell key={i} width={width}>{displayMeasurement(cc.conductivityChannelDetails?.intervalMax, cc.unit as IMeasurementUnit, true)}</GridCell>
      )),
    filter: {
      name: 'ui_common_max',
      property: 'conductivityMax',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.ConductivityMean,
    columnTitle: 'ui_common_mean',
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_conductivity',
    render: (item, width) =>
      item.conductivityChannels?.map((cc, i) => (
        <GridCell key={i} width={width}>{displayMeasurement(cc.conductivityChannelDetails?.intervalMean, cc.unit as IMeasurementUnit, true)}</GridCell>
      )),
    filter: {
      name: 'ui_common_mean',
      property: 'conductivityMean',
      component: NumberFilter
    }
  },
  {
    columnKey: ColumnAccess.ConductivityParked,
    columnTitle: 'ui_sitelist_column_parked',
    render: (item, width) =>
      item.conductivityChannels?.map((cc, i) => <GridCell key={i}  width={width}>{cc.isParked ? <Icon name="parked" className="parked-icon" /> : undefined}</GridCell>),
    initialWidth: 100,
    managementGroup: 'ui_sitelist_group_conductivity',
    filter: {
      name: 'ui_sitelist_column_parked',
      property: 'conductivityparked',
      component: BooleanFilter,
      componentProps: {
        values: [
          {
            value: true,
            header: 'With',
            sub: 'Show sites with parked conductivity channels'
          },
          {
            value: false,
            header: 'Without',
            sub: 'Show sites without parked conductivity channels'
          },
          {
            value: undefined,
            default: true,
            header: 'Both',
            sub: 'Do not filter by this property'
          }
        ]
      }
    }
  },
  {
    columnKey: ColumnAccess.ConductivityAlias,
    columnTitle: 'ui_sitelist_column_alias',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_conductivity',
    render: (item, width, highlightText) =>
      item.conductivityChannels?.map((cc, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>{cc.alias}</GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_alias',
      property: 'conductivityalias',
      component: StringFilter
    }
  },
  {
    columnKey: ColumnAccess.ConductivityUnit,
    columnTitle: 'ui_sitelist_column_unit',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_conductivity',
    render: (item, width, highlightText) =>
      item.conductivityChannels?.map((sd, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {sd.unit?.symbol}{' '}
        </GridCell>
      )),
    filter: {
      name: 'ui_sitelist_column_unit',
      property: 'conductivityunitref',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },
  {
    columnKey: ColumnAccess.ConductivityLastSampleTime,
    columnTitle: 'ui_sitelist_column_lastsampletime',
    render: (item, width, highlightText) =>
      item.conductivityChannels?.map((c, i) => (
        <GridCell key={i} width={width} highlightText={highlightText}>
          {c.lastSampleTime && pureFormatDate(c.lastSampleTime, true, '.')}
        </GridCell>      
    )),
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_conductivity',
    filter: {
      name: 'ui_sitelist_column_lastsampletime',
      property: 'conductivitylastsampletime',
      component: DateFilter
    }
  },
  {
    columnKey: ColumnAccess.ConductivityHealth,
    columnTitle: 'ui_sitelist_column_sitehealth',
    initialWidth: 200,
    managementGroup: 'ui_sitelist_group_conductivity',
    render: (item, width) =>
      item.conductivityChannels?.map((c, i) => {
        const getIcon = () => {
          switch (c.health) {
            case HealthEnum.HasAlarm:
              return <div className="icon-wrapper"><Icon name="alert-triangle" className="alarm-icon" /><span className="highlight-count">{item.numberOfActiveAlarms}</span></div>;
            case HealthEnum.Offline:
              return <Icon name="offline-cross" className="health-icon" />;
            case HealthEnum.Online:
              return <Icon name="online-tick" className="health-icon" />;
            case HealthEnum.Parked:
              return <Icon name="parked" className="parked-icon" />;
            case HealthEnum.Stale:
              return <Icon name="fa-question" className="parked-icon" />;
            default:
              return undefined;
          }
        };
        return (
          <GridCell key={i} width={width} style={{textAlign: 'center'}} tooltipText={generateTooltipTextForIcon(c as SiteListPageRowChannel)}>
            {getIcon()}
          </GridCell>
        );
      }),
    filter: {
      name: 'ui_sitelist_column_sitehealth',
      property: 'conductivityhealth',
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.Sitelist
      }
    }
  },  
]);
