import Button from '$components/buttons/button.react';
import Checkbox from '$components/checkbox/checkbox.react';
import RequireConfirmation from '$components/require-confirmation/require-confirmation.react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import { ReactDeleteSitesDocument, SitesDeleteDialogInfoDocument } from '$typings/graphql-codegen';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SitelistMultiEditForm from './modules/edittable/multi-edit-form.react';
import { DecommissionReason, IDecommissionReason } from '$pages/common/controllers/controllerdetails-react/modules/modals/controller-delete-modal/modules/decommission-reason/decommission-reason'

import './multi-edit.css';

interface IActionButtonsProps {
  selectedSiteIds: string[];
  clearSelectedIds: () => void;
}

const SitelistActionButtons: FC<IActionButtonsProps> = ({ selectedSiteIds, clearSelectedIds }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [alsoDeleteControllers, setAlsoDeleteControllers] = useState(false);
  const [canAlsoSuspendSimCards, setCanAlsoSuspendSimCards] = useState(false);
  const [alsoSuspendSimCards, setAlsoSuspendSimCards] = useState(false);
  const [reason, setReason] = useState<IDecommissionReason | undefined>(undefined);
  const [t] = useTranslation();

  const cancelButtonClick = () => {
    setAlsoSuspendSimCards(false);
    setShowDeleteConfirmation(false);
  }

  const deleteSitesButtonClick = async () => {
    const variables = { siteIds: selectedSiteIds.map(i => ensureNumber(i)) };
    const deleteDialogInfo = await mutate(SitesDeleteDialogInfoDocument, variables);
    setCanAlsoSuspendSimCards(deleteDialogInfo.sitesDeleteDialogInfo.canSuspendSims);
    setShowDeleteConfirmation(true);
  };

  const doMutate = async () => {
    const deleteControllers = alsoDeleteControllers && (reason != undefined) ? { alsoSuspendSIMCards: alsoSuspendSimCards, decommissionReason: reason.reason as number, decommissionReasonText: reason.reasonText } : null;
    const variables = {
      siteIds: selectedSiteIds.map(i => ensureNumber(i)),
      alsoDeleteControllers: deleteControllers
    };
    await mutate(ReactDeleteSitesDocument, variables, true, clearSelectedIds);
  };

  return (
    <div className="flex">
      <Button variant="secondary" onClick={() => setShowEditForm(b => !b)}>
        {t('ui_common_edit')}
      </Button>
      <Button variant="danger" onClick={deleteSitesButtonClick} className="mar_lm">
        Delete sites
      </Button>
      {showDeleteConfirmation && (
        <RequireConfirmation deleteDisabled={alsoDeleteControllers && (reason == undefined)} onConfirm={doMutate} onCancel={cancelButtonClick}>
          <div className="ai-start">
            <p className="mar_bm">{t('ui_requireconfirmation_text', { entityName: 'the selected sites' })}</p>
            <Checkbox
              checked={alsoDeleteControllers}
              className="mark-controllers"
              onChange={() => setAlsoDeleteControllers(b => !b)}
              text="Also delete connected controllers?"
            ></Checkbox>
            <br/>
            { alsoDeleteControllers ?
              <div>
                <DecommissionReason onChange={setReason}/>
                <Checkbox
                  className="mar_tm"
                  checked={alsoSuspendSimCards}
                  disabled={!canAlsoSuspendSimCards}
                  onChange={() => setAlsoSuspendSimCards(b => !b)}
                  text={t('ui_deletecontrollerdialogs_alsosuspendsimcards')}
                ></Checkbox>
                <div className="suspend-warning" hidden={!alsoSuspendSimCards}>
                    {t('ui_deletecontrollerdialogs_alsosuspendsimcards_warning_line1')}<br/>
                    {t('ui_deletecontrollerdialogs_alsosuspendsimcards_warning_line2')}
                </div>
              </div>
              : null
            }
            <br/>
          </div>
        </RequireConfirmation>
      )}
      {showEditForm && <SitelistMultiEditForm clearSelectedIds={clearSelectedIds} selectedSiteIds={selectedSiteIds} closeEditor={() => setShowEditForm(false)}/>}
    </div>
  );
};

export default SitelistActionButtons;
