import { useGridData } from '$components/grid/react-grid/gridhelper';
import LoadingBarsReact from '$components/loading-bars/loading-bars.react';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { ElasticSearchPage, ReactMobileSiteListQueryDocument, ReactMobileSiteListQueryQueryVariables, ReactSiteStatusCardFragmentFragment, SortDirection } from '$typings/graphql-codegen';
import SiteStatusCardComponent from '../sitelist-status-card/sitelist-status-card';
import DataFreshness from '$pages/common/data-freshness/data-freshness.react';
import { Button, Card, CardContent, IconButton } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import TextField from '$components/textbox/text-field.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { CardWithHeader } from '$components/cards/mui-card';
import Skeleton from '$components/placeholders/skeleton/skeleton';
import { SitelistFilterList } from '../filter-list/sitelist-filter-list';
import { IFilterGroup } from '$interfaces/iFilter';
import { groupFilterDefinitions } from '$components/grid/react-grid/filter-list/filter-list';
import { ColumnAccess } from '../../../../../src/config';
import { SiteListUrlParams, defaultSitelistFilters, getSitelistVariablesFromParams } from '$pages/sitelistpage-react/modules/sitelist-utils';
import { usePersistedParsedState } from '$lib/hooks/usePersistedState';
import { useDebounce } from '$lib/hooks/debounce';
import { getColumns } from '$pages/sitelistpage-react/sitelist-columns';
import { revalidateAllActiveQueries } from '$pages/common/GraphQLFetcher';

interface IMobileSitelistProps {
  params?: SiteListUrlParams;
  selectConfig?: { setSelectedSiteId: Dispatch<SetStateAction<number | undefined>>, selectedSiteId: number | undefined }
}

const MobileSitelist: FC<IMobileSitelistProps> = ({ params, selectConfig }) => {
  const columns = getColumns();
  const [variables, setVariables] = usePersistedParsedState<ReactMobileSiteListQueryQueryVariables>("sitelist-mobile-variables", {
    sortProperty: ColumnAccess.SiteName,
    filters: JSON.stringify(defaultSitelistFilters),
    sortDirection: SortDirection.Asc,
    offset: 0,
    first: 100,
  });
  const [filters, setFilters] = useState<IFilterGroup[] | undefined>(variables.filters ? JSON.parse(variables.filters) : defaultSitelistFilters);


  const [freeTextQueryValue, setFreeTextQueryValue] = useState<string | undefined>(
    typeof variables.freeTextQuery === 'string' ? variables.freeTextQuery : variables.freeTextQuery?.join('\n')
  );
  const [freeTextQueryAsArray, setFreeTextQueryAsArray] = useState<string[] | undefined>([]);
  const freeTextQueryValueDebounced = useDebounce(freeTextQueryValue?.trim(), 400)
  const { data, visibleItemsChanged, isRevalidating } = useGridData(
    ReactMobileSiteListQueryDocument,
    variables,
    data => data.elasticSearchPages.sitelist.data.totalCount,
    data => data.elasticSearchPages.sitelist.data.edges
  );

  const [showMobileFilterEditor, setShowMobileFilterEditor] = useState(false);
  const [t] = useCaseInsensitiveTranslation()

  useEffect(() => {
    if (params && Object.keys(params).length) {
      const { filters, freeText, sortDirection, sortProperty } = getSitelistVariablesFromParams(params);

      //For unknown reasons we need to set the freeText value separately here as well
      //On some machines it worked without, on others not. Probably timing related.
      if (freeText && freeText !== '')
        setFreeTextQueryValue(freeText);

      setFilters(filters);
      setVariables(variables => ({
        ...variables,
        freeTextQuery: freeText,
        sortDirection: sortDirection || SortDirection.Asc,
        sortProperty: sortProperty || ColumnAccess.SiteName,
      }));
    }
  }, [params]);

  useEffect(() => {
    setVariables(variables => ({
      ...variables,
      filters: JSON.stringify(filters)
    }));
  }, [filters]);


  useEffect(() => {
    const asArray = freeTextQueryValueDebounced?.split('\n').map(q => q.trim());
    setVariables(variables => ({ ...variables, freeTextQuery: asArray }));
    setFreeTextQueryAsArray(asArray);
  }, [freeTextQueryValueDebounced]);

  const dataFreshnessPrefixText = `${data?.length || 0} sites`

  function loadNewData() {
    revalidateAllActiveQueries();
  }

  return (
    <div style={{ flex: '1 0 0px', display: "flex", flexDirection: "column" }}>
      <Card className="mar_tm mar_lm mar_rm" sx={{overflow: "unset"}}>
        <CardContent className="flex row aicenter filterbar">
          <div className="flex_1">
            <TextField
              icon={<Icon name={'fa-search'} />}
              value={freeTextQueryValue ?? ''}
              placeholder={t(
                'ui_sitelist_filter_freetext_filtersites'
              )}
              onChange={e => setFreeTextQueryValue(e.target.value)}
              className="controllerlist-searchfield"
            />
          </div>
          <div className="flex_0_1_auto">
            <IconButton
              /* very specific to align perfectly with the search field */
              sx={{ py: '8px', pl: '11px', pr: '11px', ml: '7px' }}
              onClick={() => setShowMobileFilterEditor(show => !show)}
              color={showMobileFilterEditor ? 'primary' : 'default'}
            >
              <Icon className="filter-icon" name={'fa-filter'} />
            </IconButton>
          </div>
        </CardContent>
      </Card>
      <DataFreshness
        prefixedText={dataFreshnessPrefixText}
        pageToCheck={ElasticSearchPage.Sitelist}
        freshnessChanged={loadNewData}
        className="flex jccenter mar_m jsb"
      />
      {showMobileFilterEditor ? (
        <Card sx={{
          display: "flex",
          flex: "1 0 auto",
          padding: 0
        }}>
          <CardContent sx={{
            padding: 0,
            width: "100%",
            display: "flex",
            flex: "1 0 auto",
            flexDirection: "column",
            justifyContent: "space-between"
          }}>
            <SitelistFilterList
              className="flex_1 overflow-auto"
              activeFilters={filters}
              freeTextQuery={freeTextQueryAsArray}
              filtersChanged={setFilters}
              groupedFilterDefinitions={groupFilterDefinitions(columns, t)}
            />
            <div className="flex_0_0_auto flex column jcflexend mar_bm mar_rm mar_lm ">
              <div className="flex jsb">
                <Button
                  variant="outlined"
                  className="flex_1"
                  onClick={() => setShowMobileFilterEditor(false)}
                >
                  {t('UI_Common_Show') + ' ' + dataFreshnessPrefixText}
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      ) :
        <>
          <Virtuoso
            style={{ margin: '0 1rem' }}
            overscan={50}
            data={data}
            components={{
              EmptyPlaceholder: () => (
                <>{isRevalidating && !data && <LoadingBarsReact />}</>
              )
            }}
            rangeChanged={i => {
              if (i.startIndex > 0) {
                visibleItemsChanged(i);
              }
            }}
            itemContent={(_index, item: ReactSiteStatusCardFragmentFragment) => {
              if (!item) return (<CardWithHeader>
                <Skeleton rowCount={6} columnCount={1} />
              </CardWithHeader>)
              return <SiteStatusCardComponent site={item} selectConfig={selectConfig} />;
            }}
          />
        </>
      }
    </div>
  );
};

export default MobileSitelist;
