import React, { FC, memo } from 'react';
import './loading-bars.css';

interface ILoadingBarsProps {
  center?: boolean;
}

const LoadingBars: FC<ILoadingBarsProps> = ({ center = false }) => {
  const bars = (
    <div className="loading-bars">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );

  if (center) return <div className="center-wrapper">{bars}</div>;

  return bars;
};

export default memo(LoadingBars);
