import React, {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ItemProps, TableVirtuoso } from 'react-virtuoso';
import Checkbox from '$components/checkbox/checkbox.react';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import '$components/grid/grid.css';
import './planner.css';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { GridTableHeaderCell } from '$components/grid/react-grid/grid-table-header-cell/grid-table-header-cell.react';
import { usePersistedParsedState } from '$lib/hooks/usePersistedState';
import { SortDirection } from '$typings/graphql-codegen';
import { removeNoneFromArray } from '$lib/arrayHelpers';
import { PlannerGridSummary } from './planner-grid-summary';
import Icon from '$components/icons/icon/icon.react';
import classNames from "classnames";

const ERROR_COLUMN_WIDTH = 20

export interface PlannerGridColum<T> {
  columnKey: string;
  columnTitle: string;
  render?: (
    item: T,
    width?: number | string,
    selected?: boolean
  ) => React.ReactNode;
  initialWidth: number | string;
}

interface IPlannerGridProps<T, K> {
  name: string;
  data?: T[];
  getRowKey: (item: T) => K;
  loading?: boolean;
  columns: PlannerGridColum<T>[];
  selected?: K[];
  setSelected: Dispatch<SetStateAction<K[]>>;
  getAllSelectableRowIds: () => K[];
  footerActionButtons?: ReactNode;
  footerText?: string;
  footerClass?: string;
  summaryFields?: string[];
  summaryValues?: Promise<Map<string, string>>;
}

const TableRow = <T,>(props: ItemProps<T>) => {
  const index = props['data-index'];
  return <tr {...props} className={classNames(index % 2 === 0 ? 'even' : 'odd')} />;
};

function getColumnWidthCacheKey(namespace: string) {
  return `${namespace}-columnWidths`;
}

const PlannerGridComponent = <T, K>({
  name,
  data,
  getRowKey,
  loading,
  columns,
  selected,
  setSelected,
  getAllSelectableRowIds,
  footerText,
  footerClass,
  footerActionButtons,
  summaryFields,
  summaryValues,
}: IPlannerGridProps<T, K>) => {
  const [columnKeys, setColumnKeys] = useState(columns.map((c) => c.columnKey));
  const [columnWidth, setColumnWidth] = usePersistedParsedState<
    Record<string, number | string | undefined>
  >(getColumnWidthCacheKey(name), {});
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [t] = useCaseInsensitiveTranslation();

  const columnsOrdered = useMemo(
    () =>
      removeNoneFromArray(
        columnKeys
          ? columnKeys.map((key) => columns.find((c) => c.columnKey === key))
          : columns
      ),
    [columns, columnKeys]
  );

  const toggleSelectedRow = (key: K) => {
    if (selected === undefined) {
      setSelected([key]);
      return;
    }

    const index = selected?.findIndex((s) => s === key);
    if (index !== -1 && selected) {
      selected.splice(index, 1);
      setSelected([...selected]);
      return;
    }

    setSelected([...selected, ...[key]]);
  };
  const isRowSelected = (key: K) => {
    return selected?.includes(key);
  };

  useEffect(() => {
    const allIsSelected = selected?.length === getAllSelectableRowIds().length;
    setIsAllSelected(allIsSelected);
  }, [selected, getAllSelectableRowIds]);

  const toggleAllRows = (setAllTrue: boolean) => {
    if (setAllTrue) {
      const all = getAllSelectableRowIds();
      setSelected(all);
    } else {
      setSelected([]);
    }
  };

  const moveRow = (dragIndex: number, droppedOverIndex: number) => {
    setColumnKeys &&
      setColumnKeys((existing) => {
        const draggedColumnKey = existing[dragIndex];
        existing.splice(dragIndex, 1);
        existing.splice(droppedOverIndex, 0, draggedColumnKey);
        return [...existing];
      });
  };

  const columnWidthChanged = useCallback(
    (columnKey: string, width: string | number) => {
      setColumnWidth((existing) => ({ ...existing, [columnKey]: width }));
    },
    [setColumnWidth]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex_1 fill-height grid-react">
        <TableVirtuoso
          data={data}
          fixedHeaderContent={() => (
            <>
              <tr>
                <th className="relative selectbox-cell">
                  <Checkbox
                    onClick={() => toggleAllRows(!isAllSelected)}
                    checked={isAllSelected}
                    className="selectbox-cell flex jccenter pad_m"
                  />
                </th>
                <th style={{ width: ERROR_COLUMN_WIDTH }} />
                {columnsOrdered.map((column, i) => (
                  <GridTableHeaderCell
                    key={column.columnKey}
                    moveRow={moveRow}
                    columnIndex={i}
                    sortedColumnKey={column.columnKey}
                    sortedDirection={SortDirection.Asc}
                    widthChanged={columnWidthChanged}
                    width={columnWidth[column.columnKey] ?? column.initialWidth}
                    editMode={true}
                    columnKey={column.columnKey}
                    hasActiveFilter={false}
                    disallowReordering
                  >
                    {t(column.columnTitle)}
                  </GridTableHeaderCell>
                ))}
                <th />
              </tr>
            </>
          )}
          style={{ flex: '1 0 0px' }}
          components={{
            EmptyPlaceholder: () => {
              return (
                <tbody>
                  <tr className="grid-loading">
                    <td>{loading ? <LoadingBars /> : t('ui_grid_nomatch')}</td>
                  </tr>
                </tbody>
              );
            },
            TableRow: TableRow,
          }}
          itemContent={(_: number, data: T & { error: string | undefined }) => {
            if (!data)
              return (
                <>
                  <td className="selectbox-cell flex column" />
                  {columnsOrdered.map((c) => (
                    <td key={c.columnKey}>
                      <GridCell
                        width={columnWidth[c.columnKey] ?? c.initialWidth}
                      />
                    </td>
                  ))}
                </>
              );
            const rk = getRowKey(data);
            const selected = isRowSelected(rk);
            return (
              <>
                <td
                  className={classNames(
                    'selectbox-cell',
                    'flex',
                    'column',
                    !selected ? 'unselected' : data.error && 'error'
                  )}
                  style={{ width: 'unset' }}
                >
                  <Checkbox
                    className="selectbox-cell flex jccenter pad_m"
                    onClick={() => toggleSelectedRow(rk)}
                    checked={selected}
                  />
                </td>
                <td className={classNames(!selected ? 'unselected' : data.error && 'error')}>
                  <GridCell 
                    width={ERROR_COLUMN_WIDTH} 
                    tooltipText={data.error} 
                    className="error-column-cell"
                  >
                    {data.error && (
                      <Icon name={'fa-exclamation-triangle'} />
                    )}
                  </GridCell>
                </td>
                {columnsOrdered.map((c) => {
                  return (
                    <td
                      key={c.columnKey}
                      className={classNames(!selected ? 'unselected' : data.error && 'error')}
                    >
                      {c.render?.(
                        data,
                        columnWidth[c.columnKey] ?? c.initialWidth,
                        selected
                      )}
                    </td>
                  );
                })}
                <td className={classNames(!selected ? 'unselected' : data.error && 'error')} />
              </>
            );
          }}
          fixedFooterContent={() =>
            summaryValues &&
            summaryFields && (
              <PlannerGridSummary
                columnsOrdered={columnsOrdered}
                columnWidth={columnWidth}
                summaryFields={summaryFields}
                summaryValues={summaryValues}
              />
            )
          }
        />
      </div>
      {(footerText || footerActionButtons) && (
        <div className={classNames('grid-action-bar-component', footerClass)}>
          <div className="selection-text">{footerText}</div>
          <div className="flex row">{footerActionButtons}</div>
        </div>
      )}
    </DndProvider>
  );
};

export const PlannerGrid = memo(PlannerGridComponent) as typeof PlannerGridComponent;
