import React, { FC } from 'react';
import './empty-component-description.css';

const EmptyComponentDescription: FC = ({ children }) => (
  <div className="empty-component-description-wrapper">
    <div className="page-empty-data-description">{children}</div>
  </div>
);

export default EmptyComponentDescription;
