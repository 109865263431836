import './sap-siteaddress.css';
import { bindable, autoinject, customElement } from 'aurelia-framework';
import { MapService } from '../../../../../../services';
import { getLogger, Logger } from 'aurelia-logging';
import { ISiteDetailsMapAddressResult } from '../../../../../../interfaces';
import { getName } from '../../../../../../utility/application/countryHelpers';

@autoinject()
@customElement('sap-siteaddress')
export class SapSiteAddress {
  @bindable() siteId: number;
  @bindable() cancelled: Function;
  @bindable() selected: Function;

  logger: Logger;

  constructor(private mapservice: MapService) {
    this.logger = getLogger(SapSiteAddress.name);
  }

  result: ISiteDetailsMapAddressResult | undefined;
  selectedIndex: undefined | number;
  fetching = false;
  error = false;
  
  async attached() {
    this.fetching = true;
    this.error = false;
    this.result = undefined;

    try {
      const apiresult = await this.mapservice.getAddressesFromSiteId(
        this.siteId
      );

      for (const item of apiresult.addresses) {
        item.country = getName(item.country) || item.country;
      }

      this.result = apiresult;

      if (this.result?.addresses?.length) {
        this.selectedIndex = 0;
      }
    } catch (err) {
      this.logger.error(err);
      this.error = true;
    }
    this.fetching = false;
  }
}
