import {default as React, FC, useEffect, useMemo, useRef, useState} from 'react';
import {
  ReactSitelistExcelQueryDocument,
  ReactSiteListIdsQueryDocument,
  ReactSitelistQueryDocument,
  ReactSitelistQueryQueryVariables,
  SitelistItemFragment,
  SortDirection
} from '../../../../../custom_typings/graphql-codegen';
import {ColumnAccess} from '../../../../config';
import {columnKey} from '$components/grid/grid';
import {usePersistedBooleanState, usePersistedParsedState} from '$lib/hooks/usePersistedState';
import Card from '$components/cards/card/card.react';
import CardContent from '$components/cards/card-content/card-content.react';
import './sitelist.react.css';
import {IFilterGroup} from 'src/interfaces';
import {ElasticSearchPage, ReactSitelistQuery_elasticSearchPages_sitelist_data_edges} from '$typings/graphql';
import {difference} from '$lib/arrayHelpers';
import {mutate} from '$lib/hooks/fetch-utillities';
import Button from '$components/buttons/button.react';
import TextboxMultiline from '$components/textbox-multiline/textbox-multiline.react';
import ButtonGroup from '$components/button-group/button-group.react';
import {
  defaultSitelistFilters,
  getSitelistVariablesFromParams,
  SiteListUrlParams
} from '$pages/sitelistpage-react/modules/sitelist-utils';
import SitelistMap from '$pages/sitelistpage-react/modules/sitelistmap/sitelistmap.react';
import {isNone} from '$lib/helpers';
import {hasAccessToColumn, lsdIsOffline} from '$lib/';
import {getColumns} from '../../sitelist-columns';
import Sliders from '$components/icons/svg/react/sliders';
import DataFreshness from '$pages/common/data-freshness/data-freshness.react';
import {revalidateAllActiveQueries} from '$pages/common/GraphQLFetcher';
import {downloadFileFromUrl} from '$lib/hooks/blob-utillities';
import SitelistActionButtons from '$pages/sitelist/common/multi-edit/sitelist-action-buttons.react';
import {useGridData, UseGridDataReturnType} from '$components/grid/react-grid/gridhelper';
import Grid, {
  IReactGridColumn,
  IReactGridFilter,
  IReactGridSelectableRowsConfig,
  rowKey
} from '$components/grid/react-grid/grid.react';
import Icon from '$components/icons/icon/icon.react';
import stringify from 'json-stable-stringify';
import {useDebounce} from '$lib/hooks/debounce';
import {getSession} from '../../../../config/sessionService';
import {SitelistFilterList} from '$pages/sitelistpage-react/modules/filter-list/sitelist-filter-list';
import {LocalSettings} from '$services/localSettingsService';
import {groupFilterDefinitions} from '$components/grid/react-grid/filter-list/filter-list';
import {MapPanelResizable} from "$pages/sitelistpage-react/modules/mappanel/map-panel-resizable";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";

export function useSitelistSelectableRowsConfig(
  select: 'site' | 'tank' | 'siteButTanks',
  variables: ReactSitelistQueryQueryVariables,
  selectSingleRow: boolean,
  selectedRowsChanged?: Function,
  hideActionButtons?: boolean
): IReactGridSelectableRowsConfig<ReactSitelistQuery_elasticSearchPages_sitelist_data_edges> {
  type Dictionary = {[id: number]: number[]} ;  
  const [selectedRows, setSelectedRows] = useState<rowKey[]>([]);
  const ref = useRef<ReactSitelistQueryQueryVariables>();
  const [lastFetchedRowIds, setLastFetchRowIds] = useState<rowKey[] | undefined>();
  const [rowTanks, setRowTanks] = useState<Dictionary>({}); //Dictionary to keep track of row's tank's siteChannelIds for order planner

  const fetchAllRowIds = async () => {
    if (ref.current === variables && lastFetchedRowIds) {
      return lastFetchedRowIds;
    }
    const result = await mutate(ReactSiteListIdsQueryDocument, variables);
    const rowKeys = result.elasticSearchPages.sitelist.data.edges.map(s => s.siteId);
    ref.current = variables;
    setLastFetchRowIds(rowKeys);
    return rowKeys;
  };

  const getRowkey = (row: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges) => {
    switch (select) {
      case 'site':
      case 'siteButTanks':
        return [row?.siteId];
      
      case 'tank':
        return row.tankChannels?.map(t => t.siteChannelId) || [];
      
      default:
        throw `Unsupported select element "${select}" in getRowKey`;           
    }
  }
   
  const selectText = select === 'tank' ? 'ui_sitelist_selectionbar_tank_description' : undefined;

  const _selectedRowsChanged = (selectedRows: rowKey[], rowData?: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges[]) => {
    setSelectedRows(selectedRows);
    
    if (selectedRowsChanged) {
      //siteButTanks is for order planner where we select sites, but send the siteChannelIds of the tanks instead
      if (select === 'siteButTanks') 
      {    
        if (rowData && selectedRows) {
          let newRowTanks = {...rowTanks};
          selectedRows.forEach(rowKey => {
            const dataForRow = rowData.find(r => getRowkey(r)[0] === rowKey);

            if (dataForRow) {
              const siteId = rowKey as number;
              //Get siteChannels for selected row          
              const siteChannelIds = dataForRow?.tankChannels?.filter(sc => sc?.siteChannelId).map(sc => sc.siteChannelId);
              if (siteChannelIds) {
                newRowTanks = {
                  ...newRowTanks,
                  [siteId]: siteChannelIds
                }
              }
            }
          });
          
          setRowTanks(newRowTanks);
          const selectedSiteTanksSiteChannelIds = selectedRows.map(rowKey => newRowTanks[rowKey as number])?.flatMap(c => c).filter(c => c); 
          selectedRowsChanged(selectedSiteTanksSiteChannelIds);        
        }
      }
      else {
        selectedRowsChanged(selectedRows);
      }
    }
  };

  return {
    rowKey: getRowkey,
    selectedRowsChanged: _selectedRowsChanged,
    selectText: selectText,
    selectSingleRow: selectSingleRow,
    fetchAllSelectableRowIds: fetchAllRowIds,
    selectedRows: selectedRows,
    actionButtons: !hideActionButtons ? <SitelistActionButtons selectedSiteIds={selectedRows as string[]} clearSelectedIds={() => _selectedRowsChanged([])} /> : undefined
  };
}

export class IReactGroupedFilterDefinition {
  managementGroup: string;
  filters: IReactGridFilter[];
}

const defaultSelectedColumns: ColumnAccess[] = [
  ColumnAccess.SiteName,
  ColumnAccess.TankPercentFull,
  ColumnAccess.LastSampleDate,
  ColumnAccess.ProductName,
  ColumnAccess.ReachMinimum,
  ColumnAccess.ReachMinimumInDays,
  ColumnAccess.TankLevel,
  ColumnAccess.TankUnit,
  ColumnAccess.SiteHealth,
  ColumnAccess.Signal
];

export interface ISiteListProps {
  select?: 'site' | 'tank' | 'siteButTanks';
  selectSingleRow?: boolean;
  selectedRowsChanged?: Function;
  params?: SiteListUrlParams;
  noPadding?: boolean;
  plannerMode?: boolean;
  rememberScroll?: boolean;
  showBackToTopButton?: boolean;
  hideActionBar?: boolean;
  disableMap?: boolean;
  mapComponentSlot?: React.ReactNode;
}

const Sitelist: FC<ISiteListProps> = (
  {
    select = 'site',
    selectSingleRow = false,
    selectedRowsChanged,
    params,
    noPadding = false,
    plannerMode = false,
    rememberScroll = true,
    showBackToTopButton = true,
    hideActionBar = false,
    disableMap = false,
    mapComponentSlot = undefined

  }) => {
  const [session] = useState(getSession())
  const columns = useMemo(() => getColumns(plannerMode), [plannerMode]);
  const name = 'react-sitelist';
  const [t, i18n] = useCaseInsensitiveTranslation();
  const [downloadingExcel, setDownloadingExcel] = useState(false);

  const [columnEditMode, setColumnEditMode] = useState(false);
  const [showFilters, setShowFilters] = usePersistedBooleanState('sitelist-filterpinned', false);
  const [selectedColumns, setSelectedColumns] = usePersistedParsedState<columnKey[]>('sitelistSelectedColumns', defaultSelectedColumns);

  const [variables, setVariables] = usePersistedParsedState<ReactSitelistQueryQueryVariables>(`${name}-variables`, {
    ...getIncludes(),
    sortProperty: ColumnAccess.SiteName,
    filters: JSON.stringify(defaultSitelistFilters),
    sortDirection: SortDirection.Asc,
    offset: 0,
    first: 100,
    language: i18n.language
  });

  const [isMapOpen, setIsMapOpen] = usePersistedBooleanState(getShowMapKey(), LocalSettings.getSettingAsBoolean("sitelistShowMapKey", false));

  const [mapHeight, setMapHeight] = usePersistedParsedState(getHeightKey(), LocalSettings.getSetting<string>("sitelistHeightKey", '50%'));

  const [filters, setFilters] = useState<IFilterGroup[] | undefined>(variables.filters ? JSON.parse(variables.filters) : defaultSitelistFilters);
  const [freeTextQueryValue, setFreeTextQueryValue] = useState<string | undefined>(
    typeof variables.freeTextQuery === 'string' ? variables.freeTextQuery : variables.freeTextQuery?.join('\n')
  );
  const [freeTextQueryAsArray, setFreeTextQueryAsArray] = useState<string[] | undefined>([]);
  const freeTextQueryValueDebounced = useDebounce(freeTextQueryValue?.trim(), 400)
  
  function isAccessible(col: IReactGridColumn<any>): boolean {
    return hasAccessToColumn(session, col.columnKey)
  }
  
  function filterAccessibleColumns(
    cols: IReactGridColumn<SitelistItemFragment>[]
  ): IReactGridColumn<SitelistItemFragment>[] {
    return cols.filter(isAccessible)
  }

  function getShowMapKey() {
    return name + 'ShowMapKey';
  }

  function getHeightKey() {
    return name + 'HeightKey';
  }

  const gridData: UseGridDataReturnType<SitelistItemFragment> = useGridData(
    ReactSitelistQueryDocument,
    variables,
    data => data.elasticSearchPages.sitelist.data.totalCount,
    data => data.elasticSearchPages.sitelist.data.edges
  );

  useEffect(() => {
    setVariables(v => ({ ...v, language: i18n.language }))
  }, [i18n]);

  useEffect(() => {
    if (params && Object.keys(params).length) {
      const { filters, freeText, sortDirection, sortProperty } = getSitelistVariablesFromParams(params);

      //For unknown reasons we need to set the freeText value separately here as well
      //On some machines it worked without, on others not. Probably timing related.
      if (freeText && freeText !== '')
        setFreeTextQueryValue(freeText);

      if (params.showMap) { 
        mapHeight === "0px" && isMapOpen ? setMapHeight("50%") : setIsMapOpen(true);
      }

      setFilters(filters);
      
      let parsedSortProp: number
      switch (typeof sortProperty) {
        case 'string':
          parsedSortProp = parseInt(sortProperty, 10)
          break
        case 'number':
          parsedSortProp = sortProperty
          break
        default:
          parsedSortProp = ColumnAccess.SiteName
      }
      
      setVariables(variables => ({
        ...variables,
        freeTextQuery: freeText,
        sortDirection: sortDirection || SortDirection.Asc,
        sortProperty: parsedSortProp,
        language: i18n.language
      }));
    }
  }, [params]);

  useEffect(() => {
    setVariables(variables => ({
      ...variables,
      filters: JSON.stringify(filters)
    }));
  }, [filters]);

  useEffect(() => {
    setVariables(variables => ({ ...variables, ...getIncludes() }));
  }, [selectedColumns]);

  useEffect(() => {
    const asArray = freeTextQueryValueDebounced?.split('\n').map(q => q.trim());
    setVariables(variables => ({ ...variables, freeTextQuery: asArray }));
    setFreeTextQueryAsArray(asArray);
  }, [freeTextQueryValueDebounced]);

  async function downloadExcel() {
    setDownloadingExcel(true);
    if (!selectedColumns) return;

    const downloadVariables = {
      ...variables,
      columns: selectedColumns,
      language: i18n.language
    };
    try {
      const promise = mutate(ReactSitelistExcelQueryDocument, downloadVariables);
      const excelSheet = await promise;
      await downloadFileFromUrl(excelSheet.elasticSearchPages.sitelist.excelSheet, 'Sites.xlsx');
    } catch (error) {
      throw new Error('Could not download excel');
    }
    setDownloadingExcel(false);
  }

  function columnIsShown(...columns: ColumnAccess[]) {
    return columns.some(column => selectedColumns.indexOf(column) !== -1);
  }

  function loadNewData() {
    revalidateAllActiveQueries();
  }

  function activeFilterWarning() {
    if (!filters || filters.length == 0) {
      return "";
    }

    const exclusions = filters
      .filter(f => f.exclude)
      .map(f => i18n.t(`ui_sitelist_filterwarning_${f.field.toLowerCase()}`))

    if (exclusions.length > 0) {
      return i18n.t("ui_sitelist_filterwarning_excluding") + ": " + exclusions.join(" & ") + ' ' + i18n.t('ui_sitelist_filterwarning_sites');
    }

    return "";
  }

  function getIncludes() {
    const productIsShown = columnIsShown(ColumnAccess.ProductName, ColumnAccess.TankLevelInTonnes);
    const vehicleIsShown = columnIsShown(ColumnAccess.TankVehicle);
    const unitIsShown = columnIsShown(ColumnAccess.TankUnit, ColumnAccess.AvailableSpace, ColumnAccess.TankLevel, ColumnAccess.TankCapacity, ColumnAccess.TankLevelInTonnes);
    const salesUserIsShown = columnIsShown(ColumnAccess.SalesPerson);
    const customerIsShown = columnIsShown(ColumnAccess.CustomerName, ColumnAccess.AccessTagAlias);
    const buidIsShown = columnIsShown(ColumnAccess.Buid);
    const signalIsShown = columnIsShown(ColumnAccess.Signal);
    const lastTankRefillShown = columnIsShown(ColumnAccess.TankLastDeliveryTicket) || columnIsShown(ColumnAccess.TankLastDeliveryAmount);
    const nextPlannedFillingShown = columnIsShown(ColumnAccess.TankNextFilling);
    const tankChannelIsShown = select === 'tank' || productIsShown || vehicleIsShown || unitIsShown || lastTankRefillShown || nextPlannedFillingShown || columnIsShown(ColumnAccess.HaulierTag) || columnIsShown(ColumnAccess.SiteIsReceivingDeliveryTickets, ColumnAccess.ParkedDescription, ColumnAccess.TankDescription, ColumnAccess.TankPercentFull, ColumnAccess.SapNumber, ColumnAccess.OrderProcess, ColumnAccess.AvailableSpace, ColumnAccess.LastSampleDate, ColumnAccess.TankLevel, ColumnAccess.TankCapacity, ColumnAccess.TankLevelInTonnes, ColumnAccess.TankControllerType, ColumnAccess.TankControllerIcc, ColumnAccess.TankControllerIpAddress, ColumnAccess.TankControllerSerial, ColumnAccess.TankControllerAlternateSerial, ColumnAccess.ChannelId, ColumnAccess.SiteHealth, ColumnAccess.TankIsParked, ColumnAccess.CustomerProductRef);
    const gasChannelIsShown = columnIsShown(ColumnAccess.H2SMax, ColumnAccess.H2SMean, ColumnAccess.H2SPercentTWA, ColumnAccess.H2SHealth, ColumnAccess.H2SParked, ColumnAccess.H2SLastSampleTime, ColumnAccess.H2SAlias, ColumnAccess.H2SControllerType, ColumnAccess.H2SControllerIcc, ColumnAccess.H2SControllerIpAddress, ColumnAccess.H2SControllerSerial, ColumnAccess.H2SControllerAlternateSerial, ColumnAccess.H2SRuntimeCalibration);
    const batteryChannelIsShown = columnIsShown(ColumnAccess.BatteryLevel, ColumnAccess.BatteryAlias, ColumnAccess.BatteryLastSampleTime, ColumnAccess.BatteryHealth, ColumnAccess.BatteryControllerType, ColumnAccess.BatteryControllerIcc, ColumnAccess.BatteryControllerIpAddress, ColumnAccess.BatteryControllerSerial, ColumnAccess.BatteryControllerAlternateSerial, ColumnAccess.BatteryRuntimeService);
    const stockVsDoseChannelIsShown = columnIsShown(ColumnAccess.StockVsDoseCalibration, ColumnAccess.StockVsDoseCalibration5, ColumnAccess.StockVsDoseCalibration25, ColumnAccess.StockVsDoseHoulyAvgDose, ColumnAccess.StockVsDoseHoulyTankUsage, ColumnAccess.StockVsDoseLastSampleTime, ColumnAccess.StockVsDoseLastSampleTime, ColumnAccess.StockVsDoseHealth);
    const temperatureChannelIsShown = columnIsShown(ColumnAccess.TemperatureMin, ColumnAccess.TemperatureMean, ColumnAccess.TemperatureMax, ColumnAccess.TemperatureAlias, ColumnAccess.TemperatureLastSampleTime, ColumnAccess.TemperatureHealth, ColumnAccess.TemperatureControllerType, ColumnAccess.TemperatureControllerIcc, ColumnAccess.TemperatureControllerIpAddress, ColumnAccess.TemperatureControllerSerial, ColumnAccess.TemperatureControllerAlternateSerial);
    const flowChannelIsShown = columnIsShown(ColumnAccess.FlowMax, ColumnAccess.FlowMean, ColumnAccess.FlowMin, ColumnAccess.FlowSum, ColumnAccess.FlowAlias, ColumnAccess.FlowUnit, ColumnAccess.FlowLastSampleTime, ColumnAccess.FlowParked, ColumnAccess.FlowHealth, ColumnAccess.FlowControllerType, ColumnAccess.FlowControllerIcc, ColumnAccess.FlowControllerIpAddress, ColumnAccess.FlowControllerSerial, ColumnAccess.FlowControllerAlternateSerial);
    const doseChannelIsShown = columnIsShown(ColumnAccess.DoseAlias, ColumnAccess.DoseHealth, ColumnAccess.DoseLastSampleTime, ColumnAccess.DoseMax, ColumnAccess.DoseMean, ColumnAccess.DoseMin, ColumnAccess.DoseParked, ColumnAccess.DoseSum, ColumnAccess.DoseUnit, ColumnAccess.DoseHealth, ColumnAccess.DoseControllerType, ColumnAccess.DoseControllerIcc, ColumnAccess.DoseControllerIpAddress, ColumnAccess.DoseControllerSerial, ColumnAccess.DoseControllerAlternateSerial, ColumnAccess.DoseCustomerFactor);
    const retentiontimeChannelIsShown = columnIsShown(ColumnAccess.RetentiontimeAlias, ColumnAccess.RetentiontimeHealth, ColumnAccess.RetentiontimeLastSampleTime, ColumnAccess.RetentiontimeMax, ColumnAccess.RetentiontimeMean, ColumnAccess.RetentiontimeMin, ColumnAccess.RetentiontimeParked, ColumnAccess.RetentiontimeUnit);
    const conductivityChannelIsShown = columnIsShown(ColumnAccess.ConductivityAlias, ColumnAccess.ConductivityControllerAlternateSerial, ColumnAccess.ConductivityControllerIcc, ColumnAccess.ConductivityControllerIpAddress, ColumnAccess.ConductivityControllerSerial, ColumnAccess.ConductivityHealth, ColumnAccess.ConductivityLastSampleTime, ColumnAccess.ConductivityMax, ColumnAccess.ConductivityMin, ColumnAccess.ConductivityMean, ColumnAccess.ConductivityParked, ColumnAccess.ConductivityLastSample, ColumnAccess.ConductivityUnit, ColumnAccess.ConductivityControllerType);

    return {
      buidIsShown,
      lastTankRefillShown,
      tankChannelIsShown,
      gasChannelIsShown,
      batteryChannelIsShown,
      customerIsShown,
      productIsShown,
      salesUserIsShown,
      unitIsShown,
      vehicleIsShown,
      stockVsDoseChannelIsShown,
      nextPlannedFillingShown,
      temperatureChannelIsShown,
      flowChannelIsShown,
      signalIsShown,
      doseChannelIsShown,
      retentiontimeChannelIsShown,
      conductivityChannelIsShown
    };
  }

  const selectableRowConfig = useSitelistSelectableRowsConfig(select, variables, selectSingleRow, selectedRowsChanged, plannerMode);

  const getRowsInTextArea = () => {
    return typeof variables.freeTextQuery === 'string' ? 1 : Math.min(4, variables.freeTextQuery?.length || 1);
  };

  const getRowColor = (row: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges) => {
    if (isNone(row)) return '';
    if (row.isVirtual) return 'virtual';
    if (lsdIsOffline(row.lastSampleTime)) return 'offline';
    if (row.hasActiveChannelAlarms) return 'alarm';
    return undefined;
  };

  const rowLink = (row: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges) => {
    if (!row || plannerMode) return;
    return `sitedetails/${row.siteId}`;
  };

  const resetFilter = () => {
    setFilters(defaultSitelistFilters);
  };

  const numberOfActiveFilters = useMemo(() => {
    if (!filters) return;
    return (
      difference(
        defaultSitelistFilters.map(a => a.field),
        filters.map(a => a.field)
      ).length +
      filters.reduce((cur, f) => {
        const defaultFilterGroup = defaultSitelistFilters.find(df => df.field === f.field);
        if (!defaultFilterGroup) return cur + (f.filters?.length || 0);
        return stringify(defaultFilterGroup) === stringify(f) ? cur : cur + (f.filters?.length || 0);
      }, 0)
    );
  }, [filters]);

  return (
    <div
      className="sitelistpage-react"
      style={{
        padding: noPadding ? '0' : '1rem',
        flex: '1 0 0px',
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem'
      }}
    >
      {showFilters && (
        <Card className="filterlist-card fill-height">
          <CardContent className="no-padding fill-height flex column">
            <SitelistFilterList
              className="flex_1 overflow-auto"
              activeFilters={filters}
              freeTextQuery={freeTextQueryAsArray}
              filtersChanged={setFilters}
              groupedFilterDefinitions={
                groupFilterDefinitions(
                  filterAccessibleColumns(columns),
                  t
                )
              }
            />
          </CardContent>
        </Card>
      )}
      <Card className="sitelistcard">
        {/* #7391: Map is always open in planner mode */}
        {((plannerMode || (isMapOpen && !disableMap)) && (
          <MapPanelResizable
            minimumHeightPercent={mapHeight}
            leftSlot={
              <SitelistMap
                siteIds={(select === 'site' || select === 'siteButTanks') ? (selectableRowConfig.selectedRows as string[]) : undefined}
                siteChannelIds={select === 'tank' ? (selectableRowConfig.selectedRows as string[]) : undefined}
                freeTextQuery={variables.freeTextQuery as string[]}
                filters={filters}
                setMapHeight={setMapHeight}
                mapHeight={mapHeight}
              />}
            rightSlot={mapComponentSlot}
          />
        ))}
        <div className="flex justify-between items-center p-4">
          <div className="flex items-center gap-4">
            <TextboxMultiline
              className="textbox-multiline"
              restrictResizeTo="no-resize"
              rows={getRowsInTextArea()}
              value={freeTextQueryValue}
              placeholder={t('ui_sitelist_filter_freetext_filtersites')}
              onChange={value => setFreeTextQueryValue(value)}
              shiftEnterAsNewline
            />
            <div>
              {!numberOfActiveFilters && (
                <Button variant="secondary" onClick={() => setShowFilters(e => !e)}>
                  <Sliders className="mar_rs flex" />
                  {numberOfActiveFilters ? numberOfActiveFilters : undefined} Filters
                </Button>
              )}
              {!!numberOfActiveFilters && (
                <ButtonGroup className='flex'>
                  <Button variant="secondary" onClick={() => setShowFilters(e => !e)}>
                    <Sliders className="mar_rs felx" />
                    {numberOfActiveFilters ? numberOfActiveFilters : undefined} Filters
                  </Button>
                  <Button variant="danger" onClick={resetFilter}>
                    <Icon name="fa-times" />
                  </Button>
                </ButtonGroup>
              )}
            </div>
            <div className='sitelist-result-info-wrapper flex ai-start column mar_lm'>
              <span className="flex sitelist-results-info nowrap">
                {gridData.data?.length} sites <DataFreshness pageToCheck={ElasticSearchPage.Sitelist} freshnessChanged={loadNewData} />
              </span>
              <span className="sitelist-results-filter-info">
                <b>{activeFilterWarning()}</b>
              </span>
            </div>
          </div>
          {plannerMode ? (
            <>
              <Button variant="secondary" onClick={() => setColumnEditMode(e => !e)} icon="columns">
                {columnEditMode ? t('ui_sitelist_actions_closecolumneditor') : t('ui_sitelist_actions_editcolumns')}
              </Button>
            </>
          ) : (
            <>
              <ButtonGroup className="flex ai-start">
                <Button variant="secondary" onClick={() => setColumnEditMode(e => !e)} icon="columns">
                  {columnEditMode ? t('ui_sitelist_actions_closecolumneditor') : t('ui_sitelist_actions_editcolumns')}
                </Button>
                <Button variant="secondary" processing={downloadingExcel} onClick={downloadExcel} icon="download">
                  {t('ui_sitelist_actions_download')}
                </Button>
                {!disableMap && (
                    <Button variant="secondary" onClick={() => {
                      mapHeight === "0px" ? setMapHeight("50%") : setIsMapOpen(b => !b);
                    }}>
                    {t('ui_sitelist_actions_togglemap')}
                  </Button>
                )}
              </ButtonGroup>
            </>
          )}

        </div>
        <Grid
          name="sitelist"
          rowLink={rowLink}
          selectableRowsConfig={selectableRowConfig}
          items={gridData}
          columns={filterAccessibleColumns(columns)}
          selectedColumns={selectedColumns}
          selectedColumnsChanged={setSelectedColumns}
          columnEditMode={columnEditMode}
          sortedDirection={variables.sortDirection}
          sortedColumnKey={variables.sortProperty}
          activeFilters={filters}
          filtersChanged={setFilters}
          sortChanged={(sortProperty, sortDirection) => setVariables(v => ({ ...v, sortProperty, sortDirection }))}
          showGroupedHeader
          highlightText={variables.freeTextQuery || undefined}
          freeTextQuery={freeTextQueryAsArray}
          loading={gridData.isRevalidating && !gridData.data}
          rowClass={getRowColor}
          className="sitelist-grid"
          rememberScroll={rememberScroll}
          showBackToTopButton={showBackToTopButton}
          hideActionBar={hideActionBar}
        />
      </Card>
    </div>
  );
};

export default Sitelist;
