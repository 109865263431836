import Tooltip from '$components/tooltip/tooltip.react';
import { formatDate } from '$lib/dateHelpers';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { isNumber } from '$lib/numberHelpers';
import {
  ElasticSearchPage,
  ReactDataFreshnessQueryDocument,
} from '$typings/graphql-codegen';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";

interface IDataFreshnessProps {
  freshnessChanged: Function;
  pageToCheck: ElasticSearchPage;
  prefixedText?: string;
  postfixedText?: string;
  className?: string;
}

const DataFreshness: FC<IDataFreshnessProps> = ({
  pageToCheck,
  freshnessChanged,
  prefixedText,
  postfixedText,
  className
}) => {
  const { data } = useQuery(
    ReactDataFreshnessQueryDocument,
    { page: pageToCheck },
    { refreshInterval: 5000 }
  );
  const [t] = useCaseInsensitiveTranslation();

  const [previousFreshness, setPreviousFreshness] = useState<
    number | undefined | null
  >();

  useEffect(() => {
    if (!freshnessChanged) return;
    const lastUpdated = data?.elasticSearchPages?.lastUpdated;
    if (lastUpdated !== previousFreshness) freshnessChanged();
    setPreviousFreshness(lastUpdated);
  }, [data]);

  const tooltipVariables = useCallback(() => {
    const lastUpdated = data?.elasticSearchPages?.lastUpdated;
    if (lastUpdated == null) return {date: null};

    const formattedDate = formatDate(
      new Date(lastUpdated * 1000),
      true,
      '.',
      undefined
    );
    return { date: formattedDate };
  }, [data]);

  const minutesSince = useCallback(() => {
    const lastUpdated = data?.elasticSearchPages?.lastUpdated;
    const now = data?.epochNow;

    if (lastUpdated == null || now == null) return;

    const diff = now - lastUpdated;
    return Math.floor(diff / 60);
  }, [data]);

  let displayText = t('ui_common_grid_datafreshness_minutesold', {
    count: minutesSince(),
  });
  
  if (prefixedText) displayText = prefixedText + displayText;
  if (postfixedText) displayText = displayText + postfixedText;
  
  return (
    <>
      {isNumber(minutesSince()) && (
        <Tooltip
          text={t('ui_common_grid_datafreshness_tooltip', tooltipVariables())}
          customClass={className}
        >
          {displayText}
        </Tooltip>
      )}
    </>
  );
};

export default DataFreshness;
