import React, {
  ChangeEventHandler,
  HTMLInputTypeAttribute,
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  HTMLAttributes,
  ChangeEvent
} from 'react';
import './textbox.css';
import ErrorText from '$components/texts/error-text/error-text.react';
import classNames from 'classnames';

export interface ITextFieldProps extends HTMLAttributes<HTMLInputElement> {
  name?: string;
  value?: string | number;
  type?: HTMLInputTypeAttribute;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  icon?: React.ReactNode;
  size?: 'medium' | 'small' | 'large';
  autoFocus?: boolean;
  required?: boolean;
  step?: string;
  min?: number | string;
  max?: number | string;
  removeWhitespace?: boolean;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
}

const TextField = forwardRef<HTMLInputElement, ITextFieldProps>(
  (props, ref) => {
    const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
      //Remove whitespace from start of val
      if (evt?.target?.value && props.removeWhitespace)
        evt.target.value = evt.target.value.trim();

      if (props.onChange) props.onChange(evt);
    };

    return (
      <div className="text-field-component">
        <label
          className={classNames(
            'component-textbox',
            'component-textbox-' + (props.size ?? 'medium'),
            props.error,
            props.className
          )}
        >
          <input
            style={props.style}
            className="input"
            placeholder={props.placeholder}
            name={props.name}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            ref={ref}
            onChange={onChange}
            type={props.type ?? 'text'}
            value={props.value}
            required={props.required ?? false}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            step={props.step}
            min={props.min}
            max={props.max}
            onKeyPress={props.onKeyPress}
          />
          <span className="icon">{props.icon}</span>
        </label>
        {props.error && <ErrorText>{props.error}</ErrorText>}
      </div>
    );
  }
);

export default TextField;
