/**
 * orderByPredicate and orderByString didn't really sort the columns correctly, so this is a custom sort function.
 * It currently handles number and string properties on objects:
 */
export const makeSortedData = (data: unknown[], direction: string, columnKey: string): unknown[] | undefined => {
  if (data === undefined || data === null) return undefined;

  const s = [ ...data ];
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  s.sort((a: any,b: any) => {
    const aVal = a === null || a === undefined ? undefined : a[columnKey];
    const bVal = b === null || b === undefined ? undefined : b[columnKey];

    if (aVal === null || aVal === undefined) {
      return (bVal === null || bVal === undefined ? 0 : -1);   // either a and b are both nothing, or b > a
    }
    else if (bVal === null || bVal === undefined) {
      return 1;        // a can't be null or undefined: a must be > b
    }

    if (typeof(aVal) === 'number' && typeof(bVal) === 'number') {
      return aVal - bVal;
    } 
    const aStr = `${aVal}`.toLowerCase();
    const bStr = `${bVal}`.toLowerCase();
    return aStr.localeCompare(bStr);
  });

  return direction.toLocaleLowerCase() === 'asc' ? s : s.reverse();
}
