import React, { FC } from 'react';
import './button-group.css';
import classNames from 'classnames';

interface IButtonGroupProps {
  className?: string;
}

const ButtonGroup: FC<IButtonGroupProps> = ({ children, className }) => {
  return <div className={classNames('button-group-component', className)}>
    { children }
  </div>;
};

export default ButtonGroup;
