import Card from '$components/cards/card/card.react';
import { reactPortal } from '$lib/hooks/reactPortal';
import React, { PropsWithChildren, FC } from 'react';
import './modal-card.css';
import classNames from 'classnames';

interface IModalCardProps {
  size?: 'flex' | 'fill';
  overflow?: boolean;
  placementOnMobile?: 'bottom' | 'fullscreen';
  contentDirection?: 'row';
  cardClass?: string;
}

const ModalCard: FC<PropsWithChildren<IModalCardProps>> = ({
  children,
  size = 'flex',
  overflow = true,
  placementOnMobile,
  cardClass,
  contentDirection
}) => {
  const WrapperClassName = classNames(
    'modal-card-component',
    placementOnMobile && `placement-on-mobile-${placementOnMobile}`
  )
  
  const CardClassName = classNames(
    'modal-card-card',
    size && `modal-card-card-${size}`,
    overflow && 'overflow-auto',
    contentDirection,
    cardClass,
  )

  return (
    <>
    {reactPortal(
      <div className={WrapperClassName}>
        <div className="modal-card-backdrop">
          <Card
            innerClass={contentDirection}
            className={CardClassName}>
            {children}
          </Card>
        </div>
      </div>
    , "modal-portal")}
  </>
  )
};

export default ModalCard;
