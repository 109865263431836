export const ColumnWidth = {
  Common: {
    SiteName: 300,
    Buid: 150,
    AccessTag: 200,
    ProductName: 150,
    CustomerName: 300,
    AlarmType: 200,
    ChannelName: 200,
    AlarmDuration: 150
  },
  Generic: {
    Shorter: 125,
    Short: 150,
    Medium: 200,
    Long: 300,
    DateTime: 175
  }
}
