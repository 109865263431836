import React, { FC, memo } from 'react';
import './warning-text.css';
import classNames from "classnames";

interface IWarningTextProps {
  children?: React.ReactNode;
  className?: string;
  bold?: boolean;
}

const WarningTextComponent: FC<IWarningTextProps> = ({
  children,
  className,
  bold,
}) => (
  <div className={classNames(
    'warning-text-component',
    bold && 'strong',
    className
  )}>
    <span className="warning-text">{children}</span>
  </div>
);

export const WarningText = memo(WarningTextComponent);
