import React, { FC } from 'react';
import './error-text.css';
import classNames from "classnames";

interface IErrorTextProps {
  className?: string
  bold?: boolean
}

const ErrorText: FC<IErrorTextProps> = ({ children, className, bold }) => (
  <div className={classNames('error-text-component', bold && 'strong', className)}>
    <span className="error-text">{children}</span>
  </div>
);

export default ErrorText;
