import Checkbox from '$components/checkbox/checkbox.react';
import Pill from '$components/pill/pill.react';
import TextboxMultiline from '$components/textbox-multiline/textbox-multiline.react';
import { default as React, FC, useState, useEffect, MouseEvent } from 'react';
import { IFilterGroup, ITextFilter } from 'src/interfaces';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '$lib/helpers';
import { useIsTouchDevice } from '$lib/hooks/isTouchDevice';
import { IReactGridFilterComponentProps } from '$components/grid/react-grid/grid.react';

const StringFilter: FC<IReactGridFilterComponentProps> = ({
  activeFilters,
  filterChanged,
  property
}) => {
  const activeFilter = activeFilters?.find(f => {
    return f.field === property;
  })
  const filterGroup = activeFilter ?? {
    exclude: false,
    field: property,
    filters: [],
    type: 'string',
    partialMatch: true,
    showEmpty: false
  };
  const filters = filterGroup.filters as ITextFilter[];
  const [t] = useTranslation();
  const [textQuery, setTextQuery] = useState('');
  const [rowsInTextArea, setRowsInTextArea] = useState(1);

  const isTouchDevice = useIsTouchDevice();

  useEffect(() => {
    const numberOfNewlines = (textQuery && textQuery.match(/\n/g)?.length) || 0;
    setRowsInTextArea(Math.min(4, numberOfNewlines + 1));
  }, [textQuery]);

  const addFilter = (query: string) => {
    if (!query) return;
    const filters = query
      .split('\n')
      .filter(query => !isEmpty(query))
      .map<ITextFilter>(value => ({ value, selected: true }));

    const newfilter: IFilterGroup = {
        ...(activeFilter || { exclude: false, field: property, type: 'string', filters: [], partialMatch: true, showEmpty: false }),
        filters: activeFilter
            ? [...(activeFilter.filters as ITextFilter[]), ...filters]
            : filters }
        
    filterChanged && filterChanged(newfilter);
    setTextQuery('');
  }

  const toogleBlank = (showEmpty : boolean) => {    
    filterGroup.showEmpty = showEmpty;

    const emptyFilter : IFilterGroup = {
      exclude: false, 
      field: property, 
      type: 'string', 
      partialMatch: true, 
      showEmpty: false,
      filters: []
    };

    let newFilter: IFilterGroup = emptyFilter;

    if (showEmpty) {
      newFilter = {
        ...emptyFilter,
        showEmpty: true,
        filters: [{value: '<blank>', selected: true}]
      }
    } 
    
    filterChanged && filterChanged(newFilter);
    setTextQuery('')
  }

  const removeFilter = (filterToRemove: ITextFilter, event?: MouseEvent) => {
    event?.preventDefault();
    if(!activeFilter) return;
    const newfilter: IFilterGroup = {...activeFilter, filters: (activeFilter.filters as ITextFilter[]).filter(f => f !== filterToRemove) };
    filterChanged && filterChanged(newfilter)
}

  return (
    <div>
        <TextboxMultiline
          shiftEnterAsNewline
          restrictResizeTo="no-resize"
          autofocus={!isTouchDevice}
          rows={rowsInTextArea}
          value={textQuery}
          onEnter={addFilter}
          placeholder={t('ui_sitelist_filter_string_entertext')}
          onChange={setTextQuery}
          disabled={filterGroup.showEmpty}
        ></TextboxMultiline>
      <div className="pad_t" style={{fontSize: "0.9rem", color: "#555", whiteSpace: "pre-wrap"}}>
        <span className="strong">{t("ui_sitelist_filter_string_hint")}:</span>
         {t("ui_sitelist_filter_string_hinttextstart")} 
         <span className="strong"> {t("ui_sitelist_filter_string_hintshiftenter")}</span>. 
         {t("ui_sitelist_filter_string_hintpress")} 
         <span className="strong"> {t("ui_sitelist_filter_string_hintenter")} </span>
          {t("ui_sitelist_filter_string_hinttextend")}</div>
        {property === 'postCode' && (
          <div className="pad_t" style={{ fontSize: '0.9rem', color: '#555', whiteSpace: 'pre-wrap' }}>
            {t('ui_sitelist_column_postcode_filterdescription')}
          </div>
        )}
      <div className="pad_t flex gap_m">
        <Checkbox
          text="Exact matches"
          checked={!filterGroup.partialMatch}
          onChange={() =>
            filterChanged({
              ...filterGroup,
              partialMatch: !filterGroup.partialMatch
            })
          }
        ></Checkbox>

        <Checkbox
          text="Show blank"
          checked={filterGroup.showEmpty}
          onChange={() => {                                          
              toogleBlank(!filterGroup.showEmpty ?? false);
            }
          }
        ></Checkbox>
      </div>
      <div className="flex wrap pad_t">
        {filters.map((a, i) => (
          <Pill key={`${a.value}${i}`} className="mar_rs mar_bs" iconClicked={(e) => removeFilter(a, e)}>
            {a.value}
          </Pill>
        ))}
      </div>
    </div>
  );
};

export default StringFilter;
