import { useState } from 'react';
import { isTouchDeviceWatcher } from '..';

export function useIsTouchDevice(): boolean {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  isTouchDeviceWatcher(touch => {
    if(touch !== isTouchDevice) {
    return setIsTouchDevice(touch);
    }
  });

  return isTouchDevice;
}
