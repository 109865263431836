import { default as React, FC } from "react";
import { ControllerBackupViewerUrlParams } from "./controllerbackupviewer-utils";
import { useQuery } from '$lib/hooks/fetch-utillities';
import {
  ControllerBackupViewerDocument, ControllerBackupViewerQuery,
} from '$typings/graphql-codegen';
import './controllerbackupviewer.css'
import { pureFormatDate } from "$lib/dateHelpers";
import { default as IDA10BackupViewer } from "./ida10backupviewer.react"
import { default as IDA100BackupViewer } from "./ida100backupviewer.react"
import { default as IDAXBackupViewer } from "./idaxbackupviewer.react"
import { Button } from '@mui/material';
import LoadingBars from '$components/loading-bars/loading-bars.react'

export interface IControllerBackupViewerProps {
  params: ControllerBackupViewerUrlParams;
  hideBackButton?: boolean;
}

const ControllerBackupViewer: FC<IControllerBackupViewerProps> = ({ params, hideBackButton }) => {
  const { data, loading } = useQuery(ControllerBackupViewerDocument, {
    equipmentAttachmentId: parseInt(params.id)
  });

  function selectBackupViewer(controllerBackupData: ControllerBackupViewerQuery | undefined) {
    if (controllerBackupData?.controllerBackupViewer?.ida10Data)
      return controllerBackupData?.controllerBackupViewer?.ida10Data && <IDA10BackupViewer ida10Data={controllerBackupData.controllerBackupViewer.ida10Data} />

    if (controllerBackupData?.controllerBackupViewer?.ida100Data)
      return controllerBackupData?.controllerBackupViewer?.ida100Data && <IDA100BackupViewer ida100Data={controllerBackupData.controllerBackupViewer.ida100Data} />

    if (controllerBackupData?.controllerBackupViewer?.idaxData)
      return <IDAXBackupViewer idaxData={ controllerBackupData.controllerBackupViewer.idaxData } />

    return <span>No configuration backup preview available for controller type {controllerBackupData?.controllerBackupViewer?.type}</span>
  }

  return (
    <div className="controllerbackupviewerpage-react" style={{ marginLeft: "10px", marginRight: "10px" }}>
      {loading ? (
        <LoadingBars center/>
      )
        : (
          <>
            {data?.controllerBackupViewer?.controllerSerial && (
              <>
                {!hideBackButton && <Button style={{ marginTop: "10px" }} variant={'outlined'} onClick={() => window.history.back()}>
                  Back to previous screen
                </Button>}

                {data?.controllerBackupViewer.type 
                && <h2>Backup of an {data?.controllerBackupViewer.type} controller</h2>}

                {(data?.controllerBackupViewer?.ida10Data || data?.controllerBackupViewer?.ida100Data || data?.controllerBackupViewer?.idaxData)
                 && data?.controllerBackupViewer.backupTimestamp 
                 && <span>
                  Serial : {data?.controllerBackupViewer.controllerSerial}<br />
                  Backup time : {pureFormatDate(data?.controllerBackupViewer.backupTimestamp, true, '.')}
                </span>}

                {selectBackupViewer(data)}
              </>
            )}
          </>
        )}
    </div>
  );
};

export default ControllerBackupViewer;
