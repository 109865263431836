import React, { useEffect, useState, FC, memo } from 'react';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { LatestControllerImportsDocument } from '$typings/graphql-codegen';
import { IHistoryItem } from '$interfaces/application';
import { isSomething } from '$lib/helpers';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import { HistoryList } from '$components/history-list/history-list/history-list.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import { Button } from '@mui/material';
import { IControllerLogViewProps } from '../controller-log-view-props';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

const ImportViewComponent: FC<IControllerLogViewProps> = ({ controllerId }) => {
  const [t] = useCaseInsensitiveTranslation();
  const [amount] = useState(10);
  const [historyItems, setHistoryItems] = useState<IHistoryItem[]>([]);

  const { data, loading, error, revalidate } = useQuery(
    LatestControllerImportsDocument,
    {
      controllerId: controllerId,
      amount: amount,
    }
  );

  useEffect(() => {
    if (!data) return;

    const converted = data.controller?.importRefs.map(
      (item): IHistoryItem => ({
        timeStamp: isSomething(item.timestamp) ? item.timestamp.toString() : '',
        text: [],
        externalLinks: [
          {
            url: isSomething(item.sasReadUrl)
              ? (item.sasReadUrl as string)
              : '',
            text: isSomething(item.fileName)
              ? (item.fileName as string)
              : 'file',
          },
        ],
      })
    );
    if (converted) setHistoryItems(converted);
  }, [data]);

  if (error !== undefined)
    return (
      <>
        <ErrorText>Failed to fetch</ErrorText>
        <Button
          sx={{ marginTop: '1rem' }}
          fullWidth
          variant="text"
          onClick={revalidate}
        >
          {t('ui_common_try_again')}
        </Button>
      </>
    );

  return (
    <>
      {historyItems.length === 0 ? (
        loading ? (
          <LoadingBars center />
        ) : (
          <>{t('UI_Controller_ImportRefs_None')}</>
        )
      ) : (
        <HistoryList items={historyItems}/>
      )}
    </>
  );
};

export const ImportView = memo(ImportViewComponent);
