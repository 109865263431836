import { I18N } from 'aurelia-i18n';
import { TFunction } from 'i18next';
import { ReportFormatEnum, ReportPeriodEnum, Reports } from '$typings/graphql-codegen';

export type ScheduledReportFiltersConfigDateSelection = 'range' | 'endof';

export const getScheduleReportDateSelectionTypeForReportType = (
  report: Reports
): ScheduledReportFiltersConfigDateSelection => {
  switch (report) {
    case Reports.ProductStock:
      return 'endof';
  }
  return 'range';
};

export const getWeekDayFromNumber = (weekday: number) => {
  switch (weekday) {
    case 0:
      return 'UI_Days_Monday';
    case 1:
      return 'UI_Days_Tuesday';
    case 2:
      return 'UI_Days_Wednesday';
    case 3:
      return 'UI_Days_Thursday';
    case 4:
      return 'UI_Days_Friday';
    case 5:
      return 'UI_Days_Saturday';
    case 6:
      return 'UI_Days_Sunday';
  }
  throw new Error('Unknown weekday ' + weekday);
};

export const mapReportEnumToTitle = (i18n: I18N) => (report: Reports) => {
  switch (report) {    
    case Reports.DeviceType:
      return i18n.tr('UI_Reports_DeviceType');
    case Reports.Dosing:
      return i18n.tr('Dosing');
    case Reports.GlobalStatistics:
      return i18n.tr('Global statistics');
    case Reports.H2SLevel:
      return i18n.tr('H2SLevel');
    case Reports.MonthlyOffline:
      return i18n.tr('Monthly Offline');
    case Reports.ProductStock:
      return i18n.tr('UI_Reports_ProductStock');
    case Reports.NitratesReport:
      return i18n.tr('UI_Reports_NitratesReport');
    case Reports.StockVsDose:
      return i18n.tr('UI_Reports_SitesStatus');
    case Reports.Alarm:
      return i18n.tr('UI_Reports_Alarm');
    case Reports.UserStatistics:
      return i18n.tr('UI_Reports_UserStatistics');
    case Reports.NewSites:
      return i18n.tr('UI_Reports_NewSites');
    case Reports.Temperature:
      return i18n.tr('UI_Reports_Temperature');
    case Reports.IdaDeliveries:
      return i18n.tr('ui_reports_ida_deliveries');
    case Reports.IdaLifecycle:
        return i18n.tr('ui_reports_ida_lifecycle');
    case Reports.DryRun:
      return i18n.tr('ui_reports_dryruns');
    case Reports.DailyDose:
      return i18n.tr('ui_reports_DailyDose');
    default:
      return "Unknown";
  }
};

export const mapReportEnumToTranslatedTitle = (t: TFunction<"translation", undefined>) => (report: Reports) => {
  switch (report) {
    case Reports.DeviceType:
      return t('UI_Reports_DeviceType');
    case Reports.Dosing:
      return t('Dosing');
    case Reports.GlobalStatistics:
      return t('Global statistics');
    case Reports.H2SLevel:
      return t('H2SLevel');
    case Reports.MonthlyOffline:
      return t('Monthly Offline');
    case Reports.ProductStock:
      return t('UI_Reports_ProductStock');
    case Reports.StockVsDose:
      return t('UI_Reports_SitesStatus');
    case Reports.Alarm:
      return t('UI_Reports_Alarm');
    case Reports.UserStatistics:
      return t('UI_Reports_UserStatistics');
    case Reports.NewSites:
      return t('UI_Reports_NewSites');
    case Reports.Temperature:
      return t('UI_Reports_Temperature');
    case Reports.IdaDeliveries:
      return t('ui_reports_ida_deliveries');
    case Reports.IdaLifecycle:
        return t('ui_reports_ida_lifecycle');
    case Reports.DryRun:
      return t('ui_reports_dryruns');
    case Reports.DailyDose:
      return t('ui_reports_DailyDose');      
    default:
      return "Unknown";
  }
};

const mapReportRangePeriodToTitle = (reportPeriod: ReportPeriodEnum) => {
  switch (reportPeriod) {
    case ReportPeriodEnum.Previous6Months:
      return 'Previous 6 months';
    case ReportPeriodEnum.PreviousDay:
      return 'Previous day';
    case ReportPeriodEnum.PreviousMonth:
      return 'Previous month';
    case ReportPeriodEnum.PreviousWeek:
      return 'Previous week';
    case ReportPeriodEnum.PreviousQuarter:
      return 'Previous quarter';
    case ReportPeriodEnum.PreviousYear:
        return 'Previous year';
    default: return ''
  }
};

const mapReportEndOfDayPeriodToTitle = (reportPeriod: ReportPeriodEnum) => {
  switch (reportPeriod) {
    case ReportPeriodEnum.Previous6Months:
    case ReportPeriodEnum.PreviousQuarter:
    case ReportPeriodEnum.PreviousYear:
        throw Error('Not applicable');
    case ReportPeriodEnum.PreviousDay:
      return 'End of previous day';
    case ReportPeriodEnum.PreviousMonth:
      return 'End of previous month';
    case ReportPeriodEnum.PreviousWeek:
      return 'End of previous week';
    default: return ''
  }
};

export const mapReportPeriodToTitle = (
  reportPeriod: ReportPeriodEnum,
  report: Reports
) => {
  const rangeType = getScheduleReportDateSelectionTypeForReportType(report);
  switch (rangeType) {
    case 'endof':
      return mapReportEndOfDayPeriodToTitle(reportPeriod);
    case 'range':
      return mapReportRangePeriodToTitle(reportPeriod);
  }
};

export const mapReportFormatEnumToTitle = (
  format: ReportFormatEnum 
) => {
  switch (format) {
    case ReportFormatEnum.Csv:
      return "CSV";
    case ReportFormatEnum.Pdf:
      return "PDF";
    case ReportFormatEnum.Excel:
    default:
      return "Excel";
  }
};
