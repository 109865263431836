import React, { FC } from 'react';
import './action-content.css';
import classNames from "classnames";

interface IActionContentProps {
  alignTop?: boolean;
  alignRight?: boolean;
}

const ActionContent: FC<IActionContentProps> = ({
  alignTop,
  alignRight,
  children
}) => (
  <div className={classNames(
    'action-content-component',
    'action-content',
    alignTop ? 'align-top' : 'align-bottom',
    alignRight ? 'align-right' : 'align-left')}>
    {children}
  </div>
);

export default ActionContent;
