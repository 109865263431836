import { default as React, FC } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  ControllerBackupViewerIda10Data,
} from '$typings/graphql-codegen';
import ChartistGraph from 'react-chartist';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Icon from '$components/icons/icon/icon.react';
import './controllerbackupviewer.css'

export interface IIDA10BackupViewerProps {
  ida10Data: ControllerBackupViewerIda10Data;
}

const IDA10BackupViewer: FC<IIDA10BackupViewerProps> = ({ ida10Data }) => {
  const dcorrProfileChartData = {
    series: [ ida10Data.dcorrProfile]
  };

  return (
    <>
      <div className="ida10backupviewerpage-react">
        <Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Config</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800 }}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff', width:'200px'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ida10Data.configRows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row" sx={{width:'200px'}}>{row.name}</TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Parameters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Title</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Value</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Units</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Max</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Min</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ida10Data.parameterRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.value}</TableCell>
                      <TableCell>{row.units}</TableCell>
                      <TableCell>{row.max}</TableCell>
                      <TableCell>{row.min}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ unmountOnExit: true }} sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Calibrations</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Title</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Min</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Max</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Units</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Offset</TableCell>                       
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>PPU</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>PPM</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Smooth</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>MrMin</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>MrMax</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Desc</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Source</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ida10Data.calibrationRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.min}</TableCell>
                      <TableCell>{row.max}</TableCell>
                      <TableCell>{row.units}</TableCell>
                      <TableCell>{row.offset}</TableCell>                       
                      <TableCell>{row.ppu}</TableCell>
                      <TableCell>{row.ppm}</TableCell>
                      <TableCell>{row.smooth}</TableCell>
                      <TableCell>{row.mrMin}</TableCell>
                      <TableCell>{row.mrMax}</TableCell>
                      <TableCell>{row.desc}</TableCell>
                      <TableCell>{row.source}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Calibration profiles</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {ida10Data.calibrationProfiles.map((row) => {
              const chartData = {
                series: [
                  row.values
                ]
              };
              return (
              <div key={row.name}>
                <h4>{row.name}</h4>
                <ChartistGraph data={chartData} type='Line' />
              </div>
            )})}  
          </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>DCorr</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChartistGraph data={dcorrProfileChartData} type='Line' />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{marginTop:"10px"}}>
          <AccordionSummary expandIcon={<Icon name="fa-chevron-down"/>}>
            <Typography>Trends</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{fontWeight:800, backgroundColor:'lightblue'}}>
                  <TableRow>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Id</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Title</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Units</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Desc</TableCell>
                    <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Source</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ida10Data.trendRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.units}</TableCell>
                      <TableCell>{row.desc}</TableCell>
                      <TableCell>{row.source}</TableCell>
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default IDA10BackupViewer;
