import Button from '$components/buttons/button.react';
import Checkbox from '$components/checkbox/checkbox.react';
import { IReactGridFilterComponentProps, IReactGridRefComponentProps } from '$components/grid/react-grid/grid.react';
import Skeleton from '$components/placeholders/skeleton/skeleton';
import TextField from '$components/textbox/text-field.react';
import { distinct } from '$lib/arrayHelpers';
import { isEmpty } from '$lib/helpers';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { RefFilterValuesDocument } from '$typings/graphql-codegen';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { ITextFilter } from 'src/interfaces';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { RefFilterValuesVariables } from '$typings/graphql';
import { useIsTouchDevice } from '$lib/hooks/isTouchDevice';
import classNames from "classnames";

const RefFilter: FC<IReactGridFilterComponentProps> = ({ activeFilters, filterChanged, freeTextQuery, property, componentProps }) => {
  const [, n] = useCaseInsensitiveTranslation();
  const isTouchDevice = useIsTouchDevice();
  if (!componentProps) {
    throw Error('Missing componentProps on reffilterdefinition');
  }
  const refFilterProps = componentProps as IReactGridRefComponentProps;
  const variables : RefFilterValuesVariables= {
    property: property,
    filters: JSON.stringify(activeFilters?.filter(f => f.field !== property)),
    freeTextQuery: freeTextQuery,
    page: refFilterProps.page,
    language: n.language
  };

  const { data } = useQuery(RefFilterValuesDocument, variables);

  const activeFilter = activeFilters?.find(f => {
    return f.field === property;
  })

  const [searchquery, setSearchquery] = useState<string>('');
  const [showOnlyItemsWithSites, setShowOnlyItemsWithSites] = useState(true);
  const [filteredRefFiltersValues, setFilteredRefFiltersValues] = useState(data?.refFilterValues || []);
  const [numberOfItemsWithNoSites, setNumberOfItemsWithNoSites] = useState(0);
  const [t] = useCaseInsensitiveTranslation();

  useEffect(() => {
    setNumberOfItemsWithNoSites(filteredRefFiltersValues.filter(i => !i.instances).length);
  }, [filteredRefFiltersValues]);

  useEffect(() => {
    if (isEmpty(searchquery)) return setFilteredRefFiltersValues(data?.refFilterValues || []);
    const sanitizedFilterValue = searchquery.toLocaleLowerCase().trim();
    return setFilteredRefFiltersValues(
      data?.refFilterValues.filter(i =>
        i.label
          ?.toLocaleLowerCase()
          ?.trim()
          ?.includes(sanitizedFilterValue)
      ) || []
    );
  }, [searchquery, data]);

  const allItemsSelected = useCallback(() => {
    return filteredRefFiltersValues
      .filter(v => (showOnlyItemsWithSites ? v.instances : true))
      .every(e => isSelected(e.key, (activeFilter?.filters as ITextFilter[]) || []));
  }, [activeFilter, filteredRefFiltersValues, showOnlyItemsWithSites]);

  function toggleAll(selectAll: boolean) {
    const existingFilters = (activeFilter?.filters || []) as ITextFilter[];
    const items = filteredRefFiltersValues?.filter(v => (showOnlyItemsWithSites ? v.instances : true)) || [];

    const newFilters: ITextFilter[] = selectAll
      ? distinct([...existingFilters, ...items.map(i => ({ value: i.key }))])
      : existingFilters.filter(filter => !items.some(i => i.key === filter.value));

    const newfilter = { ...(activeFilter || { exclude: false, field: property, type: 'ref' }), filters: newFilters };
    filterChanged && filterChanged(newfilter);
  }

  function refChecked(checked: boolean, reference: string, existingFilters: ITextFilter[]) {
    const filters: ITextFilter[] = checked
      ? [...existingFilters, { value: reference }]
      : existingFilters.filter(filter => filter.value !== reference);

    const newfilter = { ...(activeFilter || { exclude: false, field: property, type: 'ref' }), filters };
    filterChanged && filterChanged(newfilter);
  }

  function isSelected(key: string, filters: ITextFilter[]) {
    return filters && !!filters.find(filter => filter.value.toString() === key);
  }

  if (!data) {
    return <Skeleton rowCount={9} />;
  }
  const Header = () => {
    const isAllSelected = allItemsSelected();
    return (
      <div className="mar_bm bg-white">
      <Checkbox
        text={`${isAllSelected ? t('ui_common_deselectall') : t('ui_common_selectall')}`}
        className="pad_rs oh"
        checked={isAllSelected}
        onChange={() => toggleAll(!isAllSelected)}
        />
    </div>
  );
} 
  
  return (
    <div className={classNames(
      'fill-width',
      refFilterProps.dynamicHeight && 'flex column flex_1'
    )}>
      <TextField
        autoFocus={!isTouchDevice} 
        className="mar_bm" 
        onChange={e => setSearchquery(e.target.value)}
      />
      <TableVirtuoso
        style={refFilterProps.dynamicHeight ? {flex: "1 0 250px"} : { height: 250 }}
        data={filteredRefFiltersValues?.filter(v => (showOnlyItemsWithSites ? v.instances : true))}
        fixedHeaderContent={() => <Header/>}
        itemContent={(_index, item) => (
          <div className="mar_bm" style={item.instances === 0 ? { opacity: 0.5 } : undefined}>
            <Checkbox
              text={`${!!item.translateLabel ? t(item.label) : (!!item.label ? item.label : '<blank>')} ${item.instances > 0 ? '(' + item.instances + ')' : ''}`}
              checked={isSelected(item.key, (activeFilter?.filters as ITextFilter[]) || [])}
              onChange={e => refChecked(!!e.target.value, item.key, (activeFilter?.filters as ITextFilter[]) || [])}
            />
          </div>
        )}
      />
      {numberOfItemsWithNoSites !== 0 && (
        <Button
          variant="tertiary"
          onClick={() => {
            setShowOnlyItemsWithSites(b => !b);
          }}
        >
          {t(!showOnlyItemsWithSites ? 'ui_sitelist_filter_ref_hideitemsnosites' : 'ui_sitelist_filter_ref_showitemsnosites', {
            count: numberOfItemsWithNoSites
          })}
        </Button>
      )}
    </div>
  );
};

export default RefFilter;
