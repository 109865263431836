import React, { FC, memo } from 'react';
import MuiTextField, {
  ExtendedTextFieldProps,
} from '$components/textbox/mui-textfield.react';
import {
  decimalRegex,
  getNewStringIfMatched,
  integerRegex,
} from '$lib/inputHelpers';

interface NumberInputSpecificProps {
  setValue?: (value: string) => unknown;
  value?: string | number;
  numberType: 'decimal' | 'integer';
  min?: number;
  max?: number;
}

type INumberInputProps = Omit<ExtendedTextFieldProps, 'value'> &
  NumberInputSpecificProps;

const NumberInputComponent: FC<INumberInputProps> = ({
  onChange,
  value,
  setValue,
  numberType,
  min,
  max,
  ...props
}) => {
  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    let newValue = getNewStringIfMatched(
      (value ?? '') as string,
      e.target.value,
      numberType === 'decimal' ? decimalRegex : integerRegex
    );

    const newValueAsNumber = parseFloat(newValue);
    // must check if min/max is undefined because 0 is falsy
    const disallowIfMinValue = min !== undefined && newValueAsNumber < min;
    const disallowIfMaxValue = max !== undefined && newValueAsNumber > max;
    const disallowNegative = min !== undefined && min >= 0 && newValue === '-';

    /* reset input to previous value if outside allowed range */
    if (disallowIfMinValue || disallowIfMaxValue || disallowNegative) {
      newValue = `${value ?? ''}`;
    }

    e.target.value = newValue;
    setValue?.(newValue);
    onChange?.(e);
  };

  return <MuiTextField {...props} value={value} onChange={changeHandler} />;
};

export const NumberInput = memo(NumberInputComponent);
