import { channelPropertiesIsInErrorState, hasNoConsumption, lastSampleIsOutsideThreshold, lsdIsOffline } from '$lib/';
import { isNone, isSomething } from '$lib/helpers';
import React, {FC, useMemo} from 'react';
import './site-channel-bar.css';
import classNames from "classnames";

export interface ISiteChannelBarProps extends React.HTMLAttributes<HTMLElement> {
  lastSample?: number | null;
  maximum?: number | null;
  minimum?: number | null;
  percentage?: number | null;
  capacity?: number | null;
  lastSampleTime?: string | null;
  reachMinimum?: string | null;
  direction?: string;
}

const SiteChannelBar: FC<ISiteChannelBarProps> = (props) => {
  const faulty = useMemo(
    () =>
      isNone(props.percentage) ||
      (isSomething(props.percentage) && props.percentage < 0) ||
      (isSomething(props.percentage) && props.percentage > 100) ||
      (
        isSomething(props.minimum)
        && isSomething(props.maximum)
        && isSomething(props.lastSample)
        && isSomething(props.capacity)
        && channelPropertiesIsInErrorState(
          props.minimum,
          props.maximum,
          props.lastSample,
          props.capacity
        )
      ),
    [
      props.percentage,
      props.minimum,
      props.maximum,
      props.lastSample,
      props.capacity
    ]
  );

  const wrapperClassName = useMemo(() => {
    const outsideThreshold = (
      isSomething(props.minimum)
      && isSomething(props.lastSample)
      && lastSampleIsOutsideThreshold(props.minimum, props.lastSample)
    );
    const isOffline = lsdIsOffline(props.lastSampleTime);
    const noConsumption = hasNoConsumption(props.reachMinimum);

    if (outsideThreshold) return isOffline || noConsumption ? 'noconsumption-lowlevel' : 'lowlevel';

    return isOffline || noConsumption ? 'noconsumption' : 'normal';
  }, [
    props.minimum,
    props.lastSample,
    props.lastSampleTime,
    props.reachMinimum
  ]);

  const fillClassName = useMemo(() => {
    if (faulty) return 'faulty';
    const noConsumption = hasNoConsumption(props.reachMinimum);
    const isOffline = lsdIsOffline(props.lastSampleTime);
    if (noConsumption || isOffline) return 'noconsumption';

    return 'freshdata';
  }, [faulty, props.reachMinimum, props.lastSampleTime]);

  const fillSize = useMemo(() => (
    (faulty || isNone(props.percentage))
      ? 100
      : props.percentage && Math.max(Math.min(props.percentage, 100), 0)), 
    [faulty, props.percentage]
  );

  const fillText = useMemo(
    () => (!isNone(props.percentage)
      ? `${props.percentage} %`
      : ''
    ),
    [props.percentage]
  )
  
  return (
    <div
      style={props.style}
      className={classNames('site-channel-bar-component', props.className)}>
      <div className={classNames(
        props.direction === 'vertical' && props.direction,
        'site-channel-bar-barwrapper',
        wrapperClassName
      )}>
        <div
          className={classNames('site-channel-bar-barfill', fillClassName)}
          style={{ flexBasis: `${fillSize}%` }}>
          <div className="site-channel-bar-text">{fillText}</div>
        </div>
      </div>
    </div>
  );
};

export default SiteChannelBar;
