import React, { FC, memo } from 'react';
import MuiTextField, {
  ExtendedTextFieldProps,
} from '$components/textbox/mui-textfield.react';
import { getNewStringIfMatched } from '$lib/inputHelpers';

interface IpAddressInputSpecificProps {
  setValue?: (value: string) => unknown;
  value?: string;
}

const completeIpAddressRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const partialIpAddressRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){0,3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)?$/;

export const getIpAddressFormValidation = (t: (key: string) => string) => ({
  pattern: {
    message: t('UI_Common_ValidationErrors_IPAddress'),
    value: completeIpAddressRegex,
  },
});

type IIpAddressInputProps = ExtendedTextFieldProps & IpAddressInputSpecificProps;

const IpAddressInputComponent: FC<IIpAddressInputProps> = ({
  onChange,
  value,
  setValue,
  ...props
}) => {
  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    let newValue = getNewStringIfMatched(
      (value ?? '') as string,
      e.target.value,
      partialIpAddressRegex
    );

    if (newValue.length > 20) {
      newValue = value ?? '';
    }

    e.target.value = newValue;
    setValue?.(newValue);
    onChange?.(e);
  };

  return <MuiTextField {...props} value={value} onChange={changeHandler} />;
};

export const IpAddressInput = memo(IpAddressInputComponent);
