import React, { FC, memo } from 'react';
import Icon from '$components/icons/icon/icon.react';
import { SignalEnum } from '$typings/graphql';
import './signal-icon.css'

function getSignalIcon(signal: SignalEnum) {
  switch (signal) {
    case SignalEnum.Online:
      return 'signal-good';
    case SignalEnum.Offline:
      return 'signal-bad';
    case SignalEnum.Stale:
      return 'signal-poor';
  }
}

interface ISignalIconProps {
  signal: SignalEnum;
}

const SignalIconComponent: FC<ISignalIconProps> = ({
  signal,
}: ISignalIconProps) => {
  return <Icon name={getSignalIcon(signal)} className="signal-icon" />;
};

export const SignalIcon = memo(SignalIconComponent);
