import React, { FC, memo } from 'react';
import Icon from '$components/icons/icon/icon.react';
import './mobile-view.css';
import { MobileTabItem } from '$components/mobile-tabs/mobile-tab-item/mobile-tab-item';

interface ICardPageMobileTabItemProps {
  active?: boolean;
  onClick?: () => unknown;
  iconName: string;
  name: string;
}

const CardPageMobileTabItemComponent: FC<
  ICardPageMobileTabItemProps
> = ({ active, onClick, iconName, name }) => (
  <MobileTabItem
    className="no-border-tab-bar small-padding"
    active={active}
    onClick={onClick}
  >
    <div>
      <div className="tab-icon">
        <Icon name={iconName} />
      </div>
      <div className="tab-title">{name}</div>
    </div>
  </MobileTabItem>
);

export const CardPageMobileTabItem = memo(
  CardPageMobileTabItemComponent
);
