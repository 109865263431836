import React, { FC } from 'react';
import './card-tab-bar-item.css';
import classNames from 'classnames';

interface ICardTabBarItemProps {
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

const CardTabBarItem: FC<ICardTabBarItemProps> = ({
  active = false,
  disabled = false,
  children,
  onClick,
  className,
}) => (
  <div
    className={classNames(
      'card-tab-bar-item-component',
      'radio-button-tabs-item',
      active && 'radio-button-tabs-item--active',
      disabled ? 'disabled' : 'clickable',
      className
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

export default CardTabBarItem;
