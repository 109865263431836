import React, { useRef, useState, memo, FC } from 'react';
import { Button, IconButton } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import { CardWithHeader } from '$components/cards/mui-card';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import {
  ControllerReplaceSerialListDocument,
  ControllerReplaceSerialListQuery,
} from '$typings/graphql-codegen';
import { ListRange, Virtuoso } from 'react-virtuoso';
import LoadingBars from '$components/loading-bars/loading-bars.react';

import './controller-replace.css';
import { SelectedSerialType } from './modules/selected-serial-type';
import { ConfirmControllerReplaceModal } from './modules/confirm-controller-replace-modal';
import {
  ExistingControllerSerialListRow
} from './modules/controller-serial-list-row';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { NotificationService } from '$services/notificationService';
import MuiTextField from '$components/textbox/mui-textfield.react';

interface IControllerReplaceProps {
  notificationService: NotificationService;
  controllerId: number;
  currentSerial: SelectedSerialType;
  closeControllerReplace: () => unknown;
}

const ControllerReplaceComponent: FC<IControllerReplaceProps> = ({
  closeControllerReplace,
  controllerId,
  currentSerial,
  notificationService
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [selectedSerial, setSelectedSerial] = useState<SelectedSerialType>();

  const lastViewedItems = useRef<ListRange>();

  const unassignedControllersFilter = {
    exclude: true,
    field: 'assigned',
    type: 'boolean',
    filters: [],
  };

  const [variables, setVariables] = useState({
    serialQuery: '',
    filters: JSON.stringify([unassignedControllersFilter]),
    offset: 0,
    first: 100,
  });

  const { data, isRevalidating, visibleItemsChanged } = useGridData(
    ControllerReplaceSerialListDocument,
    variables,
    ({ elasticSearchPages }) =>
      elasticSearchPages.controllerManager.data.totalCount,
    ({ elasticSearchPages }) => elasticSearchPages.controllerManager.data.edges
  );

  const serialQueryChanged = (serialQuery: string) => {
    if (!variables) return;
    setVariables({
      ...variables,
      serialQuery,
    });
  };

  const handleSetSelectedSerial = (
    isNewSerial: boolean,
    serial: string | undefined | null
  ) => {
    if (serial === undefined || serial === null) return;
    const row = data?.find(d => d?.serial === serial)
    setSelectedSerial({
      serial: serial,
      isNewSerial: isNewSerial,
      controllerName: row?.alias || "",
      controllerType: row?.controllerType || "",
      simIcc: row?.simDetails?.icc || "",
      simIp: row?.simDetails?.ipAddress || "",
    });
  };

  const headerText = t(
    'UI_ControllerManager_ControllerDetails_EditSerial_Header'
  );

  return (
    <>
      <ConfirmControllerReplaceModal
        selectedSerial={selectedSerial}
        currentSerial={currentSerial}
        controllerId={controllerId}
        handleClose={() => setSelectedSerial(undefined)}
        onSuccess={(copiedConfig) => { 
          closeControllerReplace()

          notificationService.notify({
            type: 'CUSTOM',
            level: 'info',
            text: `${t('UI_replaceBox_success')} ${copiedConfig ? t('UI_replaceBox_success_and_copiedconfig') : ''}` ,
            timestamp: new Date().toISOString(),
            acknowledged: false,
          });
        }
        } 
      />
      <CardWithHeader
        headerText={headerText}
        headerActions={
          <IconButton size="small" onClick={closeControllerReplace}>
            <Icon name={'fa-times'} />
          </IconButton>
        }
        footerContent={
          <Button
            onClick={closeControllerReplace}
            variant="outlined"
            type="button"
          >
            {t('ui_common_cancel')}
          </Button>
        }
      >
        <div className="controller-replace">
          <div className="mar_lm mar_rm">
            <MuiTextField
              size="small"
              removeWhitespace={true}
              onChange={(e) => serialQueryChanged(e.target.value)}
              placeholder={t(
                'UI_ControllerManager_ControllerDetails_EditSerial_Input_Placeholder'
              )}
            />
          </div>
          <div className="mar_m">
            {t(
              'UI_ControllerManager_ControllerDetails_EditSerial_Results_Header'
            )}
            :
          </div>
          <div className="separator-line" />
          <Virtuoso
            overscan={50}
            data={data}
            components={{
              EmptyPlaceholder: () => (
                <>{isRevalidating && !data && <LoadingBars />}</>
              ),
            }}
            rangeChanged={(i) => {
              if (i.startIndex > 0) {
                visibleItemsChanged(i)
              }
              lastViewedItems.current = i;
            }}
            itemContent={(
              _index,
              item: ControllerReplaceSerialListQuery['elasticSearchPages']['controllerManager']['data']['edges'][0]
            ) => (
              <ExistingControllerSerialListRow
                serial={item?.serial}
                alias={item?.alias}
                evenRow={_index % 2 === 0}
                onButtonClick={() =>
                  handleSetSelectedSerial(false, item?.serial)
                }
              />
            )}
          />
        </div>
      </CardWithHeader>
    </>
  );
};

export const ControllerReplace = memo(ControllerReplaceComponent);
