import React, { useState, memo, FC, useCallback } from 'react';
import { Button } from '@mui/material';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import Icon from '$components/icons/icon/icon.react';
import { NotificationService } from '$services/notificationService';
import { ControllerService } from '$services/controllerService';
import { toISOString } from '$lib/dateHelpers';

interface IControllerUploadConfigProps {
  controllerId: number;
  notificationService: NotificationService;
  controllerService: ControllerService;
}

const ControllerUploadConfigComponent: FC<IControllerUploadConfigProps> = ({
  controllerId,
  notificationService,
  controllerService
}) => {
  const [t] = useCaseInsensitiveTranslation();  
  const [processing, setProcessing] = useState(false);

  const showNotification = useCallback((level : 'warning' | 'error' | 'info', text : string) => {
    notificationService.notify({
      type: 'CUSTOM',
      level: level,
      text: text,      
      timestamp: toISOString(new Date(), true),
      acknowledged: false,
    });
  }, [notificationService, t]);


  const handleFileUpload = useCallback(async (files : FileList | null) => {
    if (!files) 
      return;

    const file = files[0];

    if (!file.name.endsWith(".bz2")) {
      showNotification('error', t('UI_Controller_Upload_Config_Error_WrongFileType'));
      return;
    }


    try {
      setProcessing(true);            
      
      await file.arrayBuffer().then(async fb =>  {
        await controllerService.uploadControllerConfig(controllerId, fb);          
      });
      
      showNotification('info', t('UI_Controller_Upload_Config_Success'));
    }
    catch (ex) {
      console.log(ex);      
      showNotification('error', t('UI_Controller_Upload_Config_Error') + ' ' + ex?.message);
    }
    
    setProcessing(false);        
  }, [controllerService, t]);
  
  return (
    <>
      <div className="flex jsb aicenter">
      <Button component="label" variant="contained" disabled={processing} startIcon={processing ? <Icon name="fa-spinner fa-pulse" className="text" /> : <Icon name="fa-upload" className="text" />}>
        {t('UI_SiteDetails_UploadConfig')}
        <input type="file" style={{display:'none', visibility: 'hidden'}} accept=".bz2" onChange={(e) => handleFileUpload(e?.target?.files) } />
      </Button>          
      </div>        
    </>
  );
}

export const ControllerUploadConfig = memo(ControllerUploadConfigComponent);
