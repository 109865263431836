import { IControllerCommand } from '$interfaces/entity';
import React, { FC, memo } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import './command-history-clear-menu.css';

interface ICommandHistoryControlProps {
  commandHistory: IControllerCommand[];
  clearCommandHistory: () => void;
}

const CommandHistoryClearMenuComponent: FC<ICommandHistoryControlProps> = ({
  commandHistory,
  clearCommandHistory,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={open ? 'clear-commands-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Icon
          className="command-history-icon-width"
          name={'fa-ellipsis-v'}
          title={'Clear command history'}
        />
      </IconButton>
      <Menu
        id="clear-commands-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            clearCommandHistory();
            handleClose();
          }}
          disabled={commandHistory.length <= 0}
        >
          Clear command history
        </MenuItem>
      </Menu>
    </>
  );
};

export const CommandHistoryClearMenu = memo(CommandHistoryClearMenuComponent);
