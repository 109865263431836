import React, { CSSProperties, FC } from 'react';
import './card-content.css'
import classNames from 'classnames';

interface ICardContentProps {
  className?: string
  style?: CSSProperties
}

const CardContent: FC<ICardContentProps> = ({ children, className, style }) => (
    <div
      className={classNames('card-content-component', className)}
      style={style}>
      { children }
    </div>
  );

export default CardContent;
