import React, { FC } from 'react';
import './card-header.css';
import classNames from 'classnames';

interface ICardHeaderProps {
  action?: JSX.Element;
  className?: string;
}

const CardHeader: FC<ICardHeaderProps> = ({ children, action, className }) => (
  <div className={classNames('card-header-component', className)}>
    <h2 className="needed">{children}</h2>
    { action }
  </div>
);

export default CardHeader;
