import { SecurityLevels } from '../../../config';
import { hasAccessToSecurityLevel } from '$lib/authorizationHelper';
import { getSession } from '../../../config/sessionService';
import { SecurityLevel } from '$interfaces/enums/securityLevel';
import styled from '@emotion/styled/dist/emotion-styled.cjs';

export interface ISecurityLevelItem {
  level: number;
  displayText: string;
  iconName: string;
}

export const securityLevelIconNames = [
  'security-level-none',
  'security-level-low',
  'security-level-medium',
  'security-level-high',
];

export const mappedSecurityLevels: ISecurityLevelItem[] = Object.keys(
  SecurityLevels
)
  .map((key) => parseInt(key))
  .filter((key) => hasAccessToSecurityLevel(getSession(), key))
  .map((n) => ({
    level: n,
    displayText: SecurityLevels[n as SecurityLevel],
    iconName: securityLevelIconNames[n],
  }));


export const SecurityLevelItemWrapperDiv = styled.div`
  display: flex;
  max-width: 100%;
  align-items: center;
  overflow: hidden;
  height: 21px;
`;
