import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import { ReactSitelistToggleAlarmDocument } from '$typings/graphql-codegen';
import React, { FC, useState } from 'react';
import classNames from "classnames";

interface IMutedAlarmsProps {
  muted: boolean;
  siteId: string;
  className?: string;
}

const MutedAlarms: FC<IMutedAlarmsProps> = ({ muted, siteId, className }) => {
  const [mutedState, setMutedState] = useState(muted);

  async function toggleMuteAlarms(siteId: string, newMutedState: boolean) {
    setMutedState(newMutedState);
    await mutate(ReactSitelistToggleAlarmDocument, { muted: newMutedState, siteIds: [ensureNumber(siteId)] }, false, undefined, () => {
      setMutedState(b => !b);
    });
  }

  return <ClickableIcon
    className={classNames(className)}
    name={`${mutedState ? 'muted' : 'volume-up'}`}
    onClick={() => toggleMuteAlarms(siteId, !mutedState)} />;
};

export default MutedAlarms;
