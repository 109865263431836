import React, {FC, HTMLAttributes} from 'react';
import './site-signal.css'
import Icon from '$components/icons/icon/icon.react';
import { SignalEnum } from '$typings/graphql-codegen';
import classNames from "classnames";

interface ISiteSignalProps extends HTMLAttributes<HTMLElement> {
  siteSignal: SignalEnum
  isVirtual: boolean
}

const SiteSignalComponent: FC<ISiteSignalProps> = (props) => {
  const getSiteSignalIcon = () => {
    if (props.isVirtual) return <Icon name="virtual" className='virtualIcon flex justify-center items-center' />;

    switch (props.siteSignal) {
      case SignalEnum.Offline:
        return <Icon name="signal-bad" className="flex justify-center items-center" />
      case SignalEnum.Stale:
        return <Icon name="signal-poor" className="flex justify-center items-center" />
      case SignalEnum.Online:
        return <Icon name="signal-good" className="flex justify-center items-center" />
    }
  }

  return (
    <div
      className={classNames(
        'site-signal-component',
        'relative',
        props.className
      )}
      style={props.style}>
      {getSiteSignalIcon()}
    </div>
  );
};

export default SiteSignalComponent;
