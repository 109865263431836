import { useState } from 'react';
import { isMobileWatcher } from '..';

export function useIsMobile(): boolean {
  const [isMobile, setIsMobile] = useState(false);

  isMobileWatcher(mobile => {
    if(mobile !== isMobile) {
      return setIsMobile(mobile);
    }
  });

  return isMobile;
}
