import React, { FC } from 'react';
import classNames from "classnames";

interface IDownloadIconProps extends React.HTMLAttributes<HTMLElement> {}

const DownloadIcon: FC<IDownloadIconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={classNames('feather', 'feather-download', className)}
  >
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M7 10l5 5 5-5M12 15V3" />
  </svg>
);

export default DownloadIcon;
