import React, { FC, MouseEventHandler } from 'react';
import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';
import './pill.css';
import classNames from "classnames";

interface IPillProps {
  iconClicked?: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  className?: string;
}

const Pill: FC<IPillProps> = ({ iconClicked, disabled = false, children, className }) => (
  <div className={classNames(
    'pillcomponent',
    disabled && 'disabled',
    className
  )}>
    <div className="text">{children}</div>
    {!disabled && iconClicked && (
      <ClickableIcon
        name="fa-times"
        className="icon pad_s"
        onClick={iconClicked}
      ></ClickableIcon>
    )}
  </div>
);

export default Pill;
