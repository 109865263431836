import { useQuery } from '$lib/hooks/fetch-utillities';
import {
  HauliersDropdownQueryDocument,
  HauliersDropdownQueryQuery
} from '$typings/graphql-codegen';
import React, { FC, ReactElement } from 'react';
import Dropdown from '../dropdown/dropdown.react';

type HauliersDropdownItem = HauliersDropdownQueryQuery['hauliers'][0];

interface IHaulierDropdownProps {
  selected?: HauliersDropdownItem | string;
  itemClicked?: (params: {
    value?: HauliersDropdownItem | string;
    text?: string;
  } | undefined) => void;
  topElement?: string | boolean | 'IF_QUERY';
  valuePath?: string;
  displayPath?: string;
  placeholder: string;
  showNoneElement?: boolean;
  replaceTopElement?: (query: string) => ReactElement;
}

const HaulierDropdown: FC<IHaulierDropdownProps> = ({
  selected,
  itemClicked,
  topElement,
  valuePath,
  displayPath,
  placeholder,
  replaceTopElement,
  showNoneElement = false
}) => {
  const { data, loading } = useQuery(HauliersDropdownQueryDocument);

  return (
    <div>
      <Dropdown<HauliersDropdownItem>
        items={data?.hauliers}
        displayPath={displayPath}
        selected={selected}
        loading={loading}
        valuePath={valuePath}
        canSelectBlankValue={showNoneElement}
        itemClicked={params => {          
          return itemClicked &&
            itemClicked(params === undefined ? undefined : { value: params?.value, text: params?.text })
        }
        }
        placeholder={placeholder}
        topElement={topElement}
        replaceTopElement={replaceTopElement}
      />
    </div>
  );
};

export default HaulierDropdown;
