import {RefObject, useState, useCallback, useLayoutEffect} from "react"
import { ResizeObserver } from '@juggle/resize-observer';


export interface ResizeObserverEntry {
    target: Element;
    contentRect: DOMRectReadOnly;
  }
  
  export const useResizeObserver = (
    ref: RefObject<HTMLElement>,
    callback?: (entry: DOMRectReadOnly) => void
  ) => {
    const [width, setWidth] = useState<number | undefined>();
    const [height, setHeight] = useState<number | undefined>();
  
    const handleResize = useCallback(
      (entries: ResizeObserverEntry[]) => {

        if (!Array.isArray(entries)) {
          return;
        }
        const entry = entries[0];
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.height);
  
        if (callback) {
          callback(entry.contentRect);
        }
      },
      [callback, ref.current]
    );
  
    useLayoutEffect(() => {
      if (!ref.current) {
        return;
      }
  
      let RO = new ResizeObserver((entries: ResizeObserverEntry[]) =>
        handleResize(entries)
      );
      RO.observe(ref.current);
  
      return () => {
        RO.disconnect();
      };
    }, [ref.current]);
  
    return [width, height];
  };