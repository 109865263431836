import { SendCommandToControllerDocument } from '$typings/graphql-codegen';
import { mutate } from '$lib/hooks/fetch-utillities';
import React, { useRef, useState, createContext, FC, memo } from 'react';
import { IControllerCommand, IControllerCommandStatus } from '../../interfaces';
import { getNewId } from '$lib/incrementer';
import { useCreateProvidedContext } from '$lib/createProvidedContext';
import { revalidateAllActiveQueries } from '$pages/common/GraphQLFetcher';

type ControllerIdCurried<T> = (controllerId: number) => T;

type SendCommandType = (
  command: string,
  slaveNumber?: number,
  onInit?: () => unknown,
  onSuccess?: () => unknown,
  onError?: () => unknown
) => unknown;

export interface UseControllerCommandServiceReturn {
  sendCommand: SendCommandType;
  activeRequest?: string;
  commandHistory: IControllerCommand[];
  clearCommandHistory: () => unknown;
}

interface ControllerCommandServiceProps {
  controllerCommandMap: Map<number, IControllerCommand[]>;
  activeCommandMap: Map<number, string | undefined>;
  clearCommandHistory: ControllerIdCurried<() => unknown>;
  sendCommand: ControllerIdCurried<SendCommandType>;
}

const ControllerCommandService = createContext<
  ControllerCommandServiceProps | undefined
>(undefined);
ControllerCommandService.displayName = 'ControllerCommandService';

export const useControllerCommandService: ControllerIdCurried<
  UseControllerCommandServiceReturn
> = (controllerId: number) => {
  const {
    controllerCommandMap,
    activeCommandMap,
    clearCommandHistory,
    sendCommand,
  } = useCreateProvidedContext(ControllerCommandService);

  // return the controllerId specific list & string, and apply the controllerId to the send and  clear command.
  return {
    sendCommand: sendCommand(controllerId),
    commandHistory: controllerCommandMap.get(controllerId) ?? [],
    activeRequest: activeCommandMap.get(controllerId),
    clearCommandHistory: clearCommandHistory(controllerId),
  };
};

interface IProvideControllerCommandServiceProps {
  children?: React.ReactNode;
}

export const ProvideControllerCommandService: FC<IProvideControllerCommandServiceProps> = memo(
  ({ children }) => {
    const [controllerCommandMap, setControllerCommandMap] = useState<Map<number, IControllerCommand[]>>(new Map<number, IControllerCommand[]>());
    const [activeCommandMap, setActiveCommandMap] = useState<Map<number, string | undefined>>(new Map<number, string | undefined>());

    // need to use a ref to capture the current value inside promise callbacks
    const commandsRef = useRef<Map<number, IControllerCommand[]>>();
    commandsRef.current = controllerCommandMap;

    const clearCommandHistory = (controllerId: number) => () => {
      navigator.locks.request("controllerCommandMapLock", (_lock) => {
        const map = commandsRef.current ?? controllerCommandMap;
        const mutatedMap = new Map(map);
        mutatedMap.set(controllerId, []);
        setControllerCommandMap(mutatedMap);
      });
    };

    const setActiveCommand = (controllerId: number, command: string) => {
      navigator.locks.request("activeCommandMapLock", (_lock) => {
        const mutatedMap = new Map(activeCommandMap);
        mutatedMap.set(controllerId, command);
        setActiveCommandMap(mutatedMap);
      });
    };

    const clearActiveCommand = (controllerId: number) => {
      navigator.locks.request("activeCommandMapLock", (_lock) => {
        const mutatedMap = new Map(activeCommandMap);
        mutatedMap.delete(controllerId);
        setActiveCommandMap(mutatedMap);
      });
    };

    const addCommand = (
      controllerId: number,
      newCommand: IControllerCommand
    ) => {
      navigator.locks.request("controllerCommandMapLock", (_lock) => {
        const map = commandsRef.current ?? controllerCommandMap;
        let list = map.get(controllerId) ?? [];
        const updatedList = [newCommand, ...list];      
        const mutatedMap = new Map(map);
        mutatedMap.set(controllerId, updatedList);
        setControllerCommandMap(mutatedMap);
      });
    };

    const mutateCommand = (
      controllerId: number,
      commandId: number,
      mutation: Partial<IControllerCommand>
    ) => {
      navigator.locks.request("controllerCommandMapLock", (_lock) => {
        const map = commandsRef.current ?? controllerCommandMap;
        let list = map.get(controllerId) ?? [];

        const i = list.findIndex((c) => c.commandId === commandId);
        if (i == -1) {
          console.error(
            'Tried to mutate a command (with id: ' +
              commandId +
              ') that does not exist!'
          );
          return;
        }

        list[i] = { ...list[i], ...mutation };
        const updatedList = [...list];

        const mutatedMap = new Map(map);
        mutatedMap.set(controllerId, updatedList);
        setControllerCommandMap(mutatedMap);
      });
    };

    const sendCommand: ControllerIdCurried<SendCommandType> = (controllerId) => 
       (command, slaveNumber, onInit, onSuccess, onError) => {
        onInit?.();        
        const cmd: IControllerCommand = {
          controllerId,
          command,
          started: new Date(),
          status: IControllerCommandStatus.Running,
          commandId: getNewId(),
          slaveNumber: command !== 'ping' ? slaveNumber : undefined,
        };
        setActiveCommand(controllerId, command);
        addCommand(controllerId, cmd);

        mutate(SendCommandToControllerDocument, {
          controllerId,
          command,
          slaveNumber,
        }).then((r) => {
            const statusCode = r.sendCommandToController.statusCode;
            if (statusCode && statusCode >= 200 && statusCode < 300) {
              mutateCommand(controllerId, cmd.commandId, {
                status: IControllerCommandStatus.Success,
                result: r.sendCommandToController.result ?? undefined,
                ended: new Date(),
              });
              onSuccess && onSuccess();
              revalidateAllActiveQueries();
            } else {
              mutateCommand(controllerId, cmd.commandId, {
                status: IControllerCommandStatus.Failed,
                result: r.sendCommandToController.result ?? undefined,
                ended: new Date(),
              });
              onError && onError();
            }
          })
          .catch((e) => {
            mutateCommand(controllerId, cmd.commandId, {
              status: IControllerCommandStatus.Failed,
              result: e ?? undefined,
              ended: new Date(),
            });
            onError && onError();
          })
          .finally(() => clearActiveCommand(controllerId));
      };

    return (
      <ControllerCommandService.Provider
        value={{
          controllerCommandMap,
          activeCommandMap,
          clearCommandHistory,
          sendCommand,
        }}
      >
        {children}
      </ControllerCommandService.Provider>
    );
  }
);
