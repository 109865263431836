import { default as React, useMemo, useState, useCallback, useEffect, FC } from 'react'
import { columnKey } from "$components/grid/grid";
import { orderByPredicate } from '$lib/sorting';
import Icon from '$components/icons/icon/icon.react';
import './grid.column-edit.react.css'
import { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import {
  Card as MuiCard, 
  CardContent as MuiCardContent, 
  Collapse, 
  IconButton, 
  List, 
  ListItem, 
  Typography, 
} from "@mui/material";
import MuiLabeledCheckbox from "$components/checkbox/mui-checkbox.react";
import MuiTextField from "$components/textbox/mui-textfield.react";
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

type GridEditPanelProps<TItem> = {
  columns: IReactGridColumn<TItem>[]
  selectedColumns: columnKey[]
  selectedColumnsChanged: React.Dispatch<React.SetStateAction<any[]>>
}

type ColumnIsSelected = {
  isSelected: boolean
}

interface IColumnGroup<T> {
  name: string;
  entireGroupIsSelected: boolean;
  groupHasAnySelected: boolean;
  columns: Array<ColumnIsSelected&IReactGridColumn<T>>;
}

interface IColumnEditGroupProps {
  selectedColumnsChanged: React.Dispatch<React.SetStateAction<any[]>>
  filterQuery: string, 
  group: IColumnGroup<unknown> 
}

const ColumnEditGroup: FC<IColumnEditGroupProps> = ({selectedColumnsChanged, filterQuery, group}) => {

  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => setOpen(!(filterQuery === undefined || filterQuery === "")), [filterQuery]);

  const toggleGroup = useCallback((g: IColumnGroup<unknown>) => {
    const columnKeys = g.columns.filter(f => !f.required).map(c => c.columnKey)
    selectedColumnsChanged(columns => g.entireGroupIsSelected ? columns.filter(c => !columnKeys.includes(c)) : Array.from(new Set([...columns, ...columnKeys])))
  }, []);

  const toggleColumn = useCallback((column: ColumnIsSelected&IReactGridColumn<unknown>) => {
    selectedColumnsChanged(columns => column.isSelected ? columns.filter(c => c !== column.columnKey) : [...columns, column.columnKey])
  }, []);
  
  
  return (
    <List key={group.name} sx={{ minWidth:"100%"}} disablePadding>
      <ListItem
        secondaryAction={
          <IconButton
            edge="start" 
            aria-label="Expand" 
            onClick={() => setOpen(o => !o)}
            size="small"
            sx={{
              padding: 0,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              }
            }}
          >
            <Icon
              name={open ? "fa-chevron-up" : "fa-chevron-down"}
              title={open ? "close" : "open"}
            />
          </IconButton>
        }
      >
        <MuiLabeledCheckbox
          label={group.name}
          checked={group.entireGroupIsSelected}
          indeterminate={group.groupHasAnySelected}
          onChange={() => toggleGroup(group)}
        />
      </ListItem>
      <Collapse in={open} unmountOnExit>
        <List component="div" disablePadding>
          {group.columns.map((column, i) => (
            <ListItem key={column.columnTitle + i} sx={{ pl: 4 }}>
              <MuiLabeledCheckbox
                label={column.columnTitle}
                disabled={column.required}
                checked={column.isSelected}
                onChange={() => toggleColumn(column)}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  )
}

export function GridEditPanel<TItem>({ columns, selectedColumns, selectedColumnsChanged }: React.PropsWithChildren<GridEditPanelProps<TItem>>) : React.ReactElement {
  const [t] = useCaseInsensitiveTranslation();
  const [filterQuery, setFilterQuery] = useState<string>('')

  const orderedAndTranslatedColumns = useMemo<IReactGridColumn<TItem>[]>(() => {
    const translated = columns.map(c => ({
      ...c,
      managementGroup: c.managementGroup ? t(c.managementGroup) : 'Other',
      columnTitle: t(c.columnTitle)
    }))

    return orderByPredicate(translated, column => column.columnTitle, 'asc')
  }, [columns, t])

  const filteredColumns = useMemo(() => {
    const sanitizedQuery = filterQuery.toLocaleLowerCase().trim();
    return orderedAndTranslatedColumns.filter(c => c.columnTitle.toLocaleLowerCase().includes(sanitizedQuery) || c.managementGroup?.toLocaleLowerCase()?.includes(sanitizedQuery))
  }, [orderedAndTranslatedColumns, filterQuery])

  const groupedColumns = useMemo(() => {
    const sortedColumns = filteredColumns
    const groups: IColumnGroup<TItem>[] = [];
    for(const column of sortedColumns) {
      const groupName = column.managementGroup || 'Other';
      let group = groups.find(g => g.name === groupName);
      if(!group) {
        group = { name: groupName, columns: [], entireGroupIsSelected: true, groupHasAnySelected: false };
        groups.push(group);
      }

      const columnIsSelected = selectedColumns.includes(column.columnKey)

      group.entireGroupIsSelected = group.entireGroupIsSelected && columnIsSelected;
      group.groupHasAnySelected = group.groupHasAnySelected || columnIsSelected;
      group.columns.push({ ...column, isSelected: columnIsSelected })
    }

    return orderByPredicate(groups, group => group.name, "asc", true);
  }, [filteredColumns, selectedColumns])

  return (
    <MuiCard className="grid-react edit-panel" sx={{ boxShadow: 'none', borderLeft: "solid #f1f1f1 1px" }}>
      <MuiCardContent>
        <Typography variant="h5" marginBottom="1rem">
          Manage columns
        </Typography>
        <MuiTextField
          icon={<Icon name="fa-search"></Icon>}
          placeholder="Search columns"
          value={filterQuery}
          onChange={e => setFilterQuery(e.target.value)}
        />
      </MuiCardContent>
      <div className="flex_1 overflow-auto">
        {groupedColumns.map((g, i) => (
          <ColumnEditGroup
            key={i}
            selectedColumnsChanged={selectedColumnsChanged}
            filterQuery={filterQuery}
            group={g}
          />
        ))}
      </div>
    </MuiCard>
  );
}
