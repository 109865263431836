import React, { FC, ReactElement } from "react";
import Dropdown from "$components/dropdowns/dropdown/dropdown.react";
import {useQuery} from "$lib/hooks/fetch-utillities";
import {ControllerTypesDocument} from "$typings/graphql-codegen";

interface IControllerTypeDropdownProps {
  selected?: string;
  topElement?: string | boolean | 'IF_QUERY';
  itemClicked?: (controllerType?: string) => void;
  error?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  replaceTopElement?: (query: string) => ReactElement

}

const ControllerTypeDropdown: FC<IControllerTypeDropdownProps> = ({ selected, topElement, itemClicked, error, className, placeholder, disabled, replaceTopElement }) => {
  const { data, loading } = useQuery(ControllerTypesDocument)
  
  return (
    <Dropdown<string>
      className={className}
      items={data?.controllerTypes}
      selected={selected}
      loading={loading}
      itemClicked={params => itemClicked && itemClicked(params?.value as string)}
      topElement={topElement}
      placeholder={placeholder}
      error={error}
      disabled={disabled}
      replaceTopElement={replaceTopElement}
    />
  );
};



export default ControllerTypeDropdown;
