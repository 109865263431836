import Button from '$components/buttons/button.react';
import ButtonGroup from '$components/button-group/button-group.react';
import CardContent from '$components/cards/card-content/card-content.react';
import Checkbox from '$components/checkbox/checkbox.react';
import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import CustomerDropdown from '$components/dropdowns/customer-dropdown/customer-dropdown.react';
import ModalCard from '$components/modals/modal-card/modal-card.react';
import Pill from '$components/pill/pill.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import TextField from '$components/textbox/text-field.react';
import { distinct } from '$lib/arrayHelpers';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import { Contact, ContactType, SitesBySiteIdsDocument, UpdateSitesDocument, Haulier } from '$typings/graphql-codegen';

import React, { FC, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import './multi-edit-form.css';
import ContactsSearch from '$pages/common/contacts/contactssearch/contactssearch.react';
import { getUserFeatures } from '../../../../../../../src/config/sessionService';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import EditContact from './edittable-contact/edit-contact.react';
import { ensureNumber } from '$lib/numberHelpers';
import HaulierDropdown from '$components/dropdowns/haulier-dropdown/haulier-dropdown.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

interface ISitelistMultiEditFormProps {
  selectedSiteIds: string[];
  closeEditor: () => void;
  clearSelectedIds: () => void;
}

interface FormValues {
  buid?: string;
  customer?: string;
  soldTo?: string;
  parked?: boolean;
  systemAlarmsDisabled?: boolean;
  orderContact?: Contact;
  techContact?: Contact;
  salesContact?: Contact;
  customerContact?: Contact;
  muted?: boolean;
  haulier?: Haulier;
}

const getContactType = (input: string) => {
  switch (input) {
    case 'orderContact':
      return ContactType.Order;
    case 'techContact':
      return ContactType.Tech;
    case 'salesContact':
      return ContactType.Sales;
    case 'customerContact':
      return ContactType.Customer;
    default:
      return;
  }
};

const SitelistMultiEditForm: FC<ISitelistMultiEditFormProps> = ({ selectedSiteIds, closeEditor, clearSelectedIds }) => {
  const [t] = useCaseInsensitiveTranslation();
  const {
    control,
    handleSubmit,
    register,
    resetField,
    getValues,
    formState: { isSubmitting }
  } = useForm<FormValues>();
  const { data } = useQuery(SitesBySiteIdsDocument, { siteIds: selectedSiteIds });
  const [pendingChangeState, setPendingChangeState] = useState({
    buid: false,
    customer: false,
    soldTo: false,
    parked: false,
    systemAlarms: false,
    orderContact: false,
    techContact: false,
    salesContact: false,
    customerContact: false,
    haulier: false,
    muted: false
  });
  const [contactsSearchOpen, setContactsSearchOpen] = useState<'orderContact' | 'techContact' | 'salesContact' | 'customerContact' | undefined>(
    undefined
  );
  const features = getUserFeatures();

  const selectedSitesData = useMemo(() => {
    const buids = distinct(data?.sitesByIds.map(d => d.buid?.name) || []);
    const customers = distinct(data?.sitesByIds.map(d => d.customer?.name) || []);
    const soldTos = distinct(data?.sitesByIds.map(d => d.soldTo) || []);
    const orderContacts = distinct(data?.sitesByIds.map(d => d.orderContact) || []);
    const techContacts = distinct(data?.sitesByIds.map(d => d.techContact) || []);
    const salesContacts = distinct(data?.sitesByIds.map(d => d.salesContact) || []);
    const customerContacts = distinct(data?.sitesByIds.map(d => d.customerContact) || []);
    
    let hauliers = new Array<Partial<Haulier>>();
    data?.sitesByIds.forEach(site => {
      site?.channels.forEach(channel => {
        if (channel.tankDetails?.haulier?.haulierId && hauliers.findIndex(h => h.haulierId == channel.tankDetails?.haulier?.haulierId) == -1) {
          hauliers.push(channel.tankDetails?.haulier);
        }
      })
    });

    return { buids, customers, soldTos, orderContacts, techContacts, salesContacts, customerContacts, hauliers };
  }, [data]);

  const onSuccess = () => {
    clearSelectedIds();
    closeEditor();
  };

  const onSubmit: SubmitHandler<FormValues> = async values => {
    await mutate(
      UpdateSitesDocument,
      {
        siteIds: selectedSiteIds.map(sid => ensureNumber(sid)),
        customerId: values.customer ? ensureNumber(values.customer) : undefined,
        editCustomer: pendingChangeState.customer,
        buidId: values.buid ? ensureNumber(values.buid) : undefined,
        editBuid: pendingChangeState.buid,
        editSystemAlarms: pendingChangeState.systemAlarms,
        systemAlarmsDisabled: values.systemAlarmsDisabled,
        soldTo: values.soldTo,
        editSoldTo: pendingChangeState.soldTo,
        parked: values.parked,
        editParked: pendingChangeState.parked,
        orderContactId: values.orderContact && ensureNumber(values.orderContact?.contactId),
        editOrderContact: pendingChangeState.orderContact,
        techContactId: values.techContact && ensureNumber(values.techContact?.contactId),
        editTechContact: pendingChangeState.techContact,
        salesContactId: values.salesContact && ensureNumber(values.salesContact?.contactId),
        editSalesContact: pendingChangeState.salesContact,
        customerContactId: values.customerContact && ensureNumber(values.customerContact?.contactId),
        editCustomerContact: pendingChangeState.customerContact,        
        haulierId: values.haulier && ensureNumber(values.haulier?.haulierId),
        editHaulier: pendingChangeState.haulier,
        alarmMuted: values.muted,
        editAlarmMuted: pendingChangeState.muted
      },
      true,
      onSuccess
    );
  };

  return (
    <ModalCard contentDirection="row">
      <CardContent className="no-padding">
        <div className="information-box flex column">
          <span className="information-header">Edit multiple sites</span>
          <p>Here you can set given properties on multiple sites in one operation.</p>
          <img src="/images/undraw_booking_33fn.svg" alt="Multi edit image" />
          <p>Click change on properties you want to change, set the properties, then click save changes to set them.</p>
        </div>
      </CardContent>

      {!contactsSearchOpen && (
        <CardContent className="multi-edti-fields">
          <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleTable className="multi-edit-table">
              <SimpleTableRow>
                <SimpleTableCell></SimpleTableCell>
                <SimpleTableCell>Value</SimpleTableCell>
                <SimpleTableCell>Change</SimpleTableCell>
              </SimpleTableRow>

              <SimpleTableRow>
                <SimpleTableCell>{t('ui_multiedit_formelement_label_buid')}</SimpleTableCell>
                <SimpleTableCell>
                  {pendingChangeState.buid && (
                    <Controller
                      name="buid"
                      control={control}
                      render={({ field }) => (
                        <BuidDropdown
                          selected={field.value}
                          itemClicked={item => field.onChange(item?.buidId)}
                          topElement={t('ui_multiedit_formelement_unchanged') as string}
                          placeholder={t('ui_multiedit_formelement_unchanged')}
                        ></BuidDropdown>
                      )}
                    />
                  )}
                  {!pendingChangeState.buid && (
                    <div className="wrap flex fill-width">
                      {selectedSitesData.buids?.map((b, i) => (
                        <Pill key={"buids" + i} className="pills" disabled>
                          {b}
                        </Pill>
                      ))}
                    </div>
                  )}
                </SimpleTableCell>
                <SimpleTableCell>
                  <Checkbox
                    checked={pendingChangeState.buid}
                    onChange={() => {
                      resetField('buid');
                      setPendingChangeState(s => ({ ...s, buid: !s.buid }));
                    }}
                    className="pad_h"
                  ></Checkbox>
                </SimpleTableCell>
              </SimpleTableRow>

              <SimpleTableRow>
                <SimpleTableCell>{t('ui_multiedit_formelement_label_customer')}</SimpleTableCell>
                <SimpleTableCell>
                  {pendingChangeState.customer && (
                    <Controller
                      name="customer"
                      control={control}
                      render={({ field }) => (
                        <CustomerDropdown
                          selected={field.value}
                          itemClicked={item => field.onChange(item?.customerId)}
                          topElement={t('ui_multiedit_formelement_unchanged') as string}
                          placeholder={t('ui_multiedit_formelement_unchanged')}
                        />
                      )}
                    />
                  )}
                  {!pendingChangeState.customer && (
                    <div className="wrap flex fill-width">
                      {selectedSitesData.customers?.map((b,i) => (
                        <Pill key={"customers" + i} className="pills" disabled>
                          {b}
                        </Pill>
                      ))}
                    </div>
                  )}
                </SimpleTableCell>
                <SimpleTableCell>
                  <Checkbox
                    checked={pendingChangeState.customer}
                    onChange={() => {
                      resetField('customer');
                      setPendingChangeState(s => ({ ...s, customer: !s.customer }));
                    }}
                    className="pad_h"
                  ></Checkbox>
                </SimpleTableCell>
              </SimpleTableRow>


              {features.siteTanks.write && (              
              <SimpleTableRow>
                <SimpleTableCell>{t('ui_multiedit_formelement_label_haulierTag')}</SimpleTableCell>
                <SimpleTableCell>
                  {pendingChangeState.haulier && (
                    <Controller
                      name="haulier"
                      control={control}
                      render={({ field }) => (
                        <HaulierDropdown                         
                          displayPath='haulierTag' 
                          selected={field.value}
                          itemClicked={
                            item => {
                              if (item === undefined) 
                                return field.onChange({haulierId: '-1', name: 'none', haulierTag: t('ui_common_none')});
                              else
                                return field.onChange(item?.value)
                            }
                          }
                          topElement={t('ui_multiedit_formelement_unchanged') as string}                          
                          placeholder={t('ui_multiedit_formelement_unchanged')}
                          showNoneElement={true}
                        ></HaulierDropdown>
                      )}
                    />
                  )}
                  {!pendingChangeState.buid && (
                    <div className="wrap flex fill-width">
                      {selectedSitesData.hauliers?.map((h, i) => (
                        <Pill key={"hailierTag" + i} className="pills" disabled>
                          {h?.haulierTag}
                        </Pill>
                      ))}
                    </div>
                  )}
                </SimpleTableCell>
                <SimpleTableCell>
                  <Checkbox
                    checked={pendingChangeState.haulier}
                    onChange={() => {
                      resetField('haulier');
                      setPendingChangeState(s => ({ ...s, haulier: !s.haulier }));
                    }}
                    className="pad_h"
                  ></Checkbox>
                </SimpleTableCell>
              </SimpleTableRow>
              )}
              <SimpleTableRow>
                <SimpleTableCell>{t('ui_multiedit_formelement_label_system_alarms')}</SimpleTableCell>
                <SimpleTableCell>
                  <Controller
                    name="systemAlarmsDisabled"
                    control={control}
                    render={({ field }) => (
                      <ButtonGroup className="flex fill-width">
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            field.onChange(true);
                          }}
                          disabled={!pendingChangeState.systemAlarms}
                          variant={field.value === true ? 'primary' : 'secondary'}
                          className="flex_1"
                        >
                          {t('ui_multiedit_formelement_disable')}
                        </Button>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            field.onChange(false);
                          }}
                          disabled={!pendingChangeState.systemAlarms}
                          variant={field.value === false ? 'primary' : 'secondary'}
                          className="flex_1"
                        >
                          {t('ui_multiedit_formelement_enable')}
                        </Button>
                      </ButtonGroup>
                    )}
                  />
                </SimpleTableCell>
                <SimpleTableCell>
                  <Checkbox
                    checked={pendingChangeState.systemAlarms}
                    onChange={() => {
                      resetField('systemAlarmsDisabled');
                      setPendingChangeState(s => ({ ...s, systemAlarms: !s.systemAlarms }));
                    }}
                    className="pad_h"
                  ></Checkbox>
                </SimpleTableCell>
              </SimpleTableRow>


              <SimpleTableRow>
                <SimpleTableCell>{t('ui_multiedit_formelement_label_soldto')}</SimpleTableCell>
                <SimpleTableCell>
                  {pendingChangeState.soldTo && <TextField {...register('soldTo', { setValueAs: (t) => t.trim()})} />}
                  {!pendingChangeState.soldTo && (
                    <div className="wrap flex fill-width">
                      {selectedSitesData.soldTos?.map(
                        (b, i) =>
                          b && (
                            <Pill key={"soldTos" + i} className="pills" disabled>
                              {b}
                            </Pill>
                          )
                      )}
                    </div>
                  )}
                </SimpleTableCell>
                <SimpleTableCell>
                  <Checkbox
                    checked={pendingChangeState.soldTo}
                    onChange={() => {
                      resetField('soldTo');
                      setPendingChangeState(s => ({ ...s, soldTo: !s.soldTo }));
                    }}
                    className="pad_h"
                  ></Checkbox>
                </SimpleTableCell>
              </SimpleTableRow>

              <SimpleTableRow>
                <SimpleTableCell>{t('ui_multiedit_formelement_label_parking')}</SimpleTableCell>
                <SimpleTableCell>
                  <Controller
                    name="parked"
                    control={control}
                    render={({ field }) => (
                      <ButtonGroup className="flex fill-width">
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            field.onChange(false);
                          }}
                          disabled={!pendingChangeState.parked}
                          variant={field.value === false ? 'primary' : 'secondary'}
                          className="flex_1"
                        >
                          {t('ui_multiedit_formelement_unpark')}
                        </Button>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            field.onChange(true);
                          }}
                          disabled={!pendingChangeState.parked}
                          variant={field.value === true ? 'primary' : 'secondary'}
                          className="flex_1"
                        >
                          {t('ui_multiedit_formelement_park')}
                        </Button>
                      </ButtonGroup>
                    )}
                  />
                </SimpleTableCell>
                <SimpleTableCell>
                  <Checkbox
                    checked={pendingChangeState.parked}
                    onChange={() => {
                      resetField('parked');
                      setPendingChangeState(s => ({ ...s, parked: !s.parked }));
                    }}
                    className="pad_h"
                  ></Checkbox>
                </SimpleTableCell>
              </SimpleTableRow>

              {features.siteDetails.write && (
                <SimpleTableRow>
                  <SimpleTableCell>{t('Order contact')}</SimpleTableCell>
                  <SimpleTableCell>
                    <EditContact
                      existingContacts={selectedSitesData.orderContacts as Contact[]}
                      changeToContact={getValues('orderContact')}
                      removePendingContact={() => {
                        setPendingChangeState(s => ({ ...s, orderContact: !s.orderContact }));
                        resetField('orderContact');
                      }}
                      changeContact={pendingChangeState.orderContact}
                    ></EditContact>
                  </SimpleTableCell>
                  <SimpleTableCell>
                    <Checkbox
                      className="pad_h"
                      checked={pendingChangeState.orderContact}
                      onChange={e => {
                        if (e.target.value) {
                          resetField('orderContact');
                          setContactsSearchOpen('orderContact');
                        }
                        setPendingChangeState(s => ({ ...s, orderContact: !s.orderContact }));
                      }}
                    />
                  </SimpleTableCell>
                </SimpleTableRow>
              )}

              {features.siteDetails.write && (
                <SimpleTableRow>
                  <SimpleTableCell>{t('Tech contact')}</SimpleTableCell>
                  <SimpleTableCell>
                    <EditContact
                      existingContacts={selectedSitesData.techContacts as Contact[]}
                      changeToContact={getValues('techContact')}
                      removePendingContact={() => {
                        setPendingChangeState(s => ({ ...s, techContact: !s.techContact }));
                        resetField('techContact');
                      }}
                      changeContact={pendingChangeState.techContact}
                    ></EditContact>
                  </SimpleTableCell>
                  <SimpleTableCell>
                    <Checkbox
                      className="pad_h"
                      checked={pendingChangeState.techContact}
                      onChange={e => {
                        if (e.target.value) {
                          resetField('techContact');
                          setContactsSearchOpen('techContact');
                        }
                        setPendingChangeState(s => ({ ...s, techContact: !s.techContact }));
                      }}
                    />
                  </SimpleTableCell>
                </SimpleTableRow>
              )}

              {features.siteDetails.write && (
                <SimpleTableRow>
                  <SimpleTableCell>{t('Sales contact')}</SimpleTableCell>
                  <SimpleTableCell>
                    <EditContact
                      existingContacts={selectedSitesData.salesContacts as Contact[]}
                      changeToContact={getValues('salesContact')}
                      removePendingContact={() => {
                        setPendingChangeState(s => ({ ...s, salesContact: !s.salesContact }));
                        resetField('salesContact');
                      }}
                      changeContact={pendingChangeState.salesContact}
                    ></EditContact>
                  </SimpleTableCell>
                  <SimpleTableCell>
                    <Checkbox
                      className="pad_h"
                      checked={pendingChangeState.salesContact}
                      onChange={e => {
                        if (e.target.value) {
                          resetField('salesContact');
                          setContactsSearchOpen('salesContact');
                        }
                        setPendingChangeState(s => ({ ...s, salesContact: !s.salesContact }));
                      }}
                    />
                  </SimpleTableCell>
                </SimpleTableRow>
              )}
              {features.siteDetails.write && (
                <SimpleTableRow>
                  <SimpleTableCell>{t('Customer contact')}</SimpleTableCell>
                  <SimpleTableCell>
                    <EditContact
                      existingContacts={selectedSitesData.customerContacts as Contact[]}
                      changeToContact={getValues('customerContact')}
                      removePendingContact={() => {
                        setPendingChangeState(s => ({ ...s, customerContact: !s.customerContact }));
                        resetField('customerContact');
                      }}
                      changeContact={pendingChangeState.customerContact}
                    ></EditContact>
                  </SimpleTableCell>
                  <SimpleTableCell>
                    <Checkbox
                      className="pad_h"
                      checked={pendingChangeState.customerContact}
                      onChange={e => {
                        if (e.target.value) {
                          resetField('customerContact');
                          setContactsSearchOpen('customerContact');
                        }
                        setPendingChangeState(s => ({ ...s, customerContact: !s.customerContact }));
                      }}
                    />
                  </SimpleTableCell>
                </SimpleTableRow>
              )}

              <SimpleTableRow>
                <SimpleTableCell>{t('Mute alarms')}</SimpleTableCell>
                <SimpleTableCell>
                  <Controller
                    name="muted"
                    control={control}
                    render={({ field }) => (
                      <ButtonGroup className="flex fill-width">
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            field.onChange(false);
                          }}
                          disabled={!pendingChangeState.muted}
                          variant={field.value === false ? 'primary' : 'secondary'}
                          className="flex_1"
                        >
                          {t('Unmute')}
                        </Button>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            field.onChange(true);
                          }}
                          disabled={!pendingChangeState.muted}
                          variant={field.value === true ? 'primary' : 'secondary'}
                          className="flex_1"
                        >
                          {t('Mute')}
                        </Button>
                      </ButtonGroup>
                    )}
                  />
                </SimpleTableCell>
                <SimpleTableCell>
                  <Checkbox
                    checked={pendingChangeState.muted}
                    onChange={() => {
                      resetField('muted');
                      setPendingChangeState(s => ({ ...s, muted: !s.muted }));
                    }}
                    className="pad_h"
                  ></Checkbox>
                </SimpleTableCell>
              </SimpleTableRow>
            </SimpleTable>

            <div className="flex jsb fill-width pad_m">
              <Button onClick={closeEditor} variant="secondary">
                {t('ui_common_discardchanges')}
              </Button>
              <Button processing={isSubmitting} type="submit">{t('ui_common_savechanges')}</Button>
            </div>
          </form>
        </CardContent>
      )}
      {contactsSearchOpen && (
        <Controller
          name={contactsSearchOpen}
          control={control}
          render={({ field }) => (
            <ContactsSearch
              setAsContact={contact => {
                field.onChange(contact);
                setContactsSearchOpen(undefined);
              }}
              cancelSearch={() => {
                setPendingChangeState(s => ({ ...s, [contactsSearchOpen]: !s[contactsSearchOpen] }));
                setContactsSearchOpen(undefined);
              }}
              className="contact-search-section"
              contactType={getContactType(contactsSearchOpen)}
              optionalButtons={
                <Button
                  onClick={() => {
                    setContactsSearchOpen(undefined);
                    field.onChange(undefined);
                  }}
                >
                  {t('Clear contact(s)')}
                </Button>
              }
            />
          )}
        />
      )}
    </ModalCard>
  );
};

export default SitelistMultiEditForm;
