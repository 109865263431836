import Pill from '$components/pill/pill.react';
import { Contact } from '$typings/graphql-codegen';
import React, { FC } from 'react';

interface IEditContactProps {
  existingContacts?: Contact[];
  changeToContact: Contact | undefined;
  changeContact: boolean;
  removePendingContact: () => void;
}

const EditContact: FC<IEditContactProps> = ({ existingContacts, changeToContact, changeContact, removePendingContact }) => (
  <div>
    {!changeContact && (
      <div className="pills">
        {existingContacts?.map(
          (contact, i) =>
            contact && (
              <Pill key={contact.name + i} className="pill" disabled>
                {contact?.avatarUrl && <avatar-image url={contact?.avatarUrl} className="avatar"></avatar-image>}
                {contact.name}
              </Pill>
            )
        )}
      </div>
    )}

    {changeContact && !changeToContact && <Pill className="pill">Contact(s) will be cleared.</Pill>}

    {changeContact && changeToContact && (
      <Pill className="pill" iconClicked={removePendingContact}>
        {changeToContact.avatarUrl && <avatar-image url={changeToContact.avatarUrl} className="avatar"></avatar-image>}
        {changeToContact.name}
      </Pill>
    )}
  </div>
);

export default EditContact;
