import React, {ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Icon from '$components/icons/icon/icon.react';
import './dropdown.react.css';
import {useIsMobile} from '$lib/hooks/isMobile';
import {isEmpty, isNone} from '$lib/helpers';
import {compareReduxAndGraphQLIds} from '$lib/graphqlHelpers';
import Button from '$components/buttons/button.react';
import {reactPortal} from '$lib/hooks/reactPortal';
import {isNumber} from '$lib/numberHelpers';
import {Popover} from '@mui/material';
import classNames from 'classnames';
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";

interface IDropdownProps<T> {
  disabled?: boolean;
  expanded?: boolean;
  alignTop?: boolean;
  straightLeft?: boolean;
  straightRight?: boolean;
  defaultQuery?: string;
  canSelectBlankValue?: boolean;
  topElement?: string | boolean | 'IF_QUERY';
  searchable?: boolean;
  items?: Array<T>;
  filter?: (t: T, query: string | undefined) => boolean;
  displayPath?: string | Function;
  valuePath?: string;
  iconPath?: string;
  itemClicked?: (params?: {
    value?: T | T[keyof T];
    text: string;
    iconName?: T | T[keyof T];
  }) => void;
  selected?: string | T;
  replaceItemElement?: (item: T) => ReactElement;
  replaceTopElement?: (query: string) => ReactElement;
  placeholder?: string;
  loading?: boolean;
  translate?: boolean;
  className?: string;
  error?: boolean;
}

const Dropdown = <T,>({
  disabled,
  selected,
  expanded,
  placeholder,
  filter,
  items,
  displayPath,
  valuePath,
  iconPath,
  itemClicked,
  replaceItemElement,
  replaceTopElement,
  className,
  searchable = true,
  topElement = undefined,
  alignTop = false,
  defaultQuery = '',
  straightLeft = false,
  straightRight = false,
  canSelectBlankValue = false,
  loading = false,
  translate = false,
  error = false,
}: IDropdownProps<T>) => {
  const [_isExpanded, setIsExpanded] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement|null>(null);
  const [_focusedItemIndex, setFocusedItemIndex] = useState<number>(0);
  const [_shouldShowTopElement, setShouldShowTopElement] = useState(false);
  const [topElementIndex, setTopElementIndex] = useState<number>(0);
  const [blankElementIndex, setBlankELementIndex] = useState<number>(0);

  const [filteredList, setFilteredList] = useState<T[]>();
  const [item, setItem] = useState<T | undefined>();
  const [query, setQuery] = useState<string>(defaultQuery);

  const dropdownlist = useRef<HTMLInputElement>(null);
  const [t] = useCaseInsensitiveTranslation();

  const isMobile = useIsMobile()

  function closePopover() {
    closeList();
    setPopoverAnchor(null);
  }

  function openPopover(e?: React.MouseEvent<HTMLDivElement>) {
    if (e) setPopoverAnchor(e.currentTarget);
    toggleList()
  }
  function toggleList() {
    if (expanded || disabled) return;
    if (_isExpanded) {
      closeList();
    } else {
      openList();
    }
  }
  
  const closeList = useCallback(() => {
    setIsExpanded(false);
  }, [])

  function openList() {
    setQuery('');
    setFirstIndexes();
    setFocusedItemIndex(getFirstIndex());
    setIsExpanded(true);
  }

  function setFirstIndexes() {
    let index = 0;
    setBlankELementIndex(canSelectBlankValue ? --index : 0);
    setTopElementIndex(_shouldShowTopElement ? --index : 0);
  }

  function getFirstIndex() {
    return _shouldShowTopElement ? topElementIndex : canSelectBlankValue ? blankElementIndex : 0;
  }

  useEffect(() => {
    if (isNone(topElement)) return setShouldShowTopElement(false);
    if (topElement === 'IF_QUERY') return setShouldShowTopElement(!isEmpty(query));

    return setShouldShowTopElement(true)
  }, [topElement, query]);

  function getDisplayValue(item: T | string) {
    if (isNone(item)) return '';
    const path = displayPath;
    let displayValue = item;
    switch (typeof path) {
      case 'function':
        displayValue = path({ item });
        break;
      case 'string':
        displayValue = (item as any)[path];
        break;
    }
    if (isNone(displayValue)) return '';

    return translate && typeof displayValue === "string" ? t(displayValue) : '' + displayValue;
  }

  useEffect(() => {
    if (isEmpty(query) || isNone(items)) setFilteredList(items);
    if (!isNone(filter)) setFilteredList(items?.filter(item => filter(item, query)));
    const lowerCasedQuery = query.toLocaleLowerCase().trim();
    setFilteredList(
      items?.filter(item => {
        const display = getDisplayValue(item);
        return isNone(display)
          ? false
          : display
            .toLocaleLowerCase()
            .trim()
            .includes(lowerCasedQuery);
      })
    );
  }, [query, items, filter]);

  function scrollSelectedItemIntoView() {
    if (!dropdownlist) return;
    const focusedIndex = _focusedItemIndex;
    const children = dropdownlist.current?.children;
    const focusedEl = focusedIndex && children ? children[focusedIndex] : undefined;
    if (!focusedEl) return;
    focusedEl.scrollIntoView(true);
  }

  function getValueFromItem(item: T | undefined) {
    return isNone(item) || isNone(valuePath) ? item : (item as any)[valuePath];
  }

  function getIconNameFromItem(item: T | undefined) {
    return isNone(item) || isNone(iconPath) ? item : (item as any)[iconPath];
  }

  function selectItem(item: T | undefined) {
    if (!isNone(itemClicked)) {
      if (_focusedItemIndex === blankElementIndex && canSelectBlankValue) {
        itemClicked(); // clear field by setting it to undefined
      } else {
        itemClicked({
          value: getValueFromItem(item),
          text: item ? getDisplayValue(item) : topElement === 'IF_QUERY' ? query : "",
          iconName: getIconNameFromItem(item)
        });
      }
    } else selected = item;

    closeList();
  }

  function inputkeyUp({ key }: React.KeyboardEvent<HTMLInputElement>) {
    if(key === "Escape") {
        closeList();
        return false;
    }
    return true
  }

  function inputkeydown({ key }: React.KeyboardEvent<HTMLInputElement>) {
    const minItemIndex = getFirstIndex();
    switch (key) {
      case "ArrowDown":
        filteredList && setFocusedItemIndex(filteredList.length - 1 > _focusedItemIndex ? _focusedItemIndex + 1 : _focusedItemIndex);
        scrollSelectedItemIntoView();
        return false;
      case "ArrowUp":
        setFocusedItemIndex(_focusedItemIndex > minItemIndex ? _focusedItemIndex - 1 : minItemIndex);
        scrollSelectedItemIntoView();
        return false;
      case "Enter":
        filteredList && selectItem(filteredList[_focusedItemIndex]);
        return false;
    }
    setFirstIndexes();
    setFocusedItemIndex(getFirstIndex());
    scrollSelectedItemIntoView();
    return true;
  }

  function itemMouseEnter(index?: number) {
    isNumber(index) && setFocusedItemIndex(index);
  }

  useEffect(() => {
    if (isNone(selected)) return setItem(selected);
    const value = typeof selected === 'object' ? getValueFromItem(selected) : selected;
    return setItem(
      typeof selected === 'object' ? selected : (items || []).find(i => compareReduxAndGraphQLIds(getValueFromItem(i), value)) || value
    );
  }, [selected, items]);

  function setInputQuery(e: ChangeEvent<HTMLInputElement>) {
    setQuery(e.target.value);
  }
  
  const QueryYieldNoMatchPlaceholder = () => {
    const hasSearchWord: boolean = (query?.length ?? 0) > 0
    const hasItemsToSearch: boolean = (items?.length ?? 0) > 0
    const noMatch: boolean = (filteredList?.length ?? 0) < 1
    const render: boolean = hasSearchWord && hasItemsToSearch && noMatch
    const text: string = t('UI_Common_NoneMatchingQuery')
    const div: JSX.Element = <div className="item special">{text}</div>
    return render ? div : <></>
  }

  const dropdownList = (
    <div 
      className={classNames(
        'dropdown-filter-list-content',
        alignTop && 'align-top'
      )} 
      ref={dropdownlist}
    >
      {loading ? (
        <div className="item special">
          {t('ui_common_loading')}
        </div>
      ) : (
        <>
          {_shouldShowTopElement && (
            <div
              className={classNames(
                'item',
                'clickable',
                'flex',
                (
                  isNumber(topElementIndex)
                  && isNumber(_focusedItemIndex)
                  && topElementIndex === _focusedItemIndex
                ) && 'focused'
              )}
              onClick={() => selectItem(undefined)}
              onMouseEnter={() => itemMouseEnter(topElementIndex)}
            >
              {replaceTopElement ? replaceTopElement(query) : topElement}
            </div>
          )}
          {canSelectBlankValue && (
            <div
              className={classNames(
                'item',
                'clickable',
                (blankElementIndex === _focusedItemIndex) && 'focused'
              )}
              onClick={() => selectItem(undefined)}
              onMouseEnter={() => itemMouseEnter(blankElementIndex)}
            >
              {t('ui_common_none')}
            </div>
          )}
          <QueryYieldNoMatchPlaceholder />
          {(!items || !items.length) && !_shouldShowTopElement && (
            <div className="item special">{t('ui_common_noitems')}</div>
          )}
          {filteredList?.map((item, i) => (
            <div
              key={"dropdownItem" + i}
              onMouseEnter={() => itemMouseEnter(i)}
              onClick={() => selectItem(item)}
              className={classNames(
                'item',
                'clickable',
                'flex',
                (i === _focusedItemIndex) && 'focused'
              )}
            >
              {replaceItemElement ? replaceItemElement(item) :
                <>
                  {(item as any).iconName && <Icon className="icon" name={(item as any).iconName}></Icon>}
                  {getDisplayValue(item)}
                </>
              }
            </div>
          ))}
        </>
      )}
    </div>
  );
  
  const searchInput = (
    <input
      type="text"
      className="query-field"
      onKeyDown={inputkeydown}
      onKeyUp={inputkeyUp}
      value={query}
      onChange={setInputQuery}
      onClick={e => e.stopPropagation}
      placeholder={t('ui_common_search')}
      autoFocus
    />
  );
  
  const MainClassName = useMemo(() => {
    const base = 'dropdown-filter-list-selected clickable'
    const disabledClass = disabled ? 'disabled' : ''
    const expandedClass = _isExpanded ? 'expanded' : ''
    const aligned = alignTop ? 'align-top' : ''
    const leftAligned = straightLeft ? 'straight-left' :  ''
    const rightAligned = straightRight ? 'straight-right' : ''
    const errorClass = error ?? ''
    return [
      base,
      disabledClass,
      expandedClass,
      aligned,
      leftAligned,
      rightAligned,
      errorClass
    ].join(' ')
  }, [_isExpanded, disabled, alignTop, straightLeft, straightRight, error])
  
  return (
    <div className={classNames('react-dropdown-component', className)}>
      <div
        className={MainClassName}
        onClick={openPopover}
      >
        {isNone(item) ? (
          <div className="selected-text">{placeholder}</div>
        ) : (
          <div className="selected-text flex">
            {replaceItemElement ? replaceItemElement(item) : (
              <>
                {(item as any).iconName && <Icon className='icon' name={(item as any).iconName} />}
                {getDisplayValue(item)}
              </>
            )}
          </div>
        )}
        <Icon name={_isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'} className="dropdown-icon" />
      </div>

      <Popover
        transitionDuration={0}
        open={_isExpanded && !isMobile}
        id={"dropdown-popover"}
        anchorEl={popoverAnchor}
        onClose={closePopover}
        anchorOrigin={{
          vertical: alignTop ? 'bottom' : 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: alignTop ? 'bottom' : 'top',
          horizontal: 'left',
        }}
      >
        <div
          className="react-dropdown-component popover" 
          style={{
            width: popoverAnchor?.clientWidth ? popoverAnchor?.clientWidth + 2 : undefined,
            flexDirection: alignTop ? 'column-reverse' : undefined
          }}
        >
          <div className={classNames(
            'dropdown-filter-list-selected',
            'clickable',
            'expanded', 
            alignTop && 'align-top',
            straightLeft && 'straight-left',
            straightRight && 'straight-right',
            error && 'error',
          )}>
            {searchable ? searchInput :
              isNone(item) ? (
                <div className="selected-text">{placeholder}</div>
              ) : (
                <div className="selected-text flex">
                  {replaceItemElement ? replaceItemElement(item) : (
                    <>
                      {(item as any).iconName && <Icon className='icon' name={(item as any).iconName} />}
                      {getDisplayValue(item)}
                    </>
                  )}
                </div>
            )}
            <div onClick={closePopover}><Icon name={'fa-chevron-up'} className="dropdown-icon" /></div>
          </div>
          {dropdownList}
        </div>
      </Popover>
      
      {isMobile && _isExpanded && reactPortal(
        <div className="react-portal-dropdown">
          <div className="dropdown-filter-list-selected clickable expanded">
            <Button
              variant='secondary'
              className="cancel-dropdown-button"
              onClick={toggleList}>
              {t('ui_common_cancel')}
            </Button>
            {searchable && searchInput}
          </div>
          {dropdownList}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
