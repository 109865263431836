import React, { memo } from 'react';
import { useIsMobile } from '$lib/hooks/isMobile';
import { MobileView } from './modules/mobile-view';
import { PageComponent } from './types/page-component';
import Grid2 from '@mui/material/Unstable_Grid2';
import { createTheme, ThemeProvider } from '@mui/material';
import yaraTheme from '$components/mui-theme-provider/yara-theme';
import './card-page.css';
import { ActionsComponentType } from './types/actions-component-type';

const customBreakpointsTheme = createTheme({
  ...yaraTheme,
  ...{
    breakpoints: {
      values: {
        xs: 767,
        sm: 1310,
        md: 1700,
        lg: 2100,
        xl: 2600,
      },
    },
  },
});

/* how many cols the cards should span over in the different breakpoints */
const getColumnSpanning = (span?: number) => ({
  xs: span ?? 1,
  sm: span ?? 1,
  md: span ?? 1,
  lg: span ?? 1,
  xl: span ?? 1,
});
/* available columns in different the breakpoints */
const columnsDefinition = { xs: 2, sm: 3, md: 4, lg: 5, xl: 6 };

export interface ICardPageProps<T>{
  showMenuBar: boolean; // renders the "action" component as a menu bar
  components: PageComponent<T | ActionsComponentType>[];
  selectedMobileComponent: T | ActionsComponentType;
  setSelectedMobileComponent: (component: T | ActionsComponentType) => unknown;
  fullHeightRows?: boolean; // sets minHeight to same value as maxHeight
  minRowHeight?: string;
  shouldFlexGrow?: boolean,
  useMinHeight?: boolean // defaults to 50rem
}

const CardPageComponent = <T,>({
  components,
  showMenuBar,
  selectedMobileComponent,
  setSelectedMobileComponent,
  fullHeightRows,
  minRowHeight,
  shouldFlexGrow,
  useMinHeight = true
}: ICardPageProps<T>) => {
  const isMobile = useIsMobile();

  const availableComponents = components.filter((c) => !!c.component);

  if (isMobile) {
    return (
      <div className="card-page-overflow-wrapper">
        <MobileView
          components={availableComponents}
          selectedMobileComponent={selectedMobileComponent}
          setSelectedMobileComponent={setSelectedMobileComponent}
        />
      </div>
    );
  }

  /* actions card MUST be first in array if available! */
  const [actionsCard, ...componentsExceptActionCard] = availableComponents;
  const gridComponents =
    actionsCard.type === 'actions'
      ? componentsExceptActionCard
      : availableComponents;

  // if we're gonna use this component for other pages, we might need to 
  // rethink how to do this height thing, so be careful about changing these
  const maxHeight = !showMenuBar
    ? 'calc(100vh - 10.5rem)' // works for sitelist controller-details
    : 'calc(100vh - 11rem)';  // works for controllermanager controller-details
  
  const minHeight = useMinHeight ? (fullHeightRows ? maxHeight : minRowHeight ?? '50rem') : 0;

  return (
    <ThemeProvider theme={customBreakpointsTheme}>
      <div className="card-page-overflow-wrapper">
        {actionsCard.type === 'actions' && (
          <div className="actions-card-wrapper">{actionsCard.component}</div>
        )}
        <Grid2
          container
          minWidth={'inherit'}
          spacing={'1rem'}
          margin={'0.5rem'}
          marginTop={!showMenuBar ? 0 : undefined}
          columns={columnsDefinition}
        >
          {gridComponents.map((c, i) => (
            shouldFlexGrow ? 
            (
              <Grid2
              key={i}
              {...getColumnSpanning(c.spanColumns)}
              style={{
                minHeight: minHeight,
                maxHeight: maxHeight,
                flex: 1
              }}
            >
              {c.component}
            </Grid2>
            ) 
            :
            (
              <Grid2
              key={i}
              {...getColumnSpanning(c.spanColumns)}
              sx={{
                minHeight: minHeight,
                maxHeight: maxHeight,
                display: 'flex'
              }}
            >
              {c.component}
            </Grid2>
            )

          ))}
        </Grid2>
      </div>
    </ThemeProvider>
  );
};

export const CardPage = memo(CardPageComponent) as typeof CardPageComponent;
