import React, { FC, memo } from 'react';
import { Modal } from '@mui/material';
import {
  CardWithHeader,
  ICardWithHeaderProps,
} from '$components/cards/mui-card';
import './mui-modal-card.css';
import classNames from 'classnames';

interface IMuiModalCardProps extends ICardWithHeaderProps {
  open: boolean;
  handleClose?: (event : object | undefined, reason : string | undefined) => unknown;
  fullScreen?: boolean;
}

const MuiModalCardComponent: FC<IMuiModalCardProps> = ({
  open,
  handleClose,
  children,
  fullScreen,
  ...cardProps
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div
        className={classNames(
          'mui-modal-card-wrapper',
          fullScreen && 'mui-modal-fullscreen',
      )}>
        <CardWithHeader {...cardProps}>{children}</CardWithHeader>
      </div>
    </Modal>
  );
};

export const MuiModalCard = memo(MuiModalCardComponent);
