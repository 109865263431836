import React, { FC } from 'react';
import './simple-table-row.css'
import classNames from "classnames";

interface ISimpleTableRowProps {
  className?: string
}

const SimpleTableRow: FC<ISimpleTableRowProps> = ({ children, className }) => (
    <div className={classNames('simple-table-row-component', className)}>{children}</div>
  );

export default SimpleTableRow;
