import ButtonGroup from '$components/button-group/button-group.react';
import Button from '$components/buttons/button.react';
import { IReactGridFilterComponentProps } from '$components/grid/react-grid/grid.react';
import Pill from '$components/pill/pill.react';
import TextField from '$components/textbox/text-field.react';
import { isNone } from '$lib/helpers';
import { ensureNumber } from '$lib/numberHelpers';
import React, { FC, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionalOperator, IFilterGroup, INumberFilter } from '../../../../../interfaces';
import { useIsTouchDevice } from '$lib/hooks/isTouchDevice';

type IOperations = 'equals' | 'lt' | 'gt' | 'between';

const NumberFilter: FC<IReactGridFilterComponentProps> = ({ activeFilters, filterChanged, property }) => {
  const activeFilter = activeFilters?.find(f => {
    return f.field === property;
  })
  const [t] = useTranslation();
  const isTouchDevice = useIsTouchDevice();
  const [operation, setOperation] = useState<IOperations>('equals');
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');

  function validateValues(value1: number | string | undefined, value2: number | string | undefined, operation: IOperations): boolean {
    const value1IsNotDefined = isNone(value1) || value1.toString() === '';
    const value2IsNotDefined = isNone(value2) || value2.toString() === '';
    return operation === 'between' ? !value1IsNotDefined && !value2IsNotDefined : !value1IsNotDefined;
  }

  function getNewFilter(): INumberFilter {
    if (!validateValues(value1, value2, operation) || isNone(value1)) throw Error('Value1 or value2 is not defined.');
    switch (operation) {
      case 'equals':
        return { operator: ConditionalOperator.Equals, value: ensureNumber(value1), symbol: '=' };
      case 'gt':
        return { operator: ConditionalOperator.GreaterThan, value: ensureNumber(value1), symbol: '>' };
      case 'lt':
        return { operator: ConditionalOperator.LesserThan, value: ensureNumber(value1), symbol: '<' };
      case 'between':
        return {
          operator: ConditionalOperator.Between,
          value: ensureNumber(value1),
          secondValue: isNone(value2) ? undefined : ensureNumber(value2),
          symbol: '&&'
        };
    }
  }

  function addFilter() {
    const filter = getNewFilter();

    const newfilter: IFilterGroup = {
      ...(activeFilter || { exclude: false, field: property, type: 'number', filters: [], partialMatch: true }),
      filters: activeFilter ? [...(activeFilter.filters as INumberFilter[]), filter] : [filter]
    };

    filterChanged && filterChanged(newfilter);
    setValue1('');
    setValue2('');
  }

  function removeFilter(filterToRemove: INumberFilter, event: MouseEvent) {
    event.preventDefault();
    if (!activeFilter) return;
    const newfilter: IFilterGroup = { ...activeFilter, filters: (activeFilter.filters as INumberFilter[]).filter(f => f !== filterToRemove) };
    filterChanged && filterChanged(newfilter);
  }

  function getTextForFilter(filter: INumberFilter) {
    switch (filter.operator) {
      case ConditionalOperator.Between:
        return t('ui_sitelist_filter_number_between') + ` ${filter.value} and ${filter.secondValue}`;
      case ConditionalOperator.Equals:
        return t('ui_sitelist_filter_number_equals') + ` ${filter.value}`;
      case ConditionalOperator.GreaterThan:
        return t('ui_sitelist_filter_number_greaterthan') + ` ${filter.value}`;
      case ConditionalOperator.LesserThan:
        return t('ui_sitelist_filter_number_lessthan') + ` ${filter.value}`;
    }
  }

  return (
    <div>
      <ButtonGroup className="mar_bm flex height-auto">
        <Button className="flex_1" variant={operation === 'equals' ? 'primary' : 'secondary'} onClick={() => setOperation('equals')}>
          {t('ui_sitelist_filter_number_equals')}
        </Button>
        <Button className="flex_1" variant={operation === 'lt' ? 'primary' : 'secondary'} onClick={() => setOperation('lt')}>
          {t('ui_sitelist_filter_number_lessthan')}
        </Button>
        <Button className="flex_1" variant={operation === 'gt' ? 'primary' : 'secondary'} onClick={() => setOperation('gt')}>
          {t('ui_sitelist_filter_number_greaterthan')}
        </Button>
        <Button className="flex_1" variant={operation === 'between' ? 'primary' : 'secondary'} onClick={() => setOperation('between')}>
          {t('ui_sitelist_filter_number_between')}
        </Button>
      </ButtonGroup>

      {operation !== 'between' && (
        <div className="pad_b">
          <TextField
            autoFocus={!isTouchDevice}
            type="number"
            onChange={e => setValue1(e.target.value)}
            value={value1}
            placeholder={t('ui_sitelist_filter_number_value')}
          ></TextField>
        </div>
      )}
      {operation === 'between' && (
        <div className="flex oh pad_b">
          <TextField
            type="number"
            className="flex_1 oh"
            onChange={e => setValue1(e.target.value)}
            value={value1}
            placeholder={t('ui_sitelist_filter_number_firstvalue')}
          ></TextField>
          <div className="center-content pad_m nowrap">{t('ui_common_and')}</div>
          <TextField
            type="number"
            className="flex_1 oh"
            onChange={e => setValue2(e.target.value)}
            value={value2}
            placeholder={t('ui_sitelist_filter_number_secondvalue')}
          ></TextField>
        </div>
      )}

      <Button className="pad_b" disabled={!validateValues(value1, value2, operation)} onClick={() => addFilter()}>
        {t('ui_sitelist_filter_add')}
      </Button>

      <div className="flex wrap">
        {activeFilter?.filters.map((f, i) => (
          <Pill key={"numberFilterPill" + i} className="mar_rs mar_bs" iconClicked={e => removeFilter(f as INumberFilter, e)}>
            {getTextForFilter(f as INumberFilter)}
          </Pill>
        ))}
      </div>
    </div>
  );
};

export default NumberFilter;
