export enum CustomIcon {
  ARROW_RIGHT = 'arrow-right',
  MUTED = 'muted',
  VOLUME_UP = 'volume-up',
  ARCHIVE = 'archive',
  FILE = 'file',
  FILE_TEXT = 'file-text',
  IMAGE = 'image',
  COLUMNS = 'columns',
  DOWNLOAD = 'download',
  SIGNAL_GOOD = 'signal-good',
  SIGNAL_POOR = 'signal-poor',
  SIGNAL_BAD = 'signal-bad',
  OFFLINE_CROSS = 'offline-cross',
  ONLINE_TICK = 'online-tick',
  PARKED = 'parked',
  PARKED_P = 'parked-p',
  BELL = 'bell',
  MENU = 'menu',
  ALERT_TRIANGLE = 'alert-triangle',
  VIRTUAL = 'virtual',
  BAG = 'bag',
  MAP = 'map',
  CLOUD = 'cloud',
  BAR_CHART = 'bar-chart',
  BAR_CHART_2 = 'bar-chart-2',
  IOS_SHARE = 'ios-share',
  IOS_HOME_SCREEN = 'ios-home-screen',
  COMPARE = 'compare',
  SECURITY_LEVEL_HIGH = 'security-level-high',
  SECURITY_LEVEL_MEDIUM = 'security-level-medium',
  SECURITY_LEVEL_LOW = 'security-level-low',
  SECURITY_LEVEL_NONE = 'security-level-none',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_UP = 'chevron-up',
  SETTINGS = 'settings',
}
