import React, { FC } from 'react';

interface IOfflineCrossProps {
  className?: string;
}

const OfflineCross: FC<IOfflineCrossProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 45.605 45.605"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path
      d="M8.895 31.848a.58.58 0 0 0-.109.159l-.018.148c-.055.442 3.698 4.22 4.327 4.58.073.063.126.099.155.103.12.014.497-.263 1.046-.739l.035-.03.07-.06.07-.064c1.969-1.741 5.801-5.647 8.334-8.242a1149.23 1149.23 0 0 0 3.34 3.371c.34.387.578.69.67.873.461.381 1.356 1.259 2.316 2.107 1.61 1.592 2.827 2.757 2.95 2.772l.149.018c.462.056 4.55-4.056 4.593-4.408l.018-.148c.082-.678-7.947-8.18-8.986-9.32-.047-.053-.1-.11-.158-.17 1.006-.993 2.193-2.167 3.373-3.341.386-.34.69-.578.872-.67.382-.461 1.26-1.356 2.108-2.316 1.591-1.61 2.757-2.827 2.771-2.95l.018-.15c.056-.46-4.056-4.55-4.407-4.592l-.148-.018c-.679-.082-8.18 7.947-9.32 8.986-.054.048-.111.101-.172.159-3.003-3.025-6.027-5.978-9.038-9.007a.58.58 0 0 0-.159-.109l-.148-.018c-.442-.055-4.22 3.699-4.58 4.327-.063.073-.1.126-.103.155-.014.121.263.498.738 1.046l.03.035.062.07.063.071c1.741 1.968 5.648 5.802 8.244 8.335-3.025 3.002-5.977 6.026-9.007 9.037z"
      fill="#7f7f7f"
    />
  </svg>
);

export default OfflineCross;
