import React, { FC } from 'react';
import './card-footer.css'


interface ICardFooterProps{
    className?: string;
    topBorder?: boolean;
}

const CardFooter: FC<ICardFooterProps> = ({ children, className, topBorder }) => {
  const ClassName = (() => {
    const base = 'card-footer-component'
    const border = topBorder ? 'border-top' : ''
    const externalClassName = className ?? ''
    return [base, border, externalClassName].join(' ')
  })()
  
  return <div className={ClassName}>{children}</div>;
};

export default CardFooter;
