import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import Dropdown from '$components/dropdowns/dropdown/dropdown.react';
import EmptyComponentDescription from '$components/empty-component/empty-component-description/empty-component-description.react';
import EmptyComponent from '$components/empty-component/empty-component/empty-component.react';
import ModalCard from '$components/modals/modal-card/modal-card.react';
import { downloadFileFromUrl } from '$lib/hooks/blob-utillities';
import { showPdfViewer } from '$lib/pdfHelpers';
import React, { FC, useEffect, useState } from 'react';
import './refill-attachment-modal.css'
import { Typography } from '@mui/material';
import ErrorText from '$components/texts/error-text/error-text.react';

interface IRefillAttachmentModalData {
  attachmentName: string;
  attachmentUrl: string;
}

interface IRefillAttachmentModalProps {
  attachments: IRefillAttachmentModalData[];
  closeModal: () => void;
}

const RefillAttachmentModal: FC<IRefillAttachmentModalProps> = ({
  attachments,
  closeModal
}) => {
  const [selectedAttachment, setSelectedAttachment] = useState<
    IRefillAttachmentModalData
  >(attachments[0]);

  const [error, setError] = useState('')

  function isPdf(attachment: IRefillAttachmentModalData) {
    return attachment.attachmentName.toLocaleLowerCase().endsWith('.pdf');
  }

  function isImage(attachment: IRefillAttachmentModalData) {
    const attachmentLowerCase = attachment.attachmentName.toLocaleLowerCase();
    return (
      attachmentLowerCase.endsWith('.jpg') ||
      attachmentLowerCase.endsWith('.jpeg')
    );
  }

  useEffect(() => {
    if (selectedAttachment.attachmentUrl && isPdf(selectedAttachment)) {
        showPdfViewer('pdf-viewer', selectedAttachment.attachmentUrl).catch((err) => {
          setError(err)
        })
     
    }
  }, [selectedAttachment]);

  function downloadImage(attachment: IRefillAttachmentModalData) {
    if (!attachment.attachmentUrl || !attachment.attachmentName) return;
    return downloadFileFromUrl(
      attachment.attachmentUrl,
      attachment.attachmentName
    );
  }
  
  return (
    <ModalCard placementOnMobile="bottom" size="fill" cardClass='refill-attachment-modal-wrapper'>
      <CardContent className="flex jsb row">
        <div className="flex_1 flex aicenter">
          {attachments && attachments.length > 1 ? 
            <Dropdown<IRefillAttachmentModalData>
              className="attachment-selector"
              items={attachments}
              displayPath="attachmentName"
              selected={selectedAttachment}
              itemClicked={value =>
                setSelectedAttachment(
                  value?.value as IRefillAttachmentModalData
                )
              }
            />
          : <Typography>{attachments?.[0].attachmentName}</Typography>}
          </div>

          {selectedAttachment.attachmentUrl && !error && (
            <Button
              className="mar_lm"
              onClick={() => downloadImage(selectedAttachment)}
            >
              Download
            </Button>
          )}
        <Button variant="secondary" className="mar_lm" onClick={closeModal}>
          Close
        </Button>
      </CardContent>
      <CardContent className="fill-height overflow-auto align-items-center">
       
        {selectedAttachment.attachmentUrl && isImage(selectedAttachment) && (
          <img src={selectedAttachment.attachmentUrl} className="fill-width" />
        )}
        {selectedAttachment.attachmentUrl && isPdf(selectedAttachment) && (
          <div>
            <div id="pdf-viewer"></div>
          </div>
        )}
        {(!selectedAttachment.attachmentUrl && !error) && (
          <EmptyComponent>
            <EmptyComponentDescription>
              This refill does not have an attachment
            </EmptyComponentDescription>
          </EmptyComponent>
        )}
         {error && (
          <EmptyComponent>
            <EmptyComponentDescription>
              <ErrorText>{error}</ErrorText>
            </EmptyComponentDescription>
          </EmptyComponent>
        )}
      </CardContent>
    </ModalCard>
  );
};

export default RefillAttachmentModal;
