import React, { FC, useState, useLayoutEffect, useRef } from 'react';
import './battery-bar.css';
import classNames from "classnames";

interface IBatteryBarProps {
  percentage: number;
}

const BatteryBar: FC<IBatteryBarProps> = ({ percentage }) => {
  const [showPercentageNumber, setShowPercentageNumber] = useState(true);
  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      setShowPercentageNumber(ref.current.offsetWidth > 100);
    }
  }, [ref.current]);

  return (
    <div ref={ref} className="battery-bar-component block fill-height">
      <div className="flex fill-height">
        {showPercentageNumber && <div className="pad_rs number-cell">{percentage} %</div>}
        <div className="flex jccenter aicenter relative">
          <div className="bar-wrapper">
            <div
              className={classNames(
                'bar-fill',
                percentage > 50 ? 'ok' : percentage > 25 ? 'warning' : 'danger'
              )}
              style={{ width: `${Math.min(Math.max(percentage, 0), 100)}%` }}
            ></div>
          </div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
};

export default BatteryBar;
