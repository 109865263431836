import React, {FC} from "react";

const Bag: FC<React.HTMLAttributes<HTMLElement>> = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width="12.000000pt"
    height="12.000000pt"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
    className={className ?? ''}>
    <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M157 633 c-13 -13 -7 -39 18 -76 l26 -37 119 0 120 0 26 39 c14 22 24 48 22 58 -5 26 -49 28 -104 4 -58 -26 -71 -26 -125 -1 -44 20 -90 26 -102 13z m122 -68 c2 -3 -9 -5 -25 -5 -29 0 -54 15 -54 32 0 7 67 -16 79 -27z m151 10 c-7 -8 -27 -15 -44 -14 l-31 1 40 18 c43 20 55 19 35 -5z"/>
      <path d="M205 489 c-15 -23 15 -30 121 -27 89 2 109 6 109 18 0 12 -20 16 -112 18 -74 2 -114 -1 -118 -9z"/>
      <path
        d="M158 392 c-80 -90 -132 -231 -112 -308 6 -28 19 -44 44 -59 32 -18 51 -20 230 -20 179 0 198 2 230 20 25 15 38 31 44 59 20 77 -32 218 -112 308 l-44 48 -118 0 -118 0 -44 -48z m289 -19 c40 -44 71 -96 93 -162 29 -86 27 -126 -7 -148 -24 -16 -52 -18 -213 -18 -161 0 -189 2 -213 18 -34 22 -36 62 -7 148 22 66 53 118 93 162 25 26 30 27 127 27 97 0 102 -1 127 -27z"/>
    </g>
  </svg>
)

export default Bag
