import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validateEmailAddress } from './email-input';
import TextField, { ITextFieldProps } from '$components/textbox/text-field.react';

interface IEmailFieldProps extends ITextFieldProps {
    register: UseFormRegister<any>
    name?: string
}

const EmailField: FC<IEmailFieldProps> = ({ register, name, required, ...rest }) => {
    const [t] = useTranslation()

    function validateEmail(e: string) {
        //TODO rewrite validateEmailAddress to return different strings explaining why not valid?
        const isValid = validateEmailAddress(e)
        return isValid || t<string>('ui_usermanager_invalidemailaddress')
    }


    return (<TextField {...register(name || "email",{ validate: (e) => validateEmail(e), required })} {...rest} />);
};

export default EmailField;