import React, {useState, memo, FC} from 'react';
import {
  CardFormEditor,
  ICardFormEditorProps,
} from '$pages/common/form-editor';
import { pureFormatDate } from '$lib/dateHelpers';
import { Controller } from 'react-hook-form';
import ControllerTypeDropdown from '$components/dropdowns/controllertype-dropdown/controllertype-dropdown';
import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import TimezoneDropdown from '$components/dropdowns/timezone-dropdown/timezone-dropdown';
import { getTimezoneDescById } from '$lib/timezone/timezoneHelpers';
import CountryCodeDropdown from '$components/dropdowns/countrycode-dropdown/countrycode-dropdown.react';
import { getName } from '$lib/application/countryHelpers';
import { mutate } from '$lib/hooks/fetch-utillities';
import {
  ControllerDetailsQueryQuery,
  UpdateControllerDocument,
  UpdateControllerConflict,
  UpdateControllerPreflightQueryDocument,
  ControllerInput,
  UpdateControllerMutation,
  IdaxCsUrlDocument,
} from '$typings/graphql-codegen';
import { isPingeableIPAddress } from '$lib/application/controllerHelpers';
import { Button } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import {
  getSession,
  getUserFeatures  
} from '../../../../../../config/sessionService';
import { getAllowedColumns } from '$pages/common';
import { useControllerCommandService } from '$lib/hooks/useControllerCommandService';
import { useIsMobile } from '$lib/hooks/isMobile';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { ControllerLastBackup } from '$pages/common/controllers/controllerdetails-react/modules/controller-last-backup';
import { NotificationService } from '$services/notificationService';
import { ControllerService } from '$services/controllerService';
import { ensureNumber } from '$lib/numberHelpers';
import Banner from '$components/banners/banner/banner.react';
import { ControllerUploadConfig } from '../controller-upload-config/controller-upload-config';
import { ControllerUpdateModal } from '../modals/controller-update-modal/controller-update-modal';
import { getIpAddressFormValidation, IpAddressInput } from '$components/inputs/ipaddress-input/ipaddress-input.react';
import MuiTextField from '$components/textbox/mui-textfield.react';
import { useQuery } from '$lib/hooks/fetch-utillities';

/* Returns null if the original value was null and the input was an empty string */
const getStringValueOrLeaveAsNullIfEmpty = (inputValue: string | undefined | null , originalValue: string | undefined | null) => {
  if (inputValue == '' && (originalValue === null || originalValue === undefined)) {
    return null;
  }
  return inputValue;
};

export interface IControllerInfoProps {
  notificationService: NotificationService;
  controllerService: ControllerService;
  controllerId: number;
  controller: NonNullable<ControllerDetailsQueryQuery['controller']>;
  editingControllerDetails: boolean;
  setEditingControllerDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setPendingBuidId: (buid: number) => unknown;
  isIda2x: boolean;
  isIda: boolean;
  displayRemoteCardOnMobile: () => unknown;
}

interface ControllerDetailsFormData {
  isMasterDataSource: boolean | null | undefined;
  isArchived: boolean;
  serial: string;
  alternateSerial: string | null | undefined;
  alias: string | undefined;
  controllerType: string;
  buidId: string | null | undefined;
  buidName: string | null | undefined;
  timeZoneId: number | null | undefined;
  numberOfSlaves: number | null | undefined;
  ipAddress: string | null | undefined;
  icc: string | null | undefined;
  msisdn: string | null | undefined;
  protocolVersion: string | null | undefined;
  communicationManagerVersion: string | null | undefined;
  provider: string | null | undefined;
  simNotes: string | null | undefined;
  countryCode: string | undefined;
  siteAlias: string | undefined;
  lastContact: string;
  lastSampleTime: string;
  created: string;
  createdByUser: string | undefined;
  currentRunningScriptName: string | null | undefined;
  currentScriptModified: string;
  idacsLabel: string;
}

const ControllerInfoComponent: FC<IControllerInfoProps> = ({
  controllerId,
  controller,
  editingControllerDetails,
  setEditingControllerDetails,
  setPendingBuidId,
  isIda2x,
  isIda,
  displayRemoteCardOnMobile,
  notificationService,
  controllerService,
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const isMobile = useIsMobile();
  const { activeRequest, sendCommand } =
    useControllerCommandService(controllerId);
  const [renderUpdateControllerModal, setRenderUpdateControllerModal] = useState(false);
  const [conflictingControllers, setConflictingControllers] = useState<Array<UpdateControllerConflict>>([]);
  const [updateRequest, setUpdateRequest] = useState<ControllerInput>()
  const [updateControllerResponse, setUpdateControllerResponse] = useState<UpdateControllerMutation>({updateController: {success: false, message: "", code: ""}})
  const features = getUserFeatures();
  const columns = getAllowedColumns(getSession());
  const isSuperUser = getSession().currentUser.isSuperUser;
  const [serialLocked, setSerialLocked] = useState<boolean>(true);

  const {data: idaxCsUrlData} = useQuery(IdaxCsUrlDocument, {controllerId: controllerId});

  const pingController = async () => {
    sendCommand('ping', undefined);
    if (isMobile) displayRemoteCardOnMobile();
  };

  const formEditorProps: ICardFormEditorProps<ControllerDetailsFormData> = {
    allowEdit:
      features.controllerManagement.write ||
      features.siteDetailsController.write,
    editing: editingControllerDetails,
    setEditing:(editing: boolean) => {
      setEditingControllerDetails(editing);
      setSerialLocked(true);
    },
    headerText: controller.alias ?? 'Controller details',
    data: {
      isMasterDataSource: controller.isMasterDataSource,
      serial: controller.serial,
      isArchived: controller.isArchived,
      alternateSerial: controller.alternateSerial,
      alias: controller.alias ?? undefined,
      controllerType: controller.controllerType,
      buidId: controller.buidId?.toString(),
      buidName: controller.buid?.name,
      timeZoneId: controller.timeZoneId,
      numberOfSlaves: controller.numberOfSlaves,
      ipAddress: controller.simDetail?.ipAddress ?? '',
      icc: controller.simDetail?.icc ?? '',
      msisdn: controller.simDetail?.msisdn ?? '',
      protocolVersion: controller.protocolVersion,
      communicationManagerVersion: controller.communicationManagerVersion,
      provider: controller.simDetail?.provider,
      simNotes: controller.simDetail?.simNotes,
      countryCode: controller.countryCode ?? undefined,
      siteAlias: controller.site?.alias,
      lastContact: controller.lastContact
        ? pureFormatDate(controller.lastContact, true, '.')
        : t('UI_Common_Controller_Value_Never'),
      lastSampleTime: controller.lastSampleTime
        ? pureFormatDate(controller.lastSampleTime, true, '.')
        : t('UI_Common_Controller_Value_Never'),
      created: pureFormatDate(controller.created ?? '', true, '.'),
      createdByUser: controller.createdByUser?.name,
      currentRunningScriptName: controller.currentRunningScriptName,
      currentScriptModified: pureFormatDate(
        controller.currentRunningScriptLastModified ?? '',
        true,
        '.'
      ),
      idacsLabel: t('UI_Common_Controller_Label_IDACSLink'),
    },
    formItems: [
      {
        displayText: t('UI_Controller_Is_Master_Data_Source'),
        dataPropertyName: 'isMasterDataSource',
        type: 'checkbox',
        onlyDisplayInMode: 'editing',
      },
      {
        displayText: t('UI_Common_Controller_Label_Serial'),
        dataPropertyName: 'serial',
        type: 'custom',
        allowEdit: isSuperUser,
        customEditComponent: ({ control }) => {
          return (
            <Controller
              name="serial"
              control={control}
              render={({ field }) => (
                <div className={"serial-and-lock"} style={{display: "flex"}} >
                  <MuiTextField
                    value={field.value}
                    disabled={serialLocked}
                    sx={{minWidth: "auto", flexGrow: 1}}
                    removeWhitespace={true}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  { isSuperUser && serialLocked && ( <Icon name={"fa-lock"} className={"lock clickable"} onClick={() => setSerialLocked(false)}></Icon> )}
                  { isSuperUser && !serialLocked && ( <Icon name={"fa-unlock"} className={"lock clickable"} onClick={() => setSerialLocked(true)}></Icon> )}
                </div>
              )}
            />
          );
        },
      },
      {
        displayText: t('UI_Common_Controller_Label_AlternateSerial'),
        dataPropertyName: 'alternateSerial',
        onlyShowIfPermission:
          !!controller.alternateSerial && columns.ControllerAlternateSerial,
        allowEdit: false,
      },
      {
        displayText: t('UI_Common_Controller_Label_Alias'),
        dataPropertyName: 'alias',
        onlyDisplayInMode: 'editing',
        validationOptions: {
          required: {
            value: true,
            message: t('ui_controllermanager_missing_alias'),
          },
        },
      },
      {
        displayText: t('UI_Common_Controller_Label_ControllerType'),
        dataPropertyName: 'controllerType',
        onlyShowIfPermission: columns.DeviceType,
        type: 'custom',
        customEditComponent: ({ control, watch }) => {
          const isMasterDataSource: boolean = !!watch('isMasterDataSource');
          return (
            <Controller
              name="controllerType"
              control={control}
              render={({ field }) => (
                <ControllerTypeDropdown
                  selected={field.value ?? undefined}
                  itemClicked={(item) => field.onChange(item)}
                  topElement={t<string>('ui_multiedit_formelement_unchanged')}
                  placeholder={t('ui_multiedit_formelement_unchanged')}
                  disabled={isMasterDataSource}
                />
              )}
            />
          );
        },
      },
      {
        displayText: t('UI_Common_Buid'),
        dataPropertyName: 'buidId',
        onlyShowIfPermission: columns.Buid,
        type: 'custom',
        customEditComponent: ({ control, watch }) => {
          const isMasterDataSource: boolean = !!watch('isMasterDataSource');
          return (
            <Controller
              name="buidId"
              control={control}
              rules={{
                required: {
                  value: controller.buidId !== null && controller.buidId !== undefined,
                  message: t('ui_controllermanager_missing_buid'),
                },
              }}
              render={({ field }) => (
                <BuidDropdown
                  selected={field.value ?? undefined}
                  itemClicked={(item) => {
                    const idAsNumber = parseInt(item.buidId ?? '');
                    if (idAsNumber && !isNaN(idAsNumber)) {
                      field.onChange(item.buidId);
                      setPendingBuidId(idAsNumber);
                    }
                  }}
                  topElement={t<string>('ui_multiedit_formelement_unchanged')}
                  placeholder={t('ui_multiedit_formelement_unchanged')}
                  disabled={isMasterDataSource}
                />
              )}
            />
          );
        },
        customViewComponent: (_, allData) => <div>{allData?.buidName}</div>,
      },
      {
        displayText: t('UI_Common_Controller_Label_Timezone'),
        dataPropertyName: 'timeZoneId',
        type: 'custom',
        customEditComponent: ({ control, watch }) => {
          const isMasterDataSource: boolean = !!watch('isMasterDataSource');
          return (
            <Controller
              name="timeZoneId"
              control={control}
              render={({ field }) => (
                <TimezoneDropdown
                  selected={field.value?.toString()}
                  itemClicked={(item) => field.onChange(item.timezoneId)}
                  topElement={t<string>('ui_multiedit_formelement_unchanged')}
                  placeholder={t('ui_multiedit_formelement_unchanged')}
                  disabled={isMasterDataSource}
                />
              )}
            />
          );
        },
        customViewComponent: (value) => {
          switch (typeof value) {
            case 'number':
              return getTimezoneDescById(value);
            case 'string':
              return getTimezoneDescById(parseInt(value));
            default:
              return;
          }
        },
      },
      {
        displayText: t('UI_Common_Controller_Label_NumberOfSlaves'),
        dataPropertyName: 'numberOfSlaves',
        onlyShowIfPermission: controller.controllerType === 'IDA-10',
        onlyDisplayInMode: !columns.IpAddress ? 'viewing' : undefined,
        type: 'number',
        minValue: 0,
        allowDecimal: false,
      },
      {
        displayText: t('UI_Common_Controller_SimDetails_Label_IPAddress'),
        dataPropertyName: 'ipAddress',
        type: 'custom',
        customEditComponent: ({ control, watch }) => {
          const isMasterDataSource: boolean = !!watch('isMasterDataSource');
          return (
            <Controller
              name={"ipAddress"}
              control={control}
              rules={getIpAddressFormValidation(t)}
              render={({ field }) => (
                <IpAddressInput
                  setValue={(value) => field.onChange(value)}
                  value={field.value as string | undefined}
                  disabled={isMasterDataSource}
                />
              )}
            />
          );
        }
      },
      {
        displayText: t('UI_Common_Controller_SimDetails_Label_ICC'),
        dataPropertyName: 'icc',
        onlyShowIfPermission: columns.Icc,
        type: 'icc',
      },
      {
        displayText: t('UI_Common_Controller_SimDetails_Label_MSISDN'),
        dataPropertyName: 'msisdn',
        valueLink: `sms:${controller.simDetail?.msisdn};?&body=ping`,
        onlyShowIfPermission: columns.Telephone,
        type: 'msisdn',
      },
      {
        displayText: t('UI_Common_Controller_Label_ProtocolVersion'),
        dataPropertyName: 'protocolVersion',
        onlyShowIfPermission: columns.DeviceType,
        allowEdit: true,
      },
      {
        displayText: t(
          'UI_Common_Controller_Label_CommunicationManagerVersion'
        ),
        dataPropertyName: 'communicationManagerVersion',
        onlyShowIfPermission: columns.DeviceType,
        allowEdit: false,
        onlyDisplayInMode: 'viewing',
      },
      {
        displayText: t('UI_Common_Controller_SimDetails_Label_Provider'),
        dataPropertyName: 'provider',
        onlyShowIfPermission: columns.Telephone,
      },
      {
        displayText: t('UI_Common_Controller_SimDetails_Label_SimNotes'),
        dataPropertyName: 'simNotes',
        onlyShowIfPermission: columns.Telephone,
      },
      {
        displayText: t('UI_Common_Controller_Label_CountryCode'),
        dataPropertyName: 'countryCode',
        onlyShowIfPermission: columns.CountryCode,
        type: 'custom',
        customEditComponent: ({ control }) => (
          <Controller
            name="countryCode"
            control={control}
            render={({ field }) => (
              <CountryCodeDropdown
                selected={field.value}
                itemClicked={(item) => field.onChange(item.countryCode)}
                topElement={t<string>('ui_multiedit_formelement_unchanged')}
                placeholder={t('ui_multiedit_formelement_unchanged')}
              />
            )}
          />
        ),
        customViewComponent: (value?: string) => (
          <div>{value ? getName(value) ?? '' + ': ' + value : ''}</div>
        ),
      },
      {
        displayText: t('UI_Common_Controller_Label_Site'),
        dataPropertyName: 'siteAlias',
        valueLink: `/sitedetails/${controller.siteId}`,
        onlyShowIfPermission: !!controller.site,
        allowEdit: false,
      },
      {
        displayText: t('UI_Common_Controller_Label_LastContact'),
        dataPropertyName: 'lastContact',
        onlyShowIfPermission: columns.LastSampleDate,
        allowEdit: false,
      },
      {
        displayText: t('UI_Common_Controller_Label_LastSampleTime'),
        dataPropertyName: 'lastSampleTime',
        onlyShowIfPermission: columns.LastSampleDate,
        allowEdit: false,
      },
      {
        displayText: t('UI_Common_Controller_Label_Created'),
        dataPropertyName: 'created',
        type: 'datetime',
        allowEdit: false,
        onlyDisplayInMode: 'editing',
      },
      {
        displayText: t('UI_Common_Controller_Label_CreatedBy'),
        dataPropertyName: 'createdByUser',
        allowEdit: false,
      },
      {
        displayText: t('UI_Common_Controller_Label_CurrentScriptName'),
        dataPropertyName: 'currentRunningScriptName',
        onlyDisplayInMode: 'viewing',
        onlyShowIfPermission: isIda2x,
      },
      {
        displayText: t('UI_Common_Controller_Label_CurrentScriptModified'),
        dataPropertyName: 'currentScriptModified',
        onlyDisplayInMode: 'viewing',
        onlyShowIfPermission: isIda2x,
      },
      {
        displayText: t('UI_Common_Controller_Label_IDACS'),
        dataPropertyName: 'idacsLabel',
        onlyDisplayInMode: 'viewing',
        valueLink: idaxCsUrlData?.idaxCsUrl?.url ?? undefined,
        onlyShowIfPermission: features.idacs.read && !!idaxCsUrlData?.idaxCsUrl?.url,
      },
      {
        onlyShowIfPermission: isIda && features.controllerManagement.read,
        displayText: t('UI_SiteDetails_LastBackup'),
        type: 'custom',
        onlyDisplayInMode: 'viewing',
        customViewComponent: (_, allData) => (
          <ControllerLastBackup
            controllerId={controllerId}
            controllerSerial={allData?.serial ?? ''}
            notificationService={notificationService}
            controllerService={controllerService}
          />
        ),
      },
      {
        onlyShowIfPermission: isIda2x && features.controllerUploadConfig.write,
        displayText: t('UI_SiteDetails_UploadConfig'),
        type: 'custom',
        onlyDisplayInMode: 'viewing',
        customViewComponent: (_, _allData) => (
          <ControllerUploadConfig
            controllerId={controllerId}
            controllerService={controllerService}
            notificationService={notificationService}
          />
        ),
      },      
    ],
    onSubmit: (setErrorMessage) => async (formData) => {
      const countryCode = formData.countryCode ?? controller.countryCode;
      if (!formData.alias) {
        setErrorMessage(t('ui_controllermanager_missing_alias'));
        return;
      }
      if (!countryCode) {
        setErrorMessage(t('ui_controllermanager_missing_countrycode'));
        return;
      }

      const updateControllerRequest = {
        controllerId: controllerId,
        serial: formData.serial,
        alias: formData.alias,
        controllerType: formData.controllerType,
        buidId: parseInt(formData.buidId ?? ''),
        countryCode: countryCode,
        timeZoneId: formData.timeZoneId
          ? ensureNumber(formData.timeZoneId)
          : undefined,
        isMasterDataSource: Boolean(formData.isMasterDataSource),
        numberOfSlaves: formData.numberOfSlaves
          ? ensureNumber(formData.numberOfSlaves)
          : undefined,
        ipAddress: getStringValueOrLeaveAsNullIfEmpty(formData.ipAddress, controller.simDetail?.ipAddress),
        icc: getStringValueOrLeaveAsNullIfEmpty(formData.icc, controller.simDetail?.icc),
        msisdn: getStringValueOrLeaveAsNullIfEmpty(formData.msisdn, controller.simDetail?.msisdn),
        protocolVersion: getStringValueOrLeaveAsNullIfEmpty(formData.protocolVersion, controller.protocolVersion),
        provider: getStringValueOrLeaveAsNullIfEmpty(formData.provider, controller.simDetail?.provider),
        simNotes: getStringValueOrLeaveAsNullIfEmpty(formData.simNotes, controller.simDetail?.simNotes),
      }
      
      setUpdateRequest(updateControllerRequest) 

      const preFlightResponse = await mutate(
        UpdateControllerPreflightQueryDocument,
        {
          controllerId: controllerId,
          controllerToCheck: updateControllerRequest,
        },
        true
      );

      if (preFlightResponse.updateControllerPreflight.length != 0) {
        setConflictingControllers(preFlightResponse.updateControllerPreflight)
        setRenderUpdateControllerModal(true)
      }
      else{       
        const response = await mutate(
          UpdateControllerDocument,
          updateControllerRequest,
          true
        );
        setUpdateControllerResponse(response);     
      }

      if(!updateControllerResponse.updateController.success){
        setErrorMessage(updateControllerResponse.updateController.message)
      }

      setEditingControllerDetails(false);
      
    }
  };


  const updateControllerWithConflict = async () => {
    const response = await mutate(
      UpdateControllerDocument,
      updateRequest,
      true
    );
    setUpdateControllerResponse(response);
  };

  const showPingButton =
    features.controllerCommands.read &&
    controller?.simDetail?.ipAddress &&
    isPingeableIPAddress(controller.simDetail.ipAddress);

  return (
    <CardFormEditor<ControllerDetailsFormData> {...formEditorProps}>
      {controller.isArchived && (
        <>
          <Banner type='warning' icon='archive'>
            { t('UI_Controller_ControllerIsArchived') }
          </Banner> 
          <br/>
        </>
      )}      
      {showPingButton && (
        <Button
          fullWidth
          variant="contained"
          startIcon={
            activeRequest === 'ping' && (
              <Icon name="fa-spinner fa-pulse" className="text" />
            )
          }
          disabled={!!activeRequest}
          onClick={pingController}
        >
          {t(
            activeRequest === 'ping'
              ? 'UI_SiteDetails_Controllers_ControllerDetails_Actions_Pinging'
              : 'UI_SiteDetails_Controllers_ControllerDetails_Actions_Ping'
          )}
        </Button>
      )}
      {renderUpdateControllerModal && (
      <ControllerUpdateModal
      conflictingControllers={conflictingControllers}
      canShowControllerManager={features.controllerManagement.read}
      handleClose={() => setRenderUpdateControllerModal(false)}
      handleUpdateController={updateControllerWithConflict}
      ></ControllerUpdateModal>
      )}

    </CardFormEditor>    
  );
};
export const ControllerInfo = memo(ControllerInfoComponent);
