import { customElement, bindable } from 'aurelia-templating';
import { IPoint } from '../../../../../../../models';
import { computedFrom } from 'aurelia-framework';
import { formatDate, isNone, hexToRGBA } from '../../../../../../../utility';
import { IUnit } from '../../../../../../../interfaces';

import './dose-graph.css';

@customElement('dose-graph')
export class DoseGraph {
  @bindable() history: IPoint[];
  @bindable() unit: IUnit | undefined;
  @bindable() timezone: string | undefined;
  @bindable() color: string | undefined;
  @bindable() timegroup: 'hourly' | 'daily' = 'daily';

  @computedFrom('color')
  get backgroundColor() {
    return isNone(this.color) ? undefined : hexToRGBA(this.color, 0.4);
  }

  @computedFrom('history', 'timezone')
  get historyGraph() {
    const history: Array<{
      height: number;
      width: number;
      date: string;
      value: number;
    }> = [];
    let previousDate: string | undefined;
    let previousPoint: IPoint | undefined;
    let sumOfDate = 0;
    let numberOfDate = 0;
    for (const point of this.history) {
      const shiftedDate = formatDate(point.ts, false, '.', this.timezone);
      if (
        !isNone(previousDate) &&
        !isNone(previousPoint) &&
        previousDate !== shiftedDate
      ) {
        history.push({
          date: shiftedDate,
          height: sumOfDate,
          width: numberOfDate,
          value: sumOfDate
        });
        sumOfDate = 0;
        numberOfDate = 0;
      }
      sumOfDate += point.v;
      numberOfDate++;
      previousPoint = point;
      previousDate = shiftedDate;
    }

    if (!isNone(previousDate))
      history.push({
        date: previousDate,
        height: sumOfDate,
        width: numberOfDate,
        value: sumOfDate
      });

    const maxHeight = Math.max(...history.map(h => h.height));
    const maxWidth = Math.max(...history.map(h => h.width));

    return history.map<{ height: number; width: number; date: string }>(h => ({
      height: (h.height / maxHeight) * 100,
      width: h.width / maxWidth,
      date: h.date,
      value: h.value
    }));
  }

  barClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  };
}
