import React, { useEffect, useState, memo, FC } from 'react';
import { MuiModalCard } from '$components/modals/mui-modal-card/mui-modal-card';
import { Button, Checkbox, IconButton } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import '../common-modal-styles.css';
import {
  CanSuspendSimCardsDocument,
  ControllersArchiveDocument,
} from '$typings/graphql-codegen';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import ErrorText from '$components/texts/error-text/error-text.react';
import { useIsMobile } from '$lib/hooks/isMobile';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { WarningText } from '$components/texts/warning-text/warning-text.react';
import classNames from 'classnames';

interface IControllerArchiveModalProps {
  open: boolean;
  controllerIds: number[];
  handleClose: () => unknown;
  onArchived: () => unknown;
}

const ControllerArchiveModalComponent: FC<IControllerArchiveModalProps> = ({
  controllerIds,
  open,
  handleClose,
  onArchived,
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [errors, setErrors] = useState<string[]>([]);
  const [requestIsRunning, setRequestIsRunning] = useState(false);
  const [suspendSim, setSuspendSim] = useState(false);
  const [canSuspendSim, setCanSuspendSim] = useState(false);
  const isMobile = useIsMobile();

  const { data, loading } = useQuery(CanSuspendSimCardsDocument, {
    controllerIds: controllerIds,
  });

  useEffect(() => {
    if (!data || loading) return;
    setCanSuspendSim(data.canSuspendSimCards);
  }, [data]);

  const onClose = () => {
    errors.length && setErrors([]);
    handleClose();
  };

  const doArchive = async () => {
    errors.length && setErrors([]);
    setRequestIsRunning(true);
    try {
      await mutate(
        ControllersArchiveDocument,
        { controllerIds: controllerIds, suspendSim },
        false,
        (result) => {
          if (!result.archiveControllers.success) {
            setErrors([result.archiveControllers.message]);
            setRequestIsRunning(false);
            return;
          }
          onArchived();
          setRequestIsRunning(false);
        },
        (err) => {
          setErrors(err.message);
          setRequestIsRunning(false);
        }
      );
    } catch (e) {
      const errors = e.response?.errors?.map((e: any) => e.message);
      errors && setErrors(errors);
      setRequestIsRunning(false);
    }
  };

  return (
    <MuiModalCard
      fullScreen={isMobile}
      open={open}
      handleClose={onClose}
      headerText={t('UI_RequireConfirmation_Header')}
      headerActions={
        <IconButton size="small" onClick={onClose}>
          <Icon name={'fa-times'} />
        </IconButton>
      }
      footerContent={
        <>
          <Button onClick={onClose} variant="outlined" type="button">
            {t('ui_common_cancel')}
          </Button>
          <Button
            onClick={doArchive}
            color="error"
            variant="contained"
            type="button"
            disabled={requestIsRunning}
            startIcon={
              requestIsRunning && (
                <Icon name={'fa-spinner fa-pulse'} className="text" />
              )
            }
          >
            {t('UI_RequireConfirmation_ConfirmArchiveButton')}
          </Button>
        </>
      }
    >
      <div
        style={{ width: isMobile ? undefined : '400px' }}
        className="text-wrapper"
      >
        <p>
          {t(
            'UI_ControllerManager_ControllerDetails_ArchiveControllersDialog_Body'
          )}
        </p>
        <div className="flex row aicenter">
          <Checkbox
            id="suspendsimcheck"
            checked={suspendSim}
            className="mark-controllers"
            disabled={!canSuspendSim}
            onChange={() => setSuspendSim((b) => !b)}
          />
          <label
            htmlFor="suspendsimcheck"
            className={classNames(
              canSuspendSim
                ? 'enabled-checkbox-label'
                : 'disabled-checkbox-label'
            )}
          >
            {t('UI_DeleteControllerDialogs_AlsoSuspendSIMCards')}
          </label>
        </div>

        {suspendSim && (
          <WarningText>
            <p className="suspend-warning mar_tm">
              {t(
                'UI_DeleteControllerDialogs_AlsoSuspendSIMCards_Warning_Line1'
              )}
            </p>
            <p className="suspend-warning mar_tm">
              {t(
                'UI_DeleteControllerDialogs_AlsoSuspendSIMCards_Warning_Line2'
              )}
            </p>
          </WarningText>
        )}
        {errors.map((e) => (
          <ErrorText key={e}>{e}</ErrorText>
        ))}
      </div>
    </MuiModalCard>
  );
};

export const ControllerArchiveModal = memo(ControllerArchiveModalComponent);
