import React, { FC, CSSProperties, useRef, useState, useEffect } from 'react';
import { ensureNumber, isSomething } from '../../utility';
import { isNone } from '$lib/helpers';
import './slider.css';

interface ISliderProps {
  min: number;
  max: number;
  step?: number;
  onChanged: ({value, from, to}: {value: number | number[], from?: number, to?: number}) => void;
  value?: number | string;
  from?: number | string;
  to?: number | string;
  range?: boolean;
  style?: CSSProperties
}

const Slider: FC<ISliderProps> = ({ min, max = 100, step, onChanged, value = 0, from, to, range = false, style }) => {
  const [filledStyle, setFilledStyle] = useState<CSSProperties>();
  const firstSlider = useRef<HTMLInputElement | null>(null);
  const secondSlider = useRef<HTMLInputElement | null>(null);

  function changed(value1: string | undefined, value2: string | undefined) {
    if (isSomething(value1)) {
      const value1parsed = ensureNumber(value1);
      if (range && isSomething(value2)) {
        const value2parsed = ensureNumber(value2);
        const from = Math.min(value1parsed, value2parsed);
        const to = Math.max(value1parsed, value2parsed);
        onChanged({ from, to, value: [from, to] });
      } else {
        onChanged({ value: value1parsed });
      }
    }
  }

  useEffect(() => {
    if (!range && !isNone(value) && !isNone(max)) {
      const percentage = (ensureNumber(value) / ensureNumber(max)) * 100;
      return setFilledStyle({ width: `${percentage}%` });
    }
    if (range && !isNone(from) && !isNone(to) && !isNone(max)) {
      const percentage = ((ensureNumber(to) - ensureNumber(from)) / ensureNumber(max)) * 100;
      const shifted = (ensureNumber(from) / ensureNumber(max)) * 100;
      return setFilledStyle({ width: `${percentage}%`, left: `${shifted}%` });
    }
    return setFilledStyle({ width: 0 });
  }, [value, from, to, range]);

  return (
    <div className="slider-component" style={style}>
      <div className="background">
        <div className="filled" style={filledStyle}></div>
      </div>
      <input
        type="range"
        value={range ? from : value}
        onInput={() => changed(firstSlider.current?.value, secondSlider.current?.value)}
        ref={firstSlider}
        min={min}
        max={max}
        step={step}
      />
      {range && (
        <input
          type="range"
          value={to}
          onInput={() => changed(firstSlider.current?.value, secondSlider.current?.value)}
          min={min}
          max={max}
          ref={secondSlider}
          step={step}
        />
      )}
    </div>
  );
};

export default Slider;
