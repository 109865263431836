import { useQuery } from '$lib/hooks/fetch-utillities';
import {
  CustomersQueryReactDocument,
  CustomersQueryReactQuery,
} from '$typings/graphql-codegen';
import React, { FC, ReactElement } from 'react';
import Dropdown from '../dropdown/dropdown.react';

type CustomerType = CustomersQueryReactQuery['customers'][0];

interface ICustomerDropdownProps {
  selected?: CustomerType | string;
  itemClicked?: (params: { customerId?: string; name?: string }) => void;
  placeholder?: string;
  topElement?: string;
  error?: boolean;
  replaceTopElement?: (query: string) => ReactElement;
  className?: string;
}

const CustomerDropdown: FC<ICustomerDropdownProps> = ({
  selected,
  itemClicked,
  placeholder,
  topElement,
  error,
  replaceTopElement,
  className
}) => {
  const { data, loading } = useQuery(CustomersQueryReactDocument);

  return (
    <Dropdown<CustomerType>
      items={data?.customers}
      className={className}
      displayPath="name"
      selected={selected}
      loading={loading}
      valuePath="customerId"
      itemClicked={(params) =>
        itemClicked &&
        itemClicked({
          customerId: params?.value?.toString(),
          name: params?.text,
        })
      }
      placeholder={placeholder}
      topElement={topElement}
      error={error}
      replaceTopElement={replaceTopElement}
    />
  );
};

export default CustomerDropdown;
