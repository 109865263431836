import { autoinject, computedFrom } from 'aurelia-framework';
import {
  editMapMarkerProperty,
  editSiteAddressPropertyForMap,
  editSiteDetailsMap,
  toggleExpandMenuOnEntity,
  cancelSiteAddressForMapEdit,
  setSiteMapMarkerMode,
  confirmMapMarkerMode,
  updateSiteAddressForMap
} from '../../../../actions';
import {
  IMapMarker,
  ILatLng,
  ISite,
  AccessLevel,
  IController,
  IControllerMapMarker,
  IControllerMapMarkerWithAutoUpdate,
  ISiteDetailsMapAddress
} from '../../../../interfaces';
import { rootState } from '../../../../reducers';
import { MapService, SiteService } from '../../../../services';
import { menuIsOpenForEntity } from '../../../../types';
import {
  isNone,
  getNewId,
  removeNoneAndEmptyFromArray,
  emptyArray,
  userHasFeature,
  ensureNumber,
  appendObjectToArray,
  emptyObject,
  isEmpty
} from '../../../../utility';
import {
  BaseViewModel,
  nativeControllersForSiteSelector
} from '../../../common';
import { getLogger } from 'aurelia-logging';
import { setMarkerMode } from '../../../../reducers/ui/sitedetailsMapReducer';
import { features, routeNames } from '../../../../config';
import { getSession } from '../../../../config/sessionService';
import { Router } from 'aurelia-router';
import { createSelector } from 'reselect';
import './sitedetailsmap.css';
import { MarkerIconEnum } from '$typings/graphql-codegen';
import { SiteSignal } from '../../../../../src/models';

interface ISiteDetailsMapState {
  isEditing: boolean;
  allMarkers: IMapMarker[];
  controllerMarkers: IControllerMapMarkerWithAutoUpdate[];
  userPosition?: ILatLng;
  site: ISite | undefined;
  menuOpenForEntity?: menuIsOpenForEntity;
  hasMarkerOrAddress: boolean;
  siteHasAddress: boolean;
  siteHasAddressOrLocation: boolean;
  setMarkerMode: setMarkerMode;
  isMobile: boolean;
  canEdit: boolean;
  showMap: boolean;
  persisting: boolean;
  persistError?: string;
  hasMarkersWithAccuracy: boolean;
}

const currentSiteHasAddressOrLocation = (site: ISite | undefined, controllerMarkers: IControllerMapMarkerWithAutoUpdate[]) => 
  site 
  && (!isEmpty(site.addressLine1) 
      && (!isEmpty(site.postcode) || !isEmpty(site.city)) 
      && !isEmpty(site.country)) 
    || (controllerMarkers.length > 0 && controllerMarkers.some(s => s.lat != 0 && s.lng != 0));

const currentSiteHasAddress = (site: ISite) =>
  !!site.addressLine1 || !!site.addressLine2 || !!site.city || !!site.country;

const createMarker = (
  lat: number,
  lng: number,
  accuracy: number,
  controllerId: number,
  title: string,
  autoUpdateLocation: boolean | undefined,
  controllerType: string,
  siteSignal: SiteSignal
): IControllerMapMarkerWithAutoUpdate => ({
  lat,
  lng,
  accuracy,
  markerType: 'controller',
  title,
  controllerId,
  markerIconEnum: siteSignal === SiteSignal.Online ?  MarkerIconEnum.ControllerOnline : MarkerIconEnum.ControllerOffline,
  autoUpdateLocation,
  controllerType
});

const createMarkerFromController = ({
  controllerId,
  lat,
  lng,
  accuracy,
  alias,
  autoUpdateLocation,
  controllerType,
  signal
}: IController): IControllerMapMarkerWithAutoUpdate =>
  createMarker(
    lat || 0,
    lng || 0,
    accuracy || 0,
    controllerId,
    alias,
    autoUpdateLocation,
    controllerType,
    signal
  );

const createMarkerFromUserPosition = (userPosition: ILatLng): IMapMarker => ({
  lat: userPosition.lat,
  lng: userPosition.lng,
  accuracy: 0,
  markerType: 'userposition'
});

const controllerMarkersSelector = createSelector<
  rootState,
  IController[],
  IControllerMapMarkerWithAutoUpdate[]
>(
  nativeControllersForSiteSelector,
  siteControllers => siteControllers.map(createMarkerFromController)
);

const filterOutEmptyLocations = (markers: IMapMarker[]): IMapMarker[] =>
  markers.filter(m => m.lat !== 0 || m.lng !== 0);

const controllerMarkersWithPositionAndUserPositionSelector = createSelector<
  rootState,
  IMapMarker[],
  ILatLng | undefined,
  IMapMarker[]
>(
  controllerMarkersSelector,
  state => state.maps.userPosition,
  (markers, possibleUserPosition) =>
    possibleUserPosition
      ? filterOutEmptyLocations(
          appendObjectToArray(
            markers || emptyArray,
            createMarkerFromUserPosition(possibleUserPosition)
          )
        )
      : filterOutEmptyLocations(markers || emptyArray)
);

@autoinject()
export class SiteDetailsMap extends BaseViewModel<ISiteDetailsMapState> {
  public menuForActionIcon = getNewId();
  public expandedMarkerPanels: number[] = [];
  public isChildViewModel: boolean = false;
  public showAccuracy = true;

  constructor(
    private router: Router,
    protected mapService: MapService,
    private siteService: SiteService
  ) {
    super(getLogger(SiteDetailsMap.name));
  }

  activate(params: {
    id: string;
    isChildViewModel: boolean;
    action: undefined | 'edit';
  }) {
    this.isChildViewModel = params.isChildViewModel;
    this.settingAddressFromSap = false;
    this.attachMapState(
      this.mapState(ensureNumber(params.id), params.action === 'edit')
    );
  }

  mapState = (siteId: number, editingThroughUrl: boolean) => (
    state: rootState
  ): ISiteDetailsMapState => {
    const siteFetcher = this.siteService.getSiteById(siteId);
    const siteHasAddress = siteFetcher    
      .map(currentSiteHasAddress)
      .getEntityOrDefault(false);
    
    const { pendingSite, pendingMarkers } = state.sitedetailsMap;

    const pendingSiteFetcher = siteFetcher.map(site =>
      pendingSite ? { ...site, ...pendingSite } : site
    );

    const originalMarkers = controllerMarkersSelector(state);
    const markers = isNone(pendingMarkers)
      ? originalMarkers
      : originalMarkers.map(marker => {
          const pendingmarker = pendingMarkers.find(
            m => m.controllerId === marker.controllerId
          );
          return isNone(pendingmarker)
            ? marker
            : { ...marker, ...pendingmarker };
        });

    const site = siteFetcher.getEntityOrUndefined();     
    const siteHasAddressOrLocation = currentSiteHasAddressOrLocation(site, markers);
        
    const markersWithUserPosition = controllerMarkersWithPositionAndUserPositionSelector(
      state
    );

    return {
      isEditing: editingThroughUrl || !isNone(pendingSite),
      allMarkers: markersWithUserPosition,
      controllerMarkers: markers,
      menuOpenForEntity: state.application.menuIsOpenForEntity,
      site: pendingSiteFetcher.getEntityOrUndefined(),
      userPosition: state.maps.userPosition,
      hasMarkerOrAddress:
        markersWithUserPosition.length !== 0 || siteHasAddress,
      siteHasAddress,
      siteHasAddressOrLocation,
      setMarkerMode: state.sitedetailsMap.setMarkerMode,
      isMobile: state.device.screenSize === 'mobile',
      canEdit: userHasFeature(
        getSession(),
        features.siteDetails,
        AccessLevel.Write
      ),
      showMap: state.device.screenSize !== 'mobile',
      persistError: state.sitedetailsMap.error,
      persisting: state.sitedetailsMap.persisting,
      hasMarkersWithAccuracy: markers.some(m => !!m.accuracy)
    };
  };

  public getUserPosition() {
    this.mapService.fetchAndPersistUserPosition();
  }

  public navigateToSite() {
    const { site } = this.state;
    if (isNone(site)) return;
    const addressArray = removeNoneAndEmptyFromArray([
      site.addressLine1,
      site.addressLine2,
      site.city,
      site.postcode,
      site.country
    ]);

    //To navigate by address we need street address, postcode or city, and country as a minimum
    if (!isEmpty(site.addressLine1) && (!isEmpty(site.postcode) || !isEmpty(site.city)) && !isEmpty(site.country))
      window.open(`https://maps.apple.com/?daddr=${addressArray.join('+')}`);
    else if (this.state.controllerMarkers.length > 0) {
      const firstControllerWithLocation = this.state.controllerMarkers.filter(c => c.lat != 0 && c.lng != 0)[0];
      window.open(`https://maps.apple.com/?daddr=${firstControllerWithLocation.lat},${firstControllerWithLocation.lng}`);
    }     
  }

  public editGeoPositions() {
    const { site } = this.state;
    if (isNone(site)) return;
    if (this.isChildViewModel) this.dispatch(editSiteDetailsMap());
    else
      this.router.navigateToRoute(routeNames.sitedetailsMap, {
        action: 'edit'
      });
  }

  public editSiteAddressProperty<K extends keyof ISite>(
    property: K,
    value: string
  ) {
    if (isNone(this.state.site) || this.state.site[property] === value) return;
    this.dispatch(editSiteAddressPropertyForMap({ property, value }));
  }

  public editMapMarkerProperty(
    markerToEdit: IControllerMapMarker,
    property: keyof IMapMarker,
    value: string
  ) {
    if (markerToEdit[property] === value) return;
    this.dispatch(editMapMarkerProperty({ markerToEdit, property, value }));
  }

  public toggleExpandMenuOnEntity(entity: IMapMarker | ISite | number) {
    this.dispatch(toggleExpandMenuOnEntity(entity));
  }

  public toggleExpandedMarkerPanel = (id: number) => {
    if (!isNone(this.expandedMarkerPanels.find(i => i === id)))
      this.expandedMarkerPanels = this.expandedMarkerPanels.filter(
        i => i !== id
      );
    else this.expandedMarkerPanels = [id, ...this.expandedMarkerPanels];
  };

  public async persistChanges() {
    const { site, controllerMarkers } = this.state;
    if (isNone(site)) return;

    await this.mapService.updateSiteAddressAndControllerLocations(
      site,
      controllerMarkers.filter(
        m => !(m.controllerId === 0 && m.lat === 0 && m.lng === 0)
      )
    );
    if (!this.isChildViewModel) this.router.navigateBack();
  }

  public setAddressFromCurrentPosition() {
    this.mapService.updateSiteAddressFromCurrentPosition(
      this.state.userPosition
    );
  }

  public async setMarkerLocationFromCurrentPosition(
    marker: IControllerMapMarker
  ) {
    this.mapService.updateMarkerLocationFromCurrentPosition(marker);
  }

  settingAddressFromSap = false;
  public async setAddressFromSAP() {
    this.settingAddressFromSap = true;
  }

  public selectedAddressFromSap(selected: ISiteDetailsMapAddress) {
    this.dispatch(
      updateSiteAddressForMap.done({ params: emptyObject, result: selected })
    );
    this.settingAddressFromSap = false;
  }

  public async queryControllerLocation(marker: IControllerMapMarker) {
    this.mapService.queryControllerLocation(marker);
  }

  public setMarkerLocationFromSiteAddress(marker: IControllerMapMarker) {
    const { site } = this.state;
    if (isNone(site)) return;
    marker.accuracy = 0;
    this.mapService.updateMarkerLocationFromSiteAddress(marker, site);
  }

  public setAddressFromMarkerPosition(marker: IMapMarker) {
    this.mapService.updateSiteAddressFromPosition(marker);
  }

  public cancelEdit() {
    if (this.isChildViewModel) this.dispatch(cancelSiteAddressForMapEdit());
    else this.router.navigateBack();
  }

  public setMarkerModeForMarker = (marker: IControllerMapMarker) =>
    isNone(marker.controllerId)
      ? undefined
      : this.dispatch(
          setSiteMapMarkerMode({
            type: 'marker',
            controllerId: marker.controllerId,
            position: marker
          })
        );

  public setMarkerModeForSite = () =>
    this.dispatch(setSiteMapMarkerMode({ type: 'site' }));
  public cancelMarkerMode = () =>
    this.dispatch(setSiteMapMarkerMode(undefined));
  public confirmMarkerMode = () =>
    this.dispatch(confirmMapMarkerMode(this.state.setMarkerMode));

  @computedFrom('state.setMarkerMode.position')
  get getSetMarkerModeMarkers() {
    const { setMarkerMode } = this.state;
    if (isNone(setMarkerMode) || isNone(setMarkerMode.position))
      return emptyArray;
    return [setMarkerMode.position];
  }

  public mapClick(latlng: ILatLng) {
    const { setMarkerMode } = this.state;
    if (isNone(setMarkerMode)) return;
    switch (setMarkerMode.type) {
      case 'site':
        this.mapService.updatePendingSiteAddressFromPosition(
          { type: 'site', position: latlng },
          latlng
        );
        break;
      case 'marker':
        if (setMarkerMode.position) {
          //Set accuracy to 0 when manually setting position by clicking on map
          this.dispatch(
            setSiteMapMarkerMode({
              type: 'marker',
              controllerId: setMarkerMode.controllerId,
              position: {
                ...setMarkerMode.position,
                ...latlng,
                accuracy: latlng.accuracy
              }
            })
          );
        }
        break;
    }
  }
}
